import { IMAGES } from 'constants/images';

import { DISCOUNT_PRICE_COLOR, INCREDIBLE_DISCOUNTS_PRICE_COLOR } from './ProductHelpersConstants';

import type {
  IProduct,
  IProductBanner,
  IProductImage,
  IProductLabel,
  IProductQuantityVariant,
  IProductSaveMe,
  IProductTaxes,
  IProductVariant,
} from 'types';

/**
 * Get product variants
 */

interface GetProductVariantParams {
  shouldShowGeniusPrice?: boolean
}

export function getProductVariants(
  product: IProduct,
  params: GetProductVariantParams = { shouldShowGeniusPrice: false }
): IProductVariant[] {

  if (!product) return [];

  return [
    getProductDefaultVariant(product, params),
    getProductAcceleratedSaleVariant(product, params),
  ].filter((v) => !!v);
}

/**
 * Get default product variant
 */

export function getProductDefaultVariant(
  product: IProduct,
  params: GetProductVariantParams = { shouldShowGeniusPrice: false }
): IProductVariant {

  const {
    slug,
    currency,
    image,
    images,
    banner,
    taxes,
    sponsored
  } = product || {};

  const isSponsored = !!sponsored;

  const { labels } = sponsored || {};

  return getVariant(
    product,
    {
      isDefault: true,
      slug,
      currency,
      image,
      images,
      banner,
      taxes,
      isSponsored,
      ...(
        isSponsored
          ? { secondaryLabels: labels }
          : {}
      )
    },
    params
  );
}

/**
 * Get accelerated sale product variant
 */

export function getProductAcceleratedSaleVariant(
  product: IProduct,
  params: GetProductVariantParams = { shouldShowGeniusPrice: false }
): IProductVariant | undefined {

  const {
    slug,
    currency,
    image,
    images,
    saveMe,
    taxes,
  } = product || {};

  if (!saveMe) return undefined;

  return getVariant(
    saveMe,
    {
      isAcceleratedSale: true,
      slug,
      currency,
      image,
      images,
      taxes,
    },
    params
  );
}

/**
 * Get variant
 */

interface GetVariantExtraProps {
  isDefault?: boolean,
  isAcceleratedSale?: boolean,
  isSponsored?: boolean,
  slug: string,
  currency: string,
  image: IProductImage,
  banner?: IProductBanner,
  images: IProductImage[],
  taxes: IProductTaxes,
  labelsV2?: IProductLabel[],
  secondaryLabels?: IProductLabel[],
  infoLabels?: IProductLabel[]
}

function getVariant(
  variant: IProduct | IProductSaveMe,
  extraProps: GetVariantExtraProps,
  params: GetProductVariantParams
): IProductVariant {

  const {
    variantCode,
    isAvailable,

    // quantity
    maxAvailableQuantity,
    maxAllowedQuantity,

    // elements
    badge,
    labels,
    labelsV2 = [],
    infoLabels
  } = variant || {};

  const {
    isAcceleratedSale
  } = extraProps || {};

  const pricingProps = getProductVariantPriceProps(variant, { ...params });

  return {
    variantCode,

    // flags
    isAvailable,

    // pricing
    ...pricingProps,

    // quantity
    maxAvailableQuantity,
    maxAllowedQuantity,

    // elements
    // For backwards compatibility /w mobile: Temporary change - labelsV2 will become labels after we can clear up the contract
    labels: isAcceleratedSale ? labels : labelsV2,
    infoLabels,
    badge,

    // extraProps
    ...extraProps,
  };
}

/**
 * Get product variant pricing props
 */
export function getProductVariantPriceProps(
  product: Partial<IProduct> | IProductSaveMe,
  params: GetProductVariantParams = { shouldShowGeniusPrice: false, }
) {

  // Product

  const {
    //
    originalPrice,
    originalUnitPrice,
    price,
    unitPrice,
    unitPriceLabel,
    //
    discountLabel,
    //
    priceLabel,
    priceLabelColor,
    //
    priceColor,
    priceIcon,
    priceTooltip,
    //
    geniusPrice,
    geniusUnitPrice,
    geniusUnitPricePerLabel,
    geniusDiscountLabel,
    //
    geniusDiscountLabelConfig,
    quantityOptionsDiscountLabel,
    //
    promotionalPrice,
    //
    taxes = [],
    //
  } = (product as IProduct & IProductSaveMe & IProductQuantityVariant) || {};

  // Params

  const {
    shouldShowGeniusPrice,
  } = params || {};

  // Genius Props

  if (shouldShowGeniusPrice && (!!geniusPrice || !!geniusUnitPrice)) {
    return {
      hasGeniusPrice: true,
      //
      originalPriceStrikethrough: true,
      originalPrice: originalPrice || price,
      price: geniusPrice,
      unitPrice: geniusUnitPrice,
      //
      originalUnitPrice: originalUnitPrice || unitPrice,
      unitPriceLabel: geniusUnitPricePerLabel || unitPriceLabel,
      discountLabel: geniusDiscountLabel,
      discountLabelWithConfig: geniusDiscountLabelConfig,
      //
      priceColor: DISCOUNT_PRICE_COLOR,
      priceIcon: IMAGES.BRANDING.GENIUS_ICON,
      priceTooltip,
      //
      taxes,
    };
  }

  if (promotionalPrice) {
    return {
      hasGeniusPrice: false,
      //
      originalPriceStrikethrough: false,
      originalPrice: price,
      price: promotionalPrice,
      unitPrice,
      originalUnitPrice,
      //
      unitPriceLabel,
      discountLabel,
      discountLabelWithConfig: quantityOptionsDiscountLabel,
      //
      priceColor: INCREDIBLE_DISCOUNTS_PRICE_COLOR,
      priceTooltip,
      //
      taxes,
    };
  }

  // Default Props

  return {
    hasGeniusPrice: false,
    //
    originalPriceStrikethrough: true,
    originalPrice,
    price,
    //
    unitPrice,
    unitPriceLabel,
    originalUnitPrice,
    discountLabel,
    discountLabelWithConfig: quantityOptionsDiscountLabel,
    //
    priceColor: (discountLabel || (originalPrice && originalPrice !== price))
      ? DISCOUNT_PRICE_COLOR
      : priceColor,
    priceIcon,
    priceTooltip,
    //
    priceLabel,
    priceLabelColor,
    //
    taxes,
  };
}
