import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Button, Icon, Link, Scrollbar
} from 'components/ui';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import type {
  IListingFilter, IListingFilterOption, IListingFilters, IUrl
} from 'types';

import styles from './ActiveFiltersList.module.scss';

interface Props {
  className?: string,
  //
  filters: IListingFilters,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  clearFilters: () => void
}

const ActiveFiltersList = (props: Props) => {

  const {
    className,
    //
    filters,
    //
    getHref,
    clearFilters,
  } = props;

  // Hooks

  const { t } = useTranslation();

  const analytics = useAnalytics();

  const { persist: persistAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // Props

  const { countSelected, items = [] } = filters || {};

  // Handlers

  const handleFilterClick = (parentName: string, optionName: string) => {
    analytics.removeFilter(parentName, optionName);
    persistAnalyticsListProps();
  };

  // Empty

  if (!countSelected) return null;

  // Active Filters

  const activeFilters = getActiveFilters(items);

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      <div className={styles.title}>
        {t('LISTING_TOOLBAR.FILTER_BY.CURRENT_FILTERS')}
      </div>

      {/* List */}
      <Scrollbar
        className={styles.scrollbar}
        autoHide={false}
        forceVisible="y"
      >

        <div className={styles.list}>
          {
            activeFilters?.map((item) => {
              const {
                code: parentCode,
                name: parentName,
                values
              } = item;

              return (
                <div
                  key={`filter-${parentCode}`}
                  className={styles.filter}
                >

                  {/* Name */}
                  <div className={styles.name}>
                    {parentName}
                  </div>

                  {/* Values */}
                  <div className={styles.values}>
                    {
                      values.map((value) => {
                        const { code, name } = value;
                        const href = getHref(value, item);

                        return (
                          <Link
                            key={`option-${code}`}
                            href={href}
                            passHref
                          >
                            <Button
                              className={styles.value}
                              contentClassName={styles.content}
                              tag="a"
                              variant="empty"
                              onClick={() => {
                                handleFilterClick(parentName, name);
                              }}
                            >
                              <div className={styles.icon}>
                                {ClearIcon}
                              </div>
                              <div className={styles.label}>
                                {name}
                              </div>
                            </Button>
                          </Link>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </Scrollbar>

      {/* Clear */}
      <div className={styles.clear}>
        <Button
          className={styles.clearBtn}
          contentClassName={styles.clearBtnContent}
          variant="empty"
          onClick={clearFilters}
        >
          <div className={styles.clearBtnIcon}>
            {ClearIcon}
          </div>
          {t('LISTING_TOOLBAR.FILTER_BY.CLEAR_FILTERS')}
        </Button>
      </div>

    </div>
  );
};

// Components

const ClearIcon = <Icon size={16} strokeWidth={2} name="x" className={styles.clearIcon} />;

// Helpers

const getActiveFilters = (filters: IListingFilter[]): IListingFilter[] => {
  return filters.reduce((acc, filter) => {
    const { countSelected, values } = filter;

    if (countSelected > 0) {
      acc.push({
        ...filter,
        values: values.filter(({ isSelected }) => isSelected)
      });
    }

    return acc;
  }, [] as IListingFilter[]);
};

// Export

export default ActiveFiltersList;
