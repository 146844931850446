import React from 'react';
import { useTranslation } from 'next-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { Icon, Input } from 'components/ui';
import { isKeyboardEvent } from 'helpers/EventHelpers';
import { isObjectEmpty } from 'helpers/ObjectHelpers';
import { isPlaceQueryValid, parsePlaceDetails } from 'helpers/PlacesHelpers';
import { stall } from 'helpers/utils';
import { useStreetAutocomplete } from 'hooks/useStreetAutocomplete';

import { FIELDS } from './AddressFormConfig';

import AutocompleteResults from './AddressStreetAutocompleteResults';

import styles from './AddressStreetAutocomplete.module.scss';

const STREET_INPUT_CLASS_NAME = 'autocomplete-street-input';

const {
  IS_PLACE_VALID, PLACE_ID, STREET, NUMBER, CITY, PROVINCE, GEOLOCATION, DRAG_CENTER_GEOLOCATION
} = FIELDS;

const AddressStreetAutocomplete = (props) => {
  const {
    form,
    editMode,
    onEnterKeyDown = () => {},
    onLoading = () => {},
    onInvalidNumber = () => {},
  } = props;

  // Props

  const { [STREET]: initialStreet } = form?.values || {};

  // Refs

  const activeStreet = React.useRef(initialStreet);
  const streetInputRef = React.useRef(null);

  const resultsRef = useOnclickOutside(() => {
    handleStreetReset();
    hideResults();
  }, { ignoreClass: STREET_INPUT_CLASS_NAME });

  // Hooks

  const { t } = useTranslation();

  const [areResultsVisible, setResultsVisible] = React.useState(false);

  // --- Street Autocomplete hook

  const {
    setQuery, debouncedSetQuery,
    setSearchParams, debouncedSetParams,
    searchParams,
    placeDetails, isPlaceDetailsLoading,
    placeSuggestions, arePlaceSuggestionsLoading,
  } = useStreetAutocomplete({ form });

  // Effects

  // --- Handle initial street value

  React.useEffect(() => {
    if (initialStreet) {
      setQuery(initialStreet);
      form?.setFieldTouched(STREET, true, true);
    }
  }, []);

  // --- Handle place details update

  React.useEffect(() => {
    onLoading(isPlaceDetailsLoading);
    if (!isPlaceDetailsLoading) {
      handlePlaceDetailsUpdate(placeDetails);
    }
  }, [placeDetails, isPlaceDetailsLoading]);

  // Handlers

  // --- Results

  const showResults = React.useCallback(() => setResultsVisible(true), []);
  const hideResults = React.useCallback(() => setResultsVisible(false), []);

  // --- Street

  const onStreetChange = async (evt) => {
    const { value: street } = evt.target;

    // Clear previous results
    setQuery(null);
    setSearchParams(null);

    debouncedSetQuery(street);

    form?.setFieldValue(STREET, street);

    // Delay setFieldTouched to prevent false invalid
    await stall(0);

    form?.setFieldTouched(STREET, true, true);

    if (street === '') {
      form?.setFieldValue(NUMBER, '');
      form?.setFieldValue(PROVINCE, '');
      form?.setFieldValue(CITY, '');
      form?.setFieldValue(GEOLOCATION, '');
      form?.setFieldValue(DRAG_CENTER_GEOLOCATION, '');
      form?.setFieldValue(IS_PLACE_VALID, false);
      activeStreet.current = '';
    }
  };

  // --- Number

  const onNumberChange = async (evt) => {
    if (!evt) return;

    const { value: number } = evt.target;

    const {
      [STREET]: street,
      [PROVINCE]: province = '',
      [CITY]: city = '',
      [GEOLOCATION]: geolocation = {}
    } = form?.values || {};

    const { lat, lng } = geolocation || {};

    form?.setFieldValue(IS_PLACE_VALID, false);
    form?.setFieldValue(PLACE_ID, null);
    form?.setFieldValue(NUMBER, number);

    // Delay setFieldTouched to prevent false invalid

    await stall(0);

    onInvalidNumber(false);
    form?.setFieldTouched(NUMBER, true, true);

    debouncedSetParams({
      street, number, province, city, lat, lng
    });
  };

  // --- Results

  const onPlaceSelected = (place, e) => {
    const { placeId, label } = place;

    form?.setFieldValue(NUMBER, '');
    form?.setFieldTouched(NUMBER, false);

    activeStreet.current = label;

    setSearchParams({ id: placeId });

    if (!isKeyboardEvent(e)) {
      hideResults();
    }
  };

  // Helpers

  const handleStreetReset = () => {
    const prev = activeStreet.current;
    setQuery(prev);
    form?.setFieldValue(STREET, prev);
    form?.setFieldTouched(STREET, true, true);
  };

  const handlePlaceDetailsUpdate = async (details) => {
    if (isObjectEmpty(details, true)) {
      form?.setFieldValue(PLACE_ID, null);
      form?.setFieldValue(IS_PLACE_VALID, false);
      handleInvalidNumber();
      return;
    }

    if (!details) {
      return;
    }

    const {
      id, street, number, city, province, lat, lng
    } = parsePlaceDetails(details);

    form?.setFieldValue(IS_PLACE_VALID, true);
    form?.setFieldValue(PLACE_ID, id);
    form?.setFieldValue(STREET, street);
    form?.setFieldValue(NUMBER, searchParams.number || number);
    form?.setFieldValue(PROVINCE, province);
    form?.setFieldValue(CITY, city);
    form?.setFieldValue(GEOLOCATION, { lat, lng });
    form?.setFieldValue(DRAG_CENTER_GEOLOCATION, { lat, lng });

    activeStreet.current = street;

    await stall(0);

    form?.validateForm();
  };

  const handleInvalidNumber = async () => {
    onInvalidNumber(true);

    await stall(0);

    form?.setFieldTouched(NUMBER, true, true);

    Notifications.showError(
      t('ERRORS.DEFAULT_TOAST'),
      { autoClose: 2000, toastId: 'addressInvalidError' }
    );
  };

  return (
    <>
      {/* Street */}
      <Input
        ref={streetInputRef}
        className={classnames(styles.streetInput, STREET_INPUT_CLASS_NAME)}
        inputClassName={styles.input}
        name={STREET}
        autoComplete="off"
        disabled={editMode}
        placeholder={t('ADDRESS.STREET_PLACEHOLDER')}
        value={form?.values[STREET]}
        startIcon={<Icon className={styles.streetInputIcon} name="map-pin" />}
        onClick={showResults}
        onFocus={showResults}
        onBlur={() => {
          form?.setFieldTouched(STREET, true, true);
        }}
        onChange={onStreetChange}
        onEnter={onEnterKeyDown}
        data-testid="address-form.search.street"
      />

      {/* Results */}
      <AutocompleteResults
        ref={resultsRef}
        isLoading={arePlaceSuggestionsLoading}
        isVisible={
          areResultsVisible
          && isPlaceQueryValid(form?.values[STREET])
          && form?.touched[STREET]
        }
        results={placeSuggestions}
        selectedResult={searchParams?.id}
        onPlaceSelected={onPlaceSelected}
      />

      {/* Number */}
      <Input
        className={styles.streetNumberInput}
        name={NUMBER}
        autoComplete="off"
        disabled={editMode}
        placeholder={t('ADDRESS.STREET_NUMBER')}
        value={form?.values[NUMBER]}
        onFocus={() => {
          hideResults();
          handleStreetReset();
        }}
        onChange={onNumberChange}
        onEnter={onEnterKeyDown}
        data-testid="address-form.search.number"
      />
    </>
  );
};

export default AddressStreetAutocomplete;
