import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'filter_events';

enum Actions {
  APPLY = 'apply',
  REMOVE = 'remove',
  CLEAR = 'clear',
  CLEAR_ALL = 'clear_all',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function applyFilter(filter: string, option: string) {
  push(Actions.APPLY, null, {
    filter_name: filter,
    filter_value: option
  });
}

export function removeFilter(filter: string, option: string) {
  push(Actions.REMOVE, null, {
    filter_name: filter,
    filter_value: option
  });
}

export function clearFilter(filter: string) {
  push(Actions.CLEAR, null, {
    filter_name: filter,
  });
}

export function clearAllFilters() {
  push(Actions.CLEAR_ALL, null);
}
