import React from 'react';

import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS, WINDOW_KEYS } from 'constants/storage';
import { isClient } from 'helpers/BrowserHelpers';
import { getWindowVariable, setWindowFunction, setWindowVariable } from 'helpers/WindowHelpers';

export function useGlobalVariables() {

  const initialize = React.useCallback(() => {
    if (isClient()) {
      setWindowVariable(WINDOW_KEYS.COOKIE_POLICY, {});
      setWindowVariable(WINDOW_KEYS.FUNCTION, {});

      // Local Feature flags
      setFunction('enableFeature', (featureKey: string) => {
        const features = LocalStorage.get(LOCAL_STORAGE_KEYS.FEATURE_FLAGS) || {};
        LocalStorage.set(LOCAL_STORAGE_KEYS.FEATURE_FLAGS, { ...features, [featureKey]: true });
      });

      setFunction('disableFeature', (featureKey: string) => {
        const features = LocalStorage.get(LOCAL_STORAGE_KEYS.FEATURE_FLAGS) || {};
        if (!features[featureKey]) return;
        LocalStorage.set(LOCAL_STORAGE_KEYS.FEATURE_FLAGS, { ...features, [featureKey]: false });
      });

      setFunction('clearFeatureFlags', () => {
        LocalStorage.set(LOCAL_STORAGE_KEYS.FEATURE_FLAGS, {});
      });

      setFunction('viewFeatureFlags', () => {
        const features = LocalStorage.get(LOCAL_STORAGE_KEYS.FEATURE_FLAGS) || {};
        const featuresArray = Object.keys(features).map((key) => {
          return { feature: key, status: features[key] };
        });
        console.table(featuresArray, ['feature', 'status']);
      });
    }
  }, []);

  // Cookie Policy

  const setCookiePolicy = (policy: any) => setWindowVariable(WINDOW_KEYS.COOKIE_POLICY, policy);
  const getCookiePolicy = () => getWindowVariable(WINDOW_KEYS.COOKIE_POLICY);

  // Function

  const setFunction = (key: string, callback: (...args: any[]) => void) => setWindowFunction(key, callback);

  // Exports

  return {
    initialize,
    setCookiePolicy,
    getCookiePolicy,
    setFunction,
  };
}
