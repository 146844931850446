import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { ModalActions, Stepper } from 'components/ui';
import { ERROR_CODES } from 'constants/errors';
import * as AddressHelpers from 'helpers/AddressHelpers';
import * as FormHelpers from 'helpers/FormHelpers';
import { isObjectEmpty } from 'helpers/ObjectHelpers';
import { joinValues } from 'helpers/StringHelpers';
import { useAddressMutation } from 'hooks/data/useAddressMutation';
import { useStepper } from 'hooks/useStepper';

import { FIELDS, INITIAL_VALUES, VALIDATIONS } from './AddressFormConfig';
import AddressFormDetails from './AddressFormDetails';
import AddressFormGeolocation from './AddressFormGeolocation';
import AddressFormStreet from './AddressFormStreet';

import styles from './AddressAddForm.module.scss';

export const STEP_IDS = {
  STREET: 'STREET',
  GEOLOCATION: 'GEOLOCATION',
  DETAILS: 'DETAILS',
};

const {
  IS_PLACE_VALID,
  STREET,
  NUMBER,
  STREET_DETAILS,
  CITY,
  PROVINCE,
  GEOLOCATION,
  CONTACT_NAME,
  CONTACT_PHONE
} = FIELDS;

const AddressAddForm = (props) => {

  const {
    className,
    initialValues = {},
    isSubmitLoading,
    withoutAddressStep,
    onSuccess = () => {},
    onError = () => {},
    onBack = () => {},
    onEditStreetClick = () => {},
    onInit = () => {},
  } = props;

  // Refs

  const formRef = React.useRef(null);
  const controllerRef = React.useRef({
    reset: () => {},
  });

  // Hooks

  const { t } = useTranslation();

  const [isInvalidAddressNumber, setInvalidAddressNumber] = React.useState(false);

  const {
    activeStep, next, prev, goTo
  } = useStepper(
    STEP_IDS,
    withoutAddressStep
      ? STEP_IDS.GEOLOCATION
      : null
  );

  const { isLoading: isMutationLoading, add: addAddress, check: checkAddress } = useAddressMutation();

  // Stepper Props

  const steps = [
    {
      id: STEP_IDS.STREET,
      component: AddressFormStreet,
      componentProps: {
        className: styles.streetStep,
        titleClassName: styles.streetStepTitle,
        onContinue: next,
        onInvalidNumber: setInvalidAddressNumber,
        renderActions: (form, params) => {
          const {
            [IS_PLACE_VALID]: isPlaceValid,
            [STREET]: street,
            [NUMBER]: number
          } = form?.values || {};

          const { isLoading, withPadding } = params || {};

          return (
            <ModalActions
              className={styles.actions}
              btnClassName={styles.submitButton}
              withPadding={withPadding}
              withBorder
              primary={{
                disabled: !isPlaceValid || !(street && number),
                loading: isLoading,
                onClick: () => {
                  next();
                },
                dataTestId: 'address-form.street.submit'
              }}
            />
          );
        }
      }
    },
    {
      id: STEP_IDS.GEOLOCATION,
      component: AddressFormGeolocation,
      componentProps: {
        renderActions: () => {
          return (
            <ModalActions
              className={styles.actions}
              btnClassName={styles.submitButton}
              primary={{
                onClick: () => {
                  next();
                },
                dataTestId: 'address-form.geo.submit'
              }}
              secondary={{
                onClick: withoutAddressStep
                  ? onBack
                  : () => {
                    prev();
                  }
              }}
            />
          );
        }
      }
    },
    {
      id: STEP_IDS.DETAILS,
      component: AddressFormDetails,
      componentProps: {
        canAddAdditionalDetails: true,
        canEditStreet: true,
        onEditStreetClick: () => {
          if (withoutAddressStep) {
            onEditStreetClick();
          } else {
            goTo(STEP_IDS.STREET);
          }
        },
        renderActions: (formikProps) => {
          const { handleSubmit, isSubmitting, isValid } = formikProps;
          return (
            <ModalActions
              className={styles.actions}
              btnClassName={styles.submitButton}
              primary={{
                text: t('ADDRESS.SAVE_ADDRESS'),
                disabled: !isValid,
                loading: isMutationLoading || isSubmitting || isSubmitLoading,
                onClick: () => {
                  handleSubmit();
                },
                dataTestId: 'address-form-add.details.submit'
              }}
              secondary={{
                onClick: prev
              }}
            />
          );
        }
      }
    }
  ];

  // Formik Props

  const initialFormValues = {
    ...INITIAL_VALUES,
    ...initialValues
  };

  const validationSchema = React.useMemo(() => yup.object().shape({
    [IS_PLACE_VALID]: VALIDATIONS[IS_PLACE_VALID]({ t }),
    [STREET_DETAILS]: VALIDATIONS[STREET_DETAILS]({ t }),
    [STREET]: VALIDATIONS[STREET]({ t }),
    [NUMBER]: VALIDATIONS[NUMBER]({ t, isInvalid: isInvalidAddressNumber }),
    [CONTACT_NAME]: VALIDATIONS[CONTACT_NAME]({ t }),
    [CONTACT_PHONE]: VALIDATIONS[CONTACT_PHONE]({ t }),
  }), [t, isInvalidAddressNumber]);

  // Effects

  React.useEffect(() => {
    controllerRef.current.reset = () => {
      goTo(withoutAddressStep ? STEP_IDS.GEOLOCATION : STEP_IDS.STREET);
    };
  }, [withoutAddressStep, goTo]);

  React.useEffect(() => {
    onInit(controllerRef.current);
  }, [controllerRef?.current]);

  React.useEffect(() => {
    if (!formRef?.current || isObjectEmpty(initialValues)) return;
    Object.keys((initialValues)).forEach((key) => {
      if (initialValues[key] && !formRef.current.values[key]) {
        formRef.current.setFieldValue(key, initialValues[key]);
      }
    });
  }, [initialValues]);

  // Handlers

  const onAddAddress = (values, actions) => {
    const { setSubmitting } = actions;

    const {
      [IS_PLACE_VALID]: isPlaceValid = false,
      [STREET]: street = '',
      [NUMBER]: number = '',
      [STREET_DETAILS]: streetDetails = '',
      [PROVINCE]: province = '',
      [CITY]: city = '',
      [GEOLOCATION]: geolocation = '',
      [CONTACT_NAME]: contactName = '',
      [CONTACT_PHONE]: contactPhoneNumber = '',
    } = values;

    if (!isPlaceValid) return;

    addAddress({
      address: {
        contactName,
        contactPhonenumber: contactPhoneNumber,
        streetAddress: joinValues([street, number, streetDetails]),
        province,
        city,
        geoLocation: {
          lat: geolocation?.lat?.toString(),
          long: geolocation?.lng?.toString()
        },
        flowVersion: 2,
      },
      onSuccess: (data) => {
        onSuccess(values, data);
      },
      onError: (err) => {
        if (err?.response?.status === ERROR_CODES.VALIDATION_ERROR) {
          FormHelpers.handleValidationError(err?.response?.data, actions);
        } else {
          onError(values);
          console.error('Error while submitting address form', err);
        }
      },
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions;

    if (!AddressHelpers.validateAddressFormat(values, actions, t)) {
      return;
    }

    setSubmitting(true);

    const {
      [STREET]: street = '',
      [NUMBER]: number = '',
      [PROVINCE]: province = '',
      [CITY]: city = '',
      [GEOLOCATION]: geolocation = '',
    } = values;

    checkAddress({
      address: {
        streetAddress: `${street}, ${number}`,
        province,
        city,
        geoLocation: {
          lat: geolocation?.lat?.toString(),
          long: geolocation?.lng?.toString()
        },
      },
      onSuccess: (data) => {
        const { available: isAddressAvailable } = data || {};

        if (!isAddressAvailable) {
          Notifications.showError(t('NOTIFICATIONS.INVALID_DELIVERY_ADDRESS'));
          setSubmitting(false);
          return;
        }

        onAddAddress(values, actions);
      }
    });
  };

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <Formik
        innerRef={formRef}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          return (
            <Form
              className={styles.form}
            >
              <Stepper
                form={formikProps}
                steps={steps}
                activeStep={activeStep}
                className={styles.stepper}
                contentClassName={styles.stepperContent}
                persist={false}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

// Prop Types

AddressAddForm.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  initialValues: PropTypes.shape({}),
  isSubmitLoading: PropTypes.bool,
  withoutAddressStep: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onBack: PropTypes.func,
  onEditStreetClick: PropTypes.func
};

// Export

export default AddressAddForm;
