import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Markdown } from 'components/ui';

import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import styles from './RecipeDetailsTips.module.scss';

interface Props {
  className?: string,
  tips: any
}

const RecipeDetailsTips = (props: Props) => {

  const {
    className,
    tips,
  } = props;

  const {
    text,
  } = tips || {};

  // Hooks

  const { t } = useTranslation();

  // Empty

  if (!text) {
    return null;
  }

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      contentClassName={styles.content}
      title={t('recipes:RECIPE_DETAILS.TIPS.TITLE')}
    >

      {/* Text */}
      <Markdown
        className={styles.text}
        text={text}
      />
    </RecipeDetailsSection>
  );
};

export default RecipeDetailsTips;
