import type { Nullable } from 'types/utility';

export type IContentfulRichText = {
  json: any
}

export type IContentfulImage = {
  url: string,
  height?: number,
  width?: number,
  fileName?: string
}

export type IContentfulSys = {
  firstPublishedAt?: string,
  publishedAt?: string,
  id?: string
}

export type IContentfulCollection<T> = {
  items: T[]
}

/* Careers */

export type ICareersPageCollection = IContentfulCollection<ICareersPageEntity>;

export type ICareersPageEntity = {
  sys: IContentfulSys,
  seoDescription?: string,
  seoTitle?: string,
  title?: IContentfulRichText,
  description?: IContentfulRichText,
  image?: IContentfulImage,
  statistics: {label: string, value: string, description: string}[],
  statisticsTitle?: string,
  videoTitle?: string,
  videoUrl?: string,
  carouselTitle?: string,
  carouselImagesCollection?: IContentfulCollection<IContentfulImage>,
  block1Title?: IContentfulRichText,
  block1Description?: IContentfulRichText,
  block1Image?: IContentfulImage,
  block2Title?: IContentfulRichText,
  block2Description?: IContentfulRichText,
  block2Image?: IContentfulImage,
  block2ImagesCollection?: IContentfulCollection<IContentfulImage>,
  benefits?: {title: string, description: string}[],
  benefitsTitle?: IContentfulRichText,
  jobListTitle?: IContentfulRichText,
  jobListDescription?: IContentfulRichText,
  jobsCollection?: ICareersJobCollection
}

export type ICareersPage = Omit<ICareersPageEntity, 'carouselImagesCollection' | 'block2ImageCollection' | 'sys' | 'jobsCollection'> & {
  carouselImages: IContentfulImage[],
  block2Images: IContentfulImage[],
  jobs?: ICareersJob[],
  createdAt: string,
  updatedAt: string
}

export type ICareersJobCollection = IContentfulCollection<ICareersJobEntity>

export type ICareersJobEntity = {
  sys: IContentfulSys,
  title: string,
  description?: string,
  url: string
}

export type ICareersJob = Omit<ICareersJobEntity, 'sys'> & {
  createdAt: string,
  updatedAt: string
}

// --- Landing pages

// Names includes 'Contentful' because we will most likely have Strapi landing pages as well

export enum ContentfulLandingPageType {
  Alpha = 'alpha',
  Beta = 'beta',
  Unknown = 'unknown'
}

export enum ContentfulSharedTemplateBlocks {
  BannerBlock = 'SharedTemplateBannerBlock',
  MediaBlock = 'SharedTemplateMediaBlock',
  ProductsBlock = 'SharedTemplateProductsBlock',
  TextBlock = 'SharedTemplateTextBlock',
}

export enum ContentfulAlphaTemplateBlocks {
  BoxesBlock = 'AlphaTemplateBoxesBlock',
  HighlightBlock = 'AlphaTemplateHighlightBlock',
}

export enum ContentfulBetaTemplateBlocks {
  DefaultBlock = 'BetaTemplateDefaultBlock',
}

export type ContentfulBlock =
  | ContentfulSharedTemplateBlocks
  | ContentfulAlphaTemplateBlocks
  | ContentfulBetaTemplateBlocks;

export interface IContentfulAlphaLandingPageResponse {
  alphaTemplatePageCollection: {
    __typename?: Nullable<string>,
    items: [IContentfulAlphaLandingPageRaw]
  }
}

export interface IContentfulAlphaLandingPageRaw {
  sys: IContentfulSys,
  slug: string,
  displayTitle: string,
  seoDescription?: string,
  seoTitle?: string,
  blocksCollection: IContentfulCollection<any>,
  hasDirectLink?: boolean
}

export interface IContentfulBetaLandingPageResponse {
  betaTemplatePageCollection: {
    __typename?: Nullable<string>,
    items: [IContentfulBetaLandingPageRaw]
  }
}

export interface IContentfulBetaLandingPageRaw {
  sys: IContentfulSys,
  slug: string,
  displayTitle?: string,
  seoTitle?: string,
  seoDescription?: string,
  footerTitle: IContentfulRichText,
  footerRegulation: IContentfulRichText,
  footerDisclaimer: IContentfulRichText,
  footerButtonLink: string,
  footerButtonText: string,
  blocksCollection: IContentfulCollection<any>,
  hasDirectLink?: boolean
}

export type IContentfulLandingPageResponse =
  | IContentfulAlphaLandingPageResponse
  | IContentfulBetaLandingPageResponse

export interface IContentfulAlphaLandingPage {
  createdAt?: string,
  updatedAt?: string,
  slug?: string,
  title?: string,
  seoTitle?: string,
  seoDescription?: string,
  hasDirectLink?: boolean,
  blocks: any[],
  __typename: ContentfulLandingPageType
}

export interface IContentfulBetaLandingPage {
  createdAt?: string,
  updatedAt?: string,
  slug?: string,
  title?: string,
  seoTitle?: string,
  seoDescription?: string,
  hasDirectLink?: boolean,
  blocks: any[],
  footer: {
    title: Nullable<IContentfulRichText>,
    button: {
      link: string,
      text: string
    },
    disclaimer: Nullable<IContentfulRichText>,
    regulation: Nullable<IContentfulRichText>
  },
  __typename: ContentfulLandingPageType
}

// Blocks

export type IContentfulLandingPageBlocks = any[]; // TODO add types
