import React from 'react';
import { useTranslation } from 'next-i18next';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

import { Button, Icon, Image } from 'components/ui';
import { CONFIG } from 'constants/config';
import { IMAGES } from 'constants/images';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { useLayoutDispatch } from 'context/LayoutContext';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useScheduledFeature } from 'hooks/useScheduledFeature';

import styles from './OpenInAppBanner.module.scss';

const VERSION = '0.0.2';
const INTERVAL = 60 * 24 * 7;

interface Props {
  link?: string,
  //
  offset?: number,
  smOffset?: number,
  mdOffset?: number,
  lgOffset?: number
}

const OpenInAppBanner = (props: Props) => {
  const {
    link = CONFIG.DEFAULT_DYNAMIC_LINK,
    //
    offset,
    smOffset,
    mdOffset,
    lgOffset
  } = props;

  // Hooks

  const { t } = useTranslation();

  const isSm = useBreakpoint('sm', 'down');
  const isMd = useBreakpoint('md', 'down');
  const isLg = useBreakpoint('lg', 'down');

  const { isVisible, markAsSeen } = useScheduledFeature(LOCAL_STORAGE_KEYS.OPEN_IN_APP_BANNER, {
    version: VERSION,
    interval: INTERVAL,
    isEnabled: isMobile && (isAndroid || isIOS),
  });

  const { setOpenInAppBannerVisibility } = useLayoutDispatch();

  // Effects

  React.useEffect(() => {
    setOpenInAppBannerVisibility(isVisible);
  }, [isVisible]);

  // Empty

  if (!isVisible) return null;

  // Render

  return (
    <div
      className={styles.root}
      style={{
        ...(offset ? { bottom: `${offset}px` } : {}),
        ...(isSm && smOffset ? { bottom: `${smOffset}px` } : {}),
        ...(isMd && mdOffset ? { bottom: `${mdOffset}px` } : {}),
        ...(isLg && lgOffset ? { bottom: `${lgOffset}px` } : {}),
      }}
    >
      <Image
        src={IMAGES.BRANDING.MOBILE_APP_ICON}
        alt="Mobile App Icon"
        height={42}
        width={42}
        quality={100}
      />
      <div className={styles.textContent}>
        <div className={styles.title}>{t('OPEN_IN_APP_BANNER.TITLE')}</div>
        <div className={styles.subtitle}>{t('OPEN_IN_APP_BANNER.SUBTITLE')}</div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.openBtn}
          tag="a"
          href={link}
          rel="noopener noreferrer"
          variant="primary"
          size="medium"
          onClick={markAsSeen}
          aria-label="Close"
        >
          {t('ACTIONS.OPEN')}
        </Button>
        <Button
          className={styles.dismissBtn}
          size="small"
          icon={CloseIcon}
          onClick={markAsSeen}
        />
      </div>
    </div>
  );
};

const CloseIcon = <Icon name="x" size={20} className={styles.closeIcon} />;

export default OpenInAppBanner;
