import { LocalStorage } from 'common/Storage';
import { CONFIG } from 'constants/config';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';

import type {
  IAddress, IDeliveryInterval, IGeolocation, IOrderAddress, PartialAddress,
  TFunction
} from 'types';

// Types

export interface IGetAddressLabelParams {
  withCity: boolean,
  withProvince: boolean
}

export interface IValidateAddressParams {
  setFieldError: (key: string, value: string) => void
}

export function getPartialAddress() {
  try {
    return LocalStorage.get(LOCAL_STORAGE_KEYS.PARTIAL_ADDRESS);
  } catch (err) {
    console.error('Error while parsing partial address', err);
    return null;
  }
}

export function setPartialAddress(address: PartialAddress) {
  LocalStorage.set(LOCAL_STORAGE_KEYS.PARTIAL_ADDRESS, address);
}

export function deletePartialAddress() {
  if (LocalStorage.get(LOCAL_STORAGE_KEYS.PARTIAL_ADDRESS)) {
    LocalStorage.remove(LOCAL_STORAGE_KEYS.PARTIAL_ADDRESS);
  }
}

export function getAddressLabel(address?: IAddress | IOrderAddress, params?: IGetAddressLabelParams) {
  if (!address) return '';

  const {
    streetAddress, street = '', number = '', province, city
  } = address;
  const { withCity = true, withProvince = true } = params || {};

  // Non-partial address
  if (streetAddress) return joinValues([streetAddress, withCity ? city : '', withProvince ? province : '']);

  // Partial address
  return joinValues([street, number, withCity ? city : '', withProvince ? province : '']);
}

export function getAddressContactLabel(address?: IAddress) {
  if (!address) return '';

  const { contactName, contactPhonenumber } = address;
  return joinValues([contactName, contactPhonenumber], ' - ');
}

function joinValues(args: string[], separator = ', ') {
  return args?.filter((a: string) => a && a.trim && a.trim() !== '').join(separator);
}

export function validateAddressFormat(values: IAddress, actions: IValidateAddressParams, t: TFunction) {
  const { city, province } = values;
  const { setFieldError } = actions;

  if (city === '' || province === '') {
    setFieldError('street', t('VALIDATION_ERRORS.AUTOCOMPLETE_NOT_SELECTED'));
    setFieldError('number', '');
    return false;
  }

  setFieldError('street', '');
  setFieldError('number', '');
  return true;
}

export function isDefaultLocation(location: IGeolocation) {
  if (!location?.lat || !location?.lng) return false;
  const { DEFAULT_COORDINATES: { lat, lng } } = CONFIG.GOOGLE || {};
  if (Number(location.lat) !== lat || Number(location.lng) !== lng) return false;
  return true;
}

export function getIntervalLabel(interval: IDeliveryInterval) {
  const { dateLabel, interval: { since, until } } = interval || {};
  return `${dateLabel}, ${since} - ${until}`;
}

export function parseGeolocation(geolocation: IGeolocation) {
  const { lat, lng } = geolocation || {};

  if (!lat || !lng) return null;

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
}
