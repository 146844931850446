import React from 'react';

import { Skeleton } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { IMAGE_SIZE, RESPONSIVE_IMAGE_SIZE } from './ProductCheckoutCard';

import styles from './ProductCheckoutCard.module.scss';

const ProductCartCardSkeleton = () => {

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const imageSize = isBreakpoint
    ? RESPONSIVE_IMAGE_SIZE
    : IMAGE_SIZE;

  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <div className={styles.imageSkeletonWrapper}>
          <Skeleton height={imageSize} width={imageSize} className={styles.imageSkeleton} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentSkeletonWrapper}>
          <Skeleton count={4} height={12} width="100%" className={styles.contentSkeleton} />
        </div>
      </div>
    </div>
  );
};

export default ProductCartCardSkeleton;
