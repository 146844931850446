import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';
import { TRANSLATIONS } from 'constants/translations';

import styles from './RecipesEmptyState.module.scss';

interface Props {
  className?: string,
  children?: React.ReactNode,
  withRedirectButton?: boolean
}

const RecipesEmptyState = (props: Props) => {

  const { className, children, withRedirectButton } = props;
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.root, className)}>
      <ImageMessage
        className={styles.imageMessage}
        image={IMAGES.RECIPES.NO_RECIPES}
        imageProps={{
          width: 613,
          height: 215,
          alt: 'No recipes found'
        }}
        imageContainerClassName={styles.image}
        title={t('GENERAL.EMPTY_STATE', { ns: TRANSLATIONS.RECIPES })}
        titleClassName={styles.title}
      >
        {withRedirectButton ? (
          <Button
            variant="primary"
            tag="a"
            href={APP_ROUTES.RECIPES_LIST}
          >
            {t('GENERAL.EMPTY_STATE_CTA', { ns: TRANSLATIONS.RECIPES })}
          </Button>
        ) : null}
        {children}
      </ImageMessage>
    </div>
  );
};

export default RecipesEmptyState;
