import React from 'react';

import * as OrderHelpers from 'helpers/OrderHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';

import ProductDeliveryComment from '../ProductDeliveryComment/ProductDeliveryComment';

import type { IProductDetails, IProductVariant } from 'types';

interface Props {
  className?: string,
  product: IProductDetails | IProductVariant
}

const ProductDetailsDeliveryComment = (props: Props) => {
  const {
    className,
    product
  } = props;

  // Hooks

  const { data: order } = useActiveOrder();

  // Props

  const orderItem = OrderHelpers.getOrderItem(order, product);

  // Empty

  if (!orderItem) return null;

  // Render

  return (
    <ProductDeliveryComment
      className={className}
      orderItem={orderItem}
    />
  );
};

export default ProductDetailsDeliveryComment;
