import { pushConfig } from './AnalyticsHelpers';

// Helpers

enum Events {
  SET_USER_ID = 'set_user_id',
  SET_ACCOUNT_TYPE = 'set_account_type'
}

function push(event: Events, eventName: string, payload: Record<string, any>) {
  pushConfig(event, eventName, payload);
}

// Events

// --- Set User ID

export function setUserId(userId: number) {
  push(Events.SET_USER_ID, 'Set User ID', { user_id: `${userId}` });
}

// --- Set Account Type

export function setAccountType(accountType: any) {
  push(Events.SET_ACCOUNT_TYPE, 'Set Account Type', { account_type: accountType });
}
