import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Button, Image, Link, Scrollbar, Skeleton
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { APP_ROUTES } from 'constants/routes';
import { useCategories } from 'hooks/data/useCategories';
import { useAnalytics } from 'hooks/useAnalytics';

import styles from './CategoriesDesktop.module.scss';

const Categories = () => {

  const router = useRouter();
  const analytics = useAnalytics();

  const { t } = useTranslation('common');
  const { data: categories } = useCategories();

  return (
    <div className={styles.root}>
      <Scrollbar className={styles.scrollbar} autoHide={false} forceVisible="y">
        <div className={styles.title} tag="div">{t('CATEGORIES.TITLE')}</div>
        <ul className={styles.list}>
          {
            categories?.map(({
              name, slug, children, iconPng: icon,
            }) => {
              let activeChildIndex;

              const parts = router.asPath.split('/');

              const hasActiveChild = children?.find((subcategory, index) => {
                const { slug: subSlug } = subcategory;
                const isChildActive = parts.find((part) => part === subSlug);
                if (isChildActive) {
                  activeChildIndex = index;
                }
                return isChildActive;
              });

              const isActive = parts.find((part) => part === slug) && !hasActiveChild;

              return (
                (
                  <li className={classnames(styles.listItemWrapper, { [styles.active]: isActive })} key={slug}>
                    <Link href={`${APP_ROUTES.CATEGORIES}/${slug}`} prefetch={CONFIG.PREFETCH_LINKS}>
                      <Button
                        tag="a"
                        className={styles.listItem}
                        onClick={() => {
                          analytics.selectCategory(name);
                        }}
                      >
                        <span className={styles.listItemIcon}>
                          <Image
                            src={icon}
                            width={26}
                            height={26}
                            quality="100"
                          />
                        </span>
                        <span className={styles.listItemText}>{name}</span>
                      </Button>
                    </Link>
                    {
                      (isActive || hasActiveChild) && children?.length > 0
                      && (
                        <ul className={styles.subList}>
                          {
                            children.map((subcategory, index) => {
                              const { name: subName, slug: subSlug } = subcategory;
                              const isSubActive = index === activeChildIndex;
                              return (
                                <li className={classnames(styles.subListItemWrapper, { [styles.active]: isSubActive })} key={subSlug}>
                                  <Link href={`${APP_ROUTES.CATEGORIES}/${slug}/${subSlug}`} prefetch={CONFIG.PREFETCH_LINKS}>
                                    <Button
                                      tag="a"
                                      className={styles.subListItem}
                                      onClick={() => {
                                        analytics.selectCategory(subName);
                                      }}
                                    >
                                      {subName}
                                    </Button>
                                  </Link>
                                </li>
                              );
                            })
                          }
                        </ul>
                      )
                    }
                  </li>
                )
              );
            })
          }
          {
            !categories && (
              <li className={styles.listSkeleton}>
                <Skeleton count={30} height={12} />
              </li>
            )
          }
        </ul>
      </Scrollbar>
    </div>
  );
};

export default Categories;
