import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'search_events';

enum Actions {
  SELECT_CATEGORY = 'select_category',
  OPEN_SEARCH = 'open_search',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function selectSearchResult(payload: {query: string, category: string}) {
  const { query, category } = payload || {};
  push(Actions.SELECT_CATEGORY, `${query} - ${category}`);
}

export function openSearch() {
  push(Actions.OPEN_SEARCH, null);
}
