import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import RecipeCarousel from '../../RecipeCarousel/RecipeCarousel';
import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import type { IRecipeCard } from 'types';

import styles from './RecipeDetailsSimilarRecipesCarousel.module.scss';

interface BreakpointParams {
  slidesPerView?: number,
  slidesPerGroup?: number,
  spaceBetween?: number
}

interface Props {
  className?: string,
  recipes: IRecipeCard[],
  breakpoints: Record<number, BreakpointParams>,
  onRecipeViewIngredientsClick?: (recipe: IRecipeCard, e: React.MouseEvent<HTMLButtonElement>) => void
}

const RecipeDetailsSimilarRecipesCarousel = (props: Props) => {

  const {
    className,
    recipes,
    breakpoints,
    onRecipeViewIngredientsClick
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Empty

  if (isArrayEmpty(recipes)) return null;

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      title={t('recipes:RECIPE_DETAILS.SIMILAR_RECIPES')}
    >
      <RecipeCarousel
        navigationButtonClassName={styles.navButton}
        //
        name={t('recipes:RECIPE_DETAILS.SIMILAR_RECIPES')}
        recipes={recipes}
        breakpoints={breakpoints}
        //
        onRecipeViewIngredientsClick={onRecipeViewIngredientsClick}
      />
    </RecipeDetailsSection>
  );
};

export default RecipeDetailsSimilarRecipesCarousel;
