import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { Alert, FormGroup } from 'components/ui';

import AddressGoogleMap from '../AddressGoogleMap/AddressGoogleMap';

import { FIELDS, MAX_DRAG_MAP_DISTANCE } from './AddressFormConfig';

import styles from './AddressFormGeolocation.module.scss';

const { PLACE_ID, GEOLOCATION, DRAG_CENTER_GEOLOCATION } = FIELDS;

const AddressFormGeolocation = (props) => {

  const {
    form,
    renderActions = () => {}
  } = props;

  const { t } = useTranslation();

  const {
    [PLACE_ID]: placeId,
    [GEOLOCATION]: geolocation,
    [DRAG_CENTER_GEOLOCATION]: dragCenterGeolocation
  } = form?.values || {};

  const onGeolocationChange = (updateGeolocation) => {
    form?.setFieldValue(GEOLOCATION, updateGeolocation);
  };

  return (
    <div className={styles.root}>

      {/* Field Groups */}
      <div className={styles.groups}>
        <FormGroup
          title={t('ADDRESS.DELIVERY_ADDRESS_LABEL')}
          className={styles.mapFormGroup}
          fieldsClassName={styles.mapFormGroupFields}
        >

          {/* Info */}
          <Alert
            className={styles.alert}
            variant="info"
            icon="map-pin"
            withCircledIcon
            description={t('ADDRESS.INFO.MOVE_PIN')}
          />

          {/* Map */}
          <AddressGoogleMap
            containerClassName={styles.googleMapContainer}
            className={styles.googleMap}
            form={form}
            placeId={placeId}
            pin={geolocation}
            center={geolocation}
            dragCenter={dragCenterGeolocation}
            maxDragDistance={MAX_DRAG_MAP_DISTANCE}
            onChange={onGeolocationChange}
          />

        </FormGroup>
      </div>

      {/* Actions */}
      <div className={styles.actions}>
        {renderActions(form)}
      </div>

    </div>
  );
};

AddressFormGeolocation.propTypes = {
  form: PropTypes.shape({}),
  renderActions: PropTypes.func
};

export default AddressFormGeolocation;
