import { pushEvent } from './AnalyticsHelpers';
import * as Einstein from './Einstein';

import type {
  EnhancedMetric, IOrderProduct, IProduct, IUser
} from 'types';

// Constants

enum Events {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  REMOVE_FROM_FAVORITES = 'remove_from_wishlist',
  SEARCH = 'search',
  SEARCH_AUTOSUGGEST = 'search_autosuggest',
  REPORT_WEB_VITAL_METRIC = 'report_web_vital_metric',
}

const LOGIN_METHOD = 'emag';
const SIGN_UP_METHOD = 'emag';

// Helpers

function pushCustomEvent(event: Events, payload: Record<string, any>) {
  pushEvent({
    event,
    ...payload
  });
}

// Events

// --- Login

export function login() {
  return pushCustomEvent(Events.LOGIN, {
    method: LOGIN_METHOD
  });
}

// --- Sign Up

export function signUp() {
  return pushCustomEvent(Events.SIGN_UP, {
    method: SIGN_UP_METHOD
  });
}

// --- Favorites

export function removeFromFavorites(item: IProduct | IOrderProduct) {
  const { sku } = item;
  const { price } = (item || {}) as IProduct;
  const { discountedUnitPrice } = (item || {}) as IOrderProduct;

  return pushCustomEvent(Events.REMOVE_FROM_FAVORITES, {
    action: Events.REMOVE_FROM_FAVORITES,
    item_id: sku || undefined,
    price: price || discountedUnitPrice || undefined,
  });
}

// --- Search

interface SearchEventPayload {
  user: IUser | null | undefined,
  term: string,
  results: number
}

export function search(payload: SearchEventPayload) {
  const { user, term, results } = payload;

  if (user) {
    Einstein.trackSearchView(user, term);
  }

  return pushCustomEvent(Events.SEARCH, {
    search_term: term,
    search_results: results
  });
}

// --- Search Autosuggest

interface SearchAutosuggestPayload {
  term: string,
  results: number,
  category: string
}

export function searchAutosuggest(payload: SearchAutosuggestPayload) {
  const { term, results, category } = payload;

  return pushCustomEvent(Events.SEARCH_AUTOSUGGEST, {
    search_term: term,
    search_results: results,
    search_category: category
  });
}

// --- Core Web Vitals

interface ReportWebVitalMetricPayload {
  metric: EnhancedMetric
}

export function reportWebVitalMetric(payload: ReportWebVitalMetricPayload) {
  const { metric } = payload;

  if (!metric) return;

  const {
    id,
    timestamp,
    deviceType,
    name,
    value,
    rating,
  } = metric || {};

  return pushCustomEvent(Events.REPORT_WEB_VITAL_METRIC, {
    id,
    timestamp: `${timestamp}`,
    metric: name,
    value: `${value}`,
    rating,
    deviceType,
  });
}
