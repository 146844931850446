import { PAGES_MAP } from 'constants/pages';
import { APP_ROUTES } from 'constants/routes';

import {
  FiltersType, getFiltersParams, getFiltersQueryString, setFiltersParams
} from './UrlFiltersParams';
import { getOrderByParams, getOrderByQueryString, setOrderByParams } from './UrlOrderByParams';
import { getPaginationParams, setPaginationParams } from './UrlPaginationParams';
import { DEFAULT_URL_SEPARATOR, getBaseSegments } from './UrlParamsHelpers';
import { getSearchParams } from './UrlSearchParams';

import type { FiltersOptions } from './UrlFiltersParams';

import { PageType } from 'types';
import type {
  IAcceleratedSalePageUrlParams,
  IAccountFreshclubUrlParams,
  ICategoryPageUrlParams,
  ICustomListingPageUrlParams,
  IPageUrlParams,
  IPageUrlParamsMap,
  IPromotionPageUrlParams,
  IRecipesByTagPageUrlParams,
  IRecipesListPageUrlParams,
  ISearchPageUrlParams
} from 'types';

export type UrlSegments = undefined | null | string | string[];

/*
  Set page params
*/

function setParams(params: Partial<IPageUrlParams>) {
  const {
    prefix, base, filters, orderBy, page, searchQuery
  } = params;
  let url = `${prefix}${base ? `/${base}` : ''}`;

  try {

    // Search Query
    if (searchQuery) {
      url = `${url}/search/${encodeURIComponent(searchQuery)}`;
    }

    // Filters
    if (Array.isArray(filters)) {
      const filtersString = filters.reduce((acc, filter) => {
        const [code, values = []] = filter || [];
        const selectedValues = typeof values === 'string' ? encodeURIComponent(values) : values?.sort().map((value) => encodeURIComponent(value));
        if (selectedValues.length > 0) {
          return `${acc}/${encodeURIComponent(code)}${DEFAULT_URL_SEPARATOR}${typeof selectedValues === 'string' ? selectedValues : selectedValues.join(',')}`;
        }
        return acc;
      }, '');
      if (filtersString) {
        url = `${url}/filters${filtersString}`;
      }
    }

    // Order By
    if (orderBy) {
      const { field, direction } = orderBy;
      url = `${url}/orderby${DEFAULT_URL_SEPARATOR}${encodeURIComponent(field)},${encodeURIComponent(direction)}`;
    }

    // Pagination
    if (page && page > 1) {
      url = `${url}/p${page}`;
    }

    return url;

  } catch (err) {
    console.error('Error while setting URL params', err);
  }

  return APP_ROUTES.HOME;
}

/*
  Get page params based on page type
*/

function getParams<T extends PageType>(segments: UrlSegments, pageType: T): IPageUrlParamsMap[T] {

  switch (pageType) {
    case PageType.Search: {
      return getSearchPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.AcceleratedSale: {
      return getAcceleratedSalePageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.NewProducts: {
      return getNewProductsPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.CustomListing: {
      return getCustomListingPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.Promotion: {
      return getPromotionPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.RecipesByTag: {
      return getRecipesByTagPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.RecipesList: {
      return getRecipesListPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.Category: {
      return getCategoryPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.AccountFreshclub: {
      return getAccountFreshclubPageParams(segments, pageType) as IPageUrlParamsMap[T];
    }
    case PageType.Unknown:
    default:
      return getDefaultPageParams(pageType) as IPageUrlParamsMap[T];
  }
}

/*
  Get pagination, orderBy, filters and search params
*/

function getCorePageParams(segments: UrlSegments, pageType: PageType, options?: {filters?: FiltersOptions}) {

  const { filters: filtersOptions } = options || {};

  // Pagination
  const { page, isPaginated } = getPaginationParams(segments);

  // Order by
  const { orderBy, isOrdered } = getOrderByParams(segments);

  // Filters
  const {
    filters, filtersCount, filtersIndexable, isFiltered,
  } = getFiltersParams(segments, { isPaginated, isOrdered }, filtersOptions);

  // Search
  const { searchQuery, hasSearchQuery } = getSearchParams(segments);

  return {
    // Pagination
    page,
    isPaginated,
    // Order by
    orderBy,
    isOrdered,
    // Filters
    filters,
    filtersCount,
    filtersIndexable,
    isFiltered,
    // Search
    searchQuery,
    hasSearchQuery,
    // Misc
    indexable: filtersIndexable && !orderBy && !isPaginated,
    isDefault: !isOrdered && !isFiltered && !hasSearchQuery,
    pageType
  };
}

// Category
function getCategoryPageParams(segments: UrlSegments, pageType: PageType.Category): ICategoryPageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  const identifier = baseSegments ? baseSegments[baseSegments.length - 1] : null;
  return {
    ...getCorePageParams(segments, pageType),
    base,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    identifier,
    category: null,
    isDefaultCategory: false
  };
}

// Search
function getSearchPageParams(segments: UrlSegments, pageType: PageType.Search): ISearchPageUrlParams {
  return {
    ...getCorePageParams(segments, pageType),
    prefix: PAGES_MAP[pageType].path,
    base: segments?.[0] || null,
    identifier: segments?.[0] || null,
    category: null,
    isDefaultCategory: false
  };
}

// Accelerated sale
const DEFAULT_ACCELERATED_SALE_CATEGORY = 'all';
function getAcceleratedSalePageParams(segments: UrlSegments, pageType: PageType.AcceleratedSale): IAcceleratedSalePageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  const category = baseSegments?.[0] || DEFAULT_ACCELERATED_SALE_CATEGORY;
  const isDefaultCategory = baseSegments?.[0] === DEFAULT_ACCELERATED_SALE_CATEGORY;
  const identifier = null;

  return {
    ...params,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    base,
    identifier,
    category,
    isDefaultCategory,
  };
}

// New Products
const DEFAULT_NEW_PRODUCTS_CATEGORY = 'all';
function getNewProductsPageParams(segments: UrlSegments, pageType: PageType.NewProducts): IAcceleratedSalePageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  const category = baseSegments?.[0] || DEFAULT_NEW_PRODUCTS_CATEGORY;
  const isDefaultCategory = baseSegments?.[0] === DEFAULT_NEW_PRODUCTS_CATEGORY;
  const identifier = null;

  return {
    ...params,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    base,
    identifier,
    category,
    isDefaultCategory,
  };
}

// Custom Listing
const DEFAULT_CUSTOM_LISTING_CATEGORY = 'all';
function getCustomListingPageParams(segments: UrlSegments, pageType: PageType.CustomListing): ICustomListingPageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  const category = baseSegments?.[1] || DEFAULT_CUSTOM_LISTING_CATEGORY;
  const isDefaultCategory = baseSegments?.[1] === DEFAULT_CUSTOM_LISTING_CATEGORY;
  const identifier = baseSegments?.[0] || null;

  return {
    ...params,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    base,
    identifier,
    category,
    isDefaultCategory,
  };
}

// Promotion
const DEFAULT_PROMOTION_CATEGORY = 'all';
function getPromotionPageParams(segments: UrlSegments, pageType: PageType.Promotion): IPromotionPageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  const category = baseSegments?.[1] || DEFAULT_PROMOTION_CATEGORY;
  const isDefaultCategory = baseSegments?.[1] === DEFAULT_PROMOTION_CATEGORY;
  const identifier = baseSegments?.[0] || null;

  return {
    ...params,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    base,
    identifier,
    category,
    isDefaultCategory,
  };
}

// Recipes list
function getRecipesListPageParams(segments: UrlSegments, pageType: PageType.RecipesList): IRecipesListPageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  return {
    ...params,
    prefix: params.isDefault ? PAGES_MAP[pageType].path : PAGES_MAP[pageType].filteredPath!,
    base: baseSegments.join('/'),
    identifier: baseSegments?.[0] || null,
    category: null,
    isDefaultCategory: false
  };
}

// Recipes by tag
function getRecipesByTagPageParams(segments: UrlSegments, pageType: PageType.RecipesByTag): IRecipesByTagPageUrlParams {
  const params = getCorePageParams(segments, pageType);
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  return {
    ...params,
    prefix: PAGES_MAP[pageType].path,
    base,
    identifier: baseSegments?.[0] || null,
    category: null,
    isDefaultCategory: false
  };
}

// Freshclub
function getAccountFreshclubPageParams(segments: UrlSegments, pageType: PageType.AccountFreshclub): IAccountFreshclubUrlParams {
  const params = getCorePageParams(segments, pageType, { filters: { type: FiltersType.Single } });
  const baseSegments = getBaseSegments(segments, params);
  const base = baseSegments.join('/');
  return {
    ...params,
    prefix: PAGES_MAP[pageType].path,
    base,
    identifier: baseSegments?.[0] || null,
    isDefaultCategory: false,
    category: null
  };
}

function getDefaultPageParams(pageType: PageType): IPageUrlParams {
  return {
    pageType,
    prefix: null,
    base: null,
    identifier: null,

    isDefaultCategory: false,
    category: null,

    page: 1,
    isPaginated: false,

    filters: null,
    isFiltered: false,
    filtersCount: 0,
    filtersIndexable: false,

    orderBy: null,
    isOrdered: false,

    searchQuery: null,
    hasSearchQuery: false,

    indexable: false,
    isDefault: true
  };
}

/* Export */
export const UrlParams = {
  getParams,
  setParams,
  // Pagination
  getPaginationParams,
  setPaginationParams,
  // Filters
  setFiltersParams,
  getFiltersQueryString,
  // OrderBy
  setOrderByParams,
  getOrderByQueryString,
  // Constants
  DEFAULT_PROMOTION_CATEGORY,
  DEFAULT_CUSTOM_LISTING_CATEGORY,
  DEFAULT_ACCELERATED_SALE_CATEGORY,
  DEFAULT_NEW_PRODUCTS_CATEGORY,
  DEFAULT_URL_SEPARATOR
};
