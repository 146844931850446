import React from 'react';
import { useTranslation } from 'next-i18next';

import {
  Button, Checkbox, Icon, Image, Scrollbar
} from 'components/ui';
import { useOrderReportProblemDispatchHelpers } from 'context/OrderReportProblemContext';
import { isEmptyObject } from 'helpers/utils';

import OrderProblemNavigation from './OrderProblemNavigation';
import OrderProblemSummary from './OrderProblemSummary';

import styles from './OrderProblemModal.module.scss';

const OrderProblemReturn = (props) => {
  const {
    order,
    problem = {},
    showCurrentStep,
    selection: initialSelection
  } = props;

  // Hooks

  const { t } = useTranslation();
  const [selection, setSelection] = React.useState({});
  const { showStep } = useOrderReportProblemDispatchHelpers();

  // Props

  const { itemSelectionTitle, itemSelectionGroups } = problem;
  const IMAGE_SIZE = 48;

  // Effects

  React.useEffect(() => {
    if (initialSelection) {
      setSelection(initialSelection);
    }
  }, [initialSelection]);

  // Handlers

  const onCheckboxChange = (id) => {
    if (selection?.[id]) {
      const draft = { ...selection };
      delete draft[id];
      setSelection(draft);
    } else {
      const group = itemSelectionGroups?.[0]?.group;
      const draft = { ...selection };
      draft[id] = [group];
      setSelection(draft);
    }
  };

  const showSummaryStep = () => {
    const orderItems = Object.keys(selection).map((key) => {
      const orderItemId = Number(key);
      const orderItem = order?.summaryItems?.find((oi) => oi.id === orderItemId);
      const {
        productName, productSlug, sku: productSku, image
      } = orderItem || {};
      return {
        orderItemId,
        productName,
        productSlug,
        productSku,
        image,
        groups: selection[key]
      };
    });
    showStep({
      title: OrderProblemNavigation,
      titleProps: {
        title: t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_TITLE'),
        onBackClick: () => showCurrentStep({ selection })
      },
      component: OrderProblemSummary,
      componentProps: { payload: { orderItems }, problem }
    });
  };

  // Render

  return (
    <div>
      <div className={styles.disclaimer}>
        <Icon name="info-circle" className={styles.disclaimerIcon} />
        <div>
          <div className={styles.disclaimerTitle}>{t('account:ORDER_HISTORY.REPORT_ISSUE_RETURN_DISCLAIMER_TITLE')}</div>
          <div className={styles.disclaimerSubtitle}>{t('account:ORDER_HISTORY.REPORT_ISSUE_RETURN_DISCLAIMER_SUBTITLE')}</div>
        </div>
      </div>
      <div className={`${styles.description} ${styles.withDisclaimer}`}>{itemSelectionTitle}</div>
      <div className={styles.list}>
        <Scrollbar className={styles.scrollbar} forceVisible="y" autoHide={false}>
          {order?.summaryItems?.map((item) => {
            const {
              id, productName, productSlug, image
            } = item;
            return (
              <div
                className={`${styles.listItem} ${styles.productListItem}`}
                key={productSlug}
              >
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    checked={!!(selection?.[id])}
                    onChange={() => onCheckboxChange(id)}
                    labelClassName={styles.checkboxLabel}
                    className={styles.checkbox}
                    label={(
                      <>
                        <div className={styles.imageContainer}>
                          <Image
                            src={image}
                            height={IMAGE_SIZE}
                            width={IMAGE_SIZE}
                          />
                        </div>
                        <div className={styles.nameContainer}>
                          {productName}
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            );
          })}
        </Scrollbar>
      </div>
      <div className={styles.footer}>
        <Button
          variant="primary"
          onClick={showSummaryStep}
          disabled={isEmptyObject(selection)}
        >
          {t('ACTIONS.CONTINUE')}
        </Button>
      </div>
    </div>
  );
};

export default OrderProblemReturn;
