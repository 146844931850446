import type { ValueOf } from 'types/utility';

// Step IDs

export const CHECKOUT_STEP_IDS = {
  SHIPPING_ADDRESS: 'shippingAddress',
  INTERVAL: 'interval',
  PRODUCTS: 'products',
  PAYMENT: 'payment',
  BILLING_ADDRESS: 'billing',
  TIPS: 'tips',
  LOYALTY: 'loyalty',
  AGE_CHECK: 'ageCheck',
} as const;

export type CheckoutStepIdType = ValueOf<typeof CHECKOUT_STEP_IDS>

// Step Icons

export const CHECKOUT_STEP_ICONS = {
  [CHECKOUT_STEP_IDS.SHIPPING_ADDRESS]: 'map-pin',
  [CHECKOUT_STEP_IDS.INTERVAL]: 'calendar-plus',
  [CHECKOUT_STEP_IDS.PRODUCTS]: 'list',
  [CHECKOUT_STEP_IDS.PAYMENT]: 'credit-card',
  [CHECKOUT_STEP_IDS.BILLING_ADDRESS]: 'building',
  [CHECKOUT_STEP_IDS.AGE_CHECK]: 'user',
  [CHECKOUT_STEP_IDS.TIPS]: 'courier'
} as const;

export type CheckoutStepIconType = ValueOf<typeof CHECKOUT_STEP_ICONS>

// Errors

export interface CheckoutValidationError {
  message: string,
  icon?: CheckoutStepIconType,
  step: CheckoutStepIdType
}

export interface CheckoutValidation {
  isOrderValid: boolean,
  //
  invalidSteps?: string[],
  errors?: CheckoutValidationError[]
}
