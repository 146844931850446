import React from 'react';
import { useTranslation } from 'next-i18next';
import useIntersectionObserver from '@react-hook/intersection-observer';
import classnames from 'classnames';

import { APP_ROUTES } from 'constants/routes';

import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Skeleton from '../Skeleton/Skeleton';

import type { IBreadcrumb, ValueOf } from 'types';

import styles from './Breadcrumbs.module.scss';

export const BREADCRUMBS_VARIANTS = {
  DEFAULT: 'default',
  UNDERLINED: 'underlined'
} as const;

interface Props {
  className?: string,
  containerClassName?: string,
  skeletonClassName?: string,
  //
  disableLast?: boolean,
  isLoading?: boolean,
  variant?: ValueOf<typeof BREADCRUMBS_VARIANTS>,
  items: IBreadcrumb[]
}

const Breadcrumbs = (props: Props) => {

  const {
    className,
    containerClassName,
    skeletonClassName,
    //
    disableLast = true,
    isLoading,
    variant = BREADCRUMBS_VARIANTS.DEFAULT,
    items,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);
  const startAnchorRef = React.useRef<HTMLDivElement>(null);
  const endAnchorRef = React.useRef<HTMLDivElement>(null);

  // Hooks

  const { t } = useTranslation();

  const { isIntersecting: isStartVisible } = useIntersectionObserver(startAnchorRef);
  const { isIntersecting: isEndVisible } = useIntersectionObserver(endAnchorRef);

  // Effects

  React.useEffect(() => {
    if (endAnchorRef.current && containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [items]);

  // Loading

  if (isLoading) {
    return (
      <div className={classnames(styles.root, className)}>
        <div className={classnames(styles.container, containerClassName)}>
          <Skeleton containerClassName={classnames(styles.skeleton, skeletonClassName)} />
        </div>
      </div>
    );
  }

  // Render

  return (

    <div
      className={classnames(
        styles.root,
        {
          [styles[variant]]: !!variant,
          [styles.disableLast]: disableLast
        },
        className,
      )}
    >

      {/* Container */}
      <div
        ref={containerRef}
        className={classnames(styles.container, containerClassName)}
      >

        {/* Start Anchor */}
        <div
          ref={startAnchorRef}
          className={styles.startAnchor}
        />

        {/* Items */}
        {
          items?.map((item, index) => {
            const { name, translate, href = APP_ROUTES.HOME } = item;
            const isLast = index === items.length - 1;
            const displayName = translate ? t(name) : name;
            return (
              <React.Fragment key={index + name}>
                {
                  !isLast
                    ? (
                      <>
                        <Link href={href} prefetch={false}>
                          <a className={styles.breadcrumb}>
                            {displayName}
                          </a>
                        </Link>
                        {ChevronRight}
                      </>
                    )
                    : (
                      <>
                        {
                          disableLast
                            ? (
                              <div className={`${styles.breadcrumb} ${styles.last}`}>
                                {displayName}
                              </div>
                            )
                            : (
                              <Link href={href} prefetch={false}>
                                <a className={styles.breadcrumb}>
                                  {displayName}
                                </a>
                              </Link>
                            )
                        }
                      </>
                    )
                }
              </React.Fragment>
            );
          })
        }

        {/* End Anchor */}
        <div
          ref={endAnchorRef}
          className={styles.endAnchor}
        />
      </div>

      {/* Fade */}
      <div
        className={classnames(
          styles.fade,
          {
            [styles.start]: !isStartVisible,
            [styles.end]: !isEndVisible
          }
        )}
      />

    </div>
  );
};

const ChevronRight = <Icon size={16} name="chevron-right" className={styles.chevron} />;

export default Breadcrumbs;
