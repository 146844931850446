import dynamic from 'next/dynamic';

// Cards

export * from './cards';

// Parts

export * from './parts';

// Components

export { default as GroupedProductList } from './GroupedProductList/GroupedProductList';
export { default as ProductDeleteToastBody } from './ProductDeleteToastBody/ProductDeleteToastBody';
export { default as ProductDeliveryComment } from './ProductDeliveryComment/ProductDeliveryComment';
export { default as ProductDetails } from './ProductDetails/ProductDetails';
export { default as ProductDetailsSkeleton } from './ProductDetails/ProductDetailsSkeleton';
export { default as ProductLink } from './ProductLink/ProductLink';
export { default as ProductList } from './ProductList/ProductList';
export { default as ProductListSkeleton } from './ProductList/ProductListSkeleton';
export { default as ProductsCarousel } from './ProductsCarousel/ProductsCarousel';
export { default as ProductsListingWidget } from './ProductsListingWidget/ProductsListingWidget';
export { default as ProductThumbnails } from './ProductThumbnails/ProductThumbnails';
export { default as ProductThumbnailsSkeleton } from './ProductThumbnails/ProductThumbnailsSkeleton';

export const SimilarProductsModal = dynamic(() => import('./SimilarProductsModal/SimilarProductsModal'), { ssr: false });
export const ProductQuantityVariantsModal = dynamic(() => import('./ProductQuantityVariantsModal/ProductQuantityVariantsModal'), { ssr: false });
