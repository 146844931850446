import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, FormGroup, Input } from 'components/ui';
import * as StringHelpers from 'helpers/StringHelpers';

import AddressGoogleMap from '../AddressGoogleMap/AddressGoogleMap';

import { FIELDS } from './AddressFormConfig';

import styles from './AddressFormDetails.module.scss';

const {
  PLACE_ID,
  STREET_ADDRESS,
  STREET,
  NUMBER,
  STREET_DETAILS,
  CITY,
  PROVINCE,
  GEOLOCATION,
  CONTACT_NAME,
  CONTACT_PHONE
} = FIELDS;

const AddressFormDetails = (props) => {

  const {
    form,
    canEditStreet,
    canAddAdditionalDetails,
    renderActions = () => {},
    onEditStreetClick = () => {}
  } = props;

  const { t } = useTranslation();

  const {
    [PLACE_ID]: placeId,
    [STREET]: street,
    [NUMBER]: number,
    [STREET_ADDRESS]: streetAddress,
    [CITY]: city,
    [PROVINCE]: province,
    [GEOLOCATION]: geolocation,
  } = form?.values || {};

  const streetLabel = streetAddress || StringHelpers.joinValues([street, number]);
  const cityLabel = StringHelpers.joinValues([city, province]);

  return (
    <div className={styles.root}>

      {/* Field Groups */}
      <div
        className={classnames(
          styles.groups,
        )}
      >

        {/* Map */}
        <FormGroup title={t('ADDRESS.DELIVERY_ADDRESS_LABEL')}>
          <div className={styles.map}>
            <AddressGoogleMap
              className={styles.googleMap}
              readonly
              form={form}
              placeId={placeId}
              pin={geolocation}
              center={geolocation}
            />
          </div>
        </FormGroup>

        {/* Address */}
        <FormGroup className={styles.addressFormGroup}>
          <div className={styles.address}>
            <div className={styles.info}>
              <div className={styles.street}>{streetLabel}</div>
              <div className={styles.city}>{cityLabel}</div>
            </div>

            {
              canEditStreet && (
                <div className={styles.action}>
                  <Button
                    size="small"
                    variant="subtle"
                    className={styles.changeButton}
                    onClick={onEditStreetClick}
                  >
                    {t('ACTIONS.MODIFY')}
                  </Button>
                </div>
              )
            }
          </div>
        </FormGroup>

        {/* Additional Details */}
        {
          canAddAdditionalDetails && (
            <FormGroup className={styles.additionalDetailsFormGroup}>
              <Input
                className={styles.contactInput}
                name={STREET_DETAILS}
                label={t('ADDRESS.ADDRESS')}
                placeholder={t('ADDRESS.ADDRESS_DETAILS')}
                autoComplete="off"
                data-testid="address-form.details.details"
              />
            </FormGroup>
          )
        }

        {/* Contact Details */}
        <FormGroup
          className={classnames(styles.contactDetailsFormGroup, { [styles.withoutMargin]: canAddAdditionalDetails })}
          withBorder
          inline
          title={t('ADDRESS.CONTACT_PERSON_LABEL')}
        >
          {/* Name */}
          <Input
            className={styles.contactField}
            name={CONTACT_NAME}
            label={t('LABELS.NAME')}
            placeholder={t('LABELS.NAME')}
            type="text"
            autoComplete="name"
            data-testid="address-form.details.contact-name"
          />

          {/* Phone */}
          <Input
            className={styles.contactField}
            name={CONTACT_PHONE}
            label={t('LABELS.PHONE')}
            placeholder={t('LABELS.PHONE')}
            autoComplete="tel"
            type="text"
            data-testid="address-form.details.contact-tel"
          />
        </FormGroup>
      </div>

      {/* Actions */}
      <div className={styles.actions}>
        {renderActions(form)}
      </div>

    </div>
  );
};

AddressFormDetails.propTypes = {
  form: PropTypes.shape({}),
  canEditStreet: PropTypes.bool,
  canAddAdditionalDetails: PropTypes.bool,
  renderActions: PropTypes.func,
  onEditStreetClick: PropTypes.func
};

export default AddressFormDetails;
