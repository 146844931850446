import React from 'react';

import { BLOG_BLOCK_TYPES } from 'constants/blog';

import BlogImageBlock from './BlogImageBlock';
import BlogProductsBlock from './BlogProductsBlock';
import BlogTextBlock from './BlogTextBlock';
import BlogVideoBlock from './BlogVideoBlock';

import type { ValueOf } from 'types';

interface Props {
  blocks: {
    __typename: ValueOf<typeof BLOG_BLOCK_TYPES>,
    [x: string]: any
  }[],
  // Video block
  videoBlockContainerClassName?: string,
  videoBlockClassName?: string,
  videoBlockTextContainerClassName?: string,
  videoBlockVideoContainerClassName?: string,
  // Text block
  textBlockContainerClassName?: string,
  textBlockClassName?: string,
  // Product block
  productsBlockContainerClassName?: string,
  productsBlockClassName?: string,
  // Image block
  imageBlockContainerClassName?: string,
  imageBlockClassName?: string,
  imageBlockTextContainerClassName?: string,
  imageBlockImageContainerClassName?: string,
  //
  isMobilePreview?: boolean
}

const BlogBlocks = (props: Props) => {

  const {
    blocks = [],
    // Video Block props
    videoBlockContainerClassName,
    videoBlockClassName,
    videoBlockTextContainerClassName,
    videoBlockVideoContainerClassName,
    // Text Block props
    textBlockContainerClassName,
    textBlockClassName,
    // Products Block props,
    productsBlockContainerClassName,
    productsBlockClassName,
    // Image Block props,
    imageBlockContainerClassName,
    imageBlockClassName,
    imageBlockTextContainerClassName,
    imageBlockImageContainerClassName,
    //
    isMobilePreview
  } = props;

  return blocks?.map((block, index) => {
    const { __typename: type } = block;

    switch (type) {

      /* VIDEO BLOCK */
      case BLOG_BLOCK_TYPES.VIDEO_BLOCK: {
        const { title, description, url } = block;
        return (
          <BlogVideoBlock
            key={index}
            title={title}
            description={description}
            url={url}
            videoContainerClassName={videoBlockVideoContainerClassName}
            textContainerClassName={videoBlockTextContainerClassName}
            containerClassName={videoBlockContainerClassName}
            className={videoBlockClassName}
          />
        );
      }

      /* TEXT BLOCK */
      case BLOG_BLOCK_TYPES.TEXT_BLOCK: {
        const { title, content } = block;
        return (
          <BlogTextBlock
            key={index}
            title={title}
            content={content}
            containerClassName={textBlockContainerClassName}
            className={textBlockClassName}
          />
        );
      }

      /* PRODUCTS BLOCK */
      case BLOG_BLOCK_TYPES.PRODUCTS_BLOCK: {
        return (
          <BlogProductsBlock
            key={index}
            className={productsBlockClassName}
            containerClassName={productsBlockContainerClassName}
            isMobilePreview={isMobilePreview}
            {...block}
          />
        );
      }

      /* IMAGE BLOCK */
      case BLOG_BLOCK_TYPES.IMAGE_BLOCK: {
        return (
          <BlogImageBlock
            key={index}
            containerClassName={imageBlockContainerClassName}
            className={imageBlockClassName}
            imageContainerClassName={imageBlockImageContainerClassName}
            textContainerClassName={imageBlockTextContainerClassName}
            {...block}
          />
        );
      }

      default: {
        console.error('Unknown block type', type);
        return null;
      }

    }
  });
};

export default BlogBlocks;
