import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import * as OrdersService from 'services/OrdersService';

export const useOrderGiftMutation = () => {
  const mutation = useMutationInternal();

  return {
    isLoading: mutation.isPending,
    isError: mutation.isError,
    //
    add: (payload) => mutation.mutate({ payload, type: ACTIONS.ADD }),
    remove: (payload) => mutation.mutate({ payload, type: ACTIONS.REMOVE }),
  };
};

const useMutationInternal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ORDER_GIFTS],
    mutationFn: (args = {}) => {

      const { type, payload = {} } = args;
      const {
        tokenValue, variantCode
      } = payload;

      switch (type) {

        case ACTIONS.ADD: {
          return OrdersService.addOrderGift(tokenValue, variantCode);
        }

        case ACTIONS.REMOVE: {
          return OrdersService.removeOrderGift(tokenValue, variantCode);
        }

        default:
          console.error('Unknown mutation type', type);
      }
    },
    onMutate: async () => {
      return {};
    },
    onSuccess: (data, args = {}) => {
      const { payload = {}, type } = args;
      const { tokenValue, variantCode } = payload || {};

      switch (type) {

        case ACTIONS.ADD: {
          queryClient.setQueryData([QUERY_KEYS.ORDER_GIFTS, tokenValue], ({ gifts, ...rest }) => {
            return {
              ...rest,
              gifts: gifts.map((gift) => {
                return gift?.product?.variantCode === variantCode
                  ? { ...gift, removed: false }
                  : gift;
              })
            };
          });
          break;
        }

        case ACTIONS.REMOVE: {
          queryClient.setQueryData([QUERY_KEYS.ORDER_GIFTS, tokenValue], ({ gifts, ...rest }) => {
            return {
              ...rest,
              gifts: gifts.map((gift) => {
                return gift?.product?.variantCode === variantCode
                  ? { ...gift, removed: true }
                  : gift;
              })
            };
          });
          break;
        }

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err, args = {}) => {
      const { payload = {} } = args;

      if (payload.onError) {
        payload.onError(err);
      }
    },
    onSettled: (data, error, args = {}) => {
      const { payload = {} } = args;

      if (payload.onSettled) {
        payload.onSettled();
      }
    }
  });
};

const ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};
