import React from 'react';

import { Field } from 'formik';
import classnames from 'classnames';

import RatingLabel from './RatingLabel';
import RatingSymbols from './RatingSymbols';

import type { FieldProps } from 'formik';

import styles from './Rating.module.scss';

// Constants

export const RATING_SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const SYMBOL_SIZES = {
  [RATING_SIZES.LARGE]: 40,
  [RATING_SIZES.MEDIUM]: 32,
  [RATING_SIZES.SMALL]: 24,
} as const;

// Types

type RatingSize = typeof SYMBOL_SIZES[keyof typeof RATING_SIZES];

interface Props {
  symbol: string,
  value?: number,
  size: RatingSize,
  count?: number,
  //
  className?: string,
  symbolClassName?: string,
  labelClassName?: string,
  wrapperClassName?: string,
  errorClassName?: string,
  //
  disabled?: boolean,
  readOnly?: boolean,
  hasError?: boolean,
  withoutForm?: boolean,
  //
  error?: string,
  name?: string,
  label?: string,
  //
  onChange?: (value: number) => void,
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void,
  onSymbolMouseEnter?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>, index: number) => void,
  onSymbolClick?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>, index: number) => void
}

const Rating = (props: Props) => {

  const {
    name,
    withoutForm = true
  } = props;

  if (withoutForm) {
    return <RatingCore {...props} />;
  }

  return (
    <Field name={name}>
      {({ field, meta: { touched, error: formikError } }: FieldProps) => {
        const { onChange: onFieldChange } = field;
        const hasError = !!(touched && formikError);
        return <RatingCore {...props} hasError={hasError} error={formikError} onChange={onFieldChange} />;
      }}
    </Field>
  );
};

// Components

interface RatingCoreProps extends React.ComponentProps<typeof Rating> {}

const RatingCore = (props: RatingCoreProps) => {

  const {
    className, labelClassName, wrapperClassName, symbolClassName, errorClassName,
    symbol, size = SYMBOL_SIZES.MEDIUM, count = 5,
    readOnly, disabled, withoutForm,
    onChange, onMouseLeave, onSymbolMouseEnter, onSymbolClick,
    label, name, hasError, error,
    value = 0
  } = props;

  const [displayValue, setDisplayValue] = React.useState(value);
  const symbolSize = SYMBOL_SIZES[size] || size;

  React.useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    setDisplayValue(value);
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };
  const handleSymbolMouseEnter = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>, index: number) => {
    setDisplayValue(index + 1);
    if (onSymbolMouseEnter) {
      onSymbolMouseEnter(e, index);
    }
  };

  const handleSymbolClick = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>, index: number) => {
    if (onChange) {
      onChange(index + 1);
    }
    if (onSymbolClick) {
      onSymbolClick(e, index);
    }
  };

  const wrapperClasses = classnames(
    styles.wrapper,
    wrapperClassName,
    {
      [styles.disabled]: disabled,
      [styles.readonly]: readOnly,
    }
  );

  return (
    <div className={classnames(styles.root, className)}>
      <RatingLabel label={label} hasError={hasError} labelClassName={labelClassName} name={name} />
      <div className={wrapperClasses} onMouseLeave={handleMouseLeave}>
        <RatingSymbols
          symbol={symbol}
          size={symbolSize}
          symbolClassName={symbolClassName}
          count={count}
          disabled={disabled}
          readOnly={readOnly}
          displayValue={displayValue}
          onSymbolClick={handleSymbolClick}
          onSymbolMouseEnter={handleSymbolMouseEnter}
        />
      </div>
      {
        !withoutForm && (
          <div className={classnames(styles.error, errorClassName, { [styles.visible]: hasError })}>
            {error}
          </div>
        )
      }
    </div>
  );
};

// Export

export default Rating;
