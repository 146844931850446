/* eslint-disable react/forbid-prop-types */

import React from 'react';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { isIE, isSafari } from 'helpers/BrowserHelpers';
import { useIsClient } from 'hooks/useIsClient';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import Button from '../Button/Button';
import Image from '../Image/Image';
import Link from '../Link/Link';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import Skeleton from '../Skeleton/Skeleton';

import type { ImageProps } from '../Image/Image';

import type { IAnalyticsListProps, IImage } from 'types';

import styles from './Banner.module.scss';

interface Props {
  className?: string,
  //
  href: string,
  newTab?: boolean,
  //
  image: IImage | { desktop: IImage, responsive: IImage },
  imageProps?: Partial<ImageProps>,
  desktopProps?: Partial<ImageProps>,
  responsiveProps?: Partial<ImageProps>,
  //
  rounded?: boolean,
  hoverable?: boolean,
  //
  loading?: boolean,
  //
  listProps?: IAnalyticsListProps,
  //
  onEnter: () => void,
  onClick: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const Banner = (props: Props) => {

  const {
    className,
    //
    href,
    newTab,
    //
    image,
    imageProps = {},
    desktopProps,
    responsiveProps,
    //
    rounded = false,
    hoverable = false,
    //
    loading,
    //
    listProps,
    //
    onEnter,
    onClick = () => {},
  } = props;

  // Hooks

  const isClient = useIsClient();
  const isWebPSupport = !(isSafari() || isIE());

  const { set: setAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // Observer

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      onEnter();
      unobserve();
    }
  });

  // Props

  const {
    desktop,
    responsive,
    default: jpg,
    webp,
  } = ('default' in image
    ? {
      desktop: null,
      responsive: null,
      ...image,
    }
    : {
      default: null,
      webp: null,
      ...image
    }
  );

  // Image

  const defaultSrc = isWebPSupport ? webp : jpg;

  const { jpg: desktopJpg, webp: desktopWebp } = desktop || {};
  const { jpg: responsiveJpg, webp: responsiveWebp } = responsive || {};

  const desktopSrc = isWebPSupport ? desktopWebp : desktopJpg;
  const responsiveSrc = isWebPSupport ? responsiveWebp : responsiveJpg;

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    setAnalyticsListProps(listProps);

    if (onClick) {
      onClick(e);
    }
  };

  // Empty

  if (!defaultSrc && !(desktopSrc || responsiveSrc)) return null;

  // Simple Banner

  if (defaultSrc) {
    return (
      <BannerLink
        ref={onEnter ? observe : null}
        className={classnames(
          styles.simple,
          { [styles.hoverable]: hoverable && !loading },
          className,
        )}
        href={href}
        newTab={newTab}
        onClick={handleClick}
      >

        {/* Image */}
        <Image
          optimize
          className={classnames(
            styles.image,
            { [styles.rounded]: rounded },
          )}
          src={defaultSrc}
          {...imageProps}
        />

        {/* Overlay */}
        <div className={styles.overlay} />

        {/* Loader */}
        <Fade
          className={styles.loader}
          isVisible={loading}
          enterDuration={0}
          exitDuration={400}
          exitDelay={200}
        >
          <Skeleton
            containerClassName={styles.skeleton}
            className={styles.skeletonInner}
          />
        </Fade>

      </BannerLink>
    );
  }

  // Responsive Banner

  return (
    <BannerLink
      ref={onEnter ? observe : null}
      className={classnames(
        { [styles.rendered]: isClient },
        { [styles.hoverable]: hoverable && !loading },
        { [styles.hideOnResponsive]: responsiveProps?.hidden },
        { [styles.hideOnDesktop]: desktopProps?.hidden },
        className,
      )}
      href={href}
      newTab={newTab}
      onClick={handleClick}
    >

      {/* Image */}
      <ResponsiveImage
        optimize
        className={classnames(
          styles.responsiveImage
        )}
        imageClassName={classnames(
          styles.image,
          { [styles.rounded]: rounded },
          { [styles.hidden]: loading },
        )}
        alt="Banner"
        desktopProps={{
          src: desktopSrc,
          ...desktopProps,
          priority: true,
        }}
        responsiveProps={{
          src: responsiveSrc,
          ...responsiveProps
        }}
      />

      {/* Overlay */}
      <div className={styles.overlay} />

      {/* Loader */}
      <Fade
        className={styles.loader}
        isVisible={loading}
        enterDuration={0}
        exitDuration={400}
        exitDelay={200}
      >
        <Skeleton
          containerClassName={styles.skeleton}
          className={styles.skeletonInner}
        />
      </Fade>

    </BannerLink>
  );
};

// Components

interface BannerLinkProps {
  className?: string,
  href: string,
  newTab?: boolean,
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  children?: React.ReactNode
}

const BannerLink = React.forwardRef<HTMLAnchorElement, BannerLinkProps>((
  props: BannerLinkProps,
  ref: React.Ref<HTMLAnchorElement>
) => {
  const {
    className,
    href,
    newTab,
    onClick,
    children
  } = props;

  return (
    <Link
      href={href}
      passHref
      prefetch={false}
    >
      <Button
        ref={ref}
        //
        className={classnames(
          styles.root,
          className
        )}
        contentClassName={styles.content}
        //
        tag="a"
        target={newTab ? '_blank' : null}
        rel={newTab ? 'noreferrer' : null}
        //
        onClick={onClick}
      >
        {children}
      </Button>
    </Link>
  );
});

// Export

export default React.memo(Banner);
