import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getIntervalLabel } from 'helpers/AddressHelpers';
import { useNextDeliveryInterval } from 'hooks/data/useNextDeliveryInterval';

export function useHeaderDeliveryInterval(addressToken: string | undefined, isAuthenticated: boolean) {

  const { t } = useTranslation();
  const router = useRouter();

  const [interval, setInterval] = React.useState({ label: t('HEADER.DESKTOP.INTERVAL_PLACEHOLDER'), isPlaceholder: false });

  const { data: nextInterval, refetch } = useNextDeliveryInterval(addressToken, isAuthenticated, {
    enabled: !!addressToken,
    refetchInterval: 2 * 60 * 1000, // 2 minutes
    retry: 0
  });

  const updateNextDeliveryInterval = React.useCallback(() => {
    if (addressToken) {
      refetch();
    }
  }, [addressToken]);

  // Refetch on route change

  React.useEffect(() => {
    router.events.on('routeChangeStart', updateNextDeliveryInterval);
    return () => {
      router.events.off('routeChangeStart', updateNextDeliveryInterval);
    };
  }, [updateNextDeliveryInterval]);

  // Update interval

  React.useEffect(() => {
    const updateDeliverySlot = async () => {
      if (nextInterval) {
        setInterval({
          label: getIntervalLabel(nextInterval),
          isPlaceholder: false
        });
      } else {
        setInterval({
          label: t('HEADER.DESKTOP.INTERVAL_PLACEHOLDER'),
          isPlaceholder: true
        });
      }
    };

    updateDeliverySlot();
  }, [nextInterval]);

  return interval;
}
