import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';

interface BreakpointsStoreState {
  isSm: boolean,
  isSmMax: boolean,
  isMd: boolean,
  isMdMax: boolean,
  isLg: boolean,
  isLgMax: boolean,
  isXl: boolean,
  isXlMax: boolean
}

const initialState: BreakpointsStoreState = {
  isSm: true,
  isSmMax: false,
  isMd: false,
  isMdMax: false,
  isLg: false,
  isLgMax: false,
  isXl: false,
  isXlMax: false
};

const reducers = {
  setBreakpoints: (draft: BreakpointsStoreState, action: IStoreAction<BreakpointsStoreState>): BreakpointsStoreState => {
    const { payload } = action;
    return {
      ...draft,
      ...payload
    };
  },

  setBreakpoint: (draft: BreakpointsStoreState, action: IStoreAction<{ breakpoint: keyof BreakpointsStoreState, value: boolean }>): BreakpointsStoreState => {
    const { payload } = action;
    return {
      ...draft,
      [payload.breakpoint]: payload.value
    };
  }
};

type Actions = IStoreActions<typeof reducers>;

const [
  BreakpointsProvider,
  useBreakpointsStore,
  useDispatch,
  actions,
  useBreakpointsSelector
] = createV2<BreakpointsStoreState, Actions>(
  reducers,
  initialState
);

function useBreakpointsDispatch() {
  const dispatch = useDispatch();

  const setBreakpoints = (breakpoints: BreakpointsStoreState) => dispatch(actions.setBreakpoints(breakpoints));
  const setBreakpoint = (breakpoint: { breakpoint: keyof BreakpointsStoreState, value: boolean }) => dispatch(actions.setBreakpoint(breakpoint));

  return {
    setBreakpoints,
    setBreakpoint
  };
}

export {
  BreakpointsProvider,
  useBreakpointsStore,
  useBreakpointsDispatch,
  useBreakpointsSelector
};
