import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getRecommendations } from 'services/RecommendationService';

import type {
  IRecommendations, ReactQueryOptions, RecommendationLocation, RecommendationType
} from 'types';

interface Params {
  isAuthenticated?: boolean,
  tokenValue?: string,
  forceFallback?: boolean,
  hash?: string
}

export const useRecommendations = (
  location: RecommendationLocation,
  type: RecommendationType,
  params: Params,
  queryOptions: ReactQueryOptions<IRecommendations> = {}
) => {
  return useQuery({
    queryKey: getKey(location, type, params, queryOptions),
    queryFn: () => getRecommendations(location, type, params),
    ...queryOptions
  });
};

export const getKey = (
  location: RecommendationLocation,
  type: RecommendationType,
  params: Params,
  queryOptions: ReactQueryOptions<IRecommendations>
) => {

  const { refetchInterval } = queryOptions || {};
  const {
    isAuthenticated, hash = null, tokenValue = null, forceFallback
  } = params;

  return createQueryKey([
    QUERY_KEYS.RECOMMENDATIONS,
    location,
    type,
    isAuthenticated,
    refetchInterval,
    tokenValue,
    hash,
    forceFallback
  ]);
};
