import React from 'react';
import classnames from 'classnames';

import { Icon } from 'components/ui';

import styles from './ProductGalleryZoom.module.scss';

interface Props {
  isImageMaximized: boolean,
  canZoomIn: boolean,
  canZoomOut: boolean,
  //
  onZoomToggle: () => void,
  onZoomIn: () => void,
  onZoomOut: () => void
}

const ProductGalleryZoom = (props: Props) => {
  const {
    isImageMaximized,
    canZoomIn,
    canZoomOut,
    //
    onZoomToggle,
    onZoomIn,
    onZoomOut,
  } = props;

  const handleOnZoomClick = () => {
    onZoomToggle();
  };

  return (
    <div className={styles.root}>

      {/* Zoom open */}
      <div className={styles.zoomOpen}>
        <ZoomIcon
          name={`arrows-${isImageMaximized ? 'minimize' : 'maximize'}`}
          onClick={handleOnZoomClick}
        />
      </div>

      {/* Zoom in / out */}
      {
        isImageMaximized && (
          <div className={styles.zoomActions}>
            <ZoomIcon
              name="zoom-in"
              onClick={onZoomIn}
              disabled={!canZoomIn}
            />
            <ZoomIcon
              name="zoom-out"
              onClick={onZoomOut}
              disabled={!canZoomOut}
            />
          </div>
        )
      }
    </div>
  );
};

// Components

interface ZoomIconProps {
  name: string,
  disabled?: boolean,
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ZoomIcon = (props: ZoomIconProps) => {
  const {
    name,
    disabled,
    onClick,
  } = props;

  return (
    <button
      type="button"
      className={classnames(styles.zoomIcon, { [styles.disabled]: disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        name={name}
        size={24}
      />
    </button>
  );
};

// Export

export default ProductGalleryZoom;
