/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import {
  Card, Icon, Image, Link
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { APP_ROUTES } from 'constants/routes';

import type { IPromotionCard } from 'types';

import styles from './PromotionCard.module.scss';

export interface Props {
  className?: string,
  cardClassName?: string,
  imageClassName?: string,
  detailsClassName?: string,
  titleClassName?: string,
  datesClassName?: string,
  arrowClassName?: string,
  //
  promotion: IPromotionCard,
  selected: boolean,
  //
  fill?: boolean,
  inline?: boolean,
  withTitle?: boolean,
  withDates?: boolean,
  withArrow?: boolean,
  asThumbnail?: boolean,
  //
  onClick?: () => void,
  onEnter?: () => void
}

const PromotionCard = (props: Props) => {

  const {
    //
    className,
    cardClassName,
    imageClassName,
    detailsClassName,
    titleClassName,
    datesClassName,
    arrowClassName,
    //
    promotion,
    selected,
    //
    fill,
    inline,
    withTitle,
    withDates,
    withArrow,
    asThumbnail,
    //
    onClick,
    onEnter,
  } = props;

  // Hooks

  const [isHovered, setHovered] = React.useState(false);

  // Refs

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter();
      }
      unobserve();
    }
  });

  // Props

  const {
    code,
    carouselImage,
    name,
    datesShort
  } = promotion;

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (selected) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  // Thumbnail Render

  if (asThumbnail) {
    return (
      <div
        ref={onEnter ? observe : null}
        className={classnames(
          styles.root,
          styles.thumbnail,
          { [styles.selected]: selected },
          className
        )}
      >

        {/* Link */}
        <Link href={`${APP_ROUTES.PROMOTIONS}/${code}`} prefetch={CONFIG.PREFETCH_LINKS}>
          <a
            className={styles.link}
            onClick={handleClick}
          >
            <div
              className={classnames(
                styles.imageWrapper,
                imageClassName
              )}
            >
              <div className={styles.image}>
                <Image
                  src={carouselImage}
                  width={304}
                  height={336}
                  alt={name}
                />
              </div>
            </div>
          </a>
        </Link>
      </div>
    );
  }

  // Render

  return (
    <div
      ref={onEnter ? observe : null}
      className={classnames(
        styles.root,
        { [styles.selected]: selected },
        { [styles.fill]: fill },
        className
      )}
    >

      {/* Link */}
      <Link href={`${APP_ROUTES.PROMOTIONS}/${code}`} prefetch={CONFIG.PREFETCH_LINKS}>
        <a
          className={styles.link}
          onClick={handleClick}
        >

          {/* Card */}
          <Card
            className={classnames(
              styles.card,
              { [styles.hasDetails]: withTitle || withDates },
              { [styles.vertical]: !inline },
              { [styles.horizontal]: inline },
              cardClassName,
            )}
            elevation={selected || isHovered ? 3 : 1}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >

            {/* Inner */}
            <div className={styles.inner}>

              {/* Image */}

              <div
                className={classnames(
                  styles.imageWrapper,
                  imageClassName
                )}
              >
                <div className={styles.image}>
                  <Image
                    src={carouselImage}
                    width={304}
                    height={336}
                  />
                </div>
              </div>

              {/* Details */}
              {
                (withTitle || withDates) && (
                  <div
                    className={classnames(
                      styles.detailsWrapper,
                      detailsClassName
                    )}
                  >

                    <div className={styles.details}>

                      {/* Title */}
                      {
                        withTitle && (
                          <div className={styles.titleWrapper}>
                            <div className={classnames(styles.title, titleClassName)}>{name}</div>
                          </div>
                        )
                      }

                      {/* Dates */}
                      {
                        withDates && (
                          <div className={styles.datesWrapper}>
                            <div className={classnames(styles.dates, datesClassName)}>{datesShort}</div>
                          </div>
                        )
                      }

                    </div>

                    {/* Arrow */}
                    {
                      withArrow && (
                        <div className={classnames(styles.arrow, arrowClassName)}>
                          <div className={styles.icon}>
                            <Icon
                              name="arrow-narrow-right"
                              size={24}
                              strokeWidth={2}
                            />
                          </div>
                        </div>
                      )
                    }

                  </div>
                )
              }

            </div>

          </Card>
        </a>
      </Link>
    </div>
  );
};

export default PromotionCard;
