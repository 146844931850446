import React from 'react';

import { Button } from 'components/ui';

import styles from './OrderProblemNavigation.module.scss';

const OrderProblemNavigation = (props) => {
  const {
    title,
    isBackVisible = true,
    onBackClick,
  } = props;

  return (
    <div className={styles.navigation}>
      {
        isBackVisible && (
          <Button
            size="small"
            icon="arrow-narrow-left"
            className={styles.backButton}
            onClick={onBackClick}
          />
        )
      }
      <div className={styles.navigationTitle}>{title}</div>
    </div>
  );
};

export default OrderProblemNavigation;
