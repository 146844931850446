import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import { IMAGE_SIZE } from './RecipeIngredientCard';

import styles from './RecipeIngredientCard.module.scss';

interface Props {
  className?: string,
  withExternalQuantityInfo?: boolean
}

const RecipeIngredientCardSkeleton = (props: Props) => {

  const {
    className,
    withExternalQuantityInfo,
  } = props;

  return (
    <div
      className={classnames(
        styles.root,
        styles.skeleton,
        className
      )}
    >

      {/* Inner */}
      <div className={styles.inner}>

        {/* Image */}
        <div className={styles.image}>
          <Skeleton
            height={IMAGE_SIZE}
            width={IMAGE_SIZE}
          />
        </div>

        {/* Details */}
        <div className={styles.details}>

          {/* Main */}
          <div className={styles.main}>

            {/* Title */}
            <div className={styles.title}>
              <Skeleton count={1} />
            </div>
          </div>

          {/* Secondary */}
          <div className={styles.secondary}>

            {/* Price */}
            <div className={styles.price}>
              <Skeleton count={1} />
            </div>

          </div>

          {/* Tertiary */}
          {
            !withExternalQuantityInfo && (
              <div className={styles.tertiary}>

                {/* Quantity Info */}
                <p className={styles.info}>
                  <Skeleton count={1} />
                </p>

              </div>
            )
          }

        </div>
      </div>

      {/* Tertiary */}
      {
        withExternalQuantityInfo && (
          <div className={styles.tertiary}>

            {/* Quantity Info */}
            <p className={styles.info}>
              <Skeleton count={1} />
            </p>

          </div>
        )
      }

    </div>
  );
};

export default RecipeIngredientCardSkeleton;
