import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { Price } from 'components/ui';
import { getOrderPaymentMethodsNames } from 'helpers/OrderHelpers';

import OrderInfoBlock from '../OrderInfoBlock/OrderInfoBlock';

// import styles from './OrderInfoPayment.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const OrderInfoPayment = (props) => {

  const {
    order = {},
    label,
    withIcon,
    withAmount,
    withPaymentMethods,
    children,
    ...rest
  } = props;

  const { total, currency } = order;

  const paymentMethodsLabel = withPaymentMethods
    ? getOrderPaymentMethodsNames(order).join('<br />') || '-'
    : null;

  const { t } = useTranslation();

  return (
    <OrderInfoBlock
      icon={withIcon ? 'credit-card' : null}
      label={label || t('LABELS.PAYMENT_METHOD')}
      value={
        withAmount
          ? <Price currency={currency} value={total} nullable />
          : paymentMethodsLabel
      }
      subValue={
        withAmount
          ? paymentMethodsLabel
          : null
      }
      {...rest}
    >
      {children}
    </OrderInfoBlock>
  );
};

OrderInfoPayment.propTypes = {
  // specific props
  order: PropTypes.shape({
    total: PropTypes.number,
    currency: PropTypes.string,
    payments: PropTypes.arrayOf(PropTypes.shape({
      state: PropTypes.string,
      method: PropTypes.shape({})
    }))
  }),
  label: PropTypes.string,
  withIcon: PropTypes.bool,
  withAmount: PropTypes.bool,

  // block props
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  innerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withBorder: PropTypes.bool,
  withPaymentMethods: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default OrderInfoPayment;
