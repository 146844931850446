import React from 'react';
import classnames from 'classnames';

import { Icon, Price, Tooltip } from 'components/ui';

import { useBreakpoint } from 'hooks/useBreakpoint';

import ProductTaxes from '../ProductTaxes/ProductTaxes';

import type { IProductPriceTooltip, IProductTaxes, ValueOf } from 'types';

import styles from './ProductPrice.module.scss';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

type Size = ValueOf<typeof SIZES>;

const ALIGNMENTS = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
} as const;

type Alignment = ValueOf<typeof ALIGNMENTS>;

const TOOLTIP_ICON_SIZES = {
  [SIZES.SMALL]: 14,
  [SIZES.MEDIUM]: 18,
  [SIZES.LARGE]: 22,
};

interface Props {
  scrollContainer?: HTMLElement,
  //
  className?: string,
  mainClassName?: string,
  originalPriceWrapperClassName?: string,
  priceWrapperClassName?: string,
  iconClassName?: string,
  originalPriceClassName?: string,
  priceClassName?: string,
  discountLabelClassName?: string,
  taxesClassName?: string,
  unitPriceLabelClassName?: string,
  //
  size?: Size,
  align?: Alignment,
  fixedHeight?: boolean,
  inline?: boolean,
  inlineReverse?: boolean,
  //
  currency?: string,
  price?: number | string,
  originalPriceCurrency?: string,
  originalPrice?: number | string,
  withOriginalPriceStrikethrough?: boolean,
  nullable?: boolean,
  //
  discountLabel?: string,
  unitPriceLabel?: string,
  withUnitPriceLabel?: boolean,
  //
  priceLabel?: string,
  priceLabelColor?: string,
  //
  taxes?: IProductTaxes,
  //
  color?: string,
  icon?: string,
  tooltip?: IProductPriceTooltip
}

const ProductPrice = (props: Props) => {

  const {
    scrollContainer,
    //
    className,
    mainClassName,
    originalPriceWrapperClassName,
    priceWrapperClassName,
    iconClassName,
    originalPriceClassName,
    priceClassName,
    discountLabelClassName,
    taxesClassName,
    unitPriceLabelClassName,
    //
    size = SIZES.MEDIUM,
    align = ALIGNMENTS.START,
    fixedHeight,
    inline,
    inlineReverse,
    //
    currency,
    price,
    originalPriceCurrency = currency,
    originalPrice,
    withOriginalPriceStrikethrough = true,
    nullable,
    //
    discountLabel,
    unitPriceLabel,
    withUnitPriceLabel = !!unitPriceLabel,
    //
    priceLabel,
    priceLabelColor,
    //
    taxes,
    //
    color,
    icon,
    tooltip,
    //
  } = props;

  // Refs

  const ref = React.useRef<HTMLDivElement>(null);

  // Hooks

  const isBreakpoint = useBreakpoint('sm', 'down');

  // Props

  const hasTaxes = taxes && taxes.length > 0;

  // Render

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        { [styles[align]]: align },
        { [styles.inline]: inline },
        { [styles.inlineReverse]: inlineReverse },
        { [styles.fixedHeight]: fixedHeight },
        { [styles[size]]: size },
        className
      )}
    >

      {
        priceLabel
          ? (
            // Price Label
            <div
              className={classnames(styles.price, priceClassName)}
              style={{
                color: priceLabelColor
              }}
            >
              {priceLabel}
            </div>
          ) : (
            <>
              {/* Main */}
              <div className={classnames(styles.main, mainClassName)}>

                {/* Original Price Wrapper */}
                <div className={classnames(styles.originalPriceWrapper, originalPriceWrapperClassName)}>

                  {/* Original Price */}
                  <Price
                    className={classnames(
                      styles.original,
                      { [styles.withStrikethrough]: withOriginalPriceStrikethrough },
                      originalPriceClassName
                    )}
                    currency={originalPriceCurrency}
                    value={originalPrice}
                    hidden={originalPrice === price}
                    lineThrough={withOriginalPriceStrikethrough}
                  />

                  {/* Discount Label */}
                  {
                    discountLabel && (
                      <div
                        className={classnames(
                          styles.discountLabel,
                          discountLabelClassName
                        )}
                        style={{ color }}
                      >
                        {discountLabel}
                      </div>
                    )
                  }
                </div>

                {/* Price Wrapper */}
                {
                  (tooltip && (tooltip?.title || tooltip?.message))
                    ? (
                      // Price with tooltip
                      <div className={classnames(styles.priceWrapper, priceWrapperClassName)}>

                        <Tooltip
                          scrollContainer={scrollContainer}
                          parentElement={ref as React.MutableRefObject<HTMLElement>}
                          //
                          containerClassName={styles.tooltipContainer}
                          className={styles.tooltip}
                          innerClassName={styles.tooltipInner}
                          contentClassName={styles.tooltipContent}
                          //
                          padding={12}
                          positions={['top']}
                          align={isBreakpoint ? 'start' : 'end'}
                          //
                          title={tooltip?.title}
                          text={tooltip?.message}
                          backgroundColor={tooltip?.bgColor}
                          textColor={tooltip?.textColor}
                          //
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >

                          {/* Price */}
                          <Price
                            className={classnames(
                              styles.price,
                              priceClassName
                            )}
                            iconClassName={classnames(styles.priceIcon, iconClassName)}
                            value={price}
                            currency={currency}
                            nullable={nullable}
                            icon={icon}
                            color={color}
                          />

                          {/* Tooltip Icon */}
                          <Icon
                            className={styles.tooltipIcon}
                            name="info-circle"
                            size={TOOLTIP_ICON_SIZES[size]}
                          />

                        </Tooltip>

                        {/* Taxes */}
                        <ProductTaxes
                          className={classnames(
                            styles.taxes,
                            taxesClassName
                          )}
                          size={size}
                          taxes={taxes}
                        />
                      </div>
                    ) : (
                      // Price without tooltip
                      <div className={classnames(styles.priceWrapper, priceWrapperClassName)}>
                        {/* Price */}
                        <Price
                          className={classnames(
                            styles.price,
                            { [styles.noTaxes]: !hasTaxes },
                            priceClassName
                          )}
                          iconClassName={classnames(styles.priceIcon, iconClassName)}
                          value={price}
                          currency={currency}
                          nullable={nullable}
                          icon={icon}
                          color={color}
                        />

                        {/* Taxes */}
                        <ProductTaxes
                          className={classnames(
                            styles.taxes,
                            taxesClassName
                          )}
                          size={size}
                          taxes={taxes}
                        />
                      </div>
                    )
                }

              </div>

              {/* Unit Price Label */}
              {
                withUnitPriceLabel && (
                  <div className={classnames(styles.perUnit, unitPriceLabelClassName)}>
                    {unitPriceLabel}
                  </div>
                )
              }
            </>
          )
      }

    </div>
  );
};

export default ProductPrice;
