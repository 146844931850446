import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import styles from './ProductDefaultCard.module.scss';

const ProductDefaultCardSkeleton = () => {
  return (
    <div className={classnames(styles.root, styles.skeletonRoot)}>
      <div className={styles.imageContainer}>
        <Skeleton className={styles.imageSkeleton} />
      </div>
      <div className={styles.content}>
        <Skeleton count={5} className={styles.contentSkeleton} />
      </div>
    </div>
  );
};

export default ProductDefaultCardSkeleton;
