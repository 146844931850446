import React from 'react';

import {
  Button, GeniusBadge, Icon, Popover
} from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';
import { useHeaderDeliveryAddress } from 'hooks/useHeaderDeliveryAddress';
import { useHeaderDeliveryInterval } from 'hooks/useHeaderDeliveryInterval';

import styles from './ProductTrustElements.module.scss';

const DEFAULT_ELEMENTS = [
  { icon: 'snowflake', label: 'PRODUCT.TRUST_ELEMENTS.DELIVERY_TEMPERATURE' },
  { icon: 'card', label: 'PRODUCT.TRUST_ELEMENTS.PAYMENT_METHOD' },
  { icon: 'receipt-refund', label: 'PRODUCT.TRUST_ELEMENTS.PAYMENT_RETURN' }
];

interface Props {
  isAvailable?: boolean
}

const ProductTrustElements = (props: Props) => {

  const {
    isAvailable
  } = props;

  // Hooks

  const { t } = useTranslation();

  const {
    isAuthenticated,
    token: deliveryAddressToken,
  } = useHeaderDeliveryAddress();

  const {
    isPlaceholder: isIntervalLabelPlaceholder,
    label: deliveryIntervalLabel,
  } = useHeaderDeliveryInterval(deliveryAddressToken, !!isAuthenticated);

  // Props

  const isDeliveryElementVisible = (
    isAvailable
    && !isIntervalLabelPlaceholder
  );

  // Render

  return (
    <div className={styles.root}>

      {/* Title */}
      <div className={styles.title}>{t('PRODUCT.TRUST_ELEMENTS.TITLE')}</div>

      {/* Delivery Trust Element */}
      {
        isDeliveryElementVisible && (
          <TrustElement
            icon="truck"
            label={(<span>{`${t('PRODUCT.TRUST_ELEMENTS.DELIVERY')} ${deliveryIntervalLabel}`}</span>)}
          />
        )
      }

      {/* Genius Trust Element */}
      <GeniusTrustElement />

      {/* Default Trust Elements */}
      {
        DEFAULT_ELEMENTS.map(({ icon, label }) => <TrustElement key={label} icon={icon} label={label} />)
      }

    </div>
  );
};

// Components

// --- Trust Element

const TrustElement = (props: {icon: React.ReactNode, label: React.ReactNode}) => {

  const {
    icon,
    label
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.element}>
      <div className={styles.icon}>
        {typeof icon === 'string' ? <Icon name={icon} /> : icon}
      </div>
      <div className={styles.label}>
        {typeof label === 'string' ? t(label) : label}
      </div>
    </div>
  );
};

// --- Genius Trust Element

const GeniusTrustElement = () => {

  const { t } = useTranslation();

  return (
    <TrustElement
      icon={<GeniusBadge withIcon size={{ height: 22, width: 24 }} />}
      label={(
        <div className={styles.genius}>
          <span>{t('PRODUCT.TRUST_ELEMENTS.GENIUS')}</span>
          <GeniusPopover>
            <Button className={styles.popoverButton}>{t('PRODUCT.TRUST_ELEMENTS.GENIUS_LABEL')}</Button>
          </GeniusPopover>
        </div>
      )}
    />
  );
};

// --- Genius Popover

const GeniusPopover = (props: {children?: React.ReactNode}) => {

  const { children } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const content = (
    <>
      <div className={styles.popoverTitle}>
        <span>{t('PRODUCT.TRUST_ELEMENTS.GENIUS_POPOVER_TITLE')}</span>
        <GeniusBadge className={styles.geniusBadge} />
      </div>
      <div className={styles.popoverBody}>
        {t('PRODUCT.TRUST_ELEMENTS.GENIUS_POPOVER_BODY')}
      </div>
    </>
  );

  return (
    <div ref={ref}>
      <Popover
        persist
        parentElement={ref || undefined}
        contentClassName={styles.popover}
        align="center"
        positions={['bottom']}
        withArrow
        withHover
        content={content}
      >
        {children}
      </Popover>
    </div>
  );

};

// Export

export default ProductTrustElements;
