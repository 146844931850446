import {
  convertRecipeIngredients,
  convertRecipeInstructions,
  convertRecipeLabel,
  convertRecipeSeo,
  convertRecipeStats,
  convertRecipeTags,
  convertRecipeTips,
  convertRecipesLandingPageHero,
  convertRecipesLandingPageSeo,
  convertRecipesLandingPageSlot,
  convertRecipesPageSeo,
  convertStrapiImage,
  convertStrapiOrderByMeta,
  convertStrapiPaginationMeta,
  convertStrapiResponsiveImage,
  convertStrapiResponsiveSeoImage,
  convertStrapiVideo
} from 'helpers/RecipeHelpers';

import type {
  IProductDetails,
  IRecipe,
  IRecipeCard,
  IRecipeCardData,
  IRecipeData,
  IRecipeIngredientProduct,
  IRecipePrimaryIngredients,
  IRecipesByTagPage,
  IRecipesByTagPageData,
  IRecipesLandingPage,
  IRecipesLandingPageData,
  IRecipesPage,
  IRecipesPageData,
  IRecipesPromotionData,
  IRecipesPromotionListing,
  IStrapiPaginationMeta,
  MarkdownSerializeFunction,
} from 'types';

// Recipes Page

export function toRecipesPage(data: IRecipesPageData, meta: IStrapiPaginationMeta): IRecipesPage {
  const {
    title = null,
    recipes = []
  } = data || {};

  return {
    details: {
      title,
    },
    recipes: recipes.map(toRecipeCard),
    pagination: convertStrapiPaginationMeta(meta),
    seo: convertRecipesPageSeo(title, recipes[0]),
    __meta: meta,
    __data: data,
    orderBy: convertStrapiOrderByMeta(meta)
  };
}

// Recipes Landing Page

export function toRecipesLandingPage(data: IRecipesLandingPageData, meta: any): IRecipesLandingPage {
  const {
    title = null,
    createdAt = null,
    hero,
    slots = [],
    seo
  } = data || {};

  return {
    details: {
      title,
      date: createdAt
    },
    //
    hero: convertRecipesLandingPageHero(hero),
    slots: slots.map(convertRecipesLandingPageSlot).filter((slot) => !!slot),
    //
    seo: convertRecipesLandingPageSeo(seo, hero),
    //
    __meta: meta,
    __data: data
  };
}

// Recipes By Tag

export function toRecipesByTag(data: IRecipesByTagPageData, meta: IStrapiPaginationMeta): IRecipesByTagPage {
  const {
    recipes,
    tag,
  } = data || {};

  const {
    slug,
    label,
    image
  } = tag || {};

  return {
    meta: {
      slug,
    },
    details: {
      title: label,
    },
    banner: convertStrapiResponsiveImage(image),
    recipes: recipes.map((toRecipeCard)),
    seo: {
      slug,
      title: label,
      images: convertStrapiResponsiveSeoImage(image),
    },
    pagination: convertStrapiPaginationMeta(meta),
    __meta: meta,
    __data: data,
    orderBy: convertStrapiOrderByMeta(meta)
  };
}

// Recipe

export async function toRecipe(data: IRecipeData, meta: any, markdownSerializeFn: MarkdownSerializeFunction | any = (s: string) => s): Promise<IRecipe> {
  const {
    id,
    slug,
    title,
    description,
    publishedAt,
    //
    analyticsListId,
    analyticsListName,
    //
    label,
    banner,
    bannerImage,
    video,
    //
    calories,
    preparationTime,
    cookingTime,
    totalTime,
    servings,
    pricePerPortion,
    originalPricePerPortion,
    disclaimer,
    //
    cookingSteps,
    //
    tags,
    related = [],
    //
    seo,
  } = data || {};

  return {
    meta: {
      id,
      slug,
    },
    analyticsListProps: {
      analyticsListId,
      analyticsListName
    },
    banner,
    details: {
      title,
      description: (description && markdownSerializeFn)
        ? await markdownSerializeFn(description)
        : null,
      date: publishedAt,
    },
    //
    label: convertRecipeLabel(label),
    media: {
      banner: convertStrapiResponsiveImage(bannerImage),
      video: await convertStrapiVideo(video, markdownSerializeFn),
    },
    //
    stats: convertRecipeStats({
      calories,
      preparationTime,
      cookingTime,
      totalTime,
      servings,
      pricePerServing: pricePerPortion,
      originalPricePerServing: originalPricePerPortion,
    }),
    //
    ingredients: convertRecipeIngredients(data),
    disclaimer,
    instructions: convertRecipeInstructions(cookingSteps),
    tips: await convertRecipeTips(cookingSteps, markdownSerializeFn),
    //
    tags: convertRecipeTags(tags),
    related: related?.map(toRecipeCard),
    seo: convertRecipeSeo(seo, bannerImage),
    //
    __meta: meta,
    __data: data
  };
}

// Recipe Ingredient

export function toRecipeIngredientProducts(ingredients: IRecipePrimaryIngredients, products: IProductDetails[]): IRecipeIngredientProduct[] {
  const productsMap = products.reduce((acc, product) => {
    const { sku } = product;
    acc[sku] = product;
    return acc;
  }, {});

  return ingredients
    .map((ingredient) => {
      const { id, sku } = ingredient || {};
      const product = productsMap[sku];
      return {
        id,
        sku,
        ingredient,
        product,
      };
    })
    .filter(({ product }) => !!product);
}

// Recipe Card

export function toRecipeCard(data: IRecipeCardData): IRecipeCard {
  const {
    id,
    slug,
    banner = null,
    //
    title,
    thumbnailImage,
    label,
    //
    calories,
    preparationTime,
    cookingTime,
    totalTime,
    servings,
    pricePerPortion,
    originalPricePerPortion,
  } = data || {};

  return {
    id,
    slug,
    banner,
    //
    title,
    image: convertStrapiImage(thumbnailImage),
    label: convertRecipeLabel(label),
    //
    stats: convertRecipeStats({
      calories,
      preparationTime,
      cookingTime,
      totalTime,
      servings,
      pricePerServing: pricePerPortion,
      originalPricePerServing: originalPricePerPortion,
    }),
  };
}

export function toRecipePromotions(data: IRecipesPromotionData): IRecipesPromotionListing {
  const {
    payload
  } = data || {};

  return {
    ...data,
    payload: {
      ...payload,
      recipes: payload.recipes?.map((recipe) => toRecipeCard(recipe))
    }
  };
}
