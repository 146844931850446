export function addRoPrefix(number?: string): string | undefined {
  if (!number) return undefined;

  if (number?.startsWith(NUMBER_PREFIX_RO)) {
    return number;
  }

  if (number?.startsWith('0')) {
    return `${NUMBER_PREFIX_RO_BASE}${number}`;
  }

  return `${NUMBER_PREFIX_RO}${number}`;
}

export function removeRoPrefix(number?: string): string | undefined {
  if (!number) return undefined;
  return number?.startsWith(NUMBER_PREFIX_RO) ? number.split(NUMBER_PREFIX_RO_BASE)[1] : number;
}

export const NUMBER_PREFIX_RO = '+40';
export const NUMBER_PREFIX_RO_BASE = '+4';
