import React from 'react';
import dynamic from 'next/dynamic';

import { MobileAppButtons } from 'components/common';
import {
  Image, Link, Logo, SocialMediaButton
} from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';
import { useTranslation } from 'hooks/common/useTranslation';

import FooterLinks from './FooterLinks';

import styles from './Footer.module.scss';

const NewsletterSubscribeForm = dynamic(() => import('./FooterNewsletterSubscribeForm'), { ssr: false });

interface Props {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root} data-testid="footer">
      <div className={styles.main}>
        <div className={styles.mainWrapper}>
          <div className={styles.appContainer}>
            <div className={styles.appMockContainer}>
              <Image
                className={styles.appMock}
                src={IMAGES.MOBILE_APP.MOCK}
                alt="Mobile app screens"
                height={455}
                width={369}
                optimize
              />
            </div>
            <div className={styles.appContent}>
              <div className={styles.appCta}>{t('FOOTER.MOBILE_APP.CTA')}</div>
              <MobileAppButtons className={styles.appButtons} />
              <NewsletterSubscribeForm />
            </div>
          </div>
          <FooterLinks />
        </div>
      </div>
      <div className={styles.sub}>
        <div className={styles.subWrapper}>
          <Logo size={{ width: 200, height: 50 }} className={styles.logo} />
          <FooterSocialMediaButtons />
          <Link href={APP_ROUTES.LEGAL_INFO} passHref>
            <a className={styles.copyright}>
              {`© ${new Date().getFullYear()} ${t('FOOTER.COPYRIGHT')}`}
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const socials = ['facebook', 'instagram', 'youtube', 'linkedin'];

const FooterSocialMediaButtons = () => {
  return (
    <div className={styles.socialButtons}>
      {
        socials.map((social, index) => (
          <SocialMediaButton
            key={index}
            className={styles.socialButton}
            variant={social}
            withoutLabel
          />
        ))
      }
    </div>
  );
};

export default Footer;
