import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';
import { getProductVariantPriceProps } from 'helpers/ProductHelpers';

import ProductPrice from '../parts/ProductPrice/ProductPrice';

import type { IProductQuantityVariant } from 'types';

import styles from './ProductQuantityVariant.module.scss';

interface Props {
  variant: IProductQuantityVariant,
  shouldShowGeniusPrice?: boolean,
  hasDiscountLabel?: boolean,
  hasGeniusDiscountLabel?: boolean,
  //
  className?: string,
  titleClassName?: string,
  quantityClassName?: string,
  imageWrapperClassName?: string,
  //
  renderTitle?: () => React.ReactNode,
  renderBefore?: () => JSX.Element,
  renderAfter?: () => JSX.Element
}

const ProductQuantityVariant = (props: Props) => {

  const {
    className, titleClassName, quantityClassName, imageWrapperClassName,
    variant,
    shouldShowGeniusPrice, hasDiscountLabel, hasGeniusDiscountLabel,
    renderBefore, renderAfter, renderTitle
  } = props;

  const {
    title,
    quantityLabel,
    currency,
    image,
  } = variant;

  const {
    price,
    unitPrice,
    discountLabelWithConfig,
    originalUnitPrice,
    priceColor,
    taxes,
  } = getProductVariantPriceProps(variant, { shouldShowGeniusPrice });

  const hasLabel = hasDiscountLabel || hasGeniusDiscountLabel;

  return (
    <div className={classnames(styles.root, className)}>
      {/* Render Before */}
      {renderBefore && renderBefore()}

      {/* Image */}
      <div className={classnames(styles.imageWrapper, imageWrapperClassName)}>
        <Image
          className={styles.image}
          src={image?.thumbnail}
          width={40}
          height={40}
          alt={title}
        />
      </div>

      {/* Name */}
      <div className={classnames(styles.title, titleClassName)}>
        {renderTitle ? renderTitle() : title}
      </div>

      {/* Discount label (desktop) */}
      {
        hasLabel ? (
          <DiscountLabel
            className={classnames(styles.desktopDiscountLabel, { [styles.hidden]: !hasLabel })}
            label={discountLabelWithConfig}
          />
        ) : null
      }

      <div className={styles.quantityContainer}>

        {/* Quantity label */}
        <div className={classnames(styles.quantity, quantityClassName)}>
          {quantityLabel}
        </div>

        {/* Discount label (responsive) */}
        {
          hasLabel ? <DiscountLabel className={styles.mobileDiscountLabel} label={discountLabelWithConfig} /> : null
        }

        {/* Unit price */}
        <ProductPrice
          className={styles.unitPriceWrapper}
          priceClassName={styles.unitPrice}
          //
          price={price}
          currency={currency}
          //
          size="small"
        />
      </div>

      {/* Price */}
      <ProductPrice
        className={styles.price}
        //
        originalPrice={originalUnitPrice || unitPrice}
        price={unitPrice}
        taxes={taxes}
        color={priceColor}
        //
        size="small"
        align="end"
      />

      {/* Render After */}
      {renderAfter && renderAfter()}
    </div>
  );
};

// --- Discount Label

interface DiscountLabelProps {
  className?: string,
  label: IProductQuantityVariant['quantityOptionsDiscountLabel']
}

const DiscountLabel = (props: DiscountLabelProps) => {
  const {
    className,
    label
  } = props;

  const { text, textColor, backgroundColor } = label || {};

  return (
    <div
      className={classnames(styles.discountLabel, className)}
      style={{
        color: textColor || 'unset',
        backgroundColor: backgroundColor || 'unset'
      }}
    >
      {text || ''}
    </div>
  );
};

export default ProductQuantityVariant;
