import { Api, SecureApi } from 'common/http';

import type { IUpdateConsentPayload } from 'types';

export const getConsent = async () => {
  const { data } = await SecureApi.get('/cookie-consents/me/all');
  return data;
};

export const getConsentCookieDefinitions = async () => {
  const { data } = await Api.get('/cookie-definitions/consent');
  return data;
};

export const updateConsent = async (payload: IUpdateConsentPayload) => {
  const {
    timestamp,
    cookies
  } = payload || {};

  const { data } = await SecureApi.mergePatch('/cookie-consents/me', {
    timestamp,
    cookies,
  });

  return data;
};
