import React from 'react';

import { AddressSearch } from 'components/address';
import { Modal } from 'components/ui';
import { useDeliveryInfoDispatchHelpers, useDeliveryInfoStore } from 'context/DeliveryInfoContext';

import styles from './AddressSearchModal.module.scss';

const AddressSearchModal = () => {
  const {
    isModalVisible,
    modalTitle,
    modalComponent,
    modalComponentProps,
    props = {}
  } = useDeliveryInfoStore();

  // Hooks

  const [isHeaderStuck, setIsHeaderStuck] = React.useState(false);

  // Context Hooks

  const { closeDeliveryInfoModal } = useDeliveryInfoDispatchHelpers();

  // Props

  const Component = modalComponent
    ? modalComponent.type
    : AddressSearch;

  // Handlers

  const handleModalClose = () => {
    closeDeliveryInfoModal();
  };

  // Render

  return (
    <Modal
      isOpen={isModalVisible}
      //
      className={styles.root}
      contentClassName={styles.content}
      innerClassName={styles.inner}
      //
      withPadding={false}
      priority={6}
      //
      desktopProps={{
        position: 'center'
      }}
      responsiveProps={{
        position: 'bottom',
        isFullScreen: true
      }}
      //
      title={modalTitle}
      headerProps={{
        desktopProps: {
          sticky: true,
        },
        responsiveProps: {
          stuck: isHeaderStuck,
          sticky: true
        }
      }}
      //
      close={handleModalClose}
      {...props}
    >
      <Component
        {...modalComponentProps}
        onContentScrolled={setIsHeaderStuck}
      />
    </Modal>
  );
};

export default AddressSearchModal;
