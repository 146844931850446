import { Api, SecureApi } from 'common/http';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';
import { toRecipePromotions } from 'services/RecipeService/RecipeAdapter';

import type {
  IListingRequestParams,
  IPromotionResponse,
  IPromotionsByOrderResponse,
  IPromotionsResponse
} from 'types';

// Promotions

export async function getPromotions() {
  const { data } = await Api.get<IPromotionsResponse>('/campaigns/active');
  return data;
}

// Promotion

export async function getPromotion(params: IListingRequestParams) {
  const {
    category,
    page = 1,
    itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE,
    orderBy,
    filters,
    identifier
  } = params || {};

  const orderByQs = UrlParams.getOrderByQueryString(orderBy);
  const filtersQs = UrlParams.getFiltersQueryString(filters);

  const endpoint = `/campaigns-v2/${identifier}?category=${category}&page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}`;

  const { data } = await Api.get<IPromotionResponse>(endpoint);

  const { type } = data;

  if (type === 'recipes') {
    return toRecipePromotions(data);
  }
  return data;
}

// Promotions By Order
export async function getPromotionsByOrder(tokenValue: string) {
  const { data } = await SecureApi.get<IPromotionsByOrderResponse>(`/orders/${tokenValue}/campaigns/incomplete`);
  return data;
}
