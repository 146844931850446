import {
  clearPreviousQueries as clearQueries, getNewQueries, getPreviousQueries, isQueryDuplicate
} from 'helpers/SearchHelpers';

import { create } from './StoreCreator';

import type { IStoreAction } from './StoreCreator';

const MIN_VALID_QUERY_LENGTH = 3;

interface SearchStoreState {
  query: string | null,
  isQueryValid: boolean,
  previousQueries: string[],
  isHeaderSearchBarActive: boolean,
  isInlineSearchBarVisible: boolean
}

const initialState: SearchStoreState = {
  query: null,
  isQueryValid: false,
  previousQueries: getPreviousQueries(),

  // mobile flags
  isHeaderSearchBarActive: false,
  isInlineSearchBarVisible: false,
};

const reducer = (draft: SearchStoreState, action: IStoreAction) => {
  const { payload = {}, type } = action;
  const { query } = payload;

  switch (type) {
    case ACTIONS.SET_QUERY:
      return {
        ...draft,
        query,
        isQueryValid: !!(query && query.length >= MIN_VALID_QUERY_LENGTH)
      };

    case ACTIONS.UPDATE_PREVIOUS_QUERIES:
      return {
        ...draft,
        ...(
          query === '' || isQueryDuplicate(query, draft.previousQueries)
            ? {}
            : { previousQueries: getNewQueries(query, draft.previousQueries) }
        )
      };

    case ACTIONS.CLEAR_PREVIOUS_QUERIES:
      return {
        ...draft,
        previousQueries: clearQueries()
      };

    case ACTIONS.TOGGLE_HEADER_SEARCH_BAR:
      return {
        ...draft,
        isHeaderSearchBarActive: payload?.isActive
      };

    case ACTIONS.SET_INLINE_SEARCH_BAR_VISIBILITY:
      return {
        ...draft,
        isInlineSearchBarVisible: payload?.isVisible
      };

    default:
      return draft;
  }
};

const [
  SearchProvider,
  useSearchStore,
  useSearchDispatch
] = create(reducer, initialState);

const ACTIONS = {
  SET_QUERY: 'SET_QUERY',
  UPDATE_PREVIOUS_QUERIES: 'UPDATE_PREVIOUS_QUERIES',
  CLEAR_PREVIOUS_QUERIES: 'CLEAR_PREVIOUS_QUERIES',
  TOGGLE_HEADER_SEARCH_BAR: 'TOGGLE_HEADER_SEARCH_BAR',
  SET_INLINE_SEARCH_BAR_VISIBILITY: 'SET_INLINE_SEARCH_BAR_VISIBILITY'
};

function useSearchDispatchHelpers() {
  const dispatch = useSearchDispatch();

  const setQuery = (query: string) => dispatch({
    type: ACTIONS.SET_QUERY,
    payload: { query }
  });

  const updatePreviousQueries = (query: string) => dispatch({
    type: ACTIONS.UPDATE_PREVIOUS_QUERIES,
    payload: { query }
  });

  const clearPreviousQueries = () => dispatch({
    type: ACTIONS.CLEAR_PREVIOUS_QUERIES,
  });

  const toggleHeaderSearchBar = (isActive: boolean) => dispatch({
    type: ACTIONS.TOGGLE_HEADER_SEARCH_BAR,
    payload: { isActive }
  });

  const setInlineSearchBarVisibility = (isVisible: boolean) => dispatch({
    type: ACTIONS.SET_INLINE_SEARCH_BAR_VISIBILITY,
    payload: { isVisible }
  });

  return {
    setQuery,
    updatePreviousQueries,
    clearPreviousQueries,
    toggleHeaderSearchBar,
    setInlineSearchBarVisibility,
  };
}

export {
  SearchProvider,
  useSearchStore,
  useSearchDispatch,
  useSearchDispatchHelpers
};
