import React from 'react';
import classnames from 'classnames';

import styles from './BlogBlockContainer.module.scss';

interface Props {
  className?: string,
  innerClassName?: string,
  children?: React.ReactNode
}

const BlogBlockContainer = (props: Props) => {

  const { className, innerClassName, children } = props;

  return (
    <div className={classnames(styles.block, className)}>
      <div className={classnames(styles.blockInner, innerClassName)}>
        {children}
      </div>
    </div>
  );
};

export default BlogBlockContainer;
