import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';

import type { IOrder } from 'types';

import styles from './OrderDeliveryBadge.module.scss';

interface Props {
  className?: string,
  order?: IOrder
}

const OrderDeliveryBadge = (props: Props) => {

  const {
    className,
    order
  } = props;

  const { deliveryNotice } = order || {};

  const {
    icon,
    htmlDescription: text,
    bgColor: backgroundColor,
    titleColor: textColor,
  } = deliveryNotice || {};

  if (!text) return null;

  return (
    <div
      className={classnames(styles.root, className)}
      style={{
        backgroundColor,
        color: textColor,
        '--text-color': textColor
      } as React.CSSProperties}
    >
      {/* Icon */}
      <div className={styles.icon}>
        <Image
          src={icon}
          fill
          style={{
            objectFit: 'contain'
          }}
          alt="Notice Icon"
        />
      </div>

      {/* Text */}
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default OrderDeliveryBadge;
