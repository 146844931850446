import { RECIPES_LANDING_PAGE_DATA_SLOT_TYPES } from './recipes-landing-page-response';

import type {
  IStrapiResponsiveImage,
  IStrapiSeoImage
} from './generic';

import type { IRecipeCard } from './recipe-card';
import type { SlotMetadataType } from './recipes-landing-page-response';

import type { ValueOf } from '../utility';

// Recipes Landing Page

export interface IRecipesLandingPage {
  details: IRecipesLandingPageDetails,
  //
  hero: IRecipesLandingPageHero,
  //
  slots: IRecipesLandingPageSlot[],
  //
  seo: IRecipesLandingPageSeo,
  //
  __meta: any,
  __data: any
}

// --- Details

export interface IRecipesLandingPageDetails {
  title: string,
  date: string
}

// --- Hero

export interface IRecipesLandingPageHero {
  title: string,
  description: string,
  tags: IRecipesLandingPageHeroTags,
  banner: IStrapiResponsiveImage
}

// ------ Hero Image Tags

export type IRecipesLandingPageHeroTags = IRecipesLandingPageHeroTag[];

export interface IRecipesLandingPageHeroTag {
  id: number,
  slug: string,
  label: string
}

// --- Slot

// ------ Slot Types

export const RECIPES_LANDING_PAGE_SLOT_TYPES = {
  [RECIPES_LANDING_PAGE_DATA_SLOT_TYPES.RECIPES_CAROUSEL]: 'recipes_carousel',
} as const;

export type RecipesLandingPageSlotType = ValueOf<typeof RECIPES_LANDING_PAGE_SLOT_TYPES>;

export type IRecipesLandingPageSlot = (
  IRecipesLandingPageCarouselSlot
)

// ------ Generic Slot

interface IRecipesLandingPageGenericSlot {
  type: RecipesLandingPageSlotType,
  data: any
}

// ------ Recipes Carousel Slot

interface IRecipesLandingPageCarouselSlot extends IRecipesLandingPageGenericSlot {
  type: typeof RECIPES_LANDING_PAGE_SLOT_TYPES[typeof RECIPES_LANDING_PAGE_DATA_SLOT_TYPES.RECIPES_CAROUSEL],
  data: {
    title: string,
    metadata: IRecipesLandingPageSlotMetadata,
    link?: IRecipesLandingPageSlotLink,
    recipes: IRecipeCard[]
  }
}

// --- Slot Link

export interface IRecipesLandingPageSlotLink {
  url: string,
  text: string
}

// --- Slot Metadata

export interface IRecipesLandingPageSlotMetadata {
  type: SlotMetadataType,
  tag?: string
}

// --- Seo

export interface IRecipesLandingPageSeo {
  title: string,
  description: string,
  images: IStrapiSeoImage[]
}
