import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { Button, Modal, Popover } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useOrderMutation } from 'hooks/data/useOrderMutation';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IOrder, Size } from 'types';

import styles from './OrderInvoices.module.scss';

interface Props {
  className?: string,
  order: IOrder
}

const OrderDetailsInvoices = (props: Props) => {
  const {
    className,
    order
  } = props;

  const {
    tokenValue,
    invoices
  } = order;

  // Refs

  const ref = React.useRef();

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const [selected, setSelected] = React.useState();

  const {
    isOpen, open, close, toggle
  } = useIsOpen();

  const { downloadInvoice } = useOrderMutation();

  // Handlers

  const handleClick = (invoice) => {
    const { url } = invoice;
    setSelected(url);
    downloadInvoice({
      url,
      tokenValue,
      onError: () => Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'downloadInvoiceError' }),
      onSuccess: (data) => {
        if (data?.url) {
          window.open(data.url, '_newtab');
        }
        close();
      },
      onSettled: () => setSelected(null)
    });
  };

  // Empty

  if (isArrayEmpty(invoices)) return null;

  // Single Invoice

  if (invoices.length === 1) {
    return (
      <OrderInvoice
        className={classnames(styles.invoice, className)}
        contentClassName={styles.invoiceLabel}
        //
        size="medium"
        loading={selected === invoices[0]?.url}
        label={t('account:ORDER_HISTORY.DOWNLOAD_INVOICE')}
        invoice={invoices[0]}
        //
        onClick={() => handleClick(invoices[0])}
      />
    );
  }

  // Invoices List

  const InvoicesList = invoices.map((invoice, index) => (
    <OrderInvoice
      key={`${invoice?.url}${index}`}
      className={classnames(styles.invoice, className)}
      size="medium"
      loading={selected === invoice.url}
      label={invoice?.label}
      contentClassName={styles.invoiceLabel}
      onClick={() => handleClick(invoice)}
    />
  ));

  // Responsive Render

  if (isBreakpoint) {
    return (
      <>
        {/* View Invoice Button */}
        <Button
          className={className}
          variant="secondary"
          size="medium"
          startIcon="file-text"
          endIcon="chevron-down"
          onClick={open}
        >
          {t('account:ORDER_HISTORY.DOWNLOAD_INVOICE')}
        </Button>

        {/* Invoices Modal */}
        <Modal
          isOpen={isOpen}
          className={styles.modal}
          contentClassName={styles.modalContent}
          title={t('account:ORDER_HISTORY.AVAILABLE_INVOICES')}
          breakpoint="md"
          desktopProps={{
            position: 'center'
          }}
          responsiveProps={{
            position: 'bottom'
          }}
          close={close}
        >
          <div className={styles.modalBody}>
            {InvoicesList}
          </div>
        </Modal>
      </>
    );
  }

  // Popover Content

  const content = (
    <div className={styles.popoverBody}>
      <div className={styles.popoverTitle}>
        {t('account:ORDER_HISTORY.AVAILABLE_INVOICES')}
      </div>
      {InvoicesList}
    </div>
  );

  // Render

  return (
    <div ref={ref}>
      <Popover
        isOpen={isOpen}
        persist
        parentElement={ref || undefined}
        contentClassName={styles.popover}
        align="center"
        positions={['bottom']}
        content={content}
        onClickOutside={close}
      >
        <Button
          variant="secondary"
          startIcon="file-text"
          size="medium"
          endIcon="chevron-down"
          onClick={toggle}
          className={classnames(styles.invoiceBtn, className)}
        >
          {t('account:ORDER_HISTORY.DOWNLOAD_INVOICE')}
        </Button>
      </Popover>
    </div>
  );
};

// Components

// --- Order Invoice

// TODO: migrate to ButtonProps
interface OrderInvoiceProps {
  className: string,
  contentClassName: string,
  size: Size,
  invoice?: any,
  selectedInvoice?: any,
  label: string,
  loading: boolean,
  onClick: () => void
}

export const OrderInvoice = (props: OrderInvoiceProps) => {
  const {
    selectedInvoice,
    invoice,
    label,
    ...rest
  } = props;
  return (
    <Button
      size="medium"
      variant="secondary"
      startIcon="file-text"
      {...rest}
    >
      {label}
    </Button>
  );
};

export default OrderDetailsInvoices;
