import { Api } from 'common/http';
import { getPlaceSearchAddress, getPlaceSearchQuery } from 'helpers/PlacesHelpers';

import type {
  IPlaceDetails, IPlaceSuggestions, Nullable, PlaceDetailsQuery
} from 'types';

export const getPlaceSuggestions = async (query: Nullable<string>) => {
  const { data } = await Api.get<IPlaceSuggestions>(`/address-auto-complete/search?streetName=${query}`);
  return data;
};

export const getPlaceDetails = async (params: PlaceDetailsQuery) => {
  const {
    id, street, number, city, province, lat, lng
  } = params || {};

  if (id) {
    const { data } = await Api.get<IPlaceDetails>(`/address-auto-complete/place/${id}`);
    return data;
  }

  const address = getPlaceSearchAddress([street, number]);
  const query = getPlaceSearchQuery({
    city, province, lat, lng
  });

  const { data } = await Api.get<IPlaceDetails>(`/address-auto-complete/find/${address}?${query}`);
  return data;
};
