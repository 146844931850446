import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Icon, Popover } from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import OrderByOptions from './OrderByOptions';

import type { IListingOrderBy, IListingOrderByOption, IUrl } from 'types';

import styles from './OrderByDesktop.module.scss';

interface Props {
  className: string,
  withLabel?: boolean,
  label?: string,
  orderBy: IListingOrderBy,
  //
  getHref: (option: IListingOrderByOption) => IUrl
}

const OrderByDesktop = (props: Props) => {

  const {
    className,
    //
    label,
    withLabel,
    orderBy,
    //
    getHref,
  } = props;

  // Refs

  const ref = React.useRef<HTMLDivElement>(null);

  // Hooks

  const { t } = useTranslation();
  const { isOpen, close, toggle } = useIsOpen();

  // Handlers

  const onOptionChange = () => {
    close();
  };

  // Render

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        className
      )}
    >

      {/* Prefix */}
      {
        withLabel && (
          <div className={styles.prefix}>
            {t('LISTING_TOOLBAR.ORDER_BY.LABEL')}
          </div>
        )
      }

      {/* Popover */}
      <Popover
        isOpen={isOpen}
        persist
        parentElement={ref as any} // TODO: fix type
        containerClassName={styles.popoverContainer}
        contentClassName={styles.popover}
        align="end"
        positions={['bottom', 'top']}
        content={(
          <>
            {/* Title */}
            <div className={styles.popoverTitle}>
              {t('LISTING_TOOLBAR.ORDER_BY.LABEL_SHORT')}
            </div>

            {/* Options */}
            <OrderByOptions
              orderBy={orderBy}
              getHref={getHref}
              onChange={onOptionChange}
            />
          </>
        )}
        onClickOutside={close}
      >
        <Button
          className={styles.selectBtn}
          type="button"
          endIcon={withLabel ? ChevronIcon : SortIcon}
          onClick={toggle}
        >
          {label}
        </Button>
      </Popover>
    </div>
  );
};

// Icons

const SortIcon = <Icon size={20} name="arrows-sort" className={styles.sortIcon} />;
const ChevronIcon = <Icon size={16} name="chevron-down" className={styles.chevronIcon} />;

// Export

export default OrderByDesktop;
