import { useTranslation } from 'next-i18next';

import { Button, ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';

import styles from './OrderProblemModal.module.scss';

const OrderProblemSuccess = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation();
  return (
    <ImageMessage
      className={styles.success}
      image={IMAGES.COMMON.SUCCESS}
      imageProps={{
        width: 100,
        height: 100,
        alt: 'Success icon'
      }}
      title={t('account:ORDER_HISTORY.REPORT_ISSUE_SUCCESS_TITLE')}
      subtitle={t('account:ORDER_HISTORY.REPORT_ISSUE_SUCCESS_SUBTITLE')}
    >
      <Button variant="primary" onClick={closeModal}>{t('ACTIONS.BACK')}</Button>
    </ImageMessage>
  );
};

export default OrderProblemSuccess;
