import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'order_by_events';

enum Actions {
  APPLY = 'apply',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function applyOrderBy(field: string, direction: string) {
  push(Actions.APPLY, `${field}-${direction}`);
}
