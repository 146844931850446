import React from 'react';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Badge, Button, Icon, Image, Link
} from 'components/ui';

import { useAnalytics } from 'hooks/useAnalytics';

import type { IMenuItemLink } from 'types';

import styles from './MenuItemLink.module.scss';

interface Props {
  className?: string,
  activeClassName?: string,
  iconWrapperClassName?: string,
  iconClassName?: string,
  labelClassName?: string,
  //
  item: IMenuItemLink,
  active?: boolean,
  //
  onClick?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseEnter?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseLeave?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  //
  children?: React.ReactNode
}

const MenuItemLink = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>((
  props: Props,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) => {

  const {
    className,
    activeClassName,
    iconWrapperClassName,
    iconClassName,
    labelClassName,
    //
    item,
    active,
    //
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    //
    children,
    ...rest
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const { t } = useTranslation();

  const router = useRouter();

  // Props

  const {
    href,
    matchHref,
    exact,
    icon,
    iconSize,
    iconImage,
    label,
    badge,
    endIcon,
    translate = true,
    dataTestId
  } = item;

  const { asPath, query } = router;
  const { originalPathname = '' } = query || {};

  const isActive = isLinkActive({
    asPath,
    originalPathname,
    href,
    matchHref,
    exact,
  });

  // Classes

  const classes = classnames(
    styles.root,
    { [styles.active]: active || isActive },
    { [activeClassName]: active || isActive },
    { [styles.withImage]: !!icon || !!iconImage },
    className
  );

  // Inner

  const Inner = (
    <div className={styles.inner}>

      {/* Image */}
      {
        iconImage && (
          <span className={classnames(styles.image, iconClassName)}>
            <Image
              src={iconImage}
              width={22}
              height={22}
            />
          </span>
        )
      }

      {/* Start Icon */}
      {
        icon && (
          <MenuItemIcon
            className={classnames(styles.startIconWrapper, iconWrapperClassName)}
            iconClassName={iconClassName}
            icon={icon}
            iconSize={iconSize}
          />
        )
      }

      {/* Label */}
      <span
        className={classnames(
          styles.label,
          labelClassName
        )}
      >
        {translate ? t(label) : label}
      </span>

      {/* Badge */}
      {
        badge && (
          <Badge
            className={styles.badge}
            style={{
              backgroundColor: badge.backgroundColor,
              color: badge.textColor
            }}
          >
            {t(badge.text)}
          </Badge>
        )
      }

      {/* End icon */}
      {
        endIcon && (
          <MenuItemIcon
            icon={endIcon}
            className={styles.endIconWrapper}
            iconClassName={classnames(styles.endIcon, iconClassName)}
          />
        )
      }

    </div>
  );

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    analytics.selectMenuButton(t(label));

    if (onClick) {
      onClick(item, e);
    }
  };

  // Without Link

  if (!href) {
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={classes}
        type="button"
        onClick={(e) => onClick(item, e)}
        onMouseEnter={(e) => onMouseEnter(item, e)}
        onMouseLeave={(e) => onMouseLeave(item, e)}
        data-testid={dataTestId}
        {...rest}
      >
        <div className={styles.content}>
          {Inner}
          {children}
        </div>
      </button>
    );
  }

  // With Link

  return (
    <Link
      href={href}
      prefetch={false}
      passHref
    >
      <Button
        ref={ref as React.Ref<HTMLAnchorElement>}
        tag="a"
        className={classes}
        contentClassName={styles.content}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleClick(e)}
        onMouseEnter={(e: React.MouseEvent<HTMLAnchorElement>) => onMouseEnter(item, e)}
        onMouseLeave={(e: React.MouseEvent<HTMLAnchorElement>) => onMouseLeave(item, e)}
        data-testid={dataTestId}
      >
        {Inner}
        {children}
      </Button>
    </Link>
  );
});

interface MenuItemIconProps {
  className?: string,
  iconClassName?: string,
  icon: string | React.ReactNode,
  iconSize?: number
}

const MenuItemIcon = (props: MenuItemIconProps) => {
  const {
    className,
    iconClassName,
    icon,
    iconSize = 24,
  } = props;

  return (
    <div className={classnames(styles.iconWrapper, className)}>
      {
        typeof icon === 'string'
          ? (
            <Icon
              className={classnames(styles.icon, iconClassName)}
              size={iconSize}
              name={icon}
            />
          )
          : icon
      }
    </div>
  );
};

// Helpers

export const isLinkActive = ({
  asPath,
  href,
  matchHref,
  exact,
  originalPathname,
}) => {
  return href === '/'
    ? asPath === href || asPath === matchHref
    : exact
      ? asPath === href || asPath === matchHref
      : (
        asPath.startsWith(href)
        || asPath.startsWith(matchHref)
        || originalPathname.startsWith(href)
        || originalPathname.startsWith(matchHref)
      );
};

// Export

export default MenuItemLink;
