import dynamic from 'next/dynamic';

export { default as ProductAddToCart } from './ProductAddToCart/ProductAddToCart';
export { default as ProductBadge } from './ProductBadge/ProductBadge';
export { default as ProductBanner } from './ProductBanner/ProductBanner';
export { default as ProductFavoritesButton } from './ProductFavoritesButton/ProductFavoritesButton';
export { default as ProductImage } from './ProductImage/ProductImage';
export { default as ProductLabel } from './ProductLabel/ProductLabel';
export { default as ProductLabels } from './ProductLabels/ProductLabels';
export { default as ProductPrice } from './ProductPrice/ProductPrice';
export { default as ProductQuantityOverlay } from './ProductQuantityOverlay/ProductQuantityOverlay';
export { default as ProductReplaceButton } from './ProductReplaceButton/ProductReplaceButton';
export { default as ProductSimilarProductsButton } from './ProductSimilarProductButton/ProductSimilarProductButton';
export { default as ProductTag } from './ProductTag/ProductTag';
export { default as ProductTaxes } from './ProductTaxes/ProductTaxes';

export const ProductTags = dynamic(() => import('./ProductTags/ProductTags'), { ssr: false });
