export const LOCAL_STORAGE_KEYS = {
  CONSENT: 'consent',
  COOKIE_POLICY: 'cp',
  DO_NOT_TRACK: 'dnt',
  GENIUS_PROMO_BANNER: 'gpb',
  GENIUS_TRIAL: 'gt3m',
  NEWSLETTER_MODAL: 'nm',
  OPEN_IN_APP_BANNER: 'oiab',
  ORDER_ID: 'orderId',
  PARTIAL_ADDRESS: 'partialAddress',
  RECENT_SEARCHES: 'recentSearchKeywords',
  REFRESH_TOKEN: 'rt',
  USER_DETAILS: 'ud',
  VERIFY_PHONE_NUMBER: 'vpn',
  FRESHFUL_AUTOMATION: 'freshfulAutomation',

  // Debug
  DEBUG_ANALYTICS: 'debugAnalytics',
  DEBUG_CONSENT: 'debugConsent',
  DEBUG_EXPERIMENTS: 'debugExperiments',
  DEBUG_WEB_VITALS: 'debugWebVitals',

  // Feature flags
  FEATURE_FLAGS: 'featureFlags',

  // Seasonal
  SNOWFALL_PAUSED: 'sfp',

} as const;

export const LOCAL_STORAGE_LEGACY_KEYS = {
  ADDRESS_POSTPONED: 'isAddressCheckPostponed',
  CHRISTMAS_SCHEDULE: 'cs2021',
  GENIUS_PROMO_BANNER: 'gpb',
} as const;

export const SESSION_STORAGE_LEGACY_KEYS = {
  CHRISTMAS_SCHEDULE_DISMISSED: 'isChristmasScheduleModalDismissed'
} as const;

export const WINDOW_KEYS = {
  COOKIE_POLICY: 'cookie_policy',
  FUNCTION: 'f',
} as const;

export const COOKIE_KEYS = {
  CONSENT: 'consent'
};
