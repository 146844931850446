import { isClient } from 'helpers/BrowserHelpers';

const log = (...args: Parameters<typeof console.log>) => print('log', ...args);

const warn = (...args: Parameters<typeof console.warn>) => print('warn', ...args);

const info = (...args: Parameters<typeof console.info>) => print('info', ...args);

const error = (...args: Parameters<typeof console.error>) => print('error', ...args);

const print = (type = 'log', message = '', payload = {}, options: {clientSide?: boolean} = {}) => {
  const { clientSide } = options;
  const fn = console[type];
  const e = type === 'error' ? new Error() : { stack: '' };
  if (!isClient()) {
    process.stdout.write(`${JSON.stringify({
      type,
      '@message': message,
      payload: JSON.stringify(payload),
      trace: e.stack,
      processId: process?.pid,
      uuid: generateUUID()
    })}\n`);
  } else if (clientSide) {
    fn(message, payload);
  }
};

const generateUUID = () => {
  let
    d = new Date().getTime();
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise, no-mixed-operators, eqeqeq
    return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
};

export default {
  log,
  warn,
  info,
  error
};
