import { APP_ROUTES } from 'constants/routes';

import { PageType } from 'types';

interface PageMapData {
  path: string,
  filteredPath?: string,
  mobilePreviewPath?: string,
  name: string // to be used for analytics
}

export const PAGES_MAP: Record<PageType, PageMapData> = {
  // Core
  [PageType.Home]: {
    path: APP_ROUTES.HOME,
    name: PageType.Home
  },
  [PageType.HomePreview]: {
    path: APP_ROUTES.HOME_PREVIEW,
    name: PageType.HomePreview
  },
  [PageType.HomeRedirect]: {
    path: APP_ROUTES.HOME_REDIRECT,
    name: PageType.HomeRedirect
  },
  [PageType.Category]: {
    path: APP_ROUTES.CATEGORIES,
    filteredPath: APP_ROUTES.CATEGORIES_FILTERED,
    name: PageType.Category
  },
  [PageType.CustomListing]: {
    path: APP_ROUTES.CUSTOM_LISTING,
    filteredPath: APP_ROUTES.CUSTOM_LISTING_FILTERED,
    name: PageType.CustomListing
  },
  [PageType.Product]: {
    path: APP_ROUTES.PRODUCTS,
    name: PageType.Product
  },
  [PageType.Search]: {
    path: APP_ROUTES.SEARCH,
    filteredPath: APP_ROUTES.SEARCH,
    name: PageType.Search
  },
  [PageType.Checkout]: {
    path: APP_ROUTES.CHECKOUT,
    name: PageType.Checkout
  },
  [PageType.CheckoutSuccess]: {
    path: APP_ROUTES.CHECKOUT_SUCCESS,
    name: PageType.CheckoutSuccess
  },
  [PageType.CheckoutSuccessRedirect]: {
    path: APP_ROUTES.CHECKOUT_SUCCESS,
    name: PageType.CheckoutSuccessRedirect
  },
  [PageType.AcceleratedSale]: {
    path: APP_ROUTES.ACCELERATED_SALE,
    filteredPath: APP_ROUTES.ACCELERATED_SALE_FILTERED,
    name: PageType.AcceleratedSale
  },
  [PageType.NewProducts]: {
    path: APP_ROUTES.NEW_PRODUCTS,
    filteredPath: APP_ROUTES.NEW_PRODUCTS_FILTERED,
    name: PageType.NewProducts
  },
  [PageType.Promotions]: {
    path: APP_ROUTES.PROMOTIONS,
    filteredPath: APP_ROUTES.PROMOTIONS_FILTERED,
    name: PageType.Promotions
  },
  [PageType.Promotion]: {
    path: APP_ROUTES.PROMOTIONS,
    filteredPath: APP_ROUTES.PROMOTIONS_FILTERED,
    name: PageType.Promotion
  },
  [PageType.Favorites]: {
    path: APP_ROUTES.FAVORITES,
    name: PageType.Favorites
  },
  [PageType.Freshlist]: {
    path: APP_ROUTES.MY_FRESHLIST,
    name: PageType.Freshlist
  },
  // Account
  [PageType.AccountOrder]: {
    path: APP_ROUTES.ORDER_HISTORY,
    name: PageType.AccountOrder
  },
  [PageType.AccountOrders]: {
    path: APP_ROUTES.ORDER_HISTORY,
    name: PageType.AccountOrders
  },
  [PageType.AccountAddresses]: {
    path: APP_ROUTES.ADDRESSES,
    name: PageType.AccountAddresses
  },
  [PageType.AccountBilling]: {
    path: APP_ROUTES.BILLING,
    name: PageType.AccountBilling
  },
  [PageType.AccountMealVouchers]: {
    path: APP_ROUTES.MEAL_VOUCHERS,
    name: PageType.AccountMealVouchers
  },
  [PageType.AccountDetails]: {
    path: APP_ROUTES.ACCOUNT,
    name: PageType.AccountDetails
  },
  [PageType.AccountFreshclub]: {
    path: APP_ROUTES.MY_FRESHCLUB,
    name: PageType.AccountFreshclub
  },
  [PageType.AccountFreshclubOptOut]: {
    path: APP_ROUTES.MY_FRESHCLUB_OPT_OUT,
    name: PageType.AccountFreshclubOptOut
  },
  [PageType.AccountBenefits]: {
    path: APP_ROUTES.BENEFITS,
    name: PageType.AccountBenefits
  },
  [PageType.AccountVouchers]: {
    path: APP_ROUTES.VOUCHERS,
    name: PageType.AccountVouchers
  },
  [PageType.AccountVoucherDetails]: {
    path: APP_ROUTES.VOUCHERS,
    name: PageType.AccountVoucherDetails
  },
  [PageType.AccountPromotions]: {
    path: APP_ROUTES.ACCOUNT_PROMOTIONS,
    name: PageType.AccountPromotions
  },

  // Recipes

  [PageType.RecipeDetails]: {
    path: APP_ROUTES.RECIPES_DETAILS,
    name: PageType.RecipeDetails
  },
  [PageType.RecipesByTag]: {
    path: APP_ROUTES.RECIPES_BY_TAG,
    name: PageType.RecipesByTag
  },
  [PageType.RecipesList]: {
    path: APP_ROUTES.RECIPES_LIST,
    filteredPath: APP_ROUTES.RECIPES_LIST_FILTERED,
    name: PageType.RecipesList
  },
  [PageType.RecipesLanding]: {
    path: APP_ROUTES.RECIPES_LANDING,
    name: PageType.RecipesLanding
  },

  // Genius

  [PageType.Genius]: {
    path: APP_ROUTES.GENIUS,
    mobilePreviewPath: APP_ROUTES.GENIUS_MOBILE,
    name: PageType.Genius
  },
  [PageType.GeniusMobilePreview]: {
    path: APP_ROUTES.GENIUS_MOBILE,
    name: PageType.Genius
  },
  [PageType.GeniusBenefits]: {
    path: APP_ROUTES.GENIUS_BENEFITS,
    name: PageType.GeniusBenefits,
    mobilePreviewPath: APP_ROUTES.GENIUS_BENEFITS_MOBILE
  },
  [PageType.GeniusBenefitsMobilePreview]: {
    path: APP_ROUTES.GENIUS_BENEFITS_MOBILE,
    name: PageType.GeniusBenefitsMobilePreview
  },
  [PageType.EmagGenius]: {
    path: APP_ROUTES.EMAG_GENIUS,
    mobilePreviewPath: APP_ROUTES.EMAG_GENIUS_MOBILE,
    name: PageType.EmagGenius
  },
  [PageType.EmagGeniusMobilePreview]: {
    path: APP_ROUTES.EMAG_GENIUS_MOBILE,
    name: PageType.EmagGeniusMobilePreview
  },
  [PageType.GeniusFastCheckoutMobilePreview]: {
    path: APP_ROUTES.GENIUS_FAST_CHECKOUT_MOBILE,
    name: PageType.GeniusFastCheckoutMobilePreview,
  },

  // Static
  [PageType.Static]: {
    path: APP_ROUTES.STATIC,
    mobilePreviewPath: APP_ROUTES.STATIC_MOBILE,
    name: PageType.Static
  },
  [PageType.StaticMobilePreview]: {
    path: APP_ROUTES.STATIC_MOBILE,
    name: PageType.StaticMobilePreview
  },
  [PageType.Contact]: {
    path: APP_ROUTES.CONTACT,
    mobilePreviewPath: APP_ROUTES.CONTACT_MOBILE,
    name: PageType.Upgreat
  },
  [PageType.ContactMobilePreview]: {
    path: APP_ROUTES.CONTACT_MOBILE,
    name: PageType.UpgreatMobilePreview
  },
  [PageType.About]: {
    path: APP_ROUTES.ABOUT,
    mobilePreviewPath: APP_ROUTES.ABOUT_MOBILE,
    name: PageType.About
  },
  [PageType.AboutMobilePreview]: {
    path: APP_ROUTES.ABOUT_MOBILE,
    name: PageType.AboutMobilePreview
  },

  // Careers

  [PageType.Careers]: {
    path: APP_ROUTES.CAREERS,
    mobilePreviewPath: APP_ROUTES.CAREERS_MOBILE,
    name: PageType.Careers
  },
  [PageType.CareersMobilePreview]: {
    path: APP_ROUTES.CAREERS_MOBILE,
    name: PageType.CareersMobilePreview
  },

  // Upgreat

  [PageType.Upgreat]: {
    path: APP_ROUTES.UPGREAT,
    mobilePreviewPath: APP_ROUTES.UPGREAT_MOBILE,
    name: PageType.Upgreat
  },
  [PageType.UpgreatMobilePreview]: {
    path: APP_ROUTES.UPGREAT_MOBILE,
    name: PageType.UpgreatMobilePreview
  },

  // Actiuni de bine

  [PageType.GoodDeeds]: {
    path: APP_ROUTES.BLOG_GOOD_DEEDS,
    mobilePreviewPath: APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE,
    name: PageType.GoodDeeds
  },
  [PageType.GoodDeedsMobilePreview]: {
    path: APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE,
    name: PageType.GoodDeedsMobilePreview
  },
  [PageType.GoodDeedsPost]: {
    path: APP_ROUTES.BLOG_GOOD_DEEDS,
    mobilePreviewPath: APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE,
    name: PageType.GoodDeedsPost
  },
  [PageType.GoodDeedsPostMobilePreview]: {
    path: APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE,
    name: PageType.GoodDeedsPostMobilePreview
  },

  // Landing pages

  [PageType.BlogLandingPage]: {
    path: APP_ROUTES.LANDING_PAGES,
    mobilePreviewPath: APP_ROUTES.LANDING_PAGES_MOBILE,
    name: PageType.BlogLandingPage
  },
  [PageType.BlogLandingPageMobilePreview]: {
    path: APP_ROUTES.LANDING_PAGES_MOBILE,
    name: PageType.BlogLandingPageMobilePreview
  },

  [PageType.LegacyBlogLandingPage]: {
    path: APP_ROUTES.BLOG_FRESHFUL_INSPIRATION,
    mobilePreviewPath: APP_ROUTES.BLOG_FRESHFUL_INSPIRATION_MOBILE,
    name: PageType.LegacyBlogLandingPage
  },
  [PageType.LegacyBlogLandingPageMobilePreview]: {
    path: APP_ROUTES.BLOG_FRESHFUL_INSPIRATION_MOBILE,
    name: PageType.LegacyBlogLandingPageMobilePreview
  },

  // Other
  [PageType.NewsletterUnsubscribe]: {
    path: APP_ROUTES.NEWSLETTER_UNSUBSCRIBE,
    name: PageType.NewsletterUnsubscribe
  },
  [PageType.AbandonedCartUnsubscribe]: {
    path: APP_ROUTES.ABANDONED_CART_UNSUBSCRIBE,
    name: PageType.AbandonedCartUnsubscribe
  },
  [PageType.OrderFeedback]: {
    path: APP_ROUTES.ORDER_FEEDBACK,
    name: PageType.OrderFeedback
  },

  // Errors

  [PageType.Error404]: {
    path: APP_ROUTES.ERROR404,
    name: PageType.Error404
  },
  [PageType.Error500]: {
    path: APP_ROUTES.ERROR500,
    name: PageType.Error500
  },

  // Authentication

  [PageType.Login]: {
    path: APP_ROUTES.LOGIN,
    name: PageType.Login
  },
  [PageType.LoginCheckEmag]: {
    path: APP_ROUTES.LOGIN_CHECK_EMAG,
    name: PageType.LoginCheckEmag
  },
  [PageType.Logout]: {
    path: APP_ROUTES.LOGOUT,
    name: PageType.Logout
  },

  [PageType.Unknown]: {
    path: APP_ROUTES.HOME,
    name: PageType.Unknown
  }
} as const;
