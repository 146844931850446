import React from 'react';
import classnames from 'classnames';

import { Card, Icon, Skeleton } from 'components/ui';

import styles from './PromotionCard.module.scss';

interface Props {
  className?: string,
  cardClassName?: string,
  imageClassName?: string,
  detailsClassName?: string,
  titleClassName?: string,
  datesClassName?: string,
  arrowClassName?: string,
  //
  inline?: boolean,
  fill?: boolean,
  //
  withTitle?: boolean,
  withDates?: boolean,
  withArrow?: boolean
}

const PromotionCardSkeleton = (props: Props) => {

  const {
    className,
    cardClassName,
    imageClassName,
    detailsClassName,
    titleClassName,
    datesClassName,
    arrowClassName,
    //
    inline,
    fill,
    //
    withTitle,
    withDates,
    withArrow,
  } = props;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.fill]: fill },
        styles.skeleton,
        className
      )}
    >

      <Card
        className={classnames(
          styles.card,
          { [styles.hasDetails]: withTitle || withDates },
          { [styles.vertical]: !inline },
          { [styles.horizontal]: inline },
          cardClassName
        )}
        elevation={1}
      >

        {/* Inner */}
        <div className={styles.inner}>

          {/* Image */}
          <div
            className={classnames(
              styles.imageWrapper,
              imageClassName
            )}
          >

            <div className={styles.image}>
              <Skeleton
                className={styles.imageSkeleton}
              />
            </div>
          </div>

          {/* Details */}
          {
            (withTitle || withDates) && (
              <div
                className={classnames(
                  styles.detailsWrapper,
                  detailsClassName
                )}
              >

                <div className={styles.details}>

                  {/* Title */}
                  {
                    withTitle && (
                      <div className={styles.titleWrapper}>
                        <div
                          className={classnames(
                            styles.title,
                            titleClassName
                          )}
                        >
                          <Skeleton
                            className={styles.titleSkeleton}
                            count={2}
                          />
                        </div>
                      </div>
                    )
                  }

                  {/* Dates */}
                  {
                    withDates && (
                      <div className={styles.datesWrapper}>
                        <div
                          className={classnames(
                            styles.dates,
                            datesClassName
                          )}
                        >
                          <Skeleton
                            className={styles.titleSkeleton}
                            count={1}
                          />
                        </div>
                      </div>
                    )
                  }

                </div>

                {/* Arrow */}
                {
                  withArrow && (
                    <div
                      className={classnames(
                        styles.arrow,
                        arrowClassName
                      )}
                    >
                      <Icon
                        name="arrow-narrow-right"
                        size={24}
                        strokeWidth={2}
                      />
                    </div>
                  )
                }

              </div>
            )
          }
        </div>
      </Card>
    </div>
  );
};

export default PromotionCardSkeleton;
