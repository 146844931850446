import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button } from 'components/ui';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderProblemModal from '../OrderProblemModal/OrderProblemModal';
import OrderProductList from '../OrderProductList/OrderProductList';

import OrderDetailsTotal from './OrderDetailsTotal';

import styles from './OrderDetailsSummary.module.scss';

const OrderDetailsSummary = (props) => {
  const {
    className,
    order
  } = props;

  // Hooks

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const [isProblemModalVisible, setIsProblemModalVisible] = React.useState();

  // Handlers

  const openProblemModal = React.useCallback(() => {
    analytics.reportOrderProblem();
    setIsProblemModalVisible(true);
  }, []);

  const closeProblemModal = React.useCallback(() => {
    setIsProblemModalVisible(false);
  }, []);

  // Props

  const reportProblemButtonLabel = OrderHelpers.getReportProblemButtonLabel(order, t);

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Header */}
      <div className={styles.header}>

        {/* Title */}
        <div className={styles.title}>
          {t('account:ORDER_HISTORY.ORDER_SUMMARY')}
        </div>

        {/* Desktop - Report Problem Button */}
        {
          (!isBreakpoint && reportProblemButtonLabel) && (
            <ReportProblemButton
              onClick={openProblemModal}
            >
              {reportProblemButtonLabel}
            </ReportProblemButton>
          )
        }
      </div>

      {/* Content */}
      <div className={styles.content}>

        {/* Products */}
        <OrderProductList
          className={styles.products}
          productClassName={styles.product}
          order={order}
          inline
        />

        {/* Totals */}
        <OrderDetailsTotal order={order} />

      </div>

      {/* Responsive - Actions */}
      {
        isBreakpoint && (
          <div className={classnames(styles.mobileActions, { [styles.empty]: !reportProblemButtonLabel })}>

            {/* Responsive - Order Problem Button */}
            {
              reportProblemButtonLabel && (
                <ReportProblemButton
                  mobile
                  onClick={openProblemModal}
                >
                  {reportProblemButtonLabel}
                </ReportProblemButton>
              )
            }

          </div>
        )
      }

      {/* Report Problem Modal */}
      <OrderProblemModal
        isOpen={isProblemModalVisible}
        order={order}
        handleClose={closeProblemModal}
      />

    </div>
  );
};

// Buttons

const ReportProblemButton = (props) => {
  const { mobile, onClick, children } = props;
  return (
    <Button
      className={classnames(styles.actionBtn, styles.reportBtn)}
      variant={mobile ? 'subtle' : 'empty'}
      size="medium"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default OrderDetailsSummary;
