import React from 'react';

import RSwitch from 'react-switch';
import classnames from 'classnames';

import type { ReactSwitchProps } from 'react-switch';

import type { ValueOf } from 'types';

import styles from './Switch.module.scss';

export const SWITCH_SIZES = {
  DEFAULT: 'default'
} as const;

const SWITCH_SIZES_MAP = {
  [SWITCH_SIZES.DEFAULT]: { height: 24, width: 44, handleDiameter: 20 }
};

interface Props extends ReactSwitchProps {
  containerClassName?: string,
  size?: ValueOf<typeof SWITCH_SIZES>
}

const Switch = (props: Props) => {

  const {
    defaultOnColor,
    defaultOnHandleColor,
    defaultOffColor,
    defaultOffHandleColor,
    defaultActiveBoxShadow
  } = styles;

  const {
    containerClassName,
    checkedIcon = false,
    uncheckedIcon = false,
    onColor = defaultOnColor,
    onHandleColor = defaultOnHandleColor,
    offColor = defaultOffColor,
    offHandleColor = defaultOffHandleColor,
    size = SWITCH_SIZES.DEFAULT,
    ...rest
  } = props;

  const { height, width, handleDiameter } = SWITCH_SIZES_MAP[size] || {};

  return (
    <div className={classnames(styles.root, containerClassName)}>
      <RSwitch
        className="react-switch"
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        onColor={onColor}
        offColor={offColor}
        onHandleColor={onHandleColor}
        offHandleColor={offHandleColor}
        height={height}
        width={width}
        handleDiameter={handleDiameter}
        activeBoxShadow={defaultActiveBoxShadow}
        {...rest}
      />
    </div>
  );
};

export default Switch;
