import { create } from './StoreCreator';

const initialState = {
  visible: false,
  type: null,
  drawerProps: {},
  componentProps: {}
};

const reducer = (draft, action) => {

  const { payload = {}, type } = action;

  switch (type) {
    case ACTIONS.SHOW_DRAWER:
      return {
        ...draft,
        visible: true,
        type: payload.type,
        drawerProps: payload.drawerProps,
        componentProps: payload.componentProps
      };

    case ACTIONS.HIDE_DRAWER:
      return {
        ...draft,
        visible: false,
        type: null,
        drawerProps: {},
        componentProps: {}
      };

    default:
      return draft;
  }
};

const [
  DrawerProvider,
  useDrawerStore,
  useDrawerDispatch
] = create(reducer, initialState);

const ACTIONS = {
  SHOW_DRAWER: 'SHOW_DRAWER',
  HIDE_DRAWER: 'HIDE_DRAWER',
};

function useDrawerDispatchHelpers() {

  const dispatch = useDrawerDispatch();

  const showDrawer = (drawerType, drawerProps = {}, componentProps = {}) => {
    dispatch({
      type: ACTIONS.SHOW_DRAWER,
      payload: {
        type: drawerType,
        drawerProps: {
          ...drawerProps,
          handleClose: (...args) => {
            if (drawerProps?.handleDrawerClose) {
              drawerProps.handleClose(...args);
            }
            hideDrawer();
          },
        },
        componentProps
      }
    });
  };

  const hideDrawer = () => dispatch({ type: ACTIONS.HIDE_DRAWER });

  return {
    showDrawer,
    hideDrawer,
  };
}

export {
  DrawerProvider,
  useDrawerStore,
  useDrawerDispatch,
  useDrawerDispatchHelpers,
  ACTIONS as DRAWER_ACTIONS,
};
