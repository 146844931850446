import React from 'react';
import classnames from 'classnames';

import { ProductThumbnails } from 'components/product';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';

import * as OrderHelpers from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';

import CartButton from './CartButton';

import type { IGiftProduct, IOrderGiftProduct } from 'types';

import styles from './CartGiftsButton.module.scss';

interface Props {
  wrapperClassName?: string,
  className?: string,
  iconClassName?: string,
  orderTokenValue: string,
  title: string,
  gifts: IGiftProduct[]
}

const CartGiftsButton = (props: Props) => {
  const {
    wrapperClassName,
    className,
    iconClassName,
    orderTokenValue,
    title,
    gifts,
  } = props;

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Handlers

  const onClick = React.useCallback((e) => {
    e.preventDefault();
    showModal(MODAL_TYPES.ORDER_GIFTS, {
      order: {
        tokenValue: orderTokenValue
      },
      gifts
    });
  }, [showModal, orderTokenValue]);

  // Props

  const validGifts = OrderHelpers.getValidGifts(gifts);
  const thumbnails: IOrderGiftProduct[] = ProductHelpers.getOrderGiftProducts(validGifts);

  const label = `${title} ${validGifts?.length}/${gifts?.length}`;

  // Flags

  const hasGifts = !isArrayEmpty(gifts);

  // Empty

  if (!hasGifts) return null;

  // Render

  return (
    <div className={classnames(styles.root, wrapperClassName)}>
      <CartButton
        className={classnames(styles.btn, className)}
        iconClassName={classnames(styles.icon, iconClassName)}
        icon="gift"
        iconColor={styles.colorGift}
        label={label}
        onClick={onClick}
        afterContent={
          !hasGifts
            ? null
            : (
              <div className={styles.thumbnailsWrapper}>
                <ProductThumbnails
                  className={styles.thumbnails}
                  thumbnailClassName={styles.thumbnail}
                  extraThumbnailClassName={styles.extra}
                  products={thumbnails}
                  desktopMaxCount={5}
                  responsiveMaxCount={5}
                  size={48}
                />
              </div>
            )
        }
      />
    </div>
  );
};

export default CartGiftsButton;
