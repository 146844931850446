export const RECIPE_CAROUSEL_BREAKPOINTS = {
  358.98: {
    slidesPerView: 1.15,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  419.98: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  759.98: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  1139.98: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 14,
  },
  1259.98: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 14,
  }
};

export const HOMEPAGE_RECIPE_CAROUSEL_BREAKPOINTS = {
  ...RECIPE_CAROUSEL_BREAKPOINTS,
  1259.98: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 14,
  }
};

export const RECIPE_DETAILS_CAROUSEL_BREAKPOINTS = {
  359.98: {
    slidesPerView: 1.15,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  529.98: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  849.98: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  1023.98: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 14,
  },
  1199.98: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 14,
  }
};

export const INGREDIENTS_CAROUSEL_BREAKPOINTS = {
  259.98: {
    slidesPerView: 1.15,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  359.98: {
    slidesPerView: 1.15,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  389.98: {
    slidesPerView: 1.5,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  499.98: {
    slidesPerView: 2.5,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  589.98: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  719.98: {
    slidesPerView: 3.5,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  849.98: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 8,
  },
  1023.98: {
    slidesPerView: 2.5,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  1099.98: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  1199.98: {
    slidesPerView: 3.5,
    slidesPerGroup: 3,
    spaceBetween: 14,
  },
  1299.98: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 14,
  }
};
