import React from 'react';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { useNotificationsDispatch } from 'context/NotificationsContext';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { isElementInViewport } from 'helpers/ScrollHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useProductCardActions } from 'hooks/useProductCardActions';

import { ProductAddToCart, ProductFavoritesButton, ProductSimilarProductsButton } from '../parts';

import type {
  IAnalyticsListProps,
  IProductDetails,
  IProductVariant
} from 'types';

import styles from './ProductDetailsActions.module.scss';

interface Props {
  className?: string,
  //
  product: IProductDetails,
  variant: IProductVariant,
  listProps: IAnalyticsListProps,
  //
  onSimilarClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  onEnterViewport?: () => void,
  onLeaveViewport?: () => void
}

const ProductDetailsActions = (props: Props) => {

  const {
    className,
    //
    product,
    variant,
    listProps = {},
    //
    onSimilarClick,
    onEnterViewport,
    onLeaveViewport,
  } = props;

  const ref: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);

  // Hooks

  // --- Visibility tracking

  const { observe } = useInView({
    onEnter: ({ scrollDirection }) => {
      if (scrollDirection.vertical === 'down' && onEnterViewport) {
        onEnterViewport();
      }
    },
    onLeave: ({ scrollDirection }) => {
      if (scrollDirection.vertical === 'up' && onLeaveViewport) {
        onLeaveViewport();
      }
    },
  });

  // --- Data Hooks

  const { isLoading: isOrderLoading, data: order } = useActiveOrder();

  // --- Context Hooks

  const { setCustomToastContainerProps } = useNotificationsDispatch();

  // --- Breakpoints

  const isVerboseBreakpoint = useMediaQuery(`
    (min-width: ${styles.breakpointSm1}) and (max-width: ${styles.breakpointSm}),
    (min-width: ${styles.breakpointMd1})
  `);

  // Handlers

  const {
    isProductActionLoading,
    isProductActionError,
    //
    handleAdd,
    handleQuantityChange,
  } = useProductCardActions({
    order,
    product,
    variant,
    listProps,
    //
    bypassQuantityVariants: true
  });

  // Props

  const isLoading = isOrderLoading || isProductActionLoading;

  const quantity = React.useMemo(() => OrderHelpers.getOrderItemQuantity(order, variant), [order, variant]);

  const { isAvailable } = variant || {};

  // Effects

  React.useEffect(() => {
    const isInView = isElementInViewport(ref.current);
    if (isInView && onEnterViewport) {
      onEnterViewport();
    } else if (!isInView && onLeaveViewport) {
      onLeaveViewport();
    }
  }, [onEnterViewport, onLeaveViewport]);

  React.useEffect(() => {
    setCustomToastContainerProps({ className: styles.favoritesToast });
    return () => {
      setCustomToastContainerProps({});
    };
  }, []);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      ref={(el) => {
        ref.current = el;
        if (onEnterViewport || onLeaveViewport) {
          observe(el);
        }
      }}

    >
      <div className={styles.buttonsContainer}>

        {/* Favorites Button */}
        <ProductFavoritesButton
          withBorder
          order={order}
          product={product}
          listProps={listProps}
        />

        {/* Actions */}
        <div className={styles.actions}>
          {
            (!isAvailable && onSimilarClick)
              ? (
                <>
                  {/* View Similar Products */}
                  <ProductSimilarProductsButton
                    className={styles.similarButton}
                    variant="secondary"
                    size="large"
                    onClick={onSimilarClick}
                  />
                </>
              )
              : (
                <>
                  <ProductAddToCart
                    //
                    product={variant}
                    quantity={quantity}
                    //
                    isAddToCartLoading={isLoading}
                    isAddToCartDisabled={isLoading || isOrderLoading}
                    shouldReset={isProductActionError}
                    //
                    size="large"
                    isPrimary
                    isVerbose={isVerboseBreakpoint}
                    withAddToCartText
                    withAddToCartIcon
                    //
                    onAddToCartClick={handleAdd}
                    onQuantityChange={handleQuantityChange}
                  />
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsActions;
