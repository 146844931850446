import type { IAnalyticsListProps } from 'types/misc';
import type { IProduct } from 'types/product';
import type { ValueOf } from 'types/utility';

export interface ISlotLink {
  title: string,
  count: number,
  url: string
}

export const SlotTypes = {
  PRODUCTS_CAROUSEL: 'products_carousel'
} as const;

export type SlotType = ValueOf<typeof SlotTypes>;

export interface ISlot extends IAnalyticsListProps {
  image?: string,
  link: ISlotLink,
  products: IProduct[],
  title: string,
  type: SlotType
}
