import { useAuthStore } from 'context/AuthenticationContext';

export function useIsAuthenticated() {
  const {
    //
    isFetching,
    isSettled,
    //
    refreshToken,
    accessToken,
    //
  } = useAuthStore();

  return {
    //
    accessToken,
    refreshToken,
    //
    isFetching,
    isSettled: !refreshToken || isSettled,
    isAuthenticated: !isFetching && !!accessToken,
    //
  };
}
