import React from 'react';
import classnames from 'classnames';

import { KEY_CODES } from 'constants/keycodes';

import type { Size } from 'types';

import styles from './ProductAddToCart.module.scss';

interface Props {
  className?: string,
  //
  size: Size,
  //
  isCompact?: boolean,
  isEditMode?: boolean,
  isVerbose?: boolean,
  isRounded?: boolean,
  //
  value: string,
  //
  disabled: boolean,
  hasError: boolean,
  //
  onChange: (value: number) => void,
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void,
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
  onEnter: () => void,
  onEsc: () => void
}

const ProductQuantityInput = React.forwardRef<HTMLInputElement, Props>((
  props,
  ref
) => {
  const {
    className,
    //
    size,
    //
    isCompact,
    isEditMode,
    isVerbose,
    isRounded,
    //
    value,
    disabled,
    hasError,
    //
    onChange,
    onFocus,
    onBlur,
    onEnter,
    onEsc,
  } = props;

  // Handlers

  // --- KeyDown

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.keyCode === KEY_CODES.ENTER) {
      (ref as React.RefObject<HTMLInputElement>)?.current?.blur();
      onEnter();

    } else if (e.keyCode === KEY_CODES.ESC) {
      (ref as React.RefObject<HTMLInputElement>)?.current?.blur();
      onEsc();
    }
  };

  // --- Change

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = e?.target?.value?.replace(/[^\d+]/, '');
    const updatedValue = eventValue ? parseInt(eventValue, 10) : 0;
    onChange(updatedValue);
  };

  // Render

  return (
    <input
      ref={ref}
      className={classnames(
        styles.input,
        styles.quantityInput,
        { [styles.compact]: isCompact },
        { [styles.rounded]: isRounded },
        { [styles.fixed]: !isVerbose },
        { [styles.error]: hasError },
        { [styles[size]]: size },
        className
      )}
      //
      type="text"
      inputMode="numeric"
      maxLength={3}
      // regex="^[0-9]*$"
      //
      value={value}
      disabled={disabled}
      //
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      //
      data-prevent-manual-edit-close={isEditMode}
    />
  );
});

export default ProductQuantityInput;
