import type React from 'react';

import type {
  CarouselSlotType,
  IAnalyticsListProps,
  IImage, IRecipeCardData, Nullable, ValueOf
} from 'types';

// Product

export interface IProduct {
  // general
  code: string,
  name: string,
  sku: string,
  slug: string,
  variantCode: string,

  // brand
  brand?: string,
  brandCode?: string,
  brandFilterCode?: string,

  // flags
  isAvailable: boolean,
  isSponsored?: boolean,
  isGift: boolean,

  // pricing
  currency: string,
  currencyCode: string,
  discountLabel?: string,
  geniusDiscountLabel?: string,
  geniusPrice?: number,
  geniusUnitPricePerLabel?: string,
  originalPrice?: number,
  price: number,
  priceIcon?: string,
  priceLabel?: string,
  priceTooltip?: IProductPriceTooltip,
  priceLabelColor?: string,
  promotionalPrice?: number,
  taxes: IProductTaxes,
  unitPriceLabel: string,

  // save me
  saveMe?: Nullable<IProductSaveMe>,

  // sponsored
  sponsored?: IProductSponsored,

  // quantity
  maxAllowedQuantity: number,
  maxAvailableQuantity: number,
  canAddMultiple?: boolean,
  canAddMultipleType?: Nullable<'quantity' | 'bmsm'>,

  // elements
  activePromotion?: IProductPromotion,
  badge?: IProductBadge,
  banner?: IProductBanner,
  breadcrumbs: IProductBreadcrumb[],
  image: IProductImage,
  images: IProductImage[],
  label?: IProductLabelItem,
  labels?: IProductLabel[],
  labelsV2?: IProductLabel[],
  infoLabels?: IProductLabel[],
  tags?: IProductTag[]
}

// Save Me

type ProductSaveMeProps =
  | 'variantCode'
  | 'isAvailable'
  | 'originalPrice'
  | 'price'
  | 'unitPriceLabel'
  | 'maxAllowedQuantity'
  | 'maxAvailableQuantity'
  | 'badge'
  | 'labels'
  | 'labelsV2'
  | 'infoLabels';

export interface IProductSaveMe extends Pick<IProduct, ProductSaveMeProps> {
  priceColor: string,
  priceIcon: string
}

// Product Variant

interface IDiscountLabelConfig {
  text: string,
  backgroundColor: string,
  textColor: string
}

export interface IProductVariant {
  // general
  variantCode: string,
  slug: string,

  // flags
  isAvailable: boolean,
  isDefault?: boolean,
  isAcceleratedSale?: boolean,

  // pricing
  currency: string,
  discountLabel?: Nullable<string>,
  discountLabelWithConfig?: Nullable<IDiscountLabelConfig>,
  geniusDiscountLabel?: string,
  geniusPrice?: number,
  geniusUnitPrice?: Nullable<string>,
  geniusDiscountLabelConfig?: Nullable<IDiscountLabelConfig>,
  quantityOptionsDiscountLabel?: Nullable<IDiscountLabelConfig>,
  geniusUnitPricePerLabel?: string,
  hasGeniusPrice: boolean,
  originalPrice?: number,
  originalPriceStrikethrough: boolean,
  price: number,
  priceColor?: string,
  priceIcon?: string,
  priceLabel?: string,
  priceLabelColor?: string,
  priceTooltip?: IProductPriceTooltip,
  promotionalPrice?: number,
  unitPriceLabel: string,

  // quantity
  maxAvailableQuantity: number,
  maxAllowedQuantity: number,

  // elements
  image: IProductImage,
  images: IProductImage[],
  labels: IProductLabel[],
  secondaryLabels?: IProductLabel[],
  badge?: IProductBadge,
  banner?: IProductBanner,
  infoLabels?: IProductLabel[],

  // misc
  sponsored?: IProductSponsored
}

// Product Quantity Variant

export interface IProductQuantityVariantsResponse {
  productName: string,
  image: IProductImage,
  label?: string,
  products: IProductQuantityVariant[],
  banner?: Nullable<IProductBanner>
}

type CommonQuantityVariantKeys =
  | 'variantCode'
  | 'image'
  | 'currency'
  | 'currencyCode'
  | 'originalPrice'
  | 'price'
  | 'geniusPrice'
  | 'taxes'
  | 'discountLabel'
  | 'geniusDiscountLabel';

export interface IProductQuantityVariant extends Pick<IProduct, CommonQuantityVariantKeys > {
  title: string,
  quantityLabel?: string,
  quantity: number,
  unitPrice?: string,
  originalUnitPrice?: Nullable<string>,
  geniusUnitPrice?: Nullable<string>,
  geniusDiscountLabelConfig?: {
    text: string,
    backgroundColor: string,
    textColor: string
  },
  quantityOptionsDiscountLabel?: {
    text: string,
    backgroundColor: string,
    textColor: string
  }
}

// Product Details

// TODO: complete
export interface IProductDetails extends IProduct, IAnalyticsListProps {
  url: string,

  // elements
  images: IProductImage[],
  slots: IProductDetailsSlot[],

  // tabs
  attributeGroups: IProductAttributeGroups,
  commercialDisclaimer: string,

  // meta
  metaTitle: string,
  metaKeywords: string,
  metaDescription: string
}

// Order Product

export interface IOrderProduct {
  // general
  id: number,
  mergeVersion: number,
  productName: string,
  productSlug: string,
  sku: string,
  variant: string,
  variantCode: string,
  variantName: string,

  // flags
  isAccSale: boolean,
  isGift: boolean,
  isLoading?: boolean,
  isOk: boolean,

  // brand
  brand?: string,
  brandCode?: string,

  // pricing
  discountedUnitPrice?: number,
  discountedUnitPriceInt?: number,
  hasGeniusDiscount: boolean,
  originalSubtotal?: number,
  originalUnitPrice?: number,
  priceColor?: string,
  priceLabel?: string,
  priceTooltip?: IProductPriceTooltip,
  priceLabelColor?: string,
  priceIcon?: string,
  subtotal: number,
  subtotalInt: number,
  taxTotal: number,
  total: number,
  unitPrice: number,
  unitPriceLabel: string,
  taxes: IProductTaxes,

  // quantity
  maxAllowedQuantity: number,
  maxAvailableQuantity: number,
  quantity: number,
  canAddMultiple?: boolean,
  canAddMultipleType?: Nullable<'quantity' | 'bmsm'>,

  // elements
  badge?: IProductBadge,
  breadcrumbs: IProductBreadcrumb[],
  image: IImage,
  labels?: IProductLabel[],
  textTags?: IProductTextTag[],

  // replacements
  replacements?: IOrderProductReplacement[],
  message?: string,

  // misc
  comment?: string,
  error?: string,

  // state
  state?: OrderProductState
}

export type IOrderProductReplacement = Pick<
IOrderProduct,
'badge' | 'breadcrumbs' | 'id' | 'image' | 'productName' | 'productSlug' | 'quantity' | 'sku' | 'subtotal' | 'variantCode' | 'variantName'
>;

// Gift

export interface IOrderGiftProduct extends Pick<IProduct, 'code' | 'name' | 'sku' | 'slug'> {
  id?: number,
  image?: IImage | null
}

export interface IGiftProduct {
  product: IProduct,
  removed: boolean
}

// Favorite

export interface IFavoriteProduct {
  code: string
}

// Generic Product

export interface WithRender {
  render?: (item: IOrderProduct | IProduct) => React.ReactNode
}

export type IGenericProduct = (IOrderProduct | IProduct) & WithRender;

// Elements

// --- Attribute Groups

export type IProductAttributeGroups = IProductAttributeGroup[];

export interface IProductAttributeGroup {
  title: string,
  attributeTypes: IProductAttributeType[]
}

export const PRODUCT_ATTRIBUTE_TYPES = {
  TEXT: 'text',
  TABLE: 'table',
  NESTED_TABLE: 'nestedTable'
} as const;

export type ProductAttributeType = ValueOf<typeof PRODUCT_ATTRIBUTE_TYPES>

export interface IProductAttributeType {
  attributes: IProductAttribute[],
  subtitle?: string,
  title?: string,
  type: ProductAttributeType
}

export interface IProductAttribute {
  name: string,
  value: string,
  children: {
    name: string,
    value: string
  }[]
}

// --- Badge

export const ProductBadgeItemTypes = {
  PREFIX: 'prefix',
  SUFFIX: 'suffix',
} as const;

export type ProductBadgeItemType = ValueOf<typeof ProductBadgeItemTypes>;

export interface IProductBadgeItem {
  type: ProductBadgeItemType,
  bgColor: string,
  color: string,
  text: string
}

export const ProductBadgeTypes = {
  FRESHPOINTS: 'freshpoint'
} as const;

export type ProductBadgeType = ValueOf<typeof ProductBadgeTypes>;

export interface IProductBadge {
  type?: ProductBadgeType,
  data: any,
  items: IProductBadgeItem[]
}

// --- Banner

export const InfoBannerTypes = {
  PROMOTION: 'promo',
  ACCELERATED_SALE: 'saveMe'
} as const;

export type InfoBannerType = ValueOf<typeof InfoBannerTypes>;

export interface IProductBanner {
  type: InfoBannerType,
  analyticsEventPayload?: any,
  analyticsEventType?: string,
  icon?: string,
  text: string,
  link: string,
  bgColor: string,
  textColor: string,
  tooltip?: Nullable<{
    title: string,
    value: string
  }>
}

// --- Breadcrumb

export interface IProductBreadcrumb {
  groupClassName?: string,
  icon?: string,
  code: string,
  slug: string,
  name: string
}

// --- Sponsored

export interface IProductSponsored {
  labels: IProductLabel[],
  position: number,
  billed: boolean
}

// --- Label

export interface IProductLabel {
  type: ProductLabelType,
  items: IProductLabelItem[]
}

export interface IProductLabelItem {
  icon?: string,
  text: string,
  textColor?: string,
  backgroundColor?: string
}

export const ProductLabelTypes = {
  INFO: 'v1',
  COMPOSITE: 'v2',
  SIMPLE: 'v3',
  GENIUS_DEALS: 'gdeals',
  SPONSORED: 'sponsored',
} as const;

export type ProductLabelType = ValueOf<typeof ProductLabelTypes>;

// --- Image

export interface IProductImage {
  extralarge: IImage,
  large?: IImage,
  thumbnail?: IImage
}

// --- Promotion

export interface IProductPromotion {
  code: string,
  name: string,
  targetQuantity: number
}

// --- Tag

export interface IProductTag {
  backgroundColor: string,
  icon: string,
  iconPng: string,
  text: string,
  textColor: string,
  type: string
}

// --- Text Tag

export interface IProductTextTag {
  backgroundColor: string,
  text: string,
  textColor: string
}

// --- Pricing

export interface IProductPricing {
  //
  originalPrice?: number,
  price: number,
  unitPriceLabel: number,
  //
  discountLabel?: string,
  //
  priceLabel?: string,
  priceLabelColor?: string,
  //
  priceColor?: string,
  priceIcon?: string,
  priceTooltip?: IProductPriceTooltip,
  //
  geniusPrice?: number,
  geniusUnitPricePerLabel?: string,
  geniusDiscountLabel?: string,
  //
  promotionalPrice?: number
}

// ------ Price Tooltip

export interface IProductPriceTooltip {
  title?: string,
  message?: string,
  bgColor?: string,
  textColor?: string
}

// ------ Taxes

export const PRODUCT_TAX_TYPES = {
  SGR: 'srg',
} as const;

export type ProductTaxType = ValueOf<typeof PRODUCT_TAX_TYPES>;

export interface IProductTax {
  type: ProductTaxType,
  text: string,
  icon: string,
  iconPng: string,
  backgroundColor: string,
  textColor: string
}

export type IProductTaxes = IProductTax[];

export interface IProductDetailsGenericSlot extends IAnalyticsListProps {
  type: CarouselSlotType,
  title: string
}

export interface IProductDetailsProductsCarouselSlot extends IProductDetailsGenericSlot {
  type: CarouselSlotType,
  products?: IProduct[],
  recipes?: IRecipeCardData[]
}

export type IProductDetailsSlot = (
  IProductDetailsProductsCarouselSlot
);

export const ORDER_SUMMARY_PRODUCT_STATES = {
  DELETED: 'deleted',
  REPLACED: 'replaced',
  PARTIALLY_REPLACED: 'partial_replaced',
  DECREASED_QUANTITY: 'decreased',
  QUANTITY_CHANGED: 'quantity_changed'
} as const;

export type OrderProductState = ValueOf<typeof ORDER_SUMMARY_PRODUCT_STATES>

export type IProductQuantityLimits = Pick<IProduct, 'maxAvailableQuantity' | 'maxAllowedQuantity'>
