import React from 'react';
import { isMobile } from 'react-device-detect';
import { debounce } from 'throttle-debounce';
import classnames from 'classnames';

import { Menu } from 'components/navigation';
import { Scrollbar } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { useCategories } from 'hooks/data/useCategories';
import { useAnalytics } from 'hooks/useAnalytics';

import type {
  ICategory,
  IMegaMenuController,
  IMenuItemLink,
  ValueOf
} from 'types';

import styles from './MegaMenu.module.scss';

const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
} as const;

type Direction = ValueOf<typeof DIRECTIONS>;

interface Props {
  menuRef: React.Ref<HTMLDivElement>,
  //
  className?: string,
  scrollbarClassName?: string,
  menuClassName?: string,
  itemClassName?: string,
  linkClassName?: string,
  //
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  onSelectedItemChange: (item: IMenuItemLink | undefined) => void
}

const MegaMenu = React.forwardRef<IMegaMenuController, Props>((
  props,
  ref
) => {

  const {
    menuRef,
    //
    className,
    scrollbarClassName,
    menuClassName,
    itemClassName,
    linkClassName,
    //
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onSelectedItemChange = () => {},
  } = props;

  // Refs

  const scrollableRef = React.useRef<HTMLDivElement>(null);
  const selectedItemRef = React.useRef<IMenuItemLink>();
  const directionRef = React.useRef<Direction>();
  const pageXRef = React.useRef<number | null>(null);

  // Hooks

  const analytics = useAnalytics();

  const [selectedItem, setSelectedItem] = React.useState<IMenuItemLink>();

  // Data Hooks

  const { data: categoriesTree } = useCategories();

  React.useImperativeHandle(ref, () => ({
    clearSelectedItem: () => {
      setSelectedItem(undefined);
    },
    resetScroll: () => {
      scrollableRef.current?.scrollTo(0, 0);
    }
  }));

  // Effects

  React.useEffect(() => {
    onSelectedItemChange(selectedItem);
  }, [selectedItem]);

  // Props

  const items = React.useMemo(() => {
    if (!categoriesTree?.length) return [];
    return categoriesTree
      .map((category, index) => parseItem(category, index, APP_ROUTES.CATEGORIES, true));
  }, [categoriesTree]);

  // Helpers

  const updateSelection = () => {
    if (selectedItemRef.current) {
      setSelectedItem(selectedItemRef.current);
    }
  };

  // Handlers

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const BUFFER = 2;

    if (!pageXRef.current) {
      pageXRef.current = e.pageX;
    }

    directionRef.current = pageXRef.current + BUFFER < e.pageX ? 'right' : 'left';
    pageXRef.current = e.pageX;
  };

  // Debounced Handlers

  const longDebouncedUpdateSelection = debounce(100, updateSelection);
  const shortDebouncedUpdateSelection = debounce(20, updateSelection);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >

      <Scrollbar
        className={classnames(styles.scrollbar, scrollbarClassName)}
        autoHide={!isMobile}
        forceVisible="y"
        scrollableNodeProps={{
          ref: scrollableRef
        }}
      >

        <Menu
          ref={menuRef}
          //
          className={classnames(styles.menu, menuClassName)}
          itemClassName={classnames(styles.menuItem, itemClassName)}
          linkClassName={classnames(styles.menuLink, linkClassName)}
          linkSelectedClassName={styles.selected}
          linkIconClassName={styles.menuIcon}
          //
          selectedId={selectedItem?.id}
          items={items}
          //
          withoutSublinks
          //
          onItemClick={(item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            if (isMobile) {
              e.preventDefault();
            }
            if (selectedItem?.slug === item?.slug) {
              setSelectedItem(undefined);
            } else {
              setSelectedItem(item);
            }
            if (item?.label) {
              analytics.selectCategory(item?.label);
            }
          }}
          onItemMouseEnter={(item: IMenuItemLink) => {
            selectedItemRef.current = item;

            if (directionRef.current === 'right') {
              longDebouncedUpdateSelection();
              shortDebouncedUpdateSelection.cancel({ upcomingOnly: true });
            } else {
              shortDebouncedUpdateSelection();
              longDebouncedUpdateSelection.cancel({ upcomingOnly: true });
            }
          }}
          onItemMouseLeave={() => {
            selectedItemRef.current = undefined;
          }}
        />
      </Scrollbar>
    </div>
  );
});

// Helpers

const parseItem = (
  item: ICategory,
  categoryIndex: number,
  baseHref: string,
  withImage: boolean
): IMenuItemLink => {
  const {
    slug,
    name: label,
    iconPng,
    banner,
    children,
  } = item;

  return {
    href: `${baseHref}/${slug}`,
    id: slug,
    categoryIndex,
    slug,
    label,
    iconImage: withImage ? iconPng : undefined,
    banner,
    children: !children?.length
      ? []
      : children.map((childItem) => {
        return parseItem(childItem, categoryIndex, `${baseHref}/${slug}`, false);
      })
  };
};

// Export

export default MegaMenu;
