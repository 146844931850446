import { Image } from 'components/ui';

import { IMAGES } from 'constants/images';

import styles from './FooterLegalImages.module.scss';

const LegalImages = () => {
  return (
    <div className={styles.root}>
      {
        LEGAL_IMAGES.map((i) => {
          const { href, image } = i;
          return (
            <a className={styles.image} href={href} target="_blank" rel="noopener noreferrer" key={href}>
              <Image
                src={image}
                alt="anpc"
                {...LEGAL_IMAGE_SIZE}
              />
            </a>
          );
        })
      }
    </div>
  );
};

const LEGAL_IMAGE_SIZE = { width: 175, height: 50 };
const LEGAL_IMAGES = [
  {
    href: 'https://anpc.ro/ce-este-sal/',
    image: IMAGES.ANPC.SAL,
  },
  {
    href: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO',
    image: IMAGES.ANPC.SOL,
  }
];

export default LegalImages;
