import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { parseMarkdown } from 'services/MarkdownService';

import type { ReactQueryOptions } from 'types';

export const useParseMarkdown = (markdown: string, queryOptions: ReactQueryOptions<MDXRemoteSerializeResult> = {}) => {
  return useQuery({
    queryKey: getKey(markdown),
    queryFn: () => parseMarkdown(markdown),
    ...queryOptions
  });
};

export const getKey = (markdown: string) => {
  return [
    QUERY_KEYS.MARKDOWN_PARSER,
    markdown,
  ];
};
