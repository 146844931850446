import React from 'react';
import { useTranslation } from 'next-i18next';

import { Modal, ModalBody } from 'components/ui';
import { objectClean } from 'helpers/ObjectHelpers';
import { useUser } from 'hooks/data/useUser';

import AddressAddForm from '../AddressForm/AddressAddForm';
import AddressEditForm from '../AddressForm/AddressEditForm';

import { FIELDS } from '../AddressForm/AddressFormConfig';

import type { IAddress } from 'types';

import styles from './AddressModal.module.scss';

const {
  STREET, NUMBER, STREET_DETAILS, CITY, PROVINCE, GEOLOCATION, CONTACT_NAME, CONTACT_PHONE
} = FIELDS;

interface Props {
  isOpen: boolean,
  isEditMode?: boolean,
  //
  address?: IAddress,
  //
  handleClose: () => void,
  onSuccess: (response: any) => void, // TODO: update once address form is migrated to TS
  onError: (error: any) => void // TODO: update once address form is migrated to TS
}

const AddressModal = (props: Props) => {
  const {
    isOpen,
    isEditMode,
    //
    address = {},
    //
    handleClose,
    onSuccess = () => {},
    onError = () => {},
  } = props;

  const { t } = useTranslation();
  const { data: user } = useUser({ isOptional: true });

  const { fullName, phoneNumber } = user || {};

  const initialValues = {
    [STREET]: '',
    [NUMBER]: '',
    [STREET_DETAILS]: '',
    [PROVINCE]: '',
    [CITY]: '',
    [GEOLOCATION]: '',
    [CONTACT_NAME]: fullName,
    [CONTACT_PHONE]: phoneNumber,
    ...objectClean(address)
  };

  const title = isEditMode
    ? t('ADDRESS.EDIT_DELIVERY_ADDRESS')
    : t('ADDRESS.ADD_DELIVERY_ADDRESS');

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      contentClassName={styles.content}
      innerClassName={styles.inner}
      //
      withPadding={false}
      withCloseButton={false}
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
        isFullScreen: true
      }}
      //
      close={() => {
        handleClose();
      }}
    >
      <ModalBody
        className={styles.body}
        headerProps={{
          title,
          desktopProps: {
            size: 'large',
            withCloseButton: true
          },
          responsiveProps: {
            size: 'medium',
            withBorder: true,
            withCloseButton: true,
          },
          onBack: handleClose,
          onClose: handleClose
        }}
      >
        {
          isEditMode
            ? (
          // Edit
              <AddressEditForm
                address={address}
                onSuccess={onSuccess}
                onCancel={handleClose}
                onError={onError}
              />
            ) : (
          // Add
              <AddressAddForm
                initialValues={initialValues}
                onSuccess={onSuccess}
                onError={onError}
              />
            )
        }
      </ModalBody>
    </Modal>
  );
};

export default AddressModal;
