import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';

interface LayoutStoreState {
  isHomepageMenuVisible: boolean,
  isHomepageSubMenuVisible: boolean,
  isOpenInAppBannerVisible: boolean
}

const initialState: LayoutStoreState = {
  isHomepageMenuVisible: false,
  isHomepageSubMenuVisible: false,
  isOpenInAppBannerVisible: false,
};

const reducers = {
  setHomepageMenuVisibility: (draft: LayoutStoreState, action: IStoreAction) => {
    const { payload } = action;
    return {
      ...draft,
      isHomepageMenuVisible: payload?.isVisible
    };
  },
  setHomepageSubMenuVisibility: (draft: LayoutStoreState, action: IStoreAction) => {
    const { payload } = action;
    return {
      ...draft,
      isHomepageSubMenuVisible: payload?.isVisible
    };
  },
  setOpenInAppBannerVisibility: (draft: LayoutStoreState, action: IStoreAction) => {
    const { payload } = action;
    return {
      ...draft,
      isOpenInAppBannerVisible: payload?.isVisible
    };
  },
};

type Actions = IStoreActions<typeof reducers>;

const [
  LayoutProvider,
  useLayoutStore,
  useDispatch,
  actions
] = createV2<LayoutStoreState, Actions>(reducers, initialState);

function useLayoutDispatch() {

  const dispatch = useDispatch();

  const setHomepageMenuVisibility = (isVisible: boolean) => dispatch(actions.setHomepageMenuVisibility({ isVisible }));
  const setHomepageSubMenuVisibility = (isVisible: boolean) => dispatch(actions.setHomepageSubMenuVisibility({ isVisible }));
  const setOpenInAppBannerVisibility = (isVisible: boolean) => dispatch(actions.setOpenInAppBannerVisibility({ isVisible }));

  return {
    setHomepageMenuVisibility,
    setHomepageSubMenuVisibility,
    setOpenInAppBannerVisibility
  };
}

export {
  LayoutProvider,
  useLayoutStore,
  useLayoutDispatch,
};
