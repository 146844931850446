import React from 'react';

import { InfoBanner } from 'components/ui';

import { InfoBannerTypes } from 'types';

// import styles from './RecipeBanner.module.scss';

interface Props extends React.ComponentProps<typeof InfoBanner> {}

const RecipeBanner = (props: Props) => {

  const {
    banner,
    excludedTypes = []
  } = props;

  const {
    type
  } = banner || {};

  // Empty

  if (!Object.values(InfoBannerTypes).includes(type) || excludedTypes.includes(type)) {
    return null;
  }

  return <InfoBanner {...props} />;
};

export default RecipeBanner;
