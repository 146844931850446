import React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import classnames from 'classnames';

import { Collapsible, Fade } from 'components/animations';
import { Button, Icon, Textarea } from 'components/ui';
import { KEY_CODES } from 'constants/keycodes';
import { useTranslation } from 'hooks/common/useTranslation';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IOrderProduct } from 'types';

import styles from './ProductDeliveryComment.module.scss';

interface Props {
  className?: string,
  textareaClassName?: string,
  //
  orderItem: IOrderProduct,
  //
  onFocus?: (e?: React.MouseEvent<HTMLInputElement>) => void
}

const ProductDeliveryComment = (props: Props) => {

  const {
    className,
    textareaClassName,
    //
    orderItem,
    //
    onFocus = () => {}
  } = props;

  // Hooks

  const { t } = useTranslation();

  const [error, setError] = React.useState<string>();
  const [comment, setComment] = React.useState<string>();
  const [isExpanded, setExpanded] = React.useState<boolean>(false);

  // Refs

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  // Breakpoint

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint_sm})`);

  // Order Hooks

  const { data: order } = useActiveOrder();
  const { isLoading: isMutationLoading, setComment: setProductComment } = useOrderProductMutation();

  const existingComment = orderItem?.comment;

  // Handle comment update

  React.useEffect(() => {
    setComment(existingComment);
  }, [existingComment]);

  // Handle close on click outside

  const containerRef = useOnclickOutside(() => {
    if (!comment || comment === existingComment) {
      setExpanded(false);
    }

    if (!comment) {
      setComment(existingComment);
    }
  });

  // Handlers

  const updateComment = (value: string) => {
    if (!order) return;
    if (existingComment !== value) {
      setProductComment({
        order,
        orderItem,
        comment: value,
        //
        onSuccess: () => {
          setExpanded(false);
          textareaRef?.current?.blur();
        },
      });
    } else {
      setExpanded(true);
    }
  };

  const onSaveClick = () => {
    updateComment(comment || '');
  };

  const onDeleteClick = () => {
    setComment('');
    setError(undefined);

    if (!existingComment) {
      setExpanded(false);
    } else {
      updateComment('');
    }
  };

  // --- Textarea Handlers

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    if (e.target.value?.length > 255) {
      setError(t('VALIDATION_ERRORS.MAX_LENGTH', { count: 255 }));
    } else {
      setError(undefined);
      setComment(e.target.value);
    }
  };

  // --- Workaround to make autofocus place cursor at the end

  const onTextareaFocus = () => {
    if (!isExpanded) {
      setExpanded(true);
      onFocus();
    }
  };

  // --- Submit on enter or close on escape

  const onTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (error) return;
      onSaveClick();

    } else if (e.keyCode === KEY_CODES.ESC) {
      e.preventDefault();
      e.stopPropagation();

      setExpanded(false);
      setComment(existingComment);
      textareaRef?.current?.blur();
    }
  };

  // Props

  const isSaveButtonDisabled = isMutationLoading || (comment === existingComment) || !!error;
  const isDeleteButtonVisible = !isMutationLoading && !!comment;
  const areActionsVisible = isExpanded && !!(comment || existingComment || isMutationLoading);

  // Render

  return (
    <div
      ref={containerRef}
      className={classnames(
        styles.root,
        { [styles.expanded]: isExpanded },
        { [styles.hasError]: !!error },
        className
      )}
    >
      <div className={styles.wrapper}>

        {/* Icon */}
        <Icon name="message-circle" className={styles.icon} size={24} />

        {/* Content */}
        <div className={styles.content}>

          {/* Textarea Container */}
          <div className={styles.textareaContainer}>

            {/* Label */}
            <div
              className={classnames(
                styles.placeholder,
                { [styles.visible]: !isExpanded && !comment && !isMutationLoading }
              )}
            >
              <div className={classnames(styles.label, styles.default)}>
                {t('PRODUCT.COMMENT_LABEL')}
              </div>
            </div>

            {/* Placeholder */}
            <div
              className={classnames(
                styles.placeholder,
                { [styles.visible]: isExpanded && !comment && !isMutationLoading }
              )}
            >
              <div className={classnames(styles.label, styles.default)}>
                {t('PRODUCT.COMMENT_PLACEHOLDER')}
              </div>
            </div>

            {/* Textarea */}
            <Textarea
              ref={textareaRef}
              //
              className={classnames(styles.textarea, { [styles.hidden]: !isExpanded && !comment }, textareaClassName)}
              errorClassName={styles.textareaError}
              //
              withoutForm
              minRows={1}
              maxRows={5}
              spellCheck="false"
              //
              value={comment || ''}
              error={error || ''}
              withCollapsibleError
              //
              onChange={onTextareaChange}
              onKeyDown={onTextareaKeyDown}
              onFocus={onTextareaFocus}
            />
          </div>

          {/* Mobile Actions */}
          {
            isBreakpoint && areActionsVisible && (
              <Actions
                isDeleteVisible={isDeleteButtonVisible}
                isSaveLoading={isMutationLoading}
                isSaveDisabled={isSaveButtonDisabled}
                //
                onSaveClick={onSaveClick}
                onDeleteClick={onDeleteClick}
              />
            )
          }

          {/* Desktop Actions */}
          {
            !isBreakpoint && (
              <Collapsible
                isExpanded={areActionsVisible}
                enterDuration={200}
                exitDuration={200}
                contentEnterDuration={200}
                contentExitDuration={200}
              >
                <Actions
                  isDeleteVisible={isDeleteButtonVisible}
                  isSaveLoading={isMutationLoading}
                  isSaveDisabled={isSaveButtonDisabled}
                  //
                  onSaveClick={onSaveClick}
                  onDeleteClick={onDeleteClick}
                />
              </Collapsible>
            )
          }
        </div>
      </div>
    </div>
  );
};

// Actions

interface ActionsProps {
  isDeleteVisible: boolean,
  isSaveLoading: boolean,
  isSaveDisabled: boolean,
  //
  onSaveClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onDeleteClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const Actions = (props: ActionsProps) => {

  const {
    isDeleteVisible,
    isSaveLoading,
    isSaveDisabled,
    //
    onSaveClick,
    onDeleteClick
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.actionsContainer}>

      {/* Cancel */}
      <Fade
        isVisible={isDeleteVisible}
        enterDuration={200}
        exitDuration={200}
      >
        <Button
          size="small"
          variant="empty"
          className={styles.deleteBtn}
          onClick={onDeleteClick}
        >
          {t('ACTIONS.DELETE')}
        </Button>
      </Fade>

      {/* Save */}
      <Button
        size="small"
        variant="primary"
        loading={isSaveLoading}
        disabled={isSaveDisabled}
        onClick={onSaveClick}
      >
        {t('ACTIONS.SAVE')}
      </Button>

    </div>
  );
};

// Export

export default React.memo(ProductDeliveryComment);
