import type { IListingActiveFilters, IListingActiveOrderBy } from 'types/listing';

export interface IPageUrlParams {
  prefix: string | null,
  base: string | null,
  // Pagination
  isPaginated: boolean,
  page: number,
  // Filters
  filters: IListingActiveFilters,
  filtersCount: number,
  filtersIndexable: boolean,
  isFiltered: boolean,
  // Order by
  orderBy: IListingActiveOrderBy,
  isOrdered: boolean,
  // Search
  searchQuery: string | null,
  hasSearchQuery: boolean,
  // Identifiers
  category: string | null,
  isDefaultCategory: boolean,
  identifier: string | null,
  // Flag for urls without any filters, orderBy or search
  isDefault: boolean,
  // SEO
  indexable: boolean,
  //
  pageType: PageType
}

export interface ISearchPageUrlParams extends IPageUrlParams {}

export interface IAcceleratedSalePageUrlParams extends IPageUrlParams {}

export interface INewProductsPageUrlParams extends IPageUrlParams {}

export interface IRecipesListPageUrlParams extends IPageUrlParams {}

export interface ICustomListingPageUrlParams extends IPageUrlParams {}

export interface ICategoryPageUrlParams extends IPageUrlParams {}

export interface IPromotionPageUrlParams extends IPageUrlParams {}

export interface IRecipesByTagPageUrlParams extends IPageUrlParams {}

export interface IAccountFreshclubUrlParams extends IPageUrlParams {}

export interface IAccountFreshclubOptOutUrlParams extends IPageUrlParams {}

export interface IPageUrlParamsMap {
  [PageType.Search]: ISearchPageUrlParams,
  [PageType.AcceleratedSale]: IAcceleratedSalePageUrlParams,
  [PageType.NewProducts]: INewProductsPageUrlParams,
  [PageType.CustomListing]: ICustomListingPageUrlParams,
  [PageType.Category]: ICategoryPageUrlParams,
  [PageType.Promotion]: IPromotionPageUrlParams,
  [PageType.RecipesList]: IRecipesListPageUrlParams,
  [PageType.RecipesByTag]: IRecipesByTagPageUrlParams,
  [PageType.AccountFreshclub]: IAccountFreshclubUrlParams,
  [PageType.AccountFreshclubOptOut]: IAccountFreshclubOptOutUrlParams,
  [PageType.Unknown]: IPageUrlParams,
  [x: string]: IPageUrlParams
}

export enum PageType {
  // Core
  Home = 'home',
  HomePreview = 'home-preview',
  HomeRedirect = 'home-redirect',
  Category = 'category',
  CustomListing = 'custom-listing',
  Product = 'product',
  Search = 'search',
  Checkout = 'checkout',
  CheckoutSuccess = 'checkout-success',
  CheckoutSuccessRedirect = 'checkout-success-redirect',
  AcceleratedSale = 'accelerated-sale',
  NewProducts = 'new-products',
  Promotions = 'promo-list',
  Promotion = 'promo',
  Favorites = 'favorites',
  Freshlist = 'my-freshlist',
  // Account
  AccountOrder = 'account-order',
  AccountOrders = 'account-orders',
  AccountAddresses = 'account-addresses',
  AccountBilling = 'account-billing',
  AccountMealVouchers = 'account-meal-vouchers',
  AccountDetails = 'account-details',
  AccountFreshclub = 'account-freshclub',
  AccountFreshclubOptOut = 'account-freshclub-opt-out',
  AccountBenefits = 'account-benefits',
  AccountVouchers = 'account-vouchers',
  AccountVoucherDetails = 'account-voucher-details',
  AccountPromotions = 'account-vouchers-promotions', // to be consistent with mobile
  // Genius
  Genius = 'genius',
  GeniusMobilePreview = 'genius-mobile',
  EmagGenius = 'emag-genius',
  EmagGeniusMobilePreview = 'emag-genius-mobile',
  GeniusBenefits = 'genius-benefits',
  GeniusBenefitsMobilePreview = 'genius-benefits-mobile',
  GeniusFastCheckoutMobilePreview= 'genius-fast-checkout-mobile',
  // Recipes
  RecipeDetails = 'simple-recipe-details-page',
  RecipesByTag = 'simple-recipes-by-tag',
  RecipesList = 'simple-recipes-list',
  RecipesLanding = 'simple-recipes-landing',
  //
  OrderFeedback = 'order-feedback',
  NewsletterUnsubscribe = 'newsletter-unsubscribe',
  AbandonedCartUnsubscribe = 'abandoned-cart-unsubscribe',
  // Static pages
  Static = 'static',
  StaticMobilePreview = 'static-mobile',
  // Contact
  Contact = 'contact',
  ContactMobilePreview = 'contact-mobile-preview',
  // About
  About = 'about',
  AboutMobilePreview = 'about-mobile-preview',

  // --- Contentful

  // Actiuni de bine
  GoodDeeds = 'actiuni-de-bine',
  GoodDeedsMobilePreview = 'actiuni-de-bine-mobile',
  GoodDeedsPost = 'actiuni-de-bine-post',
  GoodDeedsPostMobilePreview = 'actiuni-de-bine-post-mobile',

  // Upgreat
  Upgreat = 'new-customers-funnel',
  UpgreatMobilePreview = 'new-customers-funnel-mobile',
  // Careers
  Careers = 'careers',
  CareersMobilePreview = 'careers-mobile',

  // Landing pages
  BlogLandingPage = 'blog-landing-page',
  BlogLandingPageMobilePreview = 'blog-landing-page-mobile',
  LegacyBlogLandingPage = 'blog-inspiratie-freshful',
  LegacyBlogLandingPageMobilePreview = 'blog-inspiratie-freshful-mobile',

  // Errors
  Error500 = '500',
  Error404 = '404',
  // Authentication
  Login= 'login',
  LoginCheckEmag = 'login-check-emag',
  Logout = 'logout',
  // Unknown
  Unknown = 'unknown'
}
