import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button, Image, Link } from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';
import { encodeRedirectState } from 'helpers/UrlHelpers';

import styles from './LoginPanel.module.scss';

const LoginPanel = () => {

  const { t } = useTranslation();
  const router = useRouter();

  const { asPath } = router;

  return (
    <div className={styles.wrapper}>

      {/* Title */}
      <div className={styles.title}>{t('NAVIGATION.ACCOUNT.NOT_LOGGED_IN.TITLE')}</div>

      {/* Subtitle */}
      <div className={styles.subtitle}>{t('NAVIGATION.ACCOUNT.NOT_LOGGED_IN.SUBTITLE')}</div>

      {/* Login Button */}
      <Link href={{ pathname: APP_ROUTES.LOGIN, query: { state: encodeRedirectState({ path: asPath }) } }}>
        <a className={styles.mainButtonWrapper}>
          <Button
            className={styles.mainButton}
          >
            <span>{t('NAVIGATION.ACCOUNT.NOT_LOGGED_IN.CONNECT')}</span>
            <div className={styles.emagLogo}>
              <Image
                src={IMAGES.BRANDING.EMAG_GRADIENT}
                fill
                alt="Emag Logo"
              />
            </div>
          </Button>
        </a>
      </Link>

    </div>
  );

};

export default LoginPanel;
