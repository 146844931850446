import React from 'react';

import { isArray } from 'helpers/ArrayHelpers';

import styles from './Toast.module.scss';

interface Props {
  title?: React.ReactNode,
  message: string[] | string
}

const Toast = (props: Props) => {

  const {
    title,
    message
  } = props;

  return (
    <div className={styles.root}>
      <p className={styles.title}>{title}</p>
      {
        isArray(message)
          ? message.map((text: string) => <Message>{text}</Message>)
          : <Message>{message}</Message>
      }
    </div>
  );
};

interface MessageProps {
  children: React.ReactNode
}

const Message = (props: MessageProps) => {
  const { children } = props;
  return (
    <p className={styles.message}>{children}</p>
  );
};

export default Toast;
