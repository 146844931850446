import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';
import type { ToastContainerProps } from 'react-toastify';

interface NotificationsStoreState {
  customToastContainerProps: Partial<ToastContainerProps>
}

const initialState: NotificationsStoreState = {
  customToastContainerProps: {}
};

const reducers = {
  setCustomToastContainerProps: (draft: NotificationsStoreState, action: IStoreAction<NotificationsStoreState['customToastContainerProps']>) => {
    const { payload } = action;
    return {
      ...draft,
      customToastContainerProps: payload || {}
    };
  }
};

type Actions = IStoreActions<typeof reducers>;

const [
  NotificationsProvider,
  useNotificationsStore,
  useDispatch,
  actions
] = createV2<NotificationsStoreState, Actions>(reducers, initialState);

function useNotificationsDispatch() {

  const dispatch = useDispatch();

  const setCustomToastContainerProps = (props: NotificationsStoreState['customToastContainerProps']) => dispatch(actions.setCustomToastContainerProps(props));
  const resetCustomToastContainerProps = () => setCustomToastContainerProps({});

  return { setCustomToastContainerProps, resetCustomToastContainerProps };
}

export {
  NotificationsProvider,
  useNotificationsStore,
  useNotificationsDispatch,
};
