import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { ERROR_CODES } from 'constants/errors';
import { QUERY_KEYS } from 'constants/query-keys';
import { createListingQueryKey } from 'helpers/ReactQueryHelpers';
import { getCustomListing } from 'services/CustomListingsService';

import type {
  IListingRequestParams, IProductsListing, QueryClient, ReactQueryOptions
} from 'types';

export function useCustomListing(
  params: IListingRequestParams,
  queryOptions: ReactQueryOptions<IProductsListing> = {}
) {

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => getCustomListing(params),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...queryOptions
  });
}

function getKey(params: IListingRequestParams) {
  return createListingQueryKey(QUERY_KEYS.CUSTOM_LISTING, params);
}

export async function prefetchCustomListing(
  queryClient: QueryClient,
  params: IListingRequestParams
) {
  const key = getKey(params);
  try {
    const customListing = await queryClient.fetchQuery({
      queryKey: key,
      queryFn: () => getCustomListing(params)
    });
    if (!customListing) {
      return false;
    }
  } catch (err: any) {
    if (err?.response?.status === ERROR_CODES.NOT_FOUND) { return false; }
  }
  return true;
}
