import * as ErrorHandler from 'common/ErrorHandler';
import { Api, SecureApi } from 'common/http';

interface AuthenticationResponse {
  token: string,
  refresh_token: string,
  customerId: number,
  isGeniusUser: boolean,
  isSignup?: boolean
}

export const login = async (code: string): Promise<AuthenticationResponse> => {
  try {
    const { data } = await Api.post(`/authentication-token?code=${code}`);
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while logging in');
  }
};

export const refreshAccessToken = async (refreshToken: string): Promise<AuthenticationResponse> => {
  try {
    const { data } = await Api.post(`/refresh?refresh_token=${refreshToken}`);
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while refreshing access token');
  }
};

export const verifyAccessToken = async () => {
  return SecureApi.get('/customers/me?verify=true');
};

export default {
  login,
  refreshAccessToken,
  verifyAccessToken
};
