import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import { IMAGE_SIZE } from './ProductCartCard';

import styles from './ProductCartCard.module.scss';

interface Props {
  className?: string
}

const ProductCartCardSkeleton = (props: Props) => {
  const {
    className
  } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.imageContainer}>
        <div className={styles.imageSkeletonWrapper}>
          <Skeleton height={IMAGE_SIZE} width={IMAGE_SIZE} className={styles.imageSkeleton} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentSkeletonWrapper}>
          <Skeleton count={4} height={12} width="100%" className={styles.contentSkeleton} />
        </div>
      </div>
    </div>
  );
};

export default ProductCartCardSkeleton;
