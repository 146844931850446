import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import AccountMenuMobile from 'components/layout/Header/HeaderMobile/AccountMenuMobile';
import { Menu, MenuSkeleton } from 'components/navigation';
import { Accordion, Icon } from 'components/ui';
import { getMobileNavigationItems } from 'constants/navigation';
import { APP_ROUTES } from 'constants/routes';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';

import type { ICategory } from 'types';

import styles from './MenuMobile.module.scss';

interface Props {
  isLoading: boolean,
  categories: ICategory[]
}

const MenuMobile = (props: Props) => {

  const {
    isLoading,
    categories = [],
  } = props;

  const { t } = useTranslation();

  const router = useRouter();
  const analytics = useAnalytics();

  const { data: user } = useUser({ isOptional: true });
  const { isGeniusEligible3M10 } = user || {};

  // Props

  const categoriesMenuItems = React.useMemo(() => getCategoriesMenuItems(categories), [categories]);

  const navItems = React.useMemo(() => {
    return getMobileNavigationItems({
      shouldShowGeniusDiscountIcon: isGeniusEligible3M10
    });
  }, [isGeniusEligible3M10]);

  // Loading

  if (isLoading) {
    return (
      <MenuSkeleton
        className={styles.root}
        count={20}
        withBorders
        withImage
      />
    );
  }

  // Props

  const isCategoryListing = router.asPath.startsWith(`${APP_ROUTES.CATEGORIES}/`) || router.asPath.startsWith(`${APP_ROUTES.CATEGORIES_FILTERED}/`);

  // Render

  return (
    <div className={styles.root} key={router.asPath}>

      {/* Account Menu */}
      <AccountMenuMobile />

      {/* Categories Menu */}
      <Accordion
        withAnimation
        initiallyExpanded={isCategoryListing}
        title={(
          <div className={styles.productsAccordionTitle}>
            <Icon name="category" />
            <div>{t('HEADER.MOBILE.MAIN_MENU_CATEGORIES_TITLE')}</div>
          </div>
        )}
        className={styles.productsAccordion}
        headerClassName={styles.productsAccordionHeader}
        iconClassName={styles.productsAccordionIcon}
        contentClassName={styles.productsAccordionContent}
      >
        <Menu
          className={styles.categoriesMenu}
          items={categoriesMenuItems}
          withBorders
          withAccordion
          withIndent
          dataTestId="main-menu-mobile-categories"
          onItemClick={(item: ICategory) => {
            if (!item?.children?.length) {
              analytics.selectCategory(item?.name);
            }
          }}
        />
      </Accordion>

      {/* Navigation Menu */}
      <Menu
        className={styles.navigationMenu}
        linkClassName={styles.navigationMenuLink}
        items={navItems}
        withBorders
        withAccordion
        withIndent
        dataTestId="main-menu-mobile-navigation"
      />

    </div>
  );
};

function isCategory(item: any): item is ICategory {
  return !!item.slug;
}

function getCategoriesMenuItems(categories: ICategory[]) {
  return categories.map((category) => {
    const {
      name: label, slug, iconPng: icon, children = []
    } = category;

    return {
      href: null,
      slug,
      label,
      name: label,
      image: icon,
      children: !children?.length
        ? []
        : [
          {
            label, name: 'CATEGORIES.ALL', slug: null, exact: true
          },
          ...children
        ].map((child) => {

          if (!isCategory(child)) {
            return {
              href: `${APP_ROUTES.CATEGORIES}/${slug}`,
              slug: child.slug,
              exact: true,
              label: child.name,
              name: child.name,
            };
          }

          return {
            href: `${APP_ROUTES.CATEGORIES}/${slug}/${child.slug}`,
            slug: child.slug,
            name: child.name,
            label: child.name,
            exact: false
          };
        })
    };
  });
}

export default React.memo(MenuMobile);
