import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { RecipePageBanner } from 'components/recipe';
import { Button, Link } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useAnalytics } from 'hooks/useAnalytics';

import type {
  IRecipesLandingPageHero,
  IRecipesLandingPageHeroTag,
} from 'types';

import styles from './RecipesLandingPageHero.module.scss';

interface Props {
  className?: string,
  hero: IRecipesLandingPageHero
}

const RecipesLandingPageHero = (props: Props) => {

  const {
    className,
    hero
  } = props;

  const {
    title,
    description,
    banner,
    tags,
  } = hero || {};

  // Hooks

  const analytics = useAnalytics();

  const { t } = useTranslation();

  // Empty

  if (!title && !description && isArrayEmpty(tags)) return null;

  // Handlers

  const handleTagClick = (tag: IRecipesLandingPageHeroTag) => {
    const { id } = tag;

    analytics.selectRecipeTag(id);
  };

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Banner */}
      <RecipePageBanner
        className={styles.banner}
        banner={banner}
        fill
      />

      {/* Content */}
      <div className={styles.content}>

        {/* Details */}
        <div className={styles.details}>

          {/* Title */}
          <div className={styles.title}>{title}</div>

          {/* Description */}
          <div className={styles.description}>{description}</div>

        </div>

        {/* Tags */}
        {
          !isArrayEmpty(tags) && (
            <div className={styles.tagsWrapper}>
              <div className={styles.tags} data-testid="recipes-lp.tags">
                {
                  tags.map((tag, index) => {
                    const { slug, label } = tag || {};
                    return (
                      <Tag
                        key={`tag-${slug}-${index}`}
                        className={styles.tag}
                        label={label}
                        href={`${APP_ROUTES.RECIPES_BY_TAG}/${slug}`}
                        onClick={() => {
                          handleTagClick(tag);
                        }}
                      />
                    );
                  })
                }
                <Tag
                  className={classnames(styles.tag, styles.primary)}
                  label={t('recipes:RECIPES_LIST.ALL_RECIPES')}
                  href={`${APP_ROUTES.RECIPES_LIST}`}
                  onClick={() => {
                    analytics.selectAllRecipesTag();
                  }}
                />
              </div>
            </div>
          )
        }

      </div>

    </div>
  );
};

interface TagProps {
  className?: string,
  href?: string,
  label?: string,
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export const Tag = (props: TagProps) => {
  const {
    className,
    href,
    label,
    onClick
  } = props;

  return (
    <Link
      href={href}
      passHref
    >
      <Button
        tag="a"
        className={classnames(
          styles.tag,
          className
        )}
        onClick={onClick}
      >
        <span className={styles.tagLabel}>{label}</span>
      </Button>
    </Link>
  );
};

export default RecipesLandingPageHero;
