import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import * as OrdersService from 'services/OrdersService';

export const useOrderFeedbackMutation = () => {
  const mutation = useMutationInternal();
  return {
    isLoading: mutation.isPending,
    isError: mutation.isError,
    //
    clearReminders: (payload) => mutation.mutate({ payload, type: ACTIONS.CLEAR_REMINDERS }),
    sendRating: (payload) => mutation.mutate({ payload, type: ACTIONS.SEND_RATING }),
    sendFeedback: (payload) => mutation.mutate({ payload, type: ACTIONS.SEND_FEEDBACK }),
  };
};

const useMutationInternal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ORDER_FEEDBACK],
    mutationFn: (args = {}) => {

      const { type, payload = {} } = args;
      const {
        tokenValue, rating, fields
      } = payload;

      switch (type) {

        case ACTIONS.SEND_RATING: {
          return OrdersService.sendOrderFeedback(tokenValue, rating);
        }

        case ACTIONS.SEND_FEEDBACK: {
          return OrdersService.sendOrderFeedback(tokenValue, rating, fields, true);
        }

        case ACTIONS.CLEAR_REMINDERS: {
          queryClient.setQueryData([QUERY_KEYS.ORDER_FEEDBACK_REMINDERS], null);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_FEEDBACK_REMINDERS] });
          return;
        }

        default:
          console.error('Unknown mutation type', type);
      }
    },
    onMutate: async () => {
      return {};
    },
    onSuccess: (data, args = {}) => {
      const { payload = {}, type } = args;

      switch (type) {
        case ACTIONS.SEND_RATING: {
          queryClient.setQueryData([QUERY_KEYS.ORDER_FEEDBACK_REMINDERS], null);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_FEEDBACK_REMINDERS] });
          break;
        }

        case ACTIONS.CLEAR_REMINDERS:
        case ACTIONS.SEND_FEEDBACK: {
          break;
        }

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err, args = {}) => {
      const { payload = {} } = args;

      if (payload.onError) {
        payload.onError(err);
      }
    },
    onSettled: (data, error, args = {}) => {
      const { payload = {} } = args;

      if (payload.onSettled) {
        payload.onSettled();
      }
    }
  });
};

const ACTIONS = {
  SEND_RATING: 'SEND_RATING',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  CLEAR_REMINDERS: 'CLEAR_REMINDERS',
};
