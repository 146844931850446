import { HTTPError } from 'ky';

import type { KyInstance, KyResponse, Options } from 'ky';

export type HttpClientOptions = Options;

export type HttpClientConfig = Options & {
  retries?: number,
  retryDelay?: number,
  debug?: boolean,
  isOptional?: boolean
};

export type HttpClientError = HTTPError;

export interface ExtendedKyResponse extends KyResponse {
  data?: any
}

export interface ExtendedHttpClientError extends HTTPError {
  data?: any,
  config?: Options,
  response: ExtendedKyResponse
}

export const isHttpClientError = (error: any): error is ExtendedHttpClientError => {
  return error instanceof HTTPError;
};

export type HttpRequestInterceptor = (request: Request) => void;
export type HttpResponseInterceptor<R = any> = (response: R) => R | Promise<R>;
export type HttpResponseErrorInterceptor<E extends HttpClientError = HttpClientError> = (error: E) => void | Promise<void> | Promise<any>;

export interface HttpClient {
  get: <R = any>(url: string, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,
  post: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,
  put: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,
  patch: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,
  mergePatch: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,
  delete: <R = any>(url: string, requestConfig?: HttpClientConfig) => Promise<{ data: R }>,

  cancelRequest: (url: string) => void,

  addRequestInterceptor: (interceptor: HttpRequestInterceptor) => number,
  addResponseInterceptor: <R = any, E extends HttpClientError = HttpClientError>(
    interceptor: HttpResponseInterceptor<R>,
    errorInterceptor?: HttpResponseErrorInterceptor<E>
  ) => number,
  removeRequestInterceptors: () => void,
  removeResponseInterceptors: () => void,

  getAccessToken: () => string,
  setAccessToken: (token: string) => void,

  getRefreshToken: () => string,
  setRefreshToken: (token: string) => void,

  getPreviewToken: () => string,
  setPreviewToken: (token: string) => void,

  getInstance: () => KyInstance
}

export interface IContentfulApi extends HttpClient {
  fetch: <T = any>(query: string, preview?: boolean, config?: HttpClientConfig) => Promise<{ data: T }>
}

export interface IContentfulRestApi extends HttpClient {
  fetch: <T = any>(url: string, preview?: boolean, config?: HttpClientConfig) => Promise<{ data: T }>
}

/* Strapi */

export interface IStrapiApi extends HttpClient {
  fetch: <T>(query: string, requestConfig?: HttpClientConfig) => Promise<{ data: T }>
}
