import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import * as AddressHelpers from 'helpers/AddressHelpers';

import OrderInfoBlock from '../OrderInfoBlock/OrderInfoBlock';

// import styles from './OrderInfoBilling.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const OrderInfoBilling = (props) => {

  const {
    order = {},
    label,
    withIcon,
    withAddress = true,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { billingAddress } = order;

  const billingCompanyName = billingAddress?.companyName;
  const billingCompanyAddress = AddressHelpers.getAddressLabel(billingAddress);

  if (!billingCompanyName) return null;

  return (
    <OrderInfoBlock
      icon={withIcon ? 'building' : null}
      label={label || t('LABELS.BILLING_DETAILS')}
      value={billingCompanyName}
      subValue={withAddress ? billingCompanyAddress : null}
      {...rest}
    />
  );
};

OrderInfoBilling.propTypes = {
  // specific props
  order: PropTypes.shape({
    billingAddress: PropTypes.shape({})
  }),
  label: PropTypes.string,
  withIcon: PropTypes.bool,

  // block props
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withBorder: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default OrderInfoBilling;
