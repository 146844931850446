import React from 'react';
import classnames from 'classnames';

import { Notice } from 'components/ui';

import type{ INotice } from 'types';

import styles from './CartNotices.module.scss';

interface Props {
  className?: string,
  notices: INotice[]
}

const CartNotices = (props: Props) => {
  const {
    className,
    notices = []
  } = props;

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {
        notices.map((notice, index) => {
          const { icon } = notice;
          return (
            <Notice
              key={index}
              className={classnames(
                styles.notice,
                { [styles.withIcon]: !!icon },
              )}
              iconClassName={styles.noticeIcon}
              notice={notice}
              size="small"
            />
          );
        })
      }
    </div>
  );
};

export default CartNotices;
