import { useTranslation } from 'next-i18next';

import * as Notifications from 'common/Notification';
import { CONFIG } from 'constants/config';
import { APP_ROUTES } from 'constants/routes';
import { getPrechatFormDetails, getPrechatInfo } from 'helpers/ChatHelpers';

import { create } from './StoreCreator';

const isProd = CONFIG.SALESFORCE.CHAT.IS_PROD;

const initialState = {
  buttonProps: {},
  isAgentAvailable: false,
  isLoading: false,
  isButtonVisible: false,
  isMinimized: true,
  onCloseListeners: [],
  context: undefined
};

const reducer = (draft, action) => {
  const { payload, type } = action;

  switch (type) {

    case ACTIONS.SET_AGENT_AVAILABILITY: {
      const { isAgentAvailable } = payload;
      return {
        ...draft,
        isAgentAvailable
      };
    }

    case ACTIONS.SET_BUTTON_VISIBILITY: {
      const { isButtonVisible, context, buttonProps } = payload;
      return {
        ...draft,
        isButtonVisible,
        context,
        buttonProps,
      };
    }

    case ACTIONS.SET_LOADING: {
      const { isLoading } = payload;
      return {
        ...draft,
        isLoading
      };
    }

    default:
      return draft;
  }
};

const [
  ChatProvider,
  useChatStore,
  useChatDispatch
] = create(reducer, initialState);

const ACTIONS = {
  SET_AGENT_AVAILABILITY: 'setAgentAvailability',
  SET_BUTTON_VISIBILITY: 'setButtonVisibility',
  START_CHECKOUT: 'startCheckout',
  START_REPORT_PROBLEM: 'startReportProblem',
  SET_LOADING: 'setLoading'
};

function useChatDispatchHelpers() {

  const { t } = useTranslation();
  const dispatch = useChatDispatch();

  const initializeChat = () => {

    const config = isProd ? CONFIG.SALESFORCE.CHAT.PROD : CONFIG.SALESFORCE.CHAT.DEV;

    const {
      baseCoreURL, communityEndpointURL, orgId, eswConfigDevName, settings
    } = config;

    window.embedded_svc.settings.defaultMinimizedText = t('CHAT.DEFAULT_MINIMIZED_TEXT');
    window.embedded_svc.settings.disabledMinimizedText = t('CHAT.DISABLED_MINIMIZED_TEXT');
    window.embedded_svc.settings.loadingText = t('CHAT.LOADING_TEXT');
    window.embedded_svc.settings.language = 'ro';

    window.embedded_svc.settings.displayHelpButton = false;
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.addEventHandler('onAvailability', (data) => {
      const { isAgentAvailable } = data || {};
      setChatAgentAvailability(isAgentAvailable);
    });

    window.embedded_svc.addEventHandler('onSettingsCallCompleted', (data) => {
      const { isAgentAvailable } = data || {};
      setChatAgentAvailability(isAgentAvailable);
    });

    window.embedded_svc.addEventHandler('sessionDataRetrieved', (data) => {
      const { CHASITOR_SERIALIZED_KEY } = data || {};
      if (CHASITOR_SERIALIZED_KEY) {
        hideChatButton();
      }
    });

    window.embedded_svc.addEventHandler('afterDestroy', () => {
      if (window.location.pathname === APP_ROUTES.CHECKOUT) {
        showChatButton();
      }
    });

    window.embedded_svc.init(
      baseCoreURL,
      communityEndpointURL,
      null,
      orgId,
      eswConfigDevName,
      settings
    );
  };

  const setChatAgentAvailability = (isAgentAvailable) => {
    dispatch({
      type: ACTIONS.SET_AGENT_AVAILABILITY,
      payload: {
        isAgentAvailable
      }
    });
  };

  const showChatButton = (context, buttonProps = {}) => {
    dispatch({
      type: ACTIONS.SET_BUTTON_VISIBILITY,
      payload: {
        isButtonVisible: true,
        context,
        buttonProps,
      }
    });
  };

  const hideChatButton = () => {
    dispatch({
      type: ACTIONS.SET_BUTTON_VISIBILITY,
      payload: {
        isButtonVisible: false,
        context: undefined,
        buttonProps: {},
      }
    });
  };

  const startChat = async (contactDetails, problem) => {
    try {
      const extraPrechatFormDetails = getPrechatFormDetails(contactDetails, problem);
      const extraPrechatInfo = getPrechatInfo(contactDetails, problem);
      await startChatInternal({ extraPrechatFormDetails, extraPrechatInfo });
    } catch (err) {
      Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'reportProblemChatError' });
    }
  };

  const startChatInternal = async (options) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: { isLoading: true } });
    try {
      await window.embedded_svc.liveAgentAPI.startChat(options);
      hideChatButton();
    } catch (err) {
      console.error('Failed to start chat', err);
      throw new Error(err);
    } finally {
      dispatch({
        type: ACTIONS.SET_LOADING,
        payload: {
          isLoading: false
        }
      });
    }
  };

  return {
    initializeChat,
    showChatButton,
    hideChatButton,
    startChat
  };
}

export {
  ChatProvider,
  useChatStore,
  useChatDispatch,
  useChatDispatchHelpers,
  ACTIONS as CHAT_ACTIONS,
};
