import React from 'react';
import classnames from 'classnames';

import MenuItemLinkSkeleton from './MenuItemLinkSkeleton';

import styles from './Menu.module.scss';

interface Props {
  className?: string,
  listClassName?: string,
  itemClassName?: string,
  //
  count?: number,
  //
  withImage?: boolean,
  withBorders?: boolean
}

const MenuSkeleton = (props: Props) => {

  const {
    className, listClassName, itemClassName,
    count = 2,
    withImage,
    withBorders
  } = props;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >
      <ul className={classnames(
        styles.list,
        listClassName
      )}
      >
        {
          Array.from(Array(count)).map((_, index) => (
            <MenuItemLinkSkeleton
              key={index}
              className={classnames(
                styles.item,
                { [styles.withBorder]: withBorders },
                itemClassName
              )}
              withImage={withImage}
            />
          ))
        }
      </ul>
    </div>
  );
};

export default MenuSkeleton;
