import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isFeatureEnabled } from 'helpers/ConfigHelpers';
import { useConfig } from 'hooks/data/useConfig';

export function useFeatureFlag(featureFlagKey: string) {
  const { data: config } = useConfig();
  return {
    isEnabled: isFeatureLocallyEnabled(featureFlagKey) || isFeatureEnabled(config, featureFlagKey)
  };
}

function isFeatureLocallyEnabled(featureKey: string) {
  const features = LocalStorage.get(LOCAL_STORAGE_KEYS.FEATURE_FLAGS) || {};
  return features[featureKey] === true;
}
