import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import * as Notifications from 'common/Notification';
import { Alert, Button } from 'components/ui';
import { ERROR_CODES } from 'constants/errors';
import { useConfig } from 'hooks/data/useConfig';
import { useOrderMutation } from 'hooks/data/useOrderMutation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './OrderRetryPaymentAlert.module.scss';

const RETRY_PAYMENT_ACTION = 'retryPayment';
const RETRY_PAYMENT_KEY = 'RETRY_PAYMENT';

const OrderRetryPaymentAlert = (props) => {
  const {
    className, order, children
  } = props;

  // order.shouldRetryPayment = true;

  const [error, setError] = React.useState();

  const { t } = useTranslation();
  const router = useRouter();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const { data: config } = useConfig();
  const { orderPayments: { labels = {} } = {} } = config || {};
  const { getRetryPaymentLink, isLoading: isMutationLoading } = useOrderMutation();

  const { query: { action } = {} } = router;

  // Effects

  React.useEffect(() => {
    if (action === RETRY_PAYMENT_ACTION) {
      router.replace(window.location.pathname, undefined, { shallow: true });
      onRetryClick();
    }
  }, []);

  // Handlers

  const onRetryClick = () => {
    getRetryPaymentLink({
      tokenValue: order?.tokenValue,
      onSuccess: (data) => {
        if (data?.retryPaymentUrl) {
          window.location.href = data.retryPaymentUrl;
        } else {
          Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'retryPaymentError' });
        }
      },
      onError: (err) => {
        if (err?.response?.status === ERROR_CODES.VALIDATION_ERROR && err?.response?.data?.message) {
          setError(err.response.data.message);
        } else {
          Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'retryPaymentError' });
        }
      }
    });
  };

  // Props

  const message = error
    || labels[RETRY_PAYMENT_KEY]
    || t('account:ORDER_HISTORY.RETRY_PAYMENT_DEFAULT_MESSAGE');

  // Empty

  if (!order?.shouldRetryPayment && !error) {
    return (
      <>
        {children}
      </>
    );
  }

  // Error

  if (error) {
    return (
      <Alert
        className={className}
        variant="error"
        size="large"
        icon="info-circle"
        description={message}
      />
    );
  }

  // Warning

  return (
    <Alert
      className={className}
      variant="warning"
      size="large"
      icon="info-circle"
      description={message}
    >
      <Button
        className={styles.retryBtn}
        variant="primary"
        size={isBreakpoint ? 'medium' : 'small'}
        onClick={onRetryClick}
        disabled={isMutationLoading}
        loading={isMutationLoading}
      >
        {t('account:ORDER_HISTORY.RETRY_PAYMENT')}
      </Button>
    </Alert>
  );
};

export default OrderRetryPaymentAlert;
