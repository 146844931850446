/* eslint-disable react/no-danger */
import React from 'react';
import classnames from 'classnames';

import { Error, ImageMessage, Tabs } from 'components/ui';
import { IMAGES } from 'constants/images';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useSameBrandProducts } from 'hooks/data/useSameBrandProducts';

import ProductsCarousel from '../ProductsCarousel/ProductsCarousel';

import ProductSku from './ProductSku';

import type {
  IProduct,
  IProductAttribute,
  IProductDetails,
  TabsController,
} from 'types';

import {
  PRODUCT_ATTRIBUTE_TYPES
} from 'types';

import styles from './ProductTabs.module.scss';

interface Props {
  sku: string,
  product: IProductDetails,
  //
  collapsed: boolean,
  withoutSameBrandTab: boolean,
  carouselBreakpoints: any, // TODO: add
  //
  onItemClick: () => void
}

const ProductTabs = (props: Props) => {
  const {
    sku,
    product,
    //
    collapsed,
    withoutSameBrandTab,
    carouselBreakpoints,
    //
    onItemClick = () => {},
  } = props;

  // Refs

  const tabsRef = React.useRef<TabsController | null>(null);

  // Hooks

  const { t } = useTranslation();

  // Props

  const {
    brand,
    attributeGroups = [],
    commercialDisclaimer
  } = product;

  // Handlers

  const onTabClick = () => {
    tabsRef?.current?.expand();
  };

  // Render

  return (
    <div className={classnames(styles.root)}>
      <Tabs.Container
        id={styles.tabs}
        maxHeight={collapsed ? 400 : null}
        onInit={(ref) => tabsRef.current = ref}
      >

        {/* Tabs */}
        <div className={styles.tabListWrapper}>
          <Tabs.List className={styles.tabList}>

            {/* Default Tabs */}
            {
              attributeGroups.map((group) => (
                <Tabs.Tab
                  key={group.title}
                  className={styles.tab}
                  onClick={onTabClick}
                >
                  {group.title}
                </Tabs.Tab>
              ))
            }

            {/* Same Brand Tab */}
            {
              (withoutSameBrandTab || !brand)
                ? null
                : (
                  <Tabs.Tab
                    key="tab-brand"
                    className={styles.tab}
                    onClick={onTabClick}
                  >
                    {t('PRODUCT.DETAILS_SAME_BRAND')}
                  </Tabs.Tab>
                )
            }

          </Tabs.List>
        </div>

        {/* Panels */}
        {
          attributeGroups.map((group, tabIndex) => {
            const { attributeTypes = [], title: groupTitle } = group;

            return (
              <Tabs.Panel key={tabIndex + groupTitle} className={styles.tabPanel}>
                <div className={styles.tabContent}>

                  {/* Attributes */}
                  {
                    attributeTypes.map((section, index) => {
                      const {
                        type, title, subtitle, attributes
                      } = section;

                      switch (type) {
                        case PRODUCT_ATTRIBUTE_TYPES.TEXT: {
                          return (
                            <TextContent
                              key={index + type}
                              attributes={attributes}
                            />
                          );
                        }
                        case PRODUCT_ATTRIBUTE_TYPES.TABLE: {
                          return (
                            <TableContent
                              key={index + type}
                              title={title}
                              attributes={attributes}
                            />
                          );
                        }
                        case PRODUCT_ATTRIBUTE_TYPES.NESTED_TABLE: {
                          return (
                            <NestedTableContent
                              key={index + type}
                              title={title}
                              subtitle={subtitle}
                              attributes={attributes}
                            />
                          );
                        }
                        default: {
                          return null;
                        }
                      }
                    })
                  }

                  {/* Disclaimer / SKU */}
                  {
                    tabIndex === 0 && (
                      <>
                        <div className={styles.disclaimer}>{commercialDisclaimer}</div>
                        <div className={styles.skuWrapper}>
                          <ProductSku className={styles.sku} sku={sku} />
                        </div>
                      </>
                    )
                  }

                </div>
              </Tabs.Panel>
            );
          })
        }

        {/* Same Brand Panel */}
        {
          (withoutSameBrandTab || !brand)
            ? null
            : (
              <Tabs.Panel key="panel-brand" className={styles.tabPanel}>
                <div className={classnames(styles.tabContent, styles.sameBrandContent)}>
                  <SameBrandContent
                    product={product}
                    carouselBreakpoints={carouselBreakpoints}
                    onItemClick={onItemClick}
                  />
                </div>
              </Tabs.Panel>
            )
        }

      </Tabs.Container>
    </div>
  );
};

// Components

// --- Text Content

interface TextContentProps {
  attributes: IProductAttribute[]
}

const TextContent = (props: TextContentProps) => {
  const {
    attributes = []
  } = props;

  return (
    <>
      {
        attributes.map(({ name, value }, index) => (
          <div className={styles.section} key={index + name}>
            <h2 className={styles.sectionTitle}>{name}</h2>
            <div className={styles.sectionBody}>
              <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          </div>
        ))
      }
    </>
  );
};

// --- Table Content

interface TableContentProps {
  title?: string,
  attributes: IProductAttribute[]
}

const TableContent = (props: TableContentProps) => {
  const {
    title,
    attributes = []
  } = props;

  return (
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.sectionBody}>
        <div className={styles.tableWrapper}>
          <table className={styles.tableContent}>
            <tbody>
              {
                attributes.map(({ name, value }, index) => (
                  <tr key={index + name}>
                    <td>{name}</td>
                    <td>{value}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// --- Nested Table Content

interface NestedTableContentProps {
  title?: string,
  subtitle?: string,
  attributes: IProductAttribute[]
}

interface Row {
  name: string,
  value: string,
  isParent: boolean
}

const NestedTableContent = (props: NestedTableContentProps) => {
  const {
    title,
    subtitle,
    attributes = [],
  } = props;

  // Content

  const content = attributes.reduce((acc: Row[], attr) => {
    const {
      name,
      value,
      children = []
    } = attr || {};

    const newRows: Row[] = [{ name, value, isParent: true }];

    let result = [...acc];

    try {
      children.forEach(({
        name: childName,
        value: childValue
      }) => newRows.push({
        name: childName,
        value: childValue,
        isParent: false
      }));
    } finally {
      result = [...result, ...newRows];
    }

    return result;
  }, []);

  // Render

  return (
    <div className={styles.section}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.sectionSubtitle}>{subtitle}</div>
      <div className={styles.sectionBody}>
        <div className={styles.nestedTableWrapper}>
          <table className={styles.nestedTableContent}>
            <tbody>
              {
                content.map(({ name, value, isParent }, index) => (
                  <tr key={index + name} className={classnames({ [styles.isParent]: isParent })}>
                    <td>{name}</td>
                    <td>{value}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// --- Same Brand Content

interface SameBrandContentProps {
  product: IProduct,
  carouselBreakpoints: any, // TODO: update
  onItemClick: () => void
}

const SameBrandContent = (props: SameBrandContentProps) => {
  const {
    product,
    carouselBreakpoints,
    onItemClick = () => {},
  } = props;

  const {
    brand = '',
    brandFilterCode = ''
  } = product;

  // Hooks

  const { t } = useTranslation();

  const {
    isSuccess, isError, isLoading, data,
  } = useSameBrandProducts({
    brand,
    brandCode: brandFilterCode,
    page: 1,
    itemsPerPage: SAME_BRAND_PRODUCTS_LIMIT,
  });

  // Props

  const {
    analyticsListId,
    analyticsListName,
    total,
    items: products,
  } = data || {};

  const link = (!total || total <= SAME_BRAND_PRODUCTS_LIMIT)
    ? null
    : {
      url: ProductHelpers.getSameBrandLink(brand, brandFilterCode),
      title: t('PRODUCT.VIEW_MORE')
    };

  // Error

  if (isError) {
    return <Error />;
  }

  // Empty

  if (isSuccess && !products?.length) {
    return (
      <ImageMessage
        className={styles.emptyResults}
        image={IMAGES.PLACEHOLDERS.NO_PRODUCTS}
        imageProps={{
          width: 414,
          height: 242,
          alt: 'No same brand products'
        }}
        title={t('PRODUCT.SAME_BRAND_EMPTY_TITLE')}
        subtitle={t('PRODUCT.SAME_BRAND_EMPTY_SUBTITLE')}
      />
    );
  }

  // Render

  return (
    <ProductsCarousel
      className={styles.carouselContainer}
      headerClassName={styles.carouselHeader}
      //
      title={brand}
      link={link}
      //
      loading={isLoading}
      products={products}
      productListProps={{
        analyticsListId,
        analyticsListName
      }}
      //
      spaceBetween={14}
      breakpoints={carouselBreakpoints}
      //
      onItemClick={onItemClick}
    />
  );
};

// Constants

const SAME_BRAND_PRODUCTS_LIMIT = 11;

// Export

export default ProductTabs;
