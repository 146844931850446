import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './SocialMediaButton.module.scss';

const SOCIAL_BUTTON_VARIANTS = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  LINKEDIN: 'linkedin',
};

const SOCIAL_BUTTON_VARIANTS_MAP = {
  [SOCIAL_BUTTON_VARIANTS.FACEBOOK]: {
    href: 'https://facebook.com/FreshfulbyeMAG',
    label: 'facebook.com/FreshfulbyeMAG',
    icon: 'facebook'
  },
  [SOCIAL_BUTTON_VARIANTS.INSTAGRAM]: {
    href: 'https://instagram.com/freshfulbyemag',
    label: 'instagram.com/freshfulbyemag',
    icon: 'instagram'
  },
  [SOCIAL_BUTTON_VARIANTS.YOUTUBE]: {
    href: 'https://youtube.com/channel/UCq8EyuMil1BkSTw6Iyq4CkA',
    label: 'youtube.com/freshfulbyemag',
    icon: 'youtube'
  },
  [SOCIAL_BUTTON_VARIANTS.LINKEDIN]: {
    href: 'https://www.linkedin.com/company/freshfulbyemag/mycompany/',
    label: 'linkedin.com/freshfulbyemag',
    icon: 'linkedin',
  }
};

interface Props {
  variant?: typeof SOCIAL_BUTTON_VARIANTS[keyof typeof SOCIAL_BUTTON_VARIANTS],
  className?: string,
  iconClassName?: string,
  withoutLabel?: boolean
}

const SocialMediaButton = (props: Props) => {
  const {
    variant, className, iconClassName, withoutLabel
  } = props;

  if (!variant || !SOCIAL_BUTTON_VARIANTS_MAP[variant]) return null;

  const { icon, label, href } = SOCIAL_BUTTON_VARIANTS_MAP[variant];

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={classnames(styles.root, className)}>
      <Icon className={classnames(styles.icon, iconClassName, { [styles.withLabel]: !withoutLabel })} name={icon} />
      {!withoutLabel && <span>{label}</span>}
    </a>
  );
};

export default SocialMediaButton;

SocialMediaButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(SOCIAL_BUTTON_VARIANTS)).isRequired,
  className: PropTypes.string
};
