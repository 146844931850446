import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { arePlaceSearchParamsValid, isPlaceQueryValid } from 'helpers/PlacesHelpers';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getPlaceDetails, getPlaceSuggestions } from 'services/PlacesService';

import type {
  IPlaceDetails, IPlaceSuggestions, Nullable, PlaceDetailsQuery, ReactQueryOptions
} from 'types';

export const usePlaceSuggestions = (query: Nullable<string>, queryOptions: ReactQueryOptions<IPlaceSuggestions> = {}) => {
  return useQuery({
    queryKey: createQueryKey([QUERY_KEYS.PLACES_SUGGESTIONS, query]),
    queryFn: () => getPlaceSuggestions(query),
    enabled: isPlaceQueryValid(query),
    ...queryOptions
  });
};

export const usePlaceDetails = (
  params: Nullable<PlaceDetailsQuery>,
  queryOptions: ReactQueryOptions<IPlaceDetails> = {}
) => {
  const {
    id, street, number, city, province, lat, lng
  } = params || {};
  return useQuery({
    queryKey: createQueryKey([
      QUERY_KEYS.PLACE_DETAILS,
      id,
      street,
      number,
      city,
      province,
      lat,
      lng
    ]),
    queryFn: () => getPlaceDetails(params!),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    enabled: arePlaceSearchParamsValid(params),
    ...queryOptions
  });
};
