export function hexToRgb(hex: string) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1).match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

/* export function rgbToHex(r : string, g : string, b: string) {
  return `#${ [r, g, b].map((x) => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${ hex}` : hex;
  }).join('')}`;
} */

export function hexToRgbA(hex: string, opacity = 1) {
  if (!hex) return '';
  const rgb = hexToRgb(hex);
  return `rgba(${rgb.join(',')}, ${opacity})`;
}

export function shadeColor(color: string, amt: number) {
  if (!color) return null;

  color = color.replace(/^#/, '');

  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  let [r, g, b] = color.match(/.{2}/g);

  const darkenR = parseInt(r, 16) + amt;
  const darkenG = parseInt(g, 16) + amt;
  const darkenB = parseInt(b, 16) + amt;

  r = Math.max(Math.min(255, darkenR), 0).toString(16);
  g = Math.max(Math.min(255, darkenG), 0).toString(16);
  b = Math.max(Math.min(255, darkenB), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
}
