import { APP_ROUTES } from 'constants/routes';

import type {
  IBreadcrumb, ICategoryTrail, IListingBreadcrumb, IProductBreadcrumb
} from 'types';

// Types

interface Options {
  shouldIncludeHome?: boolean,
  omitLast?: boolean
}

export function getBreadcrumbsFromTrail(trail: ICategoryTrail[] | IProductBreadcrumb[] | IListingBreadcrumb[], options?: Options): IBreadcrumb[] {
  const { shouldIncludeHome = true, omitLast = false } = options || {};
  const items: IBreadcrumb[] = [];
  let path = APP_ROUTES.CATEGORIES;

  if (shouldIncludeHome) {
    items.push({
      href: APP_ROUTES.HOME,
      name: 'NAVIGATION.HOME',
      translate: true
    });
  }

  trail?.forEach((item) => {
    const { slug, name = '' } = item;
    path += `/${slug}`;
    items.push({
      href: path,
      name
    });
  });

  if (omitLast) {
    items.pop();
  }

  return items;
}
