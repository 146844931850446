/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from 'components/ui';

import styles from './OrderInfoBlock.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const ICON_SIZES = {
  [SIZES.SMALL]: 18,
  [SIZES.MEDIUM]: 24
};

const ICON_POSITION = {
  START: 'start',
  END: 'end'
};

const CHILDREN_POSITION = {
  INSIDE: 'inside',
  OUTSIDE: 'outside'
};

const OrderInfoBlock = (props) => {
  const {
    className, wrapperClassName, innerClassName, contentClassName,
    iconClassName, labelClassName, valueClassName, subValueClassName,
    bgColor, titleColor,
    size = SIZES.MEDIUM,
    iconSize = SIZES.MEDIUM,
    icon,
    iconPosition = ICON_POSITION.START,
    withBorder,
    withPadding,
    label, value, subValue,
    style = {},
    childrenPosition = CHILDREN_POSITION.INSIDE,
    children
  } = props;

  const { t } = useTranslation();

  const classes = classnames(
    styles.root,
    { [styles.withBorder]: withBorder || bgColor },
    { [styles.withPadding]: withPadding || withBorder || bgColor },
    { [styles.withIcon]: icon },
    { [styles[size]]: size },
    { [styles[`icon-${iconSize}`]]: iconSize },
    className,
  );

  return (
    <div
      className={classes}
      style={{
        backgroundColor: bgColor,
        borderColor: bgColor,
        ...style
      }}
    >

      <div className={classnames(styles.wrapper, wrapperClassName)}>

        {/* Icon - Start */}
        {
          icon && (iconPosition === ICON_POSITION.START) && (
            <div
              className={classnames(styles.icon, styles.start, iconClassName)}
              style={{
                backgroundColor: bgColor ? styles.white : null,
                color: titleColor || null
              }}
            >
              <Icon name={icon} size={ICON_SIZES[iconSize]} />
            </div>
          )
        }

        {/* Inner */}
        <div className={classnames(styles.inner, innerClassName)}>

          {/* Content */}
          <div className={classnames(styles.content, contentClassName)}>

            {/* Label */}
            <div
              className={classnames(styles.label, labelClassName)}
              style={{
                color: titleColor
              }}
            >
              {t(label)}
            </div>

            {/* Value */}
            {
              typeof value === 'string'
                ? <div className={classnames(styles.value, valueClassName)} dangerouslySetInnerHTML={{ __html: value }} />
                : <div className={classnames(styles.value, valueClassName)}>{value}</div>
            }

            {/* Sub Value */}
            {
              subValue && (
                typeof subValue === 'string'
                  ? <div className={classnames(styles.subValue, subValueClassName)} dangerouslySetInnerHTML={{ __html: subValue }} />
                  : <div className={classnames(styles.subValue, subValueClassName)}>{subValue}</div>
              )
            }
          </div>

          {/* Children */}
          {
            (children && childrenPosition === CHILDREN_POSITION.INSIDE) && (
              <div className={classnames(styles.children, styles.inside)}>
                {children}
              </div>
            )
          }
        </div>

        {/* Icon - End */}
        {
          icon && (iconPosition === ICON_POSITION.END) && (
            <div
              className={classnames(styles.icon, styles.end, iconClassName)}
              style={{
                backgroundColor: bgColor ? '#ffffff' : null,
                color: titleColor || null
              }}
            >
              <Icon name={icon} size={ICON_SIZES[iconSize]} />
            </div>
          )
        }
      </div>

      {/* Children */}
      {
        (children && childrenPosition === CHILDREN_POSITION.OUTSIDE) && (
          <div className={classnames(styles.children, styles.outside)}>
            {children}
          </div>
        )
      }

    </div>
  );
};

OrderInfoBlock.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subValueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  icon: PropTypes.string,
  withBorder: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default OrderInfoBlock;
