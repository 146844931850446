import React from 'react';

import { BlogTags } from 'components/blog';
import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useAnalytics } from 'hooks/useAnalytics';

import type { IRecipeTag, IRecipeTags } from 'types';

interface Props {
  className?: string,
  tagsClassName?: string,
  tagWrapperClassName?: string,
  tagClassName?: string,
  tags: IRecipeTags,
  onTagClick?: (tag: IRecipeTag) => void
}

const RecipeTags = (props: Props) => {

  const {
    className,
    tagsClassName,
    tagWrapperClassName,
    tagClassName,
    tags,
    onTagClick
  } = props;

  // Hooks

  const analytics = useAnalytics();

  // Empty

  if (isArrayEmpty(tags)) return null;

  // Handlers

  const handleTagClick = (tag: IRecipeTag) => {
    const { id } = tag;

    analytics.selectRecipeTag(id);

    if (onTagClick) {
      onTagClick(tag);
    }
  };
  // Props

  const baseHref = APP_ROUTES.RECIPES_BY_TAG;

  const recipeTags = tags?.map(({ slug, label }) => ({
    slug,
    name: label,
    href: `${baseHref}/${slug}`,
    selected: false
  }));

  // Render

  return (
    <BlogTags
      className={className}
      tagsClassName={tagsClassName}
      tagWrapperClassName={tagWrapperClassName}
      tagClassName={tagClassName}
      tags={recipeTags}
      onTagClick={handleTagClick}
    />
  );
};

export default RecipeTags;
