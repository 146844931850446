/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classnames from 'classnames';

import { Button, Image, Link } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { useInView } from 'hooks/common/useInView';
import { useTranslation } from 'hooks/common/useTranslation';

import RecipeBanner from '../RecipeBanner/RecipeBanner';
import RecipeLabel from '../RecipeLabel/RecipeLabel';
import RecipePrice from '../RecipePrice/RecipePrice';
import RecipeStats from '../RecipeStats/RecipeStats';

import type { IRecipeCard } from 'types';

import styles from './RecipeCard.module.scss';

interface Props {
  className?: string,
  recipe: IRecipeCard,
  isQuickView?: boolean,
  //
  onEnter?: (recipeCard: IRecipeCard) => void,
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
  onViewIngredientsClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  //
  dataTestId?: string
}

const RecipeCard = (props: Props) => {

  const {
    className,
    recipe,
    isQuickView,
    onClick,
    onViewIngredientsClick,
    onEnter,
    dataTestId
  } = props;

  const {
    slug,
    title,
    image,
    label,
    stats,
    banner
  } = recipe || {};

  // Hooks

  const { t } = useTranslation();

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter(recipe);
      }
      unobserve();
    }
  });

  // Props

  const {
    width = 360,
    height = 180,
    src
  } = image || {};

  const {
    pricePerServing,
    originalPricePerServing,
    totalTime,
    servings
  } = stats || {};

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onViewIngredientsClick) {
      onViewIngredientsClick(e);
    }
  };

  // Render

  return (

    <Link
      passHref
      href={`${APP_ROUTES.RECIPES_DETAILS}/${slug}`}
    >
      <a
        ref={onEnter ? observe : null}
        className={classnames(styles.root, className)}
        onClick={handleClick}
        data-testid={dataTestId}
      >

        {/* Thumbnail */}
        <div className={styles.thumbnail}>

          {/* Image */}
          <div className={styles.image}>
            <Image
              width={width}
              height={height}
              src={src}
              alt={title}
              style={{
                objectFit: 'cover'
              }}
            />
          </div>

          {/* Label */}
          <RecipeLabel
            className={styles.label}
            label={label}
          />

          {/* Banner */}
          {
            !!banner && (
              <div className={styles.banner}>
                <RecipeBanner
                  banner={banner}
                />
              </div>
            )
          }
        </div>

        {/* Content */}
        <div className={styles.content}>

          {/* Stats */}
          <RecipeStats
            className={styles.stats}
            statClassName={styles.stat}
            statValueClassName={styles.statValue}
            variant="minimal"
            stats={[
              servings,
              totalTime,
            ]}
          />

          {/* Name */}
          <div className={styles.name}>{title}</div>

          {/* Pricing */}
          <RecipePrice
            className={styles.pricing}
            priceClassName={styles.price}
            originalPriceClassName={styles.originalPrice}
            perServingLabelClassName={styles.perServingLabel}
            originalPricePerServing={originalPricePerServing?.value}
            pricePerServing={pricePerServing?.value}
            isQuickView={isQuickView}
          />

          {/* Actions */}
          <div className={styles.actions}>
            <Button
              className={styles.viewIngredientsButton}
              variant="subtle"
              onClick={handleAdd}
            >
              {t('recipes:RECIPE_CARD.ACTIONS.ADD_INGREDIENTS')}
            </Button>

          </div>

        </div>

      </a>
    </Link>
  );
};

export default RecipeCard;
