export function formatPrice(value?: number | string) {
  try {
    if (typeof value === 'number') return value.toFixed(2).replace('.', ',');

    if (!value) return '-';
    const converted = parseInt(value, 10);

    if (Number.isNaN(converted)) return '-';

    return converted.toString().replace('.', ',');
  } catch (err) {
    console.error('Error while formatting price', err);
    return '-';
  }
}
