import * as ErrorHandler from 'common/ErrorHandler';
import { Api, SecureApi } from 'common/http';
import { UrlParams } from 'common/url-params';
import * as AddressHelpers from 'helpers/AddressHelpers';

import { toAccountBenefits } from './AccountServiceAdapter';

import type {
  IAccountNewFeatureNotificationsResponse,
  IAccountPromotionsResponse,
  IAddress,
  IBillingCompany,
  ICreateAddressPayload,
  ICreateBillingPayload,
  IListingActiveFilters,
  IOrderFeedbackReminder,
  IUser,
  IVoucherDetailsResponse,
  IVouchersResponse,
} from 'types';

// --- User

export const getUser = async (isOptional = false) => {
  const { data } = await SecureApi.get<IUser | null>('/customers/me', { isOptional });
  return data;
};

// --- Addresses

export const getAddresses = async () => {
  const { data } = await SecureApi.get<IAddress[]>('/account/addresses');
  return data;
};

export const saveAddress = async (address: ICreateAddressPayload) => {
  AddressHelpers.deletePartialAddress();
  return SecureApi.post('/account/addresses', address);
};

export const editAddress = async (payload: Partial<ICreateAddressPayload>, addressToken: string) => {
  return SecureApi.put(`/account/addresses/${addressToken}`, payload);
};

export const deleteAddress = async (addressId: string) => {
  const { data } = await SecureApi.delete(`/account/addresses/${addressId}`);
  return data;
};

// --- Billing

export const getBilling = async () => {
  const { data } = await SecureApi.get<IBillingCompany[]>('/account/billings');
  return data;
};

export const getBillingFormOptions = async () => {
  const { data: registrationData } = await SecureApi.get('/registration-number/RO_ro');
  const { data: provincesData } = await SecureApi.get('/provinces');
  return { registrationData, provincesData };
};

export const getCitiesByProvince = async (provinceId: string) => {
  const { data: { cities } } = await SecureApi.get(`/provinces/${provinceId}`);
  return cities;
};

export const deleteBilling = async (billingId: string) => {
  const { data } = await SecureApi.delete(`/account/billings/${billingId}`);
  return data;
};

export const createBilling = async (data: ICreateBillingPayload) => {
  return SecureApi.post('/account/billings', data);
};

// --- Newsletter

export const subscribeToNewsletter = async () => {
  const { data } = await SecureApi.put('/customers/me', { subscribedToNewsletter: true });
  return data;
};

export const unsubscribeFromNewsletter = async () => {
  const { data } = await SecureApi.put('/customers/me', { subscribedToNewsletter: false });
  return data;
};

export const unsubscribeFromNewsletterWithHash = async (params: {cid: string, hash: string}) => {
  const { cid, hash } = params;
  const { data } = await Api.put(`/customers/${cid}/newsletter/unsubscribe/${hash}`);
  return data;
};

// --- Abandoned Cart

export const subscribeToAbandonedCart = async () => {
  try {
    const { data } = await SecureApi.put('/customers/me', { subscribedToAbandonedCart: true });
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while subscribing to Abandoned Cart');
  }
};

export const unsubscribeFromAbandonedCart = async () => {
  try {
    const { data } = await SecureApi.put('/customers/me', { subscribedToAbandonedCart: false });
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while unsubscribing from Abandoned Cart');
  }
};
export const unsubscribeFromAbandonedCartWithHash = async (params: {cid: string, hash: string}) => {
  const { cid, hash } = params;
  try {
    const { data } = await Api.patch(`/customers/${cid}/abandoned-cart/unsubscribe/${hash}`);
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while unsubscribing from abandoned cart with hash');
  }
};

// --- Order Feedback Reminder

export const getOrderFeedbackReminders = async () => {
  const { data } = await SecureApi.get<IOrderFeedbackReminder>('/order-feedback/remind');
  return data;
};

export const dismissOrderFeedbackReminder = async (timestamp: string) => {
  const { data } = await SecureApi.put('/customers/me', { feedbackReminderClosedAt: timestamp });
  return data;
};

// --- Meal Vouchers

export const getMealVouchers = async () => {
  const { data } = await SecureApi.get('/meal-vouchers');
  return data;
};

export const deleteMealVoucher = async (code: string) => {
  const { data } = await SecureApi.delete(`/meal-vouchers/${code}`);
  return data;
};

// --- Genius Trial

export const activateGeniusTrial = async () => {
  const { data } = await SecureApi.mergePatch('/customers/activate-genius-trial', {});
  return data;
};

// --- Genius fast checkout

export const getGeniusSubscriptionSSEConnectionInfo = async (eosToken: string, eosId: string) => {
  const body = { channel_name: `private:subscription:activated:${eosId}` };
  const { data } = await Api.post('https://genius.emag.ro/broadcasting/auth', body, { headers: { Authorization: `Bearer ${eosToken}` } });
  return data;
};

// --- Phone verifications

export const sendPhoneVerificationCode = async (phoneNumber: string) => {
  const { data } = await SecureApi.mergePatch('/customers/me/verify-phone-number/send', { phoneNumber });
  return data;
};

export const validatePhoneVerificationCode = async (payload: {phoneNumber: string, code: string}) => {
  const { phoneNumber, code } = payload || {};
  const { data } = await SecureApi.post('/customers/me/verify-phone-number/validate', { code: parseInt(code, 10), phoneNumber });
  return data;
};

// --- Benefits

export const getBenefits = async (filters?: IListingActiveFilters, shouldRequestModalContent?: boolean) => {
  const filtersQs = UrlParams.getFiltersQueryString(filters, '?');
  const endpoint = shouldRequestModalContent
    ? `/customers/me/benefits/modal${filtersQs}`
    : `/customers/me/benefits${filtersQs}`;
  const { data } = await SecureApi.get(endpoint);
  return toAccountBenefits(data);
};

// --- Segments

export const dismissSegmentAnnouncement = async (segmentId: number) => {
  const { data } = await SecureApi.post('/customers/me/dismiss/segment/announcement', { segment_id: segmentId });
  return data;
};

// --- Vouchers

export const getVouchers = async () => {
  const { data } = await SecureApi.get<IVouchersResponse>('/customers/me/coupons');
  return data;
};

export const getVoucher = async (code: string) => {
  const { data } = await SecureApi.get<IVoucherDetailsResponse>(`/customers/me/coupons/${code}`);
  return data;
};

export const addVoucher = async (code: string) => {
  const { data } = await SecureApi.post('/customers/me/coupons', { couponCode: code });
  return data;
};

export const getAccountPromotions = async () => {
  const { data } = await SecureApi.get<IAccountPromotionsResponse>('/customers/me/promotions');
  return data;
};

// --- Loyalty

export const updateLoyaltyOptInStatus = async (optIn: boolean) => {
  const { data } = await SecureApi.mergePatch('/customers/me/loyalty', { optIn });
  return data;
};

// --- New Features

export const getNewFeatureNotifications = async () => {
  const { data } = await SecureApi.get<IAccountNewFeatureNotificationsResponse>('/customers/me/intro');
  return data;
};
