import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getConfigFromWebApi } from 'services/ConfigService';

import type { IConfig, ReactQueryOptions } from 'types';

export const useConfig = (options: ReactQueryOptions<IConfig> = {}) => {
  return useQuery({
    queryKey: getKey(),
    queryFn: () => getConfigFromWebApi(),
    staleTime: 5 * 60 * 1000,
    ...options
  });
};

export function getKey() {
  return createQueryKey([QUERY_KEYS.CONFIG]);
}
