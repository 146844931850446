import type { CSSProperties } from 'react';
import React from 'react';
import {
  ArrowContainer, Popover as RTinyPopover
} from 'react-tiny-popover';
import { Transition } from 'react-transition-group';
import { debounce } from 'throttle-debounce';
import classnames from 'classnames';

import { objectClean } from 'helpers/ObjectHelpers';
import { useIsOpen } from 'hooks/useIsOpen';

import type { PopoverProps, PopoverState } from 'react-tiny-popover';
import { DirectionalPositions } from 'types';

import styles from './Popover.module.scss';

export interface Props extends Omit<PopoverProps, 'isOpen' | 'parentElement' | 'children' | 'content'> {
  //
  containerClassName?: string,
  className?: string,
  contentWrapperClassName?: string,
  contentClassName?: string,
  //
  isOpen?: boolean,
  parentElement?: React.RefObject<HTMLElement>,
  boundaryElement?: HTMLElement,
  content: JSX.Element,
  //
  padding?: number,
  persist?: boolean,
  //
  backgroundColor?: string,
  textColor?: string,
  //
  withHover?: boolean,
  withArrow?: boolean,
  withFastAnimation?: boolean,
  //
  children: React.ReactNode
  //
}

const Popover = (props: Props) => {
  const {
    //
    containerClassName,
    className,
    contentWrapperClassName,
    contentClassName,
    //
    isOpen: opened,
    parentElement,
    boundaryElement,
    content,
    //
    padding = 8,
    persist,
    //
    backgroundColor,
    textColor,
    //
    withArrow,
    withHover,
    withFastAnimation,
    //
    children,
    //
    ...rest
  } = props;

  // Refs

  const ref = React.useRef<HTMLDivElement>(null);
  const isMouseOverRef = React.useRef<boolean>();

  // Hooks

  const {
    isOpen, open, close, set
  } = useIsOpen();

  // Effects

  React.useEffect(() => {
    set(!!opened);
  }, [opened]);

  // Helpers

  const handleClose = () => {
    if (!isMouseOverRef.current) {
      close();
    }
  };

  const debouncedClose = React.useCallback(debounce(10, handleClose), []);

  // Handlers

  const onMouseEnter = () => {
    isMouseOverRef.current = true;
    if (!isOpen) {
      open();
    }
  };

  const onMouseLeave = () => {
    isMouseOverRef.current = false;
    debouncedClose();
  };

  // Render

  return (
    <RTinyPopover
      isOpen
      parentElement={parentElement?.current || ref?.current || undefined}
      boundaryElement={boundaryElement || undefined}
      containerClassName={classnames(
        styles.container,
        containerClassName,
      )}
      content={(popoverState) => (
        <Content
          isVisible={isOpen}
          //
          wrapperClassName={contentWrapperClassName}
          className={contentClassName}
          //
          persist={persist}
          content={content}
          bound={!!boundaryElement}
          //
          backgroundColor={backgroundColor}
          textColor={textColor}
          //
          withArrow={withArrow}
          withFastAnimation={withFastAnimation}
          //
          onMouseEnter={withHover ? onMouseEnter : () => {}}
          onMouseLeave={withHover ? onMouseLeave : () => {}}
          //
          popoverState={popoverState}
          //
          padding={padding}
        />
      )}
      {...rest}
    >
      <div
        ref={ref}
        className={classnames(styles.root, className)}
        onMouseEnter={withHover ? onMouseEnter : () => {}}
        onMouseLeave={withHover ? onMouseLeave : () => {}}
      >
        {children}
      </div>
    </RTinyPopover>
  );
};

// Components

interface ContentProps {
  isVisible: boolean,
  //
  wrapperClassName?: string,
  className?: string,
  //
  content: JSX.Element,
  //
  persist?: boolean,
  padding?: number,
  bound?: boolean,
  //
  backgroundColor?: string,
  textColor?: string,
  //
  withArrow?: boolean,
  withFastAnimation?: boolean,
  //
  onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => void,
  onMouseLeave: (e: React.MouseEvent<HTMLDivElement>) => void,
  //
  popoverState: PopoverState
}

const Content = (props: ContentProps) => {

  const {
    isVisible,
    //
    wrapperClassName,
    className,
    //
    content,
    //
    persist,
    padding = 8,
    bound,
    //
    backgroundColor,
    textColor,
    //
    withArrow,
    withFastAnimation,
    //
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    //
    popoverState,
  } = props;

  // Refs

  const transitionRef = React.useRef(null);

  // Props

  const {
    //
    position,
    //
    parentRect,
    popoverRect,
    childRect,
    //
    nudgedLeft,
    // nudgedTop,
    //
  } = popoverState || {};

  const {
    width: parentWidth,
    // height: parentHeight,
  } = parentRect || {};

  const isPositionedVertically = position === DirectionalPositions.TOP || position === DirectionalPositions.BOTTOM;
  // const isPositionedHorizontally = position === DirectionalPositions.LEFT || position === DirectionalPositions.RIGHT;

  // Render

  return (
    <Transition
      nodeRef={transitionRef}
      in={isVisible}
      timeout={500}
      mountOnEnter={!persist}
      unmountOnExit={!persist}
    >
      {(state) => {
        return (
          <div
            ref={transitionRef}
            //
            className={classnames(
              styles.contentWrapper,
              { [styles.visible]: isVisible },
              wrapperClassName,
            )}
            style={objectClean({
              paddingTop: position === DirectionalPositions.BOTTOM ? `${padding}px` : null,
              paddingBottom: position === DirectionalPositions.TOP ? `${padding}px` : null,
              paddingLeft: position === DirectionalPositions.RIGHT ? `${padding}px` : null,
              paddingRight: position === DirectionalPositions.LEFT ? `${padding}px` : null,
              //
              maxWidth: (bound && parentWidth && isPositionedVertically) ? `${parentWidth}px` : 'auto',
              left: (bound && isPositionedVertically && nudgedLeft) ? `${nudgedLeft}px` : null,
              //
              // maxHeight: (bound && parentHeight) ? `${parentHeight}px` : 'auto',
              // top: (bound && isPositionedHorizontally && nudgedTop) ? `${nudgedTop}px` : null,
            })}
            //
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div
              className={classnames(
                styles.content,
                {
                  ...(position ? { [styles[position]]: !!position } : {}),
                  [styles[state]]: state,
                  [styles.fast]: withFastAnimation,
                },
                className,
              )}
              style={{
                backgroundColor,
                color: textColor,
                '--backgroundColor': backgroundColor,
              } as CSSProperties}
            >
              {
                withArrow
                  ? (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      className={styles.arrowContainer}
                      arrowClassName={classnames(
                        styles.arrow,
                        {
                          ...(position ? { [styles[position]]: !!position } : {})
                        }
                      )}
                      arrowStyle={{
                        marginLeft: (bound && isPositionedVertically && nudgedLeft) ? `${-nudgedLeft}px` : undefined,
                        // marginTop: (bound && isPositionedVertically && nudgedLeft) ? `${-nudgedTop}px` : null,
                      }}
                      arrowColor="white"
                      arrowSize={12}
                    >
                      {content}
                    </ArrowContainer>
                  ) : (
                    content
                  )
              }
            </div>
          </div>
        );
      }}
    </Transition>
  );
};

// Export

export default Popover;
