import React from 'react';
import classnames from 'classnames';

import { isMobile } from 'helpers/BrowserHelpers';
import { useIsOpen } from 'hooks/useIsOpen';

import Icon from '../Icon/Icon';
import Popover from '../Popover/Popover';

import type { PopoverProps } from 'react-tiny-popover';
import { type DirectionalPosition, DirectionalPositions } from 'types';

import styles from './Tooltip.module.scss';

interface Props {
  containerClassName?: string,
  className?: string,
  popoverClassName?: string,
  innerClassName?: string,
  contentWrapperClassName?: string,
  contentClassName?: string,
  titleClassName?: string,
  textClassName?: string,
  //
  scrollContainer?: HTMLElement,
  withHover?: boolean,
  //
  parentElement?: React.MutableRefObject<HTMLElement>,
  boundaryElement?: HTMLElement,
  positions?: DirectionalPosition [],
  padding?: number,
  //
  align?: PopoverProps['align'],
  //
  title?: React.ReactNode,
  text?: React.ReactNode,
  backgroundColor?: string,
  textColor?: string,
  //
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void,
  //
  children: React.ReactNode
}

const Tooltip = (props: Props) => {

  const {
    containerClassName,
    className,
    popoverClassName,
    innerClassName,
    contentWrapperClassName,
    contentClassName,
    titleClassName,
    textClassName,
    //
    scrollContainer,
    withHover = false,
    //
    parentElement,
    boundaryElement,
    positions = [DirectionalPositions.TOP],
    padding = 16,
    //
    align = 'center',
    //
    title,
    text,
    backgroundColor,
    textColor,
    //
    onClick,
    //
    children,
    ...rest
  } = props;

  // Refs

  const ref = React.useRef<HTMLDivElement>(null);

  // Hooks

  const { isOpen, toggle, close } = useIsOpen();

  // Handlers

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (isMobile) {
      toggle();
    }
  }, [toggle, onClick]);

  const handleClose = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    close();
  }, [close]);

  const handleWindowScroll = React.useCallback(() => {
    if (isMobile) {
      close();
    }
  }, [close]);

  // Effects

  React.useEffect(() => {
    if (isOpen) {
      (scrollContainer || window).addEventListener('scroll', handleWindowScroll);
    } else {
      (scrollContainer || window).removeEventListener('scroll', handleWindowScroll);
    }
    return () => {
      (scrollContainer || window).removeEventListener('scroll', handleWindowScroll);
    };
  }, [isOpen, handleWindowScroll, scrollContainer]);

  // No title and text

  if (!title && !text) {
    return (
      <div
        className={classnames(styles.root, className)}
        onClick={handleClick}
      >
        <div className={classnames(styles.inner, innerClassName)}>
          {children}
        </div>
      </div>
    );
  }

  // Content

  const Content = (
    <div
      className={classnames(
        styles.popoverContent,
        contentClassName
      )}
    >

      {/* Title */}
      {title && (
        <div
          className={classnames(
            styles.popoverTitle,
            titleClassName
          )}
        >
          {title}
        </div>
      )}

      {/* Text */}
      {text && (
        <div
          className={classnames(
            styles.popoverText,
            textClassName
          )}
        >
          {text}
        </div>
      )}

      {/* Icon */}
      {!withHover
        && isMobile && (
        <div
          className={styles.popoverClose}
          onClick={handleClose}
        >
          <Icon
            name="x"
            size={16}
            strokeWidth={2}
          />
        </div>
      )}
    </div>
  );

  // Render

  // TODO refactor to reduce HTML tree depth and improve auto positioning options

  return (
    <div
      ref={ref}
      className={classnames(styles.root, className)}
    >
      <Popover
        parentElement={parentElement as any || ref || undefined}
        boundaryElement={boundaryElement || parentElement?.current || undefined}
        isOpen={isMobile ? isOpen : false}
        //
        containerClassName={classnames(styles.container, containerClassName)}
        contentClassName={styles.popover}
        contentWrapperClassName={contentWrapperClassName}
        className={popoverClassName}
        //
        align={align}
        positions={positions}
        persist
        reposition
        padding={padding}
        //
        backgroundColor={backgroundColor}
        textColor={textColor}
        //
        withHover={!isMobile || withHover}
        withArrow
        withFastAnimation
        //
        content={Content}
        //
        onClickOutside={() => {
          if (isMobile) {
            close();
          }
        }}
        //
        {...rest}
      >
        <div
          className={classnames(styles.inner, innerClassName)}
          onClick={handleClick}
        >
          {children}
        </div>
      </Popover>
    </div>
  );
};

export default Tooltip;
