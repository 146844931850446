import type React from 'react';

import _useDeepCompareEffect, { useDeepCompareEffectNoCheck as _useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

type UseEffectParams = Parameters<typeof React.useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];
type UseEffectReturn = ReturnType<typeof React.useEffect>;

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList
): UseEffectReturn {
  return _useDeepCompareEffect(callback, dependencies);
}

export function useDeepCompareEffectNoCheck(
  callback: EffectCallback,
  dependencies: DependencyList
): UseEffectReturn {
  return _useDeepCompareEffectNoCheck(callback, dependencies);
}
