import Cookies from 'js-cookie';

import { COOKIE_KEYS } from 'constants/storage';

import type { CookieAttributes } from 'js-cookie';

// Set
export const set = (key: string, value: string, options?: CookieAttributes) => Cookies.set(key, value, options);

// Get
export const get = (key: string) => Cookies.get(key);

// Small hack to clear the old cookie value.
// Without this, the new cookie value would not be available without a page reload
export const remove = (key: string) => Cookies.set(key, '', { expires: new Date() });

export const clear = () => {
  remove(COOKIE_KEYS.CONSENT);
};
