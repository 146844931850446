import React from 'react';

import * as Notifications from 'common/Notification';
import { CONFIG } from 'constants/config';

import type { IAnalyticsListProps, IOrder, IOrderProduct } from 'types';

// --- Product delete notification

export interface IDeleteNotificationPayload {
  component: (props: React.ComponentProps<any>) => React.JSX.Element,
  order: IOrder,
  product: IOrderProduct,
  listProps: IAnalyticsListProps,
  onSuccess: () => void
}

const REMOVE_PRODUCT_SUCCESS_TOAST_ID = 'productRemoveSuccess';

export function showDeleteNotification(payload: IDeleteNotificationPayload) {
  const {
    order, product, listProps, onSuccess,
    component: Component // workaround to avoid dependency cycle
  } = payload;

  const toastBody = (
    <Component
      order={order}
      orderItem={product}
      listProps={listProps}
      onSuccess={onSuccess}
    />
  );

  if (Notifications.isActive(REMOVE_PRODUCT_SUCCESS_TOAST_ID)) {
    Notifications.update(REMOVE_PRODUCT_SUCCESS_TOAST_ID, {
      autoClose: CONFIG.DURATIONS.PRODUCT_DELETE_TOAST_AUTOCLOSE,
      toastId: REMOVE_PRODUCT_SUCCESS_TOAST_ID,
      render: toastBody,
    });
  } else {
    Notifications.showProgress(
      toastBody,
      {
        autoClose: CONFIG.DURATIONS.PRODUCT_DELETE_TOAST_AUTOCLOSE,
        toastId: REMOVE_PRODUCT_SUCCESS_TOAST_ID,
      }
    );
  }
}

export function dismissDeleteNotification() {
  Notifications.dismiss(REMOVE_PRODUCT_SUCCESS_TOAST_ID);
}
