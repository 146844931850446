import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'banner_events';

enum Actions {
  SELECT_BANNER = 'select_banner',
  VIEW_BANNER = 'view_banner',
}

enum Labels {
  CAROUSEL_BANNER = 'carousel_banner',
  HALF_BANNER = 'half_banner',
  LISTING_BANNER = 'listing_banner',
  PROMOTION_BANNER = 'promotion_banner',
  GENIUS_UPSELL_BANNER = 'Select banner',
  MEGA_MENU_BANNER = 'mega_menu_banner'
}

const push = getPushCategoryEventHandler<Actions, Labels>(CATEGORY);

// Events

// ------ View Banner

function viewBanner(label: Labels, bannerIndex: number, bannerName: string, slotIndex?: number) {
  return push(Actions.VIEW_BANNER, label, {
    parent_index: slotIndex,
    banner_index: bannerIndex,
    banner_name: bannerName,
    index: bannerIndex,
  });
}

export function viewCarouselBanner(index: number, name: string, slotIndex?: number) {
  return viewBanner(Labels.CAROUSEL_BANNER, index, name, slotIndex);
}

export function viewHalfBanner(index: number, name: string, slotIndex?: number) {
  return viewBanner(Labels.HALF_BANNER, index, name, slotIndex);
}

export function viewListingBanner(index: number, name: string) {
  return viewBanner(Labels.LISTING_BANNER, index, name);
}

export function viewPromotionBanner(index: number, name: string) {
  return viewBanner(Labels.PROMOTION_BANNER, index, name);
}

export function viewMegaMenuBanner(index: number, name: string) {
  return viewBanner(Labels.MEGA_MENU_BANNER, index, name);
}

// ------ Select Banner

function selectBanner(label: Labels, bannerIndex: number, bannerName: string, slotIndex?: number) {
  return push(Actions.SELECT_BANNER, label, {
    parent_index: slotIndex,
    banner_index: bannerIndex,
    banner_name: bannerName,
    index: bannerIndex,
  });
}

export function selectCarouselBanner(index: number, name: string, slotIndex?: number) {
  return selectBanner(Labels.CAROUSEL_BANNER, index, name, slotIndex);
}

export function selectHalfBanner(index: number, name: string, slotIndex?: number) {
  return selectBanner(Labels.HALF_BANNER, index, name, slotIndex);
}

export function selectListingBanner(index: number, name: string) {
  return selectBanner(Labels.LISTING_BANNER, index, name);
}

export function selectPromotionBanner(index: number, name: string) {
  return selectBanner(Labels.PROMOTION_BANNER, index, name);
}

export function selectMegaMenuBanner(index: number, name: string) {
  return selectBanner(Labels.MEGA_MENU_BANNER, index, name);
}
