import React from 'react';
import PropTypes from 'prop-types';

import { Notice } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderDeliveryNotice from '../OrderDeliveryNotice/OrderDeliveryNotice';
import OrderRetryPaymentAlert from '../OrderRetryPaymentAlert/OrderRetryPaymentAlert';

import styles from './OrderDetailsNotices.module.scss';

const OrderDetailsNotices = (props) => {

  const { order } = props;

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const {
    orderNotice: notices = [],
    deliveryNotice
  } = order || {};

  return (
    <div className={styles.root}>

      {/* Retry Payment Notice */}
      <OrderRetryPaymentAlert
        className={styles.notice}
        order={order}
      >

        {/* Standard Notices */}
        {
          notices.map((notice, index) => {
            return (
              <Notice
                key={`notice-${index}`}
                className={styles.notice}
                notice={notice}
                size={isBreakpoint ? 'medium' : 'large'}
              />
            );
          })
        }

      </OrderRetryPaymentAlert>

      {/* Delivery Notice */}
      {
        deliveryNotice && (
          <OrderDeliveryNotice
            className={styles.notice}
            order={order}
            size={isBreakpoint ? 'medium' : 'large'}
          />
        )
      }
    </div>
  );
};

OrderDetailsNotices.propTypes = {
  order: PropTypes.shape({})
};

export default OrderDetailsNotices;
