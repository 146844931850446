import { ORDER_SUMMARY_PRODUCT_STATES } from 'types';
import type {
  IGiftProduct,
  IOrderGiftProduct, IOrderProduct, IProductImage
} from 'types';

export function isOrderDetailsProductUnavailable(product: IOrderProduct): boolean {
  const { state } = product || {};
  if (state === undefined) return false;

  return [
    ORDER_SUMMARY_PRODUCT_STATES.DELETED,
    ORDER_SUMMARY_PRODUCT_STATES.REPLACED,
    ORDER_SUMMARY_PRODUCT_STATES.PARTIALLY_REPLACED,
    ORDER_SUMMARY_PRODUCT_STATES.DECREASED_QUANTITY,
    ORDER_SUMMARY_PRODUCT_STATES.QUANTITY_CHANGED
  ].includes(state);
}

export function getOrderGiftProducts(gifts: IGiftProduct[], imageKey: keyof IProductImage = 'thumbnail'): IOrderGiftProduct[] {
  return gifts?.map((gift) => {
    const { product } = gift;
    const { name, slug, image } = product || {};

    return {
      ...product,
      productName: name,
      productSlug: slug,
      image: product?.image ? image[imageKey] : null
    };
  });
}
