import { UrlParams, type UrlSegments } from './UrlParams';
import { hasSegments } from './UrlParamsHelpers';

import type { PageType } from 'types';

const PAGINATION_REGEX = /^p\d+$/;

/* Pagination */
export function getPaginationParams(segments: UrlSegments) {
  if (!hasSegments(segments)) return { page: 1, isPaginated: false };

  const lastSlug = segments[segments.length - 1] || '';
  const isPaginated = !!lastSlug.match(PAGINATION_REGEX);
  const page = isPaginated ? parseInt(lastSlug.substring(1, lastSlug.length), 10) : 1;
  return { page, isPaginated };
}

export function setPaginationParams(newPage: number, options: {segments: UrlSegments, pageType: PageType}) {
  const { segments, pageType } = options;
  const params = UrlParams.getParams(segments, pageType);
  const url = UrlParams.setParams({ ...params, page: newPage });

  return url;
}
