import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import type { IProductTax, IProductTaxes, ValueOf } from 'types';

import styles from './ProductTaxes.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export type Size = ValueOf<typeof SIZES>;

interface Props {
  className?: string,
  taxClassName?: string,
  taxIconClassName?: string,
  taxValueClassName?: string,
  //
  size?: Size,
  //
  taxes?: IProductTaxes
}

const ProductTaxes = (props: Props) => {

  const {
    className,
    taxClassName,
    taxIconClassName,
    taxValueClassName,
    //
    size = SIZES.MEDIUM,
    //
    taxes = []
  } = props;

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[size]]: size },
        className
      )}
    >
      {
        taxes?.map((tax, index) => {
          return (
            <ProductTax
              key={`tax-${index}`}
              className={taxClassName}
              iconClassName={taxIconClassName}
              valueClassName={taxValueClassName}
              //
              size={size}
              //
              tax={tax}
            />
          );
        })
      }
    </div>
  );
};

// Components

interface ProductTaxProps {
  className?: string,
  iconClassName?: string,
  valueClassName?: string,
  //
  size?: Size,
  //
  tax: IProductTax
}

const ProductTax = (props: ProductTaxProps) => {
  const {
    className,
    iconClassName,
    valueClassName,
    //
    size = SIZES.MEDIUM,
    //
    tax,
  } = props;

  const {
    iconPng: icon,
    text,
    backgroundColor,
    textColor: color,
  } = tax;

  return (
    <div
      className={classnames(
        styles.tax,
        { [styles[size]]: size },
        className
      )}
      style={{
        backgroundColor,
        color,
      }}
    >
      <img className={classnames(styles.icon, iconClassName)} src={icon} alt={text} />
      <span className={classnames(styles.text, valueClassName)}>{text}</span>
    </div>
  );
};

// Export

export default ProductTaxes;
