import dynamic from 'next/dynamic';

export { default as AccountLayout } from './AccountLayout/AccountLayout';
export { default as AnnouncementBanner } from './AnnouncementBanner/AnnouncementBanner';
export { default as AppVersion } from './AppVersion/AppVersion';
export { default as BlogLayout } from './BlogLayout/BlogLayout';
export { default as BreakpointsObserver } from './BreakpointsObserver/BreakpointsObserver';
export { default as Categories } from './CategoriesNavigation/Categories';
export { default as CategoriesDesktop } from './CategoriesNavigation/CategoriesDesktop';
export { default as CategoriesMobile } from './CategoriesNavigation/CategoriesMobile';
export { default as CheckoutLayout } from './CheckoutLayout/CheckoutLayout';
export { default as GlobalErrorBoundary } from './GlobalErrorBoundary/GlobalErrorBoundary';
export { default as Header } from './Header/Header';
export { default as Layout } from './BaseLayout/Layout';
export { default as MegaMenu } from './MegaMenu/MegaMenu';
export { default as OpenInAppBanner } from './OpenInAppBanner/OpenInAppBanner';
export { default as ScriptsLoader } from './ScriptsLoader/ScriptsLoader';

export const ChatButton = dynamic(() => import('./ChatButton/ChatButton'), { ssr: false });
export const ListingBanner = dynamic(() => import('./ListingBanner/ListingBanner'), { ssr: false });
export const MegaMenuContent = dynamic(() => import('./MegaMenu/MegaMenuContent'), { ssr: false });
export const NavigationLoader = dynamic(() => import('./NavigationLoader/NavigationLoader'), { ssr: false });
export const OutdatedBrowser = dynamic(() => import('./OutdatedBrowser/OutdatedBrowser'), { ssr: false });
export const ToastContainers = dynamic(() => import('./ToastContainers/ToastContainers'), { ssr: false });
export const OrderFeedbackReminderModalManager = dynamic(() => import('./OrderFeedbackReminderModalManager/OrderFeedbackReminderModalManager'), { ssr: false });
export const Managers = dynamic(() => import('./Managers/Managers'), { ssr: false });
