import React from 'react';
import classnames from 'classnames';

import { Button, Icon } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';

import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './ProductAddToCart.module.scss';

const ICON_SIZES = {
  [Sizes.SMALL]: 20,
  [Sizes.MEDIUM]: 22,
  [Sizes.LARGE]: 24,
};

interface Props {
  className?: string,
  //
  size: Size,
  //
  targetQuantity: number,
  //
  isPrimary: boolean,
  withIcon: boolean,
  //
  loading?: boolean,
  disabled?: boolean,
  //
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductAdjustQuantityButton = (props: Props) => {
  const {
    className,
    //
    size,
    //
    targetQuantity,
    //
    isPrimary,
    withIcon,
    //
    loading,
    disabled,
    //
    onClick = () => {},
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const label = `${t('ACTIONS.ADD')} ${targetQuantity}`;

  // Render

  return (
    <Button
      className={classnames(
        styles.btn,
        styles.adjustBtn,
        { [styles[size]]: size },
        className
      )}
      iconWrapperClassName={classnames(
        styles.btnIcon,
        styles.addBtnIcon,
        styles.spaced
      )}
      variant={isPrimary ? 'primary' : 'subtle'}
      size={size}
      startIcon={
        withIcon
          ? (
            <Icon
              name="edit"
              size={ICON_SIZES[size]}
              strokeWidth={2}
            />
          )
          : null
      }
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      //
      aria-label="Adjust quantity"
    >
      {label}
    </Button>
  );
};

export default ProductAdjustQuantityButton;
