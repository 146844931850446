import type { NextRouter } from 'next/router';

import QueryString from 'common/QueryString';
import { CONFIG } from 'constants/config';
import { APP_ROUTES, ROUTES_WITH_MOBILE_PREVIEW } from 'constants/routes';
import { isClient } from 'helpers/BrowserHelpers';

import type { ParsedUrlQueryInput } from 'querystring';

// --- Lowercase Redirect

export function getLowerCaseRedirectPath(slug: string | string[]) {

  if (!slug) return false;

  if (Array.isArray(slug)) {
    const fullSlug = slug.join('/');
    if (fullSlug.toLowerCase() !== fullSlug) {
      return fullSlug.toLowerCase();
    }
  } else if (slug.toLowerCase() !== slug) {
    return slug.toLowerCase();
  }

  return false;
}

// --- Mobile Preview

export function convertPathnameToMobilePreview(pathname = '') {
  try {
    const segment = `/${pathname.split('/')[1]}`;
    if ((ROUTES_WITH_MOBILE_PREVIEW).includes(segment as any)) {
      return `/mobile-preview${pathname}`;
    }
    return false;
  } catch (err) {
    console.error('Failed to convert pathname to mobile preview', err);
  }
  return false;
}

export function convertMobilePreviewLinks(string = '') {
  try {
    return string
      .replaceAll('/mobile/', '/')
      .replaceAll('/mobile-preview/', '/');
  } catch (err) {
    console.error('Failed to convert mobile preview links', err);
  }
  return string;
}

export function isMobilePreviewRoute(path: string) {
  return path.startsWith('/mobile-preview') || path.startsWith('/info/mobile');
}

// --- Query Params

export function buildURLWithQueryString(base: string, queryParams: any) {
  try {
    const qs = QueryString.stringify(queryParams, { skipNulls: true });
    if (!qs) return base;
    return `${base}?${qs}`;
  } catch (err) {
    console.error('Failed to build URL with query string ', err);
  }
  return base;
}

export function removeQueryParams(router: NextRouter, params: string | string[]) {
  const { query, replace } = router;

  const parsedParams = typeof params === 'string'
    ? [params]
    : [...params];

  parsedParams.forEach((param) => {
    delete query[param];
  });

  replace({ query }, undefined, { shallow: true });
}

export function addQueryParams(router: NextRouter, params: ParsedUrlQueryInput) {
  const { query, replace } = router;

  const updatedQuery = {
    ...query,
    ...params
  };

  replace({ query: updatedQuery }, undefined, { shallow: true });
}

// --- Navigation

export function isValidNavigationStep(url: string, options: {ignoredUrls?: string[], ignoreMobilePreview?: boolean} = {}) {
  const { ignoredUrls = [], ignoreMobilePreview = true } = options;

  if (ignoreMobilePreview && isMobilePreviewRoute(url)) {
    return false;
  }

  if (ignoredUrls.includes(url)) {
    return false;
  }

  if (url.startsWith(APP_ROUTES.LOGIN) || url.startsWith(APP_ROUTES.LOGOUT)) {
    return false;
  }

  return true;
}

// --- Authentication

export function isAuthenticatedRoute(path: string) {
  if (!path) return false;

  const authenticatedPrefixes = [
    APP_ROUTES.ACCOUNT,
    APP_ROUTES.LOGIN,
    APP_ROUTES.LOGOUT,
    APP_ROUTES.FAVORITES,
    APP_ROUTES.MY_FRESHLIST,
    APP_ROUTES.CHECKOUT,
    APP_ROUTES.ORDER_FEEDBACK,
  ];

  return authenticatedPrefixes.some((prefix) => path.startsWith(prefix));
}

// --- Misc

function isInternalHost(host: string) {
  return host === CONFIG.BASE_HOST || host === CONFIG.BASE_HOST_SHORT;
}

export function parseAbsoluteUrl(url: string) {
  if (url) {
    try {
      const transformed = new URL(url);
      const { host, pathname } = transformed;
      return {
        valid: true,
        host,
        pathname,
        mobilePreviewPathname: isInternalHost(host) ? convertPathnameToMobilePreview(pathname) : false,
        isInternal: isInternalHost(host)
      };
    } catch (err) {
      console.error('Invalid URL', err);
    }
  }

  return {
    valid: false
  };
}

export function encodeRedirectState(state: string | object) {
  const stateObject = typeof state === 'string' ? { path: state } : state;
  if (!isClient()) return null;
  return btoa(JSON.stringify(stateObject));
}

export function decodeRedirectState(state?: string | null) {
  if (!isClient() || !state) return null;
  return JSON.parse(atob(state) || '');
}
