/* eslint-disable react/button-has-type */

import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { isClient } from 'helpers/BrowserHelpers';

import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';

import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './Button.module.scss';

export const Variants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUBTLE: 'subtle',
  EMPTY: 'empty'
} as const;

export type Variant = typeof Variants[keyof typeof Variants];

export const DEFAULT_CLASS_NAME = 'btn';

interface SharedProps {
  //
  className?: string,
  contentClassName?: string,
  iconWrapperClassName?: string,
  startIconWrapperClassName?: string,
  endIconWrapperClassName?: string,
  iconClassName?: string,
  startIconClassName?: string,
  endIconClassName?: string,
  loadingWrapperClassName?: string,
  //
  readOnly?: boolean,
  disabled?: boolean,
  //
  variant?: Variant,
  size?: Size,
  rounded?: boolean,
  //
  loading?: boolean,
  loaderColor?: string,
  //
  startIcon?: string | React.ReactNode,
  endIcon?: string | React.ReactNode,
  icon?: string | React.ReactNode,
  iconSize?: number,
  iconStrokeWidth?: number,
  //
  dataTestId?: string,
  //
  beforeContent?: React.ReactNode,
  afterContent?: React.ReactNode,
  children?: React.ReactNode
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, SharedProps {
  tag?: 'button',
  type?: 'button' | 'submit' | 'reset'
}

interface DivProps extends React.HTMLAttributes<HTMLDivElement>, SharedProps {
  tag: 'div',
  type?: never
}

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, SharedProps {
  tag: 'a',
  type?: never
}

type Props = (
  | DivProps
  | AnchorProps
  | ButtonProps
);

type Ref = (
  | HTMLButtonElement
  | HTMLDivElement
  | HTMLAnchorElement
);

const Button = React.forwardRef<Ref, Props>((
  props: Props,
  ref: React.Ref<Ref>
) => {

  const {
    tag = 'button',
    //
    className = '',
    contentClassName,
    iconWrapperClassName,
    startIconWrapperClassName,
    endIconWrapperClassName,
    iconClassName,
    startIconClassName,
    endIconClassName,
    loadingWrapperClassName,
    //
    variant,
    type = 'button',
    size = Sizes.LARGE,
    rounded = false,
    //
    readOnly = false,
    disabled,
    //
    loading = false,
    loaderColor = variant === Variants.PRIMARY ? styles.whiteColor : styles.primaryColor,
    //
    startIcon,
    endIcon,
    icon,
    iconSize = 24,
    iconStrokeWidth,
    //
    dataTestId,
    //
    beforeContent,
    afterContent,
    children,
    //
    ...rest
  } = props;

  // Hooks

  // Classes

  const classes = classnames(
    styles.btn,
    { [styles.btnLarge]: size === Sizes.LARGE && !icon },
    { [styles.btnMedium]: size === Sizes.MEDIUM && !icon },
    { [styles.btnSmall]: size === Sizes.SMALL && !icon },
    { [styles.btnLargeIcon]: size === Sizes.LARGE && icon },
    { [styles.btnMediumIcon]: size === Sizes.MEDIUM && icon },
    { [styles.btnSmallIcon]: size === Sizes.SMALL && icon },
    { [styles.btnPrimary]: variant === Variants.PRIMARY },
    { [styles.btnSecondary]: variant === Variants.SECONDARY },
    { [styles.btnSubtle]: variant === Variants.SUBTLE },
    { [styles.btnEmpty]: variant === Variants.EMPTY },
    { [styles.btnLoading]: loading },
    { [styles.btnReadOnly]: readOnly },
    { [styles.btnRounded]: rounded },
    DEFAULT_CLASS_NAME,
    className
  );

  const contentClasses = classnames(
    styles.content,
    { [styles.loading]: loading },
    contentClassName
  );

  // Components

  const StartIcon = (
    <>
      <ButtonIcon
        className={classnames(styles.iconStart, iconWrapperClassName, startIconWrapperClassName)}
        iconClassName={classnames(iconClassName, startIconClassName)}
        icon={startIcon}
        size={iconSize}
        strokeWidth={iconStrokeWidth}
        withWrapper
      />
    </>
  );

  const EndIcon = (
    <>
      <ButtonIcon
        className={classnames(styles.iconEnd, iconWrapperClassName, endIconWrapperClassName)}
        iconClassName={classnames(iconClassName, endIconClassName)}
        icon={endIcon}
        size={iconSize}
        strokeWidth={iconStrokeWidth}
        withWrapper
      />
    </>
  );

  const Content = (
    <>
      {
        !icon
          ? children
          : (
            <ButtonIcon
              iconClassName={iconClassName}
              icon={icon}
              size={iconSize}
              strokeWidth={iconStrokeWidth}
            />
          )
      }
    </>
  );

  const Inner = (
    <>
      {/* Before Content */}
      {beforeContent}

      {/* Content */}
      <div
        className={contentClasses}
      >
        {StartIcon}
        {Content}
        {EndIcon}
      </div>

      {/* After Content */}
      {afterContent}

      {/* Loader */}
      <Fade
        isVisible={loading}
        className={classnames(styles.loadingWrapper, loadingWrapperClassName)}
        enterDuration={200}
        enterDelay={200}
        exitDuration={100}
        exitDelay={0}
        lazyLoad
      >
        <ButtonLoader
          color={loaderColor}
          loading={loading}
          size={size === Sizes.SMALL ? 16 : 20}
          lineWeight={2}
        />
      </Fade>
    </>
  );

  // Render

  // --- Anchor

  if (tag === 'a') {
    return (
      <a
        ref={ref as React.RefObject<HTMLAnchorElement>}
        className={classes}
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
        {...rest as AnchorProps}
      >
        {Inner}
      </a>
    );
  }

  // --- Div

  if (tag === 'div') {
    return (
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className={classes}
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
        {...rest as DivProps}
      >
        {Inner}
      </div>
    );
  }

  // --- Button

  return (
    <button
      ref={ref as React.RefObject<HTMLButtonElement>}
      className={classes}
      type={type}
      disabled={disabled}
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      {...rest as ButtonProps}
    >
      {Inner}
    </button>
  );

});

// Components

// --- Icon

interface ButtonIconProps {
  className?: string,
  iconClassName?: string,
  icon: string | React.ReactNode,
  size: number,
  strokeWidth?: number,
  withWrapper?: boolean
}

const ButtonIcon = (props: ButtonIconProps) => {
  const {
    className,
    iconClassName,
    icon,
    size,
    strokeWidth,
    withWrapper,
  } = props;

  // Empty

  if (!icon) return null;

  // Without Wrapper

  if (!withWrapper) {
    return (
      <>
        {
          typeof icon === 'string'
            ? <Icon className={iconClassName} size={size} strokeWidth={strokeWidth} name={icon} />
            : icon
        }
      </>
    );
  }

  // With Wrapper

  return (
    <div className={className}>
      {
        typeof icon === 'string'
          ? <Icon className={iconClassName} size={size} strokeWidth={strokeWidth} name={icon} />
          : icon
      }
    </div>
  );
};

// --- Loader

interface ButtonLoaderProps {
  loading: boolean,
  color?: string,
  size?: number,
  lineWeight?: number
}

const ButtonLoader = (props: ButtonLoaderProps) => {
  const {
    loading,
    size = 24,
    lineWeight = 2,
    color,
  } = props;

  return isClient()
    ? (
      <Loader
        color={color}
        loading={loading}
        size={size}
        lineWeight={lineWeight}
      />
    ) : (
      <div />
    );
};

// Export

export default Button;
