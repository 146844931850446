import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getOrder } from 'services/OrdersService';

export const useOrder = (id, options = {}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.ORDER,
      id
    ],
    queryFn: () => getOrder(id),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });
};
