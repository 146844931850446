import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

import { DEFAULT_MODAL_PRIORITY } from 'constants/modal';
import { useDrawerStore } from 'context/DrawerContext';
import { useModalDispatchHelpers, useModalStore } from 'context/ModalContext';
import {
  SCROLL_LOCK_IGNORE_CLASS, clearAllBodyScrollLocks, disableBodyScroll
} from 'helpers/ScrollHelpers';
import { useInView } from 'hooks/common/useInView';
import { useMediaQuery } from 'hooks/useMediaQuery';

import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import ModalOverlay from './ModalOverlay';

import type { Props as ModalHeaderProps } from './ModalHeader';

import { Breakpoints, Positions } from 'types';
import type { Breakpoint, Position, ValueOf } from 'types';

import styles from './Modal.module.scss';

export const MODAL_OVERLAY_CLASS = 'modal-overlay';

export const MODAL_PRIORITIES = [1, 2, 3, 4, 5, 6] as const;
export type ModalPriority = ValueOf<typeof MODAL_PRIORITIES>;

interface BreakpointSpecificProps {
  position?: Position,
  isFullScreen?: boolean,
  withPadding?: boolean,
  withCloseButton?: boolean
}

interface Props {
  isOpen: boolean,
  //
  overlayRef?: (instance: HTMLDivElement) => void,
  bodyRef?: (instance: HTMLDivElement) => void,
  //
  className?: string,
  backgroundClassName?: string,
  contentClassName?: string,
  innerClassName?: string,
  headerClassName?: string,
  titleClassName?: string,
  closeButtonClassName?: string,
  //
  title?: string | React.ReactElement,
  headerProps?: ModalHeaderProps,
  //
  wide?: boolean,
  withPadding?: boolean,
  withCloseButton?: boolean,
  priority?: ModalPriority,
  shouldReserveScrollBarGap?: boolean,
  //
  breakpoint?: Breakpoint,
  desktopProps?: BreakpointSpecificProps,
  responsiveProps?: BreakpointSpecificProps,
  //
  shouldCloseOnEsc?: boolean,
  shouldCloseOnOverlayClick?: boolean,
  shouldFocusAfterRender?: boolean,
  shouldReturnFocusAfterClose?: boolean,
  //
  close?: () => void,
  onAfterClose?: () => void,
  onAfterOpen?: () => void,
  //
  onOverlayOpen?: () => void,
  onOverlayClose?: () => void,
  onContentOpening?: () => void,
  onContentOpen?: () => void,
  onContentClosing?: () => void,
  onContentClose?: () => void,
  //
  dataTestId?: string,
  children?: React.ReactNode
}

const Modal = (props: Props) => {
  const {
    isOpen,

    // Refs
    overlayRef: onInitOverlayRef = () => {},
    bodyRef: onInitBodyRef = () => {},

    // Classes
    className,
    backgroundClassName,
    contentClassName,
    innerClassName,
    headerClassName,
    titleClassName,
    closeButtonClassName,

    // Breakpoint
    breakpoint = Breakpoints.MD,
    desktopProps = {
      position: Positions.CENTER
    },
    responsiveProps = {
      position: Positions.BOTTOM
    },

    // Header
    title = '',
    headerProps,

    // Flags

    // --- react-modal Flags
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    shouldFocusAfterRender = true,
    shouldReturnFocusAfterClose = false,

    // --- Custom Flags
    wide = false,
    withCloseButton = true,
    withPadding = true,
    shouldReserveScrollBarGap = true,

    // --- Priority
    priority = DEFAULT_MODAL_PRIORITY,

    // Handlers
    close = () => {},
    onAfterClose = () => {},
    onAfterOpen = () => {},

    // Callbacks
    onOverlayOpen = () => {},
    onOverlayClose = () => {},
    onContentOpening = () => {},
    onContentOpen = () => {},
    onContentClosing = () => {},
    onContentClose = () => {},

    // Children
    children,

    // Misc
    dataTestId,
    ...rest
  } = props;

  // Refs

  const overlayRef = React.useRef<HTMLDivElement>();
  const contentRef = React.useRef<HTMLDivElement>();
  const innerRef = React.useRef<HTMLDivElement>(null);

  // Hooks

  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [isContentOpen, setIsContentOpen] = React.useState(false);

  const isBreakpoint = useMediaQuery(`(max-width: ${styles[`breakpoint_${breakpoint}`]})`);

  const { observe: anchorRef, inView: isNotScrolled } = useInView();

  const { visible: isDrawerOpen } = useDrawerStore();
  const { visible: isModalVisible, genericModalStackCount } = useModalStore();

  const { showGenericModal, hideGenericModal } = useModalDispatchHelpers();

  // Effects

  React.useEffect(() => {
    if (isOpen) {
      showGenericModal();
    } else {
      setIsOverlayOpen(false);
      setIsContentOpen(false);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (overlayRef.current) {
      onInitOverlayRef(overlayRef.current);
    }
    if (innerRef.current) {
      onInitBodyRef(innerRef.current);
    }
  }, [overlayRef.current, innerRef.current]);

  // Props

  const defaultPosition = isBreakpoint
    ? Positions.CENTER
    : Positions.BOTTOM;

  const {
    position = defaultPosition,
    isFullScreen,
    withPadding: withPaddingAtBreakpoint = withPadding,
    withCloseButton: withCloseButtonAtBreakpoint = withCloseButton,
  } = (
    isBreakpoint
      ? responsiveProps
      : desktopProps
  ) || {};

  const {
    desktopProps: headerDesktopProps = {},
    responsiveProps: headerResponsiveProps = {},
    ...headerRestProps
  } = headerProps || {};

  const hasHeader = (title || (withCloseButton && withCloseButtonAtBreakpoint));

  // Render

  return (
    <ReactModal
      isOpen={isOpen}
      // animation
      closeTimeoutMS={250}
      // classes
      overlayClassName={classnames(
        styles.overlay,
        { [styles[position]]: !!position },
        { [styles.responsive]: isBreakpoint },
        { [styles.fullScreen]: isFullScreen },
        MODAL_OVERLAY_CLASS,
        className,
      )}
      style={{
        overlay: {
          zIndex: parseInt(styles.baseZIndex, 10) + priority * 10,
        }
      }}
      className={classnames(
        styles.content,
        { [styles[position]]: !!position },
        { [styles.desktop]: !isBreakpoint },
        { [styles.responsive]: isBreakpoint },
        { [styles.fullScreen]: isFullScreen },
        { [styles.wide]: wide },
        SCROLL_LOCK_IGNORE_CLASS,
        contentClassName
      )}
      // refs
      overlayRef={(node: HTMLDivElement) => overlayRef.current = node}
      contentRef={(node: HTMLDivElement) => contentRef.current = node}
      // renderers
      overlayElement={(
        overlayProps: React.ComponentPropsWithRef<'div'>,
        contentElement: React.ReactElement
      ) => {
        return (
          <ModalOverlay
            isOpen={isOverlayOpen}
            //
            position={position}
            isResponsive={isBreakpoint}
            isFullScreen={!!isFullScreen}
            //
            backgroundClassName={classnames(
              styles.background,
              { [styles.responsive]: isBreakpoint },
              { [styles.fullScreen]: isFullScreen },
              backgroundClassName
            )}
            //
            onEntered={onOverlayOpen}
            onExited={onOverlayClose}
            //
            {...overlayProps}
          >
            {contentElement}
          </ModalOverlay>
        );
      }}
      contentElement={(
        contentProps: React.ComponentPropsWithRef<'div'>,
        contentChildren: React.ReactNode
      ) => {
        return (
          <ModalContent
            isOpen={isContentOpen}
            //
            position={position}
            isResponsive={isBreakpoint}
            isFullScreen={!!isFullScreen}
            isWide={wide}
            //
            onEntering={onContentOpening}
            onEntered={onContentOpen}
            onExiting={onContentClosing}
            onExited={onContentClose}
            //
            {...contentProps}
          >
            {contentChildren}
          </ModalContent>
        );
      }}
      // flags
      preventScroll
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      shouldFocusAfterRender={shouldFocusAfterRender}
      shouldCloseOnEsc={shouldCloseOnEsc}
      // test
      testId={dataTestId}
      // handlers
      onRequestClose={() => {
        close();
      }}
      onAfterOpen={() => {
        setIsOverlayOpen(true);
        setIsContentOpen(true);
        if (!isDrawerOpen) {
          disableBodyScroll(
            innerRef?.current,
            { reserveScrollBarGap: !isBreakpoint && shouldReserveScrollBarGap }
          );
        }
        onAfterOpen();
      }}
      onAfterClose={() => {
        hideGenericModal();
        if (!isDrawerOpen && !isModalVisible && genericModalStackCount <= 1) {
          clearAllBodyScrollLocks();
        }
        onAfterClose();
      }}
      // test
      data={{ testid: dataTestId }}
      // rest
      {...rest}
    >

      {/* Header */}
      {
        hasHeader
          ? (
            <ModalHeader
              className={headerClassName}
              titleClassName={titleClassName}
              closeButtonClassName={closeButtonClassName}
              //
              title={title}
              //
              breakpoint={breakpoint}
              withPadding
              desktopProps={{
                size: 'large',
                ...headerDesktopProps
              }}
              responsiveProps={{
                size: 'medium',
                ...headerResponsiveProps
              }}
              //
              stuck={!isNotScrolled}
              //
              onClose={close}
              //
              {...headerRestProps}
            />
          ) : null
      }

      {/* Inner */}
      <div
        ref={innerRef}
        className={classnames(
          styles.inner,
          { [styles.hasTitle]: !!title },
          { [styles.padded]: withPadding && withPaddingAtBreakpoint },
          SCROLL_LOCK_IGNORE_CLASS,
          innerClassName,
        )}
      >

        {/* Anchor */}
        <div ref={anchorRef} className={styles.anchor} />

        {/* Body */}
        {children}

      </div>

    </ReactModal>
  );
};

export default React.memo(Modal);
