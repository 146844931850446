import type { ImageProps } from 'components/ui';
import type { IImage } from 'types/image';
import type { IAnalyticsListProps } from 'types/misc';
import type { IProduct } from 'types/product';

// --- Params

export interface IListingRequestParams {
  page?: number,
  itemsPerPage?: number,
  filters?: IListingActiveFilters,
  orderBy?: IListingActiveOrderBy,
  searchQuery?: string | null,
  category?: string | null,
  identifier?: string | null
}

// --- Categories

export interface IListingCategory {
  code: string,
  name: string,
  count: number,
  selected: boolean
}

export type IListingCategories = IListingCategory[];

// --- Order By

export interface IListingOrderByOption {
  field: string,
  direction: 'asc' | 'desc',
  name: string,
  isSelected: boolean
}

export type IListingOrderBy = IListingOrderByOption[];

export type IListingActiveOrderBy = null | {
  field: string,
  direction: 'asc' | 'desc'
}

// --- Filters

export interface IListingFilterOption {
  code: string,
  name: string,
  count: number,
  isSelected: boolean
}

export interface IListingFilter {
  code: string,
  name: string,
  countSelected: number,
  values: IListingFilterOption[]
}

export interface IListingFilters {
  countSelected: number,
  items: IListingFilter[]
}

export type IListingActiveFilters = [string, string | string[]][] | null;

// --- Pagination

export interface IListingPagination {
  page: number,
  pages: number,
  total: number,
  itemsPerPage: number
}

// --- Breadcrumbs
export interface IListingBreadcrumb {
  name: string,
  code: string,
  slug: string
}

// --- Listing

export interface IListingAttributes {
  name: string,
  description?: null,
  slug?: string,
  descriptionHtml?: string,
  descriptionText?: string,
  //
  mainBanner: IImage,
  listingBanner: IImage
}

// --- Generic Items Listing

export interface IItemsListing<T = IProduct> extends
  IAnalyticsListProps,
  IListingPagination
{
  items: T[]
}

// --- Products listing

export interface IProductsListing extends
  IListingAttributes,
  IItemsListing<IProduct>
{
  banners?: IListingBannerResponse[],
  categories: IListingCategories,
  orderBy: IListingOrderBy,
  filters: IListingFilters
}

export interface IListingBannerResponse {
  analyticsListId?: string,
  analyticsListName?: string,
  position: number,
  type: ListingBannerType,
  name: string,
  image: {jpg: string, webp?: string},
  imageResponsive: {jpg: string, webp?: string},
  url: string,
  newTab?: boolean
}

export interface IListingBanner {
  position: number,
  type: ListingBannerType,
  name: string,
  image: {
    desktop: IImage,
    responsive: IImage
  },
  href: string,
  newTab?: boolean,
  desktopProps?: Partial<ImageProps>,
  responsiveProps?: Partial<ImageProps>,
  listProps?: IAnalyticsListProps
}

export enum ListingBannerType {
  Header = 'header',
  Listing = 'listing'
}
