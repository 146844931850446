import { decodeURIComponentSafe } from 'helpers/BrowserHelpers';

import { hasSegments } from './UrlParamsHelpers';

import type { UrlSegments } from './UrlParams';

const SEARCH_SEGMENT = 'search';

export function getSearchParams(segments: UrlSegments) {

  if (!hasSegments(segments)) {
    return {
      hasSearchQuery: false,
      searchQuery: null,
    };
  }

  const searchIndex = segments.findIndex((segment) => segment.toLowerCase() === SEARCH_SEGMENT);
  if (searchIndex !== -1) {
    const searchQuery = segments[searchIndex + 1];
    return {
      hasSearchQuery: !!searchQuery,
      searchQuery: searchQuery ? decodeURIComponentSafe(searchQuery) : null
    };
  }

  return {
    hasSearchQuery: false,
    searchQuery: null,
  };
}
