import { useQuery } from '@tanstack/react-query';

import { ERROR_CODES } from 'constants/errors';
import { QUERY_KEYS } from 'constants/query-keys';
import { getProduct } from 'services/ProductsService';

import type { IProductDetails, QueryClient, ReactQueryOptions } from 'types';

export function useProduct(slug: string = '', queryOptions: ReactQueryOptions<IProductDetails> = {}) {
  return useQuery({
    queryKey: getKey(slug),
    queryFn: () => getProduct(slug),
    ...queryOptions
  });
}

function getKey(slug: string) {
  return [
    QUERY_KEYS.PRODUCT,
    slug
  ];
}

export async function prefetchProduct(slug: string, queryClient: QueryClient) {
  try {
    const product = await queryClient.fetchQuery({
      queryKey: getKey(slug),
      queryFn: () => getProduct(slug)
    });

    if (product?.slug && product?.slug !== slug) {
      return { redirect: product.slug };
    }
  } catch (err: any) {
    if (err?.response?.status === ERROR_CODES.NOT_FOUND) { return false; }
  }
  return true;
}
