import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon/Icon';
import Image from '../Image/Image';

import styles from './List.module.scss';

export type IListItem<I> = {
  render?: (item: I) => React.ReactNode
}

interface Props<I extends IListItem<I>> {
  className?: string,
  headerClassName?: string,
  headerIconClassName?: string,
  headerTitleClassName?: string,
  itemClassName?: string,
  //
  items: I[],
  //
  header?: Header,
  withBorders?: boolean,
  //
  getItemKey: (item: I) => string | number,
  renderItem: (item: I) => React.ReactNode
}

function List<I extends IListItem<I>>(props: Props<I>) {
  const {
    className,
    headerClassName,
    headerIconClassName,
    headerTitleClassName,
    itemClassName,
    //
    items,
    //
    header,
    withBorders,
    //
    getItemKey,
    renderItem
  } = props;

  // Props

  const {
    title, image, icon,
  } = header || ({} as Header);

  // Render

  return (
    <>

      {/* Header */}
      <ListHeader
        className={headerClassName}
        iconClassName={headerIconClassName}
        titleClassName={headerTitleClassName}
        title={title}
        image={image}
        icon={icon}
      />

      {/* List */}
      <div className={classnames(styles.list, className)}>
        {
          items.map((item, index) => {
            const key = getItemKey(item);
            return (
              <div
                key={key || `${title}-${index}`}
                className={classnames(
                  styles.item,
                  { [styles.bordered]: withBorders },
                  itemClassName
                )}
              >
                {
                  item?.render
                    ? item.render(item)
                    : renderItem(item)
                }
              </div>
            );
          })
        }
      </div>

    </>
  );
}

// Components

// --- Header

interface Header {
  title: string,
  image?: string,
  icon?: string
}

interface ListHeaderProps extends Header {
  className?: string,
  iconClassName?: string,
  titleClassName?: string
}

const ListHeader = (props: ListHeaderProps) => {
  const {
    className,
    iconClassName,
    titleClassName,
    //
    title,
    image,
    icon,
  } = props;

  if (!title) return null;

  return (
    <div className={classnames(styles.header, className)}>

      {/* Image */}
      {
        image && (
          <div className={classnames(styles.icon, iconClassName)}>
            <Image
              src={image}
              width={20}
              height={20}
              quality="100"
              alt={title}
            />
          </div>
        )
      }

      {/* Icon */}
      {
        icon && (
          <div className={classnames(styles.icon, iconClassName)}>
            <Icon
              name={icon}
              size={20}
            />
          </div>
        )
      }

      {/* Title */}
      <div className={classnames(styles.title, titleClassName)}>
        {title}
      </div>

    </div>
  );
};

// Export

export default List;
