import { useBreakpointsSelector } from 'context/BreakpointsContext';
import { capitalize } from 'helpers/StringHelpers';

export type Direction = 'up' | 'min' | 'down' | 'max';
export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

export function useBreakpoint(breakpoint: Breakpoint, direction: Direction = 'up') {
  const breakpointKey = getKey(breakpoint, direction);
  const isBreakpoint = useBreakpointsSelector((breakpoints) => breakpoints[breakpointKey]);
  return isBreakpoint;
}

const getKey = (breakpoint: Breakpoint, direction: Direction) => {
  return `is${capitalize(breakpoint)}${direction === 'max' || direction === 'down' ? 'Max' : ''}`;
};
