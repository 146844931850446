import React from 'react';

import { useBreakpoint } from 'hooks/useBreakpoint';

import OrderSummary from '../OrderSummary/OrderSummary';

import type { IOrder } from 'types';

import styles from './OrderDetailsTotal.module.scss';

interface Props {
  order?: IOrder
}

const OrderDetailsTotal = (props: Props) => {
  const { order } = props;
  return (
    <div className={styles.root}>
      <OrderSummary
        className={styles.summary}
        tableClassName={styles.table}
        rowGroupClassName={styles.group}
        rowClassName={styles.row}
        rowKeyClassName={styles.key}
        rowValueClassName={styles.value}
        separatorClassName={styles.separator}
        //
        order={order}
      />
    </div>
  );
};
export default OrderDetailsTotal;
