/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import classnames from 'classnames';

import { Image, Popover } from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { DirectionalPosition, IProductTag } from 'types';
import { DirectionalPositions } from 'types';

import styles from './ProductTag.module.scss';

export interface Props {
  className?: string,
  tag: IProductTag,
  positions: DirectionalPosition[],
  showText: boolean
}

const ProductTag = (props: Props) => {
  const {
    className,
    tag,
    positions = [DirectionalPositions.BOTTOM],
    showText = false
  } = props;

  const {
    icon,
    text = '',
    textColor = '',
    backgroundColor = '',
  } = tag;

  // Hooks

  const isBreakpoint = useMediaQuery('(max-width: 1260px)');
  const { isOpen, close, toggle } = useIsOpen();

  // Empty

  if (!icon) return null;

  // Handlers

  const onTagClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isBreakpoint && !showText) {
      toggle();
    }
  };

  // Render

  return (
    <TagPopover
      className={className}
      isOpen={isOpen}
      text={text}
      positions={positions}
      onClickOutside={close}
    >
      <Tag
        text={text}
        icon={icon}
        showText={showText}
        backgroundColor={backgroundColor}
        textColor={textColor}
        onClick={onTagClickHandler}
      />
    </TagPopover>
  );
};

// --- Popover

// TODO: refactor after Popover is migrated to ts
interface TagPopoverProps {
  className?: string,
  isOpen: boolean,
  text: string,
  positions: DirectionalPosition[],
  children?: React.ReactNode,
  onClickOutside: (args: any) => any
}

const TagPopover = (props: TagPopoverProps) => {
  const {
    className,
    text,
    positions,
    children,
    ...rest
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={classnames(styles.popoverContainer, className)}
    >
      <Popover
        parentElement={ref || undefined}
        contentClassName={styles.popover}
        align="center"
        positions={positions}
        content={(
          <div className={styles.popoverContent}>{text}</div>
        )}
        {...rest}
      >
        {children}
      </Popover>
    </div>
  );
};

// --- Tag

interface TagProps {
  icon?: string,
  text?: string,
  textColor?: string,
  backgroundColor?: string,
  showText?: boolean,
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>((props: TagProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    icon,
    text,
    showText,
    textColor,
    backgroundColor,
    onClick
  } = props;

  return (
    <div
      ref={ref}
      className={classnames(styles.root)}
      role="button"
      onClick={onClick}
    >
      <div
        className={classnames(styles.container, { [styles.withText]: showText })}
        style={{ backgroundColor: showText ? backgroundColor : 'transparent', color: textColor }}
        title={showText ? undefined : text}
      >
        <Image
          optimize
          className={styles.icon}
          src={icon}
          width="24"
          height="24"
          data-prevent-click-propagation
          alt={text}
        />
        {
          showText && (
            <div className={styles.label}>
              {text}
            </div>
          )
        }
      </div>
    </div>
  );
});

// Export

export default React.memo(ProductTag);
