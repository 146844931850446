import React from 'react';

import { Fade } from 'components/animations';
import { useTranslation } from 'hooks/common/useTranslation';

import styles from './ProductQuantityOverlay.module.scss';

interface Props {
  quantity: number
}

const ProductQuantityOverlay = (props: Props) => {
  const {
    quantity
  } = props;

  const { t } = useTranslation();

  const isVisible = quantity > 0;

  return (
    <Fade
      className={styles.root}
      isVisible={isVisible}
      lazyLoad
      enterDuration={300}
      exitDuration={300}
    >
      <span className={styles.count}>{quantity}</span>
      <span>{t('PRODUCT.IN_CART')}</span>
    </Fade>
  );
};

export default ProductQuantityOverlay;
