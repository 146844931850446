import React from 'react';
import classnames from 'classnames';

import { getEmbeddedYoutubeUrl } from 'helpers/VideoHelpers';
import { useInView } from 'hooks/common/useInView';
import { useIsMounted } from 'hooks/useIsMounted';

import styles from './YoutubeVideo.module.scss';

interface Props {
  className: string,
  id?: string,
  url?: string,
  embeddedUrl?: string,
  width?: number | undefined,
  height?: number | undefined,
  onClick?: () => void,
  onEnter?: () => void
}

const YoutubeVideo = (props: Props) => {

  const {
    className,
    //
    id,
    url,
    embeddedUrl,
    //
    width = 640,
    height = 360,
    //
    onClick,
    onEnter,
  } = props;

  // Hooks

  const isMountedFn = useIsMounted();
  const isMounted = isMountedFn();

  const { observe } = useInView({
    threshold: 0.5,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter();
      }
      unobserve();
    }
  });

  // Props

  const src = embeddedUrl || getEmbeddedYoutubeUrl(id, url);

  // Handlers

  const onWindowBlur = () => {
    setTimeout(() => {
      if (document.activeElement && document.activeElement.tagName === 'IFRAME' && document.activeElement.id === `youtube-player-${id}`) {
        if (onClick) {
          onClick();
        }
      }
    });
  };

  // Effects

  React.useEffect(() => {
    if (isMounted) {
      window.addEventListener('blur', onWindowBlur);
      return () => {
        window.removeEventListener('blur', onWindowBlur);
      };
    }
  }, [isMounted]);

  // Empty

  if (!src) return null;

  // Render

  return (
    <div
      ref={onEnter ? observe : null}
      className={classnames(
        styles.root,
        className
      )}
    >
      <iframe
        id={`youtube-player-${id}`}
        width={width}
        height={height}
        key={src}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />

    </div>
  );
};

export default YoutubeVideo;
