import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { Head } from 'components/ui';
import { BlogProvider } from 'context/BlogContext';
import { useIsClient } from 'hooks/useIsClient';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import OpenInAppBanner from '../OpenInAppBanner/OpenInAppBanner';
import PreviewModeBanner from '../PreviewModeBanner/PreviewModeBanner';

import styles from './BlogLayout.module.scss';

const AddressSearchModal = dynamic(() => import('../AddressSearchModal/AddressSearchModal'), { ssr: false });
const DrawerContainer = dynamic(() => import('../DrawerContainer/DrawerContainer'), { ssr: false });
const ProductModal = dynamic(() => import('../ProductModal/ProductModal'), { ssr: false });
const ModalContainer = dynamic(() => import('../ModalContainer/ModalContainer'), { ssr: false });

interface Props {
  className?: string,
  containerClassName?: string,
  contentClassName?: string,
  //
  header?: {isVisible: boolean, props: React.ComponentProps<typeof Header>},
  footer?: {isVisible: boolean, props: React.ComponentProps<typeof Footer>},
  //
  openInAppBanner?: {isVisible: boolean, props: React.ComponentProps<typeof OpenInAppBanner>},
  //
  leftSlot?: {component: (props?: any) => JSX.Element, props: any},
  rightSlot?: {component: (props?: any) => JSX.Element, props: any},
  fullscreen?: boolean,
  preview?: boolean,
  id?: string,
  //
  pageProps?: any,
  children?: React.ReactNode
}

const BlogLayout = (props: Props) => {

  const isClient = useIsClient();

  const {
    className,
    containerClassName,
    contentClassName,
    //
    header = { isVisible: true, props: {} },
    footer = { isVisible: true, props: {} },
    openInAppBanner = { isVisible: true, props: {} },
    //
    fullscreen = false,
    preview,
    //
    children,
    ...rest
  } = props;

  return (
    <div className={classnames(styles.root, { [styles.fullscreen]: fullscreen }, className)}>

      {/* Head */}
      <Head />

      {/* Preview Banner */}
      {preview && <PreviewModeBanner />}

      {/* Header */}
      {header.isVisible && <Header {...header.props} />}

      {/* Container */}
      <div className={classnames(styles.container, containerClassName)} {...rest}>

        {/* Main */}
        <main className={classnames(styles.content, { [styles.rendered]: isClient }, contentClassName)}>
          <BlogProvider>
            {children}
          </BlogProvider>
        </main>

        {/* Containers */}
        <DrawerContainer />
        <ModalContainer />

      </div>

      {/* Open In App Banner */}
      {openInAppBanner?.isVisible && <OpenInAppBanner {...openInAppBanner.props} />}

      {/* Footer */}
      {footer.isVisible && <Footer {...footer.props} />}

      {/* Modals */}
      <ProductModal />
      <AddressSearchModal />

    </div>
  );
};

export default BlogLayout;
