import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getCategoriesFromWebApi } from 'services/CategoriesService';

import type { ICategory } from 'types';

export const useCategories = () => {
  return useQuery<ICategory[]>({
    queryKey: getKey(),
    queryFn: () => getCategoriesFromWebApi(),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME
  });
};

export function getKey() {
  return createQueryKey([QUERY_KEYS.CATEGORIES]);
}
