import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { Head } from 'components/ui';
import { useIsClient } from 'hooks/useIsClient';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import OpenInAppBanner from '../OpenInAppBanner/OpenInAppBanner';
import PreviewModeBanner from '../PreviewModeBanner/PreviewModeBanner';

import styles from './Layout.module.scss';

const ProductModal = dynamic(() => import('../ProductModal/ProductModal'), { ssr: false });
const AddressSearchModal = dynamic(() => import('../AddressSearchModal/AddressSearchModal'), { ssr: false });
const DrawerContainer = dynamic(() => import('../DrawerContainer/DrawerContainer'), { ssr: false });
const ModalContainer = dynamic(() => import('../ModalContainer/ModalContainer'), { ssr: false });

interface Props {
  className?: string,
  containerClassName?: string,
  contentClassName?: string,
  //
  header?: {isVisible: boolean, props: React.ComponentProps<typeof Header>},
  footer?: {isVisible: boolean, props: React.ComponentProps<typeof Footer>},
  //
  openInAppBanner?: {isVisible: boolean, props: React.ComponentProps<typeof OpenInAppBanner>},
  //
  leftSlot?: {component: (props?: any) => JSX.Element, props: any},
  rightSlot?: {component: (props?: any) => JSX.Element, props: any},
  fullscreen?: boolean,
  preview?: boolean,
  id?: string,
  //
  pageProps?: any,
  children?: React.ReactNode
}

const Layout = (props: Props) => {

  const isClient = useIsClient();

  const {
    header = { isVisible: true, props: {} },
    footer = { isVisible: true, props: {} },
    openInAppBanner = { isVisible: true, props: {} },
    leftSlot,
    rightSlot,
    fullscreen = false,
    className,
    containerClassName,
    contentClassName,
    preview,
    children,
    ...rest
  } = props;

  return (
    <div id="layout" className={classnames(styles.root, { [styles.fullscreen]: fullscreen }, className)}>
      <Head />
      {preview && <PreviewModeBanner />}
      {header.isVisible && <Header {...header.props} />}
      <div className={classnames(styles.container, containerClassName)} {...rest}>
        {leftSlot && <leftSlot.component {...leftSlot.props} />}
        <main className={classnames(styles.content, { [styles.rendered]: isClient }, contentClassName)}>
          {children}
        </main>
        {rightSlot && <rightSlot.component {...rightSlot.props} />}
        <DrawerContainer />
        <ModalContainer />
      </div>
      {openInAppBanner?.isVisible && <OpenInAppBanner {...openInAppBanner.props} />}
      {footer.isVisible && <Footer {...footer.props} />}
      <ProductModal />
      <AddressSearchModal />
    </div>
  );
};

export default Layout;
