import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import {
  Button, Icon, Image, Link, Modal
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { useNavigationStore } from 'context/NavigationContext';
import * as CategoryHelpers from 'helpers/CategoryHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useCategories } from 'hooks/data/useCategories';
import { useAnalytics } from 'hooks/useAnalytics';

import type { ICategory, ICategoryTrail } from 'types';

import styles from './CategoriesMobile.module.scss';

const CategoriesMobile = () => {

  // Hooks

  const { t } = useTranslation();

  const router = useRouter();
  const analytics = useAnalytics();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const [activeCategory, setActiveCategory] = React.useState<ICategory>();
  const [displayCategory, setDisplayCategory] = React.useState<ICategory>();

  const { slug } = useNavigationStore();
  const { data: categories } = useCategories();

  const updateCategory = (cat: ICategory, tr: ICategoryTrail[]) => {
    setActiveCategory({ ...cat, trail: tr });
    setDisplayCategory({ ...cat, trail: tr });
  };

  // Effects

  React.useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  React.useEffect(() => {
    if (!categories || !slug) return;

    const { category, trail: rawTrail } = CategoryHelpers.findCategoryBySlug(categories, slug);
    const trail: ICategoryTrail[] = rawTrail ?? [];

    if (!category || !category.slug) return;

    const hasChildren = !!category.children?.length;
    if (hasChildren) {
      updateCategory(category, trail);
    } else {
      const parentSlug = trail[trail.length - 2]?.slug;
      if (parentSlug) {
        const { category: parentCategory, trail: parentTrail } = CategoryHelpers.findCategoryBySlug(categories, parentSlug);
        if (parentCategory && parentCategory.slug) {
          updateCategory(parentCategory, parentTrail ?? []);
        }
      } else {
        updateCategory(category, trail);
      }
    }
  }, [categories, slug]);

  // Handlers

  const handleRouteChange = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const closeModal = React.useCallback(() => {
    setDisplayCategory(activeCategory);
    setModalOpen(false);
  }, [activeCategory]);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
    analytics.expandCategoryMenu();
  }, []);

  const onSegmentClick = (clickedCategory: ICategoryTrail | null) => {
    if (!clickedCategory) {
      setDisplayCategory({
        root: true,
        slug: '',
        name: '',
        children: categories,
        trail: []
      });
    } else {
      const { category, trail } = CategoryHelpers.findCategoryBySlug(categories, clickedCategory.slug);
      setDisplayCategory({
        ...(category || {
          root: false, slug: '', name: '', children: []
        }),
        trail: trail as ICategoryTrail[]
      });
    }
  };

  // Render

  return (
    <>
      {/* Bar */}
      <button
        className={styles.root}
        type="button"
        onClick={openModal}
      >
        <Path
          root={displayCategory?.root}
          path={activeCategory?.trail}
          readonly
        />
      </button>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        //
        className={styles.modal}
        contentClassName={styles.modalContent}
        headerClassName={styles.modalHeader}
        closeButtonClassName={styles.modalCloseButton}
        //
        title={(
          <Path
            root={displayCategory?.root}
            path={displayCategory?.trail}
            onSegmentClick={onSegmentClick}
          />
        )}
        //
        breakpoint="lg"
        responsiveProps={{
          position: 'bottom'
        }}
        headerProps={{
          withBorder: true,
        }}
        //
        close={closeModal}
      >
        <ul className={styles.list}>
          {
            (displayCategory?.children?.length ?? 0) > 1 && !displayCategory?.root && (
              <li className={styles.listItem}>
                <Link
                  href={CategoryHelpers.buildHrefFromTrail(displayCategory?.trail)}
                  prefetch={CONFIG.PREFETCH_LINKS}
                  passHref
                >
                  <Button
                    tag="a"
                    className={classnames(styles.categoryLink, { [styles.active]: displayCategory?.slug === slug })}
                    contentClassName={styles.categoryLinkButtonContent}
                    onClick={() => {
                      if (displayCategory?.name) {
                        analytics.selectCategory(displayCategory.name);
                      }
                    }}
                  >
                    <span className={styles.categoryLinkName}>{t('CATEGORIES.ALL')}</span>
                    <ChevronRight />
                  </Button>
                </Link>
              </li>
            )
          }
          {
            displayCategory?.children?.map((subcategory) => {
              const { trail: subtrail } = CategoryHelpers.findCategoryBySlug(categories, subcategory.slug);
              return (
                <li className={styles.listItem} key={subcategory.slug}>
                  {
                    (subcategory?.children?.length ?? 0) > 0
                      ? (
                        <button
                          type="button"
                          className={styles.categoryLink}
                          onClick={() => {
                            onSegmentClick(subcategory);
                          }}
                        >
                          <span className={styles.categoryLinkContent}>
                            {
                              (displayCategory.root && subcategory.iconPng) && (
                                <span className={styles.categoryLinkIcon}>
                                  <Image
                                    src={subcategory.iconPng}
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              )
                            }
                            <span className={styles.categoryLinkName}>{subcategory.name}</span>
                          </span>
                          <ChevronRight />
                        </button>
                      )
                      : (
                        <Link
                          href={CategoryHelpers.buildHrefFromTrail(subtrail || [])}
                          prefetch={CONFIG.PREFETCH_LINKS}
                          passHref
                        >
                          <Button
                            tag="a"
                            className={classnames(styles.categoryLink, { [styles.active]: subcategory.slug === slug })}
                            onClick={() => {
                              if (subcategory?.name) {
                                analytics.selectCategory(subcategory.name);
                              }
                            }}
                          >
                            <span className={styles.categoryLinkName}>{subcategory.name}</span>
                          </Button>
                        </Link>
                      )
                  }
                </li>
              );
            })
          }
        </ul>
      </Modal>
    </>
  );
};

// Components

// --- Path

interface PathProps {
  root?: boolean,
  path?: ICategoryTrail[],
  readonly?: boolean,
  onSegmentClick?: (category: ICategoryTrail | null) => void
}

// TODO: Fix types for Path component
const Path = (props: PathProps) => {
  const {
    root,
    path = [],
    readonly,
    onSegmentClick,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.path}>
      <Segment
        root
        readonly={readonly}
        onClick={
          readonly
            ? null
            : onSegmentClick && (() => onSegmentClick(null))
        }
      >
        {/* Icon */}
        <Icon name="list" size={20} />

        {/* Title */}
        {root && (
          <div className={styles.title}>{t('CATEGORIES.TITLE')}</div>
        )}

      </Segment>
      {
        path?.map((category) => (
          <Segment
            onClick={readonly ? null : () => onSegmentClick(category)}
            readonly={readonly}
            key={category.slug}
            label={category.name}
          />
        ))
      }
    </div>
  );
};

// --- Segment

interface SegmentProps {
  root?: boolean,
  label?: string,
  readonly?: boolean,
  children?: React.ReactElement | React.ReactElement[],
  onClick?: (e: any) => void
}

const Segment = (props: SegmentProps) => {
  const {
    root,
    label,
    readonly,
    children,
    onClick,
  } = props;

  const Tag = readonly ? 'div' : 'button';

  return (
    <>
      {/* Chevron */}
      {!root && (
        <ChevronRight />
      )}

      {/* Label */}
      <Tag
        className={styles.segment}
        onClick={onClick}
      >
        {children || label}
      </Tag>
    </>
  );
};

// --- Chevron Right

const ChevronRight = () => <Icon name="chevron-right" className={styles.chevron} size={18} />;

// Export

export default CategoriesMobile;
