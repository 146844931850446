import React from 'react';
import { useTranslation } from 'next-i18next';

import { showInfo } from 'common/Notification';
import { useDeliveryInfoStore } from 'context/DeliveryInfoContext';
import * as AddressHelpers from 'helpers/AddressHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useUser } from 'hooks/data/useUser';
import { useIsMounted } from 'hooks/useIsMounted';
import * as AccountService from 'services/AccountService';

const getInitialDeliveryAddress = (defaultLabel: string) => {
  const storagePartialAddress = AddressHelpers.getPartialAddress();

  if (storagePartialAddress) {
    return {
      label: AddressHelpers.getAddressLabel(storagePartialAddress)
    };
  }

  return {
    label: defaultLabel,
    isPlaceholder: true
  };
};

interface Options {
  enabled?: boolean,
  isMobile?: boolean
}

export function useHeaderDeliveryAddress(options?: Options): {
  isAuthenticated?: boolean,
  isPlaceholder?: boolean,
  label?: string,
  token?: string
} {
  const { enabled = true, isMobile = false } = options || {};

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const placeholderText = isMobile
    ? t('HEADER.DESKTOP.ADDRESS_PLACEHOLDER')
    : t('HEADER.MOBILE.ADDRESS_PLACEHOLDER');

  const [address, setAddress] = React.useState(() => getInitialDeliveryAddress(placeholderText));

  const { data: user } = useUser({ isOptional: true }, { notifyOnChangeProps: ['data'] });
  const { data: order } = useActiveOrder({ notifyOnChangeProps: ['data'] });

  const { partialAddress } = useDeliveryInfoStore();

  const getDeliveryAddress = React.useCallback(async () => {
    const storagePartialAddress = AddressHelpers.getPartialAddress();

    // If authenticated, get address from order
    if (user && order && !order?.isNotCreated) {

      // If the active order has a default address set
      if (OrderHelpers.hasAddressSet(order)) {

        // Delete partial address if it exists and notify user that the address was changed
        if (storagePartialAddress) {
          AddressHelpers.deletePartialAddress();
          showInfo(t('NOTIFICATIONS.DEFAULT_ADDRESS_CHANGED'), { autoClose: 2000 });
        }

        const orderAddress = OrderHelpers.getOrderAddress(order);

        return {
          label: AddressHelpers.getAddressLabel(orderAddress),
          token: order?.shippingAddressId,
          isAuthenticated: true,
        };
      }

      /*
        If the active order does not have an address set:
        - if the user does not have any address associated with the account and there is a partial address, use it
        - otherwise, show default placeholder and delete the partial address
      */
      try {
        const userAddresses = await AccountService.getAddresses();
        if ((!userAddresses || userAddresses?.length === 0) && storagePartialAddress) {
          return {
            label: AddressHelpers.getAddressLabel(storagePartialAddress),
            token: storagePartialAddress?.token
          };
        }
      } catch {}
      if (storagePartialAddress) {
        AddressHelpers.deletePartialAddress();
      }
      return {
        label: placeholderText,
        isPlaceholder: true
      };
    }

    // If not authenticated, show partial address if available
    if (storagePartialAddress) {
      return {
        label: AddressHelpers.getAddressLabel(storagePartialAddress),
        token: storagePartialAddress?.token
      };
    }

    return {
      label: placeholderText,
      isPlaceholder: true
    };
  }, [partialAddress, user, order, placeholderText]);

  React.useEffect(() => {
    const updateLabel = async () => {
      const deliveryAddress = await getDeliveryAddress();
      if (isMounted()) {
        setAddress(deliveryAddress);
      }
    };
    if (enabled) {
      updateLabel();
    }
  }, [partialAddress, user, order, setAddress, getDeliveryAddress, isMounted]);

  return address;
}
