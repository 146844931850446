import type { ValueOf } from 'types/utility';

// Sizes

export const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export type Size = ValueOf<typeof Sizes>;

// Breakpoints

export const Breakpoints = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
} as const;

export type Breakpoint = ValueOf<typeof Breakpoints>;

// Positions

export const Positions = {
  CENTER: 'center',
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
} as const;

export type Position = ValueOf<typeof Positions>;

export const DirectionalPositions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
} as const;

export type DirectionalPosition = ValueOf<typeof DirectionalPositions>;

// Orientations

export const Orientations = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
} as const;

export type Orientation = ValueOf<typeof Orientations>;

// Alignment

export const Alignments = {
  CENTER: 'center',
  START: 'start',
  END: 'end',
} as const;

export type Alignment = ValueOf<typeof Alignments>

// --- Carousel Slots

export enum CarouselSlotTypeEnum {
  PRODUCTS = 'products_carousel',
  RECIPES = 'recipes_carousel'
}

export type CarouselSlotType = CarouselSlotTypeEnum;
