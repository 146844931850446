import { isMobile } from 'react-device-detect';

import { reportWebVitalMetric } from 'common/analytics/AnalyticsCustomEvents';
import { LocalStorage } from 'common/Storage';
import { CONFIG } from 'constants/config';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isClient } from 'helpers/BrowserHelpers';

import type { EnhancedMetric, Metric } from 'types';

const isEnabled = CONFIG.WEB_VITALS_REPORTING;

export function reportWebVital(metric: Metric) {
  if (!isClient) return;

  // Enhance Metric

  const timestamp = new Date().getTime();
  const deviceType = isMobile ? 'mobile' : 'desktop';
  const enhancedMetric: EnhancedMetric = {
    ...metric,
    timestamp,
    deviceType,
  };

  // Debug

  const isDebug = (
    window?.debugWebVitals
    || LocalStorage.get(LOCAL_STORAGE_KEYS.DEBUG_WEB_VITALS)
  );

  if (isDebug) {
    console.log(
        `%c New core web vital reported (enabled - ${isEnabled}) %c`,
        'background-color: #629796; color: white',
        'margin-left: 8px; background-color: seagreen; color: white',
    );
    console.log(`
      Metric: ${metric.name}
      Rating: ${metric.rating}
      Value: ${metric.value}
    `);

    console.log(enhancedMetric);
    console.log('-----------------------------------------------');
  }

  // Report

  if (isEnabled) {
    reportWebVitalMetric({
      metric: enhancedMetric
    });
  }
}
