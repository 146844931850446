import React from 'react';

import { useChatDispatchHelpers } from 'context/ChatContext';

import { ORDER_PROBLEM_TYPES, canReportProblem } from 'helpers/OrderHelpers';

import OrderDetailsFeedback from './OrderDetailsFeedback';
import OrderDetailsHeader from './OrderDetailsHeader';
import OrderDetailsNotices from './OrderDetailsNotices';
import OrderDetailsSummary from './OrderDetailsSummary';

import styles from './OrderDetails.module.scss';

const OrderDetails = (props) => {
  const { order, headerProps, summaryProps } = props;
  const { showChatButton, hideChatButton } = useChatDispatchHelpers();

  React.useEffect(() => {
    if (canReportProblem(order)) {
      showChatButton({ type: ORDER_PROBLEM_TYPES.OTHER, subtype: ORDER_PROBLEM_TYPES.OTHER });
    } else {
      hideChatButton();
    }
    return () => {
      hideChatButton();
    };
  }, [order]);

  return (
    <div className={styles.root}>

      {/* Feedback */}
      <OrderDetailsFeedback order={order} />

      {/* Notices */}
      <OrderDetailsNotices order={order} />

      {/* Header */}
      <OrderDetailsHeader order={order} {...headerProps} />

      {/* Summary */}
      <OrderDetailsSummary className={styles.summary} order={order} {...summaryProps} />

    </div>
  );
};

export default OrderDetails;
