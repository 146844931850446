import React from 'react';
import classnames from 'classnames';

import { ProductCartCardSkeleton } from 'components/product';

import styles from './CartContentSkeleton.module.scss';

interface Props {
  className?: string
}

const CartContentSkeleton = (props: Props) => {

  const { className } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <ProductCartCardSkeleton className={styles.skeleton} />
      <ProductCartCardSkeleton className={styles.skeleton} />
      <ProductCartCardSkeleton className={styles.skeleton} />
    </div>
  );
};

export default CartContentSkeleton;
