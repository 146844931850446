import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import { ProductLabel, ProductPrice } from '../parts';

import type { IProductVariant } from 'types';

import styles from './ProductVariantSwitcher.module.scss';

interface Props {
  className?: string,
  variantClassName?: string,
  //
  selected: IProductVariant,
  variants?: IProductVariant[],
  //
  onSelectVariant: (variant: IProductVariant) => void
}

const ProductVariantSwitcher = (props: Props) => {
  const {
    className,
    variantClassName,
    //
    selected,
    variants = [],
    //
    onSelectVariant = () => {}
  } = props;

  // Empty

  if (isArrayEmpty(variants)) return null;

  // Props

  const isVisible = variants.find(({ isAcceleratedSale, isAvailable }) => isAcceleratedSale && isAvailable);

  // Hidden

  if (!isVisible) return null;

  // Handlers

  const handleSelectVariant = (variant: IProductVariant) => {
    onSelectVariant(variant);
  };

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >
      {
        variants.map((variant) => (
          <VariantCard
            key={`variant-${variant?.variantCode}`}
            className={variantClassName}
            isSelected={selected?.variantCode === variant?.variantCode}
            variant={variant}
            onSelect={handleSelectVariant}
          />
        ))
      }
    </div>
  );
};

// Components

interface VariantCardProps {
  className?: string,
  isSelected: boolean,
  variant: IProductVariant,
  onSelect: (variant: IProductVariant) => void
}

const VariantCard = (props: VariantCardProps) => {
  const {
    className,
    isSelected,
    variant,
    onSelect = () => {}
  } = props;

  // Props

  const {
    isAvailable,
    price,
    originalPrice,
    priceColor,
    images,
    labels
  } = variant;

  const thumbnail = images?.[0]?.thumbnail;
  const label = labels?.[0];
  const IMAGE_SIZE = 96;

  // Handlers

  const handleSelect = () => {
    if (!isSelected) {
      onSelect(variant);
    }
  };

  // Render

  return (
    <button
      type="button"
      className={classnames(
        styles.variant,
        { [styles.selected]: isSelected },
        className,
      )}
      onClick={handleSelect}
    >

      {/* Thumbnail */}
      <div className={styles.thumbnail}>
        <ProductLabel
          className={styles.label}
          size="small"
          label={label}
        />
        <Image
          className={classnames(
            styles.image,
            { [styles.unavailable]: !isAvailable }
          )}
          src={thumbnail || ''}
          width={IMAGE_SIZE * 2}
          height={IMAGE_SIZE * 2}
          quality={100}
          optimize
        />
      </div>

      {/* Price */}
      <div className={styles.price}>
        <ProductPrice
          align="center"
          size="small"
          //
          fixedHeight
          //
          originalPrice={originalPrice}
          price={price}
          color={priceColor}
        />
      </div>

    </button>
  );
};

export default ProductVariantSwitcher;
