import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import styles from './AccountPromotionCard.module.scss';

interface Props {
  className?: string
}

const AccountPromotionCardSkeleton = (props: Props) => {

  const { className } = props;

  return (
    <div className={classnames(styles.root, styles.skeleton, className)}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Skeleton className={styles.iconSkeleton} />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>
            <Skeleton className={styles.titleSkeleton} />
          </div>
          <div className={styles.date}>
            <Skeleton className={styles.dateSkeleton} />
          </div>
          <div className={styles.description}>
            <Skeleton className={styles.descriptionSkeleton} />
          </div>

        </div>
      </div>
      <div className={styles.actions}>
        <Skeleton containerClassName={styles.actionsSkeleton} />
      </div>
    </div>
  );
};

export default AccountPromotionCardSkeleton;
