import React from 'react';
import classnames from 'classnames';

import { GroupedList, List } from 'components/ui';
import { groupProductsByCategory } from 'helpers/OrderHelpers';
import { useCategories } from 'hooks/data/useCategories';

import type {
  ICategory, IGenericProduct, IRecommendationSlot
} from 'types';

import styles from './GroupedProductList.module.scss';

type IGenericSlot = IRecommendationSlot;

interface Props {
  //
  className?: string,
  groupClassName?: string,
  groupHeaderClassName?: string,
  groupTitleClassName?: string,
  groupIconClassName?: string,
  groupListClassName?: string,
  groupItemClassName?: string,
  slotClassName?: string,
  //
  withBorders?: boolean,
  withoutGrouping?: boolean,
  //
  products: IGenericProduct[],
  slots?: IGenericSlot[],
  //
  renderProduct: (product: IGenericProduct) => React.ReactNode,
  renderSlot?: (slot: IGenericSlot) => React.ReactNode
}

const GroupedProductList = (props: Props) => {

  const {
    //
    className,
    groupClassName,
    groupHeaderClassName,
    groupTitleClassName,
    groupIconClassName,
    groupListClassName,
    groupItemClassName,
    slotClassName,
    //
    withBorders,
    withoutGrouping,
    //
    products = [],
    slots = [],
    //
    renderProduct,
    renderSlot,
  } = props;

  // Hooks

  const { data: categories } = useCategories();

  // Handlers

  const getItemKey = (product: IGenericProduct) => {
    return product.variantCode;
  };

  // Props

  const categoryIcons: { [categoryName: string]: string } = React.useMemo(() => {
    if (!categories?.length) return {};
    return categories.reduce((acc: { [categoryName: string]: string }, category: ICategory) => {
      return {
        ...acc,
        [category.name]: category?.iconPng || ''
      };
    }, {});
  }, [categories]);

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {
        withoutGrouping
          ? (
            <List<IGenericProduct>
              className={groupListClassName}
              itemClassName={groupItemClassName}
              //
              items={products}
              //
              withBorders={withBorders}
              //
              getItemKey={getItemKey}
              renderItem={renderProduct}
            />
          ) : (
            <GroupedList<IGenericProduct, IGenericSlot>
              groupClassName={groupClassName}
              groupHeaderClassName={groupHeaderClassName}
              groupTitleClassName={groupTitleClassName}
              groupIconClassName={groupIconClassName}
              groupListClassName={groupListClassName}
              groupItemClassName={groupItemClassName}
              slotClassName={slotClassName}
              //
              items={products}
              slots={slots}
              icons={categoryIcons}
              //
              withBorders={withBorders}
              //
              getItemKey={getItemKey}
              groupItems={groupProductsByCategory}
              renderItem={renderProduct}
              renderSlot={renderSlot}
            />
          )
      }
    </div>
  );
};

export default GroupedProductList;
