import { EventLogTypes, logEvent, pushEvent } from './AnalyticsHelpers';

import * as Einstein from './Einstein';

import type {
  IBasePromotion, IListProps, IOrder, IOrderProduct, IProduct, IUser
} from 'types';

// Constants

enum Events {
  ADD_PAYMENT = 'add_payment',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_FAVORITES = 'add_to_wishlist',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_ITEM = 'select_item',
  SELECT_PROMOTION = 'select_promotion',
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEMS_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
}

const SHIPPING_TIER = 'courier';
const IN_STOCK_VARIANT = 'in stoc';
const OUT_OF_STOCK_VARIANT = 'indisponibil';

// Helpers

function getItemData(item: IProduct | IOrderProduct, listProps: IListProps = {}, extra: any = {}) {
  if (!item) {
    logEvent(
      { method: 'getItemData', message: 'product is not set' },
      { type: EventLogTypes.WARNING }
    );
  }

  const {
    name,
    breadcrumbs = [],
    sku,
    brand,
    price,
    isAvailable,
  } = (item || {}) as IProduct;

  const {
    productName,
    discountedUnitPrice,
    isOk,
    quantity = 1
  } = (item || {}) as IOrderProduct;

  const reversedBreadcrumbs = breadcrumbs.slice(0, -1).reverse();

  return {
    item_id: sku || undefined,
    item_name: name || productName || undefined,
    item_brand: brand || undefined,
    item_variant: (isAvailable || isOk) ? IN_STOCK_VARIANT : OUT_OF_STOCK_VARIANT,
    //
    item_category: reversedBreadcrumbs?.[0]?.name,
    item_category2: reversedBreadcrumbs?.[1]?.name,
    item_category3: reversedBreadcrumbs?.[2]?.name,
    item_category4: reversedBreadcrumbs?.[3]?.name,
    //
    price: price || discountedUnitPrice || undefined,
    quantity,
    //
    index: listProps?.index,
    item_list_id: listProps.id || listProps.analyticsListId || undefined,
    item_list_name: listProps.name || listProps.analyticsListName || undefined,
    //
    ...extra
  };
}

function pushEcommerceEvent(event: Events, ecommerce: Record<string, any> = {}) {
  pushEvent({
    event,
    ecommerce,
  });
}

// Events

// --- Generic Ecommerce Event

export function ecommerceEvent(event: string, ecommerce: Record<string, any> = {}) {
  pushEcommerceEvent(event as Events, ecommerce);
}

// --- Add Payment

export function addPayment(order: IOrder, paymentType: string) {
  return pushEcommerceEvent(Events.ADD_PAYMENT, {
    value: order?.total,
    currency: order?.currencyCode,
    payment_type: paymentType,
    items: order?.items?.map((item) => getItemData(item))
  });
}

// --- Add Shipping Info

export function addShippingInfo(order: IOrder) {
  return pushEcommerceEvent(Events.ADD_SHIPPING_INFO, {
    value: order?.total,
    currency: order?.currencyCode,
    shipping_tier: SHIPPING_TIER,
    items: order?.items?.map((item) => getItemData(item))
  });
}

// --- Add To Cart

export function addToCart(item: IProduct | IOrderProduct, listProps: IListProps, quantity: number) {
  return pushEcommerceEvent(Events.ADD_TO_CART, {
    items: [getItemData(item, listProps, { quantity })]
  });
}

// --- Add To Favorites

export function addToFavorites(item: IProduct | IOrderProduct, listProps: IListProps, quantity: number) {
  return pushEcommerceEvent(Events.ADD_TO_FAVORITES, {
    items: [getItemData(item, listProps, { quantity })]
  });
}

// --- Begin Checkout

export function beginCheckout(order: IOrder) {
  return pushEcommerceEvent(Events.BEGIN_CHECKOUT, {
    items: order?.items?.map((item) => getItemData(item))
  });
}

// --- Bulk Add To Cart

export function bulkAddToCart(items: ({ product: IProduct | IOrderProduct, quantity: number })[], listProps: IListProps) {
  return pushEcommerceEvent(Events.ADD_TO_CART, {
    items: items.map(({ product, quantity }, index) => {
      return getItemData(product, { ...listProps, index }, { quantity });
    })
  });
}

// --- Clear Cart

export function clearCart(user: IUser) {
  Einstein.trackClearCart(user);
}

// --- Purchase

export function purchase(user: IUser, order: IOrder) {
  Einstein.trackPurchase(user, order, SHIPPING_TIER);
  return pushEcommerceEvent(Events.PURCHASE, {
    transaction_id: `${order?.id}_${order?.mergeVersion}`,
    currency: order?.currencyCode,
    value: order?.total,
    coupon: order?.couponInfo?.code,
    shipping: order?.shippingTotal,
    tax: order?.taxTotal,
    items: order?.items?.map((item) => getItemData(item, {}, { discount: undefined }))
  });
}

// --- Remove From Cart

export function removeFromCart(item: IProduct | IOrderProduct, listProps: IListProps, quantity: number) {
  return pushEcommerceEvent(Events.REMOVE_FROM_CART, {
    items: [getItemData(item, listProps, { quantity })]
  });
}

// --- Select Item

export function selectItem(item: IProduct, listProps: IListProps) {
  return pushEcommerceEvent(Events.SELECT_ITEM, {
    items: [getItemData(item, listProps)]
  });
}

// --- Select Promotion

export function selectPromotion(promotion: IBasePromotion, index: number) {
  return pushEcommerceEvent(Events.SELECT_PROMOTION, {
    promotion_id: promotion.code,
    promotion_name: promotion.name,
    index,
    items: []
  });
}

// --- View Cart

export function viewCart(user: IUser, order: IOrder) {
  Einstein.trackCart(user, order);
  return pushEcommerceEvent(Events.VIEW_CART, {
    currency: order?.currencyCode,
    value: order?.total,
    items: order?.items?.map((item) => getItemData(item))
  });
}

// --- View Item

export function viewItem(item: IProduct, listProps: IListProps) {
  return pushEcommerceEvent(Events.VIEW_ITEM, {
    items: [getItemData(item, listProps)]
  });
}

// --- View Item List

export function viewItemList(items: IProduct[], listProps: IListProps, promotion?: IBasePromotion) {
  return pushEcommerceEvent(Events.VIEW_ITEMS_LIST, {
    item_list_id: listProps.id || undefined,
    item_list_name: listProps.name || undefined,
    items: items?.map((item, index) => getItemData(
      item,
      { index, ...listProps },
      { promotion_id: promotion?.code, promotion_name: promotion?.name }
    ))
  });
}

// --- View Promotion

export function viewPromotion(promotion: IBasePromotion, index: number) {
  return pushEcommerceEvent(Events.VIEW_PROMOTION, {
    promotion_id: promotion.code,
    promotion_name: promotion.name,
    index,
    items: []
  });
}
