import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import MenuItemLink, { isLinkActive } from './MenuItemLink';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItemWithAccordion from './MenuItemWithAccordion';
import MenuItemWithList from './MenuItemWithList';
import MenuItemWithPopover from './MenuItemWithPopover';

import type { Accordion } from 'components/ui';

import type { IMenuItem, IMenuItemLink } from 'types';

import styles from './Menu.module.scss';

interface Props {
  activeChildRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  //
  className?: string,
  listClassName?: string,
  itemClassName?: string,
  linkWrapperClassName?: string,
  linkClassName?: string,
  buttonClassName?: string,
  linkActiveClassName?: string,
  linkSelectedClassName?: string,
  linkIconWrapperClassName?: string,
  linkIconClassName?: string,
  linkLabelClassName?: string,
  subLinkClassName?: string,
  subLinkActiveClassName?: string,
  separatorClassName?: string,
  //
  title?: string,
  items: IMenuItem[],
  //
  horizontal?: boolean,
  withBorders?: boolean,
  //
  selectedId?: string,
  dataTestId?: string,
  //
  withIndent?: boolean,
  inline?: boolean,
  //
  withoutSublinks?: boolean,
  withAccordion?: boolean,
  accordionProps?: Partial<React.ComponentProps<typeof Accordion>>,
  //
  onItemClick?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onItemMouseEnter?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onItemMouseLeave?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const Menu = React.forwardRef<HTMLDivElement, Props>((
  props: Props,
  ref: React.Ref<HTMLDivElement>
) => {

  const {
    //
    activeChildRef,
    //
    className,
    listClassName,
    itemClassName,
    linkWrapperClassName,
    linkClassName,
    buttonClassName,
    linkActiveClassName,
    linkSelectedClassName,
    linkIconWrapperClassName,
    linkIconClassName,
    linkLabelClassName,
    subLinkClassName,
    subLinkActiveClassName,
    separatorClassName,
    //
    selectedId,
    //
    title,
    items,
    //
    horizontal,
    inline,
    withBorders,
    withIndent,
    //
    withoutSublinks,
    withAccordion,
    accordionProps = {},
    //
    dataTestId,
    //
    onItemClick = () => {},
    onItemMouseEnter = () => {},
    onItemMouseLeave = () => {}
  } = props;

  const menuItemProps = {
    //
    activeChildRef,
    //
    linkWrapperClassName,
    linkClassName,
    buttonClassName,
    linkActiveClassName,
    linkSelectedClassName,
    linkIconWrapperClassName,
    linkIconClassName,
    linkLabelClassName,
    subLinkClassName,
    subLinkActiveClassName,
    //
    selectedId,
    //
    horizontal,
    //
    withIndent,
    //
    withoutSublinks,
    withAccordion,
    accordionProps,
    //
    onItemClick,
    onItemMouseEnter,
    onItemMouseLeave
  };

  // Hooks

  const router = useRouter();
  const { t } = useTranslation();

  const { asPath, query } = router;
  const { originalPathname = '' } = query || {};

  // Render

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        className
      )}
    >

      {/* Title */}
      {
        title && (
          <div className={styles.title}>
            {t(title)}
          </div>
        )
      }

      {/* Items */}
      <ul
        className={classnames(
          styles.list,
          { [styles.horizontal]: horizontal },
          listClassName
        )}
        data-testid={dataTestId}
      >
        {
          items?.map((item, index) => {

            // Separator

            if (item === 'separator') {
              return (
                <MenuItemSeparator
                  key={`separator-${index}`}
                  className={separatorClassName}
                  horizontal={horizontal}
                />
              );
            }

            // Hidden

            if (item.hidden) {
              return null;
            }

            // Item

            const Item = getMenuItem({
              item,
              menuItemProps,
              routeProps: {
                asPath,
                originalPathname
              }
            });

            // Inline

            if (inline) {
              return Item;
            }

            // Render

            return (
              <li
                key={item.slug || index}
                className={classnames(
                  styles.item,
                  { [styles.withBorder]: withBorders },
                  itemClassName
                )}
              >
                {Item}
              </li>
            );
          })
        }
      </ul>

    </div>
  );
});

// Helpers

interface MenuItemProps
  extends Pick<
  Props,
  'activeChildRef'
  | 'itemClassName'
  | 'linkWrapperClassName'
  | 'linkClassName'
  | 'buttonClassName'
  | 'linkActiveClassName'
  | 'linkSelectedClassName'
  | 'linkIconWrapperClassName'
  | 'linkIconClassName'
  | 'linkLabelClassName'
  | 'subLinkClassName'
  | 'subLinkActiveClassName'
  //
  | 'selectedId'
  //
  | 'horizontal'
  | 'withIndent'
  | 'inline'
  //
  | 'withoutSublinks'
  | 'withAccordion'
  | 'accordionProps'
  //
  | 'onItemClick'
  | 'onItemMouseEnter'
  | 'onItemMouseLeave'
  > {}

interface RouteProps {
  asPath: string,
  originalPathname?: string | string[]
}

const getMenuItem = (params: {
  item: IMenuItemLink,
  menuItemProps: MenuItemProps,
  routeProps: RouteProps
}) => {
  const {
    item,
    menuItemProps,
    routeProps,
  } = params;

  const {
    //
    activeChildRef,
    //
    itemClassName,
    linkWrapperClassName,
    linkClassName,
    buttonClassName,
    linkActiveClassName,
    linkSelectedClassName,
    linkIconWrapperClassName,
    linkIconClassName,
    linkLabelClassName,
    subLinkClassName,
    subLinkActiveClassName,
    //
    selectedId,
    //
    horizontal,
    withIndent,
    inline,
    //
    withoutSublinks,
    withAccordion,
    accordionProps,
    //
    onItemClick,
    onItemMouseEnter,
    onItemMouseLeave
  } = menuItemProps;

  const {
    id,
    href,
    matchHref,
    exact,
    children,
  } = item;

  // Classes

  const linkClasses = classnames(
    { [buttonClassName]: !href },
    { [linkSelectedClassName]: id === selectedId },
    linkClassName,
  );

  // Menu Item Link

  if (isArrayEmpty(children) || withoutSublinks) {

    const {
      asPath,
      originalPathname,
    } = routeProps;

    // Active

    const isActive = isLinkActive({
      asPath,
      originalPathname,
      href,
      matchHref,
      exact,
    });

    // Menu Link

    return (
      <div className={itemClassName}>
        <MenuItemLink
          ref={
            isActive
              ? activeChildRef
              : null
          }
          //
          className={linkClasses}
          activeClassName={linkActiveClassName}
          iconWrapperClassName={linkIconWrapperClassName}
          iconClassName={linkIconClassName}
          labelClassName={linkLabelClassName}
          //
          item={item}
          //
          onClick={onItemClick}
          onMouseEnter={onItemMouseEnter}
          onMouseLeave={onItemMouseLeave}
        />
      </div>
    );
  }

  // Horizontal - Menu Item With Popover

  if (horizontal) {
    return (
      <MenuItemWithPopover
        //
        activeChildRef={activeChildRef}
        //
        className={linkWrapperClassName}
        linkClassName={linkClasses}
        linkActiveClassName={linkActiveClassName}
        subLinkClassName={subLinkClassName}
        subLinkActiveClassName={subLinkActiveClassName}
        iconClassName={linkIconClassName}
        labelClassName={linkLabelClassName}
        //
        item={item}
        //
        onClick={onItemClick}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
      />
    );
  }

  // Vertical - Menu Item With Accordion

  if (withAccordion) {
    return (
      <MenuItemWithAccordion
        //
        activeChildRef={activeChildRef}
        //
        className={linkWrapperClassName}
        linkClassName={linkClasses}
        linkActiveClassName={linkActiveClassName}
        subLinkClassName={subLinkClassName}
        subLinkActiveClassName={subLinkActiveClassName}
        iconClassName={linkIconClassName}
        labelClassName={linkLabelClassName}
        //
        withIndent={withIndent}
        //
        item={item}
        //
        accordionProps={accordionProps}
        //
        onClick={onItemClick}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
      />
    );
  }

  // Vertical - Menu Item With List

  return (
    <MenuItemWithList
      //
      activeChildRef={activeChildRef}
      //
      className={linkWrapperClassName}
      linkClassName={linkClasses}
      linkActiveClassName={linkActiveClassName}
      subLinkClassName={subLinkClassName}
      subLinkActiveClassName={subLinkActiveClassName}
      iconClassName={linkIconClassName}
      labelClassName={linkLabelClassName}
      //
      inline={inline}
      withIndent={withIndent}
      //
      item={item}
      //
      onClick={onItemClick}
      onMouseEnter={onItemMouseEnter}
      onMouseLeave={onItemMouseLeave}
    />
  );

};

// Export

export default React.memo(Menu);
