import React from 'react';

import { CONFIG } from 'constants/config';
import { isClient } from 'helpers/BrowserHelpers';

const AppVersion = () => {
  const isInitialized = React.useRef(true);

  if (isInitialized.current && isClient()) {
    console.log(`%cWelcome to Freshful! (v${CONFIG.APP_VERSION})`, 'padding: 4px 16px; background: #008000; color: white;');
  }

  isInitialized.current = false;

  return (
    <div style={{ display: 'none' }}>{CONFIG.APP_VERSION}</div>
  );

};

export default AppVersion;
