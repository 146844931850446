import React from 'react';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';

import Button from '../Button/Button';
import Image from '../Image/Image';

import type { Size } from 'types';

import styles from './GeniusButton.module.scss';

interface Props {
  className?: string,
  iconClassName?: string,
  //
  size?: Size,
  loading?: boolean,
  disabled?: boolean,
  iconSize?: number,
  //
  onClick?: () => void,
  //
  children?: React.ReactNode
}

const GeniusButton = (props: Props) => {

  const {
    className,
    iconClassName,
    //
    size,
    loading,
    disabled,
    iconSize = 20,
    //
    onClick,
    //
    children,
  } = props;

  return (
    <Button
      className={classnames(styles.btn, { [styles.loading]: loading }, className)}
      loaderColor={styles.whiteColor}
      startIcon={(
        <Image
          className={classnames(iconClassName)}
          src={IMAGES.BRANDING.GENIUS_ICON_WHITE}
          width={iconSize}
          height={iconSize}
          quality={100}
          optimize
          alt="Genius icon"
        />
      )}
      size={size}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default GeniusButton;
