import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Icon } from 'components/ui';

import type { ValueOf } from 'types';

import styles from './RecipeStat.module.scss';

export const VARIANTS = {
  DEFAULT: 'default',
  MINIMAL: 'minimal',
} as const;

export type Variant = ValueOf<typeof VARIANTS>;

interface Props {
  className?: string,
  iconClassName?: string,
  labelClassName?: string,
  originalValueClassName?: string,
  valueClassName?: string,
  icon?: string,
  label?: string,
  originalValue?: string,
  originalValueLabel?: string,
  value: string,
  valueLabel: string,
  variant?: Variant
}

const RecipeStat = (props: Props) => {

  const {
    className,
    iconClassName,
    originalValueClassName,
    labelClassName,
    valueClassName,
    //
    icon,
    label,
    originalValue,
    originalValueLabel,
    value,
    valueLabel,
    //
    variant = VARIANTS.DEFAULT,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const isMinimal = (variant === VARIANTS.MINIMAL);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[variant]]: !!variant },
        className
      )}
    >

      {/* Icon */}
      {
        !icon
          ? null
          : (
            <div className={classnames(styles.icon, iconClassName)}>
              <Icon name={icon} />
            </div>
          )
      }

      {/* Label */}
      {
        (!label || isMinimal)
          ? null
          : (
            <div className={classnames(styles.label, labelClassName)}>
              {t(label)}
            </div>
          )
      }

      {/* Value Wrapper */}
      <div className={styles.valueWrapper}>

        {/* Original Value */}
        {
          originalValue && (
            <div
              className={classnames(
                styles.value,
                styles.original,
                originalValueClassName
              )}
            >
              {`${originalValue} ${t(originalValueLabel)}`}
            </div>
          )
        }

        {/* Value */}
        <div
          className={classnames(
            styles.value,
            { [styles.discounted]: !!originalValue },
            valueClassName
          )}
        >
          {`${value} ${t(valueLabel)}`}
        </div>

      </div>

    </div>
  );
};

export default RecipeStat;
