/* eslint-disable max-len */

import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';

import type { CheckoutStepIdType, ICheckoutPage, IOrder } from 'types';

// State

interface CheckoutStoreState {
  isBillingVisible: boolean,
  isLoading: boolean,
  isDisabled: boolean,
  //
  order: IOrder | null,
  originalOrder: IOrder | null,
  //
  cmsContent: ICheckoutPage | null,
  //
  notes: string,
  //
  isValidationVisible: boolean,
  firstInvalidStep: CheckoutStepIdType | undefined | null
}

const initialState: CheckoutStoreState = {
  isBillingVisible: false,
  isLoading: true,
  isDisabled: false,
  //
  order: null,
  originalOrder: null,
  //
  cmsContent: null,
  //
  notes: '',
  //
  isValidationVisible: false,
  firstInvalidStep: null,
};

// Reducers

const reducers = {

  updateCheckoutStatus: (draft: CheckoutStoreState, action: IStoreAction<{ order: IOrder}>) => {
    const { payload } = action;
    const { order } = payload || {};

    if (!order) {
      return {
        ...draft,
        isLoading: true
      };
    }

    return {
      ...draft,
      order,
      isLoading: false
    };
  },

  setOriginalOrder: (draft: CheckoutStoreState, action: IStoreAction<{ originalOrder: IOrder }>) => {
    const { payload } = action;
    const { originalOrder } = payload || {};
    return {
      ...draft,
      originalOrder,
    };
  },

  setCmsContent: (draft: CheckoutStoreState, action: IStoreAction<{ data: ICheckoutPage }>) => {
    const { payload } = action;
    const { data } = payload || {};
    return {
      ...draft,
      cmsContent: data,
    };
  },

  setBillingVisible: (draft: CheckoutStoreState, action: IStoreAction<{ isBillingVisible: boolean}>) => {
    const { payload } = action;
    const { isBillingVisible } = payload || {};
    return {
      ...draft,
      isBillingVisible
    };
  },

  setNotes: (draft: CheckoutStoreState, action: IStoreAction<{ notes: string}>) => {
    const { payload } = action;
    const { notes } = payload || {};
    return {
      ...draft,
      notes
    };
  },

  setLoading: (draft: CheckoutStoreState, action: IStoreAction<{ isLoading: boolean}>) => {
    const { payload } = action;
    const { isLoading } = payload || {};
    return {
      ...draft,
      isLoading
    };
  },

  setDisabled: (draft: CheckoutStoreState, action: IStoreAction<{ isDisabled: boolean}>) => {
    const { payload } = action;
    const { isDisabled } = payload || {};
    return {
      ...draft,
      isDisabled
    };
  },

  setValidation: (draft: CheckoutStoreState, action: IStoreAction<{ isValidationVisible: boolean, firstInvalidStep?: CheckoutStepIdType | undefined | null }>) => {
    const { payload } = action;
    const {
      isValidationVisible,
      firstInvalidStep,
    } = payload || {};
    return {
      ...draft,
      isValidationVisible,
      firstInvalidStep,
    };
  },

  reset: (draft: CheckoutStoreState, action: IStoreAction<null>) => {
    return {
      ...draft,
      ...initialState
    };
  }

};

type Actions = IStoreActions<typeof reducers>;

// Create

const [
  CheckoutProvider,
  useCheckoutStore,
  useDispatch,
  actions
] = createV2<CheckoutStoreState, Actions>(reducers, initialState);

// Dispatch

function useCheckoutDispatch() {
  const dispatch = useDispatch();

  const updateCheckoutStatus = (order: IOrder) => dispatch(actions.updateCheckoutStatus({ order }));
  const setOriginalOrder = (originalOrder: IOrder) => dispatch(actions.setOriginalOrder({ originalOrder }));
  const setCmsContent = (data: ICheckoutPage) => dispatch(actions.setCmsContent({ data }));
  const setBillingVisible = (isBillingVisible: boolean) => dispatch(actions.setBillingVisible({ isBillingVisible }));
  const setNotes = (notes: string) => dispatch(actions.setNotes({ notes }));
  const setLoading = (isLoading: boolean) => dispatch(actions.setLoading({ isLoading }));
  const setDisabled = (isDisabled: boolean) => dispatch(actions.setDisabled({ isDisabled }));
  const setValidation = (isValidationVisible: boolean, firstInvalidStep?: CheckoutStepIdType) => dispatch(actions.setValidation({ isValidationVisible, firstInvalidStep }));
  const reset = () => dispatch(actions.reset(null));

  return {
    updateCheckoutStatus,
    setOriginalOrder,
    setCmsContent,
    setBillingVisible,
    setNotes,
    setLoading,
    setDisabled,
    setValidation,
    reset,
  };
}

// Export

export {
  CheckoutProvider,
  useCheckoutStore,
  useCheckoutDispatch,
};
