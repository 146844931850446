export const FEATURES = {
  ADD_TO_ORDER_SHOW_EMPTY_ELIGIBLE_ORDER_LIST: 'ADD_2_ORDER_SHOW_EMPTY_ELIGIBLE_ORDER_LIST',
  ADD_TO_ORDER: 'ADD_2_ORDER',
  DISABLE_MANUALLY_RETRIEVE_USUALS: 'DISABLE_MANUALLY_RETRIEVE_USUALS',
  FRESHLIST_SEARCH: 'FRESHLIST_SEARCH',
  GENIUS_TRIAL: 'GENIUS_TRIAL',
  GROUP_PRODUCTS: 'GROUP_PRODUCTS_WEB',
  LOYALTY_ENABLED: 'LOYALTY_ENABLED',
  LOYALTY_SHOW_NEW_LABEL: 'LOYALTY_SHOW_NEW_LABEL',
  HP_FRESHLIST_WIDGET: 'HP_FRESHLIST_WIDGET',
  ORDER_HISTORY_SEARCH: 'ORDER_HISTORY_SEARCH',
  RECIPES_SEARCH: 'RECIPES_SEARCH',
  SHOW_CUSTOMER_BENEFITS: 'SHOW_CUSTOMER_BENEFITS',
  SHOW_LEGACY_RECIPES: 'RECIPES_V1',
};
