export const CONSENT_COOKIE_TYPES = {
  FUNCTIONAL: 'functional',
  ANALYTICS: 'analytics',
  ADVERTISING: 'advertising'
} as const;

export type ConsentCookieType = typeof CONSENT_COOKIE_TYPES[keyof typeof CONSENT_COOKIE_TYPES];

// Responses

// --- Consent Cookie Definitions
export interface IConsentCookieDefinitionsResponse {
  cookies: IConsentCookieDefinitionResponse[]
}

export interface IConsentCookieDefinitionResponse {
  id: number,
  type: ConsentCookieType,
  slug: string,
  label: string,
  name: string,
  scope?: string,
  description?: string
}

// --- Consent

export interface IConsentResponse {
  cookies: IConsentCookieResponse[],
  newCookies: string[] // new cookies slugs
}

export interface IConsentCookieResponse {
  slug: string,
  isAccepted: boolean
}

// Models

// --- Consent Cookie Definitions
export interface IConsentCookieDefinition {
  id: number,
  type: ConsentCookieType,
  slug: string,
  label: string,
  name: string,
  scope?: string,
  description?: string
}

// --- Consent

export interface IConsent {
  touched?: boolean,
  timestamp?: number,
  cookies: IConsentCookie[],
  hasGivenConsent: boolean,
  shouldRequestConsent: boolean
}

export interface IConsentCookie extends IConsentCookieDefinition {
  isAccepted: boolean,
  isNew?: boolean,
  hasSetConsent?: boolean
}

// --- Status

export interface IConsentStatus {
  version: string
}

// Requests

export interface IUpdateConsentPayload {
  timestamp?: number,
  cookies: Pick<IConsentCookie, 'slug' | 'isAccepted'>[]
}
