import { create } from './StoreCreator';

const initialState = {
  modalComponent: null,
  isModalVisible: false,
  modalComponentProps: {},
  modalTitle: '',
  partialAddress: {},
  props: {}
};

const reducer = (draft, action) => {
  const { payload = {} } = action;

  switch (action.type) {

    case ACTIONS.SHOW_ADDRESS_MODAL:
      return {
        ...draft,
        modalComponent: payload.component,
        modalComponentProps: payload.props,
        isModalVisible: true,
        modalTitle: payload.modalTitle,
        props: payload.props
      };

    case ACTIONS.CLOSE_ADDRESS_MODAL:
      return {
        ...draft,
        isModalVisible: false,
      };

    case ACTIONS.SAVE_PARTIAL_ADDRESS:
      return {
        ...draft,
        partialAddress: payload.address
      };

    default:
      return draft;
  }
};

const [
  DeliveryInfoProvider,
  useDeliveryInfoStore,
  useDeliveryInfoDispatch,
] = create(reducer, initialState);

const ACTIONS = {
  SHOW_ADDRESS_MODAL: 'SHOW_ADDRESS_MODAL',
  CLOSE_ADDRESS_MODAL: 'CLOSE_ADDRESS_MODAL',
  SAVE_PARTIAL_ADDRESS: 'SAVE_PARTIAL_ADDRESS'
};

function useDeliveryInfoDispatchHelpers() {
  const dispatch = useDeliveryInfoDispatch();

  const showDeliveryInfoModal = (component, props = {}, modalTitle) => dispatch({
    type: ACTIONS.SHOW_ADDRESS_MODAL,
    payload: {
      component,
      props,
      modalTitle
    }
  });

  const closeDeliveryInfoModal = () => dispatch({ type: ACTIONS.CLOSE_ADDRESS_MODAL });
  const setPartialAddress = (address) => dispatch({ type: ACTIONS.SAVE_PARTIAL_ADDRESS, payload: { address } });

  return { showDeliveryInfoModal, closeDeliveryInfoModal, setPartialAddress };
}

export {
  DeliveryInfoProvider,
  useDeliveryInfoStore,
  useDeliveryInfoDispatch,
  useDeliveryInfoDispatchHelpers,
  ACTIONS as ACCOUNT_ACTIONS,
};
