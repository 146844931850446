/* eslint-disable react/self-closing-comp */
import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Loader } from 'components/ui';
import { getEOSUserIdFromJwt } from 'helpers/UserHelpers';
import { useUser } from 'hooks/data/useUser';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { verifyAccessToken } from 'services/AuthenticationService';

import type { IUser, ValueOf } from 'types';

import styles from './EmagGenius.module.scss';

interface Props {
  id?: string,
  routename?: EmagGeniusRouteName,
  //
  fastCheckoutDiscount?: number,
  fastCheckoutSlots?: IFastCheckoutSlots,
  //
  onFastCheckoutCancel?: () => void,
  onFastCheckoutPending?: () => void,
  onFastCheckoutSuccess?: () => void,
  onFastCheckoutError?: () => void
}

interface IFastCheckoutSlots {
  slot2?: React.ReactNode,
  slot3?: React.ReactNode,
  slot2ClassName?: string,
  slot3ClassName?: string
}

// Emag Genius Wrapper

const EmagGeniusWrapper = (props: Props) => {

  // Hooks

  const { isSettled: isAuthSettled } = useIsAuthenticated();

  const { data: user } = useUser({ isOptional: true });

  const isUserSettled = user !== undefined;

  // Loading

  if (!isAuthSettled || !isUserSettled) {
    return (
      <div className={styles.loader}>
        <Loader
          size={42}
          lineWeight={3}
          color={styles.colorGenius}
        />
      </div>
    );
  }

  // Render

  return (
    <EmagGenius
      user={user}
      {...props}
    />
  );
};

// Emag Genius

const EmagGenius = (props: Props & {user?: IUser}) => {
  const {
    id,
    user,
    routename,
    fastCheckoutDiscount,
    fastCheckoutSlots,
    //
    onFastCheckoutCancel,
    onFastCheckoutPending,
    onFastCheckoutSuccess,
    onFastCheckoutError,
  } = props;

  const { idToken: token, isGeniusUser } = user || {};
  const userId = getEOSUserIdFromJwt(token);

  const router = useRouter();

  // Effects

  React.useEffect(() => {
    const geniusEl = getGeniusElement(id);
    if (geniusEl) {
      geniusEl.refreshtoken = getRefreshTokenParams;
    }
  }, [id]);

  React.useEffect(() => {
    const geniusEl = getGeniusElement(id);
    if (geniusEl) {
      geniusEl.payload = {
        fastCheckoutDiscount
      };
    }
  }, [id, fastCheckoutDiscount]);

  // Event Handler Hooks

  useEmagGeniusEventHandler(id, EMAG_GENIUS_EVENTS.CANCEL, onFastCheckoutCancel);
  useEmagGeniusEventHandler(id, EMAG_GENIUS_EVENTS.PENDING, onFastCheckoutPending);
  useEmagGeniusEventHandler(id, EMAG_GENIUS_EVENTS.SUCCESS, onFastCheckoutSuccess);
  useEmagGeniusEventHandler(id, EMAG_GENIUS_EVENTS.ERROR, onFastCheckoutError);

  // Flags

  const isFastCheckoutRoute = (routename === EMAG_GENIUS_ROUTE_NAMES.FAST_CHECKOUT);
  const hasFastCheckoutSlots = isFastCheckoutRoute && fastCheckoutSlots;

  // Render

  return (
    <emag-genius
      id={id}
      userid={userId}
      token={token}
      routename={routename || (isGeniusUser ? EMAG_GENIUS_ROUTE_NAMES.DASHBOARD : '')}
      style={
        isFastCheckoutRoute
          ? { display: 'none' }
          : undefined
      }
    >
      {
        hasFastCheckoutSlots
          && (
            <GeniusFastCheckoutSlots
              slots={fastCheckoutSlots}
            />
          )
      }
    </emag-genius>
  );
};

// Genius Fast Checkout Slots

interface GeniusFastCheckoutSlotsProps {
  slots: IFastCheckoutSlots
}

const GeniusFastCheckoutSlots = (props: GeniusFastCheckoutSlotsProps) => {
  const { slots } = props;
  const {
    slot2, slot3, slot2ClassName, slot3ClassName
  } = slots;
  return (
    <>
      {/* Accept Btn */}
      {slot2 && (
        <span
          slot="fast-checkout-2"
          className={classnames(styles.fastCheckoutSlot, styles.primary, slot2ClassName)}
        >
          {slot2}
        </span>
      )}

      {/* Cancel Btn */}
      {slot3 && (
        <span
          slot="fast-checkout-3"
          className={classnames(styles.fastCheckoutSlot, slot3ClassName)}
        >
          {slot3}
        </span>
      )}
    </>
  );
};

// Constants

// --- Routes

export const EMAG_GENIUS_ROUTE_NAMES = {
  HOME: 'home',
  DASHBOARD: 'dashboard',
  FAST_CHECKOUT: 'fastCheckout'
} as const;

type EmagGeniusRouteName = ValueOf<typeof EMAG_GENIUS_ROUTE_NAMES>

// --- Events

const EMAG_GENIUS_EVENTS = {
  CANCEL: 'fast-checkout-cancel',
  PENDING: 'fast-checkout-pending',
  SUCCESS: 'fast-checkout-success',
  ERROR: 'fast-checkout-error',
} as const;

type EmagGeniusEventType = ValueOf<typeof EMAG_GENIUS_EVENTS>;

// Hooks

const useEmagGeniusEventHandler = (id: Props['id'], type: EmagGeniusEventType, callback: () => void) => {
  const handleEvent = React.useCallback(() => {
    if (callback) {
      callback();
    }
  }, [callback]);

  React.useEffect(() => {
    const geniusEl = getGeniusElement(id);
    if (geniusEl && callback) {
      geniusEl.addEventListener(type, handleEvent);
      return () => {
        geniusEl.removeEventListener(type, handleEvent);
      };
    }
  }, [id, handleEvent, callback]);

};

// Helpers

// TODO change type after emag genius provides types declaration
const getGeniusElement = (id: Props['id']): any => {
  return document.querySelector(`emag-genius#${id}`) as HTMLElement;
};

interface RefreshTokenParams {
  token: string,
  userId: string
}

const getRefreshTokenParams = (): Promise<RefreshTokenParams> => {
  return new Promise((resolve, reject) => {
    verifyAccessToken().then((response) => {
      const { data } = response;
      const { idToken: token } = data;
      const userId = getEOSUserIdFromJwt(token);
      resolve({ token, userId });
    }).catch((err) => {
      console.log('Error while getting refresh token params', err);
      reject(err);
    });
  });
};

// Export

export default EmagGeniusWrapper;
