import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import type { IRecipeLabel } from 'types';

import styles from './RecipeLabel.module.scss';

interface Props {
  className?: string,
  label: IRecipeLabel,
  rounded?: boolean
}

const RecipeLabel = (props: Props) => {

  const {
    className,
    label,
    rounded,
  } = props;

  const {
    text,
    textColor,
    backgroundColor
  } = label || {};

  if (!text) return null;

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.rounded]: rounded },
        className
      )}
      style={{
        color: textColor,
        backgroundColor,
      } as CSSProperties}
    >
      <span>
        {text}
      </span>
    </div>
  );
};

export default RecipeLabel;
