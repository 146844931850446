export const CHAT_TYPES = {
  CHECKOUT: 'checkout',
  REPORT_PROBLEM: 'reportProblem'
} as const;

export const CHAT_ORIGIN = {
  CHECKOUT: 'Web Chat Checkout',
  REPORT_PROBLEM: 'Web Chat Report a Problem',
} as const;

export const CHAT_LABELS = {
  NAME: 'Last Name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ORIGIN: 'Origin',
  TYPE: 'Type',
  REASON: 'Coding Reason 2'
} as const;

const CHAT_FIELD_NAMES = {
  NAME: 'LastName',
  EMAIL: 'Email',
  PHONE: 'Phone',
  TYPE: 'Type',
  REASON: 'Coding_Reason_2__c',
  ORIGIN: 'Origin'
} as const;

const CHAT_ENTITY_NAMES = {
  CASE: 'Case',
  CONTACT: 'Contact'
} as const;

const CHAT_ENTITY_FIELDS = {
  CONTACT_ID: 'ContactId'
} as const;

const PROBLEM_TYPE_MAP: Record<string, string> = {
  incomplete: 'Order',
  delivery: 'Delivery',
  return: 'Return',
  other: 'Other'
};

const PROBLEM_REASON_MAP: Record<string, string> = {
  incomplete: 'Incomplete/wrong order',
  delivery_late_delivery: 'Delayed order',
  delivery_not_delivered: 'Undelivered',
  return: 'Return product',
  other: 'Other'
};

interface Problem {
  type: string,
  secondType?: string
}

interface ContactDetails {
  name?: string,
  email?: string,
  phone?: string
}

function getProblemTypeValue(problem: Problem): string {
  const { type } = problem;
  return PROBLEM_TYPE_MAP[type] || PROBLEM_TYPE_MAP.other;
}

function getProblemReasonValue(problem: Problem): string {
  const { type, secondType } = problem;
  const reason = secondType ? PROBLEM_REASON_MAP[`${type}_${secondType}`] : PROBLEM_REASON_MAP[type];
  return reason || PROBLEM_REASON_MAP.other;
}

interface FieldMap {
  fieldName: string,
  label: string,
  doCreate?: boolean,
  doFind?: boolean,
  isExactMatch?: boolean
}

function fieldMapCreator(
  fieldName: string,
  label: string,
  doCreate: boolean = true,
  doFind: boolean = false,
  isExactMatch: boolean = false
): FieldMap {
  return {
    fieldName,
    label,
    doCreate,
    doFind,
    isExactMatch
  };
}

interface PrechatFormDetail {
  label: string,
  value: string,
  displayToAgent: boolean
}

export function getPrechatFormDetails(contactDetails: ContactDetails, problem?: Problem): PrechatFormDetail[] {
  const { name, email, phone } = contactDetails;
  const prechatFormDetails: PrechatFormDetail[] = [
    { label: CHAT_LABELS.NAME, value: `${name}`, displayToAgent: true },
    { label: CHAT_LABELS.ORIGIN, value: problem ? CHAT_ORIGIN.REPORT_PROBLEM : CHAT_ORIGIN.CHECKOUT, displayToAgent: true },
    ...(problem ? [
      { label: CHAT_LABELS.TYPE, value: getProblemTypeValue(problem), displayToAgent: true },
      { label: CHAT_LABELS.REASON, value: getProblemReasonValue(problem), displayToAgent: true }
    ] : []),
    ...(email ? [{ label: CHAT_LABELS.EMAIL, value: email, displayToAgent: true }] : []),
    ...(phone ? [{ label: CHAT_LABELS.PHONE, value: phone, displayToAgent: true }] : []),
  ];

  return prechatFormDetails;
}

interface EntityFieldMap extends FieldMap {
  fieldName: string,
  label: string
}

interface PrechatInfo {
  entityName: string,
  entityFieldMaps: EntityFieldMap[],
  linkToEntityName?: string,
  linkToEntityField?: string,
  saveToTranscript?: string,
  showOnCreate?: boolean
}

export function getPrechatInfo(contactDetails: ContactDetails, problem?: Problem): PrechatInfo[] {
  const { name, email, phone } = contactDetails;
  const prechatInfo: PrechatInfo[] = [{
    entityName: CHAT_ENTITY_NAMES.CASE,
    entityFieldMaps: [
      fieldMapCreator(CHAT_FIELD_NAMES.ORIGIN, CHAT_LABELS.ORIGIN),
      ...(problem ? [
        fieldMapCreator(CHAT_FIELD_NAMES.TYPE, CHAT_LABELS.TYPE),
        fieldMapCreator(CHAT_FIELD_NAMES.REASON, CHAT_LABELS.REASON)
      ] : [])
    ]
  }, ...((name || email || phone) ? [{
    entityName: CHAT_ENTITY_NAMES.CONTACT,
    linkToEntityName: CHAT_ENTITY_NAMES.CASE,
    linkToEntityField: CHAT_ENTITY_FIELDS.CONTACT_ID,
    saveToTranscript: CHAT_ENTITY_NAMES.CONTACT,
    showOnCreate: true,
    entityFieldMaps: [
      ...(name ? [fieldMapCreator(CHAT_FIELD_NAMES.NAME, CHAT_LABELS.NAME)] : []),
      ...(email ? [fieldMapCreator(CHAT_FIELD_NAMES.EMAIL, CHAT_LABELS.EMAIL, true, true, true)] : []),
      ...(phone ? [fieldMapCreator(CHAT_FIELD_NAMES.PHONE, CHAT_LABELS.PHONE)] : []),
    ]
  }] : [])];

  return prechatInfo;
}
