import { create } from 'context/StoreCreator';

const initialState = {
  title: '',
  titleProps: {},
  component: null,
  componentProps: {},
  modalProps: {}
};

const reducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.RESET:
      return {
        ...draft,
        ...initialState
      };
    case ACTIONS.SET_STATE:
      return {
        ...draft,
        ...payload
      };
    default:
      return draft;
  }
};

const [
  OrderReportProblemProvider,
  useOrderReportProblemStore,
  useOrderReportProblemDispatch
] = create(reducer, initialState);

const ACTIONS = {
  SET_STATE: 'SET_STATE',
  RESET: 'RESET'
};

const STEPS = {
  SELECTION: 'SELECTION',
  PROBLEM: 'PROBLEM',
  SUMMARY: 'SUMMARY',
  CONFIRMATION: 'CONFIRMATION'
};

function useOrderReportProblemDispatchHelpers() {

  const dispatch = useOrderReportProblemDispatch();

  const reset = () => dispatch({ type: ACTIONS.RESET });

  const showStep = ({
    title, titleProps, component, componentProps, modalProps
  }) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: {
        title,
        titleProps,
        component,
        componentProps,
        modalProps: modalProps || {}
      }
    });
  };

  return { reset, showStep };
}

export {
  OrderReportProblemProvider,
  useOrderReportProblemStore,
  useOrderReportProblemDispatch,
  useOrderReportProblemDispatchHelpers,
  ACTIONS,
  STEPS
};
