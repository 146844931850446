import React from 'react';

import styles from './PreviewModeBanner.module.scss';

const PreviewModeBanner = () => {

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        WARNING! You are viewing this in preview mode
        <a href="/api/preview/exit" className={styles.exitBtn}>Exit preview</a>
      </div>
    </div>
  );
};

export default PreviewModeBanner;
