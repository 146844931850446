import React from 'react';

import { Collapsible } from 'components/animations';
import { useIsOpen } from 'hooks/useIsOpen';

import OrderFeedbackModal from '../OrderFeedbackModal/OrderFeedbackModal';
import OrderRatingCard from '../OrderRatingCard/OrderRatingCard';

import styles from './OrderDetailsFeedback.module.scss';

const OrderDetailsRating = (props) => {

  const { order } = props;

  const { feedbackUrl } = order;

  const {
    isOpen: isModalOpen, open: openModal, close: closeModal
  } = useIsOpen();

  const [isRatingCardShown, setIsRatingCardShown] = React.useState(!!feedbackUrl);
  const [response, setResponse] = React.useState(null);

  React.useEffect(() => {
    setIsRatingCardShown(!!feedbackUrl);
  }, [feedbackUrl]);

  const onSuccess = (data) => {
    setResponse(data);
    openModal();
    setIsRatingCardShown(false);
  };

  return (
    <div className={styles.root}>
      <Collapsible
        isExpanded={isRatingCardShown}
        defaultExpanded={isRatingCardShown}
      >
        <OrderRatingCard
          className={styles.ratingCard}
          orderToken={order?.tokenValue}
          inline
          elevation={2}
          withPadding
          title="account:ORDER_HISTORY.ORDER_FEEDBACK_TITLE"
          onSuccess={onSuccess}
        />
      </Collapsible>

      <OrderFeedbackModal
        isOpen={isModalOpen}
        initialResponse={response}
        order={order}
        close={closeModal}
      />
    </div>
  );
};

export default OrderDetailsRating;
