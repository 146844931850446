import React, { forwardRef } from 'react';

import SwiperCore, {
  A11y, Autoplay, Lazy, Navigation, Pagination, Scrollbar, Zoom,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';

import { objectMap } from 'helpers/ObjectHelpers';
import useSliderRef from 'hooks/useSliderRef';

import Icon from '../Icon/Icon';

import type { Props as IconProps } from '../Icon/Icon';
import type { SwiperOptions } from 'swiper';
import type { SwiperProps } from 'swiper/react';

import styles from './Slider.module.scss';

SwiperCore?.use([
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Lazy
]);

const BULLETS_POSITIONS = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
} as const;

type BulletsPosition = typeof BULLETS_POSITIONS[keyof typeof BULLETS_POSITIONS];

const HEIGHTS = {
  AUTO: 'auto',
  FILL: 'fill'
};

interface Bullets {
  offsetY?: boolean,
  position?: BulletsPosition,
  external?: boolean,
  bars?: boolean
}

interface Props extends Omit<SwiperProps, 'height' | 'autoplay'> {
  className?: string,
  wrapperClassName?: string,
  sliderClassName?: string,
  prevClassName?: string,
  nextClassName?: string,
  prevIconWrapperClassName?: string,
  nextIconWrapperClassName?: string,
  prevIconClassName?: string,
  nextIconClassName?: string,
  paginationClassName?: string,
  scrollbarTrackClassName?: string,
  scrollbarHandleClassName?: string,
  //
  navigation?: boolean,
  navigationIconProps?: IconProps,
  bullets?: Bullets | boolean,
  scrollbar?: boolean,
  autoplay?: number,
  //
  breakpoints?: {
    [width: number]: SwiperProps,
    [ratio: string]: SwiperProps
  },
  height?: 'auto' | 'fill',
  //
  dataTestId?: string,
  //
  children?: React.ReactNode | React.ReactElement | React.ReactElement[]
}

export const Slider = (props: Props) => {
  const {
    //
    className,
    wrapperClassName,
    sliderClassName,
    prevClassName,
    nextClassName,
    prevIconWrapperClassName,
    nextIconWrapperClassName,
    prevIconClassName,
    nextIconClassName,
    paginationClassName,
    scrollbarTrackClassName,
    scrollbarHandleClassName,
    //
    navigation,
    navigationIconProps = {},
    bullets,
    scrollbar,
    autoplay,
    //
    breakpoints = {},
    height = HEIGHTS.AUTO,
    //
    dataTestId,
    //
    children,
    ...rest
  } = props;

  // Breakpoints

  const parsedBreakpoints: Record<number, SwiperOptions> = React.useMemo(() => {
    return objectMap(breakpoints, (breakpoint: SwiperProps) => {
      const { slidesPerGroup, slidesPerView } = breakpoint || {};
      return {
        slidesPerGroup: slidesPerGroup || slidesPerView,
        ...breakpoint
      };
    }) as Record<number, SwiperOptions>;
  }, [breakpoints]);

  // Custom Navigation and Pagination

  const { el: prevEl, ref: prevElRef } = useSliderRef<HTMLButtonElement>();
  const { el: nextEl, ref: nextElRef } = useSliderRef<HTMLButtonElement>();
  const { el: scrollbarEl, ref: scrollbarElRef } = useSliderRef<HTMLDivElement>();

  // Classes

  const navButtonClasses = classnames(
    styles.navButton,
    { [styles.hidden]: !navigation }
  );

  // TODO: add custom pagination element

  const {
    position: bulletsPosition = null,
    offsetY: bulletsOffsetY = false,
    external: bulletsExternal = false,
    bars: bulletsBars = false,
  } = typeof bullets === 'object' ? bullets : {};

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.fill]: height === 'fill' },
        className
      )}
    >

      {/* Prev */}
      <button
        ref={prevElRef}
        type="button"
        aria-label="Previous"
        className={classnames(
          navButtonClasses,
          styles.prev,
          'swiper-button-prev',
          prevClassName
        )}
      >
        <div className={classnames(styles.iconWrapper, prevIconWrapperClassName)}>
          <Icon
            className={classnames(styles.icon, prevIconClassName)}
            name="chevron-left"
            size={24}
            strokeWidth={3}
            {...navigationIconProps}
          />
        </div>
      </button>

      {/* Next */}
      <button
        ref={nextElRef}
        type="button"
        aria-label="Next"
        className={classnames(
          navButtonClasses,
          styles.next,
          'swiper-button-next',
          nextClassName
        )}
      >
        <div className={classnames(styles.iconWrapper, nextIconWrapperClassName)}>
          <Icon
            className={classnames(styles.icon, nextIconClassName)}
            name="chevron-right"
            size={24}
            strokeWidth={3}
            {...navigationIconProps}
          />
        </div>
      </button>

      {/* Wrapper */}
      <div
        className={classnames(
          styles.wrapper,
          wrapperClassName
        )}
      >
        <Swiper
          className={classnames(
            styles.slider,
            { 'swiper-pagination-bullets-offset-y': bulletsOffsetY },
            { [`swiper-pagination-bullets-${bulletsPosition}`]: bulletsPosition },
            { 'swiper-pagination-bullets-external': bulletsExternal },
            { 'swiper-pagination-bullets-bars': bulletsBars },
            sliderClassName,
          )}
          navigation={
            navigation
              ? { prevEl, nextEl }
              : false
          }
          pagination={
            bullets
              ? {
                type: 'bullets',
                clickable: true,
                horizontalClass: classnames(
                  paginationClassName,
                  'swiper-pagination-bullets swiper-pagination-horizontal'
                ),
                verticalClass: classnames(
                  paginationClassName,
                  'swiper-pagination-bullets swiper-pagination-vertical'
                ),
              } : false
          }
          scrollbar={
            scrollbar
              ? {
                el: scrollbarEl,
                hide: true
              }
              : false
          }
          autoplay={
            autoplay
              ? {
                delay: autoplay,
                pauseOnMouseEnter: true,
                disableOnInteraction: false
              }
              : false
          }
          breakpoints={parsedBreakpoints}
          updateOnWindowResize
          observer
          observeParents
          resizeObserver
          data-testid={dataTestId}
          {...rest}
        >
          {children}
        </Swiper>

        {/* Scrollbar */}
        <SliderScrollbar
          ref={scrollbarElRef}
          className={scrollbarTrackClassName}
          handleClassName={scrollbarHandleClassName}
          hidden={!scrollbar}
        />
      </div>

    </div>
  );
};

// Scrollbar

interface SliderScrollbarProps {
  className?: string,
  handleClassName?: string,
  hidden?: boolean
}

const SliderScrollbar = forwardRef<HTMLDivElement, SliderScrollbarProps>((
  props: SliderScrollbarProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    className,
    handleClassName,
    hidden,
  } = props;

  const scrollbarTrackClasses = classnames(
    styles.scrollbarTrack,
    'swiper-scrollbar',
    { [styles.hidden]: hidden },
    className,
  );

  const scrollbarHandleClasses = classnames(
    styles.scrollbarHandle,
    'swiper-scrollbar-drag',
    handleClassName,
  );

  return (
    <div ref={ref} className={scrollbarTrackClasses}>
      <div className={scrollbarHandleClasses} />
    </div>
  );
});

// Export

export { type Swiper as SliderType } from 'swiper';
export const Slide = SwiperSlide;
