import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Loader.module.scss';

interface Props {
  className?: string,
  spinnerClassName?: string,
  //
  loading?: boolean,
  //
  lineWeight?: number,
  size?: number,
  speed?: number,
  color?: string,
  //
  fullscreen?: boolean,
  fullWidth?: boolean,
  transparent?: boolean
}

const Loader = (props: Props) => {
  const {
    className,
    spinnerClassName,
    //
    loading = true,
    //
    lineWeight = 2,
    size = 24,
    speed = 1,
    color = styles.primaryColor,
    //
    fullscreen = false,
    fullWidth = false,
    transparent = false
  } = props;

  const classes = classnames(
    styles.root,
    { [styles.fullscreen]: fullscreen },
    { [styles.fullWidth]: fullWidth },
    { [styles.transparent]: transparent },
    className
  );

  return (
    <div className={classes}>
      <ClipLoader
        className={classnames(styles.clipLoader, spinnerClassName)}
        loading={loading}
        size={size}
        color={color}
        speedMultiplier={speed}
        cssOverride={{
          borderWidth: lineWeight,
        }}
      />
    </div>
  );
};

// Prop Types

Loader.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  //
  loading: PropTypes.bool,
  //
  lineWeight: PropTypes.number,
  size: PropTypes.number,
  speed: PropTypes.number,
  color: PropTypes.string,
  //
  fullWidth: PropTypes.bool,
  fullscreen: PropTypes.bool,
  transparent: PropTypes.bool,
};

// Export

export default React.memo(Loader);
