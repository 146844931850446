import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import styles from './MenuItemLink.module.scss';

interface Props {
  className?: string,
  withImage?: boolean
}

const MenuItemLinkSkeleton = (props: Props) => {

  const {
    className,
    withImage
  } = props;

  // Classes

  const classes = classnames(
    styles.root,
    styles.skeleton,
    className
  );

  // Render

  return (
    <div className={classes}>

      {/* Image */}
      {
        withImage && (
          <span className={styles.image}>
            <Skeleton containerClassName={styles.skeletonContainer} height={24} />
          </span>
        )
      }

      {/* Label */}
      <span className={styles.label}>
        <Skeleton containerClassName={styles.skeletonContainer} height={21} />
      </span>

    </div>
  );

};

export default MenuItemLinkSkeleton;
