import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { IAnalyticsListProps } from 'types';

const CATEGORY = 'navigation_events';

enum Actions {
  EXPAND_CATEGORY_MENU = 'expand_category_menu',
  SELECT_CATEGORY = 'select_category',
  //
  EXPAND_MAIN_MENU = 'expand_main_menu',
  VIEW_MENU_BUTTON = 'view_menu_button',
  SELECT_MENU_BUTTON = 'select_menu_button',
  //
  VIEW_PRODUCTS_CAROUSEL = 'view_products_carousel',
  SELECT_PRODUCTS_CAROUSEL = 'select_product_carousel',
  VIEW_NAVIGATION_BUTTONS = 'view_navigation_buttons',
  VIEW_NAVIGATION_BUTTON = 'view_navigation_button',
  SELECT_NAVIGATION_BUTTON = 'select_navigation_button',
  VIEW_PROMOTIONS_CAROUSEL = 'view_promotions_carousel',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Categories

export function expandCategoryMenu() {
  push(Actions.EXPAND_CATEGORY_MENU);
}

export function selectCategory(name: string) {
  push(Actions.SELECT_CATEGORY, name);
}

// --- Menu

export function expandMainMenu() {
  push(Actions.EXPAND_MAIN_MENU);
}

export function viewMenuButton(title: string) {
  push(Actions.VIEW_MENU_BUTTON, title);
}

export function selectMenuButton(title: string) {
  push(Actions.SELECT_MENU_BUTTON, title);
}

// --- Homepage Slots

export function viewProductsCarousel(name: string, slotIndex: number, listProps?: IAnalyticsListProps) {
  push(Actions.VIEW_PRODUCTS_CAROUSEL, name, {
    index: getIndex(slotIndex),
    item_list_id: listProps?.analyticsListId,
    item_list_name: listProps?.analyticsListName,
    param1: listProps?.analyticsListId,
    param2: listProps?.analyticsListName
  });
}

export function selectProductsCarousel(name: string, slotIndex?: number, listProps?: IAnalyticsListProps) {
  push(Actions.SELECT_PRODUCTS_CAROUSEL, name, {
    index: getIndex(slotIndex),
    item_list_id: listProps?.analyticsListId,
    item_list_name: listProps?.analyticsListName,
    param1: listProps?.analyticsListId,
    param2: listProps?.analyticsListName
  });
}

export function viewNavigationButtons(slotIndex: number) {
  push(Actions.VIEW_NAVIGATION_BUTTONS, null, {
    index: getIndex(slotIndex)
  });
}

export function viewNavigationButton(title: string, slotIndex: number, buttonIndex: number) {
  push(Actions.VIEW_NAVIGATION_BUTTON, title, {
    parent_index: getIndex(slotIndex),
    index: getIndex(buttonIndex)
  });
}

export function selectNavigationButton(title: string, slotIndex: number, buttonIndex: number) {
  push(Actions.SELECT_NAVIGATION_BUTTON, title, {
    parent_index: getIndex(slotIndex),
    index: getIndex(buttonIndex)
  });
}

export function viewPromotionsCarousel(slotIndex: number) {
  push(Actions.VIEW_PROMOTIONS_CAROUSEL, null, {
    index: getIndex(slotIndex),
  });
}

function getIndex(number: number) {
  if (typeof number === 'undefined' || number === null) {
    return undefined;
  }
  return number + 1;
}
