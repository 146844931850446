import React from 'react';
import classnames from 'classnames';

import { formatPrice } from 'helpers/FormatHelpers';

import styles from './Price.module.scss';

// TODO remove after internationalization
const DEFAULT_CURRENCY = 'lei';

interface Props {
  className?: string,
  iconClassName?: string,
  valueClassName?: string,
  currencyClassName?: string,
  tag?: JSX.ElementType,
  value?: number | string,
  currency?: string,
  color?: string | GradientColor | null,
  icon?: string,
  lineThrough?: boolean,
  hidden?: boolean,
  nullable?: boolean,
  nullLabel?: string | null
}

interface GradientColor {
  start: string,
  direction: string,
  end: string
}

const Price = (props: Props) => {
  const {
    className,
    iconClassName,
    valueClassName,
    currencyClassName,
    tag: Tag = 'div',
    value,
    currency,
    color,
    icon,
    lineThrough = false,
    hidden = false,
    nullable = false,
    nullLabel
  } = props;

  // Hidden price

  if (shouldHidePrice(value, hidden, nullable)) {
    return (
      <div className={classnames(styles.root, className)} />
    );
  }

  // Props

  const displayValue = (typeof value === 'string')
    ? value
    : (value === 0 && nullLabel)
      ? nullLabel
      : `${formatPrice(value)} `;

  const displayCurrency = (typeof value === 'string' || (value === 0 && nullLabel))
    ? null
    : currency || DEFAULT_CURRENCY;

  // Classes

  const hasGradient = isGradientColor(color);

  const classes = classnames(
    styles.root,
    { [styles.lineThrough]: lineThrough },
    { [styles.hasGradient]: hasGradient },
    className,
  );

  const style = hasGradient
    ? {
      backgroundColor: color?.start,
      backgroundImage: `linear-gradient(${color?.direction}, ${color?.start}, ${color?.end})`
    } : {
      color
    };

  return (
    <Tag className={classes} style={style}>
      {
        icon && (
          <img
            className={classnames(styles.icon, iconClassName)}
            alt="priceIcon"
            width={24}
            height={24}
            src={icon}
          />
        )
      }
      <p>
        <span className={classnames(styles.value, valueClassName)}>{displayValue}</span>
        <span className={classnames(styles.currency, currencyClassName)}>{displayCurrency}</span>
      </p>
    </Tag>
  );
};

// Helpers
const shouldHidePrice = (value: string | number | undefined, hidden: boolean, nullable: boolean) => {

  // Hide if hidden is true
  if (hidden) return true;

  // Do not hide if nullable and price is 0
  if (nullable && value === 0) return false;

  // Hide if null or undefined
  if (!value) return true;

  return false;
};

function isGradientColor(color: string | GradientColor | null | undefined): color is GradientColor {
  return (
    color !== null
    && typeof color === 'object'
    && 'start' in color
    && 'direction' in color
    && 'end' in color
  );
}

export default Price;
