import React from 'react';
import classnames from 'classnames';

import { Carousel, ViewMoreLink } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useInView } from 'hooks/common/useInView';
import { useAnalytics } from 'hooks/useAnalytics';

import RecipeCard from '../RecipeCard/RecipeCard';

import { CarouselSlotTypeEnum } from 'types';
import type { IAnalyticsListProps, IRecipeBreakpointParams, IRecipeCard } from 'types';

import styles from './RecipeCarousel.module.scss';

interface Props {
  className?: string,
  carouselClassName?: string,
  carouselSlidesClassName?: string,
  carouselSlidesWrapperClassName?: string,
  headerClassName?: string,
  titleClassName?: string,
  navigationButtonClassName?: string,
  //
  index?: number,
  listProps?: IAnalyticsListProps,
  //
  navigation?: boolean,
  breakpoints?: Record<number, IRecipeBreakpointParams>,
  isQuickView?: boolean,
  //
  name?: string,
  title?: string,
  link?: {
    count?: number,
    title?: string,
    text?: string,
    url: string
  },
  recipes: IRecipeCard[],
  //
  onRecipeEnter?: (recipe: IRecipeCard) => void,
  onRecipeClick?: (recipe: IRecipeCard, e: React.MouseEvent<HTMLAnchorElement>) => void,
  onRecipeViewIngredientsClick?: (recipe: IRecipeCard, e: React.MouseEvent<HTMLButtonElement>) => void
}

const RecipeCarousel = (props: Props) => {

  const {
    className,
    carouselClassName, carouselSlidesClassName, carouselSlidesWrapperClassName,
    headerClassName,
    titleClassName,
    navigationButtonClassName,
    //
    index: carouselIndex,
    listProps,
    //
    navigation = true,
    breakpoints = DEFAULT_BREAKPOINTS,
    isQuickView,
    //
    name = '',
    title = '',
    link,
    recipes,
    //
    onRecipeEnter,
    onRecipeClick,
    onRecipeViewIngredientsClick,
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const [shouldShowNavigation, setShouldShowNavigation] = React.useState(false);

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      analytics.viewRecipeCarousel(name || title, carouselIndex, listProps);
      unobserve();
    }
  });

  // Effects

  React.useEffect(() => {
    setShouldShowNavigation(navigation);
  }, [navigation]);

  // Handlers

  const handleRecipeEnter = (recipe: IRecipeCard) => {
    analytics.viewRecipeCard(recipe?.id);

    if (onRecipeEnter) {
      onRecipeEnter(recipe);
    }
  };

  const handleRecipeClick = (recipe: IRecipeCard, e: React.MouseEvent<HTMLAnchorElement>) => {
    analytics.selectRecipeCard(recipe?.id);

    if (onRecipeClick) {
      onRecipeClick(recipe, e);
    }
  };

  const handleRecipeViewIngredientsClick = (recipe: IRecipeCard, e: React.MouseEvent<HTMLButtonElement>) => {
    analytics.selectRecipeViewIngredients(recipe?.id);

    if (onRecipeViewIngredientsClick) {
      onRecipeViewIngredientsClick(recipe, e);
    }
  };

  // Empty

  if (isArrayEmpty(recipes)) return null;

  const hasViewMoreLink = link?.url && (link?.text || link?.title);

  // Render

  return (
    <div
      ref={observe}
      className={classnames(styles.root, className)}
    >

      {/* Header */}
      {
        (title || link) && (
          <div className={classnames(styles.header, headerClassName)}>

            {/* Title */}
            {
              title && (
                <div className={classnames(styles.title, titleClassName)}>
                  {title}
                </div>
              )
            }

            {/* Link */}
            {
              link && (
                <ViewMoreLink
                  className={styles.link}
                  //
                  carouselIndex={carouselIndex}
                  carouselType={CarouselSlotTypeEnum.RECIPES}
                  listProps={listProps}
                  //
                  name={title}
                  link={link}
                />
              )
            }
          </div>
        )
      }

      {/* Carousel */}
      <Carousel
        className={classnames(styles.carousel, carouselClassName)}
        slidesClassName={classnames(styles.carouselSlides, carouselSlidesClassName)}
        slidesWrapperClassName={classnames(styles.carouselSlidesWrapper, carouselSlidesWrapperClassName)}
        prevClassName={classnames(styles.carouselPrev, navigationButtonClassName)}
        nextClassName={classnames(styles.carouselNext, navigationButtonClassName)}
        //
        spaceBetween={24}
        slidesPerView={1}
        slidesPerGroup={1}
        breakpoints={breakpoints}
        //
        navigation={shouldShowNavigation}
      >

        {/* Recipe Cards */}
        {
          recipes.map((recipe, index) => {
            const { id } = recipe;
            return (
              <RecipeCard
                key={`recipe-slide-${index}-${id}`}
                className={styles.card}
                isQuickView={isQuickView}
                recipe={recipe}
                onEnter={() => {
                  handleRecipeEnter(recipe);
                }}
                onClick={(e) => {
                  handleRecipeClick(recipe, e);
                }}
                onViewIngredientsClick={(e) => {
                  handleRecipeViewIngredientsClick(recipe, e);
                }}
              />
            );
          })
        }

        {/* View More Card */}
        {
          hasViewMoreLink && (
            <ViewMoreLink
              className={styles.viewMore}
              carouselType={CarouselSlotTypeEnum.RECIPES}
              link={link}
              name={title}
              asCard
            />
          )
        }

      </Carousel>
    </div>
  );
};

// Constants

const DEFAULT_BREAKPOINTS = {
  [parseInt(styles.breakpointMd, 10)]: {
    slidesPerView: 4,
    slidesPerGroup: 4,
  },
  [parseInt(styles.breakpointSm, 10)]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
};

// Export

export default RecipeCarousel;
