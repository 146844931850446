import React from 'react';
import classnames from 'classnames';

import { UrlParams } from 'common/url-params';
import { APP_ROUTES } from 'constants/routes';
import { useTranslation } from 'hooks/common/useTranslation';
import { useCustomListing } from 'hooks/data/useCustomListing';
import { usePromotion } from 'hooks/data/usePromotion';

import { ProductDefaultCard, ProductMobilePreviewCard } from '../cards';

import ProductListSkeleton from '../ProductList/ProductListSkeleton';
import ProductsCarousel from '../ProductsCarousel/ProductsCarousel';

import type { IAnalyticsListProps } from 'types';

import styles from './ProductsListingWidget.module.scss';

enum ListingType {
  CustomListing = 'customListing',
  Promotion = 'promotion'
}

interface Props {
  className?: string,
  //
  title?: string,
  listingSlug: string,
  //
  isTitleHidden?: boolean,
  isMobilePreview?: boolean,
  //
  listingType?: ListingType,
  //
  getAnalyticsProps?: (listingSlug: string, listingTitle: string) => IAnalyticsListProps
}

const ProductsListingWidget = (props: Props) => {

  const {
    className,
    //
    title,
    listingSlug,
    //
    isTitleHidden,
    isMobilePreview,
    //
    listingType = ListingType.CustomListing,
    //
    getAnalyticsProps
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Data Hooks

  // --- Custom listing

  const {
    data: customListingData,
    isLoading: isCustomListingLoading,
    isError: isCustomListingError
  } = useCustomListing({
    identifier: listingSlug,
    page: 1,
    itemsPerPage: 8,
    category: UrlParams.DEFAULT_CUSTOM_LISTING_CATEGORY
  }, { enabled: !!listingSlug && listingType === ListingType.CustomListing });

  // --- Promotion

  const {
    data: promotionData,
    isLoading: isPromotionListingLoading,
    isError: isPromotionListingError,
  } = usePromotion({
    identifier: listingSlug,
    page: 1,
    itemsPerPage: 8,
    category: UrlParams.DEFAULT_PROMOTION_CATEGORY
  }, { enabled: !!listingSlug && listingType === ListingType.Promotion });

  const { payload: promotionPayload } = promotionData || {};

  // Error

  const isError = isCustomListingError || isPromotionListingError;
  if (isError) {
    return null;
  }

  // Loading

  const isLoading = isCustomListingLoading || isPromotionListingLoading;
  if (isLoading) {
    return <ProductListSkeleton count={6} className={styles.skeleton} />;
  }

  // Props

  const data = customListingData || promotionPayload;

  const {
    analyticsListId: baseAnalyticsListId,
    analyticsListName: baseAnalyticsListName,
    items: products,
    slug,
    name
  } = data || {};

  const displayTitle = !isTitleHidden
    ? title || name
    : undefined;

  const link = {
    title: t('CAROUSEL.ALL'),
    url: getUrl(listingType, listingSlug),
  };

  const { analyticsListId, analyticsListName } = getAnalyticsProps
    ? getAnalyticsProps(slug, name)
    : { analyticsListId: baseAnalyticsListId, analyticsListName: baseAnalyticsListName };

  // Multiple Products - Products Carousel

  return (
    <div className={classnames(styles.root, className)}>
      <ProductsCarousel
        title={displayTitle}
        link={link}
        cardComponent={
          isMobilePreview
            ? ProductMobilePreviewCard
            : ProductDefaultCard
        }
        products={products || []}
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
      />
    </div>
  );
};

// Helpers

function getUrl(listingType: ListingType, slug: string) {
  switch (listingType) {
    case ListingType.Promotion:
      return `${APP_ROUTES.PROMOTIONS}/${slug}`;
    case ListingType.CustomListing:
      return `${APP_ROUTES.CUSTOM_LISTING}/${slug}`;
    default:
      return `${APP_ROUTES.CUSTOM_LISTING}/${slug}`;
  }
}

// Export

export default ProductsListingWidget;
