import { CONFIG } from 'constants/config';

import { Holiday } from 'types';

export function isChristmasSeason() {
  return CONFIG.SEASONAL.CHRISTMAS_ENABLED;
}

export function isSnowfallEnabled() {
  return CONFIG.SEASONAL.SNOWFALL_ENABLED;
}

export function isEasterSeason() {
  return CONFIG.SEASONAL.EASTER_ENABLED;
}

export function getActiveHoliday() {
  if (CONFIG.SEASONAL.EASTER_ENABLED) {
    return Holiday.Easter;
  }

  if (CONFIG.SEASONAL.CHRISTMAS_ENABLED) {
    return Holiday.Christmas;
  }

  return null;
}
