import { Api, SecureApi } from 'common/http';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';

import type {
  IListingRequestParams, IProductDetails, IProductsListing,
} from 'types';

// --- Product

export async function getProduct(slug: string) {
  const { data } = await Api.get<IProductDetails>(`/product-by-slug/${encodeURIComponent(slug)}`);
  return data;
}

// --- Multiple Products

interface GetProductsBySkuParams {
  page?: number,
  itemsPerPage?: number,
  skus: string[]
}

export async function getProductsBySku(params: GetProductsBySkuParams) {
  const {
    page = 1,
    itemsPerPage = 12,
    skus = []
  } = params || {};

  const skuQs = skus.map((sku) => `sku[]=${sku}`).join('&');
  const endpoint = `/products-multi-sku?page=${page}&itemsPerPage=${itemsPerPage}&${skuQs}`;

  const { data } = await Api.get(endpoint);
  const { items: products } = data;

  return products;
}

// --- Usual Products

export async function getUsualProducts(params: IListingRequestParams) {
  const { page, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, searchQuery } = params || {};

  const endpoint = !searchQuery
    ? `/my-usuals?page=${page}&itemsPerPage=${itemsPerPage}`
    : `/my-usuals/search/${encodeURIComponent(searchQuery)}?page=${page}&itemsPerPage=${itemsPerPage}`;
  const { data } = await SecureApi.get<IProductsListing>(endpoint);
  return data;
}

// --- New Products

export async function getNewProducts(params: IListingRequestParams) {

  const {
    category, page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, filters
  } = params || {};

  const orderByQs = UrlParams.getOrderByQueryString(orderBy);
  const filtersQs = UrlParams.getFiltersQueryString(filters);

  const { data } = await Api.get<IProductsListing>(`/new-products?category=${category}&page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}`);
  return data;
}

// --- Accelerated Sale Products

export async function getAcceleratedSaleProducts(params: IListingRequestParams) {
  const {
    category, page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, filters
  } = params || {};

  const orderByQs = UrlParams.getOrderByQueryString(orderBy);
  const filtersQs = UrlParams.getFiltersQueryString(filters);
  const url = `/accelerated-sale-products?category=${category}&page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}`;

  const { data } = await Api.get<IProductsListing>(url);
  return data;
}

// --- Favorite Products

export async function getFavoriteProducts(params: IListingRequestParams) {
  const { page, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE } = params;
  const url = `/wishlists/product-variants?page=${page}&itemsPerPage=${itemsPerPage}`;
  const { data } = await SecureApi.get<IProductsListing>(url);
  return data;
}

export async function getFavoriteProductsIds() {
  const { data } = await SecureApi.get('/wishlists/product-variants-codes');
  return data;
}

export async function addFavorite(variantCode: string) {
  const { data } = await SecureApi.post('/wishlists/product-variants', { variantCode });
  return data;
}

export async function removeFavorite(variantCode: string) {
  const { data } = await SecureApi.delete(`/wishlists/product-variants/${variantCode}`);
  return data;
}

export async function getProductQuantityVariants(variantCode: string, tokenValue: string) {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/${variantCode}/multiple-quantity`);
  return data;
}
