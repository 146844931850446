import Router from 'next/router';

import { CONFIG, REFRESH_TOKEN_ENDPOINT } from 'constants/config';
import { ERROR_CODES } from 'constants/errors';
import { HTTP_HEADERS } from 'constants/http-headers';
import { APP_ROUTES } from 'constants/routes';
import {
  deleteRefreshToken, getAccessTokenFromAuthHeader, getAuthHeader, getRefreshToken
} from 'helpers/AuthenticationHelpers';
import { isClient } from 'helpers/BrowserHelpers';
import { encodeRedirectState } from 'helpers/UrlHelpers';
import { stall } from 'helpers/utils';

import { createHttpClient } from './HttpClient';

import { isHttpClientError } from 'types';
import type { ExtendedHttpClientError, HttpClientConfig } from 'types';

/* Create API instances */

export const Api = createHttpClient({ prefixUrl: CONFIG.API_URL });
export const SecureApi = createHttpClient({ prefixUrl: CONFIG.API_URL });

/* API Interceptors */

Api.addResponseInterceptor(
  (response) => response,
  (error) => {
    const { url } = error.response || {};
    if (error?.response?.status === ERROR_CODES.UNAUTHORIZED && url?.includes(REFRESH_TOKEN_ENDPOINT)) {
      deleteRefreshToken();
      Router.push({ pathname: APP_ROUTES.LOGIN, query: { state: encodeRedirectState(window.location.pathname) } });
    }
    if (error?.response?.status === ERROR_CODES.NOT_ALLOWED) {
      // AWS Bot detection
      if (isClient() && error.response.headers[HTTP_HEADERS.AWS_WAF] === 'captcha') {
        window.location.reload();
        return;
      }
    }
    return Promise.reject(error);
  }
);

/* Secure API Interceptors */

SecureApi.addRequestInterceptor(
  (request) => {
    const accessToken = SecureApi.getAccessToken();
    if (accessToken) {
      request.headers.set('Authorization', getAuthHeader(accessToken));
    }
    return request;
  }
);

const BACKOFF_MULTIPLIER = 2;
const INITIAL_DELAY = 1500;
const MAX_ATTEMPTS = 3;

export function setSecureApiResponseInterceptor(refreshAccessToken: () => Promise<void>) {

  let isRefreshTokenLoading = false;

  /* Parse error */
  function parseError(error: ExtendedHttpClientError) {
    const { response, options } = error;
    const { url } = response || {};
    const { prefixUrl } = options;
    const path = (url || '').split(prefixUrl)[1];
    return {
      options,
      path
    };
  }

  /* Check if for the given request 401 should not cause an access token refresh */
  function shouldSkipRequest(config?: HttpClientConfig): boolean {
    const { isOptional } = config || {};
    const refreshToken = getRefreshToken();
    return Boolean(isOptional && !refreshToken);
  }

  /* Retry request with backoff */
  async function retryWithBackoff(error: ExtendedHttpClientError) {
    let delay = INITIAL_DELAY;

    const { options, path } = parseError(error);

    if (!options || !path) {
      throw error;
    }

    for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
      await stall(delay);
      if (!isRefreshTokenLoading) {
        return SecureApi.getInstance()(path, options);
      }

      delay *= BACKOFF_MULTIPLIER;
    }

    throw error;
  }

  /* If the request fails with 401, refresh the access token and then retry the initial request with a delay */
  async function handleUnauthorizedError(error: ExtendedHttpClientError) {
    if (isRefreshTokenLoading) {
      return retryWithBackoff(error);
    }

    const { options, path } = parseError(error);

    const requestAccessToken = options?.headers instanceof Headers
      ? getAccessTokenFromAuthHeader(options.headers.get('Authorization')?.toString())
      : undefined;
    const actualAccessToken = SecureApi.getAccessToken();

    if (requestAccessToken && actualAccessToken && requestAccessToken !== actualAccessToken) {
      return SecureApi.getInstance()(path, options);
    }

    try {
      isRefreshTokenLoading = true;
      await refreshAccessToken();
    } catch (err) {
      console.log('Error while refreshing JWT and retrying request', err);
      throw error;
    } finally {
      isRefreshTokenLoading = false;
    }

    return SecureApi.getInstance()(path, options);
  }

  /* Remove previous interceptors */
  SecureApi.removeResponseInterceptors();

  /* Add interceptor to handle 401 errors */
  SecureApi.addResponseInterceptor(
    (response) => response,
    async (error) => {
      if (!isHttpClientError(error)) {
        return;
      }

      const { response, options } = error || {};
      const { status } = response || {};

      if (status !== ERROR_CODES.UNAUTHORIZED) {
        return;
      }

      if (shouldSkipRequest(options)) {
        return;
      }

      return handleUnauthorizedError(error);
    }
  );
}
