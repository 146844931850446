import React from 'react';
import classnames from 'classnames';

import { ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';
import { createArray, isArrayEmpty } from 'helpers/ArrayHelpers';

import AccountPromotionCard from '../AccountPromotionCard/AccountPromotionCard';
import AccountPromotionCardSkeleton from '../AccountPromotionCard/AccountPromotionCardSkeleton';

import type { IAccountPromotion } from 'types';

import styles from './AccountPromotionCardList.module.scss';

interface Props {
  className?: string,
  loading?: boolean,
  promotions?: IAccountPromotion[],
  cardProps?: Omit<React.ComponentProps<typeof AccountPromotionCard>, 'promotion'>
}

const AccountPromotionsCardList = (props: Props) => {

  const {
    className, promotions = [], loading, cardProps = {}
  } = props;

  if (loading) {
    return (
      <div className={classnames(styles.root, className)}>
        {createArray(2).map((_, index) => <AccountPromotionCardSkeleton className={classnames(styles.card, cardProps.className)} key={index} />)}
      </div>
    );
  }

  if (isArrayEmpty(promotions)) {
    return (
      <div className={classnames(styles.root, className)}>
        <ImageMessage
          className={styles.empty}
          image={IMAGES.VOUCHERS.NO_PROMOTIONS}
          imageProps={{ width: 342, height: 120, alt: 'No promotions' }}
        />
      </div>
    );
  }

  return (
    <div className={classnames(styles.root, className)}>
      {promotions.map((promotion, index) => (
        <AccountPromotionCard
          promotion={promotion}
          key={`${promotion.url}-${index}`}
          {...cardProps}
          className={classnames(styles.card, cardProps.className)}
        />
      ))}
    </div>
  );

};

export default AccountPromotionsCardList;
