import QueryString from 'common/QueryString';
import { PAGES_MAP } from 'constants/pages';
import { APP_ROUTES } from 'constants/routes';
import { decodeURIComponentSafe } from 'helpers/BrowserHelpers';

import { UrlParams, type UrlSegments } from './UrlParams';
import { DEFAULT_URL_SEPARATOR, hasSegments } from './UrlParamsHelpers';

import type { IListingActiveOrderBy, IPageUrlParams } from 'types';
import { PageType } from 'types';

const ORDER_BY_REGEX = new RegExp(`^orderby${DEFAULT_URL_SEPARATOR}`);

/* Order By */
export function getOrderByParams(segments: UrlSegments) {
  if (!hasSegments(segments)) return { orderBy: null, isOrdered: false };

  const orderBySegment = segments.find((segment) => segment.match(ORDER_BY_REGEX));

  if (orderBySegment) {
    try {
      const value = orderBySegment.split(DEFAULT_URL_SEPARATOR)[1];
      const [field, direction] = value.split(',');
      return {
        orderBy: {
          field: decodeURIComponentSafe(field),
          direction: decodeURIComponentSafe(direction) as ('asc' | 'desc')
        },
        isOrdered: true
      };
    } catch (err) {
      console.error('Error while getting order by from url', err);
    }
  }

  return { orderBy: null, isOrdered: false };
}

export function setOrderByParams(newOrderBy: IListingActiveOrderBy | null, options: {segments: UrlSegments, pageType: PageType }) {
  const { segments, pageType } = options;

  // if (!Array.isArray(segments) || !type) console.error('Failed to set orderBy, invalid options', options);

  const params = UrlParams.getParams(segments, pageType);
  const prefix = getOrderByPrefix(newOrderBy, pageType, params);

  const url = UrlParams.setParams({
    ...params,
    prefix,
    page: 1,
    orderBy: newOrderBy
  });

  return url;
}

function getOrderByPrefix(newOrderBy: IListingActiveOrderBy | null, pageType: PageType, params: IPageUrlParams) {

  const { isDefault, prefix } = params;

  if ([PageType.Search, PageType.AccountFreshclub, PageType.RecipesByTag].includes(pageType)) return prefix;

  if (isDefault && !newOrderBy) {
    return PAGES_MAP[pageType]?.path || APP_ROUTES.HOME;
  }

  return PAGES_MAP[pageType]?.filteredPath || APP_ROUTES.HOME;
}

export function getOrderByQueryString(orderBy: IListingActiveOrderBy | null | undefined, orderByOptionsMap?: { [field: string]: string }) {
  if (!orderBy) return '';
  const { field, direction } = orderBy;
  const camelCasedField = orderByOptionsMap?.[field] || field;
  const qs = QueryString.stringify({ sort: { [camelCasedField]: direction } }, { skipNulls: true });
  return `&${qs}`;
}
