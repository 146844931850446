import type { IBannerImage } from 'types/image';
import type { IAnalyticsListProps } from 'types/misc';
import type { IProduct } from 'types/product';
import type { IPromotionCard } from 'types/promotion';
import type { ValueOf } from 'types/utility';

// Response

export interface IHomepageResponse {
  slots: IHomepageSlot[],
  page?: number,
  limit?: number,
  nextStartIndex?: number,
  hasMore?: boolean
}

// Banner

export interface IHomepageBanner extends IAnalyticsListProps {
  image: IBannerImage,
  url: string,
  name: string,
  newTab: boolean
}

// Slot

export type IHomepageSlot = (
  IHomepageBannersCarouselSlot
  | IHomepagePromotionsCarouselSlot
  | IHomepageRecipesPromotionsCarouselSlot
  | IHomepageProductsCarouselSlot
  | IHomepageHalfBannerSlot
  | IHomepageNavigationSlot
);

interface IHomepageGenericSlot {
  index: number,
  type: HomepageSlotType
}

// --- Slot Types

export const HOMEPAGE_SLOT_TYPES = {
  BANNERS_CAROUSEL: 'banners_carousel',
  PROMOTIONS_CAROUSEL: 'promotions_carousel',
  RECIPE_PROMOTIONS_CAROUSEL: 'promo_recipes_carousel',
  PRODUCTS_CAROUSEL: 'products_carousel',
  HALF_BANNER: 'half_banner',
  NAVIGATION: 'navigation'
} as const;

export type HomepageSlotType = ValueOf<typeof HOMEPAGE_SLOT_TYPES>;

// ------ Banners Carousel

export interface IHomepageBannersCarouselSlot extends IHomepageGenericSlot {
  type: typeof HOMEPAGE_SLOT_TYPES.BANNERS_CAROUSEL,
  title?: string,
  banners: IHomepageBanner[]
}

// ------ Promotions Carousel

export interface IHomepagePromotionsCarouselSlot extends IHomepageGenericSlot {
  type: typeof HOMEPAGE_SLOT_TYPES.PROMOTIONS_CAROUSEL,
  title?: string,
  promotions: IHomepagePromotionsCarouselItem[]
}

export interface IHomepageRecipesPromotionsCarouselSlot extends IHomepageGenericSlot {
  type: typeof HOMEPAGE_SLOT_TYPES.RECIPE_PROMOTIONS_CAROUSEL,
  title?: string,
  promotions: IHomepageRecipesPromotionsCarouselItem[]
}

export interface IHomepagePromotionsCarouselItem extends IPromotionCard {}
export interface IHomepageRecipesPromotionsCarouselItem extends IPromotionCard {}

// ------ Products Carousel

export interface IHomepageProductsCarouselSlot extends IHomepageGenericSlot, Omit<IAnalyticsListProps, 'index'> {
  type: typeof HOMEPAGE_SLOT_TYPES.PRODUCTS_CAROUSEL,
  title: string,
  link: IHomepageProductsCarouselLink,
  products: IProduct[]
}

export interface IHomepageProductsCarouselLink {
  title: string,
  count: number,
  url: string
}

export interface IHomepageRecipesPromoCarouselLink {
  count: number,
  title: string,
  url: string
}

// ------ Half Banners

export interface IHomepageHalfBannerSlot extends IHomepageGenericSlot {
  type: typeof HOMEPAGE_SLOT_TYPES.HALF_BANNER,
  title?: string,
  halfBanners: IHomepageBanner[]
}

// ------ Navigation

export interface IHomepageNavigationSlot extends IHomepageGenericSlot {
  type: typeof HOMEPAGE_SLOT_TYPES.NAVIGATION,
  buttons: IHomepageNavigationButton[]
}

export interface IHomepageNavigationButton {
  title: string,
  url: string,
  icon: string,
  iconPng: string,
  count: number
}
