import React from 'react';
import classnames from 'classnames';

import {
  Carousel, Image, Link, Skeleton, ViewMoreLink
} from 'components/ui';

import { CONFIG } from 'constants/config';
import { areArraysEqual, isArrayEmpty } from 'helpers/ArrayHelpers';
import { calculateSlideFlex } from 'helpers/CarouselHelpers';
import { useInView } from 'hooks/common/useInView';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { usePrevious } from 'hooks/usePrevious';

import ProductDefaultCard from '../cards/ProductDefaultCard/ProductDefaultCard';
import ProductDefaultCardSkeleton from '../cards/ProductDefaultCard/ProductDefaultCardSkeleton';

import type { CarouselApi } from 'components/ui/Carousel';

import {
  Breakpoints,
  CarouselSlotTypeEnum
} from 'types';
import type { IAnalyticsListProps, IProduct, Nullable } from 'types';

import styles from './ProductsCarousel.module.scss';

interface BreakpointParams {
  slidesPerView?: number,
  slidesPerGroup?: number,
  spaceBetween?: number
}

interface Props {
  //
  className?: string,
  headerClassName?: string,
  carouselClassName?: string,
  carouselSlidesClassName?: string,
  carouselSlidesWrapperClassName?: string,
  iconClassName?: string,
  titleClassName?: string,
  prevClassName?: string,
  nextClassName?: string,
  //
  index?: number,
  //
  icon?: string,
  title?: string,
  subtitle?: string,
  link?: Nullable<{
    as?: string,
    url: string,
    title: string
  }>,
  image?: {
    src: string,
    url: string,
    size?: number
  },
  //
  products?: IProduct[],
  productListProps?: IAnalyticsListProps,
  productCardProps?: Partial<React.ComponentProps<typeof ProductDefaultCard>>,
  //
  withViewMoreLink?: boolean,
  withViewMoreCard?: boolean,
  spaceBetween?: number,
  breakpoints?: Record<number, BreakpointParams>,
  //
  loading?: boolean,
  loadingCount?: number,
  withLoadingHeader?: boolean,
  withSmallLabels?: boolean,
  //
  onItemClick?: (product: IProduct) => void,
  onItemQuantityChange?: () => {},
  onLinkClick?: () => void,
  onEnter?: () => void,
  //
  cardComponent?: React.ComponentType
  //
}

const ProductsCarousel = (props: Props) => {
  const {
    //
    className,
    headerClassName,
    iconClassName,
    titleClassName,
    prevClassName,
    nextClassName,
    carouselClassName,
    carouselSlidesClassName,
    carouselSlidesWrapperClassName,
    //
    index: carouselIndex,
    //
    icon,
    title,
    subtitle,
    link,
    image,
    //
    products,
    productListProps = {},
    productCardProps = {},
    //
    withViewMoreLink = true,
    withViewMoreCard = true,
    spaceBetween,
    breakpoints = DEFAULT_BREAKPOINTS,
    //
    loading,
    loadingCount,
    withLoadingHeader,
    withSmallLabels,
    //
    onItemClick = () => {},
    onItemQuantityChange = () => {},
    onLinkClick,
    onEnter = () => {},
    //
    cardComponent: CardComponent = ProductDefaultCard
    //
  } = props;

  // Hooks

  const analytics = useAnalytics();
  const isSmBreakpoint = useBreakpoint(Breakpoints.SM, 'down');

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  const previousProducts = usePrevious(products);
  const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();

  // Analytics

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (products && !isArrayEmpty(products)) {
        analytics.viewItemList(products, {
          id: productListProps?.analyticsListId,
          name: productListProps?.analyticsListName,
        });
        analytics.viewProductsCarousel(title, carouselIndex, productListProps);
        onEnter();
      }
      unobserve();
    }
  });

  // Effects

  // Reset carousel when products are updated
  React.useEffect(() => {
    try {
      if (
        carouselApi
        && !isArrayEmpty(previousProducts)
        && !isArrayEmpty(products)
        && !areArraysEqual(previousProducts, products)
      ) {
        carouselApi.scrollTo(0);
      }
    } catch (err) {
      console.log('Error while trying to reset carousel ', err);
    }
  }, [carouselApi, previousProducts, products]);

  // Handlers

  const onProductEnter = (product: IProduct) => {
    analytics.viewItem(product, {
      id: productListProps?.analyticsListId,
      name: productListProps?.analyticsListName,
    });

    if (product?.sponsored) {
      analytics.viewSponsoredProduct(
        product?.sku,
        product?.sponsored?.position,
        product?.sponsored?.billed,
        productListProps
      );
    }
  };

  const onProductClick = (product: IProduct, index: number) => {
    analytics.selectItem(
      product,
      {
        id: productListProps?.analyticsListId,
        name: productListProps?.analyticsListName || title,
        index
      }
    );

    if (product?.sponsored) {
      analytics.selectSponsoredProduct(
        product?.sku,
        product?.sponsored?.position,
        product?.sponsored?.billed,
        productListProps
      );
    }

    if (onItemClick) {
      onItemClick(product);
    }
  };

  // Loading

  const displayProducts = React.useMemo(() => (
    loading ? new Array(loadingCount || 12).fill(0) : products
  ), [loading, loadingCount, products]);

  // Props

  const canViewMore = link?.url && link?.title;

  // Empty state

  if (isArrayEmpty(displayProducts, true)) {
    return null;
  }

  // Render

  return (
    <div ref={loading ? undefined : observe} className={classnames(styles.root, className)}>

      {/* Header */}
      <div
        className={classnames(
          styles.header,
          {
            [styles.withSubtitle]: subtitle,
            [styles.empty]: loading ? !withLoadingHeader : (!title && !link)
          },
          headerClassName
        )}
      >

        {/* Icon */}
        {
          icon && (
            <div className={classnames(styles.icon, iconClassName)}>
              <Image
                src={icon}
                width={20}
                height={20}
                quality="100"
                alt={title}
              />
            </div>
          )
        }

        {/* Title / Subtitle */}
        <div className={styles.titleContainer}>

          {/* Title */}
          <div className={classnames(styles.title, titleClassName)}>
            {
              loading
                ? (withLoadingHeader ? <Skeleton containerClassName={styles.skeleton} /> : null)
                : title
            }
          </div>

          {/* Subtitle */}
          {
            subtitle && (
              <div className={styles.subtitle}>
                {subtitle}
              </div>
            )
          }
        </div>

        {/* View More Link */}
        {
          (withViewMoreLink && canViewMore) && (
            <ViewMoreLink
              name={title}
              link={link}
              listProps={productListProps}
              carouselIndex={carouselIndex}
              carouselType={CarouselSlotTypeEnum.PRODUCTS}
              onLinkClick={onLinkClick}
            />
          )
        }
      </div>

      {/* Subtitle - mobile */}
      {subtitle && <div className={styles.subtitleMobile}>{subtitle}</div>}

      {/* Carousel */}
      <Carousel
        prevClassName={classnames(styles.navButton, prevClassName)}
        nextClassName={classnames(styles.navButton, nextClassName)}
        className={carouselClassName}
        slidesWrapperClassName={carouselSlidesWrapperClassName}
        slidesClassName={carouselSlidesClassName}
        spaceBetween={spaceBetween || (isSmBreakpoint ? 8 : 14)}
        slidesPerView={2}
        slidesPerGroup={2}
        breakpoints={breakpoints}
        navigation
        renderBeforeSlides={image ? () => <ProductsCarouselImage image={image} title={title} /> : undefined}
        onInit={(api) => {
          setCarouselApi(api);
        }}
      >

        {/* Product Cards */}
        {
          displayProducts?.map((product, index) => (
            loading
              ? <ProductDefaultCardSkeleton key={`loading-${index}`} />
              : (
                <CardComponent
                  key={`${product.slug}${index}`}
                  className={styles.card}
                  product={product}
                  listProps={{ ...productListProps, index }}
                  shouldShowGeniusPrice={!!user?.shouldShowGeniusPrice}
                  withSmallLabels={withSmallLabels}
                  {...productCardProps}
                  onQuantityChange={onItemQuantityChange}
                  onEnter={() => {
                    onProductEnter(product);
                  }}
                  onClick={() => {
                    onProductClick(product, index);
                  }}
                />
              )
          ))
        }

        {/* View More Card */}
        {
          (withViewMoreCard && canViewMore) && (
            <div className={styles.viewMoreItem}>
              <ViewMoreLink
                asCard
                name={title}
                link={link}
                listProps={productListProps}
                carouselIndex={carouselIndex}
                carouselType={CarouselSlotTypeEnum.PRODUCTS}
                onLinkClick={onLinkClick}
              />
            </div>
          )
        }
      </Carousel>

    </div>
  );
};

const ProductsCarouselImage = (props: {image: Props['image'], title: Props['title'] }) => {
  const { image, title } = props;
  const { size = 1, url, src } = image || {};

  if (!image || !url) return null;

  const { height, width } = imageSizes[size] || imageSizes[1];

  return (
    <Link
      passHref
      href={url}
      prefetch={CONFIG.PREFETCH_LINKS}
      legacyBehavior
    >
      <a
        className={styles.imageWrapper}
        style={{
          flex: calculateSlideFlex(size)
        }}
      >
        <Image
          className={styles.image}
          src={src}
          height={height}
          width={width}
          alt={title}
          style={{ objectFit: 'cover' }}
        />
      </a>
    </Link>
  );
};

// https://confluence.emag.network/display/YUM/%5BFE%5D+Carousel+slot+images
const imageSizes = {
  1: { height: 540, width: 320 },
  2: { height: 540, width: 654 },
};

// Breakpoints

const DEFAULT_BREAKPOINTS = {
  1600: {
    slidesPerView: 4
  },
  1440: {
    slidesPerView: 3
  },
  960: {
    slidesPerView: 4
  },
  640: {
    slidesPerView: 3
  }
};

export default ProductsCarousel;
