import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { setSecureApiResponseInterceptor } from 'common/http';
import { QUERY_KEYS } from 'constants/query-keys';
import { useAuthStoreDispatch } from 'context/AuthenticationContext';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { getKey as getActiveOrderKey } from 'hooks/data/useActiveOrder';
import { getKey as getHomepageQueryKey } from 'hooks/data/useHomepage';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import * as HomepageService from 'services/HomepageService';
import * as OrdersService from 'services/OrdersService';

export function useAuthObserver() {

  // Hooks

  const queryClient = useQueryClient();
  const { accessToken } = useIsAuthenticated();
  const { refreshAccessToken } = useAuthStoreDispatch();

  // Handlers

  // --- Set Secure API Response Interceptor

  const initialize = React.useCallback(() => {
    setSecureApiResponseInterceptor(refreshAccessToken);
  }, [refreshAccessToken]);

  // --- Prefetch homepage

  const prefetchPersonalizedHomepage = React.useCallback(async () => {
    const key = getHomepageQueryKey({ isPersonalized: true, page: 1 });
    const state = queryClient.getQueryState(key);
    if (!state) {
      queryClient.prefetchQuery({
        queryKey: key,
        queryFn: () => HomepageService.getPersonalizedHomepage({ page: 1 })
      });
    }
  }, [queryClient]);

  // --- Assign the order to the logged in user or refetch order

  const handleOrderActions = React.useCallback(async (token?: string) => {
    const unauthenticatedOrderTokenValue = OrderHelpers.getUnauthenticatedOrder();

    if (unauthenticatedOrderTokenValue) {
      const order = await OrdersService.assignOrder(unauthenticatedOrderTokenValue);
      queryClient.setQueryData(getActiveOrderKey({ isAuthenticatedOrder: !!token }), order);
    }
  }, [queryClient]);

  // Effects

  React.useEffect(() => {
    if (accessToken) {
      prefetchPersonalizedHomepage();
      handleOrderActions(accessToken);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // Return

  return {
    initialize
  };

}
