import React from 'react';
import { cssTransition, toast } from 'react-toastify';

import { Button, Icon, Toast } from 'components/ui';

import type {
  ToastOptions as DefaultOptions,
  UpdateOptions
} from 'react-toastify';

import type { ValueOf } from 'types';
import { isJSXElement } from 'types';

interface ToastOptions extends DefaultOptions {
  onDismiss?: () => void
}

interface ToastUpdateOptions extends UpdateOptions {
  onDismiss?: () => void
}

export const TOAST_CONTAINER_IDS = {
  GENERIC: 'genericToastContainerId',
  CUSTOM: 'customToastContainerId',
  BANNERS: 'bannersToastContainerId',
} as const;

export const TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  DEFAULT: 'default'
} as const;

const TYPES_TO_METHODS = {
  [TYPES.SUCCESS]: 'success',
  [TYPES.ERROR]: 'error',
  [TYPES.WARNING]: 'warn',
  [TYPES.INFO]: 'info',
} as const;

type ToastId = string | number;
type ToastData = string | JSX.Element | {title?: string, message?: string, icon?: string | React.JSX.Element};

export enum ToastPosition {
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right'
}

const dismiss = (toastId: ToastId) => toast.dismiss(toastId);

const showSuccess = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.SUCCESS, options);
};

const showError = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.ERROR, options);
};

const showWarn = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.WARNING, options);
};

const showInfo = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.INFO, options);
};

const showDefault = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.DEFAULT, {
    theme: 'light',
    ...options,
  });
};

const showProgress = (data: ToastData, options?: ToastOptions) => {
  showToast(data, TYPES.DEFAULT, {
    hideProgressBar: false,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
    ...options,
  });
};

const showToast = (data: ToastData, type: ValueOf<typeof TYPES>, options?: ToastOptions) => {
  const {
    position = ToastPosition.BottomRight,
    onDismiss,
    ...rest
  } = options || {};

  const method = type === 'default' ? undefined : TYPES_TO_METHODS[type];

  let message: React.ReactNode;
  let icon: React.JSX.Element | undefined;

  if (typeof data === 'string' || isJSXElement(data)) {
    message = data;
  } else if (data.title) {
    message = <Toast title={data.title} message={data.message} />;
  } else {
    message = data?.message;
  }

  if (typeof data === 'object' && 'icon' in data) {
    if (typeof data.icon === 'string') {
      icon = <Icon name={data.icon} size={20} strokeWidth={2} />;
    } else {
      icon = data.icon;
    }
  }

  const toastProps: ToastOptions = {
    containerId: TOAST_CONTAINER_IDS.GENERIC,
    position,
    icon,
    closeButton: ({ closeToast }) => (
      <Button
        icon="x"
        iconSize={16}
        iconStrokeWidth={3}
        size="medium"
        style={
          type !== 'default'
            ? { color: '#FFFFFF' }
            : {}
        }
        onClick={(e) => {
          closeToast(e);
          if (onDismiss) {
            onDismiss();
          }
        }}
      />
    ),
    ...rest
  };

  if (method) {
    toast[method](message, toastProps);
  } else {
    toast(message, toastProps);
  }

};

const showBanner = (component: React.ReactNode, options: ToastOptions = {}) => {
  toast(component, {
    containerId: TOAST_CONTAINER_IDS.BANNERS,
    ...options
  });
};

const fadeTransition = cssTransition({
  enter: 'toast-transition-enter-fade-in-bottom',
  exit: 'toast-transition-exit-fade-out',
});

const slideFadeTransition = cssTransition({
  enter: 'toast-transition-enter-slide-right',
  exit: 'toast-transition-exit-fade-out'
});

const popFadeTransition = cssTransition({
  enter: 'toast-transition-enter-pop-in',
  exit: 'toast-transition-exit-fade-out'
});

const update = (id: ToastId, options: ToastUpdateOptions = {}) => {
  return toast.update(id, {
    containerId: TOAST_CONTAINER_IDS.GENERIC,
    ...options
  });
};

const { isActive } = toast;

export {
  //
  showSuccess,
  showError,
  showWarn,
  showInfo,
  showDefault,
  showProgress,
  showToast,
  showBanner,
  //
  isActive,
  dismiss,
  update,
  //
  slideFadeTransition,
  popFadeTransition,
  fadeTransition,
  //
};
