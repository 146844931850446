import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Error, Loader } from 'components/ui';
import { useGeniusBenefits } from 'hooks/data/useGeniusBenefits';

import EmagGeniusWrapper, { EMAG_GENIUS_ROUTE_NAMES } from '../EmagGenius/EmagGenius';

import styles from './GeniusFastCheckoutHost.module.scss';

interface Props {
  className?: string,
  //
  onCancel?: () => void,
  onPending?: () => void,
  onSuccess?: () => void,
  onError?: () => void
}

const GeniusFastCheckoutHost = (props: Props) => {

  const {
    className,
    //
    onCancel,
    onPending,
    onSuccess,
    onError,
  } = props;

  // Hooks

  const { t } = useTranslation();

  const [key, setKey] = React.useState(0);

  // Data Hooks

  const {
    data, isLoading, isError, refresh
  } = useGeniusBenefits();

  // Effects

  React.useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  // Handlers

  const handlePageShow = (event: PageTransitionEvent) => {
    if (event.persisted) {
      setKey((k) => k + 1);
    }
  };

  const handleSuccess = () => {
    // Trigger Sylius to refresh genius status
    // and invalidate existing queries that depend on genius status
    refresh({
      shouldInvalidate: true
    });

    if (onSuccess) {
      onSuccess();
    }
  };

  const handlePending = () => {
    // Trigger Sylius to refresh genius status
    refresh();

    if (onPending) {
      onPending();
    }
  };

  // Error

  if (isError) {
    return (
      <div className={classnames(styles.root, className)}>
        <Error />
      </div>
    );
  }

  // Loading

  if (isLoading) {
    return (
      <div className={classnames(styles.root, className)}>
        <Loader />
      </div>
    );
  }

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <EmagGeniusWrapper
        key={key}
        //
        id="emag-genius-fast-checkout-host"
        //
        routename={EMAG_GENIUS_ROUTE_NAMES.FAST_CHECKOUT}
        fastCheckoutDiscount={data?.discount}
        fastCheckoutSlots={{
          slot3: t('GENIUS.FAST_CHECKOUT.MODAL_CLOSE_BTN')
        }}
        //
        onFastCheckoutCancel={onCancel}
        onFastCheckoutPending={handlePending}
        onFastCheckoutSuccess={handleSuccess}
        onFastCheckoutError={onError}
      />
    </div>
  );
};

export default GeniusFastCheckoutHost;
