import React from 'react';

import { Icon, Link } from 'components/ui';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';

import type { IProductDetails } from 'types';

import styles from './ProductDetailsSameBrandNavigation.module.scss';

interface Props {
  product: IProductDetails
}

const ProductDetailsSameBrandNavigation = (props: Props) => {

  const {
    product
  } = props;

  const {
    brand,
    brandFilterCode
  } = product || {};

  // Hooks

  const { t } = useTranslation();

  // Empty

  if (!brand || !brandFilterCode) return null;

  // Props

  const brandHref = ProductHelpers.getSameBrandLink(brand, brandFilterCode);

  // Render

  return (
    <Link href={brandHref}>
      <a className={styles.root}>

        {/* Label */}
        <span className={styles.label}>{`${t('PRODUCT.DETAILS_SAME_BRAND')}:`}</span>

        {/* Brand */}
        <div className={styles.brandLink}>{brand}</div>

        {/* Icon */}
        <Icon className={styles.icon} name="chevron-right" size={20} />

      </a>
    </Link>
  );
};

export default ProductDetailsSameBrandNavigation;
