export const QUERY_KEYS = {
  ACCELERATED_SALE_PRODUCTS: 'accelerated-sale-products',
  ACCOUNT_BENEFITS_MODAL: 'account-benefits-modal',
  ACCOUNT_BENEFITS: 'account-benefits',
  ACCOUNT_NEW_FEATURE_NOTIFICATIONS: 'account-new-feature-notifications',
  ACCOUNT_NEW_FEATURE: 'account-new-feature',
  ACCOUNT_PROMOTIONS: 'account-promotions',
  ACTIVE_ORDER: 'active-order',
  ADD_TO_ORDER: 'add-to-order',
  ADDRESSES: 'addresses',
  BILLING_FORM_OPTIONS: 'billing-form-options',
  BILLING: 'billing',
  BLOG_LANDING_PAGE: 'blog-landing-page',
  CAREERS_PAGE: 'careers-page',
  CATEGORIES: 'categories',
  CATEGORY: 'category',
  CHECKOUT_PAGE: 'checkout-page',
  CITIES_BY_PROVINCE: 'cities-by-province',
  CONFIG: 'config',
  CONSENT_COOKIE_DEFINITIONS: 'consent-cookies-definitions',
  CONSENT: 'consent',
  CONTACT_PAGE: 'contact-page',
  CUSTOM_LISTING: 'custom-listing',
  FAVORITE_PRODUCT_IDS: 'favorite_product_ids',
  FAVORITES: 'favorites',
  GOOD_DEEDS_LANDING_PAGE: 'good-deeds-landing-page',
  GOOD_DEEDS_POST: 'good-deeds-post',
  HOMEPAGE_PERSONALIZED: 'homepage-personalized',
  HOMEPAGE: 'homepage',
  INTERVALS_BY_ADDRESS: 'intervals-by-address',
  INTERVALS_BY_ORDER: 'intervals-by-order',
  LOYALTY_HISTORY: 'loyalty_history',
  LOYALTY_PAGE: 'loyalty-page',
  MARKDOWN_PARSER: 'markdown-parser',
  MEAL_VOUCHERS: 'meal-vouchers',
  NEW_PRODUCTS: 'new-products',
  NEXT_DELIVERY_INTERVAL: 'delivery-interval',
  ONBOARDING_PRODUCTS: 'onboarding-products',
  ORDER_BENEFITS: 'order-benefits',
  ORDER_CANCELLATION_REASONS: 'order-cancellation-reasons',
  ORDER_FEEDBACK_REMINDERS: 'order-feedback-reminders',
  ORDER_GIFTS: 'order-gifts',
  ORDER_LOYALTY: 'order-loyalty',
  ORDER_POLL: 'order-poll',
  ORDER_PROMOTIONS: 'promotions-by-order',
  ORDER: 'order',
  ORDERS: 'orders',
  PAYMENT_METHODS: 'payment-methods',
  PLACE_DETAILS_BY_GEOLOCATION: 'place-details-by-geolocation',
  PLACE_DETAILS: 'place-details',
  PLACES_SUGGESTIONS: 'places-suggestions',
  PRODUCT_QUANTITY_VARIANTS: 'product-quantity-variants',
  PRODUCT: 'product',
  PRODUCTS_BY_SKU: 'products-by-sku',
  PROMOTION: 'promotion',
  PROMOTIONS: 'promotions',
  RECIPE: 'recipe',
  RECIPES_BY_TAG: 'recipes-by-tag',
  RECIPES_DETAILS: 'recipes-details',
  RECIPES_INGREDIENTS_LISTING: 'recipes-ingredients-listing',
  RECIPES_INGREDIENTS: 'recipe-ingredients',
  RECIPES_LANDING_PAGE: 'recipes-landing-page',
  RECIPES: 'recipes',
  RECOMMENDATIONS: 'recommendations',
  SAME_BRAND_PRODUCTS: 'same-brand-products',
  SEARCH_PRODUCTS: 'search-products',
  SEARCH_SUGGESTIONS: 'search-suggestions',
  STATIC_PAGE: 'static-page',
  USER_CONFIG: 'user-config',
  USER_FORCE: 'user-force',
  USER: 'user',
  USUAL_PRODUCTS: 'usual-products',
  VOUCHER: 'voucher',
  VOUCHERS: 'vouchers',
} as const;

export const MUTATION_KEYS = {
  ACCOUNT: 'account',
  ACTIVE_ORDER: 'active-order',
  ADDRESS: 'address',
  BILLING: 'billing',
  CONTACT: 'contact',
  FAVORITES: 'favorites',
  MEAL_VOUCHERS: 'meal-vouchers',
  ORDER_FEEDBACK: 'order-feedback',
  ORDER_GIFTS: 'order-gifts',
  ORDER_PRODUCT: 'order-product',
  SEARCH_PRODUCTS: 'search-products',
  USER: 'user',
  VOUCHERS: 'vouchers',
} as const;
