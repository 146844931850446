import { IMAGES } from 'constants/images';

import { DISCOUNT_PRICE_COLOR, INCREDIBLE_DISCOUNTS_PRICE_COLOR } from './ProductHelpersConstants';

import type { IOrderProduct, IProductQuantityVariant, IProductVariant } from 'types';

// Types

export interface Params {
  shouldShowGeniusPrice: boolean
}

export function getProductPriceProps(product: IProductVariant, params: Params) {

  // Product

  const {
    //
    originalPrice,
    price,
    unitPriceLabel,
    //
    discountLabel,
    //
    priceLabel,
    priceLabelColor,
    //
    priceColor,
    priceIcon,
    priceTooltip,
    //
    geniusPrice,
    geniusUnitPricePerLabel,
    geniusDiscountLabel,
    //
    promotionalPrice,
    //
  } = product || {};

  // Params

  const {
    shouldShowGeniusPrice,
  } = params || {};

  // Genius Props

  if (shouldShowGeniusPrice && !!geniusPrice) {
    return {
      hasGeniusPrice: true,
      //
      originalPriceStrikethrough: true,
      originalPrice: originalPrice || price,
      price: geniusPrice,
      //
      unitPriceLabel: geniusUnitPricePerLabel || unitPriceLabel,
      discountLabel: geniusDiscountLabel,
      //
      priceColor: DISCOUNT_PRICE_COLOR,
      priceIcon: IMAGES.BRANDING.GENIUS_ICON,
      priceTooltip,
    };
  }

  if (promotionalPrice) {
    return {
      hasGeniusPrice: false,
      //
      originalPriceStrikethrough: false,
      originalPrice: price,
      price: promotionalPrice,
      //
      unitPriceLabel,
      discountLabel,
      //
      priceColor: INCREDIBLE_DISCOUNTS_PRICE_COLOR,
      priceTooltip,
    };
  }

  // Default Props

  return {
    hasGeniusPrice: false,
    //
    originalPriceStrikethrough: true,
    originalPrice,
    price,
    //
    unitPriceLabel,
    discountLabel,
    //
    priceColor: discountLabel ? DISCOUNT_PRICE_COLOR : priceColor,
    priceIcon,
    priceTooltip,
    //
    priceLabel,
    priceLabelColor,
  };
}

export function enhanceOrderItem(orderItem: IOrderProduct, params: Params) {

  // Order Item

  const {
    hasGeniusDiscount,
    subtotal: price,
    originalSubtotal: originalPrice,
  } = orderItem || {};

  // Params

  const {
    shouldShowGeniusPrice,
  } = params || {};

  const hasDiscount = (originalPrice && price !== originalPrice);

  // Genius Discount - Price Color / Icon

  if (shouldShowGeniusPrice && hasGeniusDiscount) {
    orderItem.priceColor = DISCOUNT_PRICE_COLOR;
    orderItem.priceIcon = IMAGES.BRANDING.GENIUS_ICON;
    return orderItem;
  }

  // Generic Discount - Price Color

  if (hasDiscount) {
    orderItem.priceColor = DISCOUNT_PRICE_COLOR;
    return orderItem;
  }

  // Default

  return orderItem;
}

export function findItemByQuantity(products: IProductQuantityVariant[] | undefined, targetQuantity: number) {
  if (!products) return;
  return products.find((product) => product.quantity === targetQuantity);
}
