import classnames from 'classnames';

import styles from './Label.module.scss';

// Types

interface Props {
  className?: string,
  label: string,
  required?: boolean,
  hasError?: boolean | string,
  htmlFor?: string
}

const Label = (props: Props) => {
  const {
    className,
    label,
    required,
    hasError,
    ...rest
  } = props;

  const classes = classnames(
    styles.label,
    className,
    {
      [styles.hasError]: hasError,
      [styles.required]: required
    }
  );

  return (
    <label className={classes} {...rest}>
      {label}
      {required && <span className={styles.requiredMarker}>*</span>}
    </label>
  );
};

export default Label;
