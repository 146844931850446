import { Api, SecureApi } from 'common/http';
import QueryString from 'common/QueryString';

import type { IRecommendations, RecommendationLocation, RecommendationType } from 'types';

interface Params {
  isAuthenticated?: boolean,
  tokenValue?: string,
  forceFallback?: boolean
}

export async function getRecommendations(
  location: RecommendationLocation,
  type: RecommendationType,
  params: Params
) {
  const {
    isAuthenticated,
    tokenValue,
    forceFallback,
  } = params || {};

  const qs = QueryString.stringify(
    {
      ab: type,
      force_fallback: forceFallback || null
    },
    { skipNulls: true }
  );

  const url = `/recommend/${location}${qs ? `?${qs}` : ''}`;

  const config = {
    ...(tokenValue ? { headers: { 'X-Freshful-CartToken': tokenValue } } : {})
  };

  if (isAuthenticated) {
    const { data } = await SecureApi.get<IRecommendations>(url, config);
    return data;
  }

  const { data } = await Api.get<IRecommendations>(url, config);
  return data;
}
