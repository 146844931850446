/**
 * Capitalize first letter of string
 * i.e. "lorem ipsum" => "Lorem ipsum"
 * @param {string} string
 * @returns string
 */
export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Remove diacritics, replacing them with corresponding characters
 * i.e. "adresă" => "adresa"
 * @param {string} string
 * @returns string
 */
export const replaceDiacritics = (string: string): string => {
  if (!string) return '';

  let result = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const c of string) {
    const nc = diacriticsReplace[c] || c;
    result += nc;
  }

  return result;
};

/**
 * Join an array of strings, excluding empty values
 * i.e. ["Bucuresti", "Sector 1", "", "blv. Unirii", "100"] => "Bucuresti, Sector 1, blv. Unirii, 100"
 * @param {string[]} args
 * @param {string} separator
 * @returns string
 */
export function joinValues(args: string[], separator: string = ', '): string {
  return args?.filter((a) => a && a.trim && a.trim() !== '').join(separator);
}

/* eslint-disable no-bitwise */
export function hashString(s: string = ''): number {
  let h = 0;
  let i;
  let chr;

  if (s.length === 0) return h;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);

    h = ((h << 5) - h) + chr;
    h |= 0;
  }
  return h;
}
/* eslint-enable no-bitwise */

// Constants

const diacriticsReplace: { [key: string]: string } = {
  ă: 'a',
  Ă: 'A',
  â: 'a',
  Â: 'A',
  î: 'i',
  Î: 'I',
  ș: 's',
  Ș: 'S',
  ț: 't',
  Ț: 'T'
};
