import React from 'react';

export const useFade = (initial?: boolean) => {

  const [show, setShow] = React.useState(Boolean(initial));
  const [isVisible, setVisible] = React.useState(show);

  // Update visibility when show changes
  React.useEffect(() => {
    if (show) {
      setVisible(true);
    }
  }, [show]);

  // When the animation finishes, set visibility to false
  const onAnimationEnd = () => {
    if (!show) {
      setVisible(false);
    }
  };

  const style = { animation: `${show ? 'useFade-fadeIn' : 'useFade-fadeOut'} .2s ease` };

  // These props go on the fading DOM element
  const fadeProps = {
    style,
    onAnimationEnd
  };

  return [isVisible, setShow, fadeProps];
};
