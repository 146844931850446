import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { objectClean, objectMap } from 'helpers/ObjectHelpers';
import { convertStrapiResponsiveSeoImage } from 'helpers/RecipeHelpers/generic';

import type {
  IRecipeData,
  IRecipeDataBannerImage,
  IRecipeDataCookingSteps,
  IRecipeDataIngredientsListing,
  IRecipeDataLabel,
  IRecipeDataPrimaryIngredient,
  IRecipeDataPrimaryIngredients,
  IRecipeDataSeo,
  IRecipeDataTags,
  IRecipeDataTextIngredient,
  IRecipeDataTextIngredients,
  IRecipeIngredients,
  IRecipeIngredientsListing,
  IRecipeInstructions,
  IRecipeLabel,
  IRecipePrimaryIngredient,
  IRecipePrimaryIngredients,
  IRecipeSeo,
  IRecipeStat,
  IRecipeStats,
  IRecipeTags,
  IRecipeTextIngredient,
  IRecipeTextIngredients,
  IRecipeTips,
  MarkdownSerializeFunction,
  Nullable,
  RecipeStat,
} from 'types';

// Ingredients

export function convertRecipeIngredients(recipe: IRecipeData): IRecipeIngredients {
  const {
    ingredients: primaryIngredients,
    ingredientsSecondary: secondaryIngredients,
    ingredientsExtra: extraIngredients,
    ingredientsText: textIngredients,
  } = recipe || {};

  return {
    primary: convertRecipePrimaryIngredients(primaryIngredients),
    secondary: convertRecipeIngredientsListing(secondaryIngredients),
    extra: convertRecipeIngredientsListing(extraIngredients),
    text: convertRecipeTextIngredients(textIngredients),
  };
}

// --- Primary

function convertRecipePrimaryIngredients(primaryIngredients: IRecipeDataPrimaryIngredients): IRecipePrimaryIngredients {
  if (isArrayEmpty(primaryIngredients)) return [];
  return primaryIngredients.map(convertRecipePrimaryIngredient);
}

export function convertRecipePrimaryIngredient(
  primaryIngredient: IRecipeDataPrimaryIngredient
): IRecipePrimaryIngredient {
  const {
    id,
    quantity,
    sku,
    backups
  } = primaryIngredient || {};

  return {
    id,
    quantity,
    sku,
    ...(backups && !isArrayEmpty(backups) && { backups: backups.map(convertRecipePrimaryIngredient) })
  };
}

// --- Secondary / Extra

function convertRecipeIngredientsListing(ingredientsListing: IRecipeDataIngredientsListing): Nullable<IRecipeIngredientsListing> {
  const {
    slug,
    title,
  } = ingredientsListing || {};

  if (!slug) return null;

  return {
    slug,
    title,
  };
}

// --- Text

function convertRecipeTextIngredients(textIngredients: IRecipeDataTextIngredients): IRecipeTextIngredients {
  if (isArrayEmpty(textIngredients)) return [];
  return textIngredients.map(convertRecipeTextIngredient);
}

function convertRecipeTextIngredient(ingredient: IRecipeDataTextIngredient): IRecipeTextIngredient {
  const {
    ingredient: text
  } = ingredient;
  return {
    text
  };
}

// Label

export function convertRecipeLabel(label: IRecipeDataLabel): IRecipeLabel {
  const {
    text = null,
    textColor = null,
    backgroundColor = null,
  } = label || {};

  return {
    text,
    textColor,
    backgroundColor,
  };
}

// Stats

export function convertRecipeStats(stats: Record<RecipeStat, any>): IRecipeStats {
  const cleanedStats = objectClean(stats);

  const mappedStats = objectMap(cleanedStats, (value: any, key: string) => {
    const recipeStatKey = key as RecipeStat;
    return {
      key: recipeStatKey,
      value
    };
  });

  return {
    calories: validateStat(mappedStats.calories, 'calories'),
    preparationTime: validateStat(mappedStats.preparationTime, 'preparationTime'),
    cookingTime: validateStat(mappedStats.cookingTime, 'cookingTime'),
    totalTime: validateStat(mappedStats.totalTime, 'totalTime'),
    servings: validateStat(mappedStats.servings, 'servings'),
    pricePerServing: validateStat(mappedStats.pricePerServing, 'pricePerServing'),
    originalPricePerServing: validateStat(mappedStats.originalPricePerServing, 'originalPricePerServing')
  } as IRecipeStats;
}

// Helper function to validate the existence and type of a stat
function validateStat(stat: any, key: RecipeStat): IRecipeStat<RecipeStat, number> {
  if (stat && stat.value !== undefined) {
    return stat as IRecipeStat<RecipeStat, any>;
  }

  return {
    key,
    value: 0 // Default value
  };
}

// Steps

export function convertRecipeInstructions(cookingSteps: IRecipeDataCookingSteps): IRecipeInstructions {
  const {
    title = null,
    steps = [],
  } = cookingSteps || {};

  return {
    title,
    steps: steps?.map(({ step }) => step)
  };
}

// Tips

export async function convertRecipeTips(
  cookingSteps: IRecipeDataCookingSteps,
  markdownSerializeFn: MarkdownSerializeFunction | any = (s: string) => s
): Promise<IRecipeTips> {
  const {
    tips = null
  } = cookingSteps || {};

  if (!tips) {
    return null;
  }

  const text = await markdownSerializeFn(tips);

  return {
    text
  };
}

// Tags

export function convertRecipeTags(tags: IRecipeDataTags): IRecipeTags {
  if (isArrayEmpty(tags)) return [];

  return (
    tags
      .map((tag) => {
        const { id, slug, label } = tag || {};

        if (!slug) return null;

        return {
          id,
          slug,
          label
        };
      })
      .filter((t) => !!t)
  );
}

// SEO

export function convertRecipeSeo(seo: IRecipeDataSeo, banner: IRecipeDataBannerImage): IRecipeSeo {
  const {
    title = null,
    description = null
  } = seo || {};

  return {
    title,
    description,
    images: convertStrapiResponsiveSeoImage(banner)
  };
}
