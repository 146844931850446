import React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { Responsive } from 'components/ui';
import { useIsClient } from 'hooks/useIsClient';

import AnnouncementBanner from '../AnnouncementBanner/AnnouncementBanner';

// to be applied for sideEffects: false to prevent tree shaking
// import HeaderDesktop from './HeaderDesktop/HeaderDesktop';
// import HeaderMobile from './HeaderMobile/HeaderMobile';

import styles from './Header.module.scss';

const HeaderDesktop = dynamic(() => import('./HeaderDesktop/HeaderDesktop'), { ssr: false });
const HeaderMobile = dynamic(() => import('./HeaderMobile/HeaderMobile'), { ssr: false });

interface Props {
  //
  withCategoriesBar?: boolean,
  hideCart?: boolean,
  //
  hideCartOnLargeScreens?: boolean,
  hideDeliveryDetails?: boolean,
  //
  hasInlineSearchBar?: boolean
}

const Header = (props: Props) => {

  const {
    withCategoriesBar,
    hideCart,
    //
    hideCartOnLargeScreens,
    hideDeliveryDetails,
    //
    hasInlineSearchBar,
  } = props;

  // Hooks

  const isClient = useIsClient();

  // Detect pinned header

  const { observe, inView: isAnchorInView } = useInView({
    threshold: [0]
  });

  // Render

  return (
    <>

      {/* Announcement Banner */}
      <AnnouncementBanner />

      {/* Header */}
      <header
        id="header"
        className={
          classnames(
            styles.header,
            { [styles.rendered]: isClient },
            { [styles.hasCategoriesBar]: withCategoriesBar }
          )
        }
      >
        <Responsive
          breakpoint="lg"
          mobile={(
            <HeaderMobile
              isPinned={isClient && !isAnchorInView}
              hideCart={hideCart}
              hasInlineSearchBar={hasInlineSearchBar}
            />
          )}
          desktop={(
            <HeaderDesktop
              hideCart={hideCart}
              withCategoriesBar={withCategoriesBar}
              hideDeliveryDetails={hideDeliveryDetails}
              hideCartOnLargeScreens={hideCartOnLargeScreens}
            />
          )}
        />
      </header>

      {/* Pin Anchor */}
      <div
        ref={observe}
        className={classnames(
          styles.anchor,
          { [styles.pinned]: isAnchorInView }
        )}
      />

      {/* Shadow */}
      <div
        className={classnames(
          styles.shadow,
          { [styles.hasCategoriesBar]: withCategoriesBar }
        )}
      />
    </>
  );
};

export default Header;
