import type React from 'react';

import * as Notifications from 'common/Notification';

import { DEFAULT_ERROR_MESSAGE, MAX_QUANTITY_REACHED_TOAST_ID } from './ProductHelpersConstants';

import type {
  IOrderProduct, IProduct, IProductQuantityLimits, IProductVariant
} from 'types';

// Types
type ErrorsMap = Record<string, string>

// Quantity

export function getMaxAllowedQuantity(
  limits: IProductQuantityLimits,
  errors?: ErrorsMap
): [number, string] {
  const {
    maxAvailableQuantity = Infinity,
    maxAllowedQuantity = Infinity
  } = limits || {};

  const value = Math.min(maxAllowedQuantity, maxAvailableQuantity);

  const errorKey = maxAvailableQuantity < maxAllowedQuantity
    ? 'CART_ADD_MAX_AVAILABLE_QUANTITY'
    : 'CART_ADD_MAX_ALLOWED_QUANTITY';

  const errorMessage = (errors || {})[errorKey] || DEFAULT_ERROR_MESSAGE;

  return [value, errorMessage];
}

export function checkMaxQuantityReached(
  oldQuantity: number,
  newQuantity: number,
  product: IProduct | IOrderProduct | IProductVariant,
  errors?: ErrorsMap,
  payload?: any
): boolean {
  const { analytics } = payload || {};
  const { variantCode } = product || {};

  const [maxQuantity, errorMessage] = getMaxAllowedQuantity(product, errors);
  const isMaximumReached = (newQuantity === maxQuantity);

  if (oldQuantity < newQuantity && isMaximumReached) {
    const toastId = `${MAX_QUANTITY_REACHED_TOAST_ID}-${variantCode}`;

    Notifications.showError(errorMessage, {
      toastId,
      autoClose: 2000
    });

    if (analytics) {
      analytics.viewCartError(errorMessage);
    }
  }

  return isMaximumReached;
}

export function handleChangeQuantityClick(
  oldQuantity: number,
  newQuantity: number,
  handleRemove: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  handleEdit: (previousQuantity: number, currentQuantity: number) => void
) {
  if (newQuantity !== oldQuantity) {
    if (newQuantity === 0) {
      handleRemove();
    } else {
      handleEdit(oldQuantity, newQuantity);
    }
  }
}
