import React from 'react';
import { useRouter } from 'next/router';

import { APP_ROUTES } from 'constants/routes';
import { useAuthStore, useAuthStoreDispatch } from 'context/AuthenticationContext';

interface Props {
  options: {
    error?: any,
    code?: string,
    state?: string | object
  }
}

const AuthRedirect = (props: Props) => {
  const {
    options
  } = props;

  const {
    code,
    state,
    error
  } = options || {};

  // Hooks

  const router = useRouter();
  const { isReady } = router || {};

  const { accessToken } = useAuthStore();
  const { login } = useAuthStoreDispatch();

  // Handlers

  const handlePageShow = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.href = APP_ROUTES.HOME;
    }
  };

  // Effects

  React.useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  // Effects

  React.useEffect(() => {
    if (!isReady) return;

    if (error) {
      router.push({
        pathname: APP_ROUTES.ERROR500,
        query: { initial: true, reason: 'permissions_denied' }
      });
    }

    if (state && code && !accessToken) {
      login(code, state);
    } else {
      router.push(APP_ROUTES.HOME);
    }
  }, [isReady, state, code, error]);

  // Render

  return null;
};

export default AuthRedirect;
