import React from 'react';
import classnames from 'classnames';

import {
  Error, Image, Select, Skeleton
} from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import AccountBenefitsDynamicContent from '../AccountBenefitsDynamicContent/AccountBenefitsDynamicContent';
import AccountBenefitsList from '../AccountBenefitsList/AccountBenefitsList';

import type { SingleValue } from 'components/ui/Select/Select';

import type { IAccountBenefits, ISelectOption } from 'types';

import styles from './AccountBenefits.module.scss';

interface Props {
  className?: string,
  //
  isLoading?: boolean,
  isError?: boolean,
  isInline?: boolean,
  //
  benefits: IAccountBenefits,
  //
  selectedFilterOption?: ISelectOption,
  onFilterOptionChange?: (newValue: SingleValue<ISelectOption>) => void
}

const AccountBenefits = (props: Props) => {

  const {
    className,
    //
    isLoading,
    isError,
    isInline,
    //
    benefits,
    //
    selectedFilterOption,
    onFilterOptionChange
  } = props;

  // Props

  const {
    meta,
    //
    title,
    image,
    //
    monthlyBenefits,
    lifetimeBenefits,
    //
    additionalInfo,
  } = benefits || {};

  const {
    filter
  } = meta || {};

  const {
    code: filterCode,
    options: filterOptions,
  } = filter || {};

  // Props

  const shouldShowSecondarySection = !!lifetimeBenefits?.list?.length;
  const showShowTertiarySection = !!additionalInfo?.length;
  const shouldShowFilter = !!onFilterOptionChange && !isArrayEmpty(filterOptions);

  // Handlers

  const handleChange = (newValue: SingleValue<ISelectOption>) => {
    if (onFilterOptionChange) {
      onFilterOptionChange(newValue);
    }
  };

  // Loading

  if (isLoading && !benefits) {
    return (
      <div
        className={classnames(
          styles.root,
          styles.loading,
          { [styles.inline]: isInline },
          className
        )}
      >

        <div className={styles.primary}>
          <div className={styles.content}>

            {/* Header */}
            <div className={styles.header}>
              <div className={styles.title}>
                <Skeleton
                  containerClassName={styles.skeletonContainer}
                  className={styles.skeleton}
                />
              </div>
            </div>

            {/* Main Benefit */}
            <div className={styles.mainBenefit}>
              <Skeleton
                containerClassName={styles.skeletonContainer}
                className={styles.skeleton}
                count={5}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Error

  if (isError) {
    return (
      <div className={classnames(styles.root, className)}>
        <Error />
      </div>
    );
  }

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.inline]: isInline },
        className
      )}
    >

      {/* Header */}
      <div className={styles.header}>

        {/* Title */}
        <div className={styles.title}>
          {title}
        </div>

        {/* Filter */}
        {
          shouldShowFilter && (
            <Select
              className={styles.select}
              controlClassName={styles.control}
              menuClassName={styles.menu}
              menuListClassName={styles.menuList}
              errorClassName={styles.error}
              //
              name={filterCode}
              options={filterOptions}
              value={selectedFilterOption}
              onChange={handleChange}
            />
          )
        }
      </div>

      {/* Primary */}
      <div className={styles.primary}>

        {/* Content */}
        <div className={styles.content}>

          {/* Main Benefit */}
          <AccountBenefitsDynamicContent
            className={styles.mainBenefit}
            lines={monthlyBenefits?.main}
            isCentered={!isInline}
          />

          {/* Image - Responsive */}
          {
            image && (
              <div className={classnames(styles.image, { [styles.hidden]: isInline })}>
                <Image src={image} fill />
              </div>
            )
          }

          {/* Secondary Benefits */}
          <AccountBenefitsList
            className={styles.secondaryBenefits}
            list={monthlyBenefits?.secondary?.list}
            isCentered={!isInline}
          />

        </div>

        {/* Image - Desktop */}
        {
          image && (
            <div className={classnames(styles.image, { [styles.hidden]: !isInline })}>
              <Image src={image} fill />
            </div>
          )
        }

      </div>

      {/* Secondary */}
      {
        shouldShowSecondarySection && (
          <div className={styles.secondary}>

            {/* Lifetime Benefits */}
            <AccountBenefitsList
              className={styles.lifetimeBenefits}
              listClassName={styles.list}
              itemClassName={styles.item}
              //
              title={lifetimeBenefits?.title}
              description={lifetimeBenefits?.description}
              list={lifetimeBenefits?.list}
              //
              isCentered={!isInline}
            />

          </div>
        )
      }

      {/* Tertiary */}
      {
        showShowTertiarySection && (
          <div className={styles.tertiary}>

            {/* Additional Info */}
            <AccountBenefitsDynamicContent
              className={styles.mainBenefit}
              lines={additionalInfo}
              isCentered={!isInline}
            />

          </div>
        )
      }

    </div>
  );
};

export default AccountBenefits;
