import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getPromotionsByOrder } from 'services/PromotionsService';

import type {
  IPromotionsByOrderResponse,
  ReactQueryOptions
} from 'types';

export const usePromotionsByOrder = (tokenValue: string, queryOptions: ReactQueryOptions<IPromotionsByOrderResponse> = {}) => {
  return useQuery({
    queryKey: getKey(tokenValue),
    queryFn: () => getPromotionsByOrder(tokenValue),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...queryOptions
  });
};

export const getKey = (tokenValue: string): string[] => {
  return [
    QUERY_KEYS.ORDER_PROMOTIONS,
    tokenValue
  ];
};
