import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './ProductThumbnails.module.scss';

interface Props{
  className?: string,
  thumbnailClassName?: string,
  desktopMaxCount?: number,
  responsiveMaxCount?: number
}

const ProductThumbnailsSkeleton = (props: Props) => {
  const {
    className,
    thumbnailClassName,
    desktopMaxCount = 4,
    responsiveMaxCount = 3
  } = props;

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const count = isBreakpoint
    ? responsiveMaxCount
    : desktopMaxCount;

  return (
    <div className={classnames(styles.root, className)}>
      {
        Array.from(Array(count + 1)).map((_, index) => (
          <div className={classnames(styles.thumbnail, styles.skeleton, thumbnailClassName)} key={index}>
            <Skeleton className={styles.image} />
          </div>
        ))
      }
    </div>
  );
};

// Prop Types

ProductThumbnailsSkeleton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  thumbnailClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  desktopMaxCount: PropTypes.number,
  responsiveMaxCount: PropTypes.number,
};

// Export

export default ProductThumbnailsSkeleton;
