/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import type { ImageLoader, ImageLoaderProps, ImageProps as NextImageProps } from 'next/image';
import NextImage from 'next/image';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';
import { isIE } from 'helpers/BrowserHelpers';

import Skeleton from '../Skeleton/Skeleton';

import type { IImageSrc } from 'types';

import styles from './Image.module.scss';

const Loader: ImageLoader = ({ src }: ImageLoaderProps) => src;

export interface ImageProps extends Omit<NextImageProps, 'src' | 'alt'> {
  //
  className?: string,
  skeletonClassName?: string,
  //
  src?: IImageSrc,
  square?: boolean,
  next?: boolean,
  optimize?: boolean,
  alt?: string,
  //
  loader?: (arg: { src: string }) => string
}

const Image = (props: ImageProps) => {
  const {
    //
    className,
    skeletonClassName,
    //
    src,
    square,
    next = true,
    optimize = false,
    alt = 'image', // TODO: remove after Image instances have been migrated to include required alt props
    //
    loader = Loader,
    //
    ...rest
  } = props;

  // Refs

  const ref = React.useRef<any>();

  // Hooks

  const [isLoaded, setLoaded] = React.useState<boolean>();
  const [isError, setError] = React.useState<boolean>();

  // Effects

  React.useEffect(() => {
    if (ref.current?.complete) {
      setLoaded(true);
    }

  }, []);

  // Props

  const sharedProps = {
    className: classnames(
      styles.image,
      { [styles.hidden]: !isLoaded || isError },
      className
    ),
    src: getSource(src),
    onError: () => {
      setError(true);
    },
    onLoad: (e: any) => {
      const { target } = e;
      if (target.complete && target.style.visibility !== 'hidden') {
        setLoaded(true);
      }
    },
    ...rest
  };

  // Error

  if (isError) {
    return (
      <img
        ref={ref}
        className={classnames(styles.error, className)}
        width={180}
        height={180}
        src={PLACEHOLDER_PATH}
      />
    );
  }

  // Render

  return (
    <>
      {
        !next && !isLoaded && (
          <div
            className={classnames(styles.skeletonContainer, skeletonClassName)}
            style={
              skeletonClassName
                ? {}
                : { height: '100%' }
            }
          >
            <Skeleton
              className={styles.skeleton}
              height="100%"
            />
            {
              square && (
                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" width="100%" />
              )
            }
          </div>
        )
      }
      {
        next
          ? (
            <>
              {
                optimize
                  ? (
                    <NextImage
                      alt={alt}
                      {...sharedProps}
                    />
                  ) : (
                    <NextImage
                      unoptimized
                      loader={loader}
                      alt={alt}
                      {...sharedProps}
                    />
                  )
              }
            </>
          )
          : (
            <img
              ref={ref}
              {...sharedProps}
            />
          )
      }
    </>

  );
};

// Constants

const PLACEHOLDER_PATH = IMAGES.PLACEHOLDERS.GENERIC;

// Helpers

const getSource = (src?: IImageSrc): string => {
  if (!src) {
    return PLACEHOLDER_PATH;
  }

  if (typeof src === 'string') {
    return src;
  }

  const { webp, default: defaultSrc } = src;

  if (webp && !isIE) {
    return webp;
  }

  return defaultSrc || PLACEHOLDER_PATH;
};

// Export

export default Image;
