import classnames from 'classnames';

import { ProductDefaultCardSkeleton } from '../cards';

import styles from './ProductListSkeleton.module.scss';

interface Props {
  className?: string,
  count?: number,
  wide?: boolean
}

const ProductListSkeleton = (props: Props) => {
  const { className, count = 6, wide } = props;
  const list = new Array(count).fill(0);
  return (
    <div className={classnames(styles.root, className, { [styles.wide]: wide })}>
      {list.map((skeleton, index) => {
        return (
          <div key={index} className={styles.itemWrapper}>
            <ProductDefaultCardSkeleton />
          </div>
        );
      })}
    </div>
  );
};

export default ProductListSkeleton;
