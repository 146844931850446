import React from 'react';
import classnames from 'classnames';

import { Error } from 'components/ui';

import styles from './CartError.module.scss';

interface Props {
  className?: string
}

const CartError = (props: Props) => {

  const { className } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <Error
        className={styles.error}
        imageContainerClassName={styles.image}
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
        small
      />
    </div>
  );
};

export default CartError;
