import type { CSSProperties } from 'react';
import React from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import { useResize } from 'hooks/useResize';

import styles from './Collapsible.module.scss';

interface Props {
  className?: string,
  isExpanded?: boolean,
  enterDuration?: number,
  exitDuration?: number,
  contentEnterDuration?: number,
  contentExitDuration?: number,
  children?: React.ReactNode
}

const Collapsible = (props: Props) => {

  const {
    className,
    isExpanded,
    enterDuration = 200,
    exitDuration = 200,
    contentEnterDuration = 200,
    contentExitDuration = 200,
    children,
  } = props;

  // Refs

  const transitionRef = React.useRef(null);

  const { ref: resizeRef, height } = useResize({ handleWidth: false });

  // Hooks

  const [isOpen, setIsOpen] = React.useState(false);
  const [content, setContent] = React.useState(null);

  // Effects

  React.useEffect(() => {
    if (isExpanded) {
      setContent(children);
    }
  }, [
    isExpanded,
    children,
  ]);

  React.useEffect(() => {
    setIsOpen(isExpanded);
  }, [isExpanded]);

  const enterDurationInSeconds = enterDuration / 1000;
  const exitDurationInSeconds = exitDuration / 1000;

  const contentEnterDurationInSeconds = contentEnterDuration / 1000;
  const contentExitDurationInSeconds = contentExitDuration / 1000;

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <Transition
        in={isOpen}
        nodeRef={transitionRef}
        timeout={{
          enter: enterDuration + contentEnterDuration,
          exit: exitDuration + contentExitDuration,
        }}
      >
        {
          (state) => {
            return (
              <div
                ref={transitionRef}
                className={classnames(styles.wrapper, { [styles[state]]: state })}
                style={{
                  height: isOpen ? height : 0,
                  '--enterDuration': `${enterDurationInSeconds}s`,
                  '--exitDuration': `${exitDurationInSeconds}s`,
                  '--enterDelayDuration': '0s',
                  '--exitDelayDuration': `${contentExitDurationInSeconds}s`,
                } as CSSProperties}
              >
                <div
                  ref={resizeRef}
                  className={classnames(styles.content, { [styles[state]]: state })}
                  style={{
                    '--enterDuration': `${contentEnterDurationInSeconds}s`,
                    '--exitDuration': `${contentExitDurationInSeconds}s`,
                    '--enterDelayDuration': `${enterDurationInSeconds}s`,
                    '--exitDelayDuration': '0s'
                  } as CSSProperties}
                >
                  {
                    isExpanded
                      ? children
                      : content
                  }
                </div>
              </div>
            );
          }
        }
      </Transition>
    </div>
  );
};

export default Collapsible;
