import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ERROR_CODES } from 'constants/errors';
import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import { useAnalytics } from 'hooks/useAnalytics';
import * as OrdersService from 'services/OrdersService';

import type {
  IAddress,
  IDeliveryInterval,
  IMealVoucherPaymentMethod,
  IMoneyPaymentMethod,
  IOrder,
  IOrderLoyalty,
  IPayment,
  Nullable,
  ReactQueryMutationPayloadCallbacks
} from 'types';

// Actions

enum Actions {
  AddToOrder,
  Cancel,
  Complete,
  Delete,
  DeleteSummaryItem,
  DownloadInvoice,
  GetRetryPaymentLink,
  Repeat,
  ReportProblem,
  SetAgeCheck,
  SetBillingAddress,
  SetInterval,
  SetLoyaltyPointsUsed,
  SetMealVoucherPayment,
  SetMoneyPayment,
  SetShippingAddress,
  SetTip,
}

// Hook

export const useOrderMutation = () => {
  const mutation = useOrderMutationInternal();

  return {
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    //
    addToOrder: (payload: AddToOrderPayload) => {
      mutation.mutate({ payload, type: Actions.AddToOrder });
    },
    cancel: (payload: CancelPayload) => {
      mutation.mutate({ payload, type: Actions.Cancel });
    },
    complete: (payload: CompletePayload) => {
      mutation.mutate({ payload, type: Actions.Complete });
    },
    deleteOrder: (payload: DeletePayload) => {
      mutation.mutate({ payload, type: Actions.Delete });
    },
    deleteSummaryItem: (payload: DeleteSummaryItemPayload) => {
      mutation.mutate({ payload, type: Actions.DeleteSummaryItem });
    },
    downloadInvoice: (payload: DownloadInvoicePayload) => {
      mutation.mutate({ payload, type: Actions.DownloadInvoice });
    },
    getRetryPaymentLink: (payload: GetRetryPaymentLinkPayload) => {
      mutation.mutate({ payload, type: Actions.GetRetryPaymentLink });
    },
    repeat: (payload: RepeatPayload) => {
      mutation.mutate({ payload, type: Actions.Repeat });
    },
    reportProblem: (payload: ReportProblemPayload) => {
      mutation.mutate({ payload, type: Actions.ReportProblem });
    },
    setAgeCheck: (payload: SetAgeCheckPayload) => {
      mutation.mutate({ payload, type: Actions.SetAgeCheck });
    },
    setBillingAddress: (payload: SetBillingAddressPayload) => {
      mutation.mutate({ payload, type: Actions.SetBillingAddress });
    },
    setInterval: (payload: SetIntervalPayload) => {
      mutation.mutate({ payload, type: Actions.SetInterval });
    },
    setMealVouchersPaymentMethod: (payload: SetMealVoucherPaymentPayload) => {
      mutation.mutate({ payload, type: Actions.SetMealVoucherPayment });
    },
    setMoneyPaymentMethod: (payload: SetMoneyPaymentPayload) => {
      mutation.mutate({ payload, type: Actions.SetMoneyPayment });
    },
    setShippingAddress: (payload: SetShippingAddressPayload) => {
      mutation.mutate({ payload, type: Actions.SetShippingAddress });
    },
    setTip: (payload: SetTipPayload) => {
      mutation.mutate({ payload, type: Actions.SetTip });
    },
    setLoyaltyPointsUsed: (payload: SetLoyaltyPointsUsedPayload) => {
      mutation.mutate({ payload, type: Actions.SetLoyaltyPointsUsed });
    },
  };
};

const useOrderMutationInternal = () => {
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ACTIVE_ORDER],
    mutationFn: (args: { type: Actions, payload: MutationPayload }) => {
      const { type, payload } = args;
      switch (type) {

        case Actions.AddToOrder: {
          const { tokenValue, addTo } = payload as AddToOrderPayload;
          return OrdersService.addToOrder(tokenValue, addTo);
        }

        case Actions.Cancel: {
          const { tokenValue, reason } = payload as CancelPayload;
          return OrdersService.cancelOrder(tokenValue, reason);
        }

        case Actions.Complete: {
          const { tokenValue, notes } = payload as CompletePayload;
          return OrdersService.completeOrder(tokenValue, notes);
        }

        case Actions.Delete: {
          const { tokenValue } = payload as DeletePayload;
          return OrdersService.deleteOrder(tokenValue);
        }

        case Actions.DeleteSummaryItem: {
          const { path } = payload as DeleteSummaryItemPayload;
          return OrdersService.deleteSummaryItem(path);
        }

        case Actions.DownloadInvoice: {
          const { tokenValue, url } = payload as DownloadInvoicePayload;
          return OrdersService.downloadInvoice(tokenValue, url);
        }

        case Actions.GetRetryPaymentLink: {
          const { tokenValue } = payload as GetRetryPaymentLinkPayload;
          return OrdersService.getRetryPaymentLink(tokenValue);
        }

        case Actions.Repeat: {
          const { tokenValue } = payload as RepeatPayload;
          return OrdersService.repeatOrder(tokenValue);
        }

        case Actions.ReportProblem: {
          const { tokenValue, payload: data } = payload as ReportProblemPayload;
          return OrdersService.reportProblem(tokenValue, data);
        }

        case Actions.SetAgeCheck: {
          const { tokenValue, ageCheckDone } = payload as SetAgeCheckPayload;
          return OrdersService.setAgeCheck(tokenValue, ageCheckDone);
        }

        case Actions.SetBillingAddress: {
          const { tokenValue, billingAddressId } = payload as SetBillingAddressPayload;
          return OrdersService.setBillingAddress(tokenValue, billingAddressId);
        }

        case Actions.SetInterval: {
          const { tokenValue, scheduleId } = payload as SetIntervalPayload;
          return OrdersService.setInterval(tokenValue, scheduleId);
        }

        case Actions.SetLoyaltyPointsUsed: {
          const { tokenValue, points } = payload as SetLoyaltyPointsUsedPayload;
          return OrdersService.setLoyaltyPointsUsed(tokenValue, points);
        }

        case Actions.SetMealVoucherPayment: {
          const { tokenValue, paymentMethodCode } = payload as SetMealVoucherPaymentPayload;
          return OrdersService.setMealVouchersPaymentMethod(tokenValue, paymentMethodCode);
        }

        case Actions.SetMoneyPayment: {
          const { tokenValue, paymentMethod, paymentId } = payload as SetMoneyPaymentPayload;
          return OrdersService.setMoneyPaymentMethod(tokenValue, paymentMethod, paymentId);
        }

        case Actions.SetShippingAddress: {
          const { tokenValue, shippingAddressId } = payload as SetShippingAddressPayload;
          return OrdersService.setShippingAddress(tokenValue, shippingAddressId);
        }

        case Actions.SetTip: {
          const { tokenValue, tip } = payload as SetTipPayload;
          return OrdersService.setTip(tokenValue, tip);
        }

        default:
          console.error('Unknown mutation type', type);
          return new Promise((resolve) => resolve(true));
      }
    },
    onMutate: (args) => {
      const { payload, type } = args || {};
    },
    onSuccess: (data, args) => {
      const { payload, type } = args || {};

      switch (type) {

        case Actions.AddToOrder: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ORDER, data.addTo], exact: true });
          break;
        }

        case Actions.DeleteSummaryItem: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ADD_TO_ORDER, data.tokenValue] });
          break;
        }

        case Actions.SetShippingAddress: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INTERVALS_BY_ORDER] });
          break;
        }

        case Actions.SetInterval:
        case Actions.SetMoneyPayment:
        case Actions.SetBillingAddress:
        case Actions.SetTip:
        case Actions.SetAgeCheck: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          break;
        }

        case Actions.SetMealVoucherPayment: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          break;
        }

        case Actions.SetLoyaltyPointsUsed: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          queryClient.setQueryData([QUERY_KEYS.ORDER_LOYALTY, data.tokenValue], (loyalty: IOrderLoyalty) => ({
            ...loyalty,
            spend: {
              ...loyalty?.spend,
              step: {
                ...loyalty?.spend?.step,
                usedPoints: (payload as SetLoyaltyPointsUsedPayload)?.points,
              }
            }
          }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          break;
        }

        case Actions.Complete: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_CONFIG] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USUAL_PRODUCTS] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ORDER_GIFTS] });
          break;
        }

        case Actions.Cancel: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_CONFIG] });
          analytics.cancelOrder(true, {
            reason: (payload as CancelPayload)?.reason
          });
          break;
        }

        case Actions.Repeat: {
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, () => data);
          analytics.repeatOrder(true);
          break;
        }

        case Actions.ReportProblem: {
          analytics.reportOrderProblem(true, {
            type: (payload as ReportProblemPayload)?.payload?.typeLabel,
            secondaryType: (payload as ReportProblemPayload)?.payload?.secondTypeLabel
          });
          break;
        }

        case Actions.Delete: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          break;
        }

        case Actions.DownloadInvoice:
        case Actions.GetRetryPaymentLink:
          break;

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload?.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err: any, args) => {
      const { payload, type } = args;

      const REFETCH_ACTIONS = [
        Actions.Complete
      ];

      const REFETCH_ERRORS = [
        ERROR_CODES.SERVER_ERROR,
        ERROR_CODES.VALIDATION_ERROR
      ];

      if (
        REFETCH_ACTIONS.includes(type)
        && REFETCH_ERRORS.includes(err?.response?.status)
      ) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INTERVALS_BY_ORDER] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BILLING] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENT_METHODS] });
      }

      if (payload?.onError) {
        payload.onError(err);
      }
    },
    onSettled: (data, error, args) => {
      const { payload } = args;

      if (payload?.onSettled) {
        payload.onSettled(data);
      }
    }
  });
};

// Payload Types

interface AddToOrderPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  addTo: string
}

interface CancelPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  reason: string
}

interface CompletePayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  notes: string
}

interface DeletePayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue']
}

interface DeleteSummaryItemPayload extends ReactQueryMutationPayloadCallbacks {
  path: string
}

interface DownloadInvoicePayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  url: string
}

interface GetRetryPaymentLinkPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue']
}

interface RepeatPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue']
}

interface ReportProblemPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  payload: any
}

interface SetAgeCheckPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  ageCheckDone: boolean
}

interface SetBillingAddressPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  billingAddressId: IAddress['id']
}

interface SetIntervalPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  scheduleId: IDeliveryInterval['scheduleId']
}

interface SetLoyaltyPointsUsedPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  points: number
}

interface SetMealVoucherPaymentPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  paymentMethodCode: IMealVoucherPaymentMethod['code']
}

interface SetMoneyPaymentPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  paymentMethod: IMoneyPaymentMethod['code'],
  paymentId: IPayment['id']
}

interface SetShippingAddressPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  shippingAddressId: IAddress['id']
}

interface SetTipPayload extends ReactQueryMutationPayloadCallbacks {
  tokenValue: IOrder['tokenValue'],
  tip: Nullable<number>
}

type MutationPayload =
  AddToOrderPayload |
  CancelPayload |
  CompletePayload |
  DeletePayload |
  DeleteSummaryItemPayload |
  DownloadInvoicePayload |
  GetRetryPaymentLinkPayload |
  RepeatPayload |
  ReportProblemPayload |
  SetAgeCheckPayload |
  SetBillingAddressPayload |
  SetIntervalPayload |
  SetMealVoucherPaymentPayload |
  SetMoneyPaymentPayload |
  SetShippingAddressPayload |
  SetTipPayload
