import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { getUserConfig } from 'services/ConfigService';

import type { IUserConfig, ReactQueryOptions } from 'types';

export const useUserConfig = (queryOptions: ReactQueryOptions<IUserConfig> = {}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.USER_CONFIG
    ],
    queryFn: getUserConfig,
    staleTime: 2 * 60 * 1000,
    refetchInterval: 2 * 60 * 1000,
    retry: 0,
    ...queryOptions
  });
};
