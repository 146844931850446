import React from 'react';

import { LocalStorage, SessionStorage } from 'common/Storage';

import type { ValueOf } from 'types';

const STORAGES = {
  LOCAL: 'local',
  SESSION: 'session',
} as const;

const STORAGE_ENGINES = {
  [STORAGES.LOCAL]: LocalStorage,
  [STORAGES.SESSION]: SessionStorage,
};

interface Options {
  version?: string,
  interval?: number,
  storage?: ValueOf<typeof STORAGES>,
  isEnabled?: boolean
}

export function useScheduledFeature(key: string, options: Options) {
  const {
    version: currentVersion = '0.0.1',
    interval = 0,
    storage = STORAGES.LOCAL,
    isEnabled
  } = options || {};

  const [isVisible, setVisible] = React.useState<boolean | null>(null);

  const Storage = STORAGE_ENGINES[storage] || LocalStorage;

  const getVisible = () => {
    try {
      const data = Storage.get(key);
      const { version, et } = data || {};
      const now = Date.now();
      return version !== currentVersion || !et || now > et;
    } catch (err) {
      Storage.remove(key);
      console.error('Error while parsing stored data', err);
      return true;
    }
  };

  React.useEffect(() => {
    if (!isEnabled) return;
    setVisible(getVisible());
  }, [isEnabled]);

  const markAsSeen = () => {
    const now = Date.now();
    Storage.set(key, {
      dt: now, // dismiss timestamp
      et: now + 1000 * 60 * interval, // expires timestamp
      version: currentVersion
    });
    setVisible(false);
  };

  return {
    isVisible,
    markAsSeen
  };
}
