import React from 'react';
import classnames from 'classnames';

import Cart from '../Cart/Cart';

import styles from './CartSlot.module.scss';

interface Props {
  withHeaderCategoriesBar: boolean
}

const CartSlot = (props: Props) => {
  const { withHeaderCategoriesBar } = props;

  return (
    <div
      className={
        classnames(
          styles.root,
          { [styles.hasHeaderCategoriesBarOffset]: withHeaderCategoriesBar }
        )
      }
    >

      <div className={styles.inner}>

        {/* Cart */}
        <Cart title="CART.TITLE" />

      </div>

    </div>
  );
};

export default CartSlot;
