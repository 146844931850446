import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import MenuItemLink, { isLinkActive } from './MenuItemLink';
import MenuItemSeparator from './MenuItemSeparator';

import type { IMenuItemLink } from 'types';

import styles from './MenuItemWithList.module.scss';

interface Props {
  activeChildRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  //
  className?: string,
  linkClassName?: string,
  linkActiveClassName?: string,
  iconClassName?: string,
  labelClassName?: string,
  subLinkClassName?: string,
  subLinkActiveClassName?: string,
  //
  inline?: boolean,
  withIndent?: boolean,
  //
  item: IMenuItemLink,
  //
  onClick?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseEnter?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseLeave?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const MenuItemWithList = (props: Props) => {

  const {
    activeChildRef,
    //
    className,
    linkClassName,
    linkActiveClassName,
    iconClassName,
    labelClassName,
    subLinkClassName,
    subLinkActiveClassName,
    //
    inline,
    withIndent,
    //
    item,
    //
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  // Props

  const {
    slug,
    icon,
    iconImage,
    children,
  } = item || {};

  // Hooks

  const router = useRouter();

  // Props

  const { asPath, query } = router;
  const { originalPathname = '' } = query || {};

  // Inline

  if (inline) {
    return (
      <>
        {/* Link */}
        <MenuItemLink
          className={classnames(linkClassName)}
          activeClassName={linkActiveClassName}
          labelClassName={classnames(
            styles.label,
            labelClassName
          )}
          //
          item={item}
          //
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />

        {/* Sub Links */}
        {
          children.map((child, index) => {

            // Separator

            if (child === 'separator') {
              return (
                <MenuItemSeparator />
              );
            }

            // Props

            const {
              className: childClassName,
              exact,
              href,
              matchHref,
              hidden,
            } = child || {};

            // Hidden

            if (hidden) return null;

            // Active

            const isActive = isLinkActive({
              asPath,
              originalPathname,
              href,
              matchHref,
              exact,
            });

            // Render

            return (
              <MenuItemLink
                key={`${slug}-sub-${index}`}
                //
                ref={
                  isActive
                    ? activeChildRef
                    : null
                }
                //
                className={classnames(
                  styles.link,
                  { [styles.indented]: withIndent },
                  { [styles.withImage]: !!iconImage || !!icon, },
                  subLinkClassName,
                  childClassName
                )}
                activeClassName={classnames(
                  styles.active,
                  { [subLinkActiveClassName]: subLinkActiveClassName }
                )}
                iconClassName={iconClassName}
                labelClassName={labelClassName}
                //
                item={child}
                //
                onClick={onClick}
              />
            );
          })
        }
      </>
    );
  }

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >

      {/* Link */}
      <MenuItemLink
        className={classnames(linkClassName)}
        labelClassName={classnames(styles.label, labelClassName)}
        //
        item={item}
        //
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      {/* Sub List */}
      <ul className={classnames(styles.subList)}>
        {
          children.map((child, index) => {

            // Separator

            if (child === 'separator') {
              return (
                <MenuItemSeparator />
              );
            }

            // Props

            const {
              className: childClassName,
              exact,
              href,
              matchHref,
              hidden,
            } = child || {};

            // Hidden

            if (hidden) return null;

            // Active

            const isActive = isLinkActive({
              asPath,
              originalPathname,
              href,
              matchHref,
              exact,
            });

            // Render

            return (
              <li
                key={`${slug}-sub-${index}`}
                className={styles.item}
              >
                <MenuItemLink
                  ref={
                    isActive
                      ? activeChildRef
                      : null
                  }
                  //
                  className={classnames(
                    styles.link,
                    { [styles.indented]: withIndent },
                    { [styles.withImage]: !!iconImage || !!icon },
                    subLinkClassName,
                    childClassName
                  )}
                  activeClassName={classnames(
                    styles.active,
                    { [subLinkActiveClassName]: subLinkActiveClassName }
                  )}
                  iconClassName={iconClassName}
                  labelClassName={labelClassName}
                  //
                  item={child}
                  //
                  onClick={onClick}
                />
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default MenuItemWithList;
