import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'browser_events';

enum Actions {
  DISPLAY = 'display',
}

enum Labels {
  OUTDATED = 'outdated_browser_screen',
}

const push = getPushCategoryEventHandler<Actions, Labels>(CATEGORY);

// Events

// --- View Outdated Browser Warning

export function viewOutdatedBrowserWarning() {
  return push(Actions.DISPLAY, Labels.OUTDATED);
}
