import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { sortByKey } from 'helpers/ArrayHelpers';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import { useScreenSize } from 'hooks/useScreenSize';

import styles from './PerRow.module.scss';

interface BreakpointParams {
  itemsPerRow: number,
  yGap: number,
  xGap: number
}

interface Props<T> {
  className?: string,
  breakpoints?: Record<number, BreakpointParams>,
  itemsPerRow: number,
  yGap: number,
  xGap: number,
  items: T[],
  renderItem: (item: T) => React.ReactNode,
  onBreakpointChange?: (params: BreakpointParams) => void,
  dataTestId?: string
}

function PerRow<T>(props: Props<T>) {

  const {
    className,
    itemsPerRow,
    xGap,
    yGap,
    breakpoints,
    items,
    renderItem,
    onBreakpointChange,
    dataTestId
  } = props;

  // Memo

  const defaultBreakpointParams: BreakpointParams = React.useMemo(() => {
    return {
      itemsPerRow,
      yGap,
      xGap,
    };
  }, [itemsPerRow, xGap, yGap]);

  // Hooks

  const { width: screenWidth } = useScreenSize();

  const [breakpointParams, setBreakpointParams] = React.useState<BreakpointParams>(defaultBreakpointParams);

  // Effects

  useIsomorphicLayoutEffect(() => {
    setBreakpointParams(getBreakpointParams(
      breakpoints,
      defaultBreakpointParams,
      screenWidth
    ));
  }, [
    breakpoints,
    defaultBreakpointParams,
    screenWidth
  ]);

  useIsomorphicLayoutEffect(() => {
    if (onBreakpointChange) {
      onBreakpointChange(breakpointParams);
    }
  }, [onBreakpointChange, breakpointParams]);

  // Props

  const {
    itemsPerRow: currentItemsPerRow,
    yGap: currentYGap,
    xGap: currentXGap,
  } = breakpointParams || defaultBreakpointParams;

  const marginY = currentYGap / 2;
  const marginX = currentXGap / 2;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >
      <div
        data-testid={dataTestId}
        className={styles.list}
        style={{
          margin: `-${marginY}px -${currentXGap}px`,
          padding: `0 ${marginX}px`,
        } as CSSProperties}
      >
        {
          items.map((item, key) => {
            return (
              <div
                key={key}
                className={styles.item}
                style={{
                  margin: `${marginY}px ${marginX}px`,
                  width: `calc(100% / ${currentItemsPerRow} - ${currentXGap}px)`,
                } as CSSProperties}
              >
                {renderItem(item)}
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

// Helpers

const getBreakpointParams = (
  breakpoints: Record<number, BreakpointParams>,
  defaultParams: BreakpointParams,
  screenWidth: number
): BreakpointParams => {
  const sortedBreakpoints = sortByKey(
    Object
      .keys(breakpoints)
      .map((key) => ({
        breakpoint: parseFloat(key),
        ...breakpoints[key]
      })),
    'breakpoint',
    false
  );

  const currentBreakpointParams = sortedBreakpoints.find((params: BreakpointParams & { breakpoint: number }) => {
    const { breakpoint } = params;
    return (screenWidth > breakpoint);
  });

  if (currentBreakpointParams) {
    const { breakpoint, ...rest } = currentBreakpointParams;
    return rest;
  }

  return defaultParams;
};

// Export

export default PerRow;
