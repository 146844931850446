import React from 'react';

import { Field } from 'formik';
import { debounce, throttle } from 'throttle-debounce';
import classnames from 'classnames';

import { KEY_CODES } from 'constants/keycodes';

import Icon from '../Icon/Icon';
import Label from '../Label/Label';

import type { FieldProps } from 'formik';

import styles from './Input.module.scss';

// Types
type InputMode = 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'

interface Props {
  withoutForm?: boolean,
  size?: string,
  label?: string,
  className?: string,
  labelClassName?: string,
  inputClassName?: string,
  iconClassName?: string,
  startIconClassName?: string,
  endIconClassName?: string,
  errorClassName?: string,
  errorVisibleClassName?: string,
  rounded?: boolean,
  startIcon?: JSX.Element,
  endIcon?: JSX.Element,
  type?: string,
  placeholder?: string,
  inputMode?: InputMode,
  disabled?: boolean,
  name?: string,
  value?: string,
  autoFocus?: boolean,
  regex?: string | RegExp,
  onKeyDown?: (e: React.KeyboardEvent) => void,
  onEnter?: (e: any) => void,
  onEsc?: () => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  throttled?: boolean,
  autoComplete?: string,
  delay?: number,
  dataTestId?: string
}

export const INPUT_SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const Input = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    withoutForm = false,
    size = INPUT_SIZES.LARGE,
    label,
    //
    className,
    labelClassName,
    inputClassName,
    iconClassName,
    startIconClassName,
    endIconClassName,
    errorClassName,
    errorVisibleClassName,
    //
    rounded = false,
    startIcon, endIcon,
    type = 'text',
    name,
    regex,
    //
    onKeyDown,
    onEnter,
    onEsc,
    onChange,
    //
    throttled,
    delay = 0,
    dataTestId,
    ...rest
  } = props;

  const [changeEvent, setChangeEvent] = React.useState<React.ChangeEvent<HTMLInputElement>>();

  const changeHandler = onChange || (() => {});
  const debouncedOnChange = React.useCallback(throttled ? throttle(delay, changeHandler) : debounce(delay, changeHandler), [throttled, delay, onChange]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    setChangeEvent(e);
    if (delay) {
      debouncedOnChange(e);
    } else {
      onChange(e);
    }
  };

  const inputClasses = React.useMemo(() => classnames(
    styles.input,
    { [styles.inputLarge]: size === INPUT_SIZES.LARGE },
    { [styles.inputMedium]: size === INPUT_SIZES.MEDIUM },
    { [styles.inputSmall]: size === INPUT_SIZES.SMALL },
    { [styles.withStartIcon]: startIcon },
    { [styles.withEndIcon]: endIcon },
    { [styles.rounded]: rounded },
    inputClassName
  ), [size, rounded, inputClassName, endIcon, startIcon]);

  const StartIcon = (
    <>
      {
        startIcon && (
          <div className={classnames(styles.startIconContainer, iconClassName, startIconClassName)}>
            { typeof startIcon === 'string' ? <Icon size={24} name={startIcon} /> : startIcon}
          </div>
        )
      }
    </>
  );

  const EndIcon = (
    <>
      {
        endIcon && (
          <div className={classnames(styles.endIconContainer, iconClassName, endIconClassName)}>
            { typeof endIcon === 'string' ? <Icon size={24} name={endIcon} /> : endIcon}
          </div>
        )
      }
    </>
  );

  const onKeyDownHandler = (e: React.KeyboardEvent<Element>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (e.keyCode === KEY_CODES.ENTER && onEnter) {
      if (onChange && changeEvent) {
        onChange(changeEvent);
      }
      onEnter(e);
    } else if (e.keyCode === KEY_CODES.ESC && onEsc) {
      onEsc();
    }
  };

  if (withoutForm) {
    return (
      <div className={styles.root}>
        {label && (
          <Label htmlFor={name} label={label} className={classnames(styles.labelClassName, labelClassName)} />
        )}
        <div className={classnames(styles.inputWrapper, className)}>
          {StartIcon}
          <input
            ref={ref}
            type={type}
            className={inputClasses}
            onKeyDown={onKeyDownHandler}
            onChange={handleOnChange}
            {...(dataTestId ? { 'data-testid': dataTestId } : {})}
            {...rest}
          />
          {EndIcon}
        </div>
      </div>
    );
  }

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }: FieldProps<any>) => {
        const { onChange: onFieldChange, ...fieldProps } = field;

        const inputProps = onChange ? { onChange: handleOnChange } : {};

        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (regex) {
            const re = new RegExp(regex);
            if (re.test(e.target.value)) {
              onFieldChange(e);
            }
          } else {
            onFieldChange(e);
          }
        };

        const hasError = touched && error;

        return (
          <div className={classnames(styles.root, className)}>
            {label && (
              <Label htmlFor={name} label={label} hasError={hasError} className={classnames(styles.labelClassName, labelClassName)} />
            )}
            <div className={classnames(styles.inputWrapper)}>
              {StartIcon}
              <input
                ref={ref}
                type={type}
                className={classnames(inputClasses, { [styles.hasError]: hasError })}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                {...(dataTestId ? { 'data-testid': dataTestId } : {})}
                {...fieldProps}
                {...rest}
                {...inputProps}
              />
              {EndIcon}
            </div>
            <div
              className={classnames(
                styles.error,
                { [styles.visible]: hasError },
                { [errorVisibleClassName]: hasError },
                errorClassName,
              )}
            >
              {error}
            </div>
          </div>
        );
      }}
    </Field>
  );
});

export default Input;
