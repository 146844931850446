import { APP_ROUTES } from 'constants/routes';
import { getLandingPageSlug } from 'helpers/LandingPageHelpers';

export type AnalyticsPageTypeGetter = (path: string) => string;

export const PAGE_TYPES_MAP: Record<string, string | AnalyticsPageTypeGetter> = {

  // Authentication

  [`^${APP_ROUTES.LOGIN}`]: 'login',
  [`^${APP_ROUTES.LOGOUT}`]: 'logout',

  // Home

  [`^${APP_ROUTES.HOME}$`]: 'home', // exact match
  [`^${APP_ROUTES.HOME_PREVIEW}`]: 'home-preview',

  // Categories

  [`^${APP_ROUTES.CATEGORIES_FILTERED}/`]: 'category',
  [`^${APP_ROUTES.CATEGORIES}/`]: 'category',

  // Custom Listing

  [`^${APP_ROUTES.CUSTOM_LISTING_FILTERED}/`]: 'custom-listing',
  [`^${APP_ROUTES.CUSTOM_LISTING}/`]: 'custom-listing',

  // Product

  [`^${APP_ROUTES.PRODUCTS}/`]: 'product',

  // Search

  [`^${APP_ROUTES.SEARCH}/`]: 'search',

  // Accelerated Sale

  [`^${APP_ROUTES.ACCELERATED_SALE_FILTERED}`]: 'accelerated-sale',
  [`^${APP_ROUTES.ACCELERATED_SALE}`]: 'accelerated-sale',

  // New Products

  [`^${APP_ROUTES.NEW_PRODUCTS_FILTERED}`]: 'new-products',
  [`^${APP_ROUTES.NEW_PRODUCTS}`]: 'new-products',

  // Promotions

  [`^${APP_ROUTES.PROMOTIONS}$`]: 'promo-list', // exact match
  [`^${APP_ROUTES.PROMOTIONS_FILTERED}/`]: 'promo',
  [`^${APP_ROUTES.PROMOTIONS}/`]: 'promo',

  // Favorites / My Freshlist

  [`^${APP_ROUTES.FAVORITES}`]: 'favorites',
  [`^${APP_ROUTES.MY_FRESHLIST}`]: 'my-freshlist',

  // Account

  [`^${APP_ROUTES.ORDER_HISTORY}/`]: 'account-order',
  [`^${APP_ROUTES.ORDER_HISTORY}`]: 'account-orders',
  [`^${APP_ROUTES.ADDRESSES}$`]: 'account-addresses', // exact match
  [`^${APP_ROUTES.BILLING}$`]: 'account-billing', // exact match
  [`^${APP_ROUTES.MEAL_VOUCHERS}$`]: 'account-meal-vouchers', // exact match
  [`^${APP_ROUTES.ACCOUNT}$`]: 'account-details', // exact match
  [`^${APP_ROUTES.MY_FRESHCLUB_OPT_OUT}`]: 'account-freshclub-opt-out',
  [`^${APP_ROUTES.MY_FRESHCLUB}`]: 'account-freshclub',
  [`^${APP_ROUTES.BENEFITS}$`]: 'account-benefits', // exact match
  [`^${APP_ROUTES.ACCOUNT_PROMOTIONS}`]: 'account-vouchers-promotions', // exact match
  [`^${APP_ROUTES.VOUCHERS}/`]: 'account-voucher-details',
  [`^${APP_ROUTES.VOUCHERS}`]: 'account-vouchers',

  // Checkout

  [`^${APP_ROUTES.CHECKOUT}`]: 'checkout',

  // Genius

  [`^${APP_ROUTES.GENIUS_MOBILE}`]: 'genius-mobile',
  [`^${APP_ROUTES.GENIUS}`]: 'genius',

  // Buy Genius

  [`^${APP_ROUTES.EMAG_GENIUS_MOBILE}`]: 'emag-genius-mobile',
  [`^${APP_ROUTES.EMAG_GENIUS}`]: 'emag-genius',

  // Genius Benefits

  [`^${APP_ROUTES.GENIUS_BENEFITS_MOBILE}`]: 'genius-benefits-mobile',
  [`^${APP_ROUTES.GENIUS_BENEFITS}`]: 'genius-benefits',

  // Upgreat

  [`^${APP_ROUTES.UPGREAT_MOBILE}`]: 'new-customers-funnel-mobile',
  [`^${APP_ROUTES.UPGREAT}`]: 'new-customers-funnel',

  // Campaigns

  // --- Rules

  [`^${APP_ROUTES.CAMPAIGN_RULES_MOBILE}$`]: 'campaign-rules-mobile',
  [`^${APP_ROUTES.CAMPAIGN_RULES}$`]: 'campaign-rules',

  // --- Default

  [`^${APP_ROUTES.CAMPAIGNS}/`]: 'campaign',

  // Static

  // --- About

  [`^${APP_ROUTES.ABOUT_MOBILE}`]: 'about-mobile',
  [`^${APP_ROUTES.ABOUT}`]: 'about',

  // --- Contact

  [`^${APP_ROUTES.CONTACT_MOBILE}`]: 'contact-mobile',
  [`^${APP_ROUTES.CONTACT}`]: 'contact',

  // --- Default

  [`^${APP_ROUTES.STATIC_MOBILE}`]: 'static-mobile',
  [`^${APP_ROUTES.STATIC}`]: 'static',

  // Recipes V2

  [`^${APP_ROUTES.RECIPES_DETAILS}/`]: 'simple-recipe-details-page',
  [`^${APP_ROUTES.RECIPES_BY_TAG}`]: 'simple-recipes-by-tag',
  [`^${APP_ROUTES.RECIPES_LIST}`]: 'simple-recipes-list',
  [`^${APP_ROUTES.RECIPES_LIST_FILTERED}`]: 'simple-recipes-list',
  [`^${APP_ROUTES.RECIPES_LANDING}`]: 'simple-recipes-landing',

  // Blog (Contentful)

  [`^${APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE}/`]: 'actiuni-de-bine-post-mobile',
  [`^${APP_ROUTES.BLOG_GOOD_DEEDS}/`]: 'actiuni-de-bine-post',
  [`^${APP_ROUTES.BLOG_GOOD_DEEDS_MOBILE}$`]: 'actiuni-de-bine-mobile', // exact match
  [`^${APP_ROUTES.BLOG_GOOD_DEEDS}$`]: 'actiuni-de-bine', // exact match

  // --- Din Livada

  [`^${APP_ROUTES.BLOG_DIN_LIVADA_MOBILE}$`]: 'din-livada-mobile', // exact match
  [`^${APP_ROUTES.BLOG_DIN_LIVADA}$`]: 'din-livada', // exact match

  // --- Din Gradina

  [`^${APP_ROUTES.BLOG_DIN_GRADINA_MOBILE}$`]: 'din-gradina-mobile', // exact match
  [`^${APP_ROUTES.BLOG_DIN_GRADINA}$`]: 'din-gradina', // exact match

  // --- Brewzeus

  [`^${APP_ROUTES.BLOG_BREWZEUS_MOBILE}$`]: 'brewzeus-by-mihai-panfil-mobile', // exact match
  [`^${APP_ROUTES.BLOG_BREWZEUS}$`]: 'brewzeus-by-mihai-panfil', // exact match

  // --- Brutaria Freshful

  [`^${APP_ROUTES.BLOG_BRUTARIA_FRESHFUL_MOBILE}$`]: 'brutaria-freshful-mobile', // exact match
  [`^${APP_ROUTES.BLOG_BRUTARIA_FRESHFUL}$`]: 'brutaria-freshful', // exact match

  // --- LP
  [`^${APP_ROUTES.LANDING_PAGES}/`]: (pagePath: string) => getLandingPageSlug(pagePath),
  [`^${APP_ROUTES.LANDING_PAGES_MOBILE}/`]: (pagePath: string) => getLandingPageSlug(pagePath),

  // Forms

  // --- Order Feedback

  [`^${APP_ROUTES.ORDER_FEEDBACK}`]: 'order-feedback',

  // Newsletter

  [`^${APP_ROUTES.NEWSLETTER_UNSUBSCRIBE}`]: 'newsletter-unsubscribe',

  // Abandoned Cart

  [`^${APP_ROUTES.ABANDONED_CART_UNSUBSCRIBE}`]: 'abandoned-cart-unsubscribe',

  // Careers

  [`^${APP_ROUTES.CAREERS}`]: 'careers',
  [`^${APP_ROUTES.CAREERS_MOBILE}`]: 'careers-mobile',

  // Errors

  [`^${APP_ROUTES.ERROR500}`]: '500',
  [`^${APP_ROUTES.ERROR404}`]: '404',

};
