import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getOrderCancellationReasons } from 'services/OrdersService';

export const useOrderCancellationReasons = (options = { enabled: true }) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.ORDER_CANCELLATION_REASONS
    ],
    queryFn: () => getOrderCancellationReasons(),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });
};
