import type {
  IOrderByResponse,
  IStrapiDataImage,
  IStrapiDataResponsiveImage,
  IStrapiDataVideo,
  IStrapiImage,
  IStrapiPagination,
  IStrapiPaginationMeta,
  IStrapiResponsiveImage,
  IStrapiSeoImage,
  IStrapiVideo,
  MarkdownSerializeFunction,
} from 'types';

// Meta

export function convertStrapiPaginationMeta(meta: IStrapiPaginationMeta): IStrapiPagination {
  const {
    page,
    limit,
    pageCount,
    total
  } = meta;

  return {
    currentPage: page,
    totalPages: pageCount,
    limit,
    count: total,
  };
}

//  Data

// --- Responsive Image

export function convertStrapiResponsiveImage(image: IStrapiDataResponsiveImage): IStrapiResponsiveImage {
  if (!image) return null;

  const {
    desktop,
    mobile
  } = image || {};

  if (!desktop && !mobile) return null;

  return {
    desktop: convertStrapiImage(desktop),
    mobile: convertStrapiImage(mobile),
  };
}

// --- Image

export function convertStrapiImage(image: IStrapiDataImage): IStrapiImage {
  if (!image) return null;

  const {
    width,
    height,
    url,
    alternativeText
  } = image || {};

  if (!url) return null;

  return {
    width: width || null,
    height: height || null,
    src: url,
    alt: alternativeText || null,
  };
}

// --- Responsive SEO Image

export function convertStrapiResponsiveSeoImage(image: IStrapiDataResponsiveImage): IStrapiSeoImage[] {
  if (!image) return [];

  const {
    desktop,
    mobile,
  } = image || {};

  if (!desktop && !mobile) return [];

  return [
    convertStrapiSeoImage(mobile),
    convertStrapiSeoImage(desktop)
  ].filter((i) => !!i);
}

// --- OrderBy

export function convertStrapiOrderByMeta(meta: IStrapiPaginationMeta): IOrderByResponse {
  if (!meta) return [];

  const { orderBy } = meta || {};

  return orderBy;
}

// --- SEO Image

export function convertStrapiSeoImage(image: IStrapiDataImage): IStrapiSeoImage {
  if (!image) return null;

  const {
    width,
    height,
    url,
    alternativeText,
    mime,
  } = image || {};

  if (!url) return null;

  return {
    width,
    height,
    url,
    alt: alternativeText,
    type: mime,
  };
}

// --- Video

export async function convertStrapiVideo(
  video: IStrapiDataVideo,
  markdownSerializeFn: MarkdownSerializeFunction | any = (s: string) => s
): Promise<IStrapiVideo> {
  const {
    id,
    title,
    description,
    url
  } = video || {};

  if (!url) return null;

  const serializedDescription = description
    ? await markdownSerializeFn(description)
    : null;

  return {
    id,
    url,
    title,
    description: serializedDescription,
  };
}
