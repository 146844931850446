import React from 'react';

import BlogCard from 'components/blog/BlogCard/BlogCard';

import styles from './BlogCardList.module.scss';

const BlogCardList = (props) => {

  const { items } = props;

  return (
    <div className={styles.root}>
      {
        items?.map((item, index) => {
          const {
            date, title, image, href, id
          } = item;
          const key = id || index;
          return (
            <div className={styles.itemWrapper} key={key}>
              <BlogCard
                date={date}
                title={title}
                image={image}
                href={href}
              />
            </div>
          );
        })
      }
    </div>
  );
};

export default BlogCardList;
