export const RECOMMENDATION_LOCATIONS = {
  CHECKOUT: 'checkout',
  CART: 'cart',
  SEARCH: 'search'
} as const;

export const RECOMMENDATION_TYPES = {
  EMPTY: null,
  A: 'a'
} as const;
