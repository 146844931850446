import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';

import Loader from '../Loader/Loader';

import styles from './Radio.module.scss';

// Types
interface Props {
  className?: string,
  labelClassName?: string,
  iconWrapperClassName?: string,
  iconClassName?: string,
  loaderClassName?: string,
  disabledClassName?: string,
  group?: string,
  name?: string,
  autofocus?: boolean,
  checked?: boolean,
  loading?: boolean,
  disabled?: boolean,
  value?: string,
  label?: React.ReactNode,
  subtitle?: string,
  size?: string,
  tick?: boolean,
  withoutIcon?: boolean,
  withHover?: boolean,
  onClick?: () => void,
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void,
  dataTestId?: string,
  children?: React.ReactNode
}

const SIZES = {
  MEDIUM: 'medium',
  LARGE: 'large'
};

const ICON_SIZES = {
  [SIZES.MEDIUM]: 20,
  [SIZES.LARGE]: 24
};

const Radio = (props: Props) => {

  const {
    className,
    labelClassName,
    iconWrapperClassName,
    iconClassName,
    loaderClassName,
    disabledClassName,
    name = 'radio',
    autofocus,
    checked,
    loading,
    disabled = false,
    label,
    subtitle,
    size = SIZES.LARGE,
    tick = false,
    withoutIcon,
    withHover,
    onClick = () => {},
    onChange = () => {},
    children,
    dataTestId,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    if (autofocus && !disabled && isFocused) {
      inputRef.current?.focus();
    }
  }, [autofocus, disabled, isFocused]);

  const Label = (
    <>
      {
        label && (
          <span className={classnames(styles.label, labelClassName)}>{label}</span>
        )
      }
    </>
  );

  const Subtitle = (
    <>
      {
        subtitle && (
          <span className={styles.subtitle}>{subtitle}</span>
        )
      }
    </>
  );

  // Classes
  const classes = classnames(
    styles.root,
    { [styles.disabled]: disabled },
    disabledClassName ? { [disabledClassName]: disabled } : {},
    { [styles.checked]: checked },
    { [styles.focused]: isFocused },
    { [styles.hoverable]: withHover },
    className
  );

  const radioIconClasses = classnames(
    styles.radioIcon,
    { [styles.tick]: tick },
    { [styles[size]]: size },
    { [styles.loading]: loading },
    iconClassName
  );

  const labelClasses = classnames(
    styles.labelWrapper,
    { [styles.hasSubtitle]: !!subtitle },
    { [styles[size]]: size },
  );

  return (
    // eslint-disable-next-line
    <label 
      className={classes}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <input
        ref={inputRef}
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled || loading}
        onChange={(event) => {
          if (!(disabled || loading)) {
            onChange(event);
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...rest}
      />

      {/* Icon */}
      {
        !withoutIcon && (
          <div className={classnames(styles.iconWrapper, iconWrapperClassName)}>

            {/* Radio Icon */}
            <span className={radioIconClasses} />

            {/* Loader */}
            <Fade
              isVisible={!!loading}
              className={styles.loadingWrapper}
              enterDuration={200}
              enterDelay={300}
              exitDuration={300}
              lazyLoad
            >
              <Loader
                className={loaderClassName}
                color={styles.primaryColor}
                size={ICON_SIZES[size] - 2}
                lineWeight={2}
              />
            </Fade>

          </div>
        )
      }

      {/* Label */}
      {
        (label || subtitle) && (
          <div className={labelClasses}>
            {Label}
            {Subtitle}
          </div>
        )
      }

      {/* Children */}
      {children}

    </label>
  );

};

export default Radio;
