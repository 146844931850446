import { WINDOW_KEYS } from 'constants/storage';

export const setWindowVariable = (key, value) => {
  try {
    if (!window.FF) {
      window.FF = {};
    }
    window.FF[key] = value;
  } catch (err) {
    console.error('Failed to set on window - ', key, value);
  }
};

export const getWindowVariable = (key) => {
  if (!window.FF) return null;
  return window.FF[key];
};

export const setWindowFunction = (key, callback) => {
  try {
    window.FF[WINDOW_KEYS.FUNCTION][key] = callback;
  } catch (err) {
    console.error('Failed to set function on window - ', key, err);
  }
};
