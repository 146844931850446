export function sortByKey(array: any[], key: string, asc = true) {
  return array.sort((e1, e2) => {
    const x = e1[key];
    const y = e2[key];
    if (asc) {
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    }
    return ((x < y) ? 1 : ((x > y) ? -1 : 0));
  });
}

export function areArraysEqual(arr1: any, arr2: any) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) return false;
  return arr1.every((obj1, index) => JSON.stringify(obj1) === JSON.stringify(arr2[index]));
}

export function isArrayEmpty(arr: any, strict = false) {
  if (strict) {
    return !Array.isArray(arr) || !arr.length;
  }
  return !arr?.length;
}

export function isArray(arr: any): arr is any[] {
  return Array.isArray(arr);
}

export function splitInChunks<T>(array: T[] = [], size: number = 8): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export function createArray(count: number, fill = 0) {
  return new Array(count).fill(fill);
}

export function isInArray(arr: any[], el: any) {
  return arr.indexOf(el) > -1;
}
