import React from 'react';
import { INLINES } from '@contentful/rich-text-types';

import { Link } from 'components/ui';
import { parseAbsoluteUrl } from 'helpers/UrlHelpers';

import type { IAnalyticsListProps } from 'types';

export function getDocumentToReactComponentsOptions(options: {isMobilePreview?: boolean} = {}) {
  const { isMobilePreview } = options;

  return {
    renderText: (text: string) => text.split('\n').flatMap((t, i) => [i > 0 && (
      <React.Fragment key={i}>
        <br />
      </React.Fragment>
    ), t]),
    renderNode: {
      [INLINES.HYPERLINK]: (node: {data?: {uri?: string}}, children: React.ReactNode) => {
        const { data } = node || {};
        const { uri } = data || {};
        if (!uri) return null;

        const { isInternal, mobilePreviewPathname } = parseAbsoluteUrl(uri);
        const href = isMobilePreview ? mobilePreviewPathname ? mobilePreviewPathname.toString() : uri : uri;

        if (isInternal) {
          return (
            <Link passHref href={href}>
              <a>{children}</a>
            </Link>
          );
        }
        return (
          <a href={uri} target={isMobilePreview ? '_self' : '_blank'} rel="noreferrer">{children}</a>
        );
      }
    }
  };
}

export function getLandingPageSlug(pagePath: string) {
  return `lp-${pagePath.split('/').reverse()[0]}`;
}

export function getLandingPageAnalyticsProps(pageSlug: string, pageTitle: string, listingSlug: string, listingTitle: string): IAnalyticsListProps {
  return {
    analyticsListId: `lp_${pageSlug}_${listingSlug}`,
    analyticsListName: `LP - ${pageTitle} - ${listingTitle}`
  };
}
