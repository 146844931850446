import React from 'react';
import { useTranslation } from 'next-i18next';

import { Accordion } from 'components/ui';
import { useOrderReportProblemDispatchHelpers } from 'context/OrderReportProblemContext';
import { ORDER_PROBLEM_TYPES } from 'helpers/OrderHelpers';
import { useConfig } from 'hooks/data/useConfig';

import OrderProblemNavigation from './OrderProblemNavigation';

import OrderProblemSummary from './OrderProblemSummary';

import styles from './OrderProblemModal.module.scss';

const OrderProblemDelivery = (props) => {
  const {
    showCurrentStep,
    problem
  } = props;

  // Hooks

  const { t } = useTranslation();

  const { data: config } = useConfig();

  const { showStep } = useOrderReportProblemDispatchHelpers();

  // Props

  const { orderProblemTypes } = config || {};
  const problemType = orderProblemTypes?.find(({ type }) => type === ORDER_PROBLEM_TYPES.DELIVERY);
  const { secondTypes = [] } = problemType || {};

  // Helpers

  const selectProblemType = (type) => {
    const { code, name } = type;
    showStep({
      title: OrderProblemNavigation,
      titleProps: {
        title: t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_TITLE'),
        onBackClick: () => showCurrentStep()
      },
      component: OrderProblemSummary,
      componentProps: {
        payload: {
          secondType: code,
          secondTypeLabel: name
        },
        problem
      }
    });
  };

  // Render

  return (
    <div className={styles.list}>
      {
        secondTypes.map((type) => (
          <Accordion
            key={type.code}
            className={`${styles.listItem} ${styles.problemTypeItem}`}
            expandable={false}
            onClick={() => selectProblemType(type)}
            title={type.name}
          />
        ))
      }
    </div>
  );
};

export default OrderProblemDelivery;
