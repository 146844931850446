import React from 'react';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { Button } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useOrderMutation } from 'hooks/data/useOrderMutation';
import { useAnalytics } from 'hooks/useAnalytics';

import type { ButtonProps } from 'components/ui';
import type { IOrderCouponInfo, IOrderSummaryLine, } from 'types';
import { OrderSummaryLineTypes } from 'types';

import styles from './OrderSummaryTable.module.scss';

// Constants

const DELETE_KEY_MATCHER_REMOVE_VOUCHER = '/remove-discount/voucher';

// Props

interface Props {
  //
  className?: string,
  rowGroupClassName?: string,
  rowClassName?: string,
  rowValueClassName?: string,
  rowKeyClassName?: string,
  separatorClassName?: string,
  rowKeyContainerClassName?: string,
  deleteBtnClassName?: string,
  infoBtnClassName?: string,
  //
  rows: IOrderSummaryLine[],
  couponInfo?: IOrderCouponInfo,
  //
  deleteBtnProps?: Partial<ButtonProps>,
  //
  infoBtnProps?: Partial<ButtonProps>,
  onInfoBtnClick?: (row: IOrderSummaryLine) => void
  //
}

const OrderSummaryTable = (props: Props) => {
  const {
    //
    className,
    rowGroupClassName,
    rowClassName,
    rowValueClassName,
    rowKeyClassName,
    separatorClassName,
    rowKeyContainerClassName,
    deleteBtnClassName,
    infoBtnClassName,
    //
    rows,
    couponInfo,
    //
    deleteBtnProps,
    infoBtnProps,
    //
    onInfoBtnClick,
    //
  } = props;

  // Hooks

  const { t } = useTranslation();
  const analytics = useAnalytics();

  const [loadingKey, setLoadingKey] = React.useState<IOrderSummaryLine['key']['deleteKey']>(null);

  // Mutation Hooks

  const { isLoading: isMutationLoading, deleteSummaryItem } = useOrderMutation();

  // Handlers

  const handleDeleteBtnClick = (deleteKey: IOrderSummaryLine['key']['deleteKey']) => {
    const {
      code: voucherCode
    } = couponInfo || {};

    if (deleteKey) {
      setLoadingKey(deleteKey);
      deleteSummaryItem({
        path: deleteKey,
        onError: () => {
          Notifications.showError(t('ERRORS.DEFAULT_TOAST'), {
            autoClose: 2000,
            toastId: 'deleteSummaryItem'
          });
        },
        onSuccess: () => {
          // Workaround to detect if this is for deleting a voucher (as this is controlled dynamically form the backend)
          if (deleteKey?.includes(DELETE_KEY_MATCHER_REMOVE_VOUCHER) && voucherCode) {
            analytics.removeVoucher(voucherCode);
          }
        },
        onSettled: () => {
          setLoadingKey(null);
        }
      });
    }
  };

  // Empty

  if (isArrayEmpty(rows)) {
    return null;
  }

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      data-testid="order-summary.table"
    >
      <Rows
        //
        rowGroupClassName={rowGroupClassName}
        rowClassName={rowClassName}
        rowValueClassName={rowValueClassName}
        rowKeyClassName={rowKeyClassName}
        separatorClassName={separatorClassName}
        rowKeyContainerClassName={rowKeyContainerClassName}
        deleteBtnClassName={deleteBtnClassName}
        infoBtnClassName={infoBtnClassName}
        //
        rows={rows}
        //
        deleteBtnProps={deleteBtnProps}
        deleteLoadingKey={isMutationLoading ? loadingKey : null}
        onDeleteBtnClick={handleDeleteBtnClick}
        //
        infoBtnProps={infoBtnProps}
        onInfoBtnClick={onInfoBtnClick}
        //
      />
    </div>
  );
};

// Components

// --- Rows

interface RowsProps {
  //
  rowGroupClassName?: string,
  rowClassName?: string,
  rowValueClassName?: string,
  rowKeyClassName?: string,
  separatorClassName?: string,
  rowKeyContainerClassName?: string,
  deleteBtnClassName?: string,
  infoBtnClassName?: string,
  //
  rows: IOrderSummaryLine[],
  //
  deleteBtnProps?: Partial<ButtonProps>,
  deleteLoadingKey?: IOrderSummaryLine['key']['deleteKey'],
  onDeleteBtnClick?: (key: IOrderSummaryLine['key']['deleteKey']) => void,
  //
  infoBtnProps?: Partial<ButtonProps>,
  onInfoBtnClick?: (row: IOrderSummaryLine) => void
  //
}

const Rows = (props: RowsProps) => {
  const {
    //
    rowClassName,
    rowValueClassName,
    rowKeyClassName,
    separatorClassName,
    rowKeyContainerClassName,
    deleteBtnClassName,
    infoBtnClassName,
    //
    rows,
    //
    deleteBtnProps,
    deleteLoadingKey,
    onDeleteBtnClick,
    //
    infoBtnProps,
    onInfoBtnClick,
    //
  } = props;

  return (
    <>
      {
        rows.map((row, index) => {
          const {
            type, key, modal, group
          } = row || {};
          const { text, deleteKey } = key || {};

          switch (type) {

            // Separator
            case OrderSummaryLineTypes.SEPARATOR: {
              return (
                <Separator
                  className={separatorClassName}
                  key={`${index}-separator`}
                />
              );
            }

            // Default Row
            case OrderSummaryLineTypes.DEFAULT: {
              return (
                <Row
                  key={text || index}
                  //
                  className={rowClassName}
                  keyContainerClassName={rowKeyContainerClassName}
                  keyClassName={rowKeyClassName}
                  valueClassName={rowValueClassName}
                  deleteBtnClassName={deleteBtnClassName}
                  //
                  data={row}
                  //
                  withDeleteBtn={!!deleteKey}
                  deleteBtnProps={deleteBtnProps}
                  isDeleteBtnLoading={deleteKey === deleteLoadingKey}
                  onDeleteBtnClick={() => {
                    onDeleteBtnClick(deleteKey);
                  }}
                />
              );
            }

            // Nested Row
            case OrderSummaryLineTypes.NESTED: {
              return (
                <Row
                  key={text || index}
                  //
                  className={rowClassName}
                  keyContainerClassName={rowKeyContainerClassName}
                  keyClassName={rowKeyClassName}
                  valueClassName={rowValueClassName}
                  infoBtnClassName={infoBtnClassName}
                  //
                  data={row}
                  //
                  withInfoBtn={!!modal}
                  infoBtnProps={infoBtnProps}
                  onInfoBtnClick={() => {
                    onInfoBtnClick(row);
                  }}
                />
              );
            }

            // Grouped
            case OrderSummaryLineTypes.GROUPED: {
              return (
                <RowGroup
                  key={text || index}
                  group={group}
                  {...props}
                />
              );
            }

            // Default
            default:
              return null;
          }
        })
      }
    </>
  );
};

// --- Row

interface RowProps {
  //
  className?: string,
  valueClassName?: string,
  keyClassName?: string,
  keyContainerClassName?: string,
  deleteBtnClassName?: string,
  infoBtnClassName?: string,
  //
  data?: IOrderSummaryLine,
  //
  withDeleteBtn?: boolean,
  isDeleteBtnLoading?: boolean,
  deleteBtnProps?: Partial<ButtonProps>,
  onDeleteBtnClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  //
  withInfoBtn?: boolean,
  infoBtnProps?: Partial<ButtonProps>,
  onInfoBtnClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  //
}

const Row = (props: RowProps) => {
  const {
    //
    className,
    valueClassName,
    keyClassName,
    keyContainerClassName,
    deleteBtnClassName,
    infoBtnClassName,
    //
    data,
    //
    withDeleteBtn,
    isDeleteBtnLoading,
    deleteBtnProps = {},
    onDeleteBtnClick,
    //
    withInfoBtn,
    infoBtnProps = {},
    onInfoBtnClick,
    //
  } = props;

  // Props

  const {
    key,
    value,
    size = '',
  } = data || {};

  const {
    text: keyText,
    bold: keyBold,
    style: keyStyle = '',
  } = key || {};

  const {
    text: valueText,
    bold: valueBold,
    style: valueStyle = ''
  } = value || {};

  // Handlers

  const handleDeleteBtnClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (onDeleteBtnClick) {
      onDeleteBtnClick(e);
    }
  };

  const handleInfoBtnClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (onInfoBtnClick) {
      onInfoBtnClick(e);
    }
  };

  // Render

  return (
    <div
      className={classnames(
        styles.row,
        `size-${size}`,
        className,
      )}
      data-testid="order-summary.table.row"
    >
      <div
        className={classnames(
          styles.keyContainer,
          keyContainerClassName,
        )}
      >

        {/* Key */}
        <div
          className={classnames(
            styles.key,
            { bold: keyBold },
            `color-${keyStyle}`,
            keyClassName,
          )}
          data-testid="order-summary.table.row.label"
        >
          {keyText}
        </div>

        {/* Info Button */}
        {
          withInfoBtn && (
            <Button
              className={classnames(styles.infoBtn, infoBtnClassName)}
              //
              size="small"
              icon="info-circle"
              iconSize={18}
              iconStrokeWidth={2}
              //
              onClick={handleInfoBtnClick}
              {...infoBtnProps}
            />
          )
        }

        {/* Delete Button */}
        {
          withDeleteBtn && (
            <Button
              className={classnames(styles.deleteBtn, deleteBtnClassName)}
              //
              size="small"
              icon="x"
              iconSize={16}
              iconStrokeWidth={3}
              //
              loading={isDeleteBtnLoading}
              disabled={isDeleteBtnLoading}
              //
              onClick={handleDeleteBtnClick}
              {...deleteBtnProps}
            />
          )
        }
      </div>

      {/* Value */}
      <div
        className={classnames(
          styles.value,
          { bold: valueBold },
          `color-${valueStyle}`,
          valueClassName,
        )}
      >
        {valueText}
      </div>

    </div>
  );
};

// --- Row Group

interface RowGroupProps extends Omit<RowsProps, 'rows'> {
  group: IOrderSummaryLine['group']
}

const RowGroup = (props: RowGroupProps) => {
  const {
    rowGroupClassName,
    //
    group,
    //
    ...rest
  } = props;

  // Props

  const {
    summary
  } = group || {};

  // Empty

  if (isArrayEmpty(summary)) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.rowGroup,
        rowGroupClassName
      )}
    >
      <Rows
        {...rest}
        rows={summary}
      />
    </div>
  );
};

// --- Separator

interface SeparatorProps {
  className?: string
}

const Separator = (props: SeparatorProps) => {
  const { className } = props;
  return (
    <div className={classnames(styles.separator, className)} />
  );
};

// Export

export default OrderSummaryTable;
