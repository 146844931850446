import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Accordion } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import MenuItemLink, { isLinkActive } from './MenuItemLink';
import MenuItemSeparator from './MenuItemSeparator';

import type { IMenuItemLink } from 'types';

import styles from './MenuItemWithAccordion.module.scss';

interface Props {
  activeChildRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  //
  className?: string,
  linkClassName?: string,
  linkActiveClassName?: string,
  subLinkClassName?: string,
  subLinkActiveClassName?: string,
  iconClassName?: string,
  labelClassName?: string,
  //
  item: IMenuItemLink,
  //
  withIndent?: boolean,
  //
  accordionProps?: Partial<React.ComponentProps<typeof Accordion>>,
  //
  onClick?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseEnter?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseLeave?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const MenuItemWithAccordion = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>((
  props: Props,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) => {
  const {
    activeChildRef,
    //
    className,
    linkClassName,
    linkActiveClassName,
    subLinkClassName,
    subLinkActiveClassName,
    iconClassName,
    labelClassName,
    //
    withIndent,
    //
    item,
    //
    accordionProps,
    //
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const {
    slug,
    icon,
    iconImage,
    children,
  } = item || {};

  // Hooks

  const router = useRouter();

  const [active, setActive] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  // Effects

  React.useEffect(() => {
    const { asPath } = router;

    const hasActiveChild = children?.some((c) => {
      if (c === 'separator') return false;
      return asPath.startsWith(c?.href) || asPath.startsWith(c?.matchHref);
    });

    if (hasActiveChild) {
      setActive(true);
      setExpanded(true);
    } else {
      setActive(false);
    }
  }, [router]);

  // Handlers

  const toggleExpanded = React.useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  // Props

  const {
    className: accordionClassName,
    headerClassName,
    contentClassName,
    ...rest
  } = accordionProps || {};

  const { asPath, query } = router;
  const { originalPathname = '' } = query || {};

  // Render

  return (
    <Accordion
      className={classnames(
        styles.root,
        accordionClassName,
        className
      )}
      headerClassName={classnames(
        styles.header,
        headerClassName,
      )}
      expanded={expanded}
      title={(
        <MenuItemLink
          ref={ref}
          //
          className={classnames({ [styles.expanded]: expanded }, linkClassName)}
          activeClassName={linkActiveClassName}
          labelClassName={styles.label}
          //
          item={item}
          active={active}
          //
          onClick={(_, e) => {
            toggleExpanded();
            onClick(item, e);
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      iconStrokeWidth={2}
      withAnimation
      {...rest}
    >

      {/* Content */}
      <div
        className={
          classnames(
            styles.content,
            { [styles.indented]: withIndent },
            { [styles.withImage]: !!iconImage || !!icon },
            contentClassName
          )
        }
      >

        {/* List */}
        <ul
          className={classnames(
            styles.list,
            { [styles.visible]: expanded }
          )}
        >
          {
            children.map((child, index) => {

              // Separator

              if (child === 'separator') {
                return (
                  <MenuItemSeparator />
                );
              }

              // Props

              const {
                exact,
                href,
                matchHref,
                hidden,
              } = child || {};

              // Hidden

              if (hidden) return null;

              // Active

              const isActive = isLinkActive({
                asPath,
                originalPathname,
                href,
                matchHref,
                exact,
              });

              // Render

              return (
                <li
                  key={`${slug}-sub-${index}`}
                  className={styles.item}
                >
                  {
                    !isArrayEmpty(child?.children)
                      ? (
                        // With sub links
                        <MenuItemWithAccordion
                          activeChildRef={activeChildRef}
                          //
                          linkClassName={classnames(
                            styles.link,
                            { [styles.indented]: withIndent },
                            { [styles.withImage]: !!iconImage || !!icon },
                            { [styles.withChildImage]: !!child?.iconImage || !!child?.icon },
                            { [subLinkClassName]: subLinkClassName },
                          )}
                          linkActiveClassName={classnames(
                            styles.active,
                            { [subLinkActiveClassName]: subLinkActiveClassName },
                          )}
                          subLinkClassName={subLinkClassName}
                          subLinkActiveClassName={subLinkActiveClassName}
                          iconClassName={iconClassName}
                          labelClassName={labelClassName}
                          //
                          item={child}
                          //
                          withIndent={withIndent}
                          //
                          onClick={onClick}
                        />
                      ) : (
                        // Without sub links
                        <MenuItemLink
                          ref={
                            isActive
                              ? activeChildRef
                              : null
                          }
                          //
                          className={classnames(
                            styles.link,
                            { [styles.indented]: withIndent },
                            { [styles.withImage]: !!iconImage || !!icon },
                            { [styles.withChildImage]: !!child?.iconImage || !!child?.icon },
                            { [subLinkClassName]: subLinkClassName },
                          )}
                          activeClassName={classnames(
                            styles.active,
                            { [subLinkActiveClassName]: subLinkActiveClassName },
                          )}
                          iconClassName={iconClassName}
                          labelClassName={labelClassName}
                          //
                          item={child}
                          //
                          onClick={onClick}
                        />
                      )
                  }
                </li>
              );
            })
          }
        </ul>
      </div>
    </Accordion>
  );
});

export default MenuItemWithAccordion;
