import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import ProductLabel from '../ProductLabel/ProductLabel';

import type { Breakpoint, IProductLabel, Size } from 'types';
import { Breakpoints, Sizes } from 'types';

import styles from './ProductLabels.module.scss';

export interface Props {
  className?: string,
  labelClassName?: string,
  labels?: IProductLabel[],
  size?: Size,
  responsiveSize?: Size,
  breakpoint?: Breakpoint
}

const ProductLabels = (props: Props) => {

  const {
    className,
    labelClassName,
    labels,
    size = Sizes.MEDIUM,
    responsiveSize = Sizes.SMALL,
    breakpoint = Breakpoints.SM,
  } = props;

  // Empty

  if (!labels || !labels?.length) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[size]]: size },
        { [styles[`responsive-${responsiveSize}`]]: responsiveSize },
        { [styles[`break-at-${breakpoint}`]]: breakpoint },
        className
      )}
      style={{
        '--breakpoint': breakpoint
      } as CSSProperties}
    >
      {
        labels?.map((label, index) => (
          <ProductLabel
            key={index}
            className={classnames(styles.label, labelClassName)}
            label={label}
            breakpoint={breakpoint}
            size={size}
            responsiveSize={responsiveSize}
          />
        ))
      }
    </div>
  );
};

export default React.memo(ProductLabels);
