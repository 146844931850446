import React from 'react';
import { debounce } from 'throttle-debounce';

import { CONFIG } from 'constants/config';

interface Params {
  ms?: number,
  initialValue?: number,
  disabled?: boolean
}

type QuantityChangeHandler = (previousValue: number, newValue: number) => void;
type DebouncedFunction = ((previous: number, current: number) => void) & {
  cancel: () => void
};

export function useDebouncedQuantityActions(onQuantityChange: QuantityChangeHandler, params?: Params) {

  const {
    ms = CONFIG.DURATIONS.USER_ACTIONS_DEBOUNCE,
    initialValue,
    disabled
  } = params || {};

  // Refs

  const debounceHandlerRef = React.useRef<DebouncedFunction | null>(null);
  const previousValue = React.useRef<number>(initialValue);

  // Hooks

  const [currentValue, setCurrentValue] = React.useState(initialValue);

  // Handlers

  const handler = React.useCallback((previous: number, current: number) => {
    onQuantityChange(previous, current);
  }, [onQuantityChange]);

  const debouncedHandler = React.useMemo(() => {
    if (debounceHandlerRef.current) {
      debounceHandlerRef.current.cancel();
    }
    debounceHandlerRef.current = debounce(ms, (previous: number, current: number) => {
      if (previous !== current) {
        handler(previous, current);
      }
    });
    return debounceHandlerRef.current;
  }, [handler]);

  // --- Actions

  const increase = React.useCallback(() => setCurrentValue((prev) => prev + 1), []);
  const decrease = React.useCallback(() => setCurrentValue((prev) => prev - 1), []);
  const reset = React.useCallback(() => setCurrentValue(previousValue.current), []);
  const update = React.useCallback((updatedValue: number) => setCurrentValue(updatedValue), []);

  // Effects

  React.useEffect(() => {
    previousValue.current = initialValue;
    setCurrentValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (!disabled) {
      debouncedHandler(previousValue.current, currentValue);
    }
  }, [disabled, debouncedHandler, currentValue]);

  // Return

  return {
    value: currentValue,
    reset,
    decrease,
    increase,
    update
  };
}
