import React from 'react';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';
import { HOLIDAY_ICONS } from 'constants/seasonal';
import { getActiveHoliday } from 'helpers/SeasonalHelpers';

import Image from '../Image/Image';
import Link from '../Link/Link';

import { Holiday } from 'types';

import styles from './Logo.module.scss';

// Types
interface Props {
  className?: string,
  width?: number,
  height?: number,
  size?: {
    height: number,
    width: number
  },
  mobile?: boolean,
  withoutNavigation?: boolean,
  hasHolidayIcon?: boolean
}

const LOGO_TYPES = {
  DEFAULT: IMAGES.BRANDING.LOGO,
  MOBILE: IMAGES.BRANDING.LOGO_MOBILE
};

const Logo = (props: Props) => {
  const {
    className,
    width,
    height,
    size = {
      height: 39,
      width: 147
    },
    mobile,
    withoutNavigation = false,
    hasHolidayIcon = false
  } = props;

  const variant = mobile
    ? LOGO_TYPES.MOBILE
    : LOGO_TYPES.DEFAULT;

  const content = (
    <Image
      src={variant}
      width={width || size?.width}
      height={height || size?.height}
      optimize
      alt="Freshful"
    />
  );

  if (withoutNavigation) {
    return (
      <div className={className}>
        {content}
      </div>
    );
  }

  return (
    <Link href={APP_ROUTES.HOME}>
      <a className={className}>
        {/* Hat */}
        {hasHolidayIcon && (
          <Hat
            className={styles.hat}
            size={37}
          />
        )}

        {content}
      </a>
    </Link>

  );
};

interface HatProps {
  className?: string,
  size: number
}

const Hat = (props: HatProps) => {
  const {
    className,
    size,
  } = props;

  const holiday = getActiveHoliday();

  // Logo Hat

  if (holiday) {
    const imageSrc = getHolidayImage(holiday);

    if (imageSrc) {
      return (
        <div className={classnames(styles.hat, { [styles.christmas]: holiday === Holiday.Christmas }, className)}>
          <Image
            src={imageSrc}
            width={size}
            height={size}
          />
        </div>
      );
    }
  }

  // Default - Empty

  return null;
};

function getHolidayImage(holiday: Holiday) {
  const holidayImages = {
    [Holiday.Christmas]: HOLIDAY_ICONS.HEADER_LOGO_HAT.CHRISTMAS,
  };

  return holidayImages[holiday] || null;
}

Logo.types = LOGO_TYPES;

export default Logo;
