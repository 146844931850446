// Get local consent synced with remote cookie definitions

import * as Cookies from 'common/Cookies';
import { LocalStorage } from 'common/Storage';
import { COOKIE_KEYS, LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { createObjectMap } from 'helpers/ObjectHelpers';

import type {
  IConsent,
  IConsentCookie,
  IConsentCookieDefinition,
  IConsentResponse,
  IConsentStatus,
} from 'types';

import { CONSENT_COOKIE_TYPES } from 'types';

export const CONSENT_VERSION = '2.0.0';

export const GOOGLE_ADS_COOKIE_SLUG = 'google_ads_advertising';
export const GOOGLE_ADS_COOKIE_LABEL = 'GOOGLE ADS';

export const GOOGLE_ADS_PERSONALIZATION_COOKIE_SLUG = 'google_ads_personalization_advertising';
export const GOOGLE_ADS_PERSONALIZATION_COOKIE_LABEL = 'GOOGLE ADS PERSONALIZATION';

export const GOOGLE_ANALYTICS_FUNCTIONAL_COOKIE_SLUG = 'google_analytics_functional';

/**
 * Consent Cookie
 */

// --- Set Consent Cookie

export function setConsentCookie(consent: IConsent) {
  const { cookies = [] } = consent;

  if (isArrayEmpty(cookies)) return;

  // TODO: remove after Google Ads Personalization consent banner is implemented
  let hasSetConsentForGoogleAdsPersonalization = false;

  const cookieConsentValues = cookies.reduce((acc, cookie) => {
    const {
      isAccepted, slug, type, hasSetConsent
    } = cookie || {};
    const hasGrantedConsent = (type === CONSENT_COOKIE_TYPES.FUNCTIONAL || !!isAccepted);
    // TODO: temporary hack, should be removed
    if (slug === GOOGLE_ANALYTICS_FUNCTIONAL_COOKIE_SLUG) {
      return acc;
    }
    acc[slug] = hasGrantedConsent;
    // TODO: remove after Google Ads Personalization consent banner is implemented
    if (slug === GOOGLE_ADS_PERSONALIZATION_COOKIE_SLUG) {
      hasSetConsentForGoogleAdsPersonalization = !!hasSetConsent;
    }
    return acc;
  }, {} as Record<string, boolean>);

  // TODO: remove after Google Ads Personalization consent banner is implemented
  if (!hasSetConsentForGoogleAdsPersonalization) {
    cookieConsentValues[GOOGLE_ADS_PERSONALIZATION_COOKIE_SLUG] = cookieConsentValues[GOOGLE_ADS_COOKIE_SLUG];
  }

  const value = JSON.stringify(cookieConsentValues);
  Cookies.set(COOKIE_KEYS.CONSENT, value, { expires: 365 });

  return value;
}

// --- Get Consent Cookie

export function getConsentCookie() {
  return Cookies.get(COOKIE_KEYS.CONSENT);
}

/**
 * Should Not Track
 */

export function getShouldNotTrack() {
  return LocalStorage.get(LOCAL_STORAGE_KEYS.DO_NOT_TRACK);
}

/**
 * Default Consent
 */

// --- Get default consent

export function getDefaultConsent(consentCookieDefinitions: IConsentCookieDefinition[], isAccepted: boolean = false): IConsent | undefined {
  if (isArrayEmpty(consentCookieDefinitions)) {
    return {
      cookies: [],
      hasGivenConsent: true,
      shouldRequestConsent: false,
    };
  }

  return parseConsentCookieDefinitions(consentCookieDefinitions, isAccepted);
}

// --- Parse consent cookie definitions

function parseConsentCookieDefinitions(consentCookieDefinitions: IConsentCookieDefinition[], isAccepted: boolean = false): IConsent | undefined {
  if (!consentCookieDefinitions?.length) return undefined;

  const cookies = consentCookieDefinitions.map((cookieDefinition) => {
    const { type } = cookieDefinition || {};
    return {
      ...cookieDefinition,
      isAccepted: (type === CONSENT_COOKIE_TYPES.FUNCTIONAL) || isAccepted,
      isNew: true,
      hasSetConsent: true
    };
  });

  return {
    cookies,
    hasGivenConsent: true,
    shouldRequestConsent: false,
  };
}

/**
 * Local Consent
 */

// --- Get local consent

export function getLocalConsent(consentCookieDefinitions: IConsentCookieDefinition[]): IConsent | undefined {
  const storedConsent = getStoredConsent();
  const parsedStoredConsent = parseStoredConsent(storedConsent, consentCookieDefinitions);
  return parsedStoredConsent;
}

// --- Get stored consent

function getStoredConsent(): IConsent {
  return LocalStorage.get(LOCAL_STORAGE_KEYS.COOKIE_POLICY) || {};
}

// --- Get updated stored consent

export function getUpdatedStoredConsent(consent: IConsent) {
  const storedConsent = getStoredConsent();

  const { cookies = [] } = consent || {};
  const { cookies: storedCookies = [] } = storedConsent || {};

  const storedCookieMap = createObjectMap<IConsentCookie, false>(storedCookies, 'slug', false);

  cookies.forEach((cookie) => {
    const { slug } = cookie || {};
    storedCookieMap[slug] = {
      ...cookie,
      hasSetConsent: true
    };
  });

  return {
    ...consent,
    cookies: Object.values(storedCookieMap),
    touched: true,
    timestamp: Date.now(),
  };
}

// --- Update local consent

export function updateStoredConsent(consent: IConsent) {
  const {
    timestamp,
    cookies,
  } = consent || {};

  LocalStorage.set(LOCAL_STORAGE_KEYS.COOKIE_POLICY, {
    timestamp: timestamp || Date.now(),
    cookies,
    touched: true,
  });
}

// --- Clear local consent

export function clearStoredConsent() {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.COOKIE_POLICY);
}

// --- Parse stored consent

function parseStoredConsent(consent: IConsent, consentCookieDefinitions: IConsentCookieDefinition[]): IConsent | undefined {
  if (!consent || !consentCookieDefinitions?.length) return undefined;

  const {
    touched,
    timestamp,
    cookies: storedCookies = [],
  } = consent || {};

  const cookieMap = createObjectMap(storedCookies, 'label', false);

  const cookies = consentCookieDefinitions.reduce((acc, cookieDefinition) => {
    const { label } = cookieDefinition || {};
    const cookie = cookieMap[label];
    const { isAccepted = false } = cookie || {};
    const hasSetConsent = !!cookie;
    acc.push({
      ...cookieDefinition,
      isAccepted,
      isNew: !hasSetConsent,
      hasSetConsent,
    });
    return acc;
  }, [] as IConsentCookie[]);

  const hasGivenConsentForAllCookies = cookies.every(({ hasSetConsent }) => hasSetConsent);

  return {
    timestamp: timestamp ? Math.floor(new Date(timestamp).getTime() / 1000) : undefined,
    cookies,
    hasGivenConsent: !!touched,
    shouldRequestConsent: (!touched || !hasGivenConsentForAllCookies),
  };
}

/*
 * Remote Consent
 */

// --- Get remote consent

export function getRemoteConsent(userConsent?: IConsentResponse, consentCookieDefinitions?: IConsentCookieDefinition[]): IConsent | undefined {
  if (!userConsent) return undefined;
  return parseUserConsent(userConsent, consentCookieDefinitions);
}

// --- Parse user consent

function parseUserConsent(userConsent?: IConsentResponse, cookieDefinitions?: IConsentCookieDefinition[]): IConsent | undefined {
  if (!userConsent || !cookieDefinitions?.length) return undefined;

  const {
    cookies: remoteExistingCookies,
    newCookies: remoteNewCookies
  } = userConsent || {};

  const existingCookieMap = createObjectMap(remoteExistingCookies, 'slug', false);
  const newCookieMap = createObjectMap(remoteNewCookies, (slug: string) => slug, false);

  const status = getConsentStatus();
  const { version } = status || {};

  const cookies = cookieDefinitions.reduce((acc, cookieDefinition) => {
    const { slug } = cookieDefinition || {};

    if (newCookieMap[slug]) {
      acc.push({
        ...cookieDefinition,
        isAccepted: false,
        isNew: true,
        hasSetConsent: false
      });
      return acc;
    }

    const cookie = existingCookieMap[slug];
    const { isAccepted = false } = cookie || {};
    const hasSetConsent = !!cookie;

    acc.push({
      ...cookieDefinition,
      ...cookie,
      isAccepted,
      hasSetConsent
    });

    return acc;
  }, [] as IConsentCookie[]);

  const hasDeclinedOptionalCookies = cookies.every(({ type, isAccepted, hasSetConsent }) => {
    return (
      type === CONSENT_COOKIE_TYPES.FUNCTIONAL
      || (hasSetConsent && !isAccepted)
    );
  });

  const shouldRequestDeclinedConsent = hasDeclinedOptionalCookies && version !== CONSENT_VERSION;

  return {
    cookies: shouldRequestDeclinedConsent
      ? cookies.map((cookie) => ({ ...cookie, isNew: true, hasSetConsent: false }))
      : cookies,
    hasGivenConsent: cookies.some(({ hasSetConsent }) => hasSetConsent),
    shouldRequestConsent: (
      cookies.some(({ isNew, hasSetConsent }) => isNew && !hasSetConsent)
      || shouldRequestDeclinedConsent
    )
  };
}

/**
 * Consent Status
 */

export function updateConsentStatus(status?: IConsentStatus) {
  LocalStorage.set(LOCAL_STORAGE_KEYS.CONSENT, status || {
    version: CONSENT_VERSION
  });
}

export function getConsentStatus(): IConsentStatus {
  return LocalStorage.get(LOCAL_STORAGE_KEYS.CONSENT);
}
