import React from 'react';
import classnames from 'classnames';

import { Button, Icon, Link } from 'components/ui';
import { useAnalytics } from 'hooks/useAnalytics';

import { type CarouselSlotType, CarouselSlotTypeEnum, type IAnalyticsListProps } from 'types';

import styles from './ViewMoreLink.module.scss';

interface Props {
  className?: string,
  name?: string,
  carouselIndex?: number,
  carouselType?: CarouselSlotType,
  link: {
    as?: string,
    title?: string,
    text?: string,
    url: string
  },
  listProps?: IAnalyticsListProps,
  asCard?: boolean,
  onLinkClick?: () => void
}

const ViewMoreLink = (props: Props) => {
  const {
    className = '',
    name = '',
    carouselIndex,
    carouselType,
    link,
    listProps,
    asCard,
    onLinkClick
  } = props;

  const {
    url, text, title, as
  } = link || {};

  const displayedText = text || title;

  // Hooks

  const analytics = useAnalytics();

  // Empty

  if (!url || !displayedText) return null;

  const classes = classnames(
    styles.viewMore,
    { [styles.viewMoreCard]: asCard },
    { [styles.viewMoreLink]: !asCard },
    { [styles.recipesViewMoreCard]: asCard && carouselType === CarouselSlotTypeEnum.RECIPES },
    className
  );

  // Handlers

  const handleClick = () => {
    switch (carouselType) {
      case CarouselSlotTypeEnum.PRODUCTS:
        analytics.selectProductsCarousel(name, carouselIndex, listProps);
        break;
      case CarouselSlotTypeEnum.RECIPES:
        analytics.selectRecipeCarousel(name, carouselIndex, listProps);
        break;
      default:
        break;
    }
    if (onLinkClick) {
      onLinkClick();
    }
  };

  // Return

  return (
    <Link
      href={url}
      as={as}
      prefetch={false}
      passHref
      legacyBehavior
    >
      <Button
        tag="a"
        className={classes}
        contentClassName={styles.viewMoreContent}
        onClick={handleClick}
      >
        {
          !asCard
            ? (
              // Card
              <>
                <span className={styles.viewMoreText}>
                  {displayedText}
                </span>
                <Icon
                  className={styles.viewMoreIcon}
                  name="arrow-narrow-right"
                  size={18}
                  strokeWidth={2}
                />
              </>
            ) : (
              // Button
              <>
                <div className={styles.viewMoreIcon}>
                  <Icon
                    className={styles.icon}
                    name="arrow-narrow-right"
                    size={24}
                    strokeWidth={2}
                  />
                </div>
                <div className={styles.viewMoreText}>{displayedText}</div>
              </>
            )
        }
      </Button>
    </Link>
  );
};

export default ViewMoreLink;
