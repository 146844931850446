import React from 'react';
import PropTypes from 'prop-types';

import * as AddressHelpers from 'helpers/AddressHelpers';
import { useConfig } from 'hooks/data/useConfig';

import OrderInfoBlock from '../OrderInfoBlock/OrderInfoBlock';

// import styles from './OrderInfoDelivery.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const OrderInfoDelivery = (props) => {

  const {
    order = {},
    label,
    value,
    subValue,
    withIcon,
    withAddress = true,
    ...rest
  } = props;

  const {
    state, deliveryDate, shippingAddress
  } = order;

  const addressLabel = AddressHelpers.getAddressLabel(shippingAddress);

  const { data: { orders: mappings = {} } = {} } = useConfig();
  const { dateLabel: deliveryDateLabel } = mappings[state] || {};

  return (
    <OrderInfoBlock
      icon={withIcon ? 'truck' : null}
      label={label || deliveryDateLabel}
      value={value || deliveryDate}
      subValue={subValue || (withAddress ? addressLabel : null)}
      {...rest}
    />
  );
};

OrderInfoDelivery.propTypes = {
  // specific props
  order: PropTypes.shape({
    checkoutCompletedAt: PropTypes.string
  }),
  label: PropTypes.string,
  value: PropTypes.string,
  withIcon: PropTypes.bool,

  // block props
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subValueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withBorder: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default OrderInfoDelivery;
