import type { TFunction } from 'next-i18next';

import { LocalStorage } from 'common/Storage';
import { APP_ROUTES } from 'constants/routes';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isArrayEmpty, sortByKey } from 'helpers/ArrayHelpers';
import { isObjectEmpty, isSearchMatch } from 'helpers/ObjectHelpers';
import { hashString } from 'helpers/StringHelpers';
import { invertObject } from 'helpers/utils';

import type {
  IGenericProduct,
  IGiftProduct,
  IGroup,
  IGroups,
  IMealVoucherPaymentMethod,
  IMoneyPaymentMethod,
  IOngoingOrder,
  IOrder,
  IOrderPaymentTotal,
  IOrderProblem,
  IOrderProduct,
  IPaymentMethod,
  IPointsPaymentMethod,
  IProduct,
  IProductVariant,
  OrderProblemType
} from 'types';

// --- Addresses

export function hasAddressSet(order: IOrder) {
  return !!order?.currentSelectedAddress;
}

export function getOrderAddress(order: IOrder) {
  return order?.currentSelectedAddress;
}

export function getOrderAddressId(order: IOrder) {
  return order?.shippingAddressId;
}

// --- Intervals

export function getOrderInterval(order: IOrder) {
  return order?.schedule;
}

export function getOrderIntervalId(order: IOrder) {
  return order?.schedule?.id;
}

// --- Products

export function getValidProducts(order: IOrder, searchKey?: string): IOrderProduct[] {
  return order?.items?.filter((item) => {
    if (searchKey) {
      return (
        item.isOk
        && isSearchMatch(
          item,
          searchKey,
          {
            keys: ['brand', 'productName'],
            caseSensitive: false,
            replaceDiacritics: true,
            separated: false,
          }
        ));
    }
    return item.isOk;
  });
}

export function getInvalidProducts(order: IOrder, searchKey?: string): IOrderProduct[] {
  return order?.items?.filter((item) => {
    if (searchKey) {
      return (
        !item.isOk
         && isSearchMatch(
           item,
           searchKey,
           {
             keys: ['brand', 'productName'],
             caseSensitive: false,
             replaceDiacritics: true,
             separated: false,
           }
         ));
    }
    return !item.isOk;
  });
}

export function getSummaryProducts(order: IOrder, searchKey: string): IOrderProduct[] {
  if (searchKey && order?.summaryItems) {
    return order.summaryItems.filter((item) => {
      return isSearchMatch(
        item,
        searchKey,
        {
          keys: ['brand', 'productName'],
          caseSensitive: false,
          replaceDiacritics: true,
          separated: false,
        }
      );
    });
  }

  return order?.summaryItems || [];
}

export function searchAndSortSummaryProducts(order: IOrder, searchKey: string): {
  resultsCount: number,
  products: IOrderProduct[]
} {
  if (searchKey && order?.summaryItems) {
    const searchMatchProducts: IOrderProduct[] = [];
    const restProducts: IOrderProduct[] = [];

    order.summaryItems.forEach((item) => {
      if (isSearchMatch(
        item,
        searchKey,
        {
          keys: ['brand', 'productName'],
          caseSensitive: false,
          replaceDiacritics: true,
          separated: true,
        }
      )
      ) {
        searchMatchProducts.push(item);
      } else {
        restProducts.push(item);
      }
    });

    return {
      resultsCount: searchMatchProducts?.length,
      products: [...searchMatchProducts, ...restProducts]
    };
  }

  return {
    resultsCount: 0,
    products: order?.summaryItems || []
  };
}

// ------ Group Products

export function groupProductsByCategory(products: IGenericProduct[]): IGroups<IGenericProduct> {
  const groups: Record<string, IGroup<IGenericProduct>> = {
    unknown: {
      items: [],
      position: Infinity,
      category: undefined
    }
  };

  let count = 0;

  products.forEach((product) => {
    const { name, icon, ...rest } = product?.breadcrumbs?.[0] || {};

    if (name) {
      if (groups[name]) {
        groups[name].items.push(product);
      } else {
        groups[name] = {
          items: [product],
          position: count,
          name,
          icon,
          ...rest
        };
        ++count;
      }
    } else {
      groups.unknown.items.push(product);
    }
  });

  return sortByKey(
    Object.values(groups),
    'position'
  ).filter((group: IGroup<IOrderProduct>) => group?.items?.length);
}

// --- Gifts

export function getValidGifts(gifts: IGiftProduct[], searchKey?: string): IGiftProduct[] {
  return gifts?.filter(({ removed, product }) => {
    if (searchKey) {
      return (
        !removed
        && isSearchMatch(
          product,
          searchKey,
          {
            keys: ['brand', 'name'],
            caseSensitive: false,
            replaceDiacritics: true,
            separated: false,
          }
        )
      );
    }
    return !removed;
  });
}

export function getAvailableGifts(gifts: IGiftProduct[]): IGiftProduct[] {
  return gifts?.filter(({ removed }) => removed);
}

// --- Order Item

export function getOrderItem(order?: IOrder, product?: IProduct | IProductVariant): IOrderProduct | undefined {
  if (!order || !product) return undefined;
  const { variantCode } = product || {};
  return order?.items?.find((item) => item.variantCode === variantCode);
}

export function getOrderItemQuantity(order?: IOrder | undefined, product?: IProduct | IProductVariant): number {
  if (!order || !product || isArrayEmpty(order?.items)) return 0;

  try {
    const item = getOrderItem(order, product);
    return item?.quantity || 0;

  } catch (err) {
    console.error('Error while getting the order quantity for product', product, err);
  }

  return 0;
}

// --- Payments

export function areAllPaymentMethodsUsed(order: IOrder): boolean {
  if (isArrayEmpty(order?.payments, true)) return false;

  return order?.payments?.every((method) => method?.amount >= 0);
}

// ------ Checkout
// TODO: move to CheckoutHelpers

export function getSelectedPaymentsMethods(order: IOrder, paymentMethods: IPaymentMethod[]): {
  money: IMoneyPaymentMethod,
  mealVouchers: IMealVoucherPaymentMethod,
  points: IPointsPaymentMethod
} {
  const paymentsDetails: {
    money: IMoneyPaymentMethod,
    mealVouchers: IMealVoucherPaymentMethod,
    points: IPointsPaymentMethod
  } = {
    money: null,
    mealVouchers: null,
    points: null,
  };

  if (order && paymentMethods) {
    try {
      order?.payments?.forEach((orderPayment) => {
        const { amount, method: orderPaymentMethod } = orderPayment;
        const { code } = orderPaymentMethod || {};

        if (code) {
          const method = paymentMethods.find((m) => m.code === code);

          const {
            type,
            // id: paymentMethodUri,
            uri,
            name,
            // description,
            instructions,
            checkoutEnabled,
            eligible,
            // payment: paymentRef,
          } = method;

          switch (type) {

            case 'money': {
              const { payment } = method;
              paymentsDetails.money = {
                type: 'money',
                code,
                uri,
                amount,
                name,
                // description,
                instructions,
                checkoutEnabled,
                eligible,
                payment,
              // paymentMethodUri,
              // paymentRef
              };
              break;
            }

            case 'meal-voucher': {
              const { mealVoucher } = method;
              paymentsDetails.mealVouchers = {
                type: 'meal-voucher',
                code,
                uri,
                amount,
                name,
                instructions,
                // description,
                checkoutEnabled,
                eligible,
                // paymentMethodUri,
                // paymentRef,
                mealVoucher
              };
              break;
            }

            case 'points': {
              const { payment } = method;
              paymentsDetails.points = {
                type: 'points',
                code,
                uri,
                amount,
                name,
                // description,
                instructions,
                checkoutEnabled,
                eligible,
                payment,
              // paymentMethodUri,
              // paymentRef
              };
              break;
            }

            default:
              console.error('Unknown payment method type', type);
          }
        }
      });
    } catch (err) {
      console.error('Failed to get payments details', err);
    }
  }

  return paymentsDetails;
}

// TODO: check if needed
export function getPaymentMethodsTotals(
  order: IOrder,
  paymentMethods: IPaymentMethod[],
  withoutEmptyValues = false
): IOrderPaymentTotal[] {
  if (isArrayEmpty(order?.payments, true)) return [];

  const orderPayments = order?.payments?.reduce<Record<string, IOrderPaymentTotal>>((acc, paymentMethod) => {
    const { amount, currency, method } = paymentMethod || {};
    const { code, name } = method || {};

    if (code) {
      acc[code] = {
        amount,
        currency,
        label: name || ''
      };
    }

    return acc;
  }, {});

  const totals: IOrderPaymentTotal[] = paymentMethods?.map((paymentMethod) => {
    const { code } = paymentMethod || {};
    return orderPayments[code] || null;
  });

  return withoutEmptyValues
    ? totals?.filter((t) => !isObjectEmpty(t))
    : totals;
}

// TODO: check if needed
export function hasNonMoneyPayment(order: IOrder): boolean {
  return order?.payments?.some((method) => (
    method?.method?.type !== ORDER_PAYMENT_METHOD_TYPES.MONEY
  ));
}

export function hasDisabledPaymentMethod(order: IOrder): boolean {
  if (isArrayEmpty(order?.payments, true)) {
    return false;
  }

  return order?.payments?.some((payment) => (
    payment?.method?.checkoutEnabled === false
  ));
}

export function hasSelectedPaymentMethod(order: IOrder): boolean {
  if (isArrayEmpty(order?.payments, true)) {
    return false;
  }

  return order?.payments?.some((payment) => (
    payment?.method
  ));
}

export function hasRemainingPayment(order: IOrder): boolean {
  if (isArrayEmpty(order?.payments, true)) {
    return false;
  }

  return order?.payments?.some((payment) => (
    !payment?.method
  ));
}

// ------ Order Details

export function getOrderPaymentMethodsNames(order: IOrder) {
  const { payments } = order || {};

  return (payments || [])
    .filter((payment) => payment?.state !== ORDER_PAYMENT_STATES.FAILED)
    .map((payment) => payment?.method?.name)
    .filter((x, i, a) => a.indexOf(x) === i);
}

// TODO: check if needed
export function getOrderPayments(order: IOrder, withoutEmptyValues = false): any[] {
  if (isArrayEmpty(order?.payments, true)) return [];

  const payments = order?.payments?.map((payment) => {
    const { amount, currency, method } = payment || {};
    const { code, name } = method || {};
    return {
      code, label: name, amount, currency,
    };
  });

  return withoutEmptyValues
    ? payments.filter((p) => !!p?.amount)
    : payments;
}

// TODO: check if needed
export function hasMultiplePaymentMethods(order: IOrder): boolean {
  return areAllPaymentMethodsUsed(order) && order?.payments?.length >= 2;
}

// --- Unauthenticated Order

export function storeUnauthenticatedOrder(tokenValue: string): void {
  LocalStorage.set(LOCAL_STORAGE_KEYS.ORDER_ID, tokenValue);
}

export function getUnauthenticatedOrder(): string {
  return LocalStorage.get(LOCAL_STORAGE_KEYS.ORDER_ID);
}

export function removeUnauthenticatedOrder(): void {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.ORDER_ID);
}

// --- Report a problem

export function getAvailableOrderProblemTypes(
  order: IOrder,
  orderProblemTypesMappings: IOrderProblem[]
): IOrderProblem[] {
  const { reportProblemTypes } = order || {};
  return orderProblemTypesMappings.filter(({ type }) => reportProblemTypes?.includes(type));
}

export function canReportProblem(order: IOrder): boolean {
  const { reportProblemTypes } = order || {};
  return !isArrayEmpty(reportProblemTypes, true);
}

export function getReportProblemButtonLabel(order: IOrder, t: TFunction): string {
  const { reportProblemTypes } = order || {};

  if (!reportProblemTypes) return '';

  const translationGenericKey = 'account:ORDER_HISTORY.REPORT_ISSUE_BUTTON.GENERIC';

  switch (reportProblemTypes?.length) {

    case 0:
      return '';

    case 1: {
      const orderProblemKey = invertObject(ORDER_PROBLEM_TYPES)[reportProblemTypes[0]] || null;
      const translationKey = `account:ORDER_HISTORY.REPORT_ISSUE_BUTTON.${orderProblemKey}`;
      return t([translationKey, '']) || t(translationGenericKey);
    }

    default:
      return t(translationGenericKey);
  }
}

export function getProblemTypeIcon(type: OrderProblemType): string {
  return (
    ORDER_PROBLEM_TYPES_ICONS[type]
    || DEFAULT_ICON
  );
}

// --- Order Feedback

export function getOrderFeedbackLink(order: IOrder, rating: string): string {
  const { tokenValue } = order;
  return `${APP_ROUTES.ORDER_FEEDBACK}?oid=${tokenValue}&rating=${rating}`;
}

// --- Add To Order

export function canAddToOrder(orders: IOngoingOrder[], bypassEligibilityCheck?: boolean): boolean {
  if (!orders || !orders?.length) return false;

  const eligibleOrders = orders.filter((order) => (
    bypassEligibilityCheck ? true : order.eligible));

  return eligibleOrders.length > 0;
}

export function hashOrderItems(order: IOrder): string {
  const { items = [] } = order || {};

  const itemsString = JSON.stringify(
    sortByKey(items.map(({ variantCode, quantity }) => ({ variantCode, quantity })), 'variantCode')
  );

  return hashString(itemsString).toString();
}

// --- Constants

const DEFAULT_ICON = 'message-circle';

export const ORDER_PAYMENT_METHOD_TYPES = {
  MONEY: 'money',
  MEAL_VOUCHER: 'meal-voucher'
};

export const ORDER_PAYMENT_STATES = {
  FAILED: 'failed'
};

export const ORDER_PROBLEM_TYPES = {
  INCOMPLETE: 'incomplete',
  DELIVERY: 'delivery',
  RETURN: 'return',
  OTHER: 'other'
};

const ORDER_PROBLEM_TYPES_ICONS = {
  [ORDER_PROBLEM_TYPES.INCOMPLETE]: 'cart',
  [ORDER_PROBLEM_TYPES.DELIVERY]: 'truck',
  [ORDER_PROBLEM_TYPES.RETURN]: 'replace',
  [ORDER_PROBLEM_TYPES.OTHER]: 'message-circle'
};

export const ORDER_PAYMENT_TYPES = {
  ONLINE: 'online',
  DELIVERY: 'delivery'
};

export const ORDER_FEEDBACK_NEXT_STEPS = {
  LOW_RATING_FORM: 'LOW_RATING_FORM',
  TESTIMONIAL_FORM: 'TESTIMONIAL_FORM',
  COMPLETED_MESSAGE: 'COMPLETED_MESSAGE',
};

export const ORDER_NOTICE_TYPES = {
  DEFAULT: 'default',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};
