import React from 'react';

import * as OrderHelpers from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useConfig } from 'hooks/data/useConfig';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';

import type {
  IAnalyticsListProps,
  IOrder,
  IOrderProduct,
  IProduct,
  IProductVariant
} from 'types';

interface Params {
  //
  order?: IOrder,
  product?: IProduct,
  orderItem?: IOrderProduct,
  variant?: IProductVariant,
  listProps?: IAnalyticsListProps,
  //
  bypassAddressRequirement?: boolean,
  bypassQuantityVariants?: boolean,
  replaceProductVariantCode?: string,
  //
  onLoading?: (isLoading: boolean) => void,
  onQuantityChangeSuccess?: (order: IOrder) => void,
  //
  onAddStart?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onAddSuccess?: (order: IOrder) => void,
  onAddError?: (err: any) => void,
  onAddSettled?: () => void,
  //
  onEditStart?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onEditSuccess?: (order: IOrder, previousQuantity?: number, currentQuantity?: number) => void,
  onEditError?: (err: any) => void,
  onEditSettled?: () => void,
  //
  onRemoveStart?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onRemoveSuccess?: (order: IOrder) => void,
  onRemoveError?: (err: any) => void,
  onRemoveSettled?: () => void
  //
}

export function useProductCardActions(params: Params) {
  const {
    //
    order,
    product,
    orderItem: preselectedOrderItem,
    variant,
    listProps,
    //
    bypassAddressRequirement,
    bypassQuantityVariants,
    replaceProductVariantCode,
    //
    onLoading,
    onQuantityChangeSuccess,
    //
    onAddStart,
    onAddSuccess,
    onAddError,
    onAddSettled,
    //
    onEditStart,
    onEditSuccess,
    onEditError,
    onEditSettled,
    //
    onRemoveStart,
    onRemoveSuccess,
    onRemoveError,
    onRemoveSettled,
    //
  } = params;

  // Hooks

  const { data: config } = useConfig();

  const { errors } = config || {};

  // Mutation Hook

  const {
    isLoading: isMutationLoading,
    isError: isMutationError,
    add: addProduct,
    edit: editProductQuantity,
    remove: removeProduct,
    buyMore,
  } = useOrderProductMutation();

  // Props

  const orderItem = preselectedOrderItem || OrderHelpers.getOrderItem(order, variant || product);
  const { id: orderItemId, isLoading: isOrderItemLoading } = orderItem || {};
  const { tokenValue: orderTokenValue } = order || {};

  // Handlers

  const handleBuyMore = React.useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    if (!order || !product) return;

    if (onAddStart) {
      onAddStart(e);
    }

    const productToAdd = {
      ...product,
      variantCode: variant?.variantCode || product?.variantCode,
      maxAllowedQuantity: variant?.maxAllowedQuantity || product?.maxAllowedQuantity,
      maxAvailableQuantity: variant?.maxAvailableQuantity || product?.maxAvailableQuantity,
    };

    buyMore({
      order,
      product: productToAdd,
      listProps,
      //
      bypassAddressRequirement,
      bypassQuantityVariants,
      replaceProductVariantCode,
      //
      onSuccess: (data: IOrder) => {
        if (onQuantityChangeSuccess) {
          onQuantityChangeSuccess(data);
        }

        if (onAddSuccess) {
          onAddSuccess(data);
        }
      },
      onError: (err: any) => {
        if (onAddError) {
          onAddError(err);
        }
      },
      onSettled: () => {
        if (onAddSettled) {
          onAddSettled();
        }
      },
    });
  }, [order,
    product,
    variant,
    listProps,
    //
    bypassAddressRequirement,
    bypassQuantityVariants,
    replaceProductVariantCode,
    //
    errors,
    //
    onLoading,
    onQuantityChangeSuccess,
    //
    onAddSuccess,
    onAddError,
    onAddSettled,]);

  const handleAdd = React.useCallback((e?: React.MouseEvent<HTMLButtonElement>, quantity?: number) => {
    if (!order || !product) return;

    if (onAddStart) {
      onAddStart(e);
    }

    const productToAdd = {
      ...product,
      variantCode: variant?.variantCode || product?.variantCode,
      maxAllowedQuantity: variant?.maxAllowedQuantity || product?.maxAllowedQuantity,
      maxAvailableQuantity: variant?.maxAvailableQuantity || product?.maxAvailableQuantity,
    };

    addProduct({
      order,
      product: productToAdd,
      listProps,
      quantity,
      //
      bypassAddressRequirement,
      bypassQuantityVariants,
      replaceProductVariantCode,
      //
      onSuccess: (data: IOrder) => {
        if (onQuantityChangeSuccess) {
          onQuantityChangeSuccess(data);
        }

        if (onAddSuccess) {
          onAddSuccess(data);
        }
      },
      onError: (err: any) => {
        if (onAddError) {
          onAddError(err);
        }
      },
      onSettled: () => {
        if (onAddSettled) {
          onAddSettled();
        }
      },
    });
  }, [
    order,
    product,
    variant,
    listProps,
    //
    bypassAddressRequirement,
    bypassQuantityVariants,
    replaceProductVariantCode,
    //
    errors,
    //
    onLoading,
    onQuantityChangeSuccess,
    //
    onAddSuccess,
    onAddError,
    onAddSettled,
  ]);

  // --- Edit

  const handleEdit = React.useCallback((previousQuantity: number, currentQuantity: number) => {
    if (!order || !orderItem) return;

    if (onEditStart) {
      onEditStart();
    }

    const productToEdit = product
      ? {
        ...product,
        variantCode: variant?.variantCode || product?.variantCode
      } : undefined;

    editProductQuantity({
      order,
      product: productToEdit,
      orderItem,
      listProps,
      //
      previousQuantity,
      quantity: currentQuantity,
      //
      onSuccess: (data: IOrder) => {
        if (onQuantityChangeSuccess) {
          onQuantityChangeSuccess(data);
        }

        if (onEditSuccess) {
          onEditSuccess(data, previousQuantity, currentQuantity);
        }
      },
      onError: (err: any) => {
        if (onEditError) {
          onEditError(err);
        }
      },
      onSettled: () => {
        if (onEditSettled) {
          onEditSettled();
        }
      }
    });
  }, [
    orderTokenValue,
    orderItemId,
    product,
    variant,
    listProps,
    //
    onEditSuccess,
    onEditError,
    onEditSettled,
  ]);

  // --- Remove

  const handleRemove = React.useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    if (!order || !orderItem) return;

    if (onRemoveStart) {
      onRemoveStart(e);
    }

    const productToRemove = product
      ? {
        ...product,
        variantCode: variant?.variantCode || product?.variantCode
      } : undefined;

    removeProduct({
      order,
      product: productToRemove,
      orderItem,
      listProps,
      //
      onSuccess: (data: IOrder) => {
        if (onQuantityChangeSuccess) {
          onQuantityChangeSuccess(data);
        }

        if (onRemoveSuccess) {
          onRemoveSuccess(data);
        }
      },
      onError: (err: any) => {
        if (onRemoveError) {
          onRemoveError(err);
        }
      },
      onSettled: () => {
        if (onRemoveSettled) {
          onRemoveSettled();
        }
      }
    });
  }, [
    orderTokenValue,
    orderItemId,
    product,
    variant,
    listProps,
    //
    onRemoveSuccess,
    onRemoveError,
    onRemoveSettled,
  ]);

  // --- Quantity Change

  const handleQuantityChange = React.useCallback((previousQuantity: number, currentQuantity: number) => {
    ProductHelpers.handleChangeQuantityClick(
      previousQuantity,
      currentQuantity,
      handleRemove,
      handleEdit
    );
  }, [
    handleRemove,
    handleEdit
  ]);

  // Return

  return {
    isOrderItemLoading,
    isProductActionLoading: isMutationLoading,
    isProductActionError: isMutationError,
    //
    handleAdd,
    handleEdit,
    handleBuyMore,
    handleRemove,
    handleQuantityChange,
  };
}
