import React from 'react';
import classnames from 'classnames';

import { useIsOpen } from 'hooks/useIsOpen';

import OrderSummaryRowDetailsModal from './OrderSummaryRowDetailsModal';
import OrderSummaryTable from './OrderSummaryTable';

import type { ButtonProps } from 'components/ui';
import type{ IOrder, IOrderSummaryLine } from 'types';
import { OrderSummaryLineTypes, } from 'types';

import styles from './OrderSummary.module.scss';

interface Props {
  //
  className?: string,
  tableClassName?: string,
  rowGroupClassName?: string,
  rowClassName?: string,
  rowValueClassName?: string,
  rowKeyClassName?: string,
  separatorClassName?: string,
  rowKeyContainerClassName?: string,
  deleteBtnClassName?: string,
  infoBtnClassName?: string,
  //
  order?: IOrder,
  //
  deleteBtnProps?: Partial<ButtonProps>,
  infoBtnProps?: Partial<ButtonProps>
  //
}

const OrderSummary = (props: Props) => {
  const {
    //
    className,
    tableClassName,
    rowGroupClassName,
    rowClassName,
    rowValueClassName,
    rowKeyClassName,
    separatorClassName,
    rowKeyContainerClassName,
    deleteBtnClassName,
    infoBtnClassName,
    //
    order,
    deleteBtnProps,
    //
    infoBtnProps,
    //
  } = props;

  // Hooks

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useIsOpen();

  const [selectedRow, setSelectedRow] = React.useState<IOrderSummaryLine>(null);

  // Props

  const {
    summary: rows = [],
    couponInfo
  } = order || {};

  const hasNestedRows = rows.some(({ type }) => (
    type === OrderSummaryLineTypes.NESTED
  ));

  // Handlers

  const handleRowDetailsModalOpen = React.useCallback((row: IOrderSummaryLine) => {
    setSelectedRow(row);
    openModal();
  }, []);

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Table */}
      <OrderSummaryTable
        //
        className={tableClassName}
        rowGroupClassName={rowGroupClassName}
        rowClassName={rowClassName}
        rowValueClassName={rowValueClassName}
        rowKeyClassName={rowKeyClassName}
        separatorClassName={separatorClassName}
        rowKeyContainerClassName={rowKeyContainerClassName}
        deleteBtnClassName={deleteBtnClassName}
        infoBtnClassName={infoBtnClassName}
        //
        rows={rows}
        couponInfo={couponInfo}
        //
        deleteBtnProps={deleteBtnProps}
        infoBtnProps={infoBtnProps}
        //
        onInfoBtnClick={handleRowDetailsModalOpen}
        //
      />

      {/* Summary Line Modal */}
      {
        hasNestedRows && (
          <OrderSummaryRowDetailsModal
            isOpen={isModalOpen}
            row={selectedRow}
            onClose={() => {
              closeModal();
            }}
            onAfterClose={() => {
              setSelectedRow(null);
            }}
            //
          />
        )
      }

    </div>
  );
};

export default OrderSummary;
