import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';
import { ORDER_NOTICE_TYPES } from 'helpers/OrderHelpers';

import Alert, { VARIANTS } from '../Alert/Alert';

import type { INotice, Size } from 'types';
import { Sizes } from 'types';

import styles from './Notice.module.scss';

const VARIANTS_MAP = {
  [ORDER_NOTICE_TYPES.DEFAULT]: VARIANTS.WARNING,
  [ORDER_NOTICE_TYPES.INFO]: VARIANTS.INFO,
  [ORDER_NOTICE_TYPES.WARNING]: VARIANTS.WARNING,
  [ORDER_NOTICE_TYPES.SUCCESS]: VARIANTS.SUCCESS,
  [ORDER_NOTICE_TYPES.ERROR]: VARIANTS.ERROR,
};

// Types

interface Props {
  className?: string,
  iconClassName?: string,
  key?: string | number,
  size?: Size,
  notice: INotice,
  onClick?: () => void,
  children?: React.ReactNode
}

const Notice = (props: Props) => {

  const {
    className,
    iconClassName,
    notice,
    size = Sizes.MEDIUM,
    onClick,
    children,
    ...rest
  } = props;

  const {
    type,
    icon,
    title,
    description
  } = notice || {};

  const variant = VARIANTS_MAP[type] || 'default';

  const iconClasses = classnames(
    styles.icon,
    { [styles[size]]: size },
  );

  const AlertIcon = icon
    ? (
      typeof icon === 'string'
        ? (
          <div className={iconClasses}>
            <Image
              src={icon}
              fill
              style={{
                objectFit: 'contain'
              }}
              alt="Notice Icon"
            />
          </div>
        ) : (
          icon
        )
    )
    : null;

  return (
    <Alert
      className={className}
      iconClassName={iconClassName}
      variant={variant}
      icon={AlertIcon}
      withCircledIcon
      title={title}
      description={description}
      size={size}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Alert>
  );
};

export default Notice;
