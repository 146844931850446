import React from 'react';

import { useAuthStoreDispatch } from 'context/AuthenticationContext';

const LogoutRedirect = () => {
  const { logout } = useAuthStoreDispatch();

  React.useEffect(() => {
    logout();
  }, []);

  return null;
};

export default LogoutRedirect;
