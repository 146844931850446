import { isArrayEmpty } from 'helpers/ArrayHelpers';

import { ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES } from 'types';
import type {
  IAccountBenefits, IAccountBenefitsFilter, IAccountNewFeatureNotification, IAccountNewFeatureNotificationsResponse, IFilters,
  Nullable
} from 'types';

// Benefits

export function toAccountBenefits(data: any): IAccountBenefits {
  const {
    content,
    filters
  } = data || {};

  const {
    analyticsLabel,
    segmentId,
    //
    title,
    image,
    //
    mainMonthlyBenefit,
    secondaryMonthlyBenefit,
    lifetimeBenefits,
    //
    additionalInfo,
    btnLabel,
    btnLink,
  } = content || {};

  return {
    meta: {
      analyticsLabel,
      segmentId,
      filter: parseFilters(filters),
    },
    title,
    image,
    monthlyBenefits: {
      main: parseDynamicLines(mainMonthlyBenefit),
      secondary: parseBenefitsBlock({ benefits: secondaryMonthlyBenefit }),
    },
    lifetimeBenefits: parseBenefitsBlock(lifetimeBenefits),
    additionalInfo: !additionalInfo ? [] : parseDynamicLines([
      {
        type: ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES.TEXT_SMALL,
        value: additionalInfo
      }
    ]),
    footer: !btnLabel
      ? undefined
      : {
        actionBtn: {
          label: btnLabel,
          link: btnLink,
        }
      }
  };
}

function parseDynamicLines(lines: any[]) {
  if (!lines) return [];

  return lines
    .filter((line: any) => {
      const { type } = line || {};
      return Object.values(ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES).includes(type);
    })
    .map((line: any) => {
      const {
        type,
        value,
        image,
      } = line || {};

      return {
        type,
        text: value,
        image,
      };
    });
}

function parseBenefitsList(benefits: any[]) {
  if (!benefits) return [];

  return benefits
    .map((benefit: any) => {
      const { icon, label, value } = benefit || {};
      return {
        icon,
        label,
        value
      };
    });
}

function parseBenefitsBlock(data: any) {
  const {
    title,
    description,
    benefits = [],
  } = data || {};

  return {
    title,
    description,
    list: parseBenefitsList(benefits)
  };
}

function parseFilters(filters: IFilters): IAccountBenefitsFilter {
  const { items } = filters || {};

  if (isArrayEmpty(items)) {
    return {
      code: undefined,
      name: undefined,
      options: [],
      selectedOption: undefined,
    };
  }

  const {
    values,
    name: filterName,
    code: filterCode
  } = items[0];

  return values.reduce((acc, item) => {
    const { code, name, isSelected } = item;
    const option = { value: code, label: name };
    acc.options.push(option);
    if (isSelected) {
      acc.selectedOption = option;
    }
    return acc;
  }, {
    code: filterCode,
    name: filterName,
    options: [],
    selectedOption: null,
  } as {
    code: string,
    name: string,
    options: any[],
    selectedOption: any
  });
}

// New Features

export function toAccountNewFeatureNotifications(data: IAccountNewFeatureNotificationsResponse): Nullable<IAccountNewFeatureNotification[]> {
  const {
    intros = []
  } = data || {};

  if (isArrayEmpty(intros)) return null;

  const notifications = intros.map((intro) => {
    const {
      segmentId,
      strapiId,
      //
      analyticsLabel,
      icon,
      text,
    } = intro || {};

    return {
      segmentId,
      featureId: strapiId,
      //
      analyticsLabel,
      icon,
      text,
    };
  });

  return notifications;
}
