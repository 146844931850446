import React from 'react';

import { Link } from 'components/ui';
import * as ProductHelpers from 'helpers/ProductHelpers';

import type { IProductDetails } from 'types';

import styles from './ProductDetailsBrand.module.scss';

interface Props {
  product: IProductDetails,
  withoutLink: boolean
}

const ProductDetailsBrand = (props: Props) => {

  const {
    product,
    withoutLink
  } = props;

  const {
    brand,
    brandFilterCode
  } = product || {};

  // Empty

  if (!brand) return null;

  // Props

  const brandHref = ProductHelpers.getSameBrandLink(brand, brandFilterCode);

  // Render

  return (
    <div className={styles.root}>
      {
        (withoutLink || !brandFilterCode)
          ? (
            <>{brand}</>
          ) : (
            <Link href={brandHref}>
              <a className={styles.brandLink}>{brand}</a>
            </Link>
          )
      }

    </div>
  );
};

export default ProductDetailsBrand;
