import React from 'react';

import { getEOSUserIdFromJwt } from 'helpers/UserHelpers';
import { useUser } from 'hooks/data/useUser';
import { getGeniusSubscriptionSSEConnectionInfo } from 'services/AccountService';

interface Options {
  enabled?: boolean,
  onOpen?: () => void,
  onError?: () => void,
  onMessage?: (event: MessageEvent<any>) => void
}

export function useGeniusStatusListener(options?: Options) {

  const {
    enabled,
    onOpen,
    onError,
    onMessage
  } = options || {};

  // Refs

  const sse = React.useRef<EventSource | null>(null);

  // Hooks

  const { data: user } = useUser({}, { enabled });

  // Handlers

  const handleOpen = React.useCallback(() => {
    // SSE Connection opened
    if (onOpen) {
      onOpen();
    }
  }, []);

  const handleMessage = React.useCallback((event: MessageEvent<any>) => {
    // SSE Message
    if (onMessage) {
      onMessage(event);
    }
  }, []);

  const handleError = React.useCallback(() => {
    // SSE Error
    if (onError) {
      onError();
    }
  }, []);

  // Effects

  React.useEffect(() => {

    const initializeConnection = async () => {

      const { idToken: eosToken } = user as any; // TODO: refactor after useUser migration
      const eosId = getEOSUserIdFromJwt(eosToken);

      try {
        const { token, info } = await getGeniusSubscriptionSSEConnectionInfo(eosToken, eosId);
        const stream = info['event-stream'];
        const eventSourceUrl = stream.replace(':token', token);

        sse.current = new EventSource(eventSourceUrl);
        sse.current.addEventListener('open', handleOpen);
        sse.current.addEventListener('message', handleMessage);
        sse.current.addEventListener('error', handleError);
      } catch (err) {}
    };

    if (enabled && user) {
      initializeConnection();
    }

    return () => {
      // Remove event listeners
      if (sse.current) {
        sse.current.removeEventListener('open', handleOpen);
        sse.current.removeEventListener('message', handleMessage);
        sse.current.removeEventListener('error', handleError);
      }
    };
  }, [enabled, user]);

}
