export function getEOSUserIdFromJwt(jwt?: string) {
  if (!jwt) return undefined;
  try {
    const data = JSON.parse(atob(jwt.split('.')[1]));
    const { customer_id: customerId } = data || {};
    return customerId;
  } catch (err) {
    console.error('Failed to extract user id', err);
  }
  return undefined;
}
