import React from 'react';
import classnames from 'classnames';

import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

export interface Props {
  //
  className?: string,
  //
  name?: string,
  //
  color?: string,
  size?: number | { width: number, height: number },
  strokeWidth?: number,
  //
  style?: React.CSSProperties,
  onClick?: () => void
}

const Icon = (props: Props) => {
  const {
    //
    className = '',
    //
    name,
    //
    color,
    size = 24,
    strokeWidth = 1.5,
    //
    style,
    ...rest
  } = props;

  // Refs

  const importRef = React.useRef<React.ComponentClass<any>>(null);
  const isMounted = React.useRef<boolean>();

  // Hooks

  const [loading, setLoading] = React.useState(false);

  // Effects

  useIsomorphicLayoutEffect(() => {
    isMounted.current = true;
    setLoading(true);
    const importIcon = async () => {
      try {
        if (name) {
          const icon = await import(`./icons/${name}.svg`);
          importRef.current = icon.ReactComponent;
        }
      } catch (err) {
        console.warn('Error while importing icon', err);
        throw err;
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };
    importIcon();
    return () => {
      isMounted.current = false;
    };
  }, [name]);

  // Empty

  if (!name) return null;

  // Props

  const {
    width,
    height
  } = typeof size === 'number'
    ? {
      width: size,
      height: size
    } : size;

  // Loading

  if (loading || !importRef.current) {
    return (
      <div
        className={className}
        style={{
          width,
          height,
          ...style,
        }}
      />
    );
  }

  // Render

  const {
    current: ImportedIcon
  } = importRef;

  return (
    <ImportedIcon
      className={classnames(
        'icon',
        `icon-${name}`,
        className,
      )}
      width={width}
      height={height}
      strokeWidth={strokeWidth}
      color={color}
      style={style}
      {...rest}
    />
  );
};

export default Icon;
