import React from 'react';
import classnames from 'classnames';

import Label from '../Label/Label';

import type Rating from './Rating';

import styles from './Rating.module.scss';

// Types

interface RatingProps extends React.ComponentProps<typeof Rating> {}
type RatingLabelProps = Pick<RatingProps, 'label' | 'name' | 'labelClassName' | 'hasError'>;

const RatingLabel = (props: RatingLabelProps) => {
  const {
    label, name, labelClassName, hasError
  } = props;

  if (!label) return null;

  return (
    <Label
      htmlFor={name}
      hasError={hasError}
      label={label}
      className={classnames(styles.labelClassName, labelClassName)}
    />
  );
};

// Export

export default RatingLabel;
