import React from 'react';
import classnames from 'classnames';

import Filters from '../Filters/Filters';
import OrderBy from '../OrderBy/OrderBy';

import type { PageType } from 'types';

import styles from './ListingToolbar.module.scss';

interface Props {
  className?: string,
  filtersClassName?: string,
  orderByClassName?: string,
  //
  filters: React.ComponentProps<typeof Filters>['filters'],
  orderBy: React.ComponentProps<typeof OrderBy>['orderBy'],
  //
  withOrderByLabel?: boolean,
  pageType: PageType,
  data?: {count?: number}
}

const ListingToolbar = (props: Props) => {
  const {
    className,
    filtersClassName,
    orderByClassName,
    //
    filters,
    orderBy,
    withOrderByLabel = true,
    //
    pageType,
    data
  } = props;

  return (
    <div className={classnames(styles.root, className)}>

      {/* Filters */}

      <Filters
        className={filtersClassName}
        filters={filters}
        pageType={pageType}
        data={data}
      />

      {/* Order By */}
      <OrderBy
        className={orderByClassName}
        orderBy={orderBy}
        withLabel={withOrderByLabel}
        pageType={pageType}
      />

    </div>
  );
};

export default ListingToolbar;
