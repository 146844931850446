import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'genius_3m'; // TODO: align to _events prefix format

enum Actions {
  VIEW_MODAL = 'view_modal',
  CLOSE_MODAL = 'close_modal',
  ACTIVATE_TRIAL = 'activate_trial',
  SUCCESS_VIEW_MORE = 'success_view_more',
  SELECT_NOTICE = 'select_notice',
}

enum Labels {
  VIEW_MODAL = 'view_modal',
  CLOSE_MODAL = 'close_modal',
  ACTIVATE_TRIAL = 'activate_trial',
  SUCCESS_VIEW_MORE = 'success_view_more',
  SELECT_NOTICE = 'select_notice',
}

const push = getPushCategoryEventHandler<Actions, Labels>(CATEGORY);

// Events

// --- View Cart Error

export function viewGeniusTrial3MModal() {
  push(Actions.VIEW_MODAL, Labels.VIEW_MODAL);
}

export function closeGeniusTrial3MModal() {
  push(Actions.CLOSE_MODAL, Labels.CLOSE_MODAL);
}

export function activateGeniusTrial3M() {
  push(Actions.ACTIVATE_TRIAL, Labels.ACTIVATE_TRIAL);
}

export function successViewMoreGeniusTrial3M() {
  push(Actions.SUCCESS_VIEW_MORE, Labels.SUCCESS_VIEW_MORE);
}

export function selectGeniusTrial3MNotice() {
  push(Actions.SELECT_NOTICE, Labels.SELECT_NOTICE);
}
