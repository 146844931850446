export interface BodyScrollOptions {
  reserveScrollBarGap?: boolean,
  allowTouchMove?: (el: EventTarget) => boolean
}

interface Lock {
  targetElement: HTMLElement,
  options: BodyScrollOptions
}

// Older browsers don't support event options, feature detect it.
let hasPassiveEvents = false;
if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true;
      return undefined;
    },
  };
  window.addEventListener('testPassive', null, passiveTestOptions);
  // @ts-ignore
  window.removeEventListener('testPassive', null, passiveTestOptions);
}

const isIosDevice = typeof window !== 'undefined'
  && window.navigator
  && window.navigator.platform
  && (/iP(ad|hone|od)/.test(window.navigator.platform)
    || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));

type HandleScrollEvent = TouchEvent;

let locks: Array<Lock> = [];
let documentListenerAdded: boolean = false;
let initialClientY: number = -1;
let previousBodyOverflowSetting: string | undefined;
let previousBodyPosition: { position: string, top: string, left: string } | undefined;
let previousBodyPaddingRight: string | undefined;

// Returns true if `el` should be allowed to receive touchmove events.
function allowTouchMove(el: EventTarget | null): boolean {
  return locks.some((lock) => lock.options.allowTouchMove?.(el) ?? false);
}

function preventDefault(rawEvent: HandleScrollEvent): boolean {
  const e = rawEvent || window.event as HandleScrollEvent;

  if (allowTouchMove(e.target)) {
    return true;
  }

  if (e.touches.length > 1) return true;

  if (e.preventDefault) e.preventDefault();

  return false;
}

function setOverflowHidden(options?: BodyScrollOptions): void {
  if (previousBodyPaddingRight === undefined) {
    const reserveScrollBarGap = !!options?.reserveScrollBarGap;
    const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

    if (reserveScrollBarGap && scrollBarGap > 0) {
      const computedBodyPaddingRight = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right'), 10);
      previousBodyPaddingRight = document.body.style.paddingRight;
      document.body.style.paddingRight = `${computedBodyPaddingRight + scrollBarGap}px`;
    }
  }

  if (previousBodyOverflowSetting === undefined) {
    previousBodyOverflowSetting = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  }
}

function restoreOverflowSetting(): void {
  if (previousBodyPaddingRight !== undefined) {
    document.body.style.paddingRight = previousBodyPaddingRight;
    previousBodyPaddingRight = undefined;
  }

  if (previousBodyOverflowSetting !== undefined) {
    document.body.style.overflow = previousBodyOverflowSetting;
    previousBodyOverflowSetting = undefined;
  }
}

function setPositionFixed(): void {
  window.requestAnimationFrame(() => {
    if (previousBodyPosition === undefined) {
      previousBodyPosition = {
        position: document.body.style.position,
        top: document.body.style.top,
        left: document.body.style.left,
      };

      const { scrollY, scrollX } = window;
      document.body.style.position = 'fixed';
      document.body.style.top = `${-scrollY}px`;
      document.body.style.left = `${-scrollX}px`;
    }
  });
}

function restorePositionSetting(): void {
  if (previousBodyPosition !== undefined) {
    const y = -parseInt(document.body.style.top || '0', 10);
    const x = -parseInt(document.body.style.left || '0', 10);

    document.body.style.position = previousBodyPosition.position;
    document.body.style.top = previousBodyPosition.top;
    document.body.style.left = previousBodyPosition.left;

    window.scrollTo(x, y);

    previousBodyPosition = undefined;
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
function isTargetElementTotallyScrolled(targetElement: HTMLElement): boolean {
  return targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;
}

function handleScroll(event: HandleScrollEvent, targetElement: HTMLElement): boolean {
  const clientY = event.targetTouches[0].clientY - initialClientY;

  if (allowTouchMove(event.target)) {
    return false;
  }

  if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
    return preventDefault(event);
  }

  if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
    return preventDefault(event);
  }

  event.stopPropagation();
  return true;
}

function disableBodyScroll(targetElement: HTMLElement, options?: BodyScrollOptions) {
  if (!targetElement) {
    console.error('disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.');
    return;
  }

  if (locks.some((lock) => lock.targetElement === targetElement)) {
    return;
  }

  const lock: Lock = {
    targetElement,
    options: options || {},
  };

  locks = [...locks, lock];

  if (isIosDevice) {
    setPositionFixed();
  } else {
    setOverflowHidden(options);
  }

  if (isIosDevice) {
    targetElement.ontouchstart = (event: HandleScrollEvent) => {
      if (event.targetTouches.length === 1) {
        initialClientY = event.targetTouches[0].clientY;
      }
    };
    targetElement.ontouchmove = (event: HandleScrollEvent) => {
      if (event.targetTouches.length === 1) {
        handleScroll(event, targetElement);
      }
    };

    if (!documentListenerAdded) {
      document.addEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
      documentListenerAdded = true;
    }
  }
}

function clearAllBodyScrollLocks() {
  if (isIosDevice) {
    locks.forEach((lock) => {
      lock.targetElement.ontouchstart = null;
      lock.targetElement.ontouchmove = null;
    });

    if (documentListenerAdded) {
      // @ts-ignore
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
      documentListenerAdded = false;
    }

    initialClientY = -1;
  }

  if (isIosDevice) {
    restorePositionSetting();
  } else {
    restoreOverflowSetting();
  }

  locks = [];
}

function enableBodyScroll(targetElement: HTMLElement): void {
  if (!targetElement) {
    console.error('enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.');
    return;
  }

  locks = locks.filter((lock) => lock.targetElement !== targetElement);

  if (isIosDevice) {
    targetElement.ontouchstart = null;
    targetElement.ontouchmove = null;

    if (documentListenerAdded && locks.length === 0) {
      // @ts-ignore
      document.removeEventListener('touchmove', preventDefault, hasPassiveEvents ? { passive: false } : undefined);
      documentListenerAdded = false;
    }
  }

  if (isIosDevice) {
    restorePositionSetting();
  } else {
    restoreOverflowSetting();
  }
}

export const ScrollLock = {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
};
