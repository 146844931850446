import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'product_category_events';

enum Actions {
  VIEW_PRODUCT_CATEGORY_ITEM = 'view_product_category_item',
  SELECT_PRODUCT_CATEGORY_ITEM = 'select_product_category_item'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Category in search autosuggestions and in search results

export function viewProductCategoryItem(categoryUrl: string) {
  push(Actions.VIEW_PRODUCT_CATEGORY_ITEM, categoryUrl);
}

export function selectProductCategoryItem(categoryUrl: string) {
  push(Actions.SELECT_PRODUCT_CATEGORY_ITEM, categoryUrl);
}
