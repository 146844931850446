import React from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';

import * as Cookies from 'common/Cookies';
import { SecureApi } from 'common/http';
import * as Storage from 'common/Storage';
import { QUERY_KEYS } from 'constants/query-keys';
import { APP_ROUTES } from 'constants/routes';
import { USER_ACCOUNT_TYPES } from 'constants/user';
import {
  deleteRefreshToken, deleteUserDetails, getRefreshToken, setRefreshToken, setUserDetails
} from 'helpers/AuthenticationHelpers';
import { getConsentStatus, updateConsentStatus } from 'helpers/ConsentHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import AuthenticationService from 'services/AuthenticationService';

import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';

interface AuthenticationStoreState {
  accessToken: string | undefined,
  isFetching: boolean,
  isSettled: boolean,
  refreshToken: string
}

const initialState: AuthenticationStoreState = {
  accessToken: undefined,
  isFetching: false,
  isSettled: false,
  refreshToken: getRefreshToken()
};

const reducers = {
  setAccessToken: (draft: AuthenticationStoreState, action: IStoreAction<string>): AuthenticationStoreState => {
    const { payload } = action;
    return {
      ...draft,
      accessToken: payload
    };
  },

  setFetching: (draft: AuthenticationStoreState, action: IStoreAction<boolean>): AuthenticationStoreState => {
    const { payload } = action;
    return {
      ...draft,
      isFetching: payload
    };
  },

  setSettled: (draft: AuthenticationStoreState, action: IStoreAction<boolean>): AuthenticationStoreState => {
    const { payload } = action;
    return {
      ...draft,
      isSettled: payload
    };
  },
};

type Actions = IStoreActions<typeof reducers>;

const [
  AuthProvider,
  useAuthStore,
  useDispatch,
  actions,
  useAuthStoreSelector
] = createV2<AuthenticationStoreState, Actions>(reducers, initialState);

function useAuthStoreDispatch() {

  const router = useRouter();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  // Set Access Token

  const setAccessToken = React.useCallback((accessToken: string) => {
    SecureApi.setAccessToken(accessToken);
    dispatch(actions.setAccessToken(accessToken));
  }, [dispatch]);

  // Refresh Access Token

  const refreshAccessToken = React.useCallback(async () => {
    dispatch(actions.setFetching(true));

    try {
      const refreshToken = getRefreshToken();
      const {
        token: accessToken,
        customerId,
        isGeniusUser
      } = await AuthenticationService.refreshAccessToken(refreshToken);

      setAccessToken(accessToken);
      setUserDetails(customerId, isGeniusUser ? USER_ACCOUNT_TYPES.GENIUS : USER_ACCOUNT_TYPES.STANDARD);

    } catch (err) {
      deleteUserDetails();
      throw new Error(err);

    } finally {
      dispatch(actions.setFetching(false));
      dispatch(actions.setSettled(true));
    }
  }, [dispatch, setAccessToken]);

  // Login

  const login = async (code: string, state: string | object) => {
    dispatch(actions.setFetching(true));
    try {
      const {
        token: accessToken,
        refresh_token: refreshToken,
        customerId,
        isGeniusUser,
        isSignup: isSignUp
      } = await AuthenticationService.login(code);

      setRefreshToken(refreshToken);
      setAccessToken(accessToken);
      setUserDetails(customerId, isGeniusUser ? USER_ACCOUNT_TYPES.GENIUS : USER_ACCOUNT_TYPES.STANDARD);

      Cookies.clear();

      /* Analytics */
      if (isSignUp) {
        analytics.signUp();
      }
      analytics.login();

      /* Routing */
      if (state) {
        try {
          const stateObject = typeof state === 'string' ? JSON.parse(atob(state)) : state;
          const { path = APP_ROUTES.HOME } = stateObject || {};
          router.push(path);
        } catch (err) {
          console.error('Error while decoding redirect state', err);
          router.push(APP_ROUTES.HOME);
        }
      } else {
        router.push(APP_ROUTES.HOME);
      }

    } catch (err) {
      deleteRefreshToken();
      deleteUserDetails();
      router.push({ pathname: APP_ROUTES.ERROR500, query: { initial: true } });

    } finally {
      dispatch(actions.setFetching(false));
      dispatch(actions.setSettled(true));
    }
  };

  // Logout

  const logout = () => {
    deleteRefreshToken();
    deleteUserDetails();
    dispatch(actions.setAccessToken(undefined));

    queryClient.removeQueries({ queryKey: [QUERY_KEYS.USER] });
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });

    analytics.setUserId(null);
    analytics.setAccountType(null);

    const consentStatus = getConsentStatus();

    Storage.clear();
    Cookies.clear();

    updateConsentStatus(consentStatus);

    window.location.href = APP_ROUTES.HOME;
  };

  // Return

  return {
    refreshAccessToken,
    setAccessToken,
    login,
    logout
  };
}

export {
  AuthProvider,
  useAuthStore,
  useAuthStoreSelector,
  useAuthStoreDispatch,
};
