import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Scrollbar } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { SCROLL_LOCK_IGNORE_CLASS } from 'helpers/ScrollHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { usePrevious } from 'hooks/usePrevious';

import CartContent from '../CartContent/CartContent';
import CartContentSkeleton from '../CartContent/CartContentSkeleton';
import CartFooter from '../CartFooter/CartFooter';
import CartHeader from '../CartHeader/CartHeader';
import CartSubmit from '../CartSubmit/CartSubmit';
import CartTitle from '../CartTitle/CartTitle';

import CartEmpty from './CartEmpty';
import CartError from './CartError';

import type { ScrollbarElement } from 'components/ui';

import type { IOrder } from 'types';

import styles from './Cart.module.scss';

interface Props {
  title?: string
}

const Cart = (props: Props) => {

  const { title } = props;

  // Refs

  const scrollbarRef = React.useRef<ScrollbarElement>();

  // Hooks

  const router = useRouter();

  // Data Hooks

  const {
    isLoading, isError, data: order,
  } = useActiveOrder();

  const previousOrder = usePrevious<IOrder>(order);

  // Effects

  React.useEffect(() => {
    if (order?.items?.length > previousOrder?.items?.length && scrollbarRef.current) {
      try {
        const el = scrollbarRef.current.getScrollElement();
        el.scrollTop = 0;
      } catch (err) {
        console.error('Failed to scroll cart to top', err);
      }
    }
  }, [order]);

  // Handlers

  const goToCheckout = React.useCallback(() => {
    router.push({ pathname: APP_ROUTES.CHECKOUT });
  }, [order, router]);

  // Error

  if (isError) {
    return (
      <div className={classnames(styles.root, styles.simple)}>
        <div className={styles.container}>
          <CartTitle title={title} />
          <div className={styles.main}>
            <CartError />
          </div>
        </div>

      </div>
    );
  }

  // Loading

  if (isLoading) {
    return (
      <div className={classnames(styles.root, styles.simple)}>
        <div className={styles.container}>
          <CartTitle title={title} />
          <div className={styles.main}>
            <CartHeader className={styles.header} order={order} />
            <CartContentSkeleton />
            <CartFooter className={styles.footer} order={order} />
          </div>
        </div>
      </div>
    );
  }

  // Empty

  if (isArrayEmpty(order?.items)) {
    return (
      <div className={classnames(styles.root, styles.simple)}>
        <div className={styles.container}>
          <CartTitle title={title} />
          <div className={styles.main}>
            <CartHeader className={styles.header} order={order} />
            <CartEmpty />
            <CartFooter className={styles.footer} order={order} />
          </div>
        </div>
      </div>
    );
  }

  // Render

  return (
    <div className={styles.root}>
      <div className={styles.container}>

        {/* Title */}
        <CartTitle title={title} />

        {/* Main */}
        <div className={styles.main}>

          {/* Scrollbar */}
          <Scrollbar
            ref={scrollbarRef}
            className={
              classnames(
                styles.inner,
                SCROLL_LOCK_IGNORE_CLASS
              )
            }
            autoHide={false}
            forceVisible="y"
            scrollableNodeProps={{
              className: SCROLL_LOCK_IGNORE_CLASS
            }}
          >

            {/* Header - Notices / Promotions / Recommendation */}
            <CartHeader className={styles.header} order={order} />

            {/* Content - Valid / Invalid Products */}
            <CartContent className={styles.content} order={order} />

            {/* Footer - Gifts */}
            <CartFooter className={styles.footer} order={order} />

          </Scrollbar>
        </div>

        {/* Submit */}
        <CartSubmit
          className={styles.submit}
          order={order}
          onSubmit={goToCheckout}
        />

      </div>
    </div>
  );
};

export default Cart;
