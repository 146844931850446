import React from 'react';
import classnames from 'classnames';

import { Carousel, Image, Link } from 'components/ui';

import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { parseCategoryImageUrl } from 'helpers/CategoryHelpers';
import { useInView } from 'hooks/common/useInView';
import { useAnalytics } from 'hooks/useAnalytics';

import type { CarouselApi } from 'components/ui/Carousel';
import type { ISearchSuggestionCategory } from 'types';

import styles from './CategoriesCarousel.module.scss';

interface Props {
  categories: ISearchSuggestionCategory[],
  loading?: boolean
}

const CategoriesCarousel = (props: Props) => {

  // Props

  const { categories, loading } = props;

  // Hooks

  const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();
  const [isFirstSlide, setIsFirstSlide] = React.useState(false);
  const [isLastSlide, setIsLastSlide] = React.useState(false);

  const analytics = useAnalytics();

  // Handlers

  const onInit = (api: CarouselApi) => {
    setCarouselApi(api);
    updateSlidePosition(api);

    api.on('select', () => updateSlidePosition(api));
  };

  const updateSlidePosition = (emblaApi: CarouselApi) => {
    const selectedSlide = emblaApi?.selectedScrollSnap();
    const totalSlides = emblaApi?.scrollSnapList().length;

    setIsFirstSlide(selectedSlide === 0);
    setIsLastSlide(selectedSlide === totalSlides - 1);
  };

  const handleClick = (imageUrl: string) => {
    if (imageUrl) {
      analytics.selectProductCategoryItem(parseCategoryImageUrl(imageUrl));
    }
  };

  // Empty

  if (isArrayEmpty(categories)) return null;

  // Render

  return (
    <div className={styles.root}>
      {/* Fade - Left */}
      <div
        className={classnames(
          styles.fade,
          styles.left,
          { [styles.hidden]: loading || isFirstSlide }
        )}
        onClick={() => {
          carouselApi?.scrollPrev();
        }}
      />

      {/* Fade - Right */}
      <div
        className={classnames(
          styles.fade,
          styles.right,
          { [styles.hidden]: loading || isLastSlide },
        )}
        onClick={() => {
          carouselApi?.scrollNext();
        }}
      />

      <Carousel
        className={styles.carousel}
        slideClassName={styles.slide}
        onInit={onInit}
      >
        {categories.map((category, index) => {
          const {
            code, name, url, gridImage
          } = category;

          const { observe } = useInView({
            threshold: 0.75,
            onEnter: ({ unobserve }) => {
              if (gridImage) {
                analytics.viewProductCategoryItem(parseCategoryImageUrl(gridImage));
              }
              unobserve();
            }
          });

          return (
            <div ref={observe} onClick={() => handleClick(gridImage)} className={styles.category} key={`${code}-${index}`}>
              <Link passHref href={url} prefetch={false}>
                <a className={styles.categoryContent}>
                  <div className={styles.imageWrapper}>
                    <Image src={gridImage} height={56} width={56} className={styles.categoryImageBg} />
                  </div>
                  <span className={styles.categoryName}>{name}</span>
                </a>
              </Link>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CategoriesCarousel;
