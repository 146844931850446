import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'order_events';

enum Actions {
  REPEAT = 'repeat',
  CANCEL = 'cancel',
  REPORT_PROBLEM = 'report_problem',
  SEARCH_PRODUCTS = 'search_products',
}

enum Labels {
  START = 'start',
  COMPLETE = 'complete',
  IN_CHECKOUT = 'checkout'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function repeatOrder(completed = false) {
  push(Actions.REPEAT, completed ? Labels.COMPLETE : Labels.START);
}

export function cancelOrder(completed = false, data?: { reason: string }) {
  const label = JSON.stringify(
    completed
      ? { status: Labels.COMPLETE, reason: data?.reason }
      : { status: Labels.START }
  );
  push(Actions.CANCEL, label);
}

export function reportOrderProblem(completed = false, data?: { type: string, secondaryType: string }) {
  const label = JSON.stringify(
    completed
      ? { status: Labels.COMPLETE, problem: data?.type, reason: data?.secondaryType }
      : { status: Labels.START }
  );
  push(Actions.REPORT_PROBLEM, label);
}

export function searchCheckoutProducts() {
  push(Actions.SEARCH_PRODUCTS, Labels.IN_CHECKOUT);
}
