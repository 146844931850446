import * as Notifications from 'common/Notification';
import { RecipeIngredientsAddToastBody } from 'components/recipe';
import { CONFIG } from 'constants/config';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { getOrderItem } from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { hashString } from 'helpers/StringHelpers';

import type {
  IOrder,
  IRecipe,
  IRecipeIngredientProduct,
  IRecipePrimaryIngredients,
} from 'types';

// --- Get Primary Ingredients SKU list

export function getPrimaryIngredientsSkuList(ingredients: IRecipePrimaryIngredients): string[] {
  if (!ingredients) {
    return [];
  }

  return ingredients.reduce((acc, ingredient) => {
    const { sku } = ingredient;
    return [
      ...acc,
      sku,
    ];
  }, [] as string[]);
}

// --- Hash Ingredients List

export function hashIngredientsList(ingredients: IRecipePrimaryIngredients): string {
  if (isArrayEmpty(ingredients)) return '';

  return (
    hashString(
      JSON.stringify(
        ingredients?.map(({ sku, quantity }) => ({ sku, quantity }))
      )
    ).toString()
  );
}

// --- Replace Ingredient

export function replaceIngredient(
  ingredientProducts: IRecipeIngredientProduct[],
  ingredientToReplace: IRecipeIngredientProduct | undefined,
  replacement: IRecipeIngredientProduct,
) {
  return ingredientProducts.map((ingredientProduct) => {

    if (ingredientProduct?.id === ingredientToReplace?.id) {
      const { ingredient } = ingredientProduct || {};
      const { backups = [] } = ingredient || {};
      return {
        ...replacement,
        ingredient: {
          ...replacement.ingredient,
          backups: backups.map((backup) => {
            if (backup.id === replacement.id) {
              return ingredientToReplace.ingredient;
            }
            return backup;
          })
        }
      };
    }

    return ingredientProduct;

  });
}
// --- Can Bulk Add Ingredients

export function canBulkAddIngredients(order: IOrder | undefined, ingredientProducts: IRecipeIngredientProduct[]) {
  if (!order) return false;
  return !isArrayEmpty(ingredientProducts) && ingredientProducts.every(({ ingredient, product }) => {
    const orderItem = getOrderItem(order, product);

    const { isAvailable } = product || {};
    const { quantity: requiredQuantity } = ingredient || {};
    const { quantity: currentQuantity = 0 } = orderItem || {};

    const [maxQuantity] = ProductHelpers.getMaxAllowedQuantity(product);

    return (
      isAvailable
      && requiredQuantity <= maxQuantity
      && currentQuantity + requiredQuantity <= maxQuantity
    );
  });
}

// --- Has Out of Stock Ingredients

export function hasOutOfStockIngredients(ingredientProducts: IRecipeIngredientProduct[]) {
  return ingredientProducts.some(({ product }) => {
    const { isAvailable } = product || {};
    return !isAvailable;
  });
}

// --- Add Ingredients Notification

const RECIPE_ADD_INGREDIENTS_TOAST_ID = 'recipeAddIngredientsToast';

export function showRecipeAddIngredientsNotification(recipe: IRecipe) {
  const toastBody = (
    <RecipeIngredientsAddToastBody
      recipe={recipe}
    />
  );

  if (Notifications.isActive(RECIPE_ADD_INGREDIENTS_TOAST_ID)) {
    Notifications.update(RECIPE_ADD_INGREDIENTS_TOAST_ID, {
      autoClose: CONFIG.DURATIONS.RECIPE_ADD_INGREDIENTS_TOAST_AUTOCLOSE,
      toastId: RECIPE_ADD_INGREDIENTS_TOAST_ID,
      render: toastBody,
    });

  } else {
    Notifications.showProgress(
      toastBody,
      {
        autoClose: CONFIG.DURATIONS.RECIPE_ADD_INGREDIENTS_TOAST_AUTOCLOSE,
        toastId: RECIPE_ADD_INGREDIENTS_TOAST_ID,
      }
    );
  }
}
