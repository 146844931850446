import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import MenuItemSeparator from 'components/navigation/Menu/MenuItemSeparator';
import { Icon, Popover } from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import MenuItemLink, { isLinkActive } from './MenuItemLink';

import type { IMenuItemLink } from 'types';

import styles from './MenuItemWithPopover.module.scss';

interface Props {
  activeChildRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  //
  className?: string,
  linkClassName?: string,
  linkActiveClassName?: string,
  subLinkClassName?: string,
  subLinkActiveClassName?: string,
  iconClassName?: string,
  labelClassName?: string,
  //
  item: IMenuItemLink,
  //
  onClick?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseEnter?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void,
  onMouseLeave?: (item: IMenuItemLink, e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const MenuItemWithPopover = (props: Props) => {

  const {
    activeChildRef,
    //
    className,
    linkClassName,
    linkActiveClassName,
    subLinkClassName,
    subLinkActiveClassName,
    iconClassName,
    labelClassName,
    //
    item,
    //
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  // Props

  const {
    slug,
    children
  } = item || {};

  // Refs

  const parentRef = React.useRef();

  // Hooks

  const router = useRouter();

  const { isOpen, close } = useIsOpen();

  // Effects

  React.useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [router.asPath]);

  // Props

  const { asPath, query } = router;
  const { originalPathname = '' } = query || {};

  // Render

  return (
    <Popover
      isOpen={isOpen}
      parentElement={parentRef || undefined}
      className={classnames(
        styles.root,
        className
      )}
      //
      contentClassName={styles.content}
      //
      align="center"
      positions={['bottom']}
      padding={8}
      //
      withArrow
      withHover
      //
      content={(
        // List
        <ul className={styles.list}>
          {
            children.map((child, index) => {

              // Separator

              if (child === 'separator') {
                return (
                  <MenuItemSeparator
                    key={`separator-${index}`}
                  />
                );
              }

              // Props

              const {
                className: childClassName,
                exact,
                href,
                matchHref,
                hidden,
              } = child || {};

              // Hidden

              if (hidden) return null;

              // Active

              const isActive = isLinkActive({
                asPath,
                originalPathname,
                href,
                matchHref,
                exact,
              });

              // Item

              return (
                <li
                  key={`${slug}-sub-${index}`}
                  className={styles.item}
                >
                  <MenuItemLink
                    ref={
                      isActive
                        ? activeChildRef
                        : null
                    }
                    key={child?.href || index}
                    className={classnames(
                      styles.link,
                      subLinkClassName,
                      childClassName
                    )}
                    activeClassName={classnames(
                      styles.active,
                      { [subLinkActiveClassName]: subLinkActiveClassName }
                    )}
                    item={child}
                    onClick={onClick}
                  />
                </li>
              );
            })
          }
        </ul>
      )}
      //
      onClickOutside={close}
    >

      {/* Parent */}
      <MenuItemLink
        ref={parentRef}
        //
        className={classnames(
          styles.link,
          linkClassName
        )}
        activeClassName={linkActiveClassName}
        iconClassName={iconClassName}
        labelClassName={labelClassName}
        //
        item={item}
        //
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >

        {/* Chevron */}
        <Icon
          className={styles.chevron}
          name="chevron-down"
          size={16}
          strokeWidth={2}
        />

      </MenuItemLink>

    </Popover>
  );
};

export default MenuItemWithPopover;
