import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { isObjectEmpty } from 'helpers/ObjectHelpers';

import ProductBadge from '../ProductBadge/ProductBadge';
import ProductLabels from '../ProductLabels/ProductLabels';
import ProductTags from '../ProductTags/ProductTags';

import type { Props as ProductBadgeProps } from '../ProductBadge/ProductBadge';
import type { Props as ProductLabelsProps } from '../ProductLabels/ProductLabels';
import type { Props as ProductTagsProps } from '../ProductTags/ProductTags';

import type {
  IImageSrc, IProductBadge, IProductLabel, IProductTag
} from 'types';

import styles from './ProductImage.module.scss';

interface Props {
  //
  className?: string,
  imageClassName?: string,
  //
  src?: IImageSrc,
  alt?: string,
  size: number | { width: number, height: number },
  retina?: boolean,
  rounded?: boolean,
  //
  unavailable?: boolean,
  //
  labels?: IProductLabel[],
  labelsProps?: Omit<ProductLabelsProps, 'labels'>,
  //
  tags?: IProductTag[],
  tagsProps?: Omit<ProductTagsProps, 'tags'>,
  //
  badge?: IProductBadge,
  badgeProps?: Omit<ProductBadgeProps, 'badge'>
}

const ProductImage = (props: Props) => {

  const {
    className,
    imageClassName,
    //
    src,
    alt,
    size,
    retina = true,
    rounded,
    //
    labels,
    labelsProps,
    //
    tags,
    tagsProps,
    //
    badge,
    badgeProps,
    //
    unavailable,
  } = props;

  // Props

  const { className: labelsClassName, ...restLabelsProps } = labelsProps || {};
  const { className: tagsClassName, ...restTagsProps } = tagsProps || {};
  const { className: badgeClassName, ...restBadgeProps } = badgeProps || {};

  const {
    width, height
  } = (typeof size === 'number' ? { width: size, height: size } : size);

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      style={{
        '--width': `${width}px`,
        '--height': `${height}px`
      } as CSSProperties}
    >

      {/* Image */}
      <Image
        className={classnames(
          styles.image,
          { [styles.rounded]: rounded },
          { [styles.unavailable]: unavailable },
          imageClassName
        )}
        width={retina ? width * 2 : width}
        height={retina ? height * 2 : height}
        src={src}
        quality={100}
        optimize
        alt={alt}
      />

      {/* Labels */}
      {
        isArrayEmpty(labels)
          ? null
          : (
            <ProductLabels
              className={classnames(
                styles.labels,
                labelsClassName
              )}
              labels={labels}
              {...restLabelsProps}
            />
          )
      }

      {/* Tags */}
      {
        isArrayEmpty(tags)
          ? null
          : (
            <ProductTags
              className={classnames(
                styles.tags,
                tagsClassName
              )}
              tags={tags}
              orientation="vertical"
              {...restTagsProps}
            />
          )
      }

      {/* Badge */}
      {
        isObjectEmpty(badge)
          ? null
          : (
            <ProductBadge
              className={classnames(
                styles.badge,
                badgeClassName,
              )}
              badge={badge}
              {...restBadgeProps}
            />
          )
      }

    </div>
  );
};

// Export

export default ProductImage;
