import React from 'react';

import { Head } from 'components/ui';
import { CheckoutProvider } from 'context/CheckoutContext';

import DrawerContainer from '../DrawerContainer/DrawerContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import ModalContainer from '../ModalContainer/ModalContainer';
import ProductModal from '../ProductModal/ProductModal';

import styles from './CheckoutLayout.module.scss';

interface Props {
  //
  header?: {isVisible: boolean, props: React.ComponentProps<typeof Header>},
  footer?: {isVisible: boolean, props: React.ComponentProps<typeof Footer>},
  //
  children?: React.ReactNode
}

const CheckoutLayout = (props: Props) => {
  const {
    header = { isVisible: true, props: {} },
    footer = { isVisible: true, props: {} },
    children
  } = props;

  return (
    <div className={styles.root}>
      <Head />
      {header.isVisible && <Header {...header.props} />}
      <DrawerContainer />
      <ModalContainer />
      <div className={styles.container}>
        <CheckoutProvider>
          {children}
        </CheckoutProvider>
      </div>
      {footer.isVisible && <Footer {...footer.props} />}
      <ProductModal />
    </div>
  );
};

export default CheckoutLayout;
