import type { ISelectOption } from 'types/misc';
import type { ValueOf } from 'types/utility';

export interface IAccountBenefits {
  meta: {
    analyticsLabel?: string,
    segmentId: number,
    filter: IAccountBenefitsFilter
  },
  //
  title?: string,
  image?: string,
  //
  monthlyBenefits: {
    main: IAccountBenefitsDynamicContent,
    secondary: IAccountBenefitsBlock
  },
  lifetimeBenefits: IAccountBenefitsBlock,
  //
  additionalInfo?: IAccountBenefitsDynamicContent,
  footer?: {
    actionBtn?: {
      label: string,
      link?: string
    }
  }
}

// Filters

export interface IAccountBenefitsFilter {
  code: string | undefined,
  name: string | undefined,
  options: ISelectOption[],
  selectedOption: ISelectOption | undefined
}

// Dynamic Content

export type IAccountBenefitsDynamicContent = IAccountBenefitsDynamicContentLine[];

export interface IAccountBenefitsDynamicContentLine {
  type: AccountDynamicContentLineType,
  text: string,
  image?: string
}

export const ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES = {
  TEXT_SMALL: 'txtSmall',
  TEXT_MEDIUM: 'txtMedium',
  TEXT_LARGE: 'txtLarge'
} as const;

export type AccountDynamicContentLineType = ValueOf<typeof ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES>

// Benefit

export interface IAccountBenefitsBlock {
  title?: string,
  description?: string,
  list: IAccountBenefitsList
}

export type IAccountBenefitsList = IAccountBenefitItem[];

export interface IAccountBenefitItem {
  icon?: string,
  label?: string,
  value: string
}
