import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import type { IRecipeTextIngredients } from 'types';

import styles from './RecipeDetailsTextIngredients.module.scss';

interface Props {
  className?: string,
  ingredients: IRecipeTextIngredients
}

const RecipeDetailsTextIngredients = (props: Props) => {

  const {
    className,
    ingredients,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Empty

  if (isArrayEmpty(ingredients)) return null;

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      title={t('recipes:RECIPE_DETAILS.INGREDIENTS.TITLE')}
    >
      {/* List */}
      <ul className={styles.list}>
        {
          ingredients?.map((ingredient, index) => (
            <li className={styles.item} key={index}>

              {/* Marker */}
              <div className={styles.marker}>
                {INGREDIENT_MARKER}
              </div>

              {/* Label */}
              <div className={styles.label}>
                {ingredient.text}
              </div>

            </li>
          ))
        }
      </ul>
    </RecipeDetailsSection>
  );
};

// Constants

const INGREDIENT_MARKER = '-';

// Export

export default RecipeDetailsTextIngredients;
