import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { Map } from 'components/ui';
import * as AddressHelpers from 'helpers/AddressHelpers';
import { linearInterpolation } from 'helpers/utils';
import { usePrevious } from 'hooks/usePrevious';

import styles from './AddressGoogleMap.module.scss';

const MAX_DRAG_DISTANCE = 50;

const ZOOM_LEVELS = {
  FAR: 14,
  DEFAULT: 15,
  CLOSE: 17
};

const AddressGoogleMap = (props) => {
  const {
    className,
    containerClassName,
    placeId,
    pin,
    center,
    dragCenter,
    hidden,
    readonly,
    maxDragDistance = MAX_DRAG_DISTANCE,
    onChange = () => {}
  } = props;

  const { t } = useTranslation('common');

  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);

  const [markers, setMarkers] = React.useState([]);
  const [circles, setCircles] = React.useState([]);

  const currentAddress = React.useRef(null);

  const previousPlaceId = usePrevious(placeId);

  // Get markers for the given position
  const getMarkers = (position) => {
    return [{
      position: AddressHelpers.parseGeolocation(position),
      onLoad: (markerInstance) => markerRef.current = markerInstance,
      onUnmount: () => markerRef.current = null
    }];
  };

  // Get circles for the given position
  const getCircles = (position) => {
    return [{
      center: AddressHelpers.parseGeolocation(position),
      radius: maxDragDistance,
    }];
  };

  // Keep track of the selected position whenever the placeId is updated

  React.useEffect(() => {
    if (previousPlaceId !== placeId) {
      if (pin) {
        currentAddress.current = AddressHelpers.parseGeolocation(pin);
        setMarkers(getMarkers(currentAddress?.current));
        if (!readonly) {
          setCircles(getCircles(AddressHelpers.parseGeolocation(dragCenter)));
        }
      } else {
        currentAddress.current = {};
        setMarkers([]);
        setCircles([]);
      }
    }
  }, [previousPlaceId, placeId, pin]);

  // If readonly, show a marker with the pin location

  React.useEffect(() => {
    if (readonly) {
      const pinLocation = AddressHelpers.parseGeolocation(pin);
      setMarkers(getMarkers(pinLocation));
      setCircles([]);
    }
  }, [readonly, pin]);

  // Handlers

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
  };

  const onDrag = () => {
    if (!markerRef?.current) return;

    const dragRadiusCenter = mapRef?.current?.getCenter();
    const markerPosition = markerRef?.current?.getPosition();

    const tempLat = linearInterpolation(markerPosition.lat(), dragRadiusCenter.lat(), 0.25);
    const tempLng = linearInterpolation(markerPosition.lng(), dragRadiusCenter.lng(), 0.25);

    markerRef?.current?.setPosition({
      lat: tempLat,
      lng: tempLng
    });
  };

  const onDragEnd = () => {
    if (!markerRef?.current) return;

    const dragCenterPosition = new window.google.maps.LatLng(AddressHelpers.parseGeolocation(dragCenter));
    const currentAddressPosition = new window.google.maps.LatLng(currentAddress.current);

    const updatedMarkerPosition = markerRef?.current?.getPosition();

    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      dragCenterPosition,
      updatedMarkerPosition,
    );

    // If the pin is moved too far
    if (distance > maxDragDistance) {

      // Reset pin position to initial place position
      markerRef?.current?.setPosition(currentAddressPosition);
      mapRef?.current?.panTo(currentAddressPosition);

      Notifications.showInfo(
        t('ADDRESS.ERRORS.PIN_TOO_FAR'),
        { autoClose: 2000, toastId: 'invalidPinError' }
      );

      return;
    }

    currentAddress.current = AddressHelpers.parseGeolocation({
      lat: updatedMarkerPosition.lat(),
      lng: updatedMarkerPosition.lng()
    });

    Notifications.showSuccess(
      t('ADDRESS.INFO.GEOLOCATION_UPDATED'),
      { autoClose: 2000, toastId: 'locationUpdatedSuccess' }
    );

    onChange({
      lat: updatedMarkerPosition.lat(),
      lng: updatedMarkerPosition.lng()
    });
  };

  return (
    <>
      <div className={classnames(styles.mapContainer, containerClassName)}>
        <Map
          className={classnames(styles.map, { [styles.hidden]: hidden }, className)}
          draggable={!readonly}
          center={center}
          markers={markers}
          circles={circles}
          zoom={
            pin
              ? (
                readonly
                  ? ZOOM_LEVELS.DEFAULT
                  : ZOOM_LEVELS.CLOSE
              ) : ZOOM_LEVELS.FAR
          }
          onMapLoad={onMapLoad}
          onDrag={readonly ? null : onDrag}
          onDragEnd={readonly ? null : onDragEnd}
        />
      </div>
    </>
  );
};

export default AddressGoogleMap;
