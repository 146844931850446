/* eslint-disable react/no-danger */

import React from 'react';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { Button } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import * as RecipeHelpers from 'helpers/RecipeHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';
import { useRecipeIngredients } from 'hooks/data/useRecipeIngredients';
import { useAnalytics } from 'hooks/useAnalytics';
import { useIsOpen } from 'hooks/useIsOpen';

import RecipeIngredientsList from '../RecipeIngredientsList/RecipeIngredientsList';
import RecipeIngredientsModal from '../RecipeIngredientsModal/RecipeIngredientsModal';

import type { IRecipe, IRecipeIngredientProduct } from 'types';

import styles from './RecipeIngredientsListWithBulkAdd.module.scss';

interface Props {
  className?: string,
  headerClassName?: string,
  listClassName?: string,
  //
  withSimplifiedHeader?: boolean,
  withStickyAddIngredientsButton?: boolean,
  //
  recipe: IRecipe,
  //
  children?: React.ReactNode
}

const RecipeIngredientsListWithBulkAdd = (props: Props) => {

  const {
    className,
    headerClassName,
    listClassName,
    //
    withSimplifiedHeader,
    withStickyAddIngredientsButton,
    //
    recipe,
    //
    children,
  } = props;

  // Props

  const {
    meta,
    analyticsListProps,
    ingredients,
    stats,
    disclaimer
  } = recipe || {};

  const {
    id: recipeId,
    slug
  } = meta || {};

  const {
    primary: primaryIngredients
  } = ingredients || {};

  const {
    servings: {
      value: servings
    } = {
      value: null
    }
  } = stats || {};

  // Refs

  const { observe: observeAddIngredientsBtnRef, inView: isAddIngredientsBtnInView } = useInView({
    threshold: [1],
    delay: 100,
  });

  // Hooks

  const { t } = useTranslation();

  const analytics = useAnalytics();

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useIsOpen();

  const [ingredientProducts, setIngredientProducts] = React.useState<IRecipeIngredientProduct[]>([]);
  const [ingredientProductToReplace, setIngredientProductToReplace] = React.useState<IRecipeIngredientProduct>();

  // Data Hooks

  // --- Order

  const { data: order } = useActiveOrder();

  // --- Ingredients

  const { isLoading: isLoadingIngredients, data: recipeIngredientProducts } = useRecipeIngredients({
    ingredients: primaryIngredients,
  }, {
    enabled: !isArrayEmpty(primaryIngredients)
  });

  // Mutation Hooks

  const {
    isLoading: isMutationLoading, bulkAdd: bulkAddProducts
  } = useOrderProductMutation();

  // Props

  const addIngredientsBtnLabel = (
    !withSimplifiedHeader
      ? t('recipes:RECIPE_DETAILS.INGREDIENTS.ACTIONS.ADD')
      : (
        servings === 1
          ? t('recipes:RECIPE_DETAILS.INGREDIENTS.ACTIONS.ADD_PORTIONS_SINGULAR')
          : t('recipes:RECIPE_DETAILS.INGREDIENTS.ACTIONS.ADD_PORTIONS_PLURAL', { servings })
      )
  );

  const headerInfoText = (
    servings === 1
      ? t('recipes:RECIPE_DETAILS.INGREDIENTS.INFO.PORTIONS_SINGULAR')
      : t('recipes:RECIPE_DETAILS.INGREDIENTS.INFO.PORTIONS_PLURAL', { servings })
  );

  const canBulkAddIngredients = React.useMemo(() => {
    return RecipeHelpers.canBulkAddIngredients(order, ingredientProducts);
  }, [order, ingredientProducts]);

  // Effects

  React.useEffect(() => {
    if (recipeIngredientProducts) {
      setIngredientProducts(recipeIngredientProducts);
    }
  }, [recipeIngredientProducts]);

  React.useEffect(() => {
    if (isArrayEmpty(ingredientProducts)) return;

    if (!canBulkAddIngredients) {
      analytics.viewRecipeOutOfStockIngredients(recipeId);
    }
  }, [ingredientProducts, canBulkAddIngredients]);

  // Handlers

  const handleAddRecipeIngredients = React.useCallback(() => {
    const products = ingredientProducts.map(({ ingredient, product }) => {
      const { quantity = 0 } = ingredient || {};
      return { quantity, product };
    });

    bulkAddProducts({
      order,
      products,
      bypassAddressRequirement: true,
      listProps: analyticsListProps,
      onSuccess: () => {
        RecipeHelpers.showRecipeAddIngredientsNotification(recipe);
        analytics.bulkAddToCart(products, analyticsListProps);
      }
    });
  }, [order, ingredientProducts]);

  const handleReplaceIngredientClick = (ingredientProduct: IRecipeIngredientProduct) => {
    analytics.startRecipeIngredientReplace(
      recipeId,
      ingredientProduct?.product?.sku
    );

    setIngredientProductToReplace(ingredientProduct);
    openModal();
  };

  // Render

  return (
    <>

      {/* Root */}
      <div className={classnames(styles.root, className)}>

        {/* Header */}
        <div
          className={classnames(
            styles.header,
            { [styles.simplified]: withSimplifiedHeader },
            headerClassName,
          )}
        >

          {/* Title */}
          {
            !withSimplifiedHeader && (
              <div className={styles.title}>
                {t('recipes:RECIPE_DETAILS.INGREDIENTS.TITLE')}
              </div>
            )
          }

          {/* Button */}
          <Button
            ref={observeAddIngredientsBtnRef}
            //
            className={styles.addIngredientsBtn}
            //
            variant="primary"
            startIcon="cart-plus"
            //
            loading={isMutationLoading}
            disabled={!canBulkAddIngredients || isMutationLoading}
            //
            onClick={() => {
              handleAddRecipeIngredients();
            }}
            //
            data-testid="recipes-bulk-add-btn"
          >
            {addIngredientsBtnLabel}
          </Button>

          {/* Error */}
          {
            !canBulkAddIngredients && !isLoadingIngredients && (
              <p className={styles.error}>
                {t('recipes:GENERAL.ERRORS.UNAVAILABLE_INGREDIENTS')}
              </p>
            )
          }

          {/* Info */}
          {
            !withSimplifiedHeader && (
              <div
                className={styles.info}
                dangerouslySetInnerHTML={{ __html: headerInfoText }}
              />
            )
          }

        </div>

        {/* Children */}
        {children}

        {/* List */}
        <RecipeIngredientsList
          className={classnames(
            styles.list,
            listClassName
          )}
          itemClassName={styles.item}
          //
          // disabled={isMutationLoading}
          isLoading={isLoadingIngredients}
          loadingCount={primaryIngredients?.length}
          //
          order={order}
          ingredientProducts={ingredientProducts}
          cardProps={{
            listProps: analyticsListProps,
            withLink: true,
            withExternalInfo: true
          }}
          //
          onReplaceIngredientClick={(ingredientProduct: IRecipeIngredientProduct) => {
            handleReplaceIngredientClick(ingredientProduct);
          }}
        />

        {/* Disclaimer */}
        {disclaimer && <div className={styles.disclaimer}>{disclaimer}</div>}

      </div>

      {/* Sticky Anchor */}
      {
        withStickyAddIngredientsButton && (
          <Fade
            className={classnames(
              styles.anchor,
            )}
            isVisible={!isAddIngredientsBtnInView}
            persist
            enterDuration={200}
            exitDuration={200}
          >
            <div
              className={classnames(
                styles.anchorContent,
                styles.padded,
                { [styles.hidden]: isAddIngredientsBtnInView },
                { [styles.visible]: !isAddIngredientsBtnInView },
              )}
            >
              <Button
                //
                className={styles.addIngredientsBtn}
                //
                variant="primary"
                startIcon="cart-plus"
                //
                loading={isMutationLoading}
                disabled={!canBulkAddIngredients || isMutationLoading}
                //
                onClick={() => {
                  handleAddRecipeIngredients();
                }}
              >
                {addIngredientsBtnLabel}
              </Button>
            </div>
          </Fade>
        )
      }

      {/* Modal */}
      {
        !!ingredientProductToReplace && (
          <RecipeIngredientsModal
            isOpen={isModalOpen}
            //
            recipeId={recipeId}
            recipeSlug={slug}
            shouldLoadRecipe={false}
            //
            currentIngredientProducts={ingredientProducts}
            currentIngredientProductToReplace={ingredientProductToReplace}
            //
            onSelectedIngredientProductReplaced={(updatedIngredientProducts) => {
              setIngredientProducts(updatedIngredientProducts);
            }}
            onClose={() => {
              closeModal();
            }}
            onAfterClose={() => {
              setIngredientProductToReplace(undefined);
            }}
          />
        )
      }

    </>
  );
};

export default RecipeIngredientsListWithBulkAdd;
