import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Radio } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import RecipeIngredientCard, { IMAGE_SIZE as RECIPE_CARD_THUMBNAIL_SIZE } from '../RecipeIngredientCard/RecipeIngredientCard';
import RecipeIngredientCardSkeleton from '../RecipeIngredientCard/RecipeIngredientCardSkeleton';

import RecipeIngredientsListEmptyState from './RecipeIngredientsListEmptyState';

import type { Props as RecipeIngredientCardProps } from '../RecipeIngredientCard/RecipeIngredientCard';

import type {
  IOrder,
  IRecipeIngredientProduct
} from 'types';

import styles from './RecipeIngredientsList.module.scss';

interface Props {
  className?: string,
  itemClassName?: string,
  radioClassName?: string,
  ingredientClassName?: string,
  //
  name?: string,
  //
  isLoading?: boolean,
  loadingCount?: number,
  disabled?: boolean,
  //
  order?: IOrder,
  ingredientProducts: IRecipeIngredientProduct[],
  //
  withActions?: boolean,
  withVerboseActions?: boolean,
  cardProps?: Partial<RecipeIngredientCardProps>,
  //
  canSelect?: boolean,
  selectedSku?: string,
  //
  onReplaceIngredientClick?: (ingredientProduct: IRecipeIngredientProduct) => void,
  onSelectIngredient?: (ingredientProduct: IRecipeIngredientProduct, e: any) => void
}

const RecipeIngredientsList = (props: Props) => {

  const {
    className,
    itemClassName,
    radioClassName,
    ingredientClassName,
    //
    name,
    //
    isLoading,
    loadingCount = 3,
    disabled,
    //
    order,
    ingredientProducts,
    //
    canSelect,
    selectedSku,
    //
    cardProps = {},
    withActions = true,
    withVerboseActions,
    //
    onReplaceIngredientClick,
    onSelectIngredient,
  } = props;

  // Loading

  if (isLoading) {
    return (
      <div
        className={classnames(styles.root, className)}
        style={{
          '--thumbnailSize': `${RECIPE_CARD_THUMBNAIL_SIZE}px`
        } as CSSProperties}
      >
        {
          (Array.from(Array(loadingCount))).map((ingredient, index) => {
            return (
              <div
                key={`ingredientProduct-loading-${index}`}
                className={classnames(
                  styles.item,
                  { [styles.withRadio]: canSelect },
                  itemClassName
                )}
              >
                {
                  canSelect
                    ? (
                      <Radio
                        className={classnames(
                          styles.radio,
                          radioClassName,
                        )}
                        iconWrapperClassName={styles.radioIconWrapper}
                        name={name}
                        value={undefined}
                        // size={size}
                        tick
                        autofocus
                        withHover
                        disabled
                      >
                        <RecipeIngredientCardSkeleton
                          className={classnames(
                            styles.ingredient,
                            ingredientClassName
                          )}
                          {...cardProps}
                        />

                      </Radio>
                    ) : (

                      <RecipeIngredientCardSkeleton
                        className={classnames(
                          styles.ingredient,
                          ingredientClassName
                        )}
                        {...cardProps}
                      />
                    )
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  // Empty

  if (isArrayEmpty(ingredientProducts)) {
    return (
      <div
        className={classnames(styles.root, className)}
      >
        <RecipeIngredientsListEmptyState
          className={styles.emptyState}
        />
      </div>
    );
  }

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      style={{
        '--thumbnailSize': `${RECIPE_CARD_THUMBNAIL_SIZE}px`
      } as CSSProperties}
    >
      {
        ingredientProducts?.map((ingredientProduct, index) => {
          const { id, sku } = ingredientProduct || {};

          return (
            <div
              key={`ingredientProduct-${index}-${id}`}
              className={classnames(
                styles.item,
                { [styles.withRadio]: canSelect },
                itemClassName
              )}
            >
              {
                canSelect
                  ? (
                    <Radio
                      className={classnames(
                        styles.radio,
                        radioClassName,
                      )}
                      iconWrapperClassName={styles.radioIconWrapper}
                      name={name}
                      value={sku}
                      // size={size}
                      tick
                      autofocus
                      withHover
                      disabled={isLoading}
                      checked={selectedSku === sku}
                      onChange={(e) => {
                        if (onSelectIngredient) {
                          onSelectIngredient(ingredientProduct, e);
                        }
                      }}
                    >
                      <RecipeIngredientCard
                        className={classnames(
                          styles.ingredient,
                          ingredientClassName
                        )}
                        //
                        order={order}
                        ingredientProduct={ingredientProduct}
                        //
                        disabled={disabled}
                        //
                        withActions={withActions}
                        withVerboseActions={withVerboseActions}
                        {...cardProps}
                        //
                        onReplaceClick={onReplaceIngredientClick}
                      />
                    </Radio>
                  ) : (
                    <RecipeIngredientCard
                      className={classnames(
                        ingredientClassName
                      )}
                      //
                      order={order}
                      ingredientProduct={ingredientProduct}
                      //
                      disabled={disabled}
                      //
                      withActions={withActions}
                      withVerboseActions={withVerboseActions}
                      {...cardProps}
                      //
                      onReplaceClick={onReplaceIngredientClick}
                    />
                  )
              }

            </div>
          );
        })
      }
    </div>
  );
};

// Export

export default RecipeIngredientsList;
