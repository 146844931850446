import React from 'react';

import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';

const EMPTY_CART_HASH = 'zero-items';

export function useOrderStatusObserver() {

  const analytics = useAnalytics();

  const [currentItemsHash, setCurrentItemsHash] = React.useState(null);

  const { data: user } = useUser({ isOptional: true });
  const { data: order } = useActiveOrder();

  const {
    items,
    itemsHash
  } = order || {};

  React.useEffect(() => {
    setCurrentItemsHash(itemsHash);
  }, [itemsHash]);

  React.useEffect(() => {
    // Clear Cart
    if (currentItemsHash && currentItemsHash !== EMPTY_CART_HASH && itemsHash === EMPTY_CART_HASH) {
      analytics.clearCart(user);
    }

    // View Cart
    if (items?.length > 0 && currentItemsHash && itemsHash !== currentItemsHash) {
      analytics.viewCart(user, order);
    }
  }, [
    user,
    items,
    itemsHash,
    currentItemsHash,
  ]);
}
