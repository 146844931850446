import dynamic from 'next/dynamic';

export { default as AddToOrderModal } from './AddToOrderModal/AddToOrderModal';
export { default as OrderCancelModal } from './OrderCancelModal/OrderCancelModal';
export { default as OrderDetails } from './OrderDetails/OrderDetails';
export { default as OrderFeedbackLowRatingForm } from './OrderFeedbackLowRatingForm/OrderFeedbackLowRatingForm';
export { default as OrderFeedbackTestimonialForm } from './OrderFeedbackTestimonialForm/OrderFeedbackTestimonialForm';
export { default as OrderInfoBilling } from './OrderInfoBilling/OrderInfoBilling';
export { default as OrderInfoDate } from './OrderInfoDate/OrderInfoDate';
export { default as OrderInfoDelivery } from './OrderInfoDelivery/OrderInfoDelivery';
export { default as OrderInfoPayment } from './OrderInfoPayment/OrderInfoPayment';
export { default as OrderInvoices } from './OrderInvoices/OrderInvoices';
export { default as OrderItem } from './OrderItem/OrderItem';
export { default as OrderProblemModal } from './OrderProblemModal/OrderProblemModal';
export { default as OrderProductList } from './OrderProductList/OrderProductList';
export { default as OrderRatingCard } from './OrderRatingCard/OrderRatingCard';
export { default as OrderRepeatModal } from './OrderRepeatModal/OrderRepeatModal';
export { default as OrderRetryPaymentAlert } from './OrderRetryPaymentAlert/OrderRetryPaymentAlert';
export { default as OrderSummary } from './OrderSummary/OrderSummary';
export { default as OrderStatusBadge } from './OrderStatusBadge/OrderStatusBadge';
export { default as OrderFeedbackModal } from './OrderFeedbackModal/OrderFeedbackModal';

export const OrderGiftsModal = dynamic(() => import('./OrderGiftsModal/OrderGiftsModal'), { ssr: false });
