import type { ReactNode } from 'react';
import React from 'react';

import type { IProductVariant } from 'types/product';

export function isReactNode(node: any): node is ReactNode {
  return (
    node === null
    || typeof node === 'boolean'
    || typeof node === 'number'
    || typeof node === 'string'
    || React.isValidElement(node)
    || (Array.isArray(node) && node.every(isReactNode))
  );
}

export function isJSXElement(element: any): element is JSX.Element {
  return React.isValidElement(element);
}

export function isProductVariant(element: any): element is IProductVariant {
  return true;
}
