/* eslint-disable react/no-unknown-property */
import React from 'react';
import NextHead from 'next/head';
import { DefaultSeo } from 'next-seo';

import { Fonts } from 'common/Fonts';
import { DEFAULT_SEO } from 'constants/seo';

const Head = () => {

  return (
    <>
      <style jsx global>
        {`
          * {
            font-family: ${Fonts.firaSans.style.fontFamily};
          }
        `}
      </style>
      <DefaultSeo
        {...DEFAULT_SEO}
      />
      <NextHead>
        <meta name="viewport" content="width=device-width, viewport-fit=cover, initial-scale=1, maximum-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="https://webv2.freshful.ro/apple-touch-icon.png" />
        <link rel="icon" href="https://webv2.freshful.ro/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://webv2.freshful.ro/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://webv2.freshful.ro/favicon-16x16.png" />
        <link rel="manifest" href="https://webv2.freshful.ro/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </NextHead>
    </>
  );
};

export default Head;
