import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Price } from 'components/ui';

import styles from './RecipePrice.module.scss';

interface Props {
  className?: string,
  priceClassName?: string,
  originalPriceClassName?: string,
  perServingLabelClassName?: string,
  //
  pricePerServing: number,
  originalPricePerServing: number,
  isQuickView?: boolean
}

const RecipePrice = (props: Props) => {

  const {
    className,
    priceClassName,
    originalPriceClassName,
    perServingLabelClassName,
    //
    pricePerServing,
    originalPricePerServing,
    isQuickView = false
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <div className={classnames(styles.root, className, { [styles.isQuickView]: isQuickView })}>

      {/* Original Price */}
      {
        !!originalPricePerServing && (
          <Price
            className={classnames(
              styles.originalPrice,
              originalPriceClassName
            )}
            currencyClassName={styles.currency}
            //
            value={originalPricePerServing}
            lineThrough
          />
        )
      }

      {/* Price */}
      {
        !!pricePerServing && (
          <>

            {/* Current Price */}
            <Price
              className={classnames(
                styles.price,
                { [styles.discounted]: !!originalPricePerServing },
                priceClassName,
              )}
              currencyClassName={styles.currency}
              //
              value={pricePerServing}
            />

            {/* Per Serving Label */}
            <span
              className={classnames(
                styles.perServingLabel,
                perServingLabelClassName
              )}
            >
              {t('recipes:RECIPE_STATS.LABELS.PER_SERVING')}
            </span>

          </>
        )
      }
    </div>
  );
};

export default RecipePrice;
