import React from 'react';
import classnames from 'classnames';

import { Button, Icon } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';

import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './ProductAddToCart.module.scss';

const ICON_SIZES = {
  [Sizes.SMALL]: 20,
  [Sizes.MEDIUM]: 22,
  [Sizes.LARGE]: 24,
};

interface Props {
  className?: string,
  //
  size: Size,
  //
  isPrimary?: boolean,
  isReplace?: boolean,
  //
  withText?: boolean,
  withIcon?: boolean,
  withShortenedText?: boolean,
  hasBuyMore?: boolean,
  //
  loading: boolean,
  disabled: boolean,
  //
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductAddToCartButton = (props: Props) => {
  const {
    className,
    //
    size,
    //
    isPrimary,
    isReplace,
    //
    withText,
    withIcon,
    withShortenedText,
    hasBuyMore,
    //
    loading,
    disabled,
    //
    onClick,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const label = isReplace
    ? t('PRODUCT.REPLACE')
    : (
      withShortenedText
        ? t('PRODUCT.ADD_TO_CART_SHORT')
        : t('PRODUCT.ADD_TO_CART')
    );

  // Render

  return (
    <Button
      data-testid="add-to-cart-button"
      className={classnames(
        styles.btn,
        styles.addBtn,
        { [styles.fixed]: !withText },
        { [styles.withText]: withText },
        { [styles[size]]: size },
        className
      )}
      contentClassName={classnames(
        styles.btnContent,
        styles.addBtnContent
      )}
      iconWrapperClassName={classnames(
        styles.btnIcon,
        styles.addBtnIcon,
        { [styles.spaced]: withText && !hasBuyMore }
      )}
      variant={isPrimary ? 'primary' : 'subtle'}
      size={size}
      startIcon={
        withIcon
          ? (
            <Icon
              name={isReplace ? 'replace' : (hasBuyMore ? 'buy-more-cart' : 'cart-arrow')}
              size={hasBuyMore ? ICON_SIZES.medium : ICON_SIZES[size]}
              strokeWidth={2}
            />
          )
          : null
      }
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      //
      aria-label="Add to cart"
    >
      {
        withText && !hasBuyMore && (
          <span
            className={classnames(
              styles.btnLabel,
              styles.addBtnLabel
            )}
          >
            {label}
          </span>
        )
      }
    </Button>
  );
};

export default ProductAddToCartButton;
