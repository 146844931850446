import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getOrderBenefits } from 'services/OrdersService';

import type { IOrderBenefits, ReactQueryOptions } from 'types';

export const useOrderBenefits = (tokenValue: string, options: ReactQueryOptions<IOrderBenefits> = {}) => {
  return useQuery<IOrderBenefits>({
    queryKey: [
      QUERY_KEYS.ORDER_BENEFITS
    ],
    queryFn: () => getOrderBenefits(tokenValue),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });
};
