import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Badge from '../Badge/Badge';

import styles from './BadgeLabel.module.scss';

const TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
};

const BadgeLabel = (props) => {

  const {
    className,
    badge,
    type,
    children,
    ...rest
  } = props;

  const classes = classnames(
    styles.root,
    { [styles[type]]: type },
    className,
  );

  return (
    <div className={classes} {...rest}>
      <Badge type={type}>{badge}</Badge>
      {
        children && (
          <div className={styles.label}>
            {children}
          </div>
        )
      }
    </div>
  );
};

BadgeLabel.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  badge: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  children: PropTypes.node
};

export default BadgeLabel;
