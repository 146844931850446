import { convertStrapiSeoImage } from 'helpers/RecipeHelpers/generic';

import type{
  IRecipeCardData,
  IRecipesPagesSeo
} from 'types';

// --- SEO

export function convertRecipesPageSeo(title: string, recipeCard: IRecipeCardData): IRecipesPagesSeo {
  const {
    thumbnailImage: image
  } = recipeCard || {};

  return {
    title,
    description: null,
    images: image ? [convertStrapiSeoImage(image)] : []
  };
}
