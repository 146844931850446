import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'cart_events';

enum Actions {
  VIEW_ERROR = 'view_error',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- View Cart Error

export function viewCartError(message: string) {
  return push(Actions.VIEW_ERROR, message);
}
