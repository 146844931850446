/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import classnames from 'classnames';

import { Button, Link } from 'components/ui';
import { CONFIG } from 'constants/config';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import type { IListingOrderBy, IListingOrderByOption, IUrl } from 'types';

import styles from './OrderByOptions.module.scss';

interface Props {
  orderBy: IListingOrderBy,
  //
  onChange: (option: IListingOrderByOption, isSelected: boolean) => void,
  getHref: (option: IListingOrderByOption) => IUrl
}

const OrderByOptions = (props: Props) => {
  const {
    orderBy,
    onChange,
    getHref
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const { persist: persistAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // Handlers

  const handleOptionClick = (option: IListingOrderByOption, isSelected: boolean) => {
    const { field, direction } = option || {};

    if (field && direction) {
      analytics.applyOrderBy(field, direction);
    }

    persistAnalyticsListProps();

    if (onChange) {
      onChange(option, isSelected);
    }
  };

  // Empty

  if (isArrayEmpty(orderBy, true)) {
    return null;
  }

  // Render

  return (
    <div className={styles.root}>
      {
        orderBy.map((option) => {
          const {
            field, name, isSelected
          } = option;

          const href = getHref(option);

          return (
            <Link
              key={`${field}${name}`}
              href={href}
              prefetch={CONFIG.PREFETCH_LINKS}
              passHref
            >
              <Button
                tag="a"
                className={classnames(
                  styles.option,
                  { [styles.selected]: isSelected }
                )}
                onClick={() => {
                  handleOptionClick(option, isSelected);
                }}
              >
                <div className={styles.radio}><div className={styles.fill} /></div>
                <div className={styles.label}>{name}</div>
              </Button>
            </Link>
          );
        })
      }
    </div>
  );
};

export default OrderByOptions;
