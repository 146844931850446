import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isClient } from 'helpers/BrowserHelpers';

export function stall(ms = random(600, 2500), throws?: boolean) {
  return new Promise((resolve, reject) => {
    setTimeout(() => (throws ? reject(new Error()) : resolve(true)), ms);
  });
}

export function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function safeCall(fn: (...args: any[]) => any, args: any[], options: {errorMessage?: string} = {}) {
  try {
    return fn(...args);
  } catch (err) {
    const { errorMessage = 'Something went wrong' } = options;
    console.error(errorMessage, err);
  }
}

export function safeCallClient(fn: (...args: any[]) => any, args: any[], options: {errorMessage?: string} = {}) {
  if (!isClient()) return null;
  try {
    return fn(...args);
  } catch (err) {
    const { errorMessage = 'Something went wrong' } = options;
    console.error(errorMessage, err);
  }
}

export const linearInterpolation = (start: number, end: number, amt: number) => {
  return (1 - amt) * start + amt * end;
};

// TODO move the functions below to ObjectHelpers (when refactoring ObjectHelpers to TS)

export function isEmptyObject(obj: any) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function invertObject<T extends object>(obj: T): { [key: string]: keyof T } {
  return Object.keys(obj).reduce<{ [key: string]: keyof T }>((newObj, key) => {
    const value = obj[key as keyof T];
    newObj[String(value)] = key as keyof T;
    return newObj;
  }, {});
}

export function isAutomationTest() {
  if (!isClient()) return false;
  return !!LocalStorage.get(LOCAL_STORAGE_KEYS.FRESHFUL_AUTOMATION);
}
