import React from 'react';
import classnames from 'classnames';

import { Button } from 'components/ui';

import { useTranslation } from 'hooks/common/useTranslation';

import styles from './ProductAddToCart.module.scss';

interface Props {
  className?: string,
  withWideBuyMoreButton?: boolean,
  //
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductBuyMoreButton = (props: Props) => {
  const {
    className,
    withWideBuyMoreButton,
    //
    onClick
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <Button
      className={classnames(
        styles.btn,
        styles.buyMoreBtn,
        { [styles.wide]: withWideBuyMoreButton },
        className
      )}
      onClick={onClick}
      //
      aria-label="Buy more, save more"
    >
      {t('PRODUCT.BUY_MORE')}
    </Button>
  );
};

export default ProductBuyMoreButton;
