import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { FEATURES } from 'constants/features';
import * as OrderHelpers from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useUser } from 'hooks/data/useUser';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import CartProductsList from '../CartProductsList/CartProductsList';

import type { IOrder } from 'types';

import styles from './CartContent.module.scss';

interface Props {
  className?: string,
  order: IOrder
}

const CartContent = (props: Props) => {

  const {
    className,
    order
  } = props;

  const {
    analyticsListId,
    analyticsListName,
  } = order || {};

  // Hooks

  const { t } = useTranslation();

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  // Feature Flags

  const { isEnabled: shouldGroupProducts } = useFeatureFlag(FEATURES.GROUP_PRODUCTS);

  // Props

  const {
    isGeniusUser = false
  } = user || {};

  // --- Valid Products

  const validProducts = OrderHelpers
    .getValidProducts(order)
    .map((product) => ProductHelpers.enhanceOrderItem(product, {
      shouldShowGeniusPrice: isGeniusUser,
    }));

  // --- Invalid Products

  const invalidProducts = OrderHelpers
    .getInvalidProducts(order);

  // --- Flags

  const hasUnavailableProducts = invalidProducts?.length > 0;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Unavailable Products */}
      <CartProductsList
        className={classnames(styles.list, styles.unavailable)}
        titleClassName={styles.title}
        productClassName={styles.product}
        title={hasUnavailableProducts ? t('CART.UNAVAILABLE_PRODUCTS') : null}
        order={order}
        products={invalidProducts}
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
      />

      {/* Available Products */}
      <CartProductsList
        className={classnames(styles.list, styles.available)}
        titleClassName={classnames(styles.title, styles.bordered)}
        productClassName={styles.product}
        title={hasUnavailableProducts ? t('CART.IN_CART_PRODUCTS') : null}
        order={order}
        groupByCategory={shouldGroupProducts}
        products={validProducts}
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
      />

    </div>
  );
};

export default CartContent;
