import * as StringHelpers from 'helpers/StringHelpers';

// Types

type PlainObject = { [key: string]: any };

type MapFunction<T, U, K extends string> = (acc: T, key: K, value: U, index: number) => T;

type ObjectMap<T, G extends boolean> = G extends true ? { [key: string]: T[] } : { [key: string]: T };
type KeyFunction<T> = (item: T) => string | number;

type ObjectMapFunction<T, U, K extends string> = (value: T, key: K) => U;
interface ISearchMatchOptions {
  keys: string[],
  caseSensitive: boolean,
  replaceDiacritics: boolean,
  separated: boolean
}
interface ITransformString extends Pick<ISearchMatchOptions, 'caseSensitive' | 'replaceDiacritics'> {
  string: string,
  separated?: boolean,
  trimmed?: boolean
}

export function isObjectEmpty(obj: PlainObject, strict = false) {
  if (strict) {
    return obj !== null && typeof obj === 'object' && Object.values(obj).filter((i) => !!i).length === 0;
  }
  return obj != null && typeof obj === 'object' && Object.values(obj).filter((i) => !!i).length === 0;
}

export function objectMap<T, U, K extends string>(
  object: PlainObject,
  mapFn: ObjectMapFunction<T, U, K>
): Record<K, U> {
  if (!object) return {} as Record<K, U>;

  return Object.keys(object).reduce((result, key) => {
    result[key as K] = mapFn(object[key as K], key as K);
    return result;
  }, {} as Record<K, U>);
}

export function objectReduce<T>(
  object: PlainObject,
  mapFn: MapFunction<T, string, any>,
  initial: T = '' as unknown as T
): T {
  if (!object || typeof object !== 'object') return initial;

  return Object.keys(object).reduce((acc, key, index) => {
    return mapFn(acc, key, object[key], index);
  }, initial);
}

export function objectClean<T extends PlainObject>(object: T): Partial<T> {
  if (!object) return {} as Partial<T>;
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value != null && value !== '')
  ) as Partial<T>;
}

export function createObjectMap<T, G extends boolean>(
  array: T[],
  key: string | KeyFunction<T>,
  grouped: G
): ObjectMap<T, G> {
  if (!array || !Array.isArray(array)) return {} as ObjectMap<T, G>;

  return array.reduce((acc: ObjectMap<T, G>, item: T) => {
    const mapKey = typeof key === 'function' ? key(item) : (item as any)[key];

    if (grouped) {
      if (!acc[mapKey]) acc[mapKey] = [];
      (acc[mapKey] as T[]).push(item);
    } else {
      acc[mapKey] = item;
    }

    return acc;
  }, {} as ObjectMap<T, G>);
}

export function isSearchMatch(object: PlainObject, searchQuery = '', options: ISearchMatchOptions) {
  try {
    const {
      keys,
      caseSensitive = false,
      replaceDiacritics = true,
      separated = false,
    } = options || {};

    const objectKeys = (keys && Array.isArray(keys))
      ? keys
      : Object.keys(object);

    const transformedSearchQuery = transformString({
      string: searchQuery,
      caseSensitive,
      replaceDiacritics,
      trimmed: true,
      separated
    });

    return objectKeys.some((key) => {
      const value = transformString({
        string: object[key],
        caseSensitive,
        replaceDiacritics,
      });

      if (Array.isArray(transformedSearchQuery)) {
        return transformedSearchQuery.some((q) => value?.includes(q));
      }

      return value?.includes(transformedSearchQuery);
    });
  } catch (err) {
    console.error('Failed to search object', err);
  }
  return false;
}

// Helpers

const transformString = ({
  string,
  caseSensitive,
  replaceDiacritics,
  trimmed,
  separated
}: ITransformString): string | string[] => {
  let parsedString: string | string[] = string;

  if (!caseSensitive) {
    parsedString = parsedString?.toLowerCase();
  }

  if (replaceDiacritics) {
    parsedString = StringHelpers.replaceDiacritics(parsedString);
  }

  if (trimmed) {
    parsedString = parsedString?.trim();
  }

  if (separated) {
    parsedString = parsedString?.split(/[,.\s]/).filter((s) => !!s);
  }

  return parsedString;
};
