import { LocalStorage } from 'common/Storage';
import { CONFIG } from 'constants/config';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { isClient } from 'helpers/BrowserHelpers';
import { isAutomationTest } from 'helpers/utils';

import { PAGE_TYPES_MAP } from './AnalyticsPageTypes';

import type { AnalyticsPageTypeGetter } from './AnalyticsPageTypes';

export const isEnabled = CONFIG.GOOGLE.GTM_EVENTS_ENABLED;

// --- Push Event

export interface IPushEventOptions {
  shouldReset?: boolean,
  shouldSendNullEvent?: boolean,
  isClientOnly?: boolean,
  logMeta?: Record<string, any>
}

export function pushEvent(payload: any, options?: IPushEventOptions) {
  const {
    isClientOnly = true,
    shouldReset,
    shouldSendNullEvent,
    logMeta = {}
  } = options || {};

  if (shouldReset) {
    reset();
  }

  if (isClientOnly && !isClient()) return;

  logEvent({
    ...payload,
    page_type: getPageType()
  }, logMeta);

  const { dataLayer } = window;

  if (!isEnabled || !dataLayer || isAutomationTest()) return;

  if (shouldSendNullEvent) {
    dataLayer.push({ ecommerce: null });
  }

  dataLayer.push({
    ...payload,
    page_type: getPageType()
  });
}

// --- Push Config

export function pushConfig(event: string, eventName: string, payload: Record<string, any>) {
  if (!isClient()) return;

  logEvent({ event, event_name: eventName, payload });

  if (!isEnabled) return;

  const { dataLayer } = window;

  dataLayer.push(payload);
}

// --- Log event

export enum EventLogTypes {
  LOG = 'log',
  WARNING = 'warning',
}

interface LogEventOptions {
  type?: EventLogTypes,
  meta?: string
}

export function logEvent(payload: any = {}, options?: LogEventOptions) {
  const DEBUG = isClient()
    ? (window?.debugAnalytics || LocalStorage.get(LOCAL_STORAGE_KEYS.DEBUG_ANALYTICS))
    : false;

  const {
    type = EventLogTypes.LOG, meta
  } = options || {};

  if (DEBUG) {
    switch (type) {

      case EventLogTypes.LOG: {
        const { event, event_name: eventName } = payload;
        if (meta) {
          console.log(
            `%c ${meta}`,
            'background-color: dodgerblue; color: white'
          );
        }
        console.log(
          `%c New analytics event (enabled - ${isEnabled}) %c ${event || eventName} `,
          'background-color: seagreen; color: white',
          'margin-left: 8px; background-color: seagreen; color: white',
        );
        console.log(payload);
        break;
      }

      case EventLogTypes.WARNING: {
        const { method, message } = payload;
        console.warn(
          `%c Analytics warning (method - ${method}): ${message}`,
          'background-color: indianred; color: white'
        );
        break;
      }

      default:
        console.error('Unknown event log type', type);
    }
  }
}

// --- Reset dataLayer

export function reset() {
  if (isEnabled) {
    // eslint-disable-next-line func-names
    (window as any).dataLayer.push(function () {
      this.reset();
    });
  }

  logEvent({ event: 'reset' });
}

// --- Get Page Type

const PAGE_TYPES_REGEXP_MAP = Object.keys(PAGE_TYPES_MAP).reduce((acc, key) => {
  acc[key] = new RegExp(key);
  return acc;
}, {});

const UNKNOWN_PAGE_TYPE = 'unknown';

export function getPageType(): string {
  const pagePath = window.location.pathname || '';
  const paths = Object.keys(PAGE_TYPES_MAP);

  for (let index = 0; index < paths.length; index++) {
    const key = paths[index];
    const regexp = PAGE_TYPES_REGEXP_MAP[key];

    if (regexp.test(pagePath)) {
      if (typeof PAGE_TYPES_MAP[key] === 'function') {
        return (PAGE_TYPES_MAP[key] as AnalyticsPageTypeGetter)(pagePath);
      }
      return PAGE_TYPES_MAP[key] as string;
    }
  }

  return UNKNOWN_PAGE_TYPE;
}
