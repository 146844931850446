import React from 'react';
import classnames from 'classnames';

import { ProductAddToCart, ProductReplaceButton } from 'components/product';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { useProductCardActions } from 'hooks/useProductCardActions';

import type { IAnalyticsListProps, IOrder, IProductDetails } from 'types';

import styles from './RecipeIngredientCardActions.module.scss';

interface Props {
  className?: string,
  //
  order: IOrder,
  product: IProductDetails,
  listProps?: IAnalyticsListProps,
  //
  disabled?: boolean,
  canReplace?: boolean,
  //
  withVerboseActions?: boolean,
  //
  onLoading?: (isLoading: boolean) => void,
  onQuantityChange?: (data: any) => void,
  onReplaceClick?: () => void
}

const RecipeIngredientCardActions = React.memo((props: Props) => {
  const {
    className,
    //
    order,
    product,
    listProps,
    //
    disabled,
    canReplace,
    //
    withVerboseActions,
    //
    onLoading,
    onQuantityChange: onQuantityChangeSuccess,
    onReplaceClick,
  } = props;

  // Props

  const { isAvailable } = product;
  const orderItem = React.useMemo(() => OrderHelpers.getOrderItem(order, product), [order, product]);
  const quantity = React.useMemo(() => OrderHelpers.getOrderItemQuantity(order, product), [order, product]);

  const { isOk } = orderItem || {};

  // Handlers

  const {
    isProductActionLoading,
    isProductActionError,
    //
    handleAdd,
    handleQuantityChange,
  } = useProductCardActions({
    order,
    product,
    orderItem,
    listProps,
    //
    bypassAddressRequirement: true,
    bypassQuantityVariants: true,
    //
    onQuantityChangeSuccess,
  });

  // --- Click

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //  Effects

  React.useEffect(() => {
    if (onLoading) {
      onLoading(isProductActionLoading);
    }
  }, [isProductActionLoading]);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      onClick={onClick}
    >

      {/* Replace */}
      {
        (canReplace && (!isOk || quantity === 0))
          ? (
            <ProductReplaceButton
              //
              className={classnames(
                styles.actionButton,
                { [styles.fixedWidth]: !withVerboseActions }
              )}
              contentClassName={styles.buttonContent}
              //
              iconSize={18}
              withText={withVerboseActions}
              //
              disabled={isProductActionLoading || disabled}
              //
              onClick={onReplaceClick}
              //
            />
          ) : null
      }

      {/* Add To Cart */}
      {!!isAvailable
      && (
        <ProductAddToCart
        //
          className={styles.actions}
          addClassName={styles.add}
          adjustClassName={styles.adjust}
          editClassName={styles.edit}
          addToCartButtonClassName={classnames(
            styles.actionButton,
            { [styles.fixedWidth]: !withVerboseActions }
          )}
          adjustQuantityButtonClassName={classnames(
            styles.actionButton,
            { [styles.fixedWidth]: !withVerboseActions }
          )}
          quantityButtonClassName={styles.quantityButton}
          quantityInputClassName={styles.quantityInput}
          //
          product={product}
          quantity={quantity}
          //
          isAddToCartDisabled={isProductActionLoading}
          shouldReset={isProductActionError}
          shouldAdjustQuantity={!isOk}
          //
          isCompact
          size="small"
          withAddToCartText={withVerboseActions}
          withAddToCartIcon
          //
          onAddToCartClick={handleAdd}
          onQuantityChange={handleQuantityChange}
        />
      )}

    </div>
  );
});

// Export

export default RecipeIngredientCardActions;
