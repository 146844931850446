import type { UrlSegments } from './UrlParams';

import type { IPageUrlParams } from 'types';

export function getBaseSegments(
  segments: UrlSegments,
  params: Pick<IPageUrlParams, 'isPaginated' | 'isFiltered' | 'isOrdered' | 'filtersCount' | 'hasSearchQuery'>
) {
  const offset = [params.isPaginated, params.isOrdered].filter((val) => val).length;
  const partialOffset = params.isFiltered ? offset + params.filtersCount + 1 : offset;
  const totalOffset = params.hasSearchQuery ? partialOffset + 2 : partialOffset;
  return Array.isArray(segments) ? segments.slice(0, segments.length - totalOffset) : [];
}

export function hasSegments(segments: UrlSegments): segments is string[] {
  return Boolean(segments && Array.isArray(segments));
}

export const DEFAULT_URL_SEPARATOR = '+';
