import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Icon } from 'components/ui';

import styles from './CartButton.module.scss';

interface Props {
  className?: string,
  contentClassName?: string,
  iconClassName?: string,
  labelClassName?: string,
  icon: string,
  iconColor?: string,
  label: string,
  disabled?: boolean,
  afterContent?: React.ReactNode,
  beforeContent?: React.ReactNode,
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const CartButton = React.memo((props: Props) => {
  const {
    className,
    contentClassName,
    iconClassName,
    labelClassName,
    icon,
    iconColor,
    label,
    disabled,
    afterContent,
    beforeContent,
    onClick = () => {},
  } = props;

  const { t } = useTranslation();

  const ref = React.useRef<HTMLButtonElement>(null);

  return (
    <Button
      ref={ref}
      className={classnames(styles.root, className)}
      contentClassName={classnames(styles.content, contentClassName)}
      startIconWrapperClassName={classnames(styles.startIcon, iconClassName)}
      endIconWrapperClassName={classnames(styles.endIcon)}
      startIcon={(
        <Icon
          name={icon}
          size={20}
          style={{
            color: iconColor,
          }}
        />
      )}
      endIcon={(
        <Icon
          name="chevron-right"
          size={18}
          strokeWidth={2}
        />
      )}
      disabled={disabled}
      afterContent={afterContent}
      beforeContent={beforeContent}
      onClick={onClick}
    >
      <span className={classnames(styles.label, labelClassName)}>
        {t(label)}
      </span>
    </Button>
  );
});

export default CartButton;
