import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { getProductQuantityVariants } from 'services/ProductsService';

import type { IOrder, IProductQuantityVariantsResponse, ReactQueryOptions } from 'types';

export const useProductQuantityVariants = (
  variantCode: string,
  tokenValue: IOrder['tokenValue'],
  queryOptions: ReactQueryOptions<IProductQuantityVariantsResponse> = {}
) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.PRODUCT_QUANTITY_VARIANTS,
      variantCode,
      tokenValue
    ],
    queryFn: () => getProductQuantityVariants(variantCode, tokenValue),
    retry: 1,
    ...queryOptions
  });
};
