import React from 'react';
import dynamic from 'next/dynamic';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';

import styles from './Pagination.module.scss';

const Icon = dynamic(() => import('../Icon/Icon'), { ssr: false });

interface Props {
  className?: string,
  //
  activePage?: number,
  pageCount?: number,
  pageRangeDisplayed?: number,
  marginPagesDisplayed?: number,
  //
  onPageChange: (e: PaginationOnChangeEvent) => void
}

export type PaginationOnChangeEvent = {
  selected: number
};

const Pagination = (props: Props) => {
  const {
    pageCount = 1,
    activePage = 1,
    marginPagesDisplayed = 1,
    pageRangeDisplayed = 2,
    onPageChange,
    className
  } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <ReactPaginate
        previousLabel={<Icon name="chevron-left" className={styles.chevron} size={18} />}
        previousClassName="pagination-previous-page"
        nextClassName="pagination-next-page"
        nextLabel={<Icon name="chevron-right" className={styles.chevron} size={18} />}
        pageClassName="pagination-page"
        breakLabel="..."
        breakClassName="pagination-page-break"
        pageCount={pageCount}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        disabledClassName="pagination-disabled"
        forcePage={activePage - 1}
      />
    </div>
  );
};

export default Pagination;
