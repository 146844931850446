import { useMediaQuery } from 'hooks/useMediaQuery';

import CategoriesDesktop from './CategoriesDesktop';
import CategoriesMobile from './CategoriesMobile';

const CATEGORIES_BREAKPOINT = 1260;

const Categories = (props) => {
  const { isDesktopOnly } = props;

  const isBreakpoint = useMediaQuery(`(min-width: ${CATEGORIES_BREAKPOINT}px)`);

  if (isBreakpoint === null) {
    return <CategoriesMobile {...props} />;
  }

  return isBreakpoint
    ? <CategoriesDesktop {...props} />
    : (
      isDesktopOnly
        ? null
        : <CategoriesMobile {...props} />
    );
};

export default Categories;
