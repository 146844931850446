import * as ErrorHandler from 'common/ErrorHandler';
import { Api, SecureApi } from 'common/http';
import { ERROR_CODES } from 'constants/errors';

import type { ICheckAddressPayload } from 'types';

export const checkDeliveryAddress = async (address: ICheckAddressPayload) => {
  const { data } = await Api.post('/addresses/check', address);
  return data;
};

export const getNextDeliveryInterval = async (addressToken: string, isAuthenticated?: boolean) => {
  try {
    if (isAuthenticated) {
      const { data } = await SecureApi.get(`/addresses/${addressToken}/delivery-intervals/next`);
      return data;
    }
    const { data } = await Api.get(`/addresses/${addressToken}/delivery-intervals/next`);
    return data;
  } catch (error: any) {
    // TODO check why error is handled like this here
    if (error?.response?.status !== ERROR_CODES.NOT_FOUND) {
      ErrorHandler.throwError(error, 'Error while getting the next delivery interval');
    }
  }
};

export const getIntervalsByAddress = async (addressToken: string, isAuthenticated?: boolean) => {
  if (isAuthenticated) {
    const { data } = await SecureApi.get(`/addresses/${addressToken}/delivery-intervals-v2`);
    return data;
  }
  const { data } = await Api.get(`/addresses/${addressToken}/delivery-intervals`);
  return data;
};
