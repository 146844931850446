import React from 'react';

import { AUTH_REDIRECT_URL } from 'constants/config';
import { APP_ROUTES } from 'constants/routes';
import { decodeRedirectState, encodeRedirectState, isAuthenticatedRoute } from 'helpers/UrlHelpers';

const ExternalAuthRedirect = (props: {state?: string}) => {
  const {
    state = encodeRedirectState(APP_ROUTES.HOME)
  } = props;

  const parsedState = decodeRedirectState(state);

  const { path: redirectPath } = parsedState || {};

  // Handlers

  const handlePageShow = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.href = (
        !redirectPath || isAuthenticatedRoute(redirectPath)
          ? APP_ROUTES.HOME
          : redirectPath
      );
    }
  };

  // Effects

  React.useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  React.useEffect(() => {
    const redirectUrl = `${AUTH_REDIRECT_URL}&redirect_uri=${window.location.origin}/login/check-emag&state=${state}`;
    window.location.href = redirectUrl;
  }, [state]);

  // Return

  return null;
};

export default ExternalAuthRedirect;
