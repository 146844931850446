import type { TFunction } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as Notifications from 'common/Notification';
import { ERROR_CODES } from 'constants/errors';
import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import { useTranslation } from 'hooks/common/useTranslation';
import { useScheduledNewsletterSubscribe } from 'hooks/useScheduledNewsletterSubscribe';
import * as AccountService from 'services/AccountService';
import * as ConsentService from 'services/ConsentService';

import type {
  IAccountNewFeatureNotification,
  IAccountNewFeatureNotificationsResponse,
  IUpdateConsentPayload,
  IUser,
  ReactQueryMutationPayloadCallbacks
} from 'types';

enum Actions {
  // Phone verification
  SendPhoneVerificationCode,
  ValidatePhoneVerificationCode,
  // Genius trial
  ActivateGeniusTrial,
  // Cookies consent
  UpdateConsent,
  // Newsletter
  SubscribeToNewsletter,
  UnsubscribeFromNewsletter,
  UnsubscribeFromNewsletterWithHash,
  // Order Feedback
  DismissOrderFeedbackReminder,
  // Benefits
  DismissAccountBenefitsModal,
  // Abandoned Cart
  SubscribeToAbandonedCart,
  UnsubscribeFromAbandonedCart,
  UnsubscribeFromAbandonedCartWithHash,
  // Loyalty
  UpdateLoyaltyOptInStatus,
  // New Features
  DismissNewFeatureNotification,
  DismissNewFeatureModal,
}

export const useAccountMutation = () => {
  const mutation = useAccountMutationInternal();

  return {
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,

    // Phone verifications

    sendPhoneVerificationCode: (payload: SendPhoneVerificationCodePayload) => {
      mutation.mutate({ payload, type: Actions.SendPhoneVerificationCode });
    },
    validatePhoneVerificationCode: (payload: ValidatePhoneVerificationCodePayload) => {
      mutation.mutate({ payload, type: Actions.ValidatePhoneVerificationCode });
    },

    // Genius trial

    activateGeniusTrial: (payload: ActivateGeniusTrialPayload) => {
      mutation.mutate({ payload, type: Actions.ActivateGeniusTrial });
    },

    // Cookies consent

    updateConsent: (payload: UpdateConsentPayload) => {
      mutation.mutate({ payload, type: Actions.UpdateConsent });
    },

    // Newsletter

    subscribeToNewsletter: (payload: SubscribeToNewsletterPayload) => {
      mutation.mutate({ payload, type: Actions.SubscribeToNewsletter });
    },
    unsubscribeFromNewsletter: (payload: UnsubscribeFromNewsletterPayload) => {
      mutation.mutate({ payload, type: Actions.UnsubscribeFromNewsletter });
    },
    unsubscribeFromNewsletterWithHash: (payload: UnsubscribeWithHashPayload) => {
      mutation.mutate({ payload, type: Actions.UnsubscribeFromNewsletterWithHash });
    },
    dismissOrderFeedbackReminder: (payload: DismissOrderFeedbackReminderPayload) => {
      mutation.mutate({ payload, type: Actions.DismissOrderFeedbackReminder });
    },
    dismissAccountBenefitsModal: (payload: DismissAccountBenefitsModalPayload) => {
      mutation.mutate({ payload, type: Actions.DismissAccountBenefitsModal });
    },

    // Abandoned Cart

    subscribeToAbandonedCart: (payload: SubscribeToAbandonedCartPayload) => {
      mutation.mutate({ payload, type: Actions.SubscribeToAbandonedCart });
    },
    unsubscribeFromAbandonedCart: (payload: UnsubscribeFromAbandonedCartPayload) => {
      mutation.mutate({ payload, type: Actions.UnsubscribeFromAbandonedCart });
    },
    unsubscribeFromAbandonedCartWithHash: (payload: UnsubscribeWithHashPayload) => {
      mutation.mutate({ payload, type: Actions.UnsubscribeFromAbandonedCartWithHash });
    },

    // Loyalty

    updateLoyaltyOptInStatus: (payload: UpdateLoyaltyOptInStatusPayload) => {
      mutation.mutate({ payload, type: Actions.UpdateLoyaltyOptInStatus });
    },

    // New Features

    dismissNewFeatureModal: (payload: DismissNewFeatureModalPayload) => {
      mutation.mutate({ payload, type: Actions.DismissNewFeatureModal });
    },

    dismissNewFeatureNotification: (payload: DismissNewFeatureNotificationPayload) => {
      mutation.mutate({ payload, type: Actions.DismissNewFeatureNotification });
    },

  };
};

const useAccountMutationInternal = () => {

  const queryKey = [QUERY_KEYS.USER];

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { markAsSeen: markSubscribeToNewsletterModalAsSeen } = useScheduledNewsletterSubscribe();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ACCOUNT],
    retry: (failureCount, error: any) => error?.response?.status === ERROR_CODES.SERVER_ERROR && failureCount < 1,
    mutationFn: (args: {type: Actions, payload: MutationPayload}) => {
      const { type, payload } = args;

      switch (type) {

        // Phone verifications

        case Actions.SendPhoneVerificationCode: {
          const { data } = payload as SendPhoneVerificationCodePayload;
          const { phoneNumber } = data;
          return AccountService.sendPhoneVerificationCode(phoneNumber);
        }

        case Actions.ValidatePhoneVerificationCode: {
          const { data } = payload as ValidatePhoneVerificationCodePayload;
          const { phoneNumber, code } = data;
          return AccountService.validatePhoneVerificationCode({
            code,
            phoneNumber
          });
        }

        // Genius trial

        case Actions.ActivateGeniusTrial: {
          return AccountService.activateGeniusTrial();
        }

        // Cookies consent

        case Actions.UpdateConsent: {
          const { data } = payload as UpdateConsentPayload;
          const { timestamp, cookies } = data;
          return ConsentService.updateConsent({
            timestamp,
            cookies,
          });
        }

        // Newsletter

        case Actions.SubscribeToNewsletter: {
          return AccountService.subscribeToNewsletter();
        }

        case Actions.UnsubscribeFromNewsletter: {
          return AccountService.unsubscribeFromNewsletter();
        }

        case Actions.UnsubscribeFromNewsletterWithHash: {
          const { data } = payload as UnsubscribeWithHashPayload;
          const { cid, hash } = data;
          return AccountService.unsubscribeFromNewsletterWithHash({ cid, hash });
        }

        // Order feedback

        case Actions.DismissOrderFeedbackReminder: {
          const timestamp = (new Date()).toISOString();
          return AccountService.dismissOrderFeedbackReminder(timestamp);
        }

        // Account Benefits

        case Actions.DismissAccountBenefitsModal: {
          const { data } = payload as DismissAccountBenefitsModalPayload;
          const { segmentId } = data;
          return AccountService.dismissSegmentAnnouncement(segmentId);
        }

        // Abandoned Cart

        case Actions.SubscribeToAbandonedCart: {
          return AccountService.subscribeToAbandonedCart();
        }

        case Actions.UnsubscribeFromAbandonedCart: {
          return AccountService.unsubscribeFromAbandonedCart();
        }

        case Actions.UnsubscribeFromAbandonedCartWithHash: {
          const { data } = payload as UnsubscribeWithHashPayload;
          const { cid, hash } = data;
          return AccountService.unsubscribeFromAbandonedCartWithHash({ cid, hash });
        }

        // Loyalty

        case Actions.UpdateLoyaltyOptInStatus: {
          const { data } = payload as UpdateLoyaltyOptInStatusPayload;
          const { optIn } = data;
          return AccountService.updateLoyaltyOptInStatus(optIn);
        }

        // New Features

        case Actions.DismissNewFeatureModal: {
          const { data } = payload as DismissNewFeatureModalPayload;
          const { segmentId } = data;
          return AccountService.dismissSegmentAnnouncement(segmentId);
        }

        case Actions.DismissNewFeatureNotification: {
          const { data } = payload as DismissNewFeatureNotificationPayload;
          const { segmentId } = data;
          return AccountService.dismissSegmentAnnouncement(segmentId);
        }

        // Default

        default:
          console.error('Unknown mutation type', type);
          return new Promise((resolve) => resolve(true));
      }
    },
    onMutate: async (args) => {
      const { type, payload } = args;

      await queryClient.cancelQueries({ queryKey });
      const previousState = queryClient.getQueryData(queryKey);

      switch (type) {

        // Newsletter

        case Actions.SubscribeToNewsletter: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({ ...user, subscribedToNewsletter: true }));
          break;
        }

        case Actions.UnsubscribeFromNewsletter: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({ ...user, subscribedToNewsletter: false }));
          break;
        }

        // Abandoned Cart

        case Actions.SubscribeToAbandonedCart: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({ ...user, subscribedToAbandonedCart: true }));
          break;
        }

        case Actions.UnsubscribeFromAbandonedCart: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({ ...user, subscribedToAbandonedCart: false }));
          break;
        }

        // Benefits

        case Actions.DismissAccountBenefitsModal: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({ ...user, shouldShowBenefitsModal: false }));
          break;
        }

        // New Features

        case Actions.DismissNewFeatureModal: {
          const { data } = payload as DismissNewFeatureModalPayload;
          const { segmentId } = data || {};

          queryClient.setQueryData([QUERY_KEYS.ACCOUNT_NEW_FEATURE_NOTIFICATIONS], (previousData: IAccountNewFeatureNotificationsResponse) => ({
            intros: previousData?.intros?.filter((intro) => intro?.segmentId !== segmentId)
          }));
          break;
        }

        case Actions.DismissNewFeatureNotification: {
          const { data } = payload as DismissNewFeatureNotificationPayload;
          const { segmentId } = data || {};

          queryClient.setQueryData([QUERY_KEYS.ACCOUNT_NEW_FEATURE_NOTIFICATIONS], (previousData: IAccountNewFeatureNotificationsResponse) => ({
            intros: previousData?.intros?.filter((intro) => intro?.segmentId !== segmentId)
          }));
          break;
        }

        // Misc

        case Actions.SendPhoneVerificationCode:
        case Actions.ValidatePhoneVerificationCode:
        case Actions.UpdateConsent:
        case Actions.ActivateGeniusTrial:
        case Actions.UnsubscribeFromNewsletterWithHash:
        case Actions.DismissOrderFeedbackReminder:
        case Actions.UnsubscribeFromAbandonedCartWithHash:
          break;

          // Default

        default:
          console.error('Unknown mutation type (on mutate)', type);
      }

      return { previousState };
    },
    onSuccess: (data, args) => {
      const { payload, type } = args;

      switch (type) {

        // Phone verification

        case Actions.SendPhoneVerificationCode: {
          break;
        }

        case Actions.ValidatePhoneVerificationCode: {
          queryClient.setQueryData([QUERY_KEYS.USER], (user: any) => ({ ...user, ...data }));
          break;
        }

        // Genius trial

        case Actions.ActivateGeniusTrial: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INTERVALS_BY_ORDER] });
          break;
        }

        // Cookies consent

        case Actions.UpdateConsent: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSENT] });
          break;
        }

        // Newsletter

        case Actions.SubscribeToNewsletter: {
          queryClient.setQueryData([QUERY_KEYS.USER], (user: IUser) => ({ ...user, ...data }));
          break;
        }

        case Actions.UnsubscribeFromNewsletter:
        case Actions.UnsubscribeFromNewsletterWithHash: {
          markSubscribeToNewsletterModalAsSeen();
          queryClient.setQueryData([QUERY_KEYS.USER], (user: IUser) => ({ ...user, ...data }));
          break;
        }

        // Order feedback

        case Actions.DismissOrderFeedbackReminder: {
          queryClient.setQueryData([QUERY_KEYS.ORDER_FEEDBACK_REMINDERS], null);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_FEEDBACK_REMINDERS] });
          break;
        }

        // Account Benefits

        case Actions.DismissAccountBenefitsModal: {
          queryClient.setQueryData([QUERY_KEYS.ACCOUNT_BENEFITS_MODAL], null);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          break;
        }

        // Abandoned Cart

        case Actions.SubscribeToAbandonedCart: {
          queryClient.setQueryData([QUERY_KEYS.USER], (user: IUser) => ({ ...user, ...data }));
          break;
        }

        case Actions.UnsubscribeFromAbandonedCart:
        case Actions.UnsubscribeFromAbandonedCartWithHash: {
          queryClient.setQueryData([QUERY_KEYS.USER], (user: IUser) => ({ ...user, ...data }));
          break;
        }

        // Loyalty

        case Actions.UpdateLoyaltyOptInStatus: {
          queryClient.setQueryData([QUERY_KEYS.USER], (user: IUser) => ({
            ...user,
            loyalty: {
              ...user?.loyalty,
              ...data
            }
          }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          break;
        }

        // New Features

        case Actions.DismissNewFeatureModal:
        case Actions.DismissNewFeatureNotification: {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACCOUNT_NEW_FEATURE_NOTIFICATIONS] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          break;
        }

        // Default

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err, args, context) => {
      const { payload, type } = args || {};
      const { previousState } = context as {previousState: {user: IUser}}; // TODO fix type

      switch (type) {

        case Actions.SubscribeToNewsletter:
        case Actions.UnsubscribeFromNewsletter: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({
            ...user,
            subscribedToNewsletter: previousState.user.subscribedToNewsletter
          }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          handleError(err, type, t);
          break;
        }

        case Actions.SubscribeToAbandonedCart:
        case Actions.UnsubscribeFromAbandonedCart: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({
            ...user,
            subscribedToAbandonedCart: previousState.user.subscribedToAbandonedCart
          }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          handleError(err, type, t);
          break;
        }

        case Actions.DismissOrderFeedbackReminder:
        case Actions.UnsubscribeFromNewsletterWithHash: {
          handleError(err, type, t);
          break;
        }
        case Actions.UnsubscribeFromAbandonedCartWithHash: {
          handleError(err, type, t);
          break;
        }

        case Actions.UpdateLoyaltyOptInStatus: {
          queryClient.setQueryData(queryKey, (user: IUser) => ({
            ...user,
            loyalty: {
              ...user?.loyalty,
              ...previousState.user.loyalty
            }
          }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] });
          handleError(err, type, t);
          break;
        }

        case Actions.DismissNewFeatureModal:
        case Actions.DismissNewFeatureNotification: {
          handleError(err, type, t);
          break;
        }

        case Actions.ValidatePhoneVerificationCode:
        case Actions.SendPhoneVerificationCode:
        case Actions.UpdateConsent:
        case Actions.ActivateGeniusTrial:
          break;

        default: {
          console.error('Unknown mutation type (on error)', type);
        }
      }

      if (payload?.onError) {
        payload.onError(err);
      }
    },
    onSettled: (data, err, args) => {
      const { payload } = args || {};
      if (payload?.onSettled) {
        payload.onSettled(data);
      }
    }
  });
};

// Payload Types

interface SendPhoneVerificationCodePayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    phoneNumber: string
  }
}

interface ValidatePhoneVerificationCodePayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    code: string,
    phoneNumber: string
  }
}
interface ActivateGeniusTrialPayload extends ReactQueryMutationPayloadCallbacks {
  data?: {}
}

interface UpdateConsentPayload extends ReactQueryMutationPayloadCallbacks {
  data: IUpdateConsentPayload
}

interface SubscribeToNewsletterPayload extends ReactQueryMutationPayloadCallbacks {
}

interface UnsubscribeFromNewsletterPayload extends ReactQueryMutationPayloadCallbacks {
}

interface UnsubscribeWithHashPayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    cid: string,
    hash: string
  }
}

interface SubscribeToAbandonedCartPayload extends ReactQueryMutationPayloadCallbacks {
}

interface UnsubscribeFromAbandonedCartPayload extends ReactQueryMutationPayloadCallbacks {
}

interface DismissOrderFeedbackReminderPayload extends ReactQueryMutationPayloadCallbacks {
}

interface DismissAccountBenefitsModalPayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    segmentId: number
  }
}

interface UpdateLoyaltyOptInStatusPayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    optIn: boolean
  }
}

interface DismissNewFeatureModalPayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    segmentId: number
  }
}

interface DismissNewFeatureNotificationPayload extends ReactQueryMutationPayloadCallbacks {
  data: {
    segmentId: number
  }
}

//

type MutationPayload =
  | SendPhoneVerificationCodePayload
  | ValidatePhoneVerificationCodePayload
  | ActivateGeniusTrialPayload
  | SubscribeToNewsletterPayload
  | UnsubscribeFromNewsletterPayload
  | UnsubscribeWithHashPayload
  | DismissOrderFeedbackReminderPayload
  | SubscribeToAbandonedCartPayload
  | UnsubscribeFromAbandonedCartPayload
  | UpdateLoyaltyOptInStatusPayload
  | DismissNewFeatureModalPayload
  | DismissNewFeatureNotificationPayload

// Helpers

function handleError(err: any, type: Actions, t: TFunction) {
  const { response = {} } = err;
  const { status, data } = response;
  if (status === ERROR_CODES.VALIDATION_ERROR) {
    const errorMessage = data?.violations?.[0]?.message || t('ERRORS.DEFAULT_TOAST');
    Notifications.showError(errorMessage, { autoClose: 2000, toastId: type });
  } else {
    Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: type });
  }
}
