import React from 'react';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { BackButton } from 'components/navigation';
import {
  Accordion, Alert, Modal, ModalBody, Radio
} from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useConfig } from 'hooks/data/useConfig';
import { useOrderMutation } from 'hooks/data/useOrderMutation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderInfoBlock from '../OrderInfoBlock/OrderInfoBlock';

import type { IOngoingOrder } from 'types';

import styles from './AddToOrderModal.module.scss';

interface Props {
  isOpen: boolean,
  orderToken: string,
  orders: IOngoingOrder[],
  onSubmitSuccess: () => void,
  onClose: () => void
}

const AddToOrderModal = (props: Props) => {

  const {
    isOpen,
    orderToken,
    orders = [],
    onSubmitSuccess = () => {},
    onClose = () => {},
  } = props;

  const [selectedOrderToken, setSelectedOrderToken] = React.useState(null);
  const [isNewOrder, setIsNewOrder] = React.useState(null);

  // Hooks

  const { t } = useTranslation();
  const { data: config } = useConfig();
  const { add2OrderPopup: addToOrderPopupConfig } = config || {};
  const { isLoading: isMutationLoading, addToOrder } = useOrderMutation();

  // Handlers

  const onSubmit = (addToTokenValue: string) => {
    setIsNewOrder(!addToTokenValue);
    addToOrder({
      tokenValue: orderToken,
      addTo: addToTokenValue,
      onSuccess: () => {
        onSubmitSuccess();
      },
      onError: () => {
        Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'addToOrderModalError' });
      }
    });
  };

  // Props

  const hasEligibleOrders = OrderHelpers.canAddToOrder(orders);

  return (
    <Modal
      isOpen={isOpen}
      //
      className={classnames(styles.root, styles.modal)}
      contentClassName={styles.modalContent}
      innerClassName={styles.modalInner}
      headerClassName={styles.modalHeader}
      //
      withCloseButton={false}
      withPadding={false}
      breakpoint="md"
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
        isFullScreen: false,
        withPadding: false
      }}
      //
      title={(
        <>
          <BackButton
            onClick={() => {
              onClose();
            }}
          />
          <div className={styles.title}>{t('ADD_TO_ORDER.MODAL.TITLE')}</div>
        </>
      )}
      headerProps={{
        withBorder: true,
        withCloseButton: false
      }}
      //
      shouldCloseOnOverlayClick={false}
      //
      close={onClose}
    >

      {/* Body */}
      <ModalBody
        className={styles.body}
        childrenClassName={styles.children}
        actionsProps={{
          reversed: true,
          primary: {
            text: t('ADD_TO_ORDER.MODAL.ACTIONS.COMPLETE_ORDER'),
            loading: !isNewOrder && isMutationLoading,
            disabled: !selectedOrderToken || isMutationLoading,
            onClick: () => {
              onSubmit(selectedOrderToken);
            }
          },
          secondary: {
            text: t('ADD_TO_ORDER.MODAL.ACTIONS.NEW_ORDER'),
            loading: isNewOrder && isMutationLoading,
            disabled: isMutationLoading,
            onClick: () => {
              onSubmit(null);
            }
          }
        }}
      >

        {/* Description */}
        <p className={classnames(styles.description, { [styles.error]: !hasEligibleOrders })}>
          {
            hasEligibleOrders
              ? t('ADD_TO_ORDER.MODAL.DESCRIPTION.WITH_ELIGIBLE_ORDERS')
              : (addToOrderPopupConfig?.noEligibleOrdersDescription || t('ADD_TO_ORDER.MODAL.DESCRIPTION.WITHOUT_ELIGIBLE_ORDERS'))
          }
        </p>

        {/* Order List */}
        <OrderList
          name="ongoing-orders"
          disabled={isMutationLoading}
          orders={orders}
          selectedTokenValue={selectedOrderToken}
          onSelect={(token) => {
            setSelectedOrderToken(token);
          }}
        />

        {/* Voucher disclaimer */}
        <div className={styles.voucherDisclaimer}>
          <Alert
            variant="custom"
            icon="info-circle"
            className={styles.voucherDisclaimerAlert}
            iconClassName={styles.voucherDisclaimerIcon}
            description={t('ADD_TO_ORDER.MODAL.VOUCHER_DISCLAIMER')}
          />
        </div>
      </ModalBody>

    </Modal>
  );
};

// Components

// --- Order List

interface OrderListProps {
  name: string,
  orders: IOngoingOrder[],
  selectedTokenValue: string,
  disabled: boolean,
  onSelect: (token: string) => void
}

const OrderList = (props: OrderListProps) => {
  const {
    name,
    orders,
    selectedTokenValue,
    disabled,
    onSelect
  } = props;

  return (
    <div className={styles.list}>
      {
        orders?.map((order) => {
          return (
            <OrderRadio
              key={order?.tokenValue}
              name={name}
              order={order}
              disabled={disabled}
              checked={order?.tokenValue === selectedTokenValue}
              onSelect={onSelect}
            />
          );
        })
      }
    </div>
  );
};

// --- Order Radio

interface OrderRadioProps {
  name: string,
  order: IOngoingOrder,
  checked: boolean,
  disabled: boolean,
  onSelect: (token: string, e?: React.ChangeEvent) => void
}

const OrderRadio = (props: OrderRadioProps) => {
  const {
    name,
    order,
    checked,
    disabled,
    onSelect = () => {}
  } = props;

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const {
    eligible,
    tokenValue,
    title,
    errors,
    warnings,
    isSelected,
  } = order;

  React.useEffect(() => {
    if (eligible && isSelected) {
      onSelect(tokenValue);
    }
  }, [eligible, isSelected]);

  return (
    <div className={styles.order}>
      <Radio
        key={tokenValue}
        className={styles.radio}
        disabledClassName={styles.disabled}
        iconClassName={styles.radioIcon}
        loaderClassName={styles.radioLoader}
        name={name}
        value={title}
        size="medium"
        tick
        autofocus
        withHover={!isBreakpoint}
        checked={eligible && checked}
        disabled={!eligible || disabled}
        onChange={(e) => {
          onSelect(tokenValue, e);
        }}
      >
        <Accordion
          expandable
          expandOnIconOnly
          className={styles.accordion}
          headerClassName={styles.header}
          headerInnerClassName={styles.headerInner}
          titleClassName={styles.title}
          iconWrapperClassName={styles.iconWrapper}
          iconClassName={styles.icon}
          iconSize={22}
          iconStrokeWidth={1.5}
          title={title}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <OrderInfoBlock
            className={styles.details}
            label={t('LABELS.DELIVERY')}
            value={order?.details?.interval}
            subValue={order?.details?.address}
            size="small"
          />
        </Accordion>
      </Radio>

      {/* Errors */}
      {
        !isArrayEmpty(errors) && (
          <div className={styles.errors}>
            {
              errors?.map((error, index) => (
                <Alert
                  key={index}
                  className={styles.error}
                  variant="error"
                  size="medium"
                  description={error}
                />
              ))
            }
          </div>
        )
      }

      {/* Warnings */}
      {
        !isArrayEmpty(warnings) && (
          <div className={styles.warnings}>
            {
              warnings.map((warning, index) => (
                <Alert
                  key={index}
                  className={styles.warning}
                  variant="warning"
                  size="medium"
                  description={warning}
                />
              ))
            }
          </div>
        )
      }

    </div>
  );
};

export default AddToOrderModal;
