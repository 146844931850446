/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { Image, Loader } from 'components/ui';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useInView } from 'hooks/common/useInView';
import { useActiveOrder } from 'hooks/data/useActiveOrder';

import {
  ProductBadge,
  ProductBanner,
  ProductFavoritesButton,
  ProductLabels,
  ProductPrice,
  ProductQuantityOverlay,
  ProductSimilarProductsButton,
  ProductTags,
} from '../../parts';

import ProductLink from '../../ProductLink/ProductLink';

import type {
  IAnalyticsListProps, IProduct, IProductVariant, Size,
} from 'types';

import { Sizes } from 'types';

import styles from './ProductDefaultCard.module.scss';

const ProductDefaultCardActions = dynamic(() => import('./ProductDefaultCardActions'), { ssr: false });

type ProductLinkProps = React.ComponentProps<typeof ProductLink>;

interface Props {
  //
  className?: string,
  nameTextClassName?: string,
  brandClassName?: string,
  //
  product: IProduct,
  //
  modalProps?: ProductLinkProps['modalProps'],
  detailsProps?: ProductLinkProps['detailsProps'],
  listProps?: IAnalyticsListProps,
  //
  withBanner?: boolean,
  withTallBanner?: boolean,
  withSmallLabels?: boolean,
  replaceProductVariantCode?: string,
  forceAcceleratedSaleVariant?: boolean,
  bypassAddressRequirement?: boolean,
  bypassQuantityVariants?: boolean,
  //
  shouldShowGeniusPrice: boolean,
  //
  labelsSize?: Size,
  responsiveLabelsSize?: Size,
  //
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  onEnter?: () => void,
  onQuantityChange?: () => void
}

const ProductDefaultCard = (props: Props) => {

  const {
    //
    className,
    nameTextClassName,
    brandClassName,
    //
    product,
    //
    modalProps,
    detailsProps,
    listProps,
    //
    withBanner = true,
    withTallBanner,
    withSmallLabels = false,
    replaceProductVariantCode,
    forceAcceleratedSaleVariant,
    bypassAddressRequirement,
    bypassQuantityVariants,
    //
    shouldShowGeniusPrice,
    //
    labelsSize = Sizes.LARGE,
    responsiveLabelsSize = Sizes.MEDIUM,
    //
    onClick,
    onEnter,
    onQuantityChange,
    //
  } = props;

  // Ref

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter();
      }
      unobserve();
    }
  });

  // Hooks

  const [isLoading, setLoading] = React.useState(false);

  // Data Hooks

  const {
    isLoading: isOrderLoading,
    data: order
  } = useActiveOrder();

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Props

  const variant: IProductVariant | undefined = React.useMemo(() => {
    const params = {
      shouldShowGeniusPrice,
    };
    return forceAcceleratedSaleVariant
      ? ProductHelpers.getProductAcceleratedSaleVariant(product, params)
      : ProductHelpers.getProductDefaultVariant(product, params);
  }, [
    product,
    forceAcceleratedSaleVariant,
    shouldShowGeniusPrice
  ]);

  // --- Product

  const {
    slug,
    image,
    brand,
    name,
    badge,
    tags,
    taxes,
  } = product || {};

  // --- Variant

  const {
    isAvailable,
    //
    currency,
    originalPriceStrikethrough,
    originalPrice,
    price,
    unitPriceLabel,
    //
    hasGeniusPrice,
    //
    priceIcon,
    priceColor,
    priceTooltip,
    //
    labels = [],
    infoLabels,
    secondaryLabels,
    banner,
    //
  } = variant || {};

  const shouldWrapTags = banner && labels.length > 1;
  const hasBanner = withBanner && banner;
  const hasInfoLabels = React.useMemo(() => !isArrayEmpty(infoLabels) && infoLabels, [infoLabels]);

  // --- Quantity

  const quantity = React.useMemo(
    () => OrderHelpers.getOrderItemQuantity(order, variant),
    [order, variant]
  );

  // Handlers

  const onSimilarClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showModal(MODAL_TYPES.SIMILAR_PRODUCTS, { productSlug: slug });
  }, [product, showModal]);

  const onCardClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isLoading) {
      e.preventDefault();
    }

    if ((e?.target as HTMLElement)?.getAttribute('data-prevent-click-propagation')) {
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  if (!variant) return;

  // Render

  return (
    <ProductLink
      ref={onEnter ? observe : null}
      slug={slug}
      isAcceleratedSale={variant?.isAcceleratedSale}
      modalProps={modalProps}
      detailsProps={{
        withoutToolbar: true,
        ...(detailsProps || {}),
      }}
      listProps={listProps}
    >
      <a
        className={classnames(
          styles.root,
          { [styles.disabled]: !!isLoading },
          className
        )}
        aria-disabled={isLoading}
        tabIndex={isLoading ? -1 : undefined}
        data-available={isAvailable}
        onClick={onCardClick}
      >

        {/* Favorite Button */}
        <ProductFavoritesButton
          className={styles.favoritesButton}
          order={order}
          product={product}
          listProps={listProps}
          size="small"
          color="gray"
        />

        {/* Image */}
        <div className={styles.imageContainer}>

          {/* Labels */}
          <ProductLabels
            className={styles.labels}
            labels={labels}
            size={withSmallLabels ? Sizes.SMALL : labelsSize}
            responsiveSize={responsiveLabelsSize}
          />

          {/* Quantity Overlay */}
          <ProductQuantityOverlay
            quantity={quantity}
          />

          {/* Thumbnail */}
          {
            image?.thumbnail && (
              <Image
                className={classnames(
                  styles.image,
                  { [styles.unavailable]: !isAvailable }
                )}
                src={image?.thumbnail}
                square
                width={400}
                height={400}
                alt={name}
              />
            )
          }

          {(!isArrayEmpty(tags) || !isArrayEmpty(secondaryLabels) || hasBanner || hasInfoLabels) && (
            <div className={styles.optionalCardDecorations}>

              <div className={styles.optionalCardDecorationsInner}>

                {/* Tags */}
                <ProductTags
                  className={classnames(
                    styles.tags,
                    { [styles.wrapped]: shouldWrapTags }
                  )}
                  tags={tags}
                />

              </div>
              {/* Secondary Labels */}
              {
                secondaryLabels?.length && (
                  <ProductLabels
                    className={styles.secondaryLabels}
                    labelClassName={styles.secondaryLabel}
                    labels={secondaryLabels}
                    size="small"
                    responsiveSize="small"
                  />
                )
              }

              {/* InfoLabels */}
              {
                hasInfoLabels && (
                  <ProductLabels
                    className={styles.infoLabels}
                    labelClassName={styles.infoLabel}
                    labels={infoLabels}
                    size="small"
                    responsiveSize="small"
                  />
                )
              }

              {/* Banner */}
              {
                hasBanner && (
                  <div className={styles.banner}>
                    <ProductBanner
                      full
                      tall={withTallBanner}
                      banner={banner}
                      productSlug={slug}
                      onClick={onClick}
                    />
                  </div>
                )
              }
            </div>
          )}

          {/* Badge */}
          <ProductBadge
            className={styles.badge}
            badge={badge}
          />

          {/* Loading Overlay */}
          <LoadingOverlay
            isLoading={isLoading}
            withLoader
          />

        </div>

        {/* Content */}
        <div className={styles.content}>

          {/* Brand */}
          <div className={classnames(styles.brand, brandClassName)}>
            {brand}
          </div>

          {/* Name */}
          <div className={styles.name}>
            <h2 className={classnames(styles.nameText, nameTextClassName)}>{name}</h2>
          </div>

          {/* Price */}
          <div className={styles.priceContainer}>
            <ProductPrice
              //
              className={styles.productPrice}
              originalPriceClassName={classnames(
                styles.originalPrice,
                { [styles.withStrikethrough]: originalPriceStrikethrough }
              )}
              priceClassName={styles.currentPrice}
              unitPriceLabelClassName={styles.unitPriceLabel}
              taxesClassName={styles.taxes}
              //
              fixedHeight
              //
              currency={currency}
              price={price}
              originalPrice={originalPrice}
              withOriginalPriceStrikethrough={originalPriceStrikethrough}
              //
              taxes={taxes}
              //
              unitPriceLabel={unitPriceLabel}
              withUnitPriceLabel
              //
              color={priceColor}
              icon={hasGeniusPrice ? undefined : priceIcon}
              tooltip={priceTooltip}
            />
          </div>

          {/* Footer */}
          <div className={styles.footer}>
            {
              !isAvailable
                ? (
                  <>
                    {/* View similar products */}
                    <ProductSimilarProductsButton
                      className={styles.viewSimilarBtn}
                      size="small"
                      onClick={onSimilarClick}
                    />
                  </>
                ) : (
                  <>
                    {/* Actions */}
                    <ProductDefaultCardActions
                      className={styles.actions}
                      //
                      order={order}
                      product={product}
                      variant={variant}
                      listProps={listProps}
                      //
                      quantity={quantity}
                      bypassAddressRequirement={bypassAddressRequirement}
                      bypassQuantityVariants={bypassQuantityVariants}
                      replaceProductVariantCode={replaceProductVariantCode}
                      //
                      isOrderLoading={isOrderLoading}
                      setLoading={setLoading}
                      //
                      onQuantityChange={onQuantityChange}
                    />
                  </>
                )
            }
          </div>

          {/* Loading Overlay */}
          <LoadingOverlay
            isLoading={isLoading}
          />

        </div>
      </a>
    </ProductLink>
  );
};

// Components

interface LoadingOverlayProps {
  isLoading: boolean,
  withLoader?: boolean
}

const LoadingOverlay = (props: LoadingOverlayProps) => {
  const {
    isLoading,
    withLoader
  } = props;

  return (
    <Fade
      className={styles.loadingOverlay}
      isVisible={isLoading}
      lazyLoad
      enterDuration={300}
      exitDuration={300}
    >
      {
        withLoader && (
          <Loader
            size={24}
            lineWeight={2}
          />
        )
      }
    </Fade>
  );
};

// Export

export default React.memo(ProductDefaultCard);
