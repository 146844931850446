import React from 'react';
import { useRouter } from 'next/router';

import { isValidNavigationStep } from 'helpers/UrlHelpers';

const storeContext = React.createContext();
const dispatchContext = React.createContext();

const initialState = {
  //
  slug: null,
  //
  navigationUrl: null,
  navigationStepCounter: 0,
  navigationStepCounterEnabled: false,
  //
  temporaryQueryParams: null,
  queryParams: null,
};

const reducer = (draft, action) => {
  switch (action.type) {

    // Slug

    case ACTIONS.SET_SLUG:
      return {
        ...draft,
        slug: action.payload
      };

      // Navigation Step Counter

    case ACTIONS.INCREMENT_NAVIGATION_STEP_COUNTER:
      return {
        ...draft,
        navigationUrl: action?.payload?.url,
        navigationStepCounter: action?.payload?.url !== draft.navigationUrl
          ? draft.navigationStepCounter + 1
          : draft.navigationStepCounter
      };

    case ACTIONS.START_NAVIGATION_STEP_COUNTER:
      return {
        ...draft,
        navigationStepCounterEnabled: true
      };

    case ACTIONS.STOP_NAVIGATION_STEP_COUNTER:
      return {
        ...draft,
        navigationStepCounterEnabled: false
      };

    case ACTIONS.RESET_NAVIGATION_STEP_COUNTER:
      return {
        ...draft,
        navigationUrl: null,
        navigationStepCounter: 0
      };

      // Query Params

    case ACTIONS.SET_TEMPORARY_QUERY_PARAMS: {
      return {
        ...draft,
        temporaryQueryParams: action?.payload?.temporaryQueryParams || null,
        queryParams: action?.payload?.queryParams || null,
      };
    }

    case ACTIONS.SET_QUERY_PARAMS: {
      return {
        ...draft,
        queryParams: draft?.temporaryQueryParams || null,
      };
    }

    case ACTIONS.CLEAR_TEMPORARY_QUERY_PARAMS: {
      return {
        ...draft,
        temporaryQueryParams: null,
      };
    }

    // Default

    default:
      return draft;
  }
};

const NavigationProvider = ({ children }) => {

  const router = useRouter();

  const [store, dispatch] = React.useReducer(reducer, initialState);

  const onNavigationStart = () => {
    dispatch({
      type: ACTIONS.SET_QUERY_PARAMS,
    });
  };

  const onNavigationError = () => {
  };

  const onNavigationComplete = React.useCallback((url) => {
    if (store?.navigationStepCounterEnabled && isValidNavigationStep(url)) {
      dispatch({
        type: ACTIONS.INCREMENT_NAVIGATION_STEP_COUNTER,
        payload: { url }
      });
    }

    dispatch({
      type: ACTIONS.CLEAR_TEMPORARY_QUERY_PARAMS,
    });
  }, [store?.navigationStepCounterEnabled]);

  React.useEffect(() => {
    router.events.on('routeChangeStart', onNavigationStart);
    router.events.on('routeChangeError', onNavigationError);
    router.events.on('routeChangeComplete', onNavigationComplete);

    return () => {
      router.events.off('routeChangeStart', onNavigationStart);
      router.events.off('routeChangeError', onNavigationError);
      router.events.off('routeChangeComplete', onNavigationComplete);
    };
  }, [
    router.events,
    store?.temporaryQueryParams,
    store?.queryParams,
    store?.navigationStepCounterEnabled,
    onNavigationComplete
  ]);

  return (
    <dispatchContext.Provider value={dispatch}>
      <storeContext.Provider value={store}>
        {children}
      </storeContext.Provider>
    </dispatchContext.Provider>
  );
};

const ACTIONS = {
  SET_SLUG: 'SET_SLUG',
  INCREMENT_NAVIGATION_STEP_COUNTER: 'INCREMENT_NAVIGATION_STEP_COUNTER',
  START_NAVIGATION_STEP_COUNTER: 'START_NAVIGATION_STEP_COUNTER',
  STOP_NAVIGATION_STEP_COUNTER: 'STOP_NAVIGATION_STEP_COUNTER',
  RESET_NAVIGATION_STEP_COUNTER: 'RESET_NAVIGATION_STEP_COUNTER',
  SET_TEMPORARY_QUERY_PARAMS: 'SET_TEMPORARY_QUERY_PARAMS',
  CLEAR_TEMPORARY_QUERY_PARAMS: 'CLEAR_TEMPORARY_QUERY_PARAMS',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING'
};

function useNavigationStore() {
  return React.useContext(storeContext);
}

function useNavigationDispatch() {
  return React.useContext(dispatchContext);
}

function useNavigationDispatchHelpers() {
  const dispatch = useNavigationDispatch();

  const setSlug = (slug) => {
    dispatch({ type: ACTIONS.SET_SLUG, payload: slug });
  };

  const startGlobalNavigationStepCounter = () => {
    dispatch({ type: ACTIONS.START_NAVIGATION_STEP_COUNTER });
  };

  const stopGlobalNavigationStepCounter = () => {
    dispatch({ type: ACTIONS.STOP_NAVIGATION_STEP_COUNTER });
  };

  const resetGlobalNavigationStepCounter = () => {
    dispatch({ type: ACTIONS.RESET_NAVIGATION_STEP_COUNTER });
  };

  const setQueryParams = (queryParams) => {
    dispatch({
      type: ACTIONS.SET_TEMPORARY_QUERY_PARAMS,
      payload: {
        temporaryQueryParams: queryParams
      }
    });
  };

  return {
    setSlug,
    startGlobalNavigationStepCounter,
    stopGlobalNavigationStepCounter,
    resetGlobalNavigationStepCounter,
    setQueryParams
  };
}

export {
  NavigationProvider,
  useNavigationStore,
  useNavigationDispatch,
  useNavigationDispatchHelpers
};
