// TODO move to constants

export const MODAL_TYPES = {
  SIMILAR_PRODUCTS: 'similarProducts',
  PRODUCT_VARIANTS: 'productVariants',
  AVAILABLE_PROMOTIONS: 'availablePromotions',
  RECOMMENDATIONS: 'recommendations',
  GENIUS_FAST_CHECKOUT: 'geniusFastCheckout',
  ORDER_GIFTS: 'orderGifts',
  PHONE_VERIFICATION: 'phoneVerification',
  GENIUS_TRIAL: 'geniusTrial',
  ACCOUNT_BENEFITS: 'accountBenefits',
} as const;

export const DRAWER_TYPES = {
  MENU_MOBILE: 'MENU_MOBILE',
  ACCOUNT_MENU_MOBILE: 'account_menu_mobile',
  CART: 'cart',
} as const;
