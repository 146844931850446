import ky from 'ky';

export function logError(message: string, payload?: any) {
  try {
    ky.post('/api/error', {
      json: { message, payload },
    }).catch((error) => {
      console.error('Failed to log error:', error);
    });
  } catch (error) {
    console.error('Caught error:', error);
  }
}

export function logInfo(message: string, payload?: any) {
  try {
    ky.post('/api/error', {
      json: { message, payload, type: 'info' },
    }).catch((error) => {
      console.error('Failed to log info:', error);
    });
  } catch (error) {
    console.error('Caught error:', error);
  }
}
