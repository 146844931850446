import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { ResponsiveImage } from 'components/ui';

import RecipeLabel from '../RecipeLabel/RecipeLabel';

import type {
  IRecipeLabel,
  IStrapiResponsiveImage,
} from 'types';

import styles from './RecipePageBanner.module.scss';

interface Props {
  className?: string,
  //
  banner: IStrapiResponsiveImage,
  label?: IRecipeLabel,
  //
  rounded?: boolean,
  fill?: boolean
}

const RecipePageBanner = (props: Props) => {

  const {
    className,
    //
    banner,
    label,
    //
    rounded,
    fill,
  } = props;

  const {
    desktop,
    mobile
  } = banner || {};

  // Empty

  if (!banner || !desktop || !mobile) return null;

  // Render

  return (
    <>

      {/* Root */}
      <div
        className={classnames(
          styles.root,
          { [styles.rounded]: rounded },
          className
        )}
      >
        <div
          className={classnames(
            styles.banner,
            { [styles.fill]: fill },
          )}
          style={{
            '--desktopWidth': `${desktop?.width}px`,
            '--desktopHeight': `${desktop?.height}px`,
            '--responsiveWidth': `${mobile?.width}px`,
            '--responsiveHeight': `${mobile?.height}px`,
          } as CSSProperties}
        >

          {/* Image */}
          <ResponsiveImage
            desktopWrapperClassName={styles.desktopWrapper}
            responsiveWrapperClassName={styles.responsiveWrapper}
            desktopProps={
              fill ? {
                src: desktop?.src,
                fill: true,
                style: {
                  objectFit: 'cover',
                },
              } : {
                style: {
                  objectFit: 'cover',
                },
                ...desktop
              }
            }
            responsiveProps={
              fill ? {
                src: mobile?.src,
                fill: true,
                style: {
                  objectFit: 'cover',
                }
              } : {
                style: {
                  objectFit: 'cover',
                },
                ...mobile
              }
            }
          />

          {/* Label */}
          {!!label && (
            <RecipeLabel
              className={styles.label}
              label={label}
            />
          )}

        </div>
      </div>
    </>
  );
};

export default RecipePageBanner;
