import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { getRefreshToken } from 'helpers/AuthenticationHelpers';
import { getUser } from 'services/AccountService';

import type { IUser, ReactQueryOptions } from 'types';

interface Options {
  isOptional?: boolean
}

// TODO: fix this
// Important TS does not properly infer the type for this
// as it does not see data as possibly null

export const useUser = (options?: Options, queryOptions?: ReactQueryOptions<IUser | null>) => {
  const {
    isOptional = false,
  } = options || {};

  const {
    data: user,
    ...rest
  } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: () => {
      // If the call is optional and refresh token does not exist
      // we can return null from here, without having to make the api call
      if (isOptional && !getRefreshToken()) return null;
      return getUser(isOptional);
    },
    ...(queryOptions || {}),
    staleTime: 10000
  });

  return {
    data: user,
    ...rest,
    isUserSettled: typeof user !== 'undefined',
    isAuthenticatedUser: !!user,
    isUnauthenticatedUser: user === null,
  };
};
