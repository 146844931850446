import React from 'react';
import NextImage from 'next/legacy/image';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';

import { ProductLabels } from 'components/product/parts';
import { Carousel, Image } from 'components/ui';
import { IMAGES } from 'constants/images';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useBreakpoint } from 'hooks/useBreakpoint';

import ProductGalleryThumbnails from './ProductGalleryThumbnails';
import ProductGalleryZoom from './ProductGalleryZoom';

import type { CarouselApi } from 'components/ui/Carousel';
import type { IProductImage, IProductLabel } from 'types';

import styles from './ProductGallery.module.scss';

// Constants

const IMAGE_SIZE = 590;
const PANNING_SENSITIVITY = 0.5;
const ZOOM_LEVELS = [
  { minRatio: 1, maxRatio: 1 },
  { minRatio: 3, maxRatio: 3 },
  { minRatio: 7, maxRatio: 7 },
];

// Types

interface Props {
  className?: string,
  //
  productName: string,
  images: IProductImage[],
  infoLabels?: IProductLabel[],
  //
  canZoom?: boolean,
  isAvailable?: boolean,
  isQuickView?: boolean,
  isImageMaximized?: boolean,
  shouldOffsetBullets?: boolean,
  //
  setImageMaximized: (isMaximized: boolean) => void,
  //
  children: React.ReactNode
}

const ProductGallery = (props: Props) => {
  const {
    className,
    //
    productName,
    images = [],
    infoLabels = [],
    //
    canZoom,
    isAvailable,
    isQuickView,
    isImageMaximized,
    shouldOffsetBullets,
    //
    setImageMaximized = () => {},
    //
    children,
  } = props;

  const hasInfoLabels = React.useMemo(() => !isArrayEmpty(infoLabels) && infoLabels, [infoLabels]);

  // Refs

  const zoomContainerRefs = React.useRef<HTMLDivElement[]>([]);

  // Hooks

  const isBreakpoint = useBreakpoint('sm', 'down');

  // States
  // Carousel

  const [galleryCarouselApi, setGalleryCarouselApi] = React.useState<CarouselApi>();
  const [activeIndex, setActiveIndex] = React.useState(0);

  // Zoom

  const [zoomLevelIndex, setZoomLevelIndex] = React.useState(0);
  const canZoomIn = zoomLevelIndex < ZOOM_LEVELS.length - 1;
  const canZoomOut = zoomLevelIndex > 0;

  // Panning

  const [panOffset, setPanOffset] = React.useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = React.useState(false);
  const [initialPanPosition, setInitialPanPosition] = React.useState({ x: 0, y: 0 });
  const shouldPan = (!isMobile || !isBreakpoint) && isImageMaximized;

  // Refs

  const currentZoomRef = React.useMemo(() => {
    return zoomContainerRefs.current[activeIndex];
  }, [activeIndex, galleryCarouselApi]);

  // Empty state

  if (isArrayEmpty(images, true)) {
    return (
      <div
        className={classnames(
          styles.root,
          styles.empty,
          className
        )}
      >
        <div className={classnames(styles.imageWrapper, styles.empty)}>
          <Image
            className={styles.image}
            next={false}
            src={null}
          />
        </div>
      </div>
    );
  }

  // Handlers

  const handleSelect = () => {
    if (galleryCarouselApi) {
      setActiveIndex(galleryCarouselApi.selectedScrollSnap());
      setZoomLevelIndex(0);
      if (currentZoomRef) {
        currentZoomRef.style.transform = 'scale(1)';
      }
    }
  };

  const onInit = (api: CarouselApi) => {
    setGalleryCarouselApi(api);
    api.on('select', handleSelect);
  };

  const handleZoomToggle = () => {
    if (isImageMaximized) {
      setZoomLevelIndex(0);
      if (currentZoomRef) {
        currentZoomRef.style.transform = 'scale(1)';
      }
    }

    setImageMaximized(!isImageMaximized);
  };

  // Zooming

  const handleZoomIn = () => {
    resetPan();
    if (canZoomIn) {
      setZoomLevelIndex((prev) => prev + 1);
      if (currentZoomRef) {
        currentZoomRef.style.transition = 'transform 0.6s ease-in';
        currentZoomRef.style.transform = `scale(${ZOOM_LEVELS[zoomLevelIndex + 1].maxRatio})`;
      }
    }
  };

  const handleZoomOut = () => {
    resetPan();
    if (canZoomOut) {
      setZoomLevelIndex(0);
      if (currentZoomRef) {
        currentZoomRef.style.transition = 'transform 0.6s ease-in';
        currentZoomRef.style.transform = 'scale(1)';
      }
    }
  };

  const handleClick = () => {
    if (!isImageMaximized) {
      setImageMaximized(true);
      return;
    }
    if (zoomLevelIndex === 0) handleZoomIn();
  };

  // Panning

  const handleDragStart = (e: React.DragEvent) => {
    e.preventDefault(); // Disable native drag functionality
  };

  const resetPan = () => {
    setPanOffset({ x: 0, y: 0 });
    setIsPanning(false);
    if (currentZoomRef) {
      currentZoomRef.style.transform = 'scale(1) translate(0px, 0px)';
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isImageMaximized && zoomLevelIndex !== 0) {
      setIsPanning(true);
      setInitialPanPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isPanning && currentZoomRef) {
      const deltaX = (e.clientX - initialPanPosition.x) * PANNING_SENSITIVITY;
      const deltaY = (e.clientY - initialPanPosition.y) * PANNING_SENSITIVITY;

      setPanOffset((prev) => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));

      setInitialPanPosition({ x: e.clientX, y: e.clientY });

      currentZoomRef.style.transition = 'transform 0s';
      currentZoomRef.style.transform = `scale(${ZOOM_LEVELS[zoomLevelIndex].maxRatio}) translate(${panOffset.x + deltaX}px, ${panOffset.y + deltaY}px)`;

      if (Math.abs(panOffset.x + deltaX) > 600 || Math.abs(panOffset.y + deltaY) > 350) {
        resetPan();
      }
    }
  };

  // Classes

  const slideClasses = classnames(
    styles.slide,
    { [styles.isImageMaximized]: isImageMaximized },
    { [styles.firstLevelZoom]: zoomLevelIndex === 0 },
    { [styles.canPan]: isImageMaximized && zoomLevelIndex !== 0 },
    { [styles.panning]: isImageMaximized && isPanning }
  );

  // Render

  return (
    <>
      <div
        className={classnames(
          styles.root,
          className
        )}
      >

        {/* InfoLabels */}
        {
          hasInfoLabels && (
            <ProductLabels
              className={styles.infoLabels}
              labelClassName={styles.infoLabel}
              labels={infoLabels}
              size="small"
              responsiveSize="small"
            />
          )
        }

        {/* Product Images */}
        <Carousel
          className={classnames(
            styles.carousel,
            { [styles.maximized]: isImageMaximized }
          )}
          slideClassName={styles.carouselSlide}
          slidesWrapperClassName={classnames(
            styles.slidesWrapper,
            { [styles.maximized]: isImageMaximized }
          )}
          paginationClassName={classnames({ [styles.pagination]: shouldOffsetBullets || isBreakpoint })}
          prevClassName={styles.carouselPrev}
          nextClassName={styles.carouselNext}
          //
          onInit={onInit}
          //
          navigation
          pagination
          drag={zoomLevelIndex === 0}
        >
          {
            images?.map((image, index) => (
              <div
                key={index}
                className={slideClasses}
                onMouseUp={shouldPan ? handleMouseUp : undefined}
                onMouseDown={shouldPan ? handleMouseDown : undefined}
                onMouseMove={shouldPan ? handleMouseMove : undefined}
                onClick={isBreakpoint ? undefined : handleClick}
                onDragStart={handleDragStart}
                ref={(el) => (zoomContainerRefs.current[index] = el)}
              >
                <ProductImage
                  key={index}
                  name={productName}
                  image={image}
                  isAvailable={isAvailable}
                  isQuickView={isQuickView}
                  isImageMaximized={isImageMaximized}
                />
              </div>

            ))
          }
        </Carousel>

        {/* Zoom controls */}
        <div className={styles.zoomControls}>
          {
            (canZoom || isImageMaximized) && (
              <ProductGalleryZoom
                isImageMaximized={isImageMaximized}
                canZoomIn={canZoomIn}
                canZoomOut={canZoomOut}
                //
                onZoomToggle={handleZoomToggle}
                onZoomIn={handleZoomIn}
                onZoomOut={handleZoomOut}
              />
            )
          }
        </div>

        {/* Children */}
        <div className={styles.children}>
          {children}
        </div>

      </div>

      {/* Image thumbnails */}
      <ProductGalleryThumbnails
        className={styles.thumbnails}
        productName={productName}
        images={images}
        carouselApi={galleryCarouselApi}
        activeIndex={activeIndex}
      />
    </>
  );
};

// --- Image

interface ProductImageProps {
  isAvailable: boolean,
  isImageMaximized: boolean,
  isQuickView: boolean,
  //
  image: IProductImage,
  name: string
}

const ProductImage = (props: ProductImageProps) => {
  const {
    isAvailable,
    isImageMaximized,
    isQuickView,
    //
    image,
    name,
  } = props;

  // Props

  const src = getImage(image, isImageMaximized);

  const imageClasses = classnames(
    styles.image,
    { [styles.unavailable]: !isAvailable }
  );

  if (isImageMaximized) {
    return (
      <img
        className={imageClasses}
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
        src={src}
        alt={name}
      />
    );
  }

  return (
    <div
      className={classnames(
        styles.imageWrapper,
      )}
    >
      <div>
        <NextImage
          className={classnames(
            styles.image,
            { [styles.unavailable]: !isAvailable }
          )}
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          src={src}
          alt={name}
          unoptimized={isQuickView}
          priority={!isQuickView}
          quality={100}
        />
      </div>
    </div>
  );
};

// Export

export default ProductGallery;

// Helpers

const getImage = (image: IProductImage, isImageMaximized: boolean): string => {
  if (isImageMaximized) {
    return (
      image?.extralarge?.default
      || image?.large?.default
      || image?.thumbnail?.default
      || IMAGES.PLACEHOLDERS.GENERIC
    );
  }
  return (
    image?.large?.default
    || image?.thumbnail?.default
    || IMAGES.PLACEHOLDERS.GENERIC
  );
};
