import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';
import type { Nullable } from 'types';

export interface IBlogPageProps {
  slug: string,
  title: string
}

interface BlogStoreState {
  blogPageProps: Nullable<IBlogPageProps>
}

const initialState: BlogStoreState = {
  blogPageProps: null
};

const reducers = {
  setBlogPageProps: (draft: BlogStoreState, action: IStoreAction) => {
    const { payload } = action;
    return {
      ...draft,
      blogPageProps: payload || null
    };
  }
};

type Actions = IStoreActions<typeof reducers>;

const [
  BlogProvider,
  useBlogStore,
  useDispatch,
  actions
] = createV2<BlogStoreState, Actions>(reducers, initialState);

function useBlogDispatch() {

  const dispatch = useDispatch();

  const setBlogPageProps = (props: IBlogPageProps) => dispatch(actions.setBlogPageProps(props));
  const resetBlogPageProps = () => dispatch(actions.setBlogPageProps(null));

  return {
    setBlogPageProps,
    resetBlogPageProps
  };
}

export {
  BlogProvider,
  useBlogStore,
  useBlogDispatch,
};
