import React from 'react';
import classnames from 'classnames';

import { ProductsCarousel } from 'components/product';

import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useRecipeIngredientsListing } from 'hooks/data/useRecipeIngredientsListing';

import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import type {
  IRecipeIngredientsListing,
  SliderBreakpointParams
} from 'types';

import styles from './RecipeDetailsIngredientsCarousel.module.scss';

interface Props {
  className?: string,
  carouselClassName?: string,
  navigationButtonClassName?: string,
  breakpoints?: Record<number, SliderBreakpointParams>,
  ingredientsListing: IRecipeIngredientsListing
}

const LIMIT = 20;

const RecipeDetailsIngredientsCarousel = (props: Props) => {

  const {
    className,
    carouselClassName,
    navigationButtonClassName,
    //
    breakpoints,
    //
    ingredientsListing,
  } = props;

  // Props

  const {
    slug,
    title,
  } = ingredientsListing || {};

  // Hooks

  const { data } = useRecipeIngredientsListing(slug, LIMIT, {
    enabled: !!slug
  });

  // Data

  const {
    name,
    items: products = [],
    analyticsListId,
    analyticsListName,
  } = data || {};

  // Empty

  if (!slug || isArrayEmpty(products)) return null;

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      title={title || name}
    >
      <ProductsCarousel
        className={styles.carouselContainer}
        carouselClassName={classnames(styles.carousel, carouselClassName)}
        carouselSlidesWrapperClassName={styles.slidesWrapper}
        headerClassName={styles.carouselHeader}
        nextClassName={classnames(styles.navButton, styles.navNext, navigationButtonClassName)}
        prevClassName={classnames(styles.navButton, styles.navPrev, navigationButtonClassName)}
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
        //
        products={products}
        //
        breakpoints={breakpoints}
      />
    </RecipeDetailsSection>
  );
};

export default RecipeDetailsIngredientsCarousel;
