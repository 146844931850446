import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './NavigationLink.module.scss';

const NavigationLinkSkeleton = (props) => {

  const { className, withImage } = props;

  const classes = classnames(
    styles.root,
    className
  );

  return (
    <div className={classes}>
      {
        withImage && (
          <span className={styles.image}>
            <Skeleton containerClassName={styles.skeleton} height={24} />
          </span>
        )
      }
      <span className={styles.label}>
        <Skeleton containerClassName={styles.skeleton} height={21} />
      </span>
    </div>
  );
};

NavigationLinkSkeleton.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withImage: PropTypes.bool,
};

export default NavigationLinkSkeleton;
