import React from 'react';

import {
  Button, Error, ImageMessage, Loader, Radio, Textarea
} from 'components/ui';
import Modal from 'components/ui/Modal/Modal';
import { IMAGES } from 'constants/images';
import { useTranslation } from 'hooks/common/useTranslation';
import { useOrderCancellationReasons } from 'hooks/data/useOrderCancellationReasons';
import { useOrderMutation } from 'hooks/data/useOrderMutation';

import type { IOrder, IOrderCancellationReason } from 'types';

import styles from './OrderCancelModal.module.scss';

interface Props {
  isOpen: boolean,
  order: IOrder,
  handleClose: () => void,
  onSubmitSuccess: () => void
}

const OrderCancelModal = (props: Props) => {
  const {
    isOpen,
    order,
    handleClose = () => {},
    onSubmitSuccess = () => {}
  } = props;

  // Hooks

  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = React.useState<string>('');

  const [isSubmitSuccess, setSubmitSuccess] = React.useState(false);
  const [isSubmitError, setSubmitError] = React.useState(false);

  // Data Hooks

  const {
    isLoading, isError, isSuccess, data: reasons = []
  } = useOrderCancellationReasons({ enabled: !!isOpen });

  // Mutation Hooks

  const { cancel, isLoading: isMutationLoading } = useOrderMutation();

  // Handlers

  const handleSubmit = () => {
    cancel({
      tokenValue: order?.tokenValue,
      reason: selectedReason,
      onSuccess: () => {
        setSubmitError(false);
        setSubmitSuccess(true);
        onSubmitSuccess();
      },
      onError: () => {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    });
  };

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      innerClassName={styles.inner}
      //
      title={(isSubmitSuccess || isSubmitError)
        ? undefined
        : t('account:ORDER_HISTORY.CANCEL_ORDER_TITLE')}
      //
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
      }}
      //
      close={handleClose}
    >

      {/* Default State */}
      {
        (!isSubmitSuccess && !isSubmitError) && (
          <>
            {/* Description */}
            <div className={styles.description}>
              {t('account:ORDER_HISTORY.CANCEL_ORDER_DESCRIPTION')}
            </div>

            {/* Content */}
            <div className={styles.content}>

              {/* Loading */}
              {
                isLoading && (
                  <div className={styles.loadingContainer}>
                    <Loader
                      size={24}
                      lineWeight={2}
                    />
                  </div>
                )
              }

              {/* Error */}
              {
                isError && (
                  <Error />
                )
              }

              {/* Form */}
              {
                isSuccess && (
                  <OrderCancelForm
                    isModalOpen={isOpen}
                    reasons={reasons}
                    loading={isMutationLoading}
                    onReasonChange={(reason) => {
                      setSelectedReason(reason);
                    }}
                    onSubmit={() => {
                      handleSubmit();
                    }}
                  />
                )
              }
            </div>
          </>
        )
      }

      {/* Submit Success */}
      {
        isSubmitSuccess && (
          <OrderCancelSuccess
            onActionClick={handleClose}
          />
        )
      }

      {/* Submit Error */}
      {
        isSubmitError && (
          <OrderCancelError
            loading={isMutationLoading}
            onActionClick={handleSubmit}
          />
        )
      }
    </Modal>
  );
};

// Components

// --- Form

interface OrderCancelFormProps {
  isModalOpen: boolean,
  reasons: IOrderCancellationReason[],
  loading: boolean,
  onReasonChange: (text: string) => void,
  onSubmit: () => void
}

const OrderCancelForm = (props: OrderCancelFormProps) => {
  const {
    isModalOpen,
    reasons,
    loading,
    onReasonChange = () => {},
    onSubmit = () => {},
  } = props;

  // Hooks

  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = React.useState<string>();
  const [selectedReasonIndex, setSelectedReasonIndex] = React.useState<number>();

  const [customReason, setCustomReason] = React.useState<string>();
  const [customReasonError, setCustomReasonError] = React.useState<string>();

  // Handlers

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    const { value } = e.target;

    if (value?.length > 255) {
      setCustomReasonError(t('VALIDATION_ERRORS.MAX_LENGTH', { count: 255 }));
      setSelectedReason(undefined);
    } else {
      setCustomReasonError(undefined);
      setSelectedReason(value);
    }

    setCustomReason(value);
  };

  // Effects

  React.useEffect(() => {
    setSelectedReasonIndex(undefined);
    setSelectedReason(undefined);
  }, [isModalOpen]);

  React.useEffect(() => {
    if (selectedReason) {
      onReasonChange(selectedReason);
    }
  }, [selectedReason]);

  // Props

  const displayReasons = [
    ...reasons,
    { reason: t('account:ORDER_HISTORY.CANCEL_ORDER_REASON') }
  ];

  const isCustomReasonSelected = selectedReasonIndex === displayReasons.length - 1;
  const isCustomReasonInvalid = isCustomReasonSelected && (!customReason || !!customReasonError);

  // Render

  return (
    <div>
      <div className={styles.reasons}>

        {/* Pre-defined Reasons */}
        {
          displayReasons.map(({ reason }, index) => (
            <div className={styles.reasonContainer} key={index}>
              <Radio
                className={styles.reasonRadio}
                labelClassName={styles.reasonLabel}
                name="reasonsList"
                label={reason}
                checked={index === selectedReasonIndex}
                onChange={() => {
                  setSelectedReasonIndex(index);
                  setSelectedReason(reasons[index]?.reason);
                }}
                {...(index === displayReasons.length - 1 ? { dataTestId: 'order-cancel-modal.custom-reason-radio' } : {})}
              />
            </div>
          ))
        }
      </div>

      {/* Custom Reason */}
      {
        isCustomReasonSelected && (
          <>
            <Textarea
              className={styles.reasonTextarea}
              value={customReason}
              error={customReasonError}
              placeholder={t('account:ORDER_HISTORY.CANCEL_ORDER_REASON_PLACEHOLDER')}
              autoFocus
              minRows={4}
              maxRows={10}
              withoutForm
              onChange={handleTextareaChange}
              data-testid="order-cancel-modal.custom-reason-textarea"
            />
          </>
        )
      }

      {/* Actions */}
      <div className={styles.actionsWrapper}>
        <Button
          className={styles.actionButton}
          variant="primary"
          disabled={isCustomReasonInvalid || selectedReasonIndex === undefined || loading}
          loading={loading}
          onClick={onSubmit}
          data-testid="order-cancel-modal.submit-btn"
        >
          {t('account:ORDER_HISTORY.CANCEL_ORDER_CTA')}
        </Button>
      </div>

    </div>
  );
};

// --- Success

interface OrderCancelSuccessProps {
  onActionClick: React.MouseEventHandler<HTMLButtonElement>
}

const OrderCancelSuccess = (props: OrderCancelSuccessProps) => {
  const {
    onActionClick = () => {}
  } = props;

  const { t } = useTranslation();

  return (
    <ImageMessage
      image={IMAGES.COMMON.SUCCESS}
      imageProps={{
        width: 118,
        height: 118,
        alt: 'Success icon'
      }}
      title={t('account:ORDER_HISTORY.CANCEL_ORDER_SUCCESS_TITLE')}
      subtitle={t('account:ORDER_HISTORY.CANCEL_ORDER_SUCCESS_SUBTITLE')}
    >
      <Button
        variant="primary"
        onClick={onActionClick}
      >
        {t('account:ORDER_HISTORY.CANCEL_ORDER_SUCCESS_CTA')}
      </Button>
    </ImageMessage>
  );
};

// --- Error

interface OrderCancelErrorProps {
  loading: boolean,
  onActionClick: React.MouseEventHandler<HTMLButtonElement>
}

const OrderCancelError = (props: OrderCancelErrorProps) => {
  const {
    loading,
    onActionClick = () => {}
  } = props;

  const { t } = useTranslation();

  return (
    <ImageMessage
      image={IMAGES.COMMON.ERROR}
      imageProps={{
        width: 118,
        height: 118,
        alt: 'Warning icon'
      }}
      title={t('account:ORDER_HISTORY.CANCEL_ORDER_ERROR_TITLE')}
      subtitle={t('account:ORDER_HISTORY.CANCEL_ORDER_ERROR_SUBTITLE')}
    >
      <Button
        variant="primary"
        disabled={loading}
        loading={loading}
        onClick={onActionClick}
      >
        {t('account:ORDER_HISTORY.CANCEL_ORDER_ERROR_CTA')}
      </Button>
    </ImageMessage>
  );
};

// Export

export default OrderCancelModal;
