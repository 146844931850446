import React from 'react';
import classnames from 'classnames';

import {
  Alert, Modal, ModalActions, ModalHeader
} from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import OrderSummaryTable from './OrderSummaryTable';

import type { IOrderSummaryLine } from 'types';

import styles from './OrderSummaryRowDetailsModal.module.scss';

interface Props {
  isOpen: boolean,
  //
  row: IOrderSummaryLine,
  //
  onClose: () => void,
  onAfterClose: () => void
}

const OrderSummaryRowDetailsModal = (props: Props) => {
  const {
    isOpen,
    //
    row,
    //
    onClose,
    onAfterClose,
  } = props;

  // Props

  const {
    modal
  } = row || {};

  const {
    title,
    description,
    summary: rows,
    info,
    buttonLabel
  } = modal || {};

  // Handlers

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleAfterClose = () => {
    if (onAfterClose) {
      onAfterClose();
    }
  };

  // Render

  return (
    <Modal
      className={styles.root}
      contentClassName={styles.body}
      //
      isOpen={isOpen}
      //
      shouldReturnFocusAfterClose
      withCloseButton={false}
      withPadding={false}
      //
      desktopProps={{
        position: 'center'
      }}
      responsiveProps={{
        position: 'bottom'
      }}
      //
      close={handleClose}
      onAfterClose={handleAfterClose}
    >

      {/* Header */}
      <ModalHeader
        className={styles.header}
        titleClassName={styles.title}
        title={title}
        size="large"
        desktopProps={{
          withCloseButton: true,
        }}
        responsiveProps={{
          withCloseButton: true,
        }}
        onClose={handleClose}
      />

      {/* Content */}
      <div className={styles.content}>

        {/* Description */}
        {
          description && (
            <p className={styles.description}>
              {description}
            </p>
          )
        }

        {/* Summary */}
        {
          !isArrayEmpty(rows) && (
            <OrderSummaryTable
              //
              className={styles.table}
              rowClassName={styles.row}
              rowValueClassName={styles.value}
              rowKeyClassName={styles.key}
              separatorClassName={styles.separator}
              rowKeyContainerClassName={styles.keyContainer}
              //
              rows={rows}
              //
            />
          )
        }

        {/* Info */}
        {
          info && (
            <Alert
              className={styles.info}
              iconClassName={styles.icon}
              descriptionClassName={styles.text}
              //
              variant="custom"
              icon="info-circle"
              description={info}
            />
          )
        }

      </div>

      {/* Action */}
      {
        buttonLabel && (
          <ModalActions
            className={styles.actions}
            innerClassName={styles.inner}
            primary={{
              className: styles.action,
              text: buttonLabel,
              onClick: handleClose,
            }}
          />
        )
      }

    </Modal>
  );
};

export default OrderSummaryRowDetailsModal;
