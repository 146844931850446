import dynamic from 'next/dynamic';

export { default as GeniusFastCheckoutHost } from './GeniusFastCheckoutHost/GeniusFastCheckoutHost';
export { default as GeniusFastCheckoutBanner } from './GeniusFastCheckoutBanner/GeniusFastCheckoutBanner';

export const EmagGenius = dynamic(() => import('./EmagGenius/EmagGenius'), { ssr: false });
export const GeniusFastCheckoutManager = dynamic(() => import('./GeniusFastCheckoutModalManager/GeniusFastCheckoutModalManager'), { ssr: false });
export const GeniusFastCheckoutModal = dynamic(() => import('./GeniusFastCheckoutModal/GeniusFastCheckoutModal'), { ssr: false });
export const GeniusTrialBanner = dynamic(() => import('./GeniusTrialBanner/GeniusTrialBanner'), { ssr: false });
export const GeniusTrialModal = dynamic(() => import('./GeniusTrialModal/GeniusTrialModal'), { ssr: false });
export const GeniusTrialModalManager = dynamic(() => import('./GeniusTrialModalManager/GeniusTrialModalManager'), { ssr: false });
