import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import { useOrderGifts } from 'hooks/data/useOrderGifts';

import CartGiftsButton from '../CartButton/CartGiftsButton';

import type { IOrder } from 'types';

import styles from './CartFooter.module.scss';

interface Props {
  className?: string,
  order: IOrder
}

const CartFooter = (props: Props) => {

  const {
    className,
    order
  } = props;

  const {
    tokenValue
  } = order || {};

  // Data Hooks

  const { data } = useOrderGifts(tokenValue, { enabled: !!tokenValue });

  // Props

  const {
    categoryTitle, gifts,
  } = data || {};

  const hasGifts = !isArrayEmpty(gifts);

  // Empty

  if (!hasGifts) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <CartGiftsButton
        wrapperClassName={styles.btnWrapper}
        orderTokenValue={tokenValue}
        title={categoryTitle}
        gifts={gifts}
      />
    </div>
  );
};

export default CartFooter;
