import React from 'react';
import classNames from 'classnames';

import { Image } from 'components/ui';
import { CONFIG } from 'constants/config';
import { IMAGES } from 'constants/images';

import styles from './MobileAppButtons.module.scss';

interface Props {
  className?: string
}

const MobileAppButtons = (props: Props) => {
  const { className } = props;

  return (
    <div className={classNames(styles.root, className)}>
      <a
        className={styles.androidStoreButton}
        href={CONFIG.MOBILE_APPS.ANDROID_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src={IMAGES.MOBILE_APP.ANDROID}
          height={40}
          width={135}
          quality="100"
          optimize
        />
      </a>
      <a
        className={styles.iosStoreButton}
        href={CONFIG.MOBILE_APPS.IOS_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src={IMAGES.MOBILE_APP.IOS}
          width={120}
          height={40}
          quality="100"
          optimize
        />
      </a>
    </div>
  );
};

export default MobileAppButtons;
