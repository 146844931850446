import React from 'react';
import classnames from 'classnames';

import { Button, Price } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';
import { useOrderGiftMutation } from 'hooks/data/useOrderGiftMutation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { ProductImage } from '../../parts';

import ProductLink from '../../ProductLink/ProductLink';

import type { IOrder, IProduct } from 'types';

import styles from './ProductGiftCard.module.scss';

export const IMAGE_SIZE = 96;
export const RESPONSIVE_IMAGE_SIZE = 64;

interface Props {
  className?: string,
  //
  order?: IOrder,
  product: IProduct,
  removed: boolean,
  //
  withLink?: boolean,
  withBrand?: boolean,
  withActions?: boolean
}

const ProductGiftCard = (props: Props) => {

  const {
    className,
    //
    order,
    product,
    removed,
    //
    withLink,
    withBrand = true,
    withActions,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Mutation Hooks

  const { isLoading, add, remove } = useOrderGiftMutation();

  // Props

  const {
    tokenValue
  } = order || {};

  const {
    variantCode,
    slug,
    image,
    labels,
    brand,
    name,
    price,
    priceLabel,
    priceLabelColor,
  } = product || {};

  const {
    thumbnail
  } = image || {};

  const imageSize = isBreakpoint
    ? RESPONSIVE_IMAGE_SIZE
    : IMAGE_SIZE;

  // Handlers

  const handleAdd = () => {
    add({
      tokenValue,
      variantCode
    });
  };

  const handleRemove = () => {
    remove({
      tokenValue,
      variantCode
    });
  };

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >

      {/* Image */}
      <ProductLinkWrapper
        enabled={!!withLink}
        slug={slug}
      >
        <ProductImage
          alt={name}
          src={thumbnail}
          size={imageSize}
          labels={labels}
          rounded
          retina
          labelsProps={{
            className: styles.labels,
            breakpoint: 'sm',
            size: 'medium',
            responsiveSize: 'small',
          }}
        />
      </ProductLinkWrapper>

      <div className={styles.content}>

        {/* Details - Name / Brand */}
        <div className={styles.details}>

          {/* Brand */}
          {
            withBrand && (
              <div className={styles.brand}>{brand}</div>
            )
          }

          {/* Name */}
          <ProductLinkWrapper
            enabled={!!withLink}
            slug={slug}
          >
            <div className={styles.name}>{name}</div>
          </ProductLinkWrapper>

        </div>

        {/* Secondary */}
        <div className={classnames(styles.secondary, { [styles.removed]: removed })}>

          {/* Actions */}
          {
            withActions && (
              <div className={classnames(styles.actions, { [styles.removed]: removed })}>
                {
                  removed
                    ? (
                      <Button
                        size={isBreakpoint ? 'small' : 'medium'}
                        variant="subtle"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleAdd}
                      >
                        {t('PRODUCT.ADD_GIFT_TO_CART')}
                      </Button>
                    ) : (
                      <Button
                        size={isBreakpoint ? 'small' : 'medium'}
                        variant="subtle"
                        icon="trash"
                        iconStrokeWidth={2}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleRemove}
                      />
                    )
                }
              </div>
            )
          }

          {/* Price */}
          <Price
            className={styles.price}
            value={price}
            nullable
            nullLabel={priceLabel || t('LABELS.FREE')}
            color={priceLabelColor || styles.colorPrimary}
          />

        </div>
      </div>

    </div>
  );
};

// Components

interface ProductLinkWrapperProps {
  enabled: boolean,
  slug: string,
  children?: React.ReactNode
}

const ProductLinkWrapper = (props: ProductLinkWrapperProps) => {
  const {
    enabled,
    slug,
    children
  } = props;

  if (!enabled) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <ProductLink
      slug={slug}
      modalProps={{
        withoutBreadcrumbs: true,
      }}
      detailsProps={{
        withoutLinks: true,
        withoutActions: true,
        withoutTrustedElements: true,
        withoutSameBrandNavigation: true,
      }}
    >
      <a className={styles.link}>
        {children}
      </a>
    </ProductLink>
  );
};

// Export

export default ProductGiftCard;
