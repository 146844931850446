import React from 'react';
import classnames from 'classnames';

import { ProductAddToCart } from '../../parts';

import type { IOrderProduct } from 'types';

import styles from './ProductCheckoutCardActions.module.scss';

interface Props {
  className?: string,
  //
  orderItem: IOrderProduct,
  //
  shouldReset?: boolean,
  //
  onQuantityChange: (previousQuantity: number, currentQuantity: number) => void,
  onReplace?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductCheckoutCardActions = (props: Props) => {

  const {
    className,
    //
    orderItem,
    //
    shouldReset,
    //
    onQuantityChange,
    onReplace
  } = props;

  // Props

  const {
    isOk,
    quantity,
  } = orderItem || {};

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <ProductAddToCart
        //
        product={orderItem}
        quantity={quantity}
        //
        shouldReset={shouldReset}
        shouldAdjustQuantity={!isOk}
        //
        size="medium"
        isCompact
        //
        onQuantityChange={onQuantityChange}
        onReplace={onReplace}
        //
      />
    </div>
  );
};

export default ProductCheckoutCardActions;
