import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';

// Refresh Token

export const setRefreshToken = (token: string) => {
  LocalStorage.set(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return LocalStorage.get(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

export const deleteRefreshToken = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

// User Details

export const setUserDetails = (customerId: number, accountType: string) => {
  LocalStorage.set(LOCAL_STORAGE_KEYS.USER_DETAILS, { cid: customerId, at: accountType });
};

export const getUserDetails = (): { customerId: number, accountType?: string } => {
  const userDetails = LocalStorage.get(LOCAL_STORAGE_KEYS.USER_DETAILS);
  return {
    customerId: userDetails?.cid,
    accountType: userDetails?.at
  };
};

export const updateUserDetails = (updatedDetails: { customerId?: number, accountType?: string }) => {
  const currentDetails = getUserDetails();

  const { customerId: updatedCustomerId, accountType: updatedAccountType } = updatedDetails || {};
  const { customerId, accountType } = currentDetails || {};

  setUserDetails(
    typeof updatedCustomerId !== 'undefined' ? updatedCustomerId : customerId,
    typeof updatedAccountType !== 'undefined' ? updatedAccountType : accountType,
  );
};

export const deleteUserDetails = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.USER_DETAILS);
};

// Authentication Header

export const getAuthHeader = (accessToken: string) => {
  return `Bearer ${accessToken}`;
};

export const getAccessTokenFromAuthHeader = (header?: string) => {
  if (!header) return '';

  return header.split(' ')[1];
};
