import React from 'react';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as Notifications from 'common/Notification';
import { AddressSearch } from 'components/address';
import { ERROR_CODES } from 'constants/errors';
import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import { useDeliveryInfoDispatchHelpers } from 'context/DeliveryInfoContext';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { getPartialAddress } from 'helpers/AddressHelpers';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { getKey as getActiveOrderKey } from 'hooks/data/useActiveOrder';
import { useAnalytics } from 'hooks/useAnalytics';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import * as OrdersService from 'services/OrdersService';

import type {
  IAnalyticsListProps,
  IOrder,
  IOrderProduct,
  IProduct,
  ReactQueryMutationPayloadCallbacks
} from 'types';

type OnSettled = () => void;
type OnError = () => void;

enum Actions {
  Add,
  BulkAdd,
  Edit,
  Remove,
  SetComment
}

export const useOrderProductMutation = () => {

  // Hooks

  const queryClient = useQueryClient();
  const mutation = useOrderProductMutationInternal();

  const { showModal } = useModalDispatchHelpers();
  const { showDeliveryInfoModal } = useDeliveryInfoDispatchHelpers();

  // Helpers

  const showAddressSearchModal = React.useCallback(
    () => showDeliveryInfoModal(<AddressSearch />),
    [showDeliveryInfoModal]
  );

  const handleMultipleQuantityVariants = ({
    product, listProps, order, onSettled
  }: {
    product?: IProduct | IOrderProduct,
    listProps?: IAnalyticsListProps,
    order: IOrder,
    onSettled: OnSettled
  }) => {
    onSettled();
    showModal(MODAL_TYPES.PRODUCT_VARIANTS, {
      product, listProps, order
    });
  };

  const handleAddressRequired = (onSettled: OnSettled) => {
    onSettled();
    showAddressSearchModal();
  };

  const createNewOrder = async (onError: OnError, onSettled: OnSettled) => {
    try {
      const newOrder = await OrdersService.createOrder();
      queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, newOrder);
      return newOrder;
    } catch (err) {
      console.error('Error while creating a new order', err);
      onError();
      onSettled();
    }
  };

  const handleBuyMoreSaveMore = async (payload: AddPayload | BulkAddPayload) => {
    const {
      order,
      listProps,
      onError = () => {},
      onSettled = () => {}
    } = payload;

    const { product } = (payload as AddPayload);

    if (product?.canAddMultipleType === 'quantity') return;

    if (order.isNotCreated) {
      const newOrder = await createNewOrder(onError, onSettled);
      if (newOrder) {
        return handleMultipleQuantityVariants({
          product, listProps, order: newOrder, onSettled
        });
      }
    } else {
      return handleMultipleQuantityVariants({
        product, listProps, order, onSettled
      });
    }
  };

  const handleAdd = async ({ type, payload }: { type: Actions.Add | Actions.BulkAdd, payload: AddPayload | BulkAddPayload }) => {
    const {
      bypassAddressRequirement,
      bypassQuantityVariants,
      order,
      listProps,
      onError = () => {},
      onSettled = () => {}
    } = payload;

    const { product } = (payload as AddPayload);
    const partialAddress = getPartialAddress();

    const shouldShowAddressSearchModal = !bypassAddressRequirement && !partialAddress;
    const shouldShowQuantityVariantsModal = !bypassQuantityVariants && !!product?.canAddMultipleType;

    if (order.isNotCreated) {
      const newOrder = await createNewOrder(onError, onSettled);
      if (newOrder) {
        if (shouldShowQuantityVariantsModal) {
          return handleMultipleQuantityVariants({
            product, listProps, order: newOrder, onSettled
          });
        }
        if (shouldShowAddressSearchModal) {
          handleAddressRequired(onSettled);
        }
        return mutation.mutate({
          type,
          payload: { ...payload, order: newOrder }
        });
      }
    } else {
      if (shouldShowQuantityVariantsModal) {
        return handleMultipleQuantityVariants({
          product, listProps, order, onSettled
        });
      }
      if (shouldShowAddressSearchModal && !OrderHelpers.hasAddressSet(order)) {
        handleAddressRequired(onSettled);
      }
      return mutation.mutate({ payload, type });
    }
  };

  //

  return {
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    //
    add: async (payload: AddPayload) => {
      await handleAdd({ type: Actions.Add, payload });
    },
    buyMore: async (payload: AddPayload) => {
      await handleBuyMoreSaveMore(payload);
    },
    bulkAdd: async (payload: BulkAddPayload) => {
      await handleAdd({ type: Actions.BulkAdd, payload });
    },
    edit: (payload: EditPayload) => {
      mutation.mutate({ type: Actions.Edit, payload });
    },
    remove: (payload: RemovePayload) => {
      mutation.mutate({ type: Actions.Remove, payload });
    },
    setComment: (payload: SetCommentPayload) => {
      mutation.mutate({ type: Actions.SetComment, payload });
    },
  };
};

const useOrderProductMutationInternal = () => {

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { accessToken } = useIsAuthenticated();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ORDER_PRODUCT],
    mutationFn: (args: { type: Actions, payload: MutationPayload }) => {
      const { type, payload = {} } = args;
      switch (type) {

        // Add

        case Actions.Add: {
          const {
            order,
            product,
            orderItem,
            replaceProductVariantCode,
            quantity,
            listProps
          } = payload as AddPayload;
          return OrdersService.addProduct(order, product || orderItem, replaceProductVariantCode, quantity, listProps);
        }

        // Bulk Add

        case Actions.BulkAdd: {
          const {
            order,
            products,
            listProps
          } = payload as BulkAddPayload;
          return OrdersService.bulkAddProducts(order, products, listProps);
        }

        // Edit

        case Actions.Edit: {
          const {
            order,
            orderItem,
            quantity
          } = payload as EditPayload;
          return OrdersService.editProduct(order, orderItem?.id, quantity);
        }

        // Remove

        case Actions.Remove: {
          const {
            order,
            orderItem
          } = payload as RemovePayload;
          return OrdersService.removeProduct(order, orderItem?.id);
        }

        // Set Comment

        case Actions.SetComment: {
          const {
            order,
            orderItem,
            comment
          } = payload as SetCommentPayload;
          return OrdersService.setProductComment(order, orderItem?.id, comment);
        }

        default:
          console.error('Unknown mutation type', type);
          return Promise.resolve({});
      }
    },
    retry: (failureCount, error) => (
      (error as any)?.response?.status === ERROR_CODES.SERVER_ERROR
      && failureCount < 1
    ),
    onMutate: async (args) => {
      const { payload, type } = args;

      await queryClient.cancelQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
      const previousState = queryClient.getQueryData(getActiveOrderKey({ isAuthenticatedOrder: !!accessToken }));

      switch (type) {

        // Add

        case Actions.Add: {
          const {
            product,
            orderItem,
            quantity,
            replaceProductVariantCode
          } = payload as AddPayload;

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: [
              createNewItem(product || orderItem, quantity),
              ...(
                replaceProductVariantCode
                  ? order?.items.filter((item) => item.variantCode !== replaceProductVariantCode)
                  : order?.items
              ) || []
            ]
          }));
          break;
        }

        // Bulk Add

        case Actions.BulkAdd: {
          const {
            products = []
          } = payload as BulkAddPayload;

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => {
            const { items = [] } = order || {};

            const itemsMap = items.reduce((acc: { [slug: string]: IOrderProduct }, item) => {
              const { productSlug } = item;
              acc[productSlug] = item;
              return acc;
            }, {});

            const newItems = products.map((p) => {
              const { product } = p || {};
              const { slug } = product || {};
              if (itemsMap[slug]) return null;
              return createNewItem(product);
            }).filter((i) => !!i);

            return {
              ...order,
              items: [
                ...newItems,
                ...items
              ]
            };
          });

          break;
        }

        // Edit

        case Actions.Edit: {
          const {
            orderItem,
            quantity
          } = payload as EditPayload;

          const {
            id: orderItemId
          } = orderItem;

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order.items.map((item) => (
              item.id === orderItemId
                ? { ...item, quantity, isOk: true }
                : item
            ))
          }));
          break;
        }

        // Remove

        case Actions.Remove: {
          const {
            orderItem,
          } = payload as RemovePayload;

          const {
            id: orderItemId
          } = orderItem;

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items?.filter((item) => item.id !== orderItemId)
          }));
          break;
        }

        // Set Comment

        case Actions.SetComment: {
          const {
            orderItem,
            comment
          } = payload as SetCommentPayload;

          const {
            id: orderItemId
          } = orderItem;

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order.items.map((item) => (
              item.id === orderItemId
                ? { ...item, comment }
                : item
            ))
          }));
          break;
        }

        // Default

        default:
          console.error('Unknown mutation type (on mutate)', type);
      }

      if (payload.onMutate) {
        payload.onMutate();
      }

      return { previousState };
    },
    onSuccess: (data, args) => {
      const { payload, type } = args;

      switch (type) {

        // Add

        case Actions.Add: {
          const {
            product,
            orderItem,
            listProps,
            quantity = 1
          } = payload as AddPayload;

          const addedOrderItem = orderItem || (data as IOrder)?.items?.find((item) => item?.productSlug === product.slug);
          const { id: orderItemId, productSlug } = addedOrderItem || {};

          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ADD_TO_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items.map((item) => (
              item.productSlug === productSlug
                ? {
                  ...item, quantity, id: orderItemId, isLoading: !product
                }
                : item
            ))
          }));

          analytics.addToCart(
            product,
            { id: listProps?.analyticsListId, name: listProps?.analyticsListName },
            quantity
          );

          if (product?.sponsored) {
            analytics.addSponsoredProduct(
              product?.sku,
              product?.sponsored?.position,
              product?.sponsored?.billed,
              listProps
            );
          }

          break;
        }

        // Bulk Add

        case Actions.BulkAdd: {
          const {
            products = []
          } = payload as BulkAddPayload;

          const productsMap = products.reduce((acc: { [slug: string]: { quantity: number, product: IProduct }}, p) => {
            const { quantity: productQuantity, product } = p || {};
            const { slug } = product || {};
            acc[slug] = {
              quantity: productQuantity,
              product,
            };
            return acc;
          }, {});

          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items.map((item) => {
              const { productSlug } = item;
              const product = productsMap[productSlug];
              if (product) {
                return {
                  ...item,
                  quantity: item.quantity + product.quantity,
                };
              }
              return item;
            })
          }));

          break;
        }

        // Edit

        case Actions.Edit: {
          const {
            product,
            listProps,
            orderItem,
            quantity,
            previousQuantity
          } = payload as EditPayload;

          const { id } = orderItem;
          const updatedOrderItem = (data as IOrder)?.items?.find((item) => item.id === id);

          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ADD_TO_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });
          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items.map((item) => (
              item.id === updatedOrderItem?.id
                ? { ...item, subtotal: updatedOrderItem?.subtotal }
                : item
            ))
          }));

          if (previousQuantity < quantity) {
            analytics.addToCart(
              product || orderItem,
              { id: listProps?.analyticsListId, name: listProps?.analyticsListName },
              quantity - previousQuantity
            );
          } else {
            analytics.removeFromCart(
              product || orderItem,
              { id: listProps?.analyticsListId, name: listProps?.analyticsListName },
              previousQuantity - quantity
            );
          }

          break;
        }

        // Remove

        case Actions.Remove: {
          const {
            product,
            listProps,
            orderItem
          } = payload as RemovePayload;

          const { quantity } = orderItem;

          queryClient.removeQueries({ queryKey: [QUERY_KEYS.ADD_TO_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_LOYALTY] });

          analytics.removeFromCart(
            product || orderItem,
            { id: listProps?.analyticsListId, name: listProps?.analyticsListName },
            quantity
          );

          break;
        }

        // Set Comment

        case Actions.SetComment: {
          break;
        }

        // Default

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err: any, args, context) => {
      const { response } = err;
      const { payload, type } = args;
      const { previousState } = context as any; // TODO: fix
      const { status, data } = response || {};

      const errorToastId = ERROR_TYPES[type];
      const errorMessage = status === ERROR_CODES.VALIDATION_ERROR
        ? data?.violations?.[0]?.message || t('ERRORS.DEFAULT_TOAST')
        : t('ERRORS.DEFAULT_TOAST');

      switch (type) {

        // Add

        case Actions.Add: {
          analytics.viewCartError(errorMessage);
          break;
        }

        // Bulk Add

        case Actions.BulkAdd: {
          break;
        }

        // Edit

        case Actions.Edit: {
          const {
            orderItem
          } = payload as EditPayload;

          const { id: orderItemId } = orderItem;
          const previousOrderItem = (previousState as IOrder)?.items?.find((item) => item.id === orderItemId);

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items.map((item) => (
              item.id === orderItemId
                ? previousOrderItem
                : item
            ))
          }));

          analytics.viewCartError(errorMessage);
          break;
        }

        // Remove

        case Actions.Remove: {
          analytics.viewCartError(errorMessage);
          break;
        }

        // Set Comment

        case Actions.SetComment: {
          const {
            orderItem
          } = payload as SetCommentPayload;

          const { id: orderItemId } = orderItem;
          const previousOrderItem = (previousState as IOrder)?.items?.find((item) => item.id === orderItemId);

          queryClient.setQueriesData({ queryKey: [QUERY_KEYS.ACTIVE_ORDER], type: 'active' }, (order: IOrder) => ({
            ...order,
            items: order?.items.map((item) => (
              item.id === orderItemId
                ? previousOrderItem
                : item
            ))
          }));
          break;
        }

        // Default

        default:
          console.error('Unknown mutation type (on error)', type);
      }

      Notifications.showError(errorMessage, { autoClose: 2000, toastId: errorToastId });

      if (payload.onError) {
        payload.onError(err);
      }
    },
    onSettled: (data, error, args) => {
      const { payload } = args;

      if (payload.onSettled) {
        payload.onSettled(data, error);
      }
    }
  });
};

// Payload Types

interface AddPayload extends ReactQueryMutationPayloadCallbacks {
  order: IOrder,
  product?: IProduct,
  orderItem?: IOrderProduct,
  listProps?: IAnalyticsListProps,
  //
  quantity?: number,
  replaceProductVariantCode?: string,
  bypassAddressRequirement?: boolean,
  bypassQuantityVariants?: boolean,
  hasMultipleQuantity?: boolean,
  //
  onSuccess?: (data: IOrder) => void,
  onError?: (err?: any) => void,
  onSettled?: (data?: IOrder, err?: any) => void
}

interface BulkAddPayload extends ReactQueryMutationPayloadCallbacks {
  order: IOrder,
  products: {
    quantity: number,
    product: IProduct
  }[],
  listProps?: IAnalyticsListProps,
  //
  bypassAddressRequirement?: boolean,
  bypassQuantityVariants?: boolean,
  hasMultipleQuantity?: boolean,
  //
  onSuccess?: (data: IOrder) => void,
  onError?: (err?: any) => void,
  onSettled?: (data?: IOrder, err?: any) => void
}

interface EditPayload extends ReactQueryMutationPayloadCallbacks {
  order: IOrder,
  product?: IProduct,
  orderItem: IOrderProduct,
  listProps?: IAnalyticsListProps,
  //
  previousQuantity: number,
  quantity: number,
  //
  onSuccess?: (data: IOrder) => void,
  onError?: (err?: any) => void,
  onSettled?: (data?: IOrder, err?: any) => void
}

interface RemovePayload extends ReactQueryMutationPayloadCallbacks {
  order: IOrder,
  product?: IProduct,
  orderItem: IOrderProduct,
  listProps?: IAnalyticsListProps,
  //
  onSuccess?: (data: IOrder) => void,
  onError?: (err?: any) => void,
  onSettled?: (data?: IOrder, err?: any) => void
}

interface SetCommentPayload extends ReactQueryMutationPayloadCallbacks {
  order: IOrder,
  orderItem: IOrderProduct,
  comment: string,
  //
  onSuccess?: (data: IOrder) => void,
  onError?: (err?: any) => void,
  onSettled?: (data?: IOrder, err?: any) => void
}

type MutationPayload =
  AddPayload |
  BulkAddPayload |
  EditPayload |
  RemovePayload |
  SetCommentPayload

// Helpers

const createNewItem = (product: IProduct | IOrderProduct | undefined, quantity?: number) => {
  const {
    image,
    maxAllowedQuantity,
    maxAvailableQuantity,
    variantCode,
    breadcrumbs,
  } = product || {};

  const {
    name, slug,
  } = (product || {}) as IProduct;

  const {
    productName, productSlug
  } = (product || {}) as IOrderProduct;

  return {
    quantity: quantity || 0,
    image,
    productName: name || productName,
    productSlug: slug || productSlug,
    variantCode,
    maxAllowedQuantity,
    maxAvailableQuantity,
    isLoading: true,
    isOk: true,
    breadcrumbs
  };
};

// Constants

const ERROR_TYPES = {
  [Actions.Add]: 'productAddError',
  [Actions.BulkAdd]: 'productBulkAddError',
  [Actions.Edit]: 'productEditError',
  [Actions.Remove]: 'productRemoveError',
  [Actions.SetComment]: 'setCommentError',
};
