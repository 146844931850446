import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import type { IRecipe } from 'types';

import styles from './RecipeIngredientsAddToastBody.module.scss';

interface Props {
  className?: string,
  recipe: IRecipe
}

const RecipeIngredientsAddToastBody = (props: Props) => {

  const {
    className,
    recipe
  } = props;

  const {
    details,
    media,
    stats,
  } = recipe;

  const {
    title
  } = details || {};

  const {
    banner
  } = media || {};

  const {
    mobile
  } = banner || {};

  const {
    alt,
    src
  } = mobile || {};

  const {
    servings: {
      value: servings
    } = {
      value: null
    }
  } = stats || {};

  const { t } = useTranslation();

  const message = servings === 1
    ? t('recipes:RECIPE_INGREDIENTS_MODAL.NOTIFICATIONS.BULK_ADD_SUCCESS_SINGULAR', { servings })
    : t('recipes:RECIPE_INGREDIENTS_MODAL.NOTIFICATIONS.BULK_ADD_SUCCESS_PLURAL', { servings });

  return (
    <div className={classnames(styles.root, className)}>

      {/* Image */}
      {
        src && (
          <div className={styles.thumbnail}>
            <img
              className={styles.image}
              alt={alt}
              src={src}
            />
          </div>
        )
      }

      {/* Content */}
      <div className={styles.content}>

        {/* Title */}
        {
          title && (
            <div className={styles.title}>
              {title}
            </div>
          )
        }

        {/* Message */}
        <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />

      </div>
    </div>
  );
};

export default RecipeIngredientsAddToastBody;
