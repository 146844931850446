import { useQuery } from '@tanstack/react-query';

import { ERROR_CODES } from 'constants/errors';
import { QUERY_KEYS } from 'constants/query-keys';
import {
  getHomepage,
  getHomepagePreview,
  getPersonalizedHomepage,
  getPersonalizedHomepagePreview
} from 'services/HomepageService';

import type { IHomepageResponse, QueryClient, ReactQueryOptions } from 'types';

interface Options {
  isPersonalized?: boolean,
  previewId?: string,
  page?: number,
  limit?: number,
  nextStartIndex?: number
}

export function useHomepage(options: Options = {}, queryOptions: ReactQueryOptions<IHomepageResponse>) {
  const {
    isPersonalized, previewId, page, limit, nextStartIndex
  } = options;
  return useQuery({
    queryKey: getKey(options),
    queryFn: () => (
      previewId
        ? (
          isPersonalized
            ? getPersonalizedHomepagePreview(previewId)
            : getHomepagePreview(previewId)
        ) : (
          isPersonalized
            ? getPersonalizedHomepage({ page, limit, nextStartIndex })
            : getHomepage({ page, limit, nextStartIndex })
        )
    ),
    gcTime: Infinity,
    ...queryOptions,
  });
}

export function getKey(options?: Options) {
  const {
    isPersonalized, previewId, page, limit, nextStartIndex
  } = options || {};

  const additionalKeyParams = [
    page ? `${page}` : 'no-page',
    limit ? `${limit}` : 'no-limit',
    nextStartIndex ? `${nextStartIndex}` : 'no-nextstartindex',
  ];

  return (
    previewId
      ? (
        isPersonalized
          ? [QUERY_KEYS.HOMEPAGE_PERSONALIZED, previewId]
          : [QUERY_KEYS.HOMEPAGE, previewId]
      ) : (
        isPersonalized
          ? [QUERY_KEYS.HOMEPAGE_PERSONALIZED, ...additionalKeyParams]
          : [QUERY_KEYS.HOMEPAGE, ...additionalKeyParams]
      )
  );
}

export function prefetchHomepage(queryClient: QueryClient) {
  return queryClient.prefetchQuery({ queryKey: getKey(), queryFn: () => getHomepage() });
}

export async function prefetchHomepagePreview(queryClient: QueryClient, options: Options) {
  const { previewId } = options;
  try {
    const data = await queryClient.fetchQuery({
      queryKey: getKey(options),
      queryFn: () => getHomepagePreview(previewId)
    });
    return !!data;
  } catch (err: any) {
    if (err?.response?.status === ERROR_CODES.NOT_FOUND) { return false; }
  }
  return true;
}
