/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';

import SimpleBar from 'simplebar-react';

import type SimpleBarCore from 'simplebar-core';
import type { Props } from 'simplebar-react';

const Scrollbar = React.forwardRef((props: Props, ref: React.Ref<SimpleBarCore>) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <SimpleBar ref={ref} {...rest}>
      {children}
    </SimpleBar>
  );
});

export interface ScrollbarElement extends SimpleBarCore {}
export default Scrollbar;
