import React from 'react';
import { useTranslation } from 'next-i18next';

import { Button, Icon } from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import FiltersModal from './FiltersModal';

import type {
  IListingFilter, IListingFilterOption, IListingFilters, IUrl, PageType
} from 'types';

import styles from './FiltersMobile.module.scss';

interface Props {
  filters: IListingFilters,
  data?: { count?: number, query?: any },
  pageType: PageType,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  clearFilter: (filter: IListingFilter) => void,
  clearFilters: () => void
}

const FiltersMobile = (props: Props) => {

  const {
    filters,
    data,
    pageType,
    //
    getHref,
    clearFilter,
    clearFilters,
  } = props;

  // Hooks

  const { t } = useTranslation();

  const {
    isOpen: isModalOpen, open: openModal, close: closeModal
  } = useIsOpen();

  // Props

  const { countSelected } = filters;

  // Render

  return (
    <>

      {/* Open Modal Button */}
      <Button
        className={styles.modalBtn}
        type="button"
        startIcon={FilterIcon}
        endIcon={ChevronIcon}
        onClick={openModal}
      >

        {/* Label */}
        {t('LISTING_TOOLBAR.FILTER_BY.LABEL_SHORT')}

        {/* Count */}
        {
          (countSelected > 0)
            ? <div className={styles.filtersCount}>{countSelected}</div>
            : null
        }

      </Button>

      {/* Modal */}
      <FiltersModal
        isOpen={isModalOpen}
        close={closeModal}
        filters={filters}
        data={data}
        pageType={pageType}
        getHref={getHref}
        clearFilter={clearFilter}
        clearFilters={clearFilters}
      />

    </>
  );
};

// Icons

const FilterIcon = <Icon size={20} name="filter" className={styles.filterIcon} />;
const ChevronIcon = <Icon size={16} name="chevron-down" className={styles.chevronIcon} />;

// Export

export default FiltersMobile;
