import React from 'react';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';
import { isChristmasSeason } from 'helpers/SeasonalHelpers';

import GeniusBadge from '../GeniusBadge/GeniusBadge';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';

import type { IUser } from 'types';

import styles from './Avatar.module.scss';

export const VARIANTS = {
  EMPTY: 'empty',
  SUBTLE: 'subtle'
} as const;

type VARIANT = typeof VARIANTS[keyof typeof VARIANTS];

interface Props {
  //
  className?: string,
  imageClassName?: string,
  iconClassName?: string,
  //
  user?: IUser,
  //
  variant?: VARIANT,
  size?: number,
  iconSize?: number,
  iconStrokeWidth?: number,
  //
  withGeniusBadge?: boolean,
  geniusBadgeProps?: AvatarBadgeProps
}

const Avatar = (props: Props) => {
  const {
    //
    className,
    imageClassName,
    iconClassName,
    //
    user,
    //
    variant = VARIANTS.EMPTY,
    size = 32,
    iconSize = 24,
    iconStrokeWidth = 2,
    //
    withGeniusBadge,
    geniusBadgeProps = {}
  } = props;

  return (
    <div
      className={classnames(styles.root, className)}
    >
      {/* Avatar */}
      <AvatarImage
        imageClassName={imageClassName}
        iconClassName={iconClassName}
        user={user}
        size={size}
        iconSize={iconSize}
        variant={variant}
        iconStrokeWidth={iconStrokeWidth}
      />

      {/* Badge */}
      {
        ((withGeniusBadge && user?.isGeniusUser)) && (
          <AvatarBadge
            {...geniusBadgeProps}
          />
        )
      }

    </div>
  );
};

// Components

// --- Avatar Image

interface AvatarImageProps {
  imageClassName?: string,
  iconClassName?: string,
  user?: IUser,
  size: number,
  variant: VARIANT,
  iconSize: number,
  iconStrokeWidth: number
}

const AvatarImage = (props: AvatarImageProps) => {
  const {
    imageClassName,
    iconClassName,
    user,
    size,
    variant,
    iconSize,
    iconStrokeWidth,
  } = props;

  return (
    <div
      className={classnames(styles.avatar, { [styles[variant]]: variant })}
      style={{
        width: size,
        height: size,
        maxWidth: size,
        maxHeight: size,
      }}
    >
      {
        user?.image?.default ? (
          <Image
            className={classnames(styles.image, imageClassName)}
            src={user?.image?.default}
            width={size}
            height={size}
          />
        ) : (
          <Icon
            className={classnames(styles.icon, iconClassName)}
            name="user"
            size={iconSize}
            strokeWidth={iconStrokeWidth}
          />
        )
      }
    </div>
  );
};

// --- Badge

type AvatarBadgeProps = Partial<React.ComponentProps<typeof GeniusBadge>>;

const AvatarBadge = (props: AvatarBadgeProps) => {
  const {
    className,
    withIcon,
    ...rest
  } = props || {};

  return (
    <GeniusBadge
      className={classnames(styles.geniusBadge, { [styles.fixed]: withIcon }, className)}
      withIcon={withIcon}
      {...rest}
    />
  );
};

// Export

export default Avatar;
