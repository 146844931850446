import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import type { IAccountBenefitsDynamicContent, IAccountBenefitsDynamicContentLine } from 'types';
import { ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES } from 'types';

import styles from './AccountBenefitsDynamicContent.module.scss';

interface Props {
  className?: string,
  isCentered?: boolean,
  lines: IAccountBenefitsDynamicContent
}

const AccountBenefitsDynamicContent = (props: Props) => {
  const {
    className,
    isCentered = true,
    lines
  } = props;

  if (isArrayEmpty(lines)) return null;

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.centered]: isCentered },
        className
      )}
    >
      {
        lines.map((line, index) => {
          return (
            <DynamicContentLine
              key={`line-${index}`}
              line={line}
            />
          );
        })
      }
    </div>
  );
};

// Components

interface DynamicContentLineProps {
  className?: string,
  line: IAccountBenefitsDynamicContentLine
}

const DynamicContentLine = (props: DynamicContentLineProps) => {
  const {
    className,
    line
  } = props;

  const {
    type,
    text,
    image
  } = line || {};

  return (
    <div
      className={classnames(
        styles.line,
        { [styles.withImage]: !!image },
        DYNAMIC_CONTENT_LINE_CLASSES[type] || styles.default,
        className,
      )}
    >

      {/* Image */}
      {
        image && (
          <div className={styles.image}>
            <img src={image} alt={text} />
          </div>
        )
      }

      {/* Text */}
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

// Constants

const DYNAMIC_CONTENT_LINE_CLASSES = {
  [ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES.TEXT_SMALL]: styles.small,
  [ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES.TEXT_MEDIUM]: styles.medium,
  [ACCOUNT_BENEFITS_DYNAMIC_CONTENT_LINE_TYPES.TEXT_LARGE]: styles.large,
};

// Export

export default AccountBenefitsDynamicContent;
