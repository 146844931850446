import type { ValueOf } from 'types/utility';

export const ORDER_FEEDBACK_NEXT_STEPS = {
  LOW_RATING_FORM: 'LOW_RATING_FORM',
  TESTIMONIAL_FORM: 'TESTIMONIAL_FORM',
  COMPLETED_MESSAGE: 'COMPLETED_MESSAGE',
} as const;

export type OrderFeedbackNextStepType = ValueOf<typeof ORDER_FEEDBACK_NEXT_STEPS>;

export interface IDriver {
  name: string
}

export interface IOrderFeedbackResponse {
  rating: number,
  nextStep: OrderFeedbackNextStepType,
  ratingEditable?: boolean,
  formSubmitted?: boolean,
  driver?: IDriver
}
