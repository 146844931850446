import React from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import classnames from 'classnames';

import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useConfig } from 'hooks/data/useConfig';
import { useAnalytics } from 'hooks/useAnalytics';
import { useDebouncedQuantityActions } from 'hooks/useDebouncedQuantityActions';

import ProductReplaceButton from '../ProductReplaceButton/ProductReplaceButton';

import ProductAddToCartButton from './ProductAddToCartButton';
import ProductAdjustQuantityButton from './ProductAdjustQuantityButton';
import ProductBuyMoreButton from './ProductBuyMoreButton';
import ProductQuantityButton from './ProductQuantityButton';
import ProductQuantityInput from './ProductQuantityInput';

import type {
  Alignment, IOrderProduct, IProduct, IProductVariant, Size
} from 'types';
import { Alignments, Sizes } from 'types';

import styles from './ProductAddToCart.module.scss';

//

interface Props {
  className?: string,
  addClassName?: string,
  adjustClassName?: string,
  editClassName?: string,
  buttonClassName?: string,
  addToCartButtonClassName?: string,
  adjustQuantityButtonClassName?: string,
  quantityButtonClassName?: string,
  quantityInputClassName?: string,
  //
  product: IProduct | IProductVariant | IOrderProduct,
  quantity: number,
  //
  disabled?: boolean,
  isAddToCartDisabled?: boolean,
  isAddToCartLoading?: boolean,
  //
  bypassQuantityVariants?: boolean,
  canAddMultiple?: boolean,
  canAddMultipleType?: 'quantity' | 'bmsm',
  withWideBuyMoreButton?: boolean,
  //
  shouldReset?: boolean,
  shouldAdjustQuantity?: boolean,
  shouldDisableQuantityHandler?: boolean,
  //
  size?: Size,
  align?: Alignment,
  //
  isCompact?: boolean,
  isPrimary?: boolean,
  isReplace?: boolean,
  isVerbose?: boolean,
  isInputRounded?: boolean,
  //
  withAddToCartIcon?: boolean,
  withAddToCartText?: boolean,
  withShortenedAddToCartText?: boolean,
  withAdjustIcon?: boolean,
  //
  onBuyMoreClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onAddToCartClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  onQuantityChange: (currentQuantity: number, updatedQuantity: number) => void,
  onReplace?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductAddToCart = (props: Props) => {

  const {
    className,
    addClassName,
    adjustClassName,
    editClassName,
    buttonClassName,
    addToCartButtonClassName,
    adjustQuantityButtonClassName,
    quantityButtonClassName,
    quantityInputClassName,
    //
    product,
    quantity,
    //
    disabled,
    isAddToCartDisabled,
    isAddToCartLoading,
    bypassQuantityVariants,
    canAddMultiple,
    canAddMultipleType,
    withWideBuyMoreButton,
    //
    shouldReset,
    shouldAdjustQuantity,
    shouldDisableQuantityHandler,
    //
    size = Sizes.MEDIUM,
    align = Alignments.END,
    //
    isCompact,
    isPrimary,
    isReplace,
    isVerbose,
    isInputRounded,
    //
    withAddToCartIcon,
    withAddToCartText,
    withShortenedAddToCartText,
    withAdjustIcon,
    //
    onBuyMoreClick,
    onAddToCartClick,
    onQuantityChange,
    onReplace,
  } = props;

  // Refs

  const inputRef = React.useRef<HTMLInputElement>(null);
  const editRef = React.useRef(null);

  // Hooks

  const analytics = useAnalytics();

  const { t } = useTranslation();
  const { data: config } = useConfig();

  const { errors } = config || {};

  const [editValue, setEditValue] = React.useState(0);
  const [isEditMode, setEditMode] = React.useState(false);

  // On Click Outside Hook

  useOnclickOutside(() => {
    setEditMode(false);
  }, {
    disabled: !isEditMode,
    refs: [editRef]
  });

  // Debounce Hook

  const {
    value: quantityTarget = 0,
    reset, decrease, increase, update
  } = useDebouncedQuantityActions(onQuantityChange, {
    ms: 600,
    initialValue: quantity,
    disabled: shouldDisableQuantityHandler
  });

  // Props

  const {
    maxAllowedQuantity,
    maxAvailableQuantity
  } = product;

  const [maxQuantity, errorMessage] = React.useMemo(() => (
    ProductHelpers.getMaxAllowedQuantity({ maxAllowedQuantity, maxAvailableQuantity }, errors)
  ), [maxAllowedQuantity, maxAvailableQuantity, errors]);

  const targetQuantity = quantity > maxQuantity
    ? maxQuantity
    : null;

  const canSaveEditValue = editValue <= maxQuantity;
  const canIncreaseQuantity = quantityTarget < maxQuantity;

  const hasBuyMore = canAddMultiple && canAddMultipleType === 'bmsm' && bypassQuantityVariants;

  const value = isEditMode
    ? `${editValue}`
    : (isVerbose ? t('PRODUCT.QUANTITY_IN_CART', { quantity: quantityTarget }) : `${quantityTarget}`);

  // Effects

  React.useEffect(() => {
    if (shouldReset) {
      reset();
    }
  }, [shouldReset]);

  // Handlers

  const handleCloseEditMode = (e: React.FocusEvent<HTMLButtonElement | HTMLInputElement>) => {
    if (e.relatedTarget && !e.relatedTarget.getAttribute('data-prevent-manual-edit-close')) {
      setEditMode(false);
    }
  };

  const hasReachedMaxQuantity = () => {
    return ProductHelpers.checkMaxQuantityReached(
      quantityTarget - 1,
      quantityTarget,
      product,
      errors,
      { analytics }
    );
  };

  const handleIncrease = () => {
    const shouldIncrease = !(hasReachedMaxQuantity());
    if (shouldIncrease) {
      increase();
    }
  };

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[align]]: align },
        className
      )}
    >

      {/* Add To Cart */}
      {
        (quantity === 0 && onAddToCartClick) && (
          <div
            className={classnames(
              styles.add,
              { [styles.compact]: isCompact },
              { [styles[size]]: size },
              { [styles.hasBuyMore]: hasBuyMore },
              addClassName
            )}
          >
            {hasBuyMore
            && (
              <ProductBuyMoreButton
                withWideBuyMoreButton={withWideBuyMoreButton}
                onClick={(e) => {
                  e.preventDefault();
                  if (onBuyMoreClick) {
                    onBuyMoreClick(e);
                  }
                }}
              />
            )}
            <ProductAddToCartButton
              //
              className={classnames(
                addToCartButtonClassName,
                buttonClassName,
              )}
              size={size}
              //
              isPrimary={isPrimary}
              isReplace={isReplace}
              withText={withAddToCartText}
              withIcon={withAddToCartIcon}
              withShortenedText={withShortenedAddToCartText}
              hasBuyMore={hasBuyMore}
              //
              loading={!!isAddToCartLoading}
              disabled={!!(disabled || isAddToCartDisabled)}
              //
              onClick={(e) => {
                e.preventDefault();
                onAddToCartClick(e);
              }}
              //
            />
          </div>
        )
      }

      {/* Adjust Quantity */}
      {
        (quantity > 0 && shouldAdjustQuantity) && (
          <div
            className={classnames(
              styles.adjust,
              { [styles.compact]: isCompact },
              { [styles[size]]: size },
              adjustClassName
            )}
          >
            <div
              className={classnames(
                styles.btnWrapper,
                styles.adjustBtnWrapper
              )}
            >
              {
                ((targetQuantity === null || targetQuantity === 0) && onReplace)
                  ? (
                    // Replace Product Button
                    <ProductReplaceButton
                      withText
                      size="medium"
                      onClick={onReplace}
                    />
                  ) : (
                    (targetQuantity !== null && targetQuantity > 0) && (
                      // Adjust Quantity Button
                      <ProductAdjustQuantityButton
                        //
                        className={classnames(
                          adjustQuantityButtonClassName,
                          buttonClassName
                        )}
                        size={size}
                        //
                        targetQuantity={targetQuantity}
                        //
                        isPrimary={!!isPrimary}
                        withIcon={!!withAdjustIcon}
                        //
                        onClick={(e) => {
                          e.preventDefault();
                          onQuantityChange(quantity, targetQuantity);
                        }}
                        //
                      />
                    )
                  )
              }
            </div>
          </div>
        )
      }

      {/* Edit Quantity */}
      {
        (quantity > 0 && !shouldAdjustQuantity) && (
          <div
            ref={editRef}
            className={classnames(
              styles.edit,
              { [styles.compact]: isCompact },
              { [styles[size]]: size },
              editClassName
            )}
          >

            {/* Decrease / Remove / Cancel  */}
            <ProductQuantityButton
              //
              className={classnames(
                styles.decreaseBtn,
                quantityButtonClassName,
                buttonClassName
              )}
              //
              size={size}
              icons={[
                { name: 'x', isVisible: isEditMode, fromTop: true },
                { name: 'minus', isVisible: !isEditMode && quantity > 1 },
                { name: 'trash', strokeWidth: 2, isVisible: !isEditMode && (quantity === 0 || quantity === 1) }
              ]}
              //
              disabled={
                disabled
                || (isEditMode ? false : quantityTarget <= 0)
              }
              //
              isFixed={isVerbose}
              isEditMode={isEditMode}
              //
              onClick={
                !isEditMode
                  ? decrease
                  : () => setEditMode(false)
              }
              onEsc={() => {
                if (isEditMode) {
                  setEditMode(false);
                }
              }}
              onBlur={handleCloseEditMode}
              //
            />

            {/* Input */}
            <ProductQuantityInput
              //
              ref={inputRef}
              //
              className={classnames(
                quantityInputClassName
              )}
              //
              size={size}
              //
              value={value}
              disabled={!!disabled}
              hasError={isEditMode && !canSaveEditValue}
              //
              isVerbose={isVerbose}
              isCompact={isCompact}
              isRounded={isInputRounded}
              //
              onChange={(updatedValue) => {
                setEditValue(updatedValue);
              }}
              onEnter={() => {
                if (canSaveEditValue) {
                  update(editValue || 0);
                  setEditMode(false);
                }
              }}
              onEsc={() => {
                setEditMode(false);
              }}
              onFocus={() => {
                if (!isEditMode) {
                  if (inputRef?.current) {
                    inputRef.current.select();
                  }
                  setEditMode(true);
                  setEditValue(quantityTarget);
                }
              }}
              onBlur={handleCloseEditMode}
              //
            />

            {/* Increase / Confirm */}
            <ProductQuantityButton
              //
              className={classnames(
                styles.increaseBtn,
                quantityButtonClassName,
                { [styles.disabled]: !canIncreaseQuantity },
                buttonClassName
              )}
              //
              title={
                isEditMode
                  ? (canSaveEditValue ? '' : errorMessage)
                  : (canIncreaseQuantity ? '' : errorMessage)
              }
              //
              size={size}
              variant={
                isEditMode
                  ? 'primary'
                  : 'subtle'
              }
              icons={[
                { name: 'check', isVisible: isEditMode, fromTop: true },
                { name: 'plus', isVisible: !isEditMode }
              ]}
              //
              isFixed={isVerbose}
              isEditMode={isEditMode}
              //
              disabled={disabled || (isEditMode && !canSaveEditValue)}
              //
              onClick={
                !isEditMode
                  ? handleIncrease
                  : () => {
                    update(editValue || 0);
                    setEditMode(false);
                  }
              }
              onEsc={() => {
                if (isEditMode) {
                  setEditMode(false);
                }
              }}
              onBlur={handleCloseEditMode}
              //
            />
          </div>
        )
      }
    </div>
  );
};

export default ProductAddToCart;
