import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { ILoyaltyHistoryItem } from 'types';

const CATEGORY = 'loyalty_events';

enum Actions {
  //
  OPEN_BREAKDOWN_MODAL = 'open_breakdown_modal',
  //
  VIEW_TAB = 'view_tab',
  SELECT_LINK = 'select_link',
  EXPAND_FAQ = 'expand_faq',
  EXPAND_TRANSACTION = 'expand_transaction',
  DOWNLOAD_PDF = 'download_pdf',
  //
  SELECT_OPT_IN = 'select_opt_in',
  COMPLETE_OPT_IN = 'complete_opt_in',
  //
  SELECT_OPT_OUT_START = 'select_opt_out_start',
  SELECT_OPT_OUT_WARNING = 'select_opt_out_warning',
  SELECT_OPT_OUT_CONFIRMATION = 'select_opt_out_confirmation',
  COMPLETE_OPT_OUT = 'complete_opt_out',
  //
  SELECT_POINTS_OPTION = 'select_points_option',
  CLEAR_POINTS_OPTION = 'clear_points_option',
  //
  OPEN_CUSTOM_POINTS_MODAL = 'open_custom_points_modal',
  SELECT_CUSTOM_POINTS_OPTION = 'select_custom_points_option',
  CLEAR_CUSTOM_POINTS_OPTION = 'clear_custom_points_option',
  //
  OPEN_LOYALTY_INFO_MODAL = 'open_loyalty_info_modal',
  DISMISS_LOYALTY_INFO_MODAL = 'dismiss_loyalty_info_modal',
  //
}

enum Labels {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}

// Helper

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Breakdown Modal

export function openLoyaltyBreakdownModal() {
  push(Actions.OPEN_BREAKDOWN_MODAL, null);
}

// --- Tabs

export function viewLoyaltyTab(tabName: string) {
  push(Actions.VIEW_TAB, tabName);
}

export function selectLoyaltyLink(linkUrl: string) {
  push(Actions.SELECT_LINK, linkUrl);
}

export function expandLoyaltyFaq(strapiId: number) {
  push(Actions.EXPAND_FAQ, `${strapiId}`);
}

// --- Transactions

export function expandLoyaltyTransaction(transaction: ILoyaltyHistoryItem) {
  const { data } = transaction || {};
  const { title, date, value } = data || {};
  push(Actions.EXPAND_TRANSACTION, null, {
    param1: title,
    param2: date,
    param3: value,
  });
}

export function downloadLoyaltyTransactionPdf(transaction: ILoyaltyHistoryItem) {
  const { data } = transaction || {};
  const { title, date, value } = data || {};
  push(Actions.DOWNLOAD_PDF, null, {
    param1: title,
    param2: date,
    param3: value,
  });
}

// --- Opt in

export function selectLoyaltyOptIn() {
  push(Actions.SELECT_OPT_IN, null);
}

export function completeLoyaltyOptIn() {
  push(Actions.COMPLETE_OPT_IN, null);
}

// --- Opt Out --- Start

export function selectLoyaltyOptOutStart() {
  push(Actions.SELECT_OPT_OUT_START, null);
}

// --- Opt Out --- Warning

function selectLoyaltyOptOutWarning(label: Labels) {
  push(Actions.SELECT_OPT_OUT_WARNING, label);
}

export function selectLoyaltyOptOutWarningConfirm() {
  selectLoyaltyOptOutWarning(Labels.CONFIRM);
}

export function selectLoyaltyOptOutWarningCancel() {
  selectLoyaltyOptOutWarning(Labels.CANCEL);
}

// --- Opt Out --- Confirmation

function selectLoyaltyOptOutConfirmation(label: Labels) {
  push(Actions.SELECT_OPT_OUT_CONFIRMATION, label);
}

export function selectLoyaltyOptOutConfirmationConfirm() {
  selectLoyaltyOptOutConfirmation(Labels.CONFIRM);
}

export function selectLoyaltyOptOutConfirmationCancel() {
  selectLoyaltyOptOutConfirmation(Labels.CANCEL);
}

// --- Opt Out --- Complete

export function completeLoyaltyOptOut() {
  push(Actions.COMPLETE_OPT_OUT, null);
}

// --- Preset Points

export function selectLoyaltyPointsOption(points: number, amount: string) {
  push(Actions.SELECT_POINTS_OPTION, null, {
    param1: amount,
    param2: points
  });
}

export function clearLoyaltyPointsOption(points: number, amount: string) {
  push(Actions.CLEAR_POINTS_OPTION, null, {
    param1: amount,
    param2: points
  });
}

// --- Custom Points

export function openLoyaltyCustomPointsModal() {
  push(Actions.OPEN_CUSTOM_POINTS_MODAL, null);
}

export function selectLoyaltyCustomPointsOption(points: number, amount: string) {
  push(Actions.SELECT_CUSTOM_POINTS_OPTION, null, {
    param1: amount,
    param2: points
  });
}

export function clearLoyaltyCustomPointsOption(points: number, amount: string) {
  push(Actions.CLEAR_CUSTOM_POINTS_OPTION, null, {
    param1: amount,
    param2: points
  });
}

// --- Info Modal

export function openLoyaltyInfoModal() {
  push(Actions.OPEN_LOYALTY_INFO_MODAL, null);
}

export function dismissLoyaltyInfoModal() {
  push(Actions.DISMISS_LOYALTY_INFO_MODAL, null);
}
