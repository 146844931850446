import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Notice } from 'components/ui';

import styles from './OrderDeliveryNotice.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

const OrderDeliveryNotice = (props) => {

  const {
    className, order = {}, size,
  } = props;

  const { deliveryNotice = {} } = order;

  const {
    icon,
    title,
    htmlDescription: text,
    bgColor: backgroundColor,
    titleColor,
  } = deliveryNotice;

  return (
    <Notice
      className={classnames(styles.root, className)}
      variant="custom"
      size={size}
      iconColor={titleColor}
      titleColor={titleColor}
      backgroundColor={backgroundColor}
      notice={{
        icon,
        title,
        description: text
      }}
    />
  );
};

OrderDeliveryNotice.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  order: PropTypes.shape({}),
  size: PropTypes.oneOf(Object.values(SIZES))
};

export default OrderDeliveryNotice;
