import React from 'react';
import {
  HydrationBoundary, MutationCache, QueryCache, QueryClient, QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Logger from 'common/Logger';
import { CONFIG } from 'constants/config';
import { useGlobalVariables } from 'hooks/useGlobalVariables';

import type { QueryClientConfig } from '@tanstack/react-query';

interface Props {
  state: any,
  children?: React.ReactNode
}

const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !CONFIG.DISABLE_REFETCH_ON_FOCUS,
      staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME
    }
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      Logger.error(`Failed to fetch query ${query.queryKey[0]}`, {
        errorMessage: error?.message,
        errorCode: error?.response?.status,
        key: query.queryKey,
        meta: query.meta
      }, { clientSide: true });
    }
  }),
  mutationCache: new MutationCache({
    onError: (error: any, variables, context, mutation) => {
      Logger.error(`Failed to perform mutation ${mutation.options.mutationKey}`, {
        errorMessage: error?.message,
        errorCode: error?.response?.status,
        key: mutation.options.mutationKey,
        meta: mutation.options.meta,
        variables,
        context
      }, { clientSide: true });
    }
  })
};

const QueryProvider = (props: Props) => {
  const { state, children } = props;
  const [showQueryDevTools, setShowQueryDevTools] = React.useState(false);

  const { setFunction } = useGlobalVariables();

  React.useEffect(() => {
    setFunction('toggleQueryDevTools', () => {
      setShowQueryDevTools((prev) => !prev);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [queryClient] = React.useState(() => new QueryClient(config));
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      {showQueryDevTools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
      <HydrationBoundary state={state}>
        {children}
      </HydrationBoundary>
    </QueryClientProvider>
  );
};

const ReactQueryDevtoolsProduction = React.lazy(() => import('@tanstack/react-query-devtools/build/modern/production.js').then(
  (d) => ({ default: d.ReactQueryDevtools })
));

export default QueryProvider;
