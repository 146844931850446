import React from 'react';

export function useIsOpen(opened = false) {
  const [isOpen, setIsOpen] = React.useState(opened);

  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  const toggle = React.useCallback(() => setIsOpen((prev) => !prev), []);
  const set = React.useCallback((value: boolean) => setIsOpen(value), []);

  return {
    isOpen,
    open,
    close,
    toggle,
    set
  };
}
