import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Icon, Link } from 'components/ui';
import { TRANSLATIONS } from 'constants/translations';
import { useAnalytics } from 'hooks/useAnalytics';

import type { IAccountPromotion } from 'types';

import styles from './AccountPromotionCard.module.scss';

interface Props {
  className?: string,
  promotion: IAccountPromotion
}

const AccountPromotionsCard = (props: Props) => {

  const { className, promotion } = props;
  const {
    dateLabel, title, description, url
  } = promotion || {};
  const { t } = useTranslation();
  const analytics = useAnalytics();

  const handleViewClick = () => {
    analytics.selectAccountPromotion(url);
  };

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} name="discount" />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>{dateLabel}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
      <div className={styles.actions}>
        <Link
          href={url}
          legacyBehavior
          passHref
        >
          <Button
            tag="a"
            className={styles.detailsLink}
            onClick={handleViewClick}
            endIcon={<Icon name="arrow-narrow-right" className={styles.icon} />}
          >
            {t('PROMOTIONS.PROMOTION_VIEW_DETAILS', { ns: TRANSLATIONS.ACCOUNT })}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AccountPromotionsCard;
