import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';

import styles from './CartEmpty.module.scss';

interface Props {
  className?: string
}
const CartEmpty = (props: Props) => {
  const { className } = props;

  const { t } = useTranslation('common');

  return (
    <div className={classnames(styles.root, className)}>
      <ImageMessage
        className={styles.emptyContainer}
        titleClassName={styles.title}
        subtitleClassName={styles.subtitle}
        image={IMAGES.BRANDING.MOBILE_APP_ICON}
        imageProps={{
          width: 82,
          height: 80,
          alt: 'No cart products'
        }}
        title={t('CART.EMPTY_TITLE')}
        subtitle={t('CART.EMPTY_SUBTITLE')}
      />
    </div>
  );
};

export default CartEmpty;
