import { AnalyticsProvider } from './AnalyticsContext';
import { AuthProvider } from './AuthenticationContext';
import { BreakpointsProvider } from './BreakpointsContext';
import { ChatProvider } from './ChatContext';
import { DeliveryInfoProvider } from './DeliveryInfoContext';
import { DrawerProvider } from './DrawerContext';
import { LayoutProvider } from './LayoutContext';
import { ModalProvider } from './ModalContext';
import { NavigationProvider } from './NavigationContext';
import { NotificationsProvider } from './NotificationsContext';
import { ScreenSizeProvider } from './ScreenSizeContext';
import { SearchProvider } from './SearchContext';
import { SeasonalProvider } from './SeasonalContext';

function AppProviders({ children }) {

  return (
    <AnalyticsProvider>
      <ChatProvider>
        <AuthProvider>
          <DrawerProvider>
            <ModalProvider>
              <NavigationProvider>
                <DeliveryInfoProvider>
                  <SearchProvider>
                    <NotificationsProvider>
                      <LayoutProvider>
                        <ScreenSizeProvider>
                          <BreakpointsProvider>
                            <SeasonalProvider>
                              {children}
                            </SeasonalProvider>
                          </BreakpointsProvider>
                        </ScreenSizeProvider>
                      </LayoutProvider>
                    </NotificationsProvider>
                  </SearchProvider>
                </DeliveryInfoProvider>
              </NavigationProvider>
            </ModalProvider>
          </DrawerProvider>
        </AuthProvider>
      </ChatProvider>
    </AnalyticsProvider>
  );
}

export default AppProviders;
