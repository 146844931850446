import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getNextDeliveryInterval } from 'services/AddressService';

export const useNextDeliveryInterval = (addressToken, isAuthenticated, options = {}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.NEXT_DELIVERY_INTERVAL,
      addressToken
    ],
    queryFn: () => getNextDeliveryInterval(
      addressToken,
      isAuthenticated
    ),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });
};
