import React from 'react';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';

import Image from '../Image/Image';

import styles from './GeniusBadge.module.scss';

interface Props {
  className?: string,
  imageClassName?: string,
  withIcon?: boolean,
  size?: {height: number, width: number},
  label?: string
}

const GeniusBadge = (props: Props) => {

  const {
    className,
    imageClassName,
    withIcon,
    size = withIcon ? { height: 17, width: 18 } : { width: 49, height: 17 },
    label
  } = props;

  const src = React.useMemo(() => (withIcon ? IMAGES.BRANDING.GENIUS_ICON : IMAGES.BRANDING.GENIUS_LOGO_HD), [withIcon]);

  return (
    <div className={classnames(styles.root, className, { [styles.withLabel]: label })}>
      {label && (<div className={styles.label}>{label}</div>)}
      <Image
        className={classnames(imageClassName)}
        src={src}
        height={size.height}
        width={size.width}
        optimize
        quality={100}
        alt="Genius logo"
      />
    </div>
  );
};

export default GeniusBadge;
