import { IMAGES } from 'constants/images';

export const HOLIDAY_ICONS = {
  CART: {
    EASTER: IMAGES.SEASONAL.EASTER_BASKET,
    CHRISTMAS: IMAGES.SEASONAL.CHRISTMAS_BAG
  },
  DELIVERY_INFO_BAR_MOBILE: {
    EASTER: IMAGES.SEASONAL.EASTER_BUNNY,
    CHRISTMAS: IMAGES.SEASONAL.REINDEER
  },
  DELIVERY_INTERVAL_DESKTOP: {
    CHRISTMAS: IMAGES.SEASONAL.REINDEER,
    EASTER: IMAGES.SEASONAL.EASTER_BUNNY
  },
  DELIVERY_MAP_PIN_DESKTOP: {
    CHRISTMAS: IMAGES.SEASONAL.SLEIGH,
  },
  CHECKOUT_SUCCESS_TEMPLATE: {
    EASTER: IMAGES.SEASONAL.EASTER_BUNNIES_CHECKOUT,
    CHRISTMAS: IMAGES.SEASONAL.CHRISTMAS_TREE_CHECKOUT
  },
  HEADER_LOGO_HAT: {
    CHRISTMAS: IMAGES.SEASONAL.SANTA_HAT
  }
} as const;
