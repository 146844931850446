import dynamic from 'next/dynamic';

export const AddressDeliveryIntervals = dynamic(() => import('./AddressDeliveryIntervals/AddressDeliveryIntervals'), { ssr: false });
export const AddressList = dynamic(() => import('./AddressList/AddressList'), { ssr: false });
export const AddressAddForm = dynamic(() => import('./AddressForm/AddressAddForm'));
export const AddressEditForm = dynamic(() => import('./AddressForm/AddressEditForm'));
export const AddressGoogleMap = dynamic(() => import('./AddressGoogleMap/AddressGoogleMap'), { ssr: false });
export const AddressInvalid = dynamic(() => import('./AddressInvalid/AddressInvalid'), { ssr: false });
export const AddressItem = dynamic(() => import('./AddressItem/AddressItem'));
export const AddressSearch = dynamic(() => import('./AddressSearch/AddressSearch'), { ssr: false });
export const AddressValid = dynamic(() => import('./AddressValid/AddressValid'), { ssr: false });

export { default as AddressModal } from './AddressModal/AddressModal';
