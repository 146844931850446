import { DateTime } from 'common/DateTime';

export const DATE_FORMAT = 'dd MMM yyyy';
export const DATE_TIME_FORMAT = 'dd MMM yyyy, HH:mm';
export const DATE_TIME_FORMAT_EXTENDED = 'dd MMM yyyy, HH:mm:ss';
export const BLOG_DATE_FORMAT = 'DD.MM.YYYY';

export function formatDate(date: string | null | undefined, format = DATE_FORMAT) {
  if (!date) return '-';
  return DateTime(date).utc().local().format(format);
}

export function formatBlogDate(date: string | null | undefined, format = BLOG_DATE_FORMAT) {
  if (!date) return '-';
  return DateTime(date).utc().local().format(format);
}

export function minutesToISODuration(minutes: number) {
  return DateTime.duration({ minutes }).toISOString();
}
