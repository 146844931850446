/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { UrlParams } from 'common/url-params';
import { APP_ROUTES } from 'constants/routes';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import FiltersDesktop from './FiltersDesktop';
import FiltersMobile from './FiltersMobile';

import type {
  IListingActiveFilters,
  IListingFilter, IListingFilterOption, IListingFilters, IUrl, PageType
} from 'types';

import styles from './Filters.module.scss';

interface Props {
  className?: string,
  //
  filters: { filters: IListingFilters | undefined, multiple?: boolean },
  //
  pageType: PageType,
  data?: { count?: number },
  //
  withLabel?: boolean,
  withAccordion?: boolean,
  withActiveFilters?: boolean,
  withClearButton?: boolean,
  inline?: boolean
}

const Filters = (props: Props) => {

  const {
    className,
    //
    filters: {
      filters,
      multiple = true
    } = {},
    //
    pageType,
    data,
    //
    withLabel = true,
    withAccordion = false,
    withActiveFilters = false,
    withClearButton = true,
    inline = true,
  } = props;

  // Hooks

  const analytics = useAnalytics();
  const router = useRouter();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const { persist: persistAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // Props

  const { items } = filters || {};

  const hasAvailableFilters = items?.some((filter) => filter?.values?.length > 0);

  // Helpers

  // --- Get updated option href

  const getHref = (option: IListingFilterOption, parent: IListingFilter): IUrl => {
    try {
      const { query: { slug: segments = [] } } = router;

      const updatedFilters = getUpdatedFilters(option, parent, items, undefined, multiple);
      const url = UrlParams.setFiltersParams(updatedFilters, { segments, pageType });
      return url;

    } catch (err) {
      console.error('Failed to compute filter URL', err);
    }

    return {
      href: APP_ROUTES.HOME
    };
  };

  // --- Clear filter

  const clearFilter = (parent: IListingFilter) => {
    try {
      const { query: { slug: segments = [] } } = router;

      const mapFn = (filter: IListingFilter, option: IListingFilterOption) => (
        filter.code === parent.code
          ? { ...option, isSelected: false }
          : option
      );
      const updatedFilters = getUpdatedFilters(null, parent, items, mapFn);
      const url = UrlParams.setFiltersParams(updatedFilters, { segments, pageType });

      persistAnalyticsListProps();
      analytics.clearFilter(parent?.name);
      router.push(url);

    } catch (err) {
      console.error('Failed to compute filter URL', err);
      router.push(APP_ROUTES.HOME);
    }
  };

  // --- Clear all filters

  const clearFilters = () => {
    try {
      const { query: { slug: segments } } = router;
      const url = UrlParams.setFiltersParams(null, { segments, pageType });

      persistAnalyticsListProps();
      analytics.clearAllFilters();
      router.push(url);

    } catch (err) {
      console.error('Failed to clear filters', err);
      router.push(APP_ROUTES.HOME);
    }
  };

  // Empty

  if (!filters || !Array.isArray(items) || !hasAvailableFilters) {
    return null;
  }

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {
        isBreakpoint
          ? (
            <FiltersMobile
              filters={filters}
              data={data}
              pageType={pageType}
              //
              getHref={getHref}
              clearFilter={clearFilter}
              clearFilters={clearFilters}
            />
          ) : (
            <FiltersDesktop
              filters={filters}
              //
              withLabel={withLabel}
              withAccordion={withAccordion}
              withActiveFilters={withActiveFilters}
              withClearButton={withClearButton}
              inline={inline}
              multiple={multiple}
              //
              getHref={getHref}
              clearFilters={clearFilters}
            />
          )
      }
    </div>
  );
};

// Helpers

const getUpdatedFilters = (
  option: IListingFilterOption | null,
  parent: IListingFilter,
  items: IListingFilter[] | undefined,
  mapFn?: (filter: IListingFilter, option: IListingFilterOption) => any,
  multiple: boolean = true
): IListingActiveFilters | null => {
  if (!items) return null;

  return items.reduce((acc, filter) => {
    const { code, values: options } = filter;

    const defaultMapFn = (o: IListingFilterOption) => {
      if (code === parent.code && o.code === option?.code) {
        return {
          ...o,
          isSelected: !o?.isSelected
        };
      }

      if (!multiple && code === parent.code) {
        return {
          ...o,
          isSelected: false
        };
      }

      return o;
    };

    const updatedOptions = options
      .map((o) => (
        mapFn
          ? mapFn(filter, o)
          : defaultMapFn(o)
      ))
      .filter((o) => o.isSelected)
      .map((o) => o.code);

    if (updatedOptions.length === 0) return acc;

    return [
      ...(acc || []),
      [code, updatedOptions]
    ];
  }, [] as IListingActiveFilters);
};

// Export

export default Filters;
