import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { formatPrice } from 'helpers/FormatHelpers';

import RecipeStat, { VARIANTS } from './RecipeStat';

import type { Variant } from './RecipeStat';

import type {
  IRecipeStat,
  RecipeStat as RecipeStatKey
} from 'types';

import styles from './RecipeStats.module.scss';

interface Props {
  className?: string,
  statClassName?: string,
  statIconClassName?: string,
  statLabelClassName?: string,
  statValueClassName?: string,
  stats?: IRecipeStat<RecipeStatKey, any>[],
  variant?: Variant
}

const RecipeStats = (props: Props) => {

  const {
    className,
    statClassName,
    statIconClassName,
    statLabelClassName,
    statValueClassName,
    stats,
    variant = VARIANTS.DEFAULT,
  } = props;

  // Empty

  if (isArrayEmpty(stats)) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[variant]]: !!variant },
        className
      )}
    >
      {
        stats?.map((stat) => {

          const details = getStatDetails(stat, variant);

          if (!stat) return null;

          const {
            key,
            icon,
            label,
            originalValue,
            originalValueLabel,
            value,
            valueLabel
          } = details || {};

          return (
            <RecipeStat
              key={key}
              //
              className={classnames(
                styles.stat,
                statClassName,
              )}
              iconClassName={classnames(
                styles.icon,
                statIconClassName
              )}
              labelClassName={classnames(
                styles.label,
                statLabelClassName
              )}
              valueClassName={classnames(
                styles.value,
                statValueClassName
              )}
              //
              icon={icon}
              label={label}
              originalValue={originalValue}
              originalValueLabel={originalValueLabel}
              value={value}
              valueLabel={valueLabel}
              variant={variant}
            />
          );
        })
      }
    </div>
  );
};

// Helpers

const getStatDetails = (stat: IRecipeStat<RecipeStatKey, any>, variant: Variant) => {
  const {
    key,
    value,
    originalValue,
  } = stat || {};

  const details = RECIPE_STATS_DETAILS_MAP[key];

  if (!details) return null;

  const {
    icon,
    label,
    originalValueLabel,
    valueLabel,
    formatter,
  } = details;

  return {
    key,
    //
    icon,
    label,
    //
    originalValue: (formatter && originalValue) ? formatter(originalValue) : originalValue,
    originalValueLabel,
    //
    value: formatter ? formatter(value) : value,
    valueLabel: typeof valueLabel === 'string' ? valueLabel : valueLabel(variant)
  };
};

// Constants

const RECIPE_STATS_DETAILS_MAP: Record<string, {
  icon: string,
  label: string,
  originalValueLabel?: string,
  formatter?: (v: any) => any,
  valueLabel: string | ((variant: Variant) => string)
}> = {
  preparationTime: {
    icon: 'slice',
    label: 'recipes:RECIPE_STATS.LABELS.PREPARATION_TIME',
    valueLabel: 'recipes:RECIPE_STATS.LABELS.PREPARATION_TIME_VALUE'
  },
  cookingTime: {
    icon: 'cooking',
    label: 'recipes:RECIPE_STATS.LABELS.COOKING_TIME',
    valueLabel: 'recipes:RECIPE_STATS.LABELS.COOKING_TIME_VALUE'
  },
  servings: {
    icon: 'tools-kitchen',
    label: 'recipes:RECIPE_STATS.LABELS.SERVINGS',
    valueLabel: (variant: Variant) => (variant === 'minimal' ? 'recipes:RECIPE_STATS.LABELS.SERVINGS_VALUE' : '')
  },
  totalTime: {
    icon: 'clock',
    label: 'recipes:RECIPE_STATS.LABELS.TOTAL_TIME',
    valueLabel: 'recipes:RECIPE_STATS.LABELS.TOTAL_TIME_VALUE'
  },
  pricePerServing: {
    icon: 'discount',
    label: 'recipes:RECIPE_STATS.LABELS.PRICE_PER_SERVING',
    originalValueLabel: 'recipes:RECIPE_STATS.LABELS.PRICE_VALUE',
    valueLabel: 'recipes:RECIPE_STATS.LABELS.PRICE_PER_SERVING_VALUE',
    formatter: formatPrice,
  },
  calories: {
    icon: 'flame',
    label: 'recipes:RECIPE_STATS.LABELS.CALORIES',
    valueLabel: 'recipes:RECIPE_STATS.LABELS.CALORIES_VALUE'
  },
};

// Export

export default RecipeStats;
