import React from 'react';
import classnames from 'classnames';

import * as Notifications from 'common/Notification';
import { Button, Icon } from 'components/ui';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useFavoriteProductsIds } from 'hooks/data/useFavoriteProductsIds';
import { useFavoriteProductsMutation } from 'hooks/data/useFavoriteProductsMutation';

import ProductToastBody from '../../ProductToastBody/ProductToastBody';

import type {
  IAnalyticsListProps,
  IOrder,
  IProduct,
  ValueOf
} from 'types';

import styles from './ProductFavoritesButton.module.scss';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
} as const;

type Size = ValueOf<typeof SIZES>

const COLORS = {
  GRAY: 'gray',
  PRIMARY: 'primary'
} as const;

type Color = ValueOf<typeof COLORS>

interface Props {
  className?: string,
  //
  size?: Size,
  color?: Color,
  //
  withBorder?: boolean,
  //
  order?: IOrder,
  product: IProduct,
  listProps?: IAnalyticsListProps
}

const ProductFavoritesButton = (props: Props) => {

  const {
    className,
    //
    size = SIZES.MEDIUM,
    color = COLORS.PRIMARY,
    //
    withBorder,
    //
    order,
    product,
    listProps
  } = props;

  // Props

  const isAuthenticated = !!order?.customer;

  // Hooks

  const { t } = useTranslation();

  const { add, remove } = useFavoriteProductsMutation();
  const { data: favorites } = useFavoriteProductsIds({ enabled: isAuthenticated });

  const ref = React.useRef<HTMLButtonElement>(null);

  // Props

  const isFavorite = React.useMemo(() => {
    return ProductHelpers.isFavorite(favorites, product);
  }, [favorites, product]);

  // Handlers

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      product,
      listProps,
      onError: () => {
        Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'favoritesMutationError' });
      },
      onSuccess: () => {
        const message = !isFavorite ? t('NOTIFICATIONS.FAVORITES_ADDED') : t('NOTIFICATIONS.FAVORITES_REMOVED');
        ProductHelpers.showFavoritesNotification({
          component: ProductToastBody,
          product: { name: product?.name, image: product?.image?.thumbnail },
          message
        });
      }
    };

    if (isFavorite) {
      remove(payload);
    } else {
      add(payload);
    }

    ref?.current?.blur();
  };

  // Empty

  if (!isAuthenticated) return null;

  // Render

  return (
    <Button
      ref={ref}
      className={classnames(
        styles.root,
        { [styles[size]]: size },
        { [styles[color]]: color },
        { [styles.bordered]: withBorder },
        { [styles.isFavorite]: isFavorite },
        className
      )}
      variant="secondary"
      icon={FavoritesIcon}
      onClick={onClick}
      //
      aria-label={isFavorite ? t('PRODUCT.REMOVE_FROM_FAVORITES') : t('PRODUCT.ADD_TO_FAVORITES')}
    />
  );
};

// Icons

const FavoritesIcon = <Icon size={22} className={styles.icon} name="heart" />;

// Export

export default ProductFavoritesButton;
