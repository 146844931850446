import * as ErrorHandler from 'common/ErrorHandler';
import { Api } from 'common/http';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';

import type { IProductsListing, ISearchQueryParams, ISearchSuggestions } from 'types';

export async function searchProducts(keyword: string | null, options: ISearchQueryParams) {
  try {
    const {
      page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, filters, suggestions
    } = options || {};
    const orderByQs = UrlParams.getOrderByQueryString(orderBy);
    const filtersQs = UrlParams.getFiltersQueryString(filters);
    const suggestionsQs = suggestions ? '&suggestions=1' : '';
    const url = `/search/${encodeURIComponent(keyword || '')}?page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}${suggestionsQs}`;
    const { data } = await Api.get<IProductsListing>(url);
    return data;
  } catch (err) {
    ErrorHandler.throwError(err, 'Error while getting search results');
  }
}

export async function getSearchSuggestions(keyword: string) {
  const { data } = await Api.get<ISearchSuggestions>(`/suggestions/${encodeURIComponent(keyword)}`);
  return data;
}
