import qsParse from 'qs/lib/parse';

function parse(s: string): any {
  return qsParse(s);
}

function stringify(data: any, options: { arrayFormat?: 'indices' | 'brackets' | 'repeat', skipNulls?: boolean } = {}): string {
  const pairs: string[] = [];
  const { arrayFormat = 'indices', skipNulls = true } = options;

  const encode = (value: any) => encodeURIComponent(String(value));

  const addPair = (key: string, value: any) => {
    if (skipNulls && (value === null || value === undefined)) {
      return;
    }
    pairs.push(`${encode(key)}=${encode(value)}`);
  };

  const handleArray = (value: any[], parent: string) => {
    value.forEach((item, index) => {
      const key = arrayFormat === 'indices' ? `${parent}[${index}]`
        : arrayFormat === 'brackets' ? `${parent}[]`
          : `${parent}`;
      serialize(item, key);
    });
  };

  const handleObject = (obj: any, parent?: string) => {
    Object.entries(obj).forEach(([key, value]) => {
      const fullKey = parent ? `${parent}[${key}]` : key;
      serialize(value, fullKey);
    });
  };

  const serialize = (value: any, currentKey: string) => {
    if (Array.isArray(value)) {
      handleArray(value, currentKey);
    } else if (typeof value === 'object' && value !== null) {
      handleObject(value, currentKey);
    } else if (!(skipNulls && (value === null || value === undefined))) {
      addPair(currentKey, value);
    }
  };

  serialize(data, '');

  return pairs.join('&');
}

export default {
  parse,
  stringify
};
