import React from 'react';
import classnames from 'classnames';

import { GroupedProductList, ProductCartCard } from 'components/product';

import type { IAnalyticsListProps, IOrder, IOrderProduct } from 'types';

import styles from './CartProductsList.module.scss';

interface Props {
  className?: string,
  titleClassName?: string,
  productClassName?: string,
  title?: string,
  groupByCategory?: boolean,
  order: IOrder,
  products: IOrderProduct[],
  productListProps: IAnalyticsListProps
}

const CartProductsList = (props: Props) => {

  const {
    className,
    titleClassName,
    productClassName,
    title,
    groupByCategory,
    order,
    products = [],
    productListProps = {},
  } = props;

  // Props

  const { currency } = order || {};

  // Empty

  if (products?.length === 0) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      {
        title && (
          <div className={classnames(styles.title, titleClassName)}>
            {title}
          </div>
        )
      }

      {/* List */}
      <div className={styles.list}>
        <GroupedProductList
          groupClassName={styles.group}
          groupHeaderClassName={styles.groupHeader}
          groupTitleClassName={styles.groupTitle}
          groupIconClassName={styles.groupIcon}
          groupItemClassName={classnames(styles.item, productClassName)}
          products={products}
          withoutGrouping={!groupByCategory}
          renderProduct={(product) => {
            return (
              <ProductCartCard
                order={order}
                product={product as IOrderProduct}
                currency={currency}
                listProps={productListProps}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default CartProductsList;
