import React from 'react';
import classnames from 'classnames';

import { sortByKey } from 'helpers/ArrayHelpers';
import { createObjectMap } from 'helpers/ObjectHelpers';

import List from './List';

import type { IListItem } from './List';

import type { IGroupHeaderIcons, IGroups } from 'types';

import styles from './GroupedList.module.scss';

export interface IGroupedListSlot {
  position: number
}

interface Props<I extends IListItem<I>, S extends IGroupedListSlot> {
  //
  className?: string,
  groupClassName?: string,
  groupHeaderClassName?: string,
  groupTitleClassName?: string,
  groupIconClassName?: string,
  groupListClassName?: string,
  groupItemClassName?: string,
  slotClassName?: string,
  //
  withBorders?: boolean,
  //
  items: I[],
  slots?: S[],
  icons?: IGroupHeaderIcons,
  //
  groupItems: (items: I[]) => IGroups<I>,
  //
  getItemKey: (item: I) => string | number,
  renderItem: (item: I) => React.ReactNode,
  renderSlot?: (slot: S) => React.ReactNode
}

function GroupedList<I extends IListItem<I>, S extends IGroupedListSlot>(props: Props<I, S>) {
  const {
    //
    className,
    groupClassName,
    groupHeaderClassName,
    groupTitleClassName,
    groupIconClassName,
    groupListClassName,
    groupItemClassName,
    slotClassName,
    //
    withBorders,
    //
    items,
    slots = [],
    icons,
    //
    groupItems,
    //
    getItemKey,
    renderSlot,
    renderItem,
  } = props;

  // Props

  // --- Groups

  const groups: IGroups<I> = React.useMemo(() => {
    return groupItems(items);
  }, [items]);

  // --- Slot Map

  const slotMap: { [position: string]: S[] } = React.useMemo(() => {
    return createObjectMap(slots, 'position', true);
  }, [slots]);

  // --- Appended Slots

  const appendedSlots: S[] = React.useMemo(() => {
    return sortByKey(
      slots.filter((slot) => slot?.position >= groups.length),
      'position'
    );
  }, [slots, groups.length]);

  // Helpers

  const getGroupIcon = React.useCallback((name) => {
    return icons[name] || null;
  }, [icons]);

  // Empty

  if (items.length === 0 || groups.length === 0) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Groups */}
      {
        groups.map((group, position) => {

          // Empty

          if (!group || !group?.items?.length) return null;

          // Props

          const {
            groupClassName: currentGroupClassName,
            name: title,
            icon,
          } = group;

          // Image

          const image = getGroupIcon(title);

          // Slots

          const currentSlots = slotMap[position];

          // Group Render

          return (
            <React.Fragment key={title || position}>

              {/* Slot */}
              {
                currentSlots && currentSlots.map((slot, index) => (
                  <div key={`slot-${position}-${index}`} className={classnames(styles.slot, slotClassName)}>
                    {renderSlot(slot)}
                  </div>
                ))
              }

              {/* Group */}
              <div
                className={classnames(
                  styles.group,
                  { [styles.bordered]: withBorders },
                  groupClassName,
                  currentGroupClassName
                )}
              >

                {/* List */}
                <List<I>
                  className={groupListClassName}
                  headerClassName={groupHeaderClassName}
                  headerIconClassName={groupIconClassName}
                  headerTitleClassName={groupTitleClassName}
                  itemClassName={groupItemClassName}
                  withBorders={withBorders}
                  items={group?.items}
                  header={{
                    title,
                    icon,
                    image,
                  }}
                  getItemKey={getItemKey}
                  renderItem={renderItem}
                />

              </div>

            </React.Fragment>
          );

        })
      }

      {/* Appended Slots */}
      {
        appendedSlots.map((slot, index) => {
          return (
            <div
              key={`appended-slot-${index}`}
              className={classnames(styles.slot, slotClassName)}
            >
              {renderSlot(slot)}
            </div>
          );
        })
      }

    </div>
  );
}

// Export

export default GroupedList;
