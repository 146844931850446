import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { IAnalyticsListProps, IProduct } from 'types';

const CATEGORY = 'sponsored_product_events';

enum Actions {
  VIEW_PRODUCT = 'view_product',
  SELECT_PRODUCT = 'select_product',
  ADD_PRODUCT = 'add_product',
}

enum Params {
  BILLED = 'billed',
  INTERNAL = 'internal'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- View Sponsored Product

export function viewSponsoredProduct(sku: IProduct['sku'], position: number, isBilled?: boolean, listProps?: IAnalyticsListProps) {
  const { analyticsListId } = listProps || {};
  push(Actions.VIEW_PRODUCT, sku, {
    param1: position,
    param2: analyticsListId,
    param3: isBilled ? Params.BILLED : Params.INTERNAL,
  });
}

// --- Select Sponsored Product

export function selectSponsoredProduct(sku: IProduct['sku'], position: number, isBilled?: boolean, listProps?: IAnalyticsListProps) {
  const { analyticsListId } = listProps || {};
  push(Actions.SELECT_PRODUCT, sku, {
    param1: position,
    param2: analyticsListId,
    param3: isBilled ? Params.BILLED : Params.INTERNAL,
  });
}

// --- Add Sponsored Product

export function addSponsoredProduct(sku: IProduct['sku'], position: number, isBilled?: boolean, listProps?: IAnalyticsListProps) {
  const { analyticsListId } = listProps || {};
  push(Actions.ADD_PRODUCT, sku, {
    param1: position,
    param2: analyticsListId,
    param3: isBilled ? Params.BILLED : Params.INTERNAL,
  });
}
