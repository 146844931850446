import React from 'react';
import { useRouter } from 'next/router';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { Alert, GeniusBadge } from 'components/ui';
import { QUERY_PARAMS } from 'constants/query-params';
import { addQueryParams } from 'helpers/UrlHelpers';
import { useGeniusBenefits } from 'hooks/data/useGeniusBenefits';
import { useGeniusStatusListener } from 'hooks/data/useGeniusStatusListener';
import { useAnalytics } from 'hooks/useAnalytics';

import styles from './GeniusFastCheckoutBanner.module.scss';

interface Props {
  className?: string
}

const STATUS = {
  ELIGIBLE: 'eligible',
  INELIGIBLE: 'nonEligible',
  PENDING: 'pending'
};

const GeniusFastCheckoutBanner = (props: Props) => {

  // Props

  const {
    className
  } = props;

  // Hooks

  const router = useRouter();
  const analytics = useAnalytics();

  // Data Hooks

  const {
    data: benefits, isLoading, isError, refresh
  } = useGeniusBenefits({
    shouldRefetch: true
  });

  // Props

  const {
    status, titleHtml, subtitleHtml, title, subtitle
  } = benefits || {};

  // Open SSE connection if status is pending  and refresh benefits on message received

  useGeniusStatusListener({
    enabled: status === STATUS.PENDING,
    onMessage: () => {
      refresh({ shouldInvalidate: true });
    }
  });

  // Observer

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      analytics.viewGeniusFastCheckoutBanner(status);
      unobserve();
    }
  });

  // Handlers

  const handleClick = () => {
    analytics.selectGeniusFastCheckoutBanner();
    addQueryParams(router, { [QUERY_PARAMS.GENIUS_FAST_CHECKOUT]: true });
  };

  // Props

  const isClickable = status === STATUS.ELIGIBLE;

  // Empty

  if (
    isLoading
    || isError
    || !benefits
    || status === STATUS.INELIGIBLE
  ) return null;

  // Render

  return (
    <Alert
      ref={observe}
      className={classnames(styles.root, className)}
      titleClassName={styles.title}
      iconClassName={styles.icon}
      descriptionClassName={styles.description}
      //
      variant="genius"
      //
      title={titleHtml || title}
      icon={<GeniusBadge withIcon />}
      description={subtitleHtml || subtitle}
      //
      onClick={
        isClickable
          ? handleClick
          : undefined
      }
    />
  );
};

export default React.memo(GeniusFastCheckoutBanner);
