import { isMobile } from 'react-device-detect';

import { Api, SecureApi } from 'common/http';
import { CONFIG } from 'constants/config';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { toOrderLoyalty } from 'services/OrdersServiceAdapters';

import type {
  IAnalyticsListProps,
  IItemsListing,
  IOrder,
  IOrderBenefits,
  IOrderLoyalty,
  IOrderProduct,
  IPaymentMethod,
  IProduct,
  Nullable
} from 'types';

export const getOrder = async (id: string) => {
  const { data } = await SecureApi.get(`myaccount_orders/${id}`);
  return data;
};

export const getActiveOrder = async (accessToken?: string) => {
  const unauthenticatedOrderTokenValue = OrderHelpers.getUnauthenticatedOrder();

  // No Order

  if (!unauthenticatedOrderTokenValue && !accessToken) {
    return new Promise((resolve) => resolve({
      items: [],
      isNotCreated: true
    }));
  }

  try {

    // Authenticated Order

    if (!unauthenticatedOrderTokenValue && accessToken) {
      const { data } = await SecureApi.post('/orders', {});
      return data;
    }

    // Unauthenticated Order

    try {
      const { data } = await Api.get(`/orders/${unauthenticatedOrderTokenValue}`);
      return data;
    } catch (err) {
      console.error('Error while getting current unauthenticated order', err);
      OrderHelpers.removeUnauthenticatedOrder();
    }

  } catch (err) {
    console.error('Error while getting current order', err);
    throw new Error(err);
  }
};

export const getOrders = async (options?: {page?: number | string, itemsPerPage?: number | string, searchQuery?: string}) => {
  const {
    page = 1, itemsPerPage = CONFIG.PAGINATION.ORDER_HISTORY, searchQuery
  } = options || {};

  const searchQs = !searchQuery
    ? ''
    : `&q=${encodeURIComponent(searchQuery)}`;

  const { data } = await SecureApi.get<IItemsListing<IOrder>>(`/orders?page=${page}&itemsPerPage=${itemsPerPage}${searchQs}`);
  return data;
};

// --- Get Order Gifts

export const getOrderGifts = async (id: string) => {
  const { data } = await SecureApi.get(`/orders/${id}/gifts`);
  return data;
};

// --- Add / Remove Order Gifts

export const addOrderGift = async (tokenValue: IOrder['tokenValue'], variantCode: string) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/gifts`, {
    add: [variantCode],
    remove: []
  });
  return data;
};

export const removeOrderGift = async (tokenValue: IOrder['tokenValue'], variantCode: string) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/gifts`, {
    add: [],
    remove: [variantCode]
  });
  return data;
};

// --- Add To Order

export const getAddToOrders = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/add-to`, {});
  return data;
};

// --- Add / Assign Order

export const createOrder = async () => {
  const { data } = await Api.post('/orders', {});
  OrderHelpers.storeUnauthenticatedOrder(data.tokenValue);
  return data;
};

export const assignOrder = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/assign-customer`, {});
  OrderHelpers.removeUnauthenticatedOrder();
  return data;
};

// --- Add To Order

export const addToOrder = async (tokenValue: IOrder['tokenValue'], addTo: string) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/add-to`, { addTo });
  return data;
};

// --- Complete Order

export const completeOrder = async (tokenValue: IOrder['tokenValue'], notes = '') => {
  const options = {
    headers: {
      'X-User-IsMobile': isMobile.toString()
    },
    timeout: 60 * 1000 // 60 seconds
  };
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/complete`, { notes }, options);
  return data;
};

// --- Repeat Order

export const repeatOrder = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/duplicate`, {});
  return data;
};

// --- Cancel Order

export const getOrderCancellationReasons = async () => {
  const { data } = await SecureApi.get('/order-cancellation-reasons');
  return data;
};

export const cancelOrder = async (tokenValue: IOrder['tokenValue'], reason: string) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/cancel`, { reason });
  return data;
};

// --- Delete order

export const deleteOrder = async (tokenValue: IOrder['tokenValue']) => {
  await SecureApi.delete(`/orders/${tokenValue}`);
};

// --- Report a problem

export const reportProblem = async (tokenValue: IOrder['tokenValue'], payload: any) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/problems`, { ...payload });
  return data;
};

// --- Feedback

export const sendOrderFeedback = async (tokenValue: IOrder['tokenValue'], rating: string, extraFields: any, submitted = false) => {
  const { data } = await SecureApi.post('/order-feedback', {
    orderToken: tokenValue,
    rating: rating ? parseInt(rating, 10) : 0,
    formSubmitted: submitted,
    ...extraFields
  });
  return data;
};

// --- Invoices

export const downloadInvoice = async (tokenValue: IOrder['tokenValue'], url: string) => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/download/invoice/${url}`);
  return data;
};

// --- Products

export const addProduct = async (
  order: IOrder,
  product?: IProduct | IOrderProduct,
  replaceProductVariantCode?: IProduct['variantCode'],
  quantity = 1,
  listProps?: IAnalyticsListProps
) => {
  if (!order || !product) return;

  const { tokenValue } = order;
  const { variantCode } = product;
  const { analyticsListId, analyticsListName } = listProps || {};

  const params = {
    productVariant: `/api/v2/shop/product-variants/${variantCode}`,
    analyticsListId,
    analyticsListName,
    quantity,
    ...(
      replaceProductVariantCode
        ? { replaceProductVariant: replaceProductVariantCode }
        : {}
    )
  };
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/items`, params);
  return data;
};

export const bulkAddProducts = async (order: IOrder, payload: {quantity: number, product: IProduct}[], listProps: IAnalyticsListProps) => {
  const { tokenValue } = order;

  const { analyticsListId, analyticsListName } = listProps || {};
  const hasBothProps = analyticsListId && analyticsListName;

  const analytics = hasBothProps ? { analyticsListId, analyticsListName } : {};

  const items = payload.map((products) => {
    const { quantity, product } = products || {};
    const { variantCode } = product || {};

    return {
      variantCode,
      quantity
    };
  });

  const params = { items, ...analytics };

  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/multiple-items`, params);
  return data;
};

export const editProduct = async (order: IOrder, orderItemId?: IOrderProduct['id'], quantity?: number) => {
  if (!order || !orderItemId || !quantity) return;

  const { tokenValue } = order;
  const params = { quantity };

  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/items/${orderItemId}`, params);
  return data;
};

export const removeProduct = async (order: IOrder, orderItemId?: IOrderProduct['id']) => {
  if (!order || !orderItemId) return;

  const { tokenValue } = order;
  const { data } = await SecureApi.delete(`/orders/${tokenValue}/items/${orderItemId}`);
  return data;
};

export const setProductComment = async (order: IOrder, orderItemId: IOrderProduct['id'], comment?: string) => {
  const { tokenValue } = order;
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/items/${orderItemId}/comment`, { comment });
  return data;
};

// --- Addresses

export const setShippingAddress = async (tokenValue: IOrder['tokenValue'], shippingAddressId: string | number) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/set-shipping-address`, { shippingAddressId }, {});
  return data;
};

export const setBillingAddress = async (tokenValue: IOrder['tokenValue'], billingAddressId: string | number) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/set-billing-address`, { billingAddressId }, {});
  return data;
};

// --- Intervals

export const getIntervalsByOrder = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/delivery-intervals-v2`);
  return data;
};

export const setInterval = async (tokenValue: IOrder['tokenValue'], scheduleId: string | number) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/set-delivery-interval`, { scheduleId });
  return data;
};

// --- Payments

export const getPaymentMethods = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/payment-methods`);
  return data;
};

export const setMoneyPaymentMethod = async (tokenValue: IOrder['tokenValue'], paymentMethod: string, paymentId: number) => {
  const { data } = await SecureApi.mergePatch(
      `/orders/${tokenValue}/payments/${paymentId}`,
      { paymentMethod }
  );
  return data;
};

export const setMealVouchersPaymentMethod = async (tokenValue: IOrder['tokenValue'], paymentCode: IPaymentMethod['code']) => {
  const { data } = await SecureApi.mergePatch(
      `/orders/${tokenValue}/use-meal-vouchers`,
      { paymentCode }
  );
  return data;
};

export const getRetryPaymentLink = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/retry-payment-url`);
  return data;
};

// --- Coupons

export const applyCoupon = async (tokenValue: IOrder['tokenValue'], couponCode: string) => {
  const { data } = await SecureApi.mergePatch<IOrder>(`/orders/${tokenValue}/apply-coupon`, { couponCode });
  return data;
};

export const removeCoupon = async (tokenValue: IOrder['tokenValue']) => {
  const { data } = await SecureApi.mergePatch<IOrder>(`/orders/${tokenValue}/remove-discount/voucher`, {});
  return data;
};

// --- Age Check

export const setAgeCheck = async (tokenValue: IOrder['tokenValue'], ageCheckDone: boolean) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/age-check-confirmation`, { ageCheckDone });
  return data;
};

// --- Tips

export const setTip = async (tokenValue: IOrder['tokenValue'], tip?: Nullable<number>) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/tips`, { tip });
  return data;
};

// --- Summary

export const deleteSummaryItem = async (path: string) => {
  const { data } = await SecureApi.mergePatch(`/${path}`, {});
  return data;
};

// --- Order benefits

export const getOrderBenefits = async (tokenValue: string) => {
  const { data } = await SecureApi.get<IOrderBenefits>(`/orders/${tokenValue}/benefits`);
  return data;
};

export const refreshOrderBenefits = async (tokenValue: string) => {
  const { data } = await SecureApi.get<IOrderBenefits>(`/orders/${tokenValue}/refresh-benefits`);
  return data;
};

// ---- Order Loyalty

export const getOrderLoyalty = async (tokenValue: string): Promise<IOrderLoyalty> => {
  const { data } = await SecureApi.get(`/orders/${tokenValue}/loyalty`);
  const orderLoyalty = toOrderLoyalty(data);
  return orderLoyalty;
};

export const setLoyaltyPointsUsed = async (tokenValue: string, points: number) => {
  const { data } = await SecureApi.mergePatch(`/orders/${tokenValue}/loyalty`, { points });
  return data;
};
