import React from 'react';
import { useRouter } from 'next/router';

import { APP_ROUTES } from 'constants/routes';

const RESTORE_ON_PAGES = [
  APP_ROUTES.CATEGORIES_FILTERED,
  APP_ROUTES.PROMOTIONS_FILTERED,
  APP_ROUTES.NEW_PRODUCTS_FILTERED,
];

export function useProductModalScrollRestoration() {
  const router = useRouter();
  const prevRoute = React.useRef<string>(null);
  const scrollPosition = React.useRef<{x: number, y: number}>(null);

  React.useEffect(() => {

    const onRouteChangeComplete = (url: string) => {
      if (prevRoute.current?.startsWith(APP_ROUTES.PRODUCTS)) {
        const shouldRestoreScroll = RESTORE_ON_PAGES.some((page) => url.startsWith(page));
        if (shouldRestoreScroll && scrollPosition.current) {
          const { x, y } = scrollPosition.current;
          window.scrollTo(x, y);
        }
      }
      scrollPosition.current = { x: window.scrollX, y: window.scrollY };
      prevRoute.current = url;
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };

  }, [router.events]);
}
