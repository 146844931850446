/* eslint-disable dot-notation */

import { CONFIG } from 'constants/config';
import { USER_ACCOUNT_TYPES } from 'constants/user';
import { isClient } from 'helpers/BrowserHelpers';

import type {
  ICategory, ICategoryProductsListing, IOrder, IProduct, IUser
} from 'types';

// Constants

enum Events {
  SET_ORG_ID = 'setOrgId',
  SET_USER_INFO = 'setUserInfo',
  TRACK_CART = 'trackCart',
  TRACK_PAGE_VIEW = 'trackPageView',
  TRACK_CONVERSION = 'trackConversion',
}

// Push

export function isEnabled() {
  return CONFIG.SALESFORCE.EINSTEIN.IS_ENABLED;
}

export function pushEinsteinEvent(key: Events, value: any) {
  if (
    !isEnabled()
    || !isClient()
    || !window['_etmc']
    || !window['_etmc']['push']
  ) return;

  window['_etmc'].push([key, value]);
}

// Trackers

// --- Track Product Page View

export function trackProductView(user: IUser, product: IProduct) {
  if (!user) return;
  setOrgId();
  setUserInfo(user);
  const { sku } = product || {};
  pushEinsteinEvent(Events.TRACK_PAGE_VIEW, { item: sku });
}

// --- Track Search Page View

export function trackSearchView(user: IUser, searchQuery: string) {
  setOrgId();
  setUserInfo(user);
  pushEinsteinEvent(Events.TRACK_PAGE_VIEW, { search: searchQuery });
}

// --- Track Category Page View

export function trackCategoryView(user: IUser, category: ICategory | ICategoryProductsListing['payload']) {
  setOrgId();
  setUserInfo(user);
  const { slug } = category || {};
  pushEinsteinEvent(Events.TRACK_PAGE_VIEW, { category: slug });
}

// --- Track Cart

export function trackCart(user: IUser, order: IOrder) {
  setOrgId();
  setUserInfo(user);
  const cart = getCart(order);
  pushEinsteinEvent(Events.TRACK_CART, { cart });
}

// --- Track Clear Cart

export function trackClearCart(user: IUser) {
  setOrgId();
  setUserInfo(user);
  pushEinsteinEvent(Events.TRACK_CART, { clear_cart: true });
}

// --- Track Purchase

export function trackPurchase(user: IUser, order: IOrder, shippingTier: string) {
  setOrgId();
  setUserInfo(user);
  const purchase = getPurchase(order, shippingTier);
  pushEinsteinEvent(Events.TRACK_CONVERSION, purchase);
}

// Helpers

// --- Set Org Id

export function setOrgId() {
  const orgId = getOrgId();
  if (!orgId) return;
  pushEinsteinEvent(Events.SET_ORG_ID, orgId);
}

// --- Set User Info

export function setUserInfo(user: IUser) {
  if (!user) return;
  const userInfo = getUserInfo(user);
  pushEinsteinEvent(Events.SET_USER_INFO, userInfo);
}

// Helpers

// --- Get Org ID

function getOrgId() {
  return CONFIG.SALESFORCE.EINSTEIN.ORG_ID;
}

// --- Get User Info

function getUserInfo(user: IUser) {
  const { id, isGeniusUser } = user || {};
  return {
    email: `customer${id}`,
    account_type: isGeniusUser
      ? USER_ACCOUNT_TYPES.GENIUS
      : USER_ACCOUNT_TYPES.STANDARD
  };
}

// --- Get Cart

function getCart(order: IOrder) {
  return order?.items?.map((item) => {
    const {
      sku,
      total,
      quantity,
    } = item || {};
    return {
      unique_id: sku,
      item: sku,
      quantity,
      price: total,
    };
  });
}

// --- Get Purchase

function getPurchase(order: IOrder, shippingTier: string) {
  const { tokenValue, couponInfo, shippingTotal } = order || {};
  const { totalDiscount } = couponInfo || {};
  return {
    order_number: tokenValue,
    cart: getCart(order),
    discount: totalDiscount ? -totalDiscount : 0,
    shipping: shippingTotal,
    shipping_tier: shippingTier,
  };
}
