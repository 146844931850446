import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Cart, CartTitle } from 'components/cart';
import { Drawer } from 'components/ui';
import { useDrawerDispatchHelpers, useDrawerStore } from 'context/DrawerContext';
import { DRAWER_TYPES } from 'helpers/LayoutHelpers';
import { useCategories } from 'hooks/data/useCategories';

import MenuMobile from '../Header/HeaderMobile/MenuMobile';

import styles from './DrawerContainer.module.scss';

const POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right'
};

const DrawerContainer = () => {

  // Hooks
  const router = useRouter();

  // Drawer Hooks

  const {
    visible: isOpen,
    type,
    drawerProps,
    componentProps
  } = useDrawerStore();

  const { hideDrawer } = useDrawerDispatchHelpers();

  const {
    headerClassName,
    className,
    position,
    handleClose
  } = drawerProps;

  // Data Hooks

  const {
    isLoading: isLoadingCategories, data: categories = []
  } = useCategories();

  // Props

  // Handlers

  const handleRouteChange = () => {
    hideDrawer();
  };

  // Effects

  React.useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <>
      {/* Main Menu - Mobile */}
      <Drawer
        persist
        isOpen={isOpen && type === DRAWER_TYPES.MENU_MOBILE}
        //
        className={className}
        //
        title="HEADER.MOBILE.MAIN_MENU"
        position={position || POSITIONS.LEFT}
        breakpoint="lg"
        desktopProps={{
          hidden: true,
        }}
        headerProps={{
          className: headerClassName
        }}
        //
        handleClose={handleClose}
      >
        <MenuMobile
          isLoading={isLoadingCategories}
          categories={categories}
          isOpen={isOpen && type === DRAWER_TYPES.MENU_MOBILE}
          {...componentProps}
        />
      </Drawer>

      {/* Cart */}
      <Drawer
        persist
        isOpen={isOpen && type === DRAWER_TYPES.CART}
        //
        className={classnames(styles.drawer, styles.cart, className)}
        bodyClassName={styles.body}
        //
        title={(
          <CartTitle
            title="CART.TITLE"
            withPadding={false}
            withBorder={false}
          />
        )}
        position={position || POSITIONS.RIGHT}
        headerProps={{
          withBorder: true,
          className: headerClassName
        }}
        //
        handleClose={handleClose}
      >
        <Cart
          {...componentProps}
        />
      </Drawer>
    </>
  );
};

export default DrawerContainer;
