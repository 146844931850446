import React, { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { ImageMessage, Loader, Radio } from 'components/ui';
import { IMAGES } from 'constants/images';
import { useFade } from 'hooks/useFade';

import styles from './AddressStreetAutocomplete.module.scss';

const AutocompleteResults = forwardRef((props, ref) => {
  const {
    isVisible,
    isLoading,
    results,
    selectedResult,
    onPlaceSelected = () => {},
  } = props;

  const [areResultsVisible, setResultsVisible, fadeProps] = useFade();

  React.useEffect(() => {
    setResultsVisible(isVisible);
  }, [isVisible]);

  if (!areResultsVisible) return null;

  const hasResults = results?.length > 0;

  const classes = classnames(
    styles.resultsRoot,
    { [styles.noResults]: !hasResults }
  );

  return (
    <div
      ref={ref}
      className={classes}
      {...fadeProps}
      data-testid="address-form.search.autocomplete-results"
    >
      {
        isLoading || !results
          ? (
            <Loader
              size={24}
              lineWeight={2}
            />
          ) : hasResults
            ? (
              <Results
                results={results}
                selectedId={selectedResult}
                onSelect={onPlaceSelected}
              />
            )
            : <EmptyState />
      }
    </div>
  );
});

const Results = (props) => {
  const {
    results,
    selectedId,
    onSelect
  } = props;

  return (
    <>
      {
        results?.map((place, index) => {
          const {
            placeId: id, label: title, subLabel: subtitle
          } = place;

          const value = `${title} - ${subtitle}`;

          return (
            <Radio
              key={id}
              className={styles.result}
              name="autocomplete-place"
              value={value}
              autofocus
              withoutIcon
              withHover
              checked={selectedId === id}
              onChange={(e) => {
                onSelect(place, e);
              }}
              // dataTestId={`address-autocomplete-result-${index}`}
            >
              <div className={styles.resultTitle}>{title}</div>
              <div className={styles.resultSubtitle}>{subtitle}</div>
            </Radio>
          );
        })
      }
    </>
  );
};

const EmptyState = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <ImageMessage
        className={styles.emptyContainer}
        image={IMAGES.ADDRESS.NO_RESULTS}
        imageProps={{
          next: false,
          width: 64,
          height: 64,
          alt: 'No results found'
        }}
        imageContainerClassName={styles.noResultsImage}
        title={t('ADDRESS.NO_RESULTS')}
        titleClassName={styles.noResultsTitle}
        subtitle={t('ADDRESS.NO_RESULTS_CHECK')}
        subtitleClassName={styles.noResultsSubtitle}
      />
    </>
  );
};

export default AutocompleteResults;
