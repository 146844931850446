import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ModalActions } from 'components/ui';
import { ERROR_CODES } from 'constants/errors';
import { handleValidationError } from 'helpers/FormHelpers';
import { isEmptyObject } from 'helpers/utils';
import { useAddressMutation } from 'hooks/data/useAddressMutation';

import { FIELDS, VALIDATIONS } from './AddressFormConfig';
import AddressFormDetails from './AddressFormDetails';

import styles from './AddressEditForm.module.scss';

const {
  STREET_ADDRESS, CITY, PROVINCE, GEOLOCATION, CONTACT_NAME, CONTACT_PHONE
} = FIELDS;

const AddressEditForm = (props) => {

  const {
    className,
    address = {},
    onSuccess = () => {},
    onError = () => {},
    onCancel = () => {},
  } = props;

  const { t } = useTranslation();

  const { isLoading: isMutationLoading, edit: editAddress } = useAddressMutation();

  // Formik Props

  const initialValues = {
    [STREET_ADDRESS]: address?.streetAddress || '',
    [CITY]: address?.city || '',
    [PROVINCE]: address?.province || '',
    [CONTACT_NAME]: address?.contactName || '',
    [CONTACT_PHONE]: address?.contactPhonenumber || '',
    [GEOLOCATION]: address?.geoLocation
      ? { lat: address?.geoLocation.lat, lng: address?.geoLocation.long }
      : ''
  };

  const validationSchema = React.useMemo(() => yup.object().shape({
    [CONTACT_NAME]: VALIDATIONS[CONTACT_NAME]({ t }),
    [CONTACT_PHONE]: VALIDATIONS[CONTACT_PHONE]({ t }),
  }), [t]);

  // Handlers

  const onSubmit = async (values, actions) => {
    const { setSubmitting } = actions;

    const {
      [CONTACT_NAME]: contactName,
      [CONTACT_PHONE]: contactPhoneNumber
    } = values;

    setSubmitting(true);

    editAddress({
      addressToken: address?.token,
      fields: {
        contactName,
        contactPhonenumber: contactPhoneNumber,
      },
      onSuccess: (data) => {
        onSuccess(values, data);
      },
      onError: (err) => {
        if (err?.response?.status === ERROR_CODES.VALIDATION_ERROR) {
          handleValidationError(err?.response?.data, actions);
        } else {
          onError(values);
          console.error('Error while submitting address form', err);
        }
      },
      onSettled: () => {
        setSubmitting(false);
      }
    });
  };

  // Empty state

  if (isEmptyObject(address)) {
    return null;
  }

  return (
    <div className={classnames(styles.root, className)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          return (
            <Form className={styles.form}>
              <AddressFormDetails
                form={formikProps}
                address={address}
                isEditMode
                renderActions={(form) => {
                  const { handleSubmit, isValid, isSubmitting } = form;
                  return (
                    <ModalActions
                      className={styles.actions}
                      btnClassName={styles.submitButton}
                      primary={{
                        text: t('ADDRESS.EDIT_ADDRESS'),
                        disabled: isMutationLoading || !isValid,
                        loading: isMutationLoading || isSubmitting,
                        onClick: () => {
                          handleSubmit();
                        },
                        dataTestId: 'address-form-edit.details.submit'
                      }}
                      secondary={{
                        text: t('ACTIONS.CANCEL'),
                        onClick: () => {
                          onCancel();
                        }
                      }}
                    />
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddressEditForm.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  address: PropTypes.shape({}),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AddressEditForm;
