import type { CSSProperties } from 'react';
import React from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import styles from './Fade.module.scss';

interface Props {
  className?: string,
  //
  isVisible: boolean,
  persist?: boolean,
  lazyLoad?: boolean,
  //
  enterDuration?: number,
  enterDelay?: number,
  exitDuration?: number,
  exitDelay?: number,
  yOffset?: number,
  //
  enterScale?: number,
  exitScale?: number,
  //
  withDynamicChildren?: boolean,
  children?: React.ReactNode
}

const Fade = (props: Props) => {

  const {
    className,
    //
    isVisible,
    persist,
    lazyLoad,
    //
    enterDuration = 400,
    enterDelay = 0,
    exitDuration = 300,
    exitDelay = 0,
    yOffset = 0,
    //
    enterScale,
    exitScale,
    //
    withDynamicChildren,
    children
  } = props;

  const yOffsetValue = yOffset ? `${yOffset}px` : undefined;

  // Refs

  const transitionRef = React.useRef(null);

  // Hooks

  const [content, setContent] = React.useState(null);

  // Effects

  React.useEffect(() => {
    if (isVisible) {
      setContent(children);
    }
  }, [
    isVisible,
    children,
  ]);

  // Render

  return (
    <Transition
      in={isVisible}
      nodeRef={transitionRef}
      timeout={{
        enter: enterDuration + enterDelay,
        exit: exitDuration + exitDelay,
      }}
      mountOnEnter={!persist || lazyLoad}
      unmountOnExit={!persist && !lazyLoad}
    >
      {
        (state) => {
          return (
            <div
              ref={transitionRef}
              className={
                classnames(
                  styles.root,
                  { [styles[state]]: state },
                  { [styles.withScaleDown]: enterScale || exitScale },
                  className
                )
              }
              style={{
                '--enterDuration': `${enterDuration / 1000}s`,
                '--enterDelay': `${enterDelay / 1000}s`,
                '--exitDuration': `${exitDuration / 1000}s`,
                '--exitDelay': `${exitDelay / 1000}s`,
                //
                '--enterScale': enterScale || 1,
                '--exitScale': exitScale || 1,
                '--yOffset': yOffsetValue
              } as CSSProperties}
            >
              {
                (isVisible || !withDynamicChildren)
                  ? children
                  : content
              }
            </div>
          );
        }
      }
    </Transition>
  );
};

// Export

export default Fade;
