import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { useActiveOrder } from 'hooks/data/useActiveOrder';

import styles from './CartTitle.module.scss';

interface Props {
  title?: string,
  withCount?: boolean,
  withPadding?: boolean,
  withBorder?: boolean,
  withShadow?: boolean
}

const CartTitle = (props: Props) => {
  const {
    title,
    withCount = true,
    withPadding = true,
    withBorder = true,
    withShadow = true,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const { isLoading, data: order } = useActiveOrder();

  const count = isLoading ? null : order?.items?.length;

  // Empty

  if (!title) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.padded]: withPadding },
        { [styles.bordered]: withBorder },
        { [styles.shadowed]: withShadow },
      )}
    >
      <span className={styles.title}>{t(title)}</span>
      {
        !withCount
          ? null
          : (
            <span className={styles.count}>{count}</span>
          )
      }
    </div>
  );
};

export default CartTitle;
