import React from 'react';

import { useNavigationDispatchHelpers, useNavigationStore } from 'context/NavigationContext';

import type { IAnalyticsListProps } from 'types';

export function useRouteQueryAnalyticsListProps(defaultAnalyticsListProps?: IAnalyticsListProps): {
  analyticsListProps: IAnalyticsListProps,
  set: (props: IAnalyticsListProps) => void,
  persist: () => void
} {

  // Hooks

  const { queryParams } = useNavigationStore();
  const { setQueryParams } = useNavigationDispatchHelpers();

  // Props

  const { analyticsListProps }: { analyticsListProps: IAnalyticsListProps } = queryParams || {};

  // Handlers

  const set = React.useCallback((props: IAnalyticsListProps) => {
    if (areListPropsValid(props)) {
      setQueryParams({
        analyticsListProps: props
      });
    }
  }, []);

  const persist = React.useCallback(() => {
    if (areListPropsValid(analyticsListProps)) {
      setQueryParams({
        analyticsListProps
      });
    }
  }, [analyticsListProps]);

  // Return

  return {
    analyticsListProps: analyticsListProps || defaultAnalyticsListProps,
    set,
    persist
  };
}

const areListPropsValid = (listProps: IAnalyticsListProps) => {
  return !!listProps?.analyticsListId && !!listProps?.analyticsListName;
};
