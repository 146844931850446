/* eslint-disable no-underscore-dangle */

import { APP_ROUTES } from 'constants/routes';

import type {
  IBreadcrumb,
  ICategory, ICategoryTrail, IFindCategoryResult,
  IListingBreadcrumb,
  TFunction
} from 'types';

// Types

interface Options {
  shouldIncludeHome?: boolean,
  omitLast?: boolean
}

interface CategoriesParams {
  t: TFunction
}

interface CategoryNavigationItem {
  id: string,
  slug: string,
  label?: string,
  href?: string,
  as?: string,
  matchHref: string,
  exact?: boolean,
  image?: string | null,
  children?: CategoryNavigationItem[]
}

interface CategoryNavigationResult {
  parent: CategoryNavigationItem,
  children: CategoryNavigationItem[]
}

interface CategoriesNavigationMap {
  __parents: Record<string, CategoryNavigationItem>,
  [key: string]: CategoryNavigationItem | Record<string, CategoryNavigationItem>
}

export function findCategoryBySlug(list: ICategory[] | undefined, slug: string): IFindCategoryResult {
  if (!list) {
    return { category: null, trail: [] };
  }

  const recurse = (categories: ICategory[], trail: ICategoryTrail[]): { found: ICategory | null, trail: ICategoryTrail[] } => {
    return categories.reduce<{ found: ICategory | null, trail: ICategoryTrail[] }>((result, category) => {
      if (result.found) {
        return result;
      }

      const currentTrail = [...trail, {
        code: category.code,
        name: category.name,
        slug: category.slug,
        children: category.children || []
      }];

      if (category.slug === slug) {
        return { found: category, trail: currentTrail };
      }

      if (category.children) {
        const childResult = recurse(category.children, currentTrail);
        if (childResult.found) {
          return childResult;
        }
      }

      return result;
    }, { found: null, trail: [] });
  };

  const { found, trail } = recurse(list, []);
  return {
    category: found,
    trail: found ? trail : []
  };
}

export function buildHrefFromTrail(trail: ICategoryTrail[] = []) {
  return trail?.reduce((href: string, category) => href += `/${category.slug}`, APP_ROUTES.CATEGORIES);
}

// Navigation

export function getCategoriesNavigationMap(categories: ICategory[], params: CategoriesParams): CategoriesNavigationMap {
  return categories.reduce((acc, c) => {
    return categoriesMapReducer(acc, c, c, params);
  }, {
    __parents: {}
  });
}

// --- Helpers

const categoriesMapReducer = (acc: { [x: string]: any, __parents: any }, parent: ICategory, child: ICategory, params: CategoriesParams) => {
  const { slug: parentSlug } = parent;
  const { slug: childSlug, children: subChildren } = child;

  if (!acc.__parents[parentSlug]) {
    acc.__parents[parentSlug] = getCategoryNavigationItem(parent, params);
  }

  acc[childSlug] = acc.__parents[parentSlug];

  if (subChildren) {
    subChildren.map((c) => categoriesMapReducer(acc, parent, c, params));
  }

  return acc;
};

const getCategoryNavigationItem = (category: ICategory, params: CategoriesParams): CategoryNavigationResult => {
  const { slug, children } = category || {};
  return {
    parent: parseCategoryNavigationItem({
      item: category,
      baseHref: APP_ROUTES.CATEGORIES,
      matchBaseHref: APP_ROUTES.CATEGORIES_FILTERED,
      withImage: true,
      withChildren: false,
      params,
    }),
    children: children?.map((c) => parseCategoryNavigationItem({
      item: c,
      baseHref: `${APP_ROUTES.CATEGORIES}/${slug}`,
      matchBaseHref: `${APP_ROUTES.CATEGORIES_FILTERED}/${slug}`,
      withImage: false,
      withChildren: true,
      params
    })) || []
  };
};

const parseCategoryNavigationItem = ({
  item,
  baseHref,
  matchBaseHref,
  withImage,
  withChildren,
  params
}: {
  item: ICategory,
  baseHref: string,
  matchBaseHref: string,
  withImage: boolean,
  withChildren: boolean,
  params: CategoriesParams
}): CategoryNavigationItem => {
  const { t } = params || {};

  const {
    slug,
    name: label,
    iconPng,
    exact,
    children,
  } = item || {};

  const pathname = `${baseHref}/${slug}`;
  const matchPathname = `${matchBaseHref}/${slug}`;

  const href = withChildren ? undefined : pathname;
  const as = withChildren ? undefined : pathname;
  const matchHref = withChildren ? '' : matchPathname;

  return {
    id: slug,
    slug,
    label,
    href,
    as,
    matchHref,
    exact,
    image: withImage ? iconPng : null,
    children: (!withChildren || !children?.length)
      ? []
      : [
        parseCategoryNavigationItem({
          item: {
            slug,
            name: t('CATEGORIES.ALL'),
            exact: true,
          },
          baseHref: `${baseHref}`,
          matchBaseHref: `${matchBaseHref}`,
          withImage: false,
          withChildren: false,
          params
        }),
        ...children.map((childItem): CategoryNavigationItem => {
          return parseCategoryNavigationItem({
            item: childItem,
            baseHref: `${baseHref}/${slug}`,
            matchBaseHref: `${matchBaseHref}/${slug}`,
            withImage: false,
            withChildren: false,
            params
          });
        })
      ]
  };
};

export function isCategoryNavigationResult(item: any): item is CategoryNavigationResult {
  return item && typeof item === 'object' && 'parent' in item && 'children' in item;
}

// Parse gridImage url for analytics purposes

export const parseCategoryImageUrl = (imageUrl: string) => {
  const filename = imageUrl?.split('/').pop();
  return filename;
};
