import { FEATURES } from 'constants/features';
import { getDesktopAccountNavigationItems, getMobileAccountNavigationItems } from 'constants/navigation';
import { useUser } from 'hooks/data/useUser';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

interface Params {
  isDesktop?: boolean
}

export function useAccountMenu(params: Params) {

  const {
    isDesktop
  } = params || {};

  // Hooks

  const { isEnabled: isLoyaltyEnabled } = useFeatureFlag(FEATURES.LOYALTY_ENABLED);
  const { isEnabled: shouldShowLoyaltyNewLabel } = useFeatureFlag(FEATURES.LOYALTY_SHOW_NEW_LABEL);
  const { isEnabled: areBenefitsEnabled } = useFeatureFlag(FEATURES.SHOW_CUSTOMER_BENEFITS);

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  // Items

  const options = {
    canViewBenefitsPage: areBenefitsEnabled,
    canViewLoyaltyPage: isLoyaltyEnabled && !!user?.hasLoyaltyUserConditions,
    shouldShowLoyaltyNewLabel,
    shouldShowVouchersNewLabel: false,
    shouldShowBenefitsNewLabel: true
  };

  const items = (
    isDesktop
      ? getDesktopAccountNavigationItems(options)
      : getMobileAccountNavigationItems(options)
  );

  // Return

  return {
    items,
  };
}
