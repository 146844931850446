import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import * as AccountService from 'services/AccountService';
import * as AddressService from 'services/AddressService';

export const useAddressMutation = () => {
  const mutation = useAddressMutationInternal();

  return {
    isLoading: mutation.isPending,
    isError: mutation.isError,
    //
    add: (payload) => mutation.mutate({ payload, type: ACTIONS.ADD }),
    edit: (payload) => mutation.mutate({ payload, type: ACTIONS.EDIT }),
    delete: (payload) => mutation.mutate({ payload, type: ACTIONS.DELETE }),
    check: (payload) => mutation.mutate({ payload, type: ACTIONS.CHECK }),
  };
};

const useAddressMutationInternal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEYS.ADDRESS],
    mutationFn: (args = {}) => {

      const { type, payload = {} } = args;
      switch (type) {

        case ACTIONS.ADD: {
          const { address } = payload;
          return AccountService.saveAddress(address);
        }

        case ACTIONS.EDIT: {
          const { addressToken, fields } = payload;
          return AccountService.editAddress(fields, addressToken);
        }

        case ACTIONS.DELETE: {
          const { addressId } = payload;
          return AccountService.deleteAddress(addressId);
        }

        case ACTIONS.CHECK: {
          const { address } = payload;
          return AddressService.checkDeliveryAddress(address);
        }

        default:
          console.error('Unknown mutation type', type);
      }
    },
    onMutate: async (args = {}) => {
      const { type } = args;

      const previousState = queryClient.getQueryData([QUERY_KEYS.ADDRESSES]);

      switch (type) {

        case ACTIONS.ADD:
        case ACTIONS.EDIT:
        case ACTIONS.DELETE:
        case ACTIONS.CHECK: {
          break;
        }

        default:
          console.error('Unknown mutation type (on mutate)', type);
      }

      return { previousState };
    },
    onSuccess: (data, args = {}) => {
      const { payload = {}, type } = args;
      switch (type) {

        case ACTIONS.ADD: {
          const { address } = payload || {};
          queryClient.setQueryData([QUERY_KEYS.ADDRESSES], (oldAddresses) => [...oldAddresses, address]);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
          break;
        }

        case ACTIONS.EDIT: {
          const { addressToken, fields } = payload || {};
          queryClient.setQueryData([QUERY_KEYS.ADDRESSES], (oldAddresses) => (
            oldAddresses.map((address) => (address?.token === addressToken ? { ...address, ...fields } : address))
          ));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
          break;
        }

        case ACTIONS.DELETE: {
          const { addressId } = payload || {};
          queryClient.setQueryData([QUERY_KEYS.ADDRESSES], (oldAddresses) => oldAddresses.filter((address) => address?.id !== addressId));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          break;
        }

        case ACTIONS.CHECK:
          break;

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    },
    onError: (err, args = {}) => {
      const { payload = {} } = args;
      if (payload.onError) {
        payload.onError();
      }
    },
    onSettled: (data, error, args = {}) => {
      const { payload = {} } = args;
      if (payload.onSettled) {
        payload.onSettled();
      }
    }
  });
};

const ACTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  CHECK: 'CHECK'
};
