import { create } from './StoreCreator';

import type { IStoreAction } from './StoreCreator';

interface ScreenSizeStoreState {
  size: {width: number, height: number}
}

const initialState: ScreenSizeStoreState = {
  size: { width: 0, height: 0 }
};

const reducer = (draft: ScreenSizeStoreState, action: IStoreAction) => {
  const { payload = {}, type } = action;

  switch (type) {
    case ACTIONS.SET_SIZE: {
      const { size } = payload as {size: {width: number, height: number}};
      return {
        ...draft,
        size
      };
    }

    default:
      return draft;
  }
};

const [
  ScreenSizeProvider,
  useScreenSizeStore,
  useScreenSizeDispatch
] = create(reducer, initialState);

const ACTIONS = {
  SET_SIZE: 'SET_SIZE',
};

function useScreenSizeDispatchHelpers() {
  const dispatch = useScreenSizeDispatch();

  const setSize = (size: {width: number, height: number}) => dispatch({
    type: ACTIONS.SET_SIZE,
    payload: { size }
  });

  return {
    setSize
  };
}

export {
  ScreenSizeProvider,
  useScreenSizeStore,
  useScreenSizeDispatch,
  useScreenSizeDispatchHelpers,
};
