import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'abandoned_cart_events';

enum Actions {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe'
}

enum Labels {
  FROM_ACCOUNT = 'account'
}

const push = getPushCategoryEventHandler<Actions, Labels>(CATEGORY);

// Events

// --- Subscribe

function subscribeToAbandonedCart(label: Labels) {
  push(Actions.SUBSCRIBE, label);
}

export function subscribeToAbandonedCartFromAccount() {
  subscribeToAbandonedCart(Labels.FROM_ACCOUNT);
}

// --- Unsubscribe

function unsubscribeFromAbandonedCart(label: Labels) {
  push(Actions.UNSUBSCRIBE, label);
}

export function unsubscribeFromAbandonedCartFromAccount() {
  unsubscribeFromAbandonedCart(Labels.FROM_ACCOUNT);
}
