import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import ProductQuantityVariant from 'components/product/ProductQuantityVariant/ProductQuantityVariant';
import { Button, Skeleton } from 'components/ui';
import { createArray, isArrayEmpty } from 'helpers/ArrayHelpers';
import { getOrderItem, getOrderItemQuantity } from 'helpers/OrderHelpers';
import { getMaxAllowedQuantity } from 'helpers/ProductHelpers';
import { useInView } from 'hooks/common/useInView';
import { useTranslation } from 'hooks/common/useTranslation';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useConfig } from 'hooks/data/useConfig';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';
import { useProductQuantityVariants } from 'hooks/data/useProductQuantityVariants';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

import type {
  IAnalyticsListProps, IProductDetails, IProductQuantityVariant, IProductVariant
} from 'types';

import styles from './ProductDetailsQuantityVariants.module.scss';

interface Props {
  className?: string,
  variant: IProductVariant,
  product: IProductDetails,
  listProps?: IAnalyticsListProps
}

const ProductDetailsQuantityVariants = (props: Props) => {

  const {
    className, variant, product, listProps
  } = props;

  const { isSettled } = useIsAuthenticated();
  const { data: user } = useUser({ isOptional: true });
  const { data: order } = useActiveOrder();

  const { data, isLoading, isError } = useProductQuantityVariants(product?.variantCode, order?.tokenValue || '', {
    enabled: !!product?.variantCode && !!order && isSettled
  });
  const { products: variants } = data || {};

  // Hooks

  const analytics = useAnalytics();
  const router = useRouter();
  const { t } = useTranslation();

  const isAcceleratedSaleVariantSelected = router?.query?.sm === 'true';

  // Analytics

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (product) {
        analytics.viewProductQuantityVariantsSelect(product?.variantCode, product?.canAddMultipleType);
      }
      unobserve();
    }
  });

  // Loading
  if (isLoading || !isSettled) {
    return <VariantsSkeleton />;
  }

  // Empty (save me variant, error, loading)
  if (isAcceleratedSaleVariantSelected || !order || isError || isArrayEmpty(variants)) return null;

  const hasDiscountLabel = variants?.some((v) => v.quantityOptionsDiscountLabel);
  const hasGeniusDiscountLabel = variants?.some((v) => v.geniusDiscountLabelConfig);
  const displayedVariants = variants?.filter((v) => v.quantity > 1);
  const title = t(product.canAddMultipleType === 'bmsm' ? 'PRODUCT.BMSM.TITLE' : 'PRODUCT.QUANTITY_VARIANTS.TITLE');

  // Should never happen, but better safe than sorry
  if (isArrayEmpty(displayedVariants)) return null;

  return (
    <div className={classnames(styles.root, className)} ref={observe}>

      {/* Title */}
      <div className={styles.title}>
        {title}
      </div>

      {/* Variants */}
      {
        displayedVariants?.map((quantityVariant, index) => (
          <Variant
            key={`${index}-${quantityVariant?.variantCode}-${quantityVariant?.quantityLabel}`}
            quantityVariant={quantityVariant}
            activeProductVariant={variant}
            product={product}
            shouldShowGeniusPrice={!!user?.shouldShowGeniusPrice}
            hasDiscountLabel={hasDiscountLabel}
            hasGeniusDiscountLabel={hasGeniusDiscountLabel}
            listProps={listProps}
          />
        ))
      }
    </div>
  );
};

export default ProductDetailsQuantityVariants;

const VariantsSkeleton = () => {
  return (
    <div className={classnames(styles.root, styles.skeletonContainer)}>
      {
        createArray(2).map((e, index) => {
          return (
            <div className={styles.variant} key={index}>
              <Skeleton className={styles.imageSkeleton} />
              <Skeleton containerClassName={styles.variantNameSkeleton} />
            </div>
          );
        })
      }
    </div>
  );
};

interface VariantProps {
  quantityVariant: IProductQuantityVariant,
  shouldShowGeniusPrice: boolean,
  hasDiscountLabel: boolean,
  hasGeniusDiscountLabel: boolean,
  activeProductVariant: IProductVariant,
  product: IProductDetails,
  listProps?: IAnalyticsListProps
}

const Variant = (props: VariantProps) => {
  const {
    quantityVariant,
    activeProductVariant,
    product,
    shouldShowGeniusPrice,
    hasDiscountLabel,
    hasGeniusDiscountLabel,
    listProps
  } = props;

  const { data: config } = useConfig();
  const { errors } = config || {};
  const { data: order } = useActiveOrder();
  const { isLoading: isMutationLoading, add: addProduct, edit: editProduct } = useOrderProductMutation();

  const analytics = useAnalytics();

  const currentQuantity = React.useMemo(() => getOrderItemQuantity(order, activeProductVariant), [order, activeProductVariant]);
  const orderItem = getOrderItem(order, activeProductVariant);
  const [maxQuantity, quantityErrorMessage] = getMaxAllowedQuantity(activeProductVariant, errors);

  const handleAdd = (selectedQuantity: number) => {
    if (!order) return;
    if (currentQuantity === 0) {
      addProduct({
        product,
        quantity: selectedQuantity,
        bypassQuantityVariants: true,
        listProps,
        order
      });
    } else {
      editProduct({
        product,
        quantity: currentQuantity + selectedQuantity,
        listProps,
        order,
        previousQuantity: currentQuantity,
        orderItem
      });
    }
  };

  const handleClick = (quantity: number) => {
    if (product) {
      analytics.addProductQuantityVariant(product?.variantCode, quantity, product?.canAddMultipleType);
    }

    handleAdd(quantity);
  };

  // Empty
  if (!quantityVariant) return null;

  const { quantity } = quantityVariant;

  const isAddDisabled = currentQuantity + quantity > maxQuantity;

  // Render

  return (
    <ProductQuantityVariant
      className={styles.variant}
      titleClassName={styles.variantTitle}
      quantityClassName={styles.variantQuantity}
      hasDiscountLabel={hasDiscountLabel}
      hasGeniusDiscountLabel={hasGeniusDiscountLabel}
      shouldShowGeniusPrice={shouldShowGeniusPrice}
      variant={quantityVariant}
      renderTitle={() => quantityVariant.quantityLabel}
      renderAfter={() => (
        <Button
          variant="subtle"
          size="small"
          icon="plus"
          disabled={isMutationLoading || isAddDisabled}
          title={quantityErrorMessage || undefined}
          loading={isMutationLoading}
          onClick={() => handleClick(quantity)}
          className={styles.addBtn}
        />
      )}
    />
  );
};
