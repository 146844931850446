import { LOCAL_STORAGE_KEYS } from 'constants/storage';
import { useConfig } from 'hooks/data/useConfig';
import { useScheduledFeature } from 'hooks/useScheduledFeature';

export function useScheduledNewsletterSubscribe(params?: {isEnabled?: boolean}) {

  const { isEnabled } = params || {};
  const { data: config } = useConfig();
  const { newsletterInfo } = config || {};

  const {
    NUM_HOURS_AFTER_SUBSCRIBE_DIALOG_IS_SHOWN_ON_DISMISS,
  } = newsletterInfo || {};

  const { isVisible, markAsSeen } = useScheduledFeature(LOCAL_STORAGE_KEYS.NEWSLETTER_MODAL, {
    interval: NUM_HOURS_AFTER_SUBSCRIBE_DIALOG_IS_SHOWN_ON_DISMISS * 60,
    version: '0.0.1',
    isEnabled
  });

  return {
    isVisible,
    markAsSeen,
  };
}
