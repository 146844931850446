import type { IImage } from 'types/image';
import type { IListingAttributes } from 'types/listing';
import type { IAnalyticsListProps } from 'types/misc';
import type { IProduct } from 'types/product';
import type { ValueOf } from 'types/utility';
import type { IProductPromotionDataPayload, IRecipeCard, IRecipePromotionDataPayload } from 'types';

// Promotion

export interface IPromotion {
  carouselImage: IImage,
  footerImageWeb: IImage,
  footerImageResponsive: IImage,
  code: string,
  //
  startsAt: string,
  endsAt: string,
  datesShort: string,
  datesLong: string
}

// Promotion Listing

export type IPromotionListing = IRecipesPromotionListing | IProductsPromotionListing;

export interface IProductsPromotionListing {
  type: 'products',
  payload: IProductPromotionPayload
}
export interface IRecipesPromotionListing {
  type: 'recipes',
  payload: IRecipePromotionPayload
}

export interface IProductPromotionPayload extends IProductPromotionDataPayload {
}

export type IRecipePromotionPayload = Omit<IRecipePromotionDataPayload, 'recipes'> & {
  recipes?: IRecipeCard[]
}

// Promotion Item

export interface IPromotionItem extends IListingAttributes, IPromotion {
}

// Promotion Card

export type IPromotionCard = Pick<IPromotionItem, 'code' | 'name' | 'datesShort' | 'carouselImage'>

// Promotion

export type IBasePromotion = Pick<IPromotionItem, 'code' | 'name'>

// Incomplete Promotions

export const PROMOTIONS_SLOT_TYPES = {
  HORIZONTAL_PRODUCTS_CAROUSEL: 'products_carousel',
  VERTICAL_PRODUCTS_CAROUSEL: 'products_carousel_vertical',
} as const;

export type PromotionSlotType = ValueOf<typeof PROMOTIONS_SLOT_TYPES>

export interface IPromotionSlot extends IAnalyticsListProps {
  type: PromotionSlotType,
  title: string,
  subtitle: string,
  product: IProduct[]
}

export type IPromotionSlots = IPromotionSlot[]

export interface IAccountPromotionsResponse {
  items: IAccountPromotion[]
}

export interface IAccountPromotion {
  title: string,
  description: string,
  dateLabel: string,
  url: string
}
