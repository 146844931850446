import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import ProductCartCardActions from 'components/product/cards/ProductCartCard/ProductCartCardActions';
import {
  Button, Icon, Link, Loader
} from 'components/ui';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useAnalytics } from 'hooks/useAnalytics';

import { useProductCardActions } from 'hooks/useProductCardActions';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import {
  ProductImage, ProductLabel, ProductPrice, ProductReplaceButton
} from '../../parts';

import ProductDeleteToastBody from '../../ProductDeleteToastBody/ProductDeleteToastBody';

import { ProductLabelTypes } from 'types';
import type { IAnalyticsListProps, IOrder, IOrderProduct } from 'types';

import styles from './ProductCartCard.module.scss';

export const IMAGE_SIZE = 70;

interface Props {
  className?: string,
  //
  order: IOrder,
  product: IOrderProduct,
  //
  currency: string,
  listProps: IAnalyticsListProps
}

const ProductCartCard = (props: Props) => {
  const {
    className,
    //
    order,
    product,
    //
    currency,
    listProps,
  } = props;

  // Props

  const {
    variantCode,
    productSlug,
    productName,
    //
    image,
    badge,
    textTags = [],
    //
    originalSubtotal,
    subtotal,
    priceColor,
    taxes,
    //
    error,
    //
    isAccSale,
    isOk,
    isLoading = false,
  } = product;

  // Hooks

  const analytics = useAnalytics();
  const {
    set: setAnalyticsListProps
  } = useRouteQueryAnalyticsListProps(listProps);

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Product Card Actions Hook

  const {
    isProductActionLoading,
    isProductActionError,
    //
    handleRemove,
    handleQuantityChange,
  } = useProductCardActions({
    order,
    orderItem: product,
    listProps,
    //
    onRemoveSuccess: () => {
      ProductHelpers.showDeleteNotification({
        component: ProductDeleteToastBody,
        order,
        product,
        listProps,
        onSuccess: () => analytics.undoDeleteProductFromCart()
      });
    }
  });

  // Handlers

  const onReplaceClick = React.useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    showModal(
      MODAL_TYPES.SIMILAR_PRODUCTS,
      { productSlug, productVariantCode: variantCode }
    );
  }, [productSlug, variantCode, showModal]);

  const handleClick = () => {
    setAnalyticsListProps(listProps);
  };

  // Props

  const [maxQuantity] = ProductHelpers.getMaxAllowedQuantity(product);
  const labels = textTags?.map((tag) => ({ type: ProductLabelTypes.SIMPLE, items: [tag] }));
  const link = ProductHelpers.getProductLink(productSlug, isAccSale && isOk) || '';

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Image */}
      <ProductImage
        src={image}
        alt={productName}
        size={IMAGE_SIZE}
        retina
        unavailable={!isOk}
        rounded
        badge={badge}
        badgeProps={{
          size: 'small',
          withPrefix: false,
        }}
      />

      {/* Content */}
      <div className={styles.content}>

        {/* Name Row */}
        <div className={classnames(styles.row, styles.nameRow)}>

          {/* Name */}
          <div className={styles.nameContainer}>
            <Link href={link} prefetch={false}>
              <Button tag="a" className={styles.name} onClick={handleClick}>
                {productName}
              </Button>
            </Link>
          </div>

          {/* Remove */}
          <div className={styles.removeBtnContainer}>
            <Button
              variant="empty"
              onClick={handleRemove}
              size="small"
              className={styles.removeBtn}
              icon={CloseIcon}
            />
          </div>
        </div>

        {/* Label Row */}
        {
          isArrayEmpty(labels)
            ? null
            : (
              <div className={classnames(styles.row, styles.labelRow)}>
                <ProductLabel
                  size="small"
                  label={labels[0]}
                  rounded
                />
              </div>
            )
        }

        {/* Error Row */}
        {
          !isOk && (
            <div className={classnames(styles.row, styles.errorRow)}>
              <div className={styles.error}>{error}</div>
            </div>
          )
        }

        {/* Details Row */}
        <div className={classnames(styles.row, styles.detailsRow, { [styles.unavailable]: maxQuantity === 0 })}>
          {
            maxQuantity === 0
              ? (
                <>
                  {/* Product Replace  Button */}
                  <ProductReplaceButton
                    size="small"
                    withText
                    onClick={onReplaceClick}
                  />
                </>
              ) : (
                <>
                  {/* Price */}
                  <div className={styles.priceContainer}>

                    {/* Loader */}
                    <Fade
                      className={styles.loader}
                      isVisible={isProductActionLoading}
                      lazyLoad
                      enterDuration={400}
                      enterDelay={200}
                      exitDelay={400}
                      exitDuration={400}
                    >
                      <Loader
                        size={18}
                        lineWeight={2}
                      />
                    </Fade>

                    {/* Product Price */}
                    <Fade
                      isVisible={!isProductActionLoading}
                      persist
                      enterDuration={400}
                      enterDelay={1000}
                      exitDuration={400}
                    >
                      <ProductPrice
                        className={styles.productPrice}
                        originalPriceClassName={styles.originalPrice}
                        taxesClassName={styles.taxes}
                        //
                        size="small"
                        //
                        currency={currency}
                        price={subtotal}
                        originalPrice={originalSubtotal}
                        taxes={taxes}
                        //
                        nullable
                        //
                        color={priceColor}
                      />
                    </Fade>
                  </div>

                  {/* Quantity Actions */}
                  <div className={styles.actionsContainer}>
                    <ProductCartCardActions
                      orderItem={product}
                      //
                      shouldReset={isProductActionError}
                      //
                      onQuantityChange={handleQuantityChange}
                    />
                  </div>
                </>
              )
          }
        </div>
      </div>

      {/* Loading Overlay */}
      <Fade
        className={styles.loadingOverlay}
        isVisible={isLoading}
        persist
      >
        <Loader
          size={24}
          lineWeight={2.5}
        />
      </Fade>
    </div>
  );
};

// Components

const CloseIcon = <Icon size={16} strokeWidth={2} name="x" />;

// Export

export default React.memo(ProductCartCard);
