import React from 'react';
import Script from 'next/script';

import { CONFIG } from 'constants/config';
import { useChatDispatchHelpers } from 'context/ChatContext';

const ChatScript = () => {
  if (!CONFIG.SALESFORCE.CHAT.IS_ENABLED) return null;

  const { initializeChat } = useChatDispatchHelpers();
  const [isLoaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (isLoaded) {
      initializeChat();
    }
  }, [isLoaded]);

  return (
    <>
      <Script
        strategy="lazyOnload"
        type="text/javascript"
        src="https://service.force.com/embeddedservice/5.0/esw.min.js"
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

export default ChatScript;
