import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'new_feature_events';

enum Actions {
  VIEW_NOTIFICATION = 'view_notification',
  DISMISS_NOTIFICATION = 'dismiss_notification',
  SELECT_NOTIFICATION = 'select_notification',
  //
  OPEN_MODAL = 'open_modal',
  DISMISS_MODAL = 'dismiss_modal',
  //
  VIEW_SLIDE = 'view_slide',
  COMPLETE_SLIDES = 'complete_slides',
  //
  SELECT_CTA = 'select_cta',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

//

export function viewNewFeatureNotification(strapiId: number, featureAnalyticsLabel: string) {
  push(Actions.VIEW_NOTIFICATION, `${strapiId}`, {
    param1: featureAnalyticsLabel,
  });
}

export function dismissNewFeatureNotification(strapiId: number, featureAnalyticsLabel: string) {
  push(Actions.DISMISS_NOTIFICATION, `${strapiId}`, {
    param1: featureAnalyticsLabel,
  });
}

export function selectNewFeatureNotification(strapiId: number, featureAnalyticsLabel: string) {
  push(Actions.SELECT_NOTIFICATION, `${strapiId}`, {
    param1: featureAnalyticsLabel,
  });
}

//

export function openNewFeatureModal(strapiId: number, featureAnalyticsLabel: string) {
  push(Actions.OPEN_MODAL, `${strapiId}`, {
    param1: featureAnalyticsLabel,
  });
}

// export function dismissNewFeatureModal(strapiId: number, featureAnalyticsLabel: string, slideIndex: number, slideAnalyticsLabel: string) {
//   push(Actions.DISMISS_MODAL, `${strapiId}`, {
//     param1: featureAnalyticsLabel,
//     param2: `${slideIndex}`,
//     param3: slideAnalyticsLabel
//   });
// }

//

export function viewNewFeatureModalSlide(strapiId: number, featureAnalyticsLabel: string, slideIndex: number, slideAnalyticsLabel: string) {
  push(Actions.VIEW_SLIDE, `${strapiId}`, {
    param1: featureAnalyticsLabel,
    param2: `${slideIndex}`,
    param3: slideAnalyticsLabel
  });
}

export function completeNewFeatureModalSlides(strapiId: number, featureAnalyticsLabel: string) {
  push(Actions.COMPLETE_SLIDES, `${strapiId}`, {
    param1: featureAnalyticsLabel,
  });
}

//

export function selectNewFeatureModalCTA(strapiId: number, featureAnalyticsLabel: string, slideIndex: number, slideAnalyticsLabel: string) {
  push(Actions.SELECT_CTA, `${strapiId}`, {
    param1: featureAnalyticsLabel,
    param2: `${slideIndex}`,
    param3: slideAnalyticsLabel
  });
}
