import React from 'react';
import dynamic from 'next/dynamic';

import { Head } from 'components/ui';

import AccountNavigation from '../AccountNavigation/AccountNavigation';
import AddressSearchModal from '../AddressSearchModal/AddressSearchModal';
import DrawerContainer from '../DrawerContainer/DrawerContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ModalContainer from '../ModalContainer/ModalContainer';
import OpenInAppBanner from '../OpenInAppBanner/OpenInAppBanner';

import styles from './AccountLayout.module.scss';

const ProductModal = dynamic(() => import('../ProductModal/ProductModal'), { ssr: false });

interface Props {
  openInAppBanner?: {isVisible: boolean, props: React.ComponentProps<typeof OpenInAppBanner>},
  children?: React.ReactNode
}

const AccountLayout = (props: Props) => {
  const {
    children,
    openInAppBanner = { isVisible: true, props: {} },
  } = props;

  return (
    <div className={styles.root}>
      <Head />
      <Header />
      <div className={styles.container}>
        <AccountNavigation />
        <main className={styles.content}>
          {children}
        </main>
        <DrawerContainer />
        <ModalContainer />
      </div>
      {openInAppBanner?.isVisible && <OpenInAppBanner {...openInAppBanner.props} />}
      <AddressSearchModal />
      <ProductModal />
      <Footer />
    </div>
  );
};

export default AccountLayout;
