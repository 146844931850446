/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { debounce } from 'throttle-debounce';

import { CONFIG } from 'constants/config';
import { usePlaceDetails, usePlaceSuggestions } from 'hooks/data/usePlaces';

import type { FormikProps } from 'formik';
import type { Nullable, PlaceDetailsQuery } from 'types';

interface Props {
  form: FormikProps<any>
}

export function useStreetAutocomplete(props: Props) {
  const { form } = props;

  // Handlers

  const updateParams = (params: PlaceDetailsQuery) => {
    const { street, number, ...rest } = params;

    form?.validateForm().then((errors) => {
      if (!street || !number || errors?.number) {
        return;
      }

      setSearchParams({
        street, number, ...rest
      });
    });
  };

  // Hooks

  const [query, setQuery] = React.useState(null);
  const [searchParams, setSearchParams] = React.useState<Nullable<PlaceDetailsQuery>>(null);

  const { data: placeSuggestions, isLoading: arePlaceSuggestionsLoading } = usePlaceSuggestions(query);
  const { data: placeDetails, isLoading: isPlaceDetailsLoading } = usePlaceDetails(searchParams);

  const debouncedSetQuery = React.useCallback(debounce(CONFIG.DURATIONS.USER_ACTIONS_DEBOUNCE, setQuery), []);
  const debouncedSetParams = React.useCallback(debounce(CONFIG.DURATIONS.USER_ACTIONS_DEBOUNCE, updateParams), []);

  // Effects

  React.useEffect(() => {
    return () => {
      if (debouncedSetQuery) {
        debouncedSetQuery.cancel();
      }

      if (debouncedSetParams) {
        debouncedSetParams.cancel();
      }
    };
  }, []);

  // Exports

  return {
    setQuery,
    debouncedSetQuery,
    setSearchParams,
    debouncedSetParams,
    searchParams,
    placeSuggestions,
    arePlaceSuggestionsLoading,
    placeDetails,
    isPlaceDetailsLoading
  };
}
