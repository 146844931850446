import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Badge, Icon, Link, Scrollbar
} from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { useAccountMenu } from 'hooks/useAccountMenu';

import styles from './AccountNavigation.module.scss';

const AccountNavigation = () => {

  // Hooks

  const router = useRouter();
  const { t } = useTranslation();

  // Props

  const { items: navItems } = useAccountMenu({ isDesktop: true });

  // Render

  return (
    <div className={styles.root} data-testid="account-navigation">
      <Scrollbar
        className={styles.scrollbar}
        autoHide={false}
        forceVisible="y"
      >

        {/* Title */}
        <div className={styles.title}>{t('NAVIGATION.ACCOUNT.TITLE')}</div>

        {/* List */}
        <ul className={styles.list}>
          {
            navItems?.map((item, index) => {

              // Separator
              if (item === 'separator') {
                return <div className={styles.separator} key={index} />;
              }

              const {
                label, href, icon, hidden, badge, dataTestId
              } = item;

              // Hidden
              if (hidden) return null;

              const isActive = href === APP_ROUTES.ACCOUNT ? router.asPath === href : router.asPath.startsWith(href);
              return (
                (
                  <li className={classnames(styles.listItemWrapper, { [styles.active]: isActive })} key={href || index}>
                    <Link href={href}>
                      <a className={styles.listItem} data-testid={dataTestId}>
                        <Icon className={styles.listItemIcon} name={icon} />
                        <span className={styles.listItemLabel}>{t(label)}</span>
                        {
                          badge && (
                            <Badge
                              className={styles.listItemBadge}
                              style={{
                                backgroundColor: badge.backgroundColor,
                                color: badge.textColor
                              } as React.CSSProperties}
                            >
                              {t(badge.text)}
                            </Badge>
                          )
                        }
                      </a>
                    </Link>
                  </li>
                )
              );
            })
          }
        </ul>
      </Scrollbar>
    </div>
  );
};

export default AccountNavigation;
