import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import ProductTag from '../ProductTag/ProductTag';

import type { IProductTag, Orientation } from 'types';
import { Orientations, Positions } from 'types';

import styles from './ProductTags.module.scss';

export interface Props {
  className?: string,
  tagClassName?: string,
  tags?: IProductTag[],
  orientation?: Orientation,
  showText?: boolean,
  limit?: number
}

const ProductTags = (props: Props) => {
  const {
    className,
    tagClassName,
    tags = [],
    orientation = Orientations.HORIZONTAL,
    showText = false,
    limit = 3
  } = props;

  // Empty

  if (isArrayEmpty(tags)) return null;

  // Limit tags

  const displayTags = tags?.length >= limit
    ? tags.slice(0, limit)
    : tags;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[orientation]]: orientation },
        className,
      )}
    >
      {
        displayTags?.map((tag, index) => (
          <ProductTag
            key={`${tag?.icon}${index}`}
            className={classnames(styles.tag, tagClassName)}
            tag={tag}
            showText={showText}
            positions={
              orientation === Orientations.HORIZONTAL
                ? [Positions.BOTTOM]
                : [Positions.RIGHT]
            }
          />
        ))
      }
    </div>
  );
};

export default React.memo(ProductTags);
