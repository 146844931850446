import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'vouchers';

enum Actions {
  VIEW_VOUCHERS = 'view_vouchers',
  VIEW_PROMOTIONS = 'view_promotions',
  APPLY_VOUCHER = 'apply_voucher',
  SELECT_VOUCHER_VIEW_DETAILS = 'select_voucher_view_details',
  ADD_VOUCHER = 'add_voucher',
  SELECT_PROMOTION = 'select_promotion',
  REMOVE_VOUCHER = 'remove_voucher'
}

enum Labels {
  VIEW_VOUCHERS = 'view_vouchers',
  VIEW_PROMOTIONS = 'view_promotions'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function viewVouchers() {
  return push(Actions.VIEW_VOUCHERS, Labels.VIEW_VOUCHERS);
}

export function viewAccountPromotions() {
  return push(Actions.VIEW_PROMOTIONS, Labels.VIEW_PROMOTIONS);
}

export function selectAccountPromotion(url: string) {
  return push(Actions.SELECT_PROMOTION, url);
}

export function applyVoucher(code: string) {
  return push(Actions.APPLY_VOUCHER, code);
}

export function addVoucher(code: string) {
  return push(Actions.ADD_VOUCHER, code);
}

export function removeVoucher(code: string) {
  return push(Actions.REMOVE_VOUCHER, code);
}

export function selectVoucherViewDetails(code: string) {
  return push(Actions.SELECT_VOUCHER_VIEW_DETAILS, code);
}
