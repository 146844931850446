import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button } from 'components/ui';

import ActiveFiltersList from './ActiveFiltersList';
import FilterAccordion from './FilterAccordion';
import FiltersPopover from './FiltersPopover';

import type {
  IListingFilter, IListingFilterOption, IListingFilters, IUrl
} from 'types';

import styles from './FiltersDesktop.module.scss';

interface Props {
  filters: IListingFilters,
  //
  withAccordion?: boolean,
  withLabel?: boolean,
  withActiveFilters?: boolean,
  withClearButton?: boolean,
  inline?: boolean,
  multiple?: boolean,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  clearFilters: () => void
}

const FiltersDesktop = (props: Props) => {

  const {
    filters,
    //
    withAccordion,
    withLabel,
    withActiveFilters,
    withClearButton,
    inline,
    multiple = false,
    //
    getHref,
    clearFilters,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const { items } = filters;

  const hasActiveFilters = items?.some((filter) => filter?.countSelected > 0);

  // Render

  return (
    <div className={classnames(styles.root, { [styles.inline]: inline })}>

      {/* Active Filters */}
      {
        withActiveFilters && (
          <ActiveFiltersList
            filters={filters}
            getHref={getHref}
            clearFilters={clearFilters}
          />
        )
      }

      {/* Label */}
      {
        withLabel && (
          <div className={styles.label}>
            {t('LISTING_TOOLBAR.FILTER_BY.LABEL')}
          </div>
        )
      }

      {/* List */}
      <div className={classnames(styles.list, { [styles.inline]: inline })}>
        {
          items?.map((filter) => (
            withAccordion
              ? (
                <FilterAccordion
                  className={styles.item}
                  key={filter?.code}
                  filter={filter}
                  getHref={getHref}
                  withBorders
                />
              ) : (
                <FiltersPopover
                  key={filter?.code}
                  filter={filter}
                  getHref={getHref}
                  multiple={multiple}
                />
              )
          ))
        }
      </div>

      {/* Clear Button */}
      {
        (withClearButton && hasActiveFilters) && (
          <Button
            className={styles.clearFiltersBtn}
            onClick={clearFilters}
          >
            {t('LISTING_TOOLBAR.FILTER_BY.CLEAR_FILTERS')}
          </Button>
        )
      }

    </div>
  );
};

export default FiltersDesktop;
