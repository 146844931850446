import { debounce } from 'throttle-debounce';

import { useScreenSizeDispatchHelpers } from 'context/ScreenSizeContext';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';

export function useScreenSizeObserver() {
  const { setSize } = useScreenSizeDispatchHelpers();

  const handleResize = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  };

  /*
  const handleOrientationChange = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  };
  */

  const debouncedHandleResize = debounce(50, handleResize);

  useIsomorphicLayoutEffect(() => {

    handleResize();
    /* window.addEventListener('orientationchange', handleOrientationChange); */
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      /* window.removeEventListener('orientationchange', handleOrientationChange); */
    };
  }, []);

}
