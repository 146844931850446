import React from 'react';

import { clearLegacyKeys as clearStorageLegacyKeys } from 'common/Storage';
import { useAuthObserver } from 'hooks/observers/useAuthObserver';
import { useOrderMutationsObserver } from 'hooks/observers/useOrdersMutationsObserver';
import { useOrderStatusObserver } from 'hooks/observers/useOrderStatusObserver';
import { useScreenSizeObserver } from 'hooks/observers/useScreenSizeObserver';
import { useUserObserver } from 'hooks/observers/useUserObserver';
import { useGlobalVariables } from 'hooks/useGlobalVariables';
import { useProductModalScrollRestoration } from 'hooks/useProductModalScrollRestoration';

export function useBootstrap() {
  const isInitialized = React.useRef(true);

  const { initialize: initializeAuthObserver } = useAuthObserver();
  const { initialize: initializeGlobalVariables } = useGlobalVariables();

  useOrderMutationsObserver();
  useUserObserver();
  useScreenSizeObserver();
  useOrderStatusObserver();

  useProductModalScrollRestoration();

  if (isInitialized.current) {
    initializeAuthObserver();
    initializeGlobalVariables();
  }

  React.useEffect(() => {
    clearStorageLegacyKeys();
  }, []);
  isInitialized.current = false;
}
