import React from 'react';
import classnames from 'classnames';

import CategoriesCarousel from 'components/ui/CategoriesCarousel/CategoriesCarousel';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useBreakpoint } from 'hooks/useBreakpoint';

import ListingToolbar from '../ListingToolbar/ListingToolbar';
import OrderBy from '../OrderBy/OrderBy';

import { Breakpoints } from 'types';
import type {
  IListingFilters,
  IListingOrderBy,
  IProduct,
  IRecipeCard,
  ISearchSuggestions,
  PageType
} from 'types';

import styles from './ListingHeader.module.scss';

interface Props {
  className?: string,
  titleClassName?: string,
  title: React.ReactNode,
  items: IProduct[] | IRecipeCard[],
  suggestedCategories?: ISearchSuggestions,
  loading?: boolean,
  count?: number,
  pageType: PageType,
  filters?: IListingFilters,
  orderBy?: IListingOrderBy,
  titleDataTestId?: string
}

const ListingHeader = (props: Props) => {

  const {
    //
    className,
    titleClassName,
    //
    title,
    items = [],
    suggestedCategories,
    loading,
    count,
    //
    pageType,
    //
    filters,
    orderBy,
    //
    titleDataTestId,
  } = props;

  // Hooks

  const isBreakpoint = useBreakpoint(Breakpoints.LG);

  const { categories } = suggestedCategories || {};

  // Props

  const hasItems = !isArrayEmpty(items);
  const hasOrderBy = isBreakpoint && orderBy && hasItems;
  const hasListingToolbar = !isBreakpoint && (orderBy || filters) && hasItems;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      <div className={classnames(styles.titleWrapper, { [styles.withoutTitle]: !title })}>

        <div className={classnames({ [styles.fullWidth]: !isBreakpoint })}>
          {/* Name */}
          {!!title
          && (
            <h1
              className={classnames(styles.title, titleClassName)}
              data-testid={titleDataTestId}
            >
              {title}
            </h1>
          )}

          {!isBreakpoint && !isArrayEmpty(categories) && (
            <div className={styles.carousel}>
              <CategoriesCarousel categories={categories} loading={loading} />
            </div>
          )}
        </div>

        {/* Order By - Desktop */}
        {
          hasOrderBy && (
            <OrderBy
              orderBy={{ orderBy }}
              pageType={pageType}
            />
          )
        }
      </div>

      {/* Listing Toolbar - Tablet / Mobile */}
      {
        hasListingToolbar && (
          <div className={styles.listingToolbarWrapper}>
            {
              hasItems && (
                <ListingToolbar
                  className={styles.listingToolbar}
                  filters={{ filters }}
                  orderBy={{ orderBy }}
                  pageType={pageType}
                  data={{ count }}
                />
              )
            }
          </div>
        )
      }

    </div>
  );
};

// Export

export default ListingHeader;
