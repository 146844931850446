import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'landing_page_events';

enum Actions {
  VIEW_CTA = 'view_cta',
  CLICK_CTA = 'click_cta'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- View Cart Error

export function viewLandingPageCTA(id: string) {
  push(Actions.VIEW_CTA, id);
}

export function clickLandingPageCTA(id: string) {
  push(Actions.CLICK_CTA, id);
}
