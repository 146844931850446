import React from 'react';
import classnames from 'classnames';

import { ProductAddToCart } from 'components/product/parts';

import type { IOrderProduct } from 'types';

import styles from './ProductCartCardActions.module.scss';

interface Props {
  className?: string,
  //
  orderItem: IOrderProduct,
  //
  shouldReset?: boolean,
  //
  onQuantityChange: (previousQuantity: number, currentQuantity: number) => void
}

const ProductCartCardActions = (props: Props) => {
  const {
    className,
    //
    orderItem,
    //
    shouldReset,
    //
    onQuantityChange,
  } = props;

  // Props

  const {
    isOk,
    isLoading,
    quantity,
  } = orderItem || {};

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {
        !isLoading && (
          <ProductAddToCart
            //
            product={orderItem}
            quantity={quantity}
            //
            shouldReset={shouldReset}
            shouldAdjustQuantity={!isOk}
            //
            size="small"
            isCompact
            //
            onQuantityChange={onQuantityChange}
            //
          />
        )
      }
    </div>
  );
};

export default ProductCartCardActions;
