import React from 'react';
import classnames from 'classnames';

import { Icon, Image } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IImageSrc, IOrderGiftProduct, IOrderProduct } from 'types';

import styles from './ProductThumbnails.module.scss';

interface Props {
  //
  className?: string,
  thumbnailClassName?: string,
  extraThumbnailClassName?: string,
  iconClassName?: string,
  //
  products: IOrderProduct[] | IOrderGiftProduct[],
  //
  desktopMaxCount?: number,
  responsiveMaxCount?: number,
  //
  size?: number,
  icon?: string,
  forceExtra?: boolean,
  //
  onExtraClick?: () => void
}

const ProductThumbnails = (props: Props) => {
  const {
    className,
    thumbnailClassName,
    extraThumbnailClassName,
    iconClassName,
    //
    products = [],
    //
    desktopMaxCount = 4,
    responsiveMaxCount = 3,
    //
    size,
    icon,
    forceExtra,
    //
    onExtraClick
  } = props;

  // Hooks

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Props

  const maxCount = isBreakpoint ? responsiveMaxCount : desktopMaxCount;
  const hasExtra = forceExtra ? products.length > maxCount - 1 : products.length > maxCount;
  const extraMessage = `+${products.length - maxCount + 1}`;
  const thumbnails = hasExtra ? products.slice(0, maxCount - 1) : products;
  const imageSize = size || (isBreakpoint ? 32 : 36);

  // Empty

  if (isArrayEmpty(products)) return null;

  // TODO: use regular image instead of NextImage
  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {/* Thumbnails */}
      {
        thumbnails.map((product: IOrderProduct | IOrderGiftProduct, index) => {
          const { image } = product;
          const key = product?.id || product?.sku;
          if (!image) return null;

          return (
            <Thumbnail
              key={`${index}-${key}`}
              className={classnames(thumbnailClassName)}
              size={imageSize}
              image={image}
            />
          );
        })
      }

      {/* Extra */}
      {
        hasExtra && (
          <Thumbnail
            className={classnames(
              styles.extra,
              thumbnailClassName,
              extraThumbnailClassName
            )}
            iconClassName={iconClassName}
            size={imageSize}
            text={extraMessage}
            icon={icon}
            onClick={onExtraClick}
          />
        )
      }

    </div>
  );
};

interface ThumbnailProps {
  className?: string,
  imageClassName?: string,
  iconClassName?: string,
  //
  size: number,
  //
  text?: string,
  image?: IImageSrc,
  icon?: string,
  //
  onClick?: () => void
}

const Thumbnail = (props: ThumbnailProps) => {
  const {
    className,
    imageClassName,
    iconClassName,
    //
    size,
    //
    text,
    image,
    icon,
    //
    onClick,
  } = props;

  // Handlers

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Render

  return (
    <div
      className={classnames(
        styles.thumbnail,
        styles.extra,
        { [styles.withHover]: !!onClick },
        className,
      )}
      style={{
        width: size ? `${size}px` : undefined,
        height: size ? `${size}px` : undefined,
      }}
      onClick={handleClick}
    >

      {/* Text */}
      {
        text && (
          <div className={styles.text}>
            {text}
          </div>
        )
      }

      {/* Icon */}
      {
        icon && (
          <div className={classnames(styles.icon, iconClassName)}>
            <Icon
              name={icon}
              size={20}
              strokeWidth={1.5}
            />
          </div>
        )
      }

      {/* Image */}
      {
        image && (
          <Image
            className={classnames(styles.image, imageClassName)}
            src={
              typeof image === 'object'
                ? image?.default
                : image
            }
            width={size}
            height={size}
            quality={100}
            optimize
          />
        )
      }
    </div>
  );
};

// Export

export default ProductThumbnails;
