import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getOrderGifts } from 'services/OrdersService';

export const useOrderGifts = (id, options = {}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.ORDER_GIFTS,
      id
    ],
    queryFn: () => getOrderGifts(id),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    retry: 1,
    ...options
  });
};
