import React from 'react';
import classnames from 'classnames';

import { useIsClient } from 'hooks/useIsClient';
import { useMediaQuery } from 'hooks/useMediaQuery';

import Image from '../Image/Image';

import type { ImageProps } from '../Image/Image';

import styles from './ResponsiveImage.module.scss';

// Types

interface Props {
  className?: string,
  imageClassName?: string,
  wrapperClassName?: string,
  desktopWrapperClassName?: string,
  responsiveWrapperClassName?: string,
  desktopProps: ImageProps,
  quality?: number,
  responsiveProps: ImageProps,
  breakpoint?: string,
  optimize?: boolean,
  alt?: string
}

const ResponsiveImage = (props: Props) => {
  const {
    className,
    imageClassName,
    wrapperClassName,
    desktopWrapperClassName,
    responsiveWrapperClassName,
    //
    desktopProps,
    responsiveProps,
    breakpoint = 'sm',
    //
    ...rest
  } = props;

  // Hooks

  const isClient = useIsClient();
  const isBreakpoint = useMediaQuery(`(min-width: ${styles[`breakpoint_${breakpoint}`]})`);

  // Classes

  const rootClassName = React.useMemo(() => {
    return classnames(
      styles.root,
      { [styles.rendered]: isClient },
      { [styles[`break-at-${breakpoint}`]]: breakpoint },
      className,
    );
  }, [isClient, breakpoint]);

  // Render

  return (
    <div className={rootClassName}>

      {/* Responsive */}
      <div
        className={classnames(
          styles.responsiveImage,
          responsiveWrapperClassName,
          wrapperClassName
        )}
      >
        <Image
          className={imageClassName}
          {...rest}
          {...responsiveProps}
        />
      </div>

      {/* Desktop */}
      {
        isClient && isBreakpoint && (
          <div
            className={classnames(
              styles.desktopImage,
              desktopWrapperClassName,
              wrapperClassName
            )}
          >
            <Image
              className={imageClassName}
              {...rest}
              {...desktopProps}
            />
          </div>
        )
      }

    </div>
  );
};

export default ResponsiveImage;
