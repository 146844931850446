import ChatScript from 'scripts/Salesforce/ChatScript';

const ScriptsLoader = () => {
  return (
    <>
      <ChatScript />
    </>
  );
};

export default ScriptsLoader;
