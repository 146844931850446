import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'phone_verification'; // TODO: align to _events prefix format

enum Actions {
  VIEW_MODAL = 'view_modal',
  CLOSE_MODAL = 'close_modal',
  VERIFY = 'verify',
  MODIFY = 'modify',
  SELECT_NOTICE = 'select_notice'
}

enum Labels {
  VIEW_MODAL = 'view_modal',
  CLOSE_MODAL = 'close_modal',
  VERIFY = 'verify',
  MODIFY = 'modify',
  SELECT_NOTICE = 'select_notice'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function viewPhoneNumberVerificationModal() {
  push(Actions.VIEW_MODAL, Labels.VIEW_MODAL);
}

export function closePhoneNumberVerificationModal() {
  push(Actions.CLOSE_MODAL, Labels.CLOSE_MODAL);
}

export function verifyPhoneNumber() {
  push(Actions.VERIFY, Labels.VERIFY);
}

export function modifyPhoneNumber() {
  push(Actions.MODIFY, Labels.MODIFY);
}

export function selectPhoneNumberVerificationNotice() {
  push(Actions.SELECT_NOTICE, Labels.SELECT_NOTICE);
}
