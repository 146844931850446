import React from 'react';
import classnames from 'classnames';

import styles from './MenuItemSeparator.module.scss';

interface Props {
  className?: string,
  horizontal?: boolean
}

const MenuItemSeparator = (props: Props) => {
  const {
    className,
    horizontal
  } = props;

  return (
    <li
      className={classnames(
        styles.root,
        { [styles.horizontal]: horizontal },
        className,
      )}
    />
  );
};

export default MenuItemSeparator;
