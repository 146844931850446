/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */

import React from 'react';
import classnames from 'classnames';

import { Button, Image, Link } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { buildURLWithQueryString } from 'helpers/UrlHelpers';
import { useTranslation } from 'hooks/common/useTranslation';

import {
  ProductBadge, ProductLabels, ProductPrice, ProductTags
} from '../../parts';

import type { IAnalyticsListProps, IProduct } from 'types';

import styles from './ProductMobilePreviewCard.module.scss';

interface Props {
  className?: string,
  product: IProduct,
  listProps?: IAnalyticsListProps,
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const MobilePreviewProductCard = (props: Props) => {
  const {
    className,
    product,
    listProps,
    onClick
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const variant = React.useMemo(() => {
    return ProductHelpers.getProductDefaultVariant(product, {});
  }, [product]);

  const {
    isAvailable,
    slug,
    brand,
    name,
    image,
    tags,
    badge,
    taxes,
  } = product || {};

  const {
    labels,
    currency,
    originalPrice,
    price,
    priceColor,
  } = variant;

  const {
    analyticsListId: item_list_id,
    analyticsListName: item_list_name
  } = listProps || {};

  const href = (listProps?.analyticsListId && listProps?.analyticsListName)
    ? buildURLWithQueryString(`${APP_ROUTES.PRODUCTS}/${slug}`, { item_list_id, item_list_name })
    : `${APP_ROUTES.PRODUCTS}/${slug}`;

  // Render

  return (
    <Link href={href}>

      {/* Link */}
      <a className={classnames(styles.root, className)} onClick={onClick}>

        {/* Image Container */}
        <div className={styles.imageContainer}>

          {/* Labels */}
          <ProductLabels
            className={styles.productLabels}
            labels={labels}
            size="large"
            responsiveSize="medium"
          />

          {/* Image */}
          <Image
            className={classnames(styles.image, { [styles.unavailable]: !isAvailable })}
            src={image?.thumbnail}
            width={400}
            height={400}
            square
            alt={name}
          />

          {/* Tags */}
          <ProductTags
            className={styles.tagsContainer}
            tags={tags}
          />

          {/* Badge */}
          <ProductBadge
            className={styles.badge}
            size="medium"
            badge={badge}
          />

        </div>

        {/* Content */}
        <div className={styles.content}>

          {/* Brand */}
          <div className={styles.brandContainer}>
            {brand}
          </div>

          {/* Name */}
          <div className={styles.details}>
            <h2 className={styles.name}>{name}</h2>
          </div>

          {/* Price */}
          <div className={styles.priceContainer}>
            <ProductPrice
              //
              className={styles.productPrice}
              originalPriceClassName={styles.originalPrice}
              priceClassName={styles.currentPrice}
              unitPriceLabelClassName={styles.unitPriceLabel}
              //
              fixedHeight
              //
              currency={currency}
              originalPrice={originalPrice}
              price={price}
              taxes={taxes}
              color={priceColor}
            />
          </div>

          {/* Actions */}
          <div className={styles.footer}>
            <Button
              className={styles.viewBtn}
              variant="primary"
              size="medium"
            >
              {t('MOBILE_PREVIEW.VIEW_PRODUCT')}
            </Button>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default React.memo(MobilePreviewProductCard);
