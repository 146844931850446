import React from 'react';

import { useTranslation } from 'hooks/common/useTranslation';

import type { IProductDetails } from 'types';

interface Props {
  className?: string,
  sku: IProductDetails['sku']
}

const ProductSku = (props: Props) => {
  const {
    className,
    sku
  } = props;

  const { t } = useTranslation();

  return (
    <div className={className}>
      {`${t('PRODUCT.SKU')}: ${sku}`}
    </div>
  );
};

export default ProductSku;
