import React from 'react';
import classnames from 'classnames';

import type { IAccountBenefitsBlock } from 'types';

import styles from './AccountBenefitsList.module.scss';

interface Props extends IAccountBenefitsBlock {
  className?: string,
  titleClassName?: string,
  descriptionClassName?: string,
  listClassName?: string,
  itemClassName?: string,
  isCentered?: boolean
}

const AccountBenefitsList = (props: Props) => {
  const {
    className,
    titleClassName,
    descriptionClassName,
    listClassName,
    itemClassName,
    //
    title,
    description,
    list,
    //
    isCentered = true
  } = props;

  // Empty

  if (!list?.length) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.centered]: isCentered },
        className
      )}
    >

      {/* Title */}
      {
        title && (
          <div className={classnames(styles.title, titleClassName)}>
            {title}
          </div>
        )
      }

      {/* Description */}
      {
        description && (
          <div className={classnames(styles.description, descriptionClassName)}>
            {description}
          </div>
        )
      }

      {/* List */}
      <div className={classnames(styles.list, listClassName)}>
        {
          list?.map((item, index) => {
            const { icon, label, value } = item;
            return (
              <div
                key={`account-benefit-${index}`}
                className={classnames(styles.item, itemClassName)}
              >

                {/* Icon */}
                {
                  icon && (
                    <div className={styles.icon}>
                      <img alt={value} src={icon} />
                    </div>
                  )
                }

                {/* Value */}
                <div className={styles.value}>{value}</div>

                {/* Label */}
                {
                  label && (
                    <div className={styles.label}>{label}</div>
                  )
                }

              </div>
            );
          })
        }
      </div>
    </div>
  );
};
export default AccountBenefitsList;
