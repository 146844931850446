/* Should be kept in sync with _variables.scss */
// TODO find a way to import scss variables here

const breakpoints = {
  sm: 640,
  smMax: 639.98,
  md: 1024,
  mdMax: 1023.98,
  lg: 1260,
  lgMax: 1259.98,
  xl: 1440,
  xlMax: 1439.98
};

export const theme = {
  breakpoints
};
