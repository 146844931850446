import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { hashIngredientsList } from 'helpers/RecipeHelpers';
import { getRecipeIngredients } from 'services/RecipeService/RecipeService';

import type {
  IRecipeIngredientProduct, IRecipePrimaryIngredients, ReactQueryOptions
} from 'types';

interface Params {
  page?: number,
  itemsPerPage?: number,
  ingredients: IRecipePrimaryIngredients
}

export const useRecipeIngredients = (params: Params, queryOptions: ReactQueryOptions<IRecipeIngredientProduct[]> = {}) => {

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => getRecipeIngredients(params),
    ...queryOptions
  });
};

export const getKey = (params: Params) => {
  const {
    page,
    itemsPerPage,
    ingredients
  } = params;

  const ingredientsHash = hashIngredientsList(ingredients);

  return createQueryKey([
    QUERY_KEYS.RECIPES_INGREDIENTS,
    page,
    itemsPerPage,
    ingredientsHash
  ]);
};
