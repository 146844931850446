import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'genius_upsell'; // TODO: align to _events prefix format

enum Actions {
  VIEW_BANNER = 'view_banner',
  SELECT_BANNER = 'select_banner'
}

enum Labels {
  SELECT_BANNER = 'Select banner'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function viewGeniusFastCheckoutBanner(status: string) {
  push(Actions.VIEW_BANNER, status);
}

export function selectGeniusFastCheckoutBanner() {
  push(Actions.SELECT_BANNER, Labels.SELECT_BANNER);
}
