import React from 'react';
import classnames from 'classnames';

import { Modal, Stepper } from 'components/ui';
import { DEFAULT_MODAL_PRIORITY } from 'constants/modal';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { replaceIngredient } from 'helpers/RecipeHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useRecipe } from 'hooks/data/useRecipe';
import { useRecipeIngredients } from 'hooks/data/useRecipeIngredients';
import { useAnalytics } from 'hooks/useAnalytics';
import { useStepper } from 'hooks/useStepper';

import RecipeIngredientsModalBackupsStep from './RecipeIngredientsModalBackupsStep';
import RecipeIngredientsModalListStep from './RecipeIngredientsModalListStep';

import type { ModalPriority } from 'components/ui/Modal/Modal';

import type {
  IRecipeIngredientProduct,
} from 'types';

import styles from './RecipeIngredientsModal.module.scss';

export const STEP_IDS = {
  INGREDIENT_LIST: 'INGREDIENT_LIST',
  INGREDIENT_BACKUPS: 'INGREDIENT_BACKUPS',
};

interface Props {
  className?: string,
  //
  isOpen: boolean,
  //
  title?: string,
  recipeId?: number,
  recipeSlug?: string,
  preview?: boolean,
  priority?: ModalPriority,
  //
  shouldLoadRecipe?: boolean,
  //
  currentIngredientProducts?: IRecipeIngredientProduct[],
  currentIngredientProductToReplace?: IRecipeIngredientProduct,
  //
  onSelectedIngredientProductReplaced?: (ingredients: IRecipeIngredientProduct[]) => void,
  onClose: () => void,
  onAfterClose: () => void
}

const RecipeIngredientsModal = (props: Props) => {

  const {
    className,
    //
    isOpen,
    //
    title,
    recipeId,
    recipeSlug,
    preview,
    priority = DEFAULT_MODAL_PRIORITY,
    //
    shouldLoadRecipe = true,
    //
    currentIngredientProducts,
    currentIngredientProductToReplace,
    //
    onSelectedIngredientProductReplaced,
    onClose,
    onAfterClose,
  } = props;

  // Hooks

  const analytics = useAnalytics();

  // --- Stepper

  const {
    activeStep, goTo
  } = useStepper(
    STEP_IDS,
    // @ts-ignore TODO: fix when useStepper is migrated to TS
    currentIngredientProductToReplace
      ? STEP_IDS.INGREDIENT_BACKUPS
      : STEP_IDS.INGREDIENT_LIST
  );

  // --- State

  const [ingredientProducts, setIngredientProducts] = React.useState<IRecipeIngredientProduct[]>([]);
  const [ingredientProductToReplace, setIngredientProductToReplace] = React.useState<IRecipeIngredientProduct>();

  // Data Hooks

  // --- Order

  const { data: order } = useActiveOrder();

  // --- Recipe

  const {
    isLoading: isLoadingRecipe,
    data: recipe
  } = useRecipe({
    slug: recipeSlug || '',
    preview
  }, {
    enabled: shouldLoadRecipe && !!recipeSlug
  });

  // --- Ingredients

  const {
    isLoading: isLoadingIngredients,
    data: recipeIngredientProducts
  } = useRecipeIngredients({
    ingredients: recipe?.ingredients?.primary || [],
  }, {
    enabled: !isArrayEmpty(recipe?.ingredients?.primary)
  });

  // Effects

  // --- Update current ingredients from hook

  React.useEffect(() => {
    if (recipeIngredientProducts) {
      setIngredientProducts(recipeIngredientProducts);
    }
  }, [recipeIngredientProducts]);

  // --- Update current ingredients

  React.useEffect(() => {
    if (currentIngredientProducts) {
      setIngredientProducts(currentIngredientProducts);
    }
  }, [currentIngredientProducts]);

  // --- Update ingredient to replace

  React.useEffect(() => {
    if (currentIngredientProductToReplace) {
      setIngredientProductToReplace(currentIngredientProductToReplace);
    }
  }, [currentIngredientProductToReplace]);

  // Handlers

  const handleUpdateIngredientProduct = (selectedBackupIngredientProduct: IRecipeIngredientProduct) => {
    const updatedIngredients = replaceIngredient(
      ingredientProducts,
      ingredientProductToReplace,
      selectedBackupIngredientProduct
    );

    setIngredientProducts(updatedIngredients);

    if (onSelectedIngredientProductReplaced) {
      onSelectedIngredientProductReplaced(updatedIngredients);
      onClose();
    } else {
      goTo(STEP_IDS.INGREDIENT_LIST);
    }
  };

  const handleClose = () => {
    if (activeStep === STEP_IDS.INGREDIENT_BACKUPS) {
      analytics.cancelRecipeIngredientReplace(
        recipeId,
        ingredientProductToReplace?.product?.sku,
      );
    }

    if (onClose) {
      onClose();
    }
  };

  const handleAfterClose = () => {
    if (!currentIngredientProducts) {
      setIngredientProducts([]);
    }

    setIngredientProductToReplace(undefined);

    if (onAfterClose) {
      onAfterClose();
    }
  };

  // Steps

  const steps = [
    {
      id: STEP_IDS.INGREDIENT_LIST,
      component: RecipeIngredientsModalListStep,
      componentProps: {
        isLoadingRecipe,
        isLoadingIngredients: isLoadingRecipe || isLoadingIngredients,
        //
        title,
        //
        order,
        recipe,
        //
        primaryIngredients: recipe?.ingredients?.primary,
        ingredientProducts,
        //
        onClose: handleClose,
        onReplaceIngredientClick: (ingredientProduct: IRecipeIngredientProduct) => {
          analytics.startRecipeIngredientReplace(
            recipeId,
            ingredientProduct?.product?.sku
          );

          setIngredientProductToReplace(ingredientProduct);
          goTo(STEP_IDS.INGREDIENT_BACKUPS);
        }
      }
    },
    {
      id: STEP_IDS.INGREDIENT_BACKUPS,
      component: RecipeIngredientsModalBackupsStep,
      componentProps: {
        ingredientProductToReplace,
        //
        canNavigateBack: !currentIngredientProductToReplace,
        //
        onClose: handleClose,
        onBack: () => {
          analytics.cancelRecipeIngredientReplace(
            recipeId,
            ingredientProductToReplace?.product?.sku
          );

          goTo(STEP_IDS.INGREDIENT_LIST);
        },
        onSelectIngredient: (selectedBackupIngredientProduct: IRecipeIngredientProduct) => {
          analytics.completeRecipeIngredientReplace(
            recipeId,
            ingredientProductToReplace?.product?.sku,
            selectedBackupIngredientProduct?.product?.sku
          );

          handleUpdateIngredientProduct(selectedBackupIngredientProduct);
        }
      }
    }
  ];

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      className={classnames(styles.root, className)}
      contentClassName={styles.content}
      //
      breakpoint="md"
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
      }}
      priority={priority}
      withPadding={false}
      withCloseButton={false}
      //
      close={handleClose}
      onAfterClose={() => {
        goTo(STEP_IDS.INGREDIENT_LIST);
        handleAfterClose();
      }}
    >

      {/* Stepper */}
      <Stepper
        steps={steps}
        activeStep={activeStep}
        className={styles.stepper}
        stepClassName={styles.step}
        contentClassName={styles.stepperContent}
        persist={false}
      />

    </Modal>
  );
};

// Export

export default RecipeIngredientsModal;
