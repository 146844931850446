import React from 'react';
import classnames from 'classnames';

import { RECOMMENDATION_LOCATIONS, RECOMMENDATION_TYPES } from 'constants/recommendations';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { none } from 'helpers/BooleanHelpers';
import { useRecommendations } from 'hooks/data/useRecommendations';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

import CartPromotionsButton from '../CartButton/CartPromotionsButton';
import CartRecommendationsButton from '../CartButton/CartRecommendationsButton';
import CartNotices from '../CartNotices/CartNotices';

import type { IOrder } from 'types';

import styles from './CartHeader.module.scss';

interface Props {
  className?: string,
  order: IOrder
}

const RECOMMENDATION_REFETCH_INTERVAL = 2 * 60 * 1000;

const CartHeader = (props: Props) => {
  const {
    className,
    order
  } = props;

  const {
    tokenValue,
    cartNoticeV2: notices,
    incompletePromotionsCount,
  } = order || {};

  // Hooks

  const { isAuthenticated } = useIsAuthenticated();

  // Data Hooks

  const {
    data: recommendations
  } = useRecommendations(
    RECOMMENDATION_LOCATIONS.CART,
    RECOMMENDATION_TYPES.A,
    { isAuthenticated, tokenValue },
    { refetchInterval: RECOMMENDATION_REFETCH_INTERVAL, staleTime: RECOMMENDATION_REFETCH_INTERVAL, retry: 0 }
  );

  // Props

  const { slots = [] } = recommendations || {};

  // Flags

  const hasRecommendations = !isArrayEmpty(slots);
  const hasNotices = !isArrayEmpty(notices);
  const hasPromotions = incompletePromotionsCount > 0;

  // Empty

  if (none(hasNotices, hasRecommendations, hasPromotions)) {
    return null;
  }

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Notices */}
      {
        hasNotices && (
          <CartNotices
            notices={notices}
          />
        )
      }

      {/* Promotions */}
      {
        hasPromotions && (
          <CartPromotionsButton
            wrapperClassName={styles.btnWrapper}
            orderTokenValue={tokenValue}
            incompletePromotionsCount={incompletePromotionsCount}
          />
        )
      }

      {/* Recommendations */}
      {
        hasRecommendations && (
          <CartRecommendationsButton
            wrapperClassName={styles.btnWrapper}
            orderTokenValue={tokenValue}
            recommendationType={RECOMMENDATION_TYPES.A}
          />
        )
      }

    </div>
  );
};

export default CartHeader;
