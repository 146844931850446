import QueryString from 'common/QueryString';
import { PAGES_MAP } from 'constants/pages';
import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import { UrlParams, type UrlSegments } from './UrlParams';
import { DEFAULT_URL_SEPARATOR, hasSegments } from './UrlParamsHelpers';

import type { IListingActiveFilters, IPageUrlParams } from 'types';
import { PageType } from 'types';

const FILTERS_SEGMENT = 'filters';

export enum FiltersType {
  Single = 'single',
  Multiple = 'multiple'
}

export type FiltersOptions = {
  type?: FiltersType
}

export function getFiltersParams(segments: UrlSegments, payload: {isPaginated: boolean, isOrdered: boolean}, options?: FiltersOptions) {

  const unfiltered = {
    isFiltered: false,
    filtersCount: 0,
    filtersIndexable: true,
    filters: null
  };

  if (!hasSegments(segments)) {
    return unfiltered;
  }

  const filtersSegmentIndex = segments.findIndex((segment) => segment.toLowerCase() === FILTERS_SEGMENT);
  if (filtersSegmentIndex === -1) {
    return unfiltered;
  }

  const { isPaginated, isOrdered } = payload;
  const { type = FiltersType.Multiple } = options || {};
  const segmentsOffset = [isPaginated, isOrdered].filter((val) => val).length;
  const filtersSegments = segments.slice(filtersSegmentIndex + 1, segments.length - segmentsOffset);
  const filters: IListingActiveFilters = filtersSegments.reduce((acc, filterSegment) => {
    const [code, values] = filterSegment.split(DEFAULT_URL_SEPARATOR);
    if (!acc) return [];
    if (!code || !values) return acc;
    return [
      ...acc,
      [code, type === FiltersType.Single ? values : values.split(',')]
    ];
  }, [] as IListingActiveFilters);

  // Index at most 3 filters and no more than 1 filter value
  const filtersIndexable = filtersSegments.length <= 3 && Boolean(filters?.every(([, filterValues]) => filterValues.length < 2));
  const filtersCount = filtersSegments.length;
  const isFiltered = filtersCount > 0;

  return {
    filtersCount,
    filtersIndexable,
    isFiltered,
    filters: isFiltered ? filters : null
  };

}

export function setFiltersParams(newFilters: IListingActiveFilters | null, options: {segments: UrlSegments, pageType: PageType }) {
  const { segments, pageType } = options;

  const params = UrlParams.getParams(segments, pageType);
  const prefix = getFiltersPrefix(newFilters, pageType, params);

  const url = UrlParams.setParams({
    ...params,
    prefix,
    page: 1,
    filters: newFilters
  });

  return url;
}

function getFiltersPrefix(newFilters: IListingActiveFilters | null, pageType: PageType, params: IPageUrlParams) {

  const { isDefault, prefix } = params;

  if ([PageType.Search, PageType.AccountFreshclub].includes(pageType)) return prefix;

  if (isDefault && isArrayEmpty(newFilters)) {
    return PAGES_MAP[pageType]?.path || APP_ROUTES.HOME;
  }

  return PAGES_MAP[pageType]?.filteredPath || APP_ROUTES.HOME;
}

export function getFiltersQueryString(filters: IListingActiveFilters | null | undefined, prefix = '&') {
  if (!filters) return '';

  const qs = QueryString.stringify({
    filter: filters.reduce((acc, [filterCode, values]) => ({
      ...acc,
      [filterCode]: values
    }), {})
  });

  return `${prefix}${qs}`;
}
