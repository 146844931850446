import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';

import * as ProductHelpers from 'helpers/ProductHelpers';
import { useBreakpoint } from 'hooks/useBreakpoint';

import type { CarouselApi } from 'components/ui/Carousel';

import type { IProductImage } from 'types';

import styles from './ProductGalleryThumbnails.module.scss';

interface Props {
  className?: string,
  //
  productName: string,
  images: IProductImage[],
  activeIndex: number,
  //
  carouselApi: CarouselApi
}

const ProductGalleryThumbnails = (props: Props) => {

  const {
    className,
    //
    productName,
    images,
    activeIndex,
    //
    carouselApi,
  } = props;
  // Hooks

  const isBreakpoint = useBreakpoint('sm', 'down');

  // Empty

  if (isBreakpoint) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      {
        images?.map((image, index) => {
          return (
            <ProductGalleryThumbnail
              key={index}
              activeIndex={activeIndex}
              index={index}
              image={image}
              productName={productName}
              carouselApi={carouselApi}
            />
          );
        })
      }
    </div>
  );
};

// Components

interface ProductGalleryThumbnailProps {
  productName: string,
  //
  index: number,
  activeIndex: number,
  image: IProductImage,
  //
  carouselApi: CarouselApi
}

const ProductGalleryThumbnail = (props: ProductGalleryThumbnailProps) => {
  const {
    productName,
    //
    index,
    activeIndex,
    image,
    //
    carouselApi,
  } = props;

  const src = React.useMemo(() => ProductHelpers.getThumbnailSrc(image), [image]);
  const onMouseEnter = () => {
    if (carouselApi?.selectedScrollSnap() !== index) {
      carouselApi?.scrollTo(index);
    }
  };

  const isActive = index === activeIndex;

  return (
    <div
      className={classnames(styles.thumbnail, { [styles.active]: isActive })}
      onMouseEnter={onMouseEnter}
    >
      <Image
        src={src}
        height={60}
        width={60}
        alt={productName}
      />
    </div>
  );
};

// Export

export default React.memo(ProductGalleryThumbnails);
