import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';

import Loader from '../Loader/Loader';

import styles from './Checkbox.module.scss';

// Types
interface Props {
  className?: string,
  labelClassName?: string,
  iconClassName?: string,
  loaderClassName?: string,
  subtitleClassName?: string,
  label: React.ReactNode,
  subtitle?: string,
  checked: boolean,
  loading?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  size?: string,
  dataTestId?: string,
  onChange: (event?: React.ChangeEvent<HTMLElement>) => void
}
const SIZES = {
  MEDIUM: 'medium',
  LARGE: 'large'
};

const ICON_SIZES = {
  [SIZES.MEDIUM]: 20,
  [SIZES.LARGE]: 24
};

const Checkbox = (props: Props) => {
  const {
    className,
    labelClassName,
    iconClassName,
    loaderClassName,
    subtitleClassName,
    label,
    subtitle,
    checked,
    loading,
    disabled,
    readOnly,
    size = SIZES.LARGE,
    onChange,
    dataTestId,
    ...rest
  } = props;

  const Label = (
    <>
      {
        label && (
          <span className={classnames(styles.label, labelClassName)}>{label}</span>
        )
      }
    </>
  );

  const Subtitle = (
    <>
      {
        subtitle && (
          <span className={classnames(styles.subtitle, subtitleClassName)}>{subtitle}</span>
        )
      }
    </>
  );

  // Classes

  const classes = classnames(
    styles.container,
    { [styles.disabled]: disabled },
    { [styles.checked]: checked },
    className
  );

  const checkboxIconClasses = classnames(
    styles.checkboxIcon,
    { [styles[size]]: size },
    { [styles.loading]: loading },
    iconClassName
  );

  return (
    <label className={classes}>

      {/* Input */}
      <input
        type="checkbox"
        name="checkbox"
        checked={checked}
        disabled={disabled || loading}
        readOnly={readOnly}
        data-testid={dataTestId}
        onChange={(event) => !(disabled || loading) && onChange(event)}
        {...rest}
      />

      {/* Icon */}
      <div className={styles.iconWrapper}>

        {/* Checkbox Icon */}
        <span className={checkboxIconClasses} />

        {/* Loader */}
        <Fade
          isVisible={!!loading}
          className={styles.loadingWrapper}
          enterDuration={200}
          enterDelay={300}
          exitDuration={300}
          lazyLoad
        >
          <Loader
            className={loaderClassName}
            color={styles.primaryColor}
            size={ICON_SIZES[size] - 2}
            lineWeight={2}
          />
        </Fade>

      </div>

      {/* Label */}
      <div className={styles.labelWrapper}>
        {Label}
        {Subtitle}
      </div>

    </label>
  );

};

export default Checkbox;
