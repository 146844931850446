import React from 'react';
import classnames from 'classnames';

import { ListingBanner } from 'components/layout';
import { createObjectMap, objectClean } from 'helpers/ObjectHelpers';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';
import { useScreenSize } from 'hooks/useScreenSize';

import ProductDefaultCard from '../cards/ProductDefaultCard/ProductDefaultCard';

import type {
  IAnalyticsListProps, IListingBanner, IProduct
} from 'types';

import styles from './ProductList.module.scss';

interface Props {
  className?: string,
  products: IProduct[],
  productListProps?: IAnalyticsListProps,
  productCardProps?: Partial<React.ComponentProps<typeof ProductDefaultCard>>,
  banners?: IListingBanner[],
  wide?: boolean,
  dataTestId?: string
}

const ProductList = (props: Props) => {
  const {
    //
    className,
    //
    products,
    banners = [],
    //
    productListProps = {},
    productCardProps = {},
    //
    wide = false,
    //
    dataTestId
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const { width } = useScreenSize();
  const [itemsPerRow, setItemsPerRow] = React.useState<number>();

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  // Analytics Handlers

  const onItemClick = (item: IProduct, index: number) => {
    analytics.selectItem(item, {
      index,
      id: productListProps?.analyticsListId,
      name: productListProps?.analyticsListName
    });

    if (item?.sponsored) {
      analytics.selectSponsoredProduct(
        item?.sku,
        item?.sponsored?.position,
        item?.sponsored?.billed,
        productListProps
      );
    }
  };

  const onItemViewed = (item: IProduct, index: number) => {
    analytics.viewItem(item, {
      index,
      id: productListProps?.analyticsListId,
      name: productListProps?.analyticsListName
    });

    if (item?.sponsored) {
      analytics.viewSponsoredProduct(
        item?.sku,
        item?.sponsored?.position,
        item?.sponsored?.billed,
        productListProps
      );
    }
  };

  // Effects

  React.useEffect(() => {
    setItemsPerRow(getProductsPerRow(width, wide));
  }, [width, wide]);

  // Map banners by position

  const bannersByPosition: Record<number, IListingBanner> = React.useMemo(() => {
    return createObjectMap(banners, 'position', false) as Record<number, IListingBanner>;
  }, [banners]);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.wide]: wide },
        className,
      )}
      data-testid={dataTestId}
    >
      {
        products?.map((item, index) => {
          const position = itemsPerRow ? index / itemsPerRow : -1;
          const isLastItem = index === (products?.length || 0) - 1;
          const banner = itemsPerRow ? bannersByPosition[position] : null;
          const nextBanner = position < Math.ceil(position) ? bannersByPosition[Math.ceil(position)] : null;

          return (
            <React.Fragment key={item.code}>

              {/* Pre Banner */}
              {
                banner
                  ? (
                    <ListingBanner
                      className={classnames(styles.banner, { [styles.first]: index === 0 })}
                      position={position}
                      banner={banner}
                    />
                  )
                  : null
              }

              {/* Product Card */}
              <div className={styles.itemWrapper}>
                <ProductDefaultCard
                  {...objectClean(productCardProps)}
                  product={item}
                  listProps={productListProps}
                  shouldShowGeniusPrice={!!user?.shouldShowGeniusPrice}
                  onClick={() => {
                    onItemClick(item, index);
                  }}
                  onEnter={() => {
                    onItemViewed(item, index);
                  }}
                />
              </div>

              {/* Post Banner */}
              {
                isLastItem && nextBanner
                  ? (
                    <ListingBanner
                      className={classnames(styles.banner, styles.last)}
                      position={position + 1}
                      banner={nextBanner}
                    />
                  )
                  : null
              }

            </React.Fragment>
          );
        })
      }
    </div>
  );
};

const getProductsPerRow = (windowWidth: number, wide = false) => {

  if (wide) {
    if (windowWidth < parseInt(styles.wideSm, 10)) {
      return 2;
    }
    if (windowWidth < parseInt(styles.wideMd, 10)) {
      return 3;
    }
    if (windowWidth < parseInt(styles.wideLg, 10)) {
      return 4;
    }
    if (windowWidth < parseInt(styles.wideXxxl, 10)) {
      return 5;
    }
    return 6;
  }

  if (windowWidth < parseInt(styles.sm, 10)) {
    return 2;
  }
  if (windowWidth < parseInt(styles.md, 10)) {
    return 3;
  }
  if (windowWidth < parseInt(styles.xl, 10)) {
    return 4;
  }
  if (windowWidth < parseInt(styles.xxl, 10)) {
    return 3;
  }
  if (windowWidth < parseInt(styles.xxxl, 10)) {
    return 4;
  }

  return 4;

};

export default ProductList;
