import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';

import BlogBlockContainer from '../BlogBlockContainer/BlogBlockContainer';

import styles from './BlogImageBlock.module.scss';

interface Props {
  image?: {url: string},
  title?: string,
  description?: string,
  className?: string,
  containerClassName?: string,
  innerContainerClassName?: string,
  textContainerClassName?: string,
  imageContainerClassName?: string
}

const BlogImageBlock = (props: Props) => {

  const {
    title, description, image,
    containerClassName, innerContainerClassName, className,
    textContainerClassName, imageContainerClassName,
  } = props;

  return (
    <BlogBlockContainer
      className={classnames(styles.container, containerClassName)}
      innerClassName={classnames(styles.innerContainer, innerContainerClassName)}
    >
      <div className={classnames(styles.root, className)}>
        <div className={classnames(styles.textContainer, textContainerClassName)}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.description}>
            {description}
          </div>
        </div>
        <div className={classnames(styles.imageContainer, imageContainerClassName)}>
          <Image
            className={styles.image}
            height={540}
            width={960}
            src={image?.url}
            alt={title}
          />
        </div>
      </div>
    </BlogBlockContainer>
  );
};

export default BlogImageBlock;
