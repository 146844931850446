import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { toRecipeCard } from 'services/RecipeService/RecipeAdapter';

import {
  convertStrapiResponsiveImage,
  convertStrapiResponsiveSeoImage,
} from './generic';

import type {
  IRecipesLandingPageDataHero,
  IRecipesLandingPageDataLink,
  IRecipesLandingPageDataSeo,
  IRecipesLandingPageDataSlot,
  IRecipesLandingPageDataSlotMetadata,
  IRecipesLandingPageHero,
  IRecipesLandingPageSeo,
  IRecipesLandingPageSlot,
  IRecipesLandingPageSlotLink,
  IRecipesLandingPageSlotMetadata,
} from 'types';

import {
  RECIPES_LANDING_PAGE_SLOT_TYPES, SLOT_METADATA_TYPES,
} from 'types';

// Landing Page

// --- Hero

export function convertRecipesLandingPageHero(hero: IRecipesLandingPageDataHero): IRecipesLandingPageHero {
  const {
    title = null,
    description = null,
    image,
    tags = []
  } = hero;

  return {
    title,
    description,
    banner: convertStrapiResponsiveImage(image),
    tags: tags?.map(({ id, slug, label }) => ({
      id,
      slug,
      label,
    }))
  };
}

// --- Slot

export function convertRecipesLandingPageSlot(slot: IRecipesLandingPageDataSlot): IRecipesLandingPageSlot {
  const {
    type,
    data
  } = slot || {};

  // Empty - if no type

  if (!type) {
    return null;
  }

  // TODO: conditional data object based on type

  const {
    title,
    metadata,
    link,
    recipes
  } = data || {};

  // Empty - if no recipes

  if (isArrayEmpty(recipes)) {
    return null;
  }

  // Metadata / Link

  const convertedMetadata = convertRecipesLandingPageSlotMetadata(metadata);
  const convertedLink = convertRecipesLandingPageSlotLink(link, convertedMetadata);

  // Return

  return {
    type: RECIPES_LANDING_PAGE_SLOT_TYPES[type],
    data: {
      title,
      link: convertedLink,
      metadata: convertedMetadata,
      recipes: recipes.map(toRecipeCard)
    }
  };
}

function convertRecipesLandingPageSlotMetadata(metadata: IRecipesLandingPageDataSlotMetadata): IRecipesLandingPageSlotMetadata {
  if (metadata.type === SLOT_METADATA_TYPES.BY_TAG) {
    return {
      type: SLOT_METADATA_TYPES.BY_TAG,
      tag: metadata?.tag?.slug,
    };
  }

  if (metadata.type === SLOT_METADATA_TYPES.LATEST) {
    return {
      type: SLOT_METADATA_TYPES.LATEST,
    };
  }

  if (metadata.type === SLOT_METADATA_TYPES.CUSTOM) {
    return {
      type: SLOT_METADATA_TYPES.CUSTOM,
    };
  }

  return null;
}

function convertRecipesLandingPageSlotLink(link: IRecipesLandingPageDataLink, metadata: IRecipesLandingPageSlotMetadata): IRecipesLandingPageSlotLink {
  if (!link || !metadata) {
    return null;
  }

  const { title: text } = link || {};

  // By Tag

  if (metadata.type === SLOT_METADATA_TYPES.BY_TAG) {
    return {
      text,
      url: `${APP_ROUTES.RECIPES_BY_TAG}/${metadata.tag}`
    };
  }

  // Latest

  if (metadata.type === SLOT_METADATA_TYPES.LATEST) {
    return {
      text,
      url: `${APP_ROUTES.RECIPES_LIST}`
    };
  }

  // Custom

  if (metadata.type === SLOT_METADATA_TYPES.CUSTOM) {
    return {
      text,
      url: `${APP_ROUTES.RECIPES_LANDING}`
    };
  }

  // Empty

  return null;
}

// --- SEO

export function convertRecipesLandingPageSeo(seo: IRecipesLandingPageDataSeo, hero: IRecipesLandingPageDataHero): IRecipesLandingPageSeo {
  const {
    title,
    description,
  } = seo;

  const {
    image
  } = hero || {};

  return {
    title,
    description,
    images: convertStrapiResponsiveSeoImage(image)
  };
}
