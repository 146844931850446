import type { IOrderLoyalty, IOrderLoyaltyOption } from 'types';

export function toOrderLoyalty(response: any): IOrderLoyalty {
  const {
    disabled,
    //
    spend,
    //
    unitName,
    unitsName,
  } = response || {};

  const {
    icon,
    title,
    subtitle,
    availableAmount,
    options,
    usedPoints,
    eligibleAmountLabel,
    //
    slider,
  } = spend || {};

  const {
    title: sliderTitle,
    subtitle: sliderSubtitle,
    description,
    //
    currentLabel,
    maxLabel,
    btnLabel,
    //
    min,
    max,
    step,
    conversionRate,
  } = slider || {};

  return {
    disabled: !!disabled,
    general: {
      unitName,
      unitsName
    },
    spend: {
      step: {
        icon,
        title,
        subtitle,
        availableAmount,
        options: options?.map((option: IOrderLoyaltyOption) => {
          const {
            enabled,
            label,
            subLabel,
            points,
            type
          } = option || {};

          return {
            enabled,
            label,
            subLabel,
            points,
            type
          };
        }),
        usedPoints,
      },
      slider: {
        title: sliderTitle,
        subtitle: sliderSubtitle,
        description,
        //
        currentLabel,
        maxLabel,
        btnLabel,
        eligibleAmountLabel,
        //
        min,
        max,
        step,
        conversionRate,
      }
    }
  };
}
