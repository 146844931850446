/* eslint-disable react/no-danger */

import React from 'react';
import classnames from 'classnames';

import { Markdown } from 'components/ui';
import { INGREDIENTS_CAROUSEL_BREAKPOINTS, RECIPE_DETAILS_CAROUSEL_BREAKPOINTS } from 'constants/recipes';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useIsOpen } from 'hooks/useIsOpen';

import RecipeBanner from '../RecipeBanner/RecipeBanner';
import RecipeIngredientsListWithBulkAdd from '../RecipeIngredientsListWithBulkAdd/RecipeIngredientsListWithBulkAdd';
import RecipeIngredientsModal from '../RecipeIngredientsModal/RecipeIngredientsModal';
import RecipePrice from '../RecipePrice/RecipePrice';
import RecipeShareButtons from '../RecipeShareButtons/RecipeShareButtons';
import RecipeStats from '../RecipeStats/RecipeStats';
import RecipeTags from '../RecipeTags/RecipeTags';

import RecipeDetailsIngredientsCarousel from './RecipeDetailsIngredientsCarousel/RecipeDetailsIngredientsCarousel';
import RecipeDetailsInstructions from './RecipeDetailsInstructions/RecipeDetailsInstructions';
import RecipeDetailsSimilarRecipesCarousel from './RecipeDetailsSimilarRecipesCarousel/RecipeDetailsSimilarRecipesCarousel';
import RecipeDetailsTextIngredients from './RecipeDetailsTextIngredients/RecipeDetailsTextIngredients';
import RecipeDetailsTips from './RecipeDetailsTips/RecipeDetailsTips';
import RecipeDetailsVideo from './RecipeDetailsVideo/RecipeDetailsVideo';

import type { IRecipe, IRecipeCard } from 'types';

import styles from './RecipeDetails.module.scss';

interface Props {
  className?: string,
  recipe: IRecipe
}

const RecipeDetails = (props: Props) => {

  const {
    className,
    recipe,
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const isMd = useBreakpoint('md', 'down');

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useIsOpen();

  const [selectedRecipeCard, setSelectedRecipeCard] = React.useState<IRecipeCard | null>(null);

  // Handlers

  const handleRecipeViewIngredientsClick = (recipeCard: IRecipeCard, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRecipeCard(recipeCard);
    openModal();
  };

  // Props

  const {
    meta,
    details,
    media,
    stats,
    ingredients,
    instructions,
    tips,
    tags,
    banner,
    related,
  } = recipe || {};

  const {
    id: recipeId,
  } = meta || {};

  const {
    pricePerServing,
    originalPricePerServing
  } = stats || {};

  const {
    title,
    description
  } = details || {};

  const {
    video
  } = media || {};

  // Render

  return (
    <>

      <div
        className={
          classnames(
            styles.root,
            { [styles.mobile]: isMd },
            { [styles.desktop]: !isMd },
            className
          )
        }
      >

        {/* Main */}
        <div className={styles.main}>

          {/* Header */}
          <div className={styles.header}>

            {/* Title */}
            <div className={styles.title}>
              {title}
            </div>

            {/* Price Per Portion - Desktop Only */}
            {
              !isMd && (
                <RecipePrice
                  className={styles.pricePerServing}
                  priceClassName={styles.price}
                  originalPriceClassName={styles.originalPrice}
                  perServingLabelClassName={styles.perServingLabel}
                  pricePerServing={pricePerServing?.value}
                  originalPricePerServing={originalPricePerServing?.value}
                />
              )
            }

          </div>

          {/* Promo Banner */}
          {!!banner && (
            <div className={styles.promoBannerContainer}>
              <RecipeBanner className={styles.promoBanner} banner={banner} />
            </div>
          )}

          {/* Share buttons */}
          <RecipeShareButtons />

          {/* Stats - Mobile Only */}
          {
            isMd && (
              <RecipeStats
                className={styles.stats}
                statClassName={styles.stat}
                statIconClassName={styles.statIcon}
                stats={[
                  {
                    ...stats.pricePerServing,
                    originalValue: originalPricePerServing?.value
                  },
                  stats.servings,
                  stats.preparationTime,
                  stats.cookingTime,
                  stats.totalTime
                ]}
              />
            )
          }

          {/* Ingredients - Mobile Only */}
          {
            isMd && (
              <RecipeIngredientsListWithBulkAdd
                className={styles.ingredients}
                listClassName={classnames(styles.ingredientsList, styles.withoutBorder)}
                recipe={recipe}
                withSimplifiedHeader
                withStickyAddIngredientsButton
              />
            )
          }

          {/* Descriptions */}
          <Markdown
            className={styles.description}
            text={description}
          />

          {/* Stats - Desktop Only */}
          {
            !isMd && (
              <RecipeStats
                className={styles.stats}
                statClassName={styles.stat}
                stats={[
                  stats.preparationTime,
                  stats.cookingTime,
                  stats.servings,
                  stats.totalTime,
                ]}
              />
            )
          }

        </div>

        {/* Details */}
        <div className={styles.details}>

          {/* Text Ingredients */}
          <RecipeDetailsTextIngredients
            className={classnames(styles.section, styles.textIngredients)}
            ingredients={ingredients?.text}
          />

          {/* Extra Ingredients */}
          <RecipeDetailsIngredientsCarousel
            className={classnames(styles.section, styles.extraIngredients)}
            ingredientsListing={ingredients?.extra}
            breakpoints={INGREDIENTS_CAROUSEL_BREAKPOINTS}
          />

          {/* Instructions */}
          <RecipeDetailsInstructions
            className={classnames(styles.section, styles.instructions)}
            instructions={instructions}
            onEnter={() => {
              analytics.viewRecipeCookingSteps(recipeId);
            }}
          />

          {/* Video */}
          <RecipeDetailsVideo
            className={classnames(styles.section, styles.video)}
            recipeId={recipeId}
            video={video}
          />

          {/* Secondary Ingredients */}
          <RecipeDetailsIngredientsCarousel
            className={classnames(styles.section, styles.secondaryIngredients)}
            ingredientsListing={ingredients?.secondary}
            breakpoints={INGREDIENTS_CAROUSEL_BREAKPOINTS}
          />

          {/* Tips */}
          <RecipeDetailsTips
            className={classnames(styles.section, styles.tips)}
            tips={tips}
          />

          {/* Tags */}
          <RecipeTags
            className={styles.tags}
            tagsClassName={styles.tagsWrapper}
            tags={tags}
          />

          {/* Related */}
          <RecipeDetailsSimilarRecipesCarousel
            className={classnames(styles.section, styles.related)}
            recipes={related}
            breakpoints={RECIPE_DETAILS_CAROUSEL_BREAKPOINTS}
            onRecipeViewIngredientsClick={(recipeCard, e) => {
              handleRecipeViewIngredientsClick(recipeCard, e);
            }}
          />

        </div>

      </div>

      {/* Modal */}
      <RecipeIngredientsModal
        isOpen={isModalOpen}
        //
        title={selectedRecipeCard?.title}
        recipeId={selectedRecipeCard?.id}
        recipeSlug={selectedRecipeCard?.slug}
        //
        onClose={() => {
          closeModal();
        }}
        onAfterClose={() => {
          setSelectedRecipeCard(null);
        }}
      />

    </>
  );
};

// Export
export default RecipeDetails;
