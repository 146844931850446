import React from 'react';
import classnames from 'classnames';

import { RecipeCarousel, RecipeIngredientsModal } from 'components/recipe';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useIsOpen } from 'hooks/useIsOpen';
import { toRecipeCard } from 'services/RecipeService/RecipeAdapter';

import {
  type IHomepageRecipesPromoCarouselLink, type IRecipeBreakpointParams, type IRecipeCard, type IRecipeCardData
} from 'types';

import styles from './RecipeCarouselSlot.module.scss';

interface Props {
  className?: string,
  carouselClassName?: string,
  carouselSlidesClassName?: string,
  carouselSlidesWrapperClassName?: string,
  //
  index?: number,
  analyticsListId?: string,
  analyticsListName?: string,
  //
  recipes: IRecipeCardData[],
  title: string,
  link?: IHomepageRecipesPromoCarouselLink,
  breakpoints?: Record<number, IRecipeBreakpointParams>,
  isQuickView?: boolean,
  //
  dataTestId?: string
}

const RecipeCarouselSlot = (props: Props) => {

  const {
    className,
    carouselClassName, carouselSlidesClassName, carouselSlidesWrapperClassName,
    //
    index,
    analyticsListId,
    analyticsListName,
    //
    recipes,
    title,
    link,
    breakpoints,
    isQuickView,
    //
    dataTestId,
  } = props;

  const recipesCards = recipes.map((recipe) => toRecipeCard(recipe));

  const [selectedRecipeCard, setSelectedRecipeCard] = React.useState<IRecipeCard>();
  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useIsOpen();

  // Handlers

  const handleRecipeViewIngredientsClick = (recipeCard: IRecipeCard, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRecipeCard(recipeCard);
    openModal();
  };

  // Empty

  if (isArrayEmpty(recipes)) {
    return null;
  }

  // Render

  return (
    <>
      <div
        className={classnames(styles.root, className)}
        data-testid={dataTestId}
      >
        {/* Carousel */}
        <RecipeCarousel
          //
          headerClassName={styles.header}
          titleClassName={styles.title}
          carouselClassName={classnames(styles.carousel, carouselClassName)}
          carouselSlidesClassName={classnames(styles.carouselSlides, carouselSlidesClassName)}
          carouselSlidesWrapperClassName={classnames(styles.carouselSlidesWrapper, carouselSlidesWrapperClassName)}
          //
          index={index}
          listProps={{
            analyticsListId,
            analyticsListName
          }}
          //
          title={title}
          link={link}
          recipes={recipesCards}
          breakpoints={breakpoints}
          isQuickView={isQuickView}
          //
          onRecipeViewIngredientsClick={handleRecipeViewIngredientsClick}
          //
        />
      </div>

      {/* Modal */}
      <RecipeIngredientsModal
        isOpen={isModalOpen}
        //
        title={selectedRecipeCard?.title}
        recipeId={selectedRecipeCard?.id}
        recipeSlug={selectedRecipeCard?.slug}
        priority={5}
        //
        onClose={() => {
          closeModal();
        }}
        onAfterClose={() => {
          setSelectedRecipeCard(undefined);
        }}
      />
    </>
  );
};

export default RecipeCarouselSlot;
