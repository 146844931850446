import type { IListingRequestParams } from 'types';

export function createQueryKey(arr: any[]): string[] {
  return arr.map((k) => (k ? k.toString() : 'null'));
}

export function createListingQueryKey(key: string, params: IListingRequestParams, payload?: any[]) {
  const {
    page,
    itemsPerPage,
    filters,
    orderBy,
    searchQuery,
    category,
    identifier,
  } = params || {};

  const filtersKey = filters ? JSON.stringify(filters) : 'no-filters';
  const orderByKey = orderBy ? JSON.stringify(orderBy) : 'no-orderby';
  const searchQueryKey = searchQuery || 'no-search';

  return createQueryKey([
    key,
    page,
    itemsPerPage,
    category,
    identifier,
    filtersKey,
    orderByKey,
    searchQueryKey,
    ...(payload || [])
  ]);
}
