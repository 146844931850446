import { objectReduce } from 'helpers/ObjectHelpers';

import type { IPlaceDetails, Nullable, PlaceDetailsQuery } from 'types';

// Types

type SearchPlaceAddressParams = [street?: string, number?: string]

// Constants

const MIN_QUERY_LENGTH = 1;

const cleanNumber = (number: string) => {
  return number ? number.replace(/Nr /g, '') : '';
};

export function parsePlaceDetails(placeDetails: IPlaceDetails) {
  if (!placeDetails) return {};

  const {
    placeId: id, streetName: street, streetNumber: number, city, province, geo
  } = placeDetails;

  const { lat, long: lng } = geo || {};

  return {
    id, street, number: cleanNumber(number), city, province, lat, lng
  };
}

export function arePlaceSearchParamsValid(params: Nullable<PlaceDetailsQuery>) {
  const { id, ...rest } = params || {};
  return !!id || Object.values(rest).some((v) => !!v);
}

export function getPlaceSearchAddress(params: SearchPlaceAddressParams) {
  return encodeURIComponent(
    Object.values(params)
      .filter((v) => !!v)
      .join(' ')
  );
}

export function getPlaceSearchQuery(params: PlaceDetailsQuery) {
  return objectReduce(
    params,
    (acc, key, value, index) => {
      const prefix = index !== 0 ? '&' : '';
      const current = value ? `${key}=${encodeURIComponent(value)}` : '';
      return `${acc}${prefix}${current}`;
    }
  );
}

export function isPlaceQueryValid(query: Nullable<string>) {
  if (!query) return false;
  return query.length >= MIN_QUERY_LENGTH;
}
