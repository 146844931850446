import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getRecipeIngredientsListing } from 'services/RecipeService/RecipeService';

import type { IProductsListing, ReactQueryOptions } from 'types';

export const useRecipeIngredientsListing = (slug: string, count: number, options: ReactQueryOptions<IProductsListing> = {}) => {
  return useQuery({
    queryKey: getKey(slug, count),
    queryFn: () => getRecipeIngredientsListing(slug, count),
    ...options
  });
};

export const getKey = (slug: string, count: number) => {
  return createQueryKey([
    QUERY_KEYS.RECIPES_INGREDIENTS_LISTING,
    slug,
    count
  ]);
};
