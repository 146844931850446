import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Badge, Button, Icon, Image, Link, Popover
} from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import styles from './NavigationLink.module.scss';

const NavigationLink = (props) => {

  const {
    className, activeClassName, labelClassName,
    item = {},
    withPopover,
    children,
    onClick = () => {},
    ...rest
  } = props;

  // With Popover

  if (withPopover) {
    return (
      <NavigationLinkWithPopover
        className={className}
        item={item}
      />
    );
  }

  return (
    <NavigationLinkWithoutPopover
      item={item}
      className={className}
      activeClassName={activeClassName}
      labelClassName={labelClassName}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NavigationLinkWithoutPopover>
  );
};

// --- Without Popover

const NavigationLinkWithoutPopover = (props) => {

  const {
    item,
    className,
    activeClassName,
    labelClassName,
    onClick,
    children,
    ...rest
  } = props;

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();

  // Props

  const {
    href, exact,
    icon, image, label, badge,
    translate = true
  } = item;

  const isActive = href === '/'
    ? router.asPath === href
    : exact
      ? router.asPath === href
      : router.asPath.startsWith(href);

  // Classes

  const classes = classnames(
    styles.root,
    { [styles.active]: isActive },
    { [activeClassName]: isActive },
    className
  );

  // Content

  const content = (
    <>
      {
        image && (
          <span className={styles.image}>
            <Image
              src={image}
              width={24}
              height={24}
            />
          </span>
        )
      }
      {
        icon && (
          <Icon
            className={styles.icon}
            name={icon}
            size={24}
          />
        )
      }
      <span className={classnames(styles.label, labelClassName)}>{translate ? t(label) : label}</span>
      {
        badge && (
          <Badge
            className={styles.badge}
            style={{
              backgroundColor: badge.backgroundColor,
              color: badge.textColor
            }}
          >
            {t(badge.text)}
          </Badge>
        )
      }
    </>
  );

  // Without Link

  if (!href) {
    return (
      <button
        type="button"
        className={classes}
        onClick={onClick}
        {...rest}
      >
        {content}
        {children}
      </button>
    );
  }

  // With Link

  return (
    <Link
      href={href}
      prefetch={false}
      passHref
    >
      <Button
        tag="a"
        className={classes}
        onClick={onClick}
      >
        {content}
        {children}
      </Button>
    </Link>
  );
};

// --- With Popover

const NavigationLinkWithPopover = (props) => {

  const {
    className,
    item,
  } = props;

  // Props

  const {
    label,
    translate = true,
    children
  } = item || {};

  // Refs

  const ref = React.useRef();

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();

  const { isOpen, close } = useIsOpen();

  // Effects

  React.useEffect(() => {
    if (isOpen) {
      close();
    }
  }, [router.asPath]);

  // Render

  return (
    <div ref={ref} className={styles.wrapper}>
      <Popover
        isOpen={isOpen}
        persist
        parentElement={ref || undefined}
        className={styles.inner}
        contentClassName={styles.popover}
        align="center"
        positions={['bottom']}
        padding={8}
        withArrow
        withHover
        content={(
          <div className={styles.popoverContent}>
            {
              children.map((child, index) => {
                return (
                  <div key={child?.href || index} className={styles.popoverItemWrapper}>
                    <NavigationLink
                      className={styles.popoverItem}
                      item={child}
                      exact={child.exact}
                    />
                  </div>
                );
              })
            }
          </div>
        )}
        onClickOutside={close}
      >
        <div className={classnames(styles.root, styles.withPopover, className)}>
          {translate ? t(label) : label}
        </div>
      </Popover>
    </div>
  );
};

// Prop Types

NavigationLink.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  activeClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  skeleton: PropTypes.bool,
  withImage: PropTypes.bool,
  item: PropTypes.shape({
    href: PropTypes.string,
    exact: PropTypes.bool,
    slug: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
      exact: PropTypes.bool,
      slug: PropTypes.string,
      label: PropTypes.string,
      translate: PropTypes.bool
    })),
  }),
  withPopover: PropTypes.bool,
};

// Export

export default NavigationLink;
