import React from 'react';
import classnames from 'classnames';

import styles from './Badge.module.scss';

export const TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  CUSTOM: 'custom'
} as const;

type Type = typeof TYPES[keyof typeof TYPES];

interface Props {
  className?: string,
  type?: Type,
  children: React.ReactNode,
  style?: React.CSSProperties
}

const Badge = (props: Props) => {

  const {
    className, type = TYPES.CUSTOM, children, style, ...rest
  } = props;

  const classes = classnames(
    styles.root,
    { [styles[type]]: type !== TYPES.CUSTOM },
    className,
  );

  return (
    <div className={classes} style={style} {...rest}>
      {children}
    </div>
  );
};

export default Badge;
