import React from 'react';

import { theme } from 'constants/theme';
import { useBreakpointsDispatch } from 'context/BreakpointsContext';
import { useMediaQuery } from 'hooks/useMediaQuery';

const BreakpointsObserver = () => {

  const { setBreakpoint } = useBreakpointsDispatch();

  const isSmMax = useMediaQuery({ direction: 'down', breakpoint: theme.breakpoints.sm });

  const isMd = useMediaQuery({ direction: 'up', breakpoint: theme.breakpoints.md });
  const isMdMax = useMediaQuery({ direction: 'down', breakpoint: theme.breakpoints.mdMax });

  const isLg = useMediaQuery({ direction: 'up', breakpoint: theme.breakpoints.lg });
  const isLgMax = useMediaQuery({ direction: 'down', breakpoint: theme.breakpoints.lgMax });

  const isXl = useMediaQuery({ direction: 'up', breakpoint: theme.breakpoints.xl });
  const isXlMax = useMediaQuery({ direction: 'down', breakpoint: theme.breakpoints.xlMax });

  // SM Max
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isSmMax', value: isSmMax });
  }, [isSmMax]);

  // MD min
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isMd', value: isMd });
  }, [isMd]);

  // MD max
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isMdMax', value: isMdMax });
  }, [isMdMax]);

  // LG min
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isLg', value: isLg });
  }, [isLg]);

  // LG max
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isLgMax', value: isLgMax });
  }, [isLgMax]);

  // XL min
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isXl', value: isXl });
  }, [isXl]);

  // XL max
  React.useEffect(() => {
    setBreakpoint({ breakpoint: 'isXlMax', value: isXlMax });
  }, [isXlMax]);

  return null;
};

export default BreakpointsObserver;
