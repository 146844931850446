import React from 'react';

import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';

import ProductToastBody from '../ProductToastBody/ProductToastBody';

import type { IAnalyticsListProps, IOrder, IOrderProduct } from 'types';

interface Props {
  order: IOrder,
  orderItem: IOrderProduct,
  listProps?: IAnalyticsListProps,
  onSuccess: () => void
}

const ProductDeleteToastBody = (props: Props) => {
  const {
    order,
    orderItem,
    listProps = {},
    onSuccess,
  } = props;

  const {
    sku,
    isOk,
    quantity,
    productName: name,
    image
  } = orderItem || {};

  // Refs

  const currentSkuRef = React.useRef<string>();

  // Hooks

  const { t } = useTranslation();

  const { add: addProduct, isLoading: isMutationLoading } = useOrderProductMutation();

  const [isSuccess, setIsSuccess] = React.useState(false);

  // Effects

  React.useEffect(() => {
    if (isSuccess && currentSkuRef.current === sku) {
      ProductHelpers.dismissDeleteNotification();
    } else {
      setIsSuccess(false);
    }
  }, [sku, isSuccess]);

  // Handlers

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
    setIsSuccess(true);
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    currentSkuRef.current = sku;
    addProduct({
      order,
      product: undefined,
      orderItem,
      listProps,
      quantity,
      //
      bypassQuantityVariants: true,
      bypassAddressRequirement: true,
      onSuccess: () => {
        handleSuccess();
      },
    });
  };

  // Render

  return (
    <ProductToastBody
      product={{ name, image } as any} // TODO: fix type
      message={t('NOTIFICATIONS.PRODUCT_DELETED')}
      button={
        !isOk
          ? undefined
          : {
            props: {
              disabled: isMutationLoading || isSuccess,
              onClick: handleAdd
            },
            label: t('NOTIFICATIONS.PRODUCT_DELETED_UNDO')
          }
      }
    />
  );
};

export default ProductDeleteToastBody;
