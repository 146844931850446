import React from 'react';
import classnames from 'classnames';

import { useTranslation } from 'hooks/common/useTranslation';

import Button from '../Button/Button';

import styles from './Spoiler.module.scss';

interface Props {
  id: string,
  className?: string,
  expandClassName?: string,
  height: number,
  expanded?: boolean,
  label?: string,
  desktop?: boolean,
  mobile?: boolean,
  children: React.ReactNode
}

const Spoiler = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const {
    id, className, expandClassName, height = 30, expanded = false,
    label = 'SPOILER.LABEL', desktop = true, mobile = true,
    children
  } = props;

  const contentRef = React.useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = React.useState(expanded);
  const [hasOverflow, setHasOverflow] = React.useState(true);
  const [expandedHeight, setExpandedHeight] = React.useState(0);

  const { t } = useTranslation();

  const classes = classnames(
    styles.root,
    { [styles.expanded]: isExpanded },
    { [styles.desktop]: desktop },
    { [styles.mobile]: mobile },
    className
  );

  React.useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  React.useEffect(() => {
    setExpanded(false);
  }, [id]);

  React.useEffect(() => {
    const contentHeight = contentRef?.current?.clientHeight;
    if (contentHeight) {
      setHasOverflow(contentHeight > height);
      setExpandedHeight(contentHeight);
    }
  }, [id, contentRef.current, height, isExpanded]);

  return (
    <div ref={ref} className={classes} style={{ maxHeight: isExpanded ? expandedHeight : height }}>
      <div ref={contentRef} className={styles.content}>
        {children}
      </div>
      {
        hasOverflow && (
          <div className={classnames(styles.expand, expandClassName)}>
            <Button
              className={styles.expandBtn}
              variant="empty"
              size="small"
              endIcon="chevron-down"
              onClick={() => setExpanded(true)}
            >
              {t(label)}
            </Button>
          </div>
        )
      }
    </div>
  );
});

export default Spoiler;
