import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Image, Link } from 'components/ui';
import { formatBlogDate } from 'helpers/DateHelpers';

import styles from './BlogCard.module.scss';

const BlogCard = (props) => {

  const {
    date, title, image, href = '/'
  } = props;

  const { t } = useTranslation();

  return (
    <Link href={href}>
      <a className={styles.root}>
        <div className={styles.imageContainer}>
          <Image
            src={image}
            height={574}
            width={764}
            optimize
          />
        </div>
        <div className={styles.date}>
          {formatBlogDate(date)}
        </div>
        <h2 className={styles.title}>
          {title}
        </h2>
        <Button
          className={classnames(styles.navBtn)}
          endIcon="arrow-narrow-right"
          iconSize={24}
        >
          {t('blog:SEE_MORE')}
        </Button>
      </a>
    </Link>
  );
};

export default BlogCard;
