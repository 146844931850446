import { LOCAL_STORAGE_LEGACY_KEYS, SESSION_STORAGE_LEGACY_KEYS } from 'constants/storage';
import { safeCallClient } from 'helpers/utils';

function setLocalStorage(key: string, item: unknown) {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(item));
  }
}

function getLocalStorage(key: string) {
  const raw = localStorage.getItem(key);
  return raw ? JSON.parse(raw) : null;
}

function removeLocalStorage(key: string) {
  if (localStorage) {
    localStorage.removeItem(key);
  }
}

function clearLocalStorage() {
  if (localStorage) {
    localStorage.clear();
  }
}

function setSessionStorage(key: string, item: unknown) {
  if (sessionStorage) {
    sessionStorage.setItem(key, JSON.stringify(item));
  }
}

function getSessionStorage(key: string) {
  const raw = sessionStorage.getItem(key);
  return raw ? JSON.parse(raw) : null;
}

function removeSessionStorage(key: string) {
  if (sessionStorage) {
    sessionStorage.removeItem(key);
  }
}

function clearSessionStorage() {
  if (sessionStorage) {
    sessionStorage.clear();
  }
}

export const LocalStorage = {
  set: (...args: Parameters<typeof setLocalStorage>) => safeCallClient(setLocalStorage, args),
  get: (...args: Parameters<typeof getLocalStorage>) => safeCallClient(getLocalStorage, args),
  remove: (...args: Parameters<typeof removeLocalStorage>) => safeCallClient(removeLocalStorage, args),
  clear: (...args: Parameters<typeof clearLocalStorage>) => safeCallClient(clearLocalStorage, args)
};

export const SessionStorage = {
  set: (...args: Parameters<typeof setSessionStorage>) => safeCallClient(setSessionStorage, args),
  get: (...args: Parameters<typeof getSessionStorage>) => safeCallClient(getSessionStorage, args),
  remove: (...args: Parameters<typeof removeSessionStorage>) => safeCallClient(removeSessionStorage, args),
  clear: (...args: Parameters<typeof clearSessionStorage>) => safeCallClient(clearSessionStorage, args)
};

export const clear = () => {
  clearLocalStorage();
  clearSessionStorage();
};

export const clearLegacyKeys = () => {
  clearLocalStorageLegacyKeys();
  clearSessionStorageLegacyKeys();
};

const clearLocalStorageLegacyKeys = () => {
  try {
    const keys = Object.values(LOCAL_STORAGE_LEGACY_KEYS);
    keys.forEach((key) => LocalStorage.remove(key));
  } catch (err) {
    console.error('Failed to clear local storage legacy keys', err);
  }
};

const clearSessionStorageLegacyKeys = () => {
  try {
    const keys = Object.values(SESSION_STORAGE_LEGACY_KEYS);
    keys.forEach((key) => LocalStorage.remove(key));
  } catch (err) {
    console.error('Failed to clear session storage legacy keys', err);
  }
};
