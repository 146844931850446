import React from 'react';

import { Link, ModalBody, Skeleton } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import * as RecipeHelpers from 'helpers/RecipeHelpers';
import { showRecipeAddIngredientsNotification } from 'helpers/RecipeHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useOrderProductMutation } from 'hooks/data/useOrderProductMutation';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';

import RecipeBanner from '../RecipeBanner/RecipeBanner';
import RecipeIngredientsList from '../RecipeIngredientsList/RecipeIngredientsList';
import RecipePrice from '../RecipePrice/RecipePrice';

import type {
  IOrder,
  IRecipe,
  IRecipeIngredientProduct,
  IRecipePrimaryIngredients
} from 'types';

import styles from './RecipeIngredientsModalListStep.module.scss';

interface Props {
  isLoadingIngredients: boolean,
  //
  title?: string,
  //
  order: IOrder,
  recipe: IRecipe,
  //
  primaryIngredients: IRecipePrimaryIngredients,
  ingredientProducts: IRecipeIngredientProduct[],
  //
  onReplaceIngredientClick: (ingredientProduct: IRecipeIngredientProduct) => void,
  onClose: () => void
}

const RecipeIngredientsModalListStep = (props: Props) => {
  const {
    isLoadingIngredients,
    //
    title,
    //
    order,
    recipe,
    primaryIngredients,
    ingredientProducts,
    //
    onClose,
    onReplaceIngredientClick,
  } = props;

  // Hooks

  const { t } = useTranslation();

  const analytics = useAnalytics();

  const isSm = useMediaQuery(`(max-width: ${styles.breakpointSm})`);

  // Mutation Hooks

  const {
    isLoading: isMutationLoading,
    bulkAdd: bulkAddProducts
  } = useOrderProductMutation();

  // Props

  const {
    meta,
    analyticsListProps,
    stats,
    banner
  } = recipe || {};

  const {
    id: recipeId,
    slug
  } = meta || {};

  const {
    pricePerServing,
    originalPricePerServing,
    servings: {
      value: servings
    } = {
      value: null
    }
  } = stats || {};

  // Props

  const descriptionText = servings === 1
    ? t('recipes:RECIPE_INGREDIENTS_MODAL.LIST.DESCRIPTION.TEXT_SINGULAR')
    : t('recipes:RECIPE_INGREDIENTS_MODAL.LIST.DESCRIPTION.TEXT_PLURAL', { servings });

  const addIngredientsBtnLabel = servings === 1
    ? t('recipes:GENERAL.ACTIONS.ADD_INGREDIENTS_SINGULAR', { servings })
    : t('recipes:GENERAL.ACTIONS.ADD_INGREDIENTS_PLURAL', { servings });

  const canBulkAddIngredients = React.useMemo(() => {
    return RecipeHelpers.canBulkAddIngredients(order, ingredientProducts);
  }, [order, ingredientProducts]);

  // Effects

  React.useEffect(() => {
    if (isArrayEmpty(ingredientProducts)) return;

    if (!canBulkAddIngredients) {
      analytics.viewRecipeOutOfStockIngredients(recipeId);
    }
  }, [ingredientProducts, canBulkAddIngredients]);

  // Handlers

  const handleAddRecipeIngredients = React.useCallback(() => {
    const products = ingredientProducts.map(({ ingredient, product }) => {
      const { quantity = 0 } = ingredient || {};
      return { quantity, product };
    });

    bulkAddProducts({
      order,
      products,
      bypassAddressRequirement: true,
      listProps: analyticsListProps,
      onSuccess: () => {
        showRecipeAddIngredientsNotification(recipe);
        analytics.bulkAddToCart(products, analyticsListProps);
      }
    });
  }, [order, ingredientProducts, analyticsListProps]);

  const handleReplaceIngredientClick = (ingredientProduct: IRecipeIngredientProduct) => {
    if (onReplaceIngredientClick) {
      onReplaceIngredientClick(ingredientProduct);
    }
  };

  // Render

  return (
    <ModalBody
      className={styles.root}
      headerProps={{
        className: styles.header,
        title: (
          <div className={styles.title}>
            <p className={styles.text}>
              {title}
            </p>
            {
              !!pricePerServing?.value && (
                <RecipePrice
                  className={styles.recipePrice}
                  priceClassName={styles.price}
                  originalPriceClassName={styles.originalPrice}
                  perServingLabelClassName={styles.perServingLabel}
                  pricePerServing={pricePerServing?.value}
                  originalPricePerServing={originalPricePerServing?.value}
                />
              )
            }
          </div>
        ),
        desktopProps: {
          size: 'large',
          withCloseButton: true
        },
        responsiveProps: {
          size: 'medium',
          withCloseButton: true
        },
        onClose,
      }}
      actionsProps={{
        primary: {
          icon: 'cart-plus',
          text: addIngredientsBtnLabel,
          disabled: !canBulkAddIngredients || isMutationLoading,
          loading: isMutationLoading,
          onClick: () => {
            handleAddRecipeIngredients();
          }
        }
      }}
    >

      {/* Promo Banner */}
      {!!banner && (
        <div className={styles.promoBannerContainer}>
          <RecipeBanner className={styles.promoBanner} tall banner={banner} />
        </div>
      )}

      {/* Description */}
      <div className={styles.description}>
        {
          !recipe
            ? (
              <>
                {/* Loading Skeleton */}
                <Skeleton count={3} />
              </>
            ) : (
              <>
                {/* Text */}
                <span
                  dangerouslySetInnerHTML={{ __html: descriptionText }}
                />
                {' '}

                {/* Link */}
                <span className={styles.link}>
                  <Link href={`${APP_ROUTES.RECIPES_DETAILS}/${slug}`} passHref>
                    <a>
                      {t('recipes:RECIPE_INGREDIENTS_MODAL.LIST.DESCRIPTION.LINK')}
                    </a>
                  </Link>
                </span>
              </>
            )
        }

      </div>

      {/* List */}
      <RecipeIngredientsList
        className={styles.list}
        itemClassName={styles.item}
        //
        disabled={isMutationLoading}
        isLoading={isLoadingIngredients}
        loadingCount={primaryIngredients?.length}
        //
        order={order}
        ingredientProducts={ingredientProducts}
        //
        cardProps={{
          listProps: analyticsListProps,
          withExternalInfo: isSm,
          withVerboseActions: !isSm,
        }}
        //
        onReplaceIngredientClick={(ingredientProduct: IRecipeIngredientProduct) => {
          handleReplaceIngredientClick(ingredientProduct);
        }}
      />

    </ModalBody>
  );
};

export default RecipeIngredientsModalListStep;
