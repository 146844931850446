import { APP_ROUTES } from 'constants/routes';

export function getProductLink(slug?: string, isAcceleratedSale?: boolean) {
  if (!slug) return null;

  return isAcceleratedSale
    ? `${APP_ROUTES.PRODUCTS}/${slug}?sm=true`
    : `${APP_ROUTES.PRODUCTS}/${slug}`;
}

export function getSameBrandLink(brand: string, brandFilterCode?: string) {
  return `${APP_ROUTES.SEARCH}/${brand}/filters/brand+${brandFilterCode}`;
}
