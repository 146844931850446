import React from 'react';
import classnames from 'classnames';

import { ShareButton } from 'components/ui';
import { isClient } from 'helpers/BrowserHelpers';
import { useBreakpoint } from 'hooks/useBreakpoint';

import styles from './RecipeShareButtons.module.scss';

interface Props {
  className?: string
}

const RecipeShareButtons = (props: Props) => {

  const { className } = props;

  const isVisible = useBreakpoint('md', 'down');

  if (!isClient() || !isVisible) return null;

  const url = window.location.toString();

  return (
    <div className={classnames(styles.root, className)}>
      <ShareButton
        variant="link"
        wrapperClassName={styles.btnWrapper}
        link={url}
      />
      <ShareButton
        wrapperClassName={styles.btnWrapper}
        variant="whatsapp"
        url={url}
      />
      <ShareButton
        wrapperClassName={styles.btnWrapper}
        variant="facebook"
        url={url}
      />
    </div>
  );
};

export default RecipeShareButtons;
