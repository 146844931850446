import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Icon, Modal } from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import OrderByOptions from './OrderByOptions';

import type { IListingOrderBy, IListingOrderByOption, IUrl } from 'types';

import styles from './OrderByMobile.module.scss';

interface Props {
  className?: string,
  //
  label?: string,
  orderBy: IListingOrderBy,
  //
  getHref: (option: IListingOrderByOption) => IUrl
}

const OrderByMobile = (props: Props) => {

  const {
    className,
    //
    label,
    orderBy,
    //
    getHref,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const { isOpen, open, close, } = useIsOpen();

  // Handlers

  const onOptionChange = () => {
    close();
  };

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
    >

      {/* Select Button */}
      <Button
        className={styles.selectBtn}
        type="button"
        endIcon={label ? SortIcon : ChevronIcon}
        onClick={open}
      >
        {label || t('LISTING_TOOLBAR.ORDER_BY.LABEL_SHORT')}
      </Button>

      {/* Modal */}
      <Modal
        isOpen={isOpen}
        className={styles.modal}
        contentClassName={styles.modalContent}
        title={t('LISTING_TOOLBAR.ORDER_BY.LABEL_SHORT')}
        breakpoint="md"
        responsiveProps={{
          position: 'bottom'
        }}
        close={close}
      >
        <div className={styles.modalBody}>
          <OrderByOptions
            orderBy={orderBy}
            onChange={onOptionChange}
            getHref={getHref}
          />
        </div>
      </Modal>

    </div>
  );
};

// Icons

const SortIcon = <Icon size={20} name="arrows-sort" className={styles.sortIcon} />;
const ChevronIcon = <Icon size={16} name="chevron-down" className={styles.chevronIcon} />;

// Export

export default OrderByMobile;
