import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { SCROLL_LOCK_IGNORE_CLASS } from 'helpers/ScrollHelpers';

import ModalActions from './ModalActions';

import ModalHeader from './ModalHeader';

import type { Props as ModalActionsProps } from './ModalActions';
import type { Props as ModalHeaderProps } from './ModalHeader';

import styles from './ModalBody.module.scss';

export interface Props {
  className?: string,
  childrenClassName?: string,
  //
  headerProps?: ModalHeaderProps,
  actionsProps?: ModalActionsProps,
  //
  pre?: React.ReactNode,
  children?: React.ReactNode,
  post?: React.ReactNode
}

const ModalBody = forwardRef<HTMLDivElement, Props>((
  props: Props,
  ref: React.MutableRefObject<HTMLDivElement>
) => {

  const {
    className,
    childrenClassName,
    //
    headerProps,
    actionsProps,
    //
    pre,
    children,
    post,
  } = props;

  return (
    <div
      ref={ref}
      className={classnames(styles.root, className)}
    >
      {/* Pre */}
      {pre}

      {/* Header */}
      {
        headerProps && (
          <ModalHeader
            withCloseButton={false}
            {...headerProps}
          />
        )
      }

      {/* Children */}
      {
        children && (
          <div
            className={classnames(
              styles.children,
              SCROLL_LOCK_IGNORE_CLASS,
              childrenClassName,
            )}
          >
            {children}
          </div>
        )
      }

      {/* Actions */}
      {
        actionsProps && (
          <ModalActions
            {...actionsProps}
          />
        )
      }

      {/* Post */}
      {post}

    </div>
  );
});

export default ModalBody;
