import { ERROR_CODES } from 'constants/errors';

import type { FormikHelpers } from 'formik';
import { isHttpClientError } from 'types';

export const IBAN_REGEX = /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/;
export const NUMBERS_ONLY_REGEX = /^\d+$/;
export const LETTERS_ONLY_REGEX = /^[a-zA-Z]+$/;
export const LETTERS_AND_SPACES_REGEX = /^[a-zA-Z\s]*$/;
export const STRING_MAX_LENGTH = 255;
export const NAME_REGEX = /^[a-z\u00C0-\u024F\u1E00-\u1EFF\u0400-\u04FF\s-']+$/i;
export const STREET_NUMBER_REGEX = /^([0-9]+[0-9A-Za-z\-_]*)$/;
export const STREET_DETAILS_REGEX = /^[0-9a-z\u00C0-\u024F\u1E00-\u1EFF\u0400-\u04FF\s-',.-]+$/i;
export const PHONE_NUMBER_REGEX = /^((\+)?\d{2})?(\s\(|\s|\()?(\d{3,4})(\)\s|\s)?(\s)?(\d{3,4})(\s)?(\d{3,4})$/;
export const PHONE_NUMBER_RO_REGEX = /^(\+4)?(0)?(7)(\d{8})$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type Violations = Violation[];
type Violation = {
  code: null | string,
  message: string,
  propertyPath: string
}
export type ViolationError = {
  violations: Violations,
  detail?: string,
  title?: string,
  type?: string
}

export function handleValidationError(error: ViolationError, formikProps: FormikHelpers<any>) {
  const { violations = [] } = error;
  violations.forEach((violation) => {
    const { message, propertyPath } = violation;
    const { setFieldError } = formikProps;
    setFieldError(propertyPath, message);
  });
}

export function isValidationError(err: any) {
  return isHttpClientError(err) && err.response?.status === ERROR_CODES.VALIDATION_ERROR;
}

export function getDefaultValidationErrorMessage(err: any) {
  if (!isValidationError(err)) return '';
  const { violations = [] } = err?.response?.data as ViolationError || {};
  return violations[0]?.message;
}
