import { pushEvent } from './AnalyticsHelpers';

import type { IPushEventOptions } from './AnalyticsHelpers';

enum Events {
  PAGE_VIEW = 'page_refresh'
}

// Helpers

function push(event: Events, payload: Record<string, any>, options: IPushEventOptions) {
  pushEvent({
    event,
    ...payload
  }, options);
}

function generateUniqueToken() {
  return `${String(Date.now())}${Math.floor(Math.random() * 90000) + 10000}`;
}

// Events

export function pageView(url: string) {
  push(Events.PAGE_VIEW, {
    unique_token: generateUniqueToken(),
  }, {
    isClientOnly: false,
    shouldReset: true,
    shouldSendNullEvent: false,
    logMeta: { meta: `History Change - ${url}` }
  });
}
