import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Accordion, Button } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import FilterOptions from './FilterOptions';

import type { IListingFilter, IListingFilterOption, IUrl } from 'types';

import styles from './FilterAccordion.module.scss';

interface Props {
  className?: string,
  headerClassName?: string,
  headerTitleClassName?: string,
  headerLabelClassName?: string,
  contentClassName?: string,
  optionClassName?: string,
  //
  filter: IListingFilter,
  multiple?: boolean,
  //
  withBorders?: boolean,
  withClearButton?: boolean,
  withAnimation?: boolean,
  //
  optionsProps?: Omit<React.ComponentProps<typeof FilterOptions>, 'parent' | 'options' | 'getHref'>,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  clearFilter?: (filter: IListingFilter) => void
}

const FilterAccordion = (props: Props) => {

  const {
    className,
    headerClassName,
    headerTitleClassName,
    headerLabelClassName,
    contentClassName,
    optionClassName,
    //
    filter,
    multiple = true,
    //
    withBorders,
    withClearButton,
    withAnimation = true,
    //
    optionsProps = {},
    //
    getHref,
    clearFilter,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const isXSmallBreakpoint = useMediaQuery(`(max-width: ${styles.breakpointXsm})`);

  // Props

  const {
    name,
    values = [],
    countSelected: selectedCount
  } = filter;

  // Empty

  if (!Array.isArray(values) || values.length === 0) return null;

  // Render

  return (
    <Accordion
      className={classnames(
        styles.root,
        { [styles.bordered]: withBorders },
        className
      )}
      headerClassName={classnames(
        styles.header,
        headerClassName
      )}
      headerInnerClassName={styles.inner}
      initiallyExpanded
      iconStrokeWidth={2}
      withAnimation={withAnimation}
      title={(
        <div
          className={classnames(
            styles.title,
            headerTitleClassName
          )}
        >

          {/* Label */}
          <div
            className={classnames(
              styles.label,
              headerLabelClassName
            )}
          >
            {name}

            {/* Count */}
            {
              (selectedCount > 0) && (
                <div className={styles.count}>{selectedCount}</div>
              )
            }
          </div>

          {/* Clear Button */}
          {
            (withClearButton && selectedCount > 0) && (
              <Button
                className={styles.clearBtn}
                variant="empty"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilter(filter);
                }}
              >
                {
                  isXSmallBreakpoint
                    ? t('ACTIONS.DELETE')
                    : t('LISTING_TOOLBAR.FILTER_BY.CLEAR_CURRENT')
                }
              </Button>
            )
          }
        </div>
      )}
    >
      {/* Content */}
      <div
        className={classnames(
          styles.content,
          contentClassName
        )}
      >

        {/* Options */}
        <FilterOptions
          className={styles.options}
          optionClassName={classnames(styles.option, optionClassName)}
          noResultsClassName={styles.noResults}
          searchClassName={styles.search}
          scrollbarClassName={styles.scrollbar}
          //
          parent={filter}
          options={values}
          multiple={multiple}
          //
          withScrollbar
          withSearch
          //
          getHref={getHref}
          //
          {...optionsProps}
        />

      </div>
      <div />
    </Accordion>
  );
};

export default FilterAccordion;
