import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'announcement_banner'; // TODO: align to _events prefix format

enum Actions {
  SELECT = 'select',
  DISMISS = 'dismiss'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Select Announcement Banner

export function selectAnnouncementBanner(bgColor: string, text: string) {
  return push(Actions.SELECT, bgColor, {
    param1: text?.substring(0, 99)
  });
}

// --- Dismiss Announcement Banner

export function dismissAnnouncementBanner(bgColor: string, text: string) {
  return push(Actions.DISMISS, bgColor, {
    param1: text?.substring(0, 99)
  });
}
