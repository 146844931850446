import ky, { HTTPError } from 'ky';

import Logger from 'common/Logger';
import { CONFIG } from 'constants/config';
import { stall } from 'helpers/utils';

import type {
  KyInstance,
  KyRequest,
  KyResponse,
  NormalizedOptions,
  Options
} from 'ky';

import { isHttpClientError } from 'types';
import type {
  ExtendedHttpClientError,
  HttpClient,
  HttpClientConfig,
  HttpClientError,
  HttpRequestInterceptor,
  HttpResponseErrorInterceptor,
  HttpResponseInterceptor
} from 'types';

const defaultOptions: HttpClientConfig = {
  timeout: CONFIG.DURATIONS.DEFAULT_REQUEST_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  retries: 0,
  retryDelay: 500,
  debug: false
};

export function createHttpClient(config: HttpClientConfig = {}): HttpClient {
  const { retries = defaultOptions.retries, retryDelay = 500, debug = false } = config;

  /* Tokens */
  let accessToken = '';
  let refreshToken = '';
  let previewToken = '';

  /* Request Cancellation */
  const abortControllers = new Map<string, AbortController>();

  const log = (...args: any[]) => {
    if (debug) {
      Logger.info(...args);
    }
  };

  const getAccessToken = () => accessToken;
  const setAccessToken = (at: string) => accessToken = at;

  const getRefreshToken = () => refreshToken;
  const setRefreshToken = (rt: string) => refreshToken = rt;

  const getPreviewToken = () => previewToken;
  const setPreviewToken = (pt: string) => previewToken = pt;

  // Interceptor storage
  const requestInterceptors: HttpRequestInterceptor[] = [];
  const responseInterceptors: Array<{ interceptor: HttpResponseInterceptor, errorInterceptor?: HttpResponseErrorInterceptor }> = [];

  // Function to create the ky instance with the current interceptors
  const createInstance = (): KyInstance => {
    return ky.create({
      ...defaultOptions,
      ...config,
      retry: {
        limit: retries,
        methods: ['get', 'post', 'put', 'patch', 'delete'],
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
        backoffLimit: retryDelay,
      },
      hooks: {
        beforeRequest: [
          (request) => {
            log(`[HTTP Client] Request sent: [${request.method}] ${request.url}`);
          },
          // Apply user-defined request interceptors
          ...(requestInterceptors.map((interceptor) => {
            return (request: KyRequest) => {
              return interceptor(request);
            };
          }))
        ],
        afterResponse: [
          async (request, options, response) => {
            log(`[HTTP Client] Response received: [${response.status}] ${response.url}`);
            return response;
          },
          // Apply user-defined response interceptors
          ...(responseInterceptors.map(({ interceptor, errorInterceptor }) => {
            return async (request: KyRequest, options: Options, response: KyResponse) => {
              try {

                // Response Not OK
                if (!response.ok) {
                  const error = new HTTPError(response, request, options as NormalizedOptions);
                  const updatedResponse = await errorInterceptor(error as HttpClientError);
                  if (updatedResponse) {
                    return updatedResponse;
                  }
                }

                // Response OK
                const responseClone = response.clone();
                let jsonResponse = {};
                try {
                  if (responseClone.headers.get('content-type')?.includes('application/json')) {
                    jsonResponse = await responseClone.json();
                  }
                } catch (error) {
                  console.error('Error parsing JSON:', error);
                }
                return await interceptor(jsonResponse);

              // Error
              } catch (error) {
                console.log('Error while intercepting response', error);
                throw error;
              }
            };
          }))
        ],
        beforeRetry: [
          async ({ request, error, retryCount }) => {
            log(`[HTTP Client] Retry attempt ${retryCount} for [${request.method}] ${request.url}`);
            await stall(retryDelay * retryCount);
          },
        ],
        beforeError: [
          async (error) => {
            // Log the error and modify or handle it if necessary
            if (isHttpClientError(error)) {
              log(`[HTTP Client] HTTP Error: ${error.message}`, error);
            } else {
              log(`[HTTP Client] Unexpected Error: ${error}`);
            }
            return error;
          },
          async (error) => {
            if (isHttpClientError(error)) {
              const { response } = error;
              const data = await response.clone().json();
              (error as ExtendedHttpClientError).config = error.options;
              (error as ExtendedHttpClientError).response.data = data;
              return error;
            }
          }
        ],
      },
    });
  };

  // Initially create the ky instance
  let instance = createInstance();

  const makeRequest = async <R = any>(
    url: string,
    options: Options
  ): Promise<{ data: R }> => {
    const abortController = new AbortController();
    options.signal = abortController.signal;

    // Workaround as when using prefixUrl in ky, / is automatically added after it
    const transformedUrl = url.startsWith('/') ? url.slice(1) : url;

    abortControllers.set(transformedUrl, abortController);

    try {
      const response = await instance(transformedUrl, options).json<R>();
      return { data: response };
    } finally {
      abortControllers.delete(transformedUrl);
    }
  };

  const addRequestInterceptor = (interceptor: HttpRequestInterceptor) => {
    requestInterceptors.push(interceptor);
    instance = createInstance();
    return requestInterceptors.length - 1;
  };

  const addResponseInterceptor = <R = any, E extends HTTPError = HttpClientError>(
    interceptor: HttpResponseInterceptor<R>,
    errorInterceptor?: HttpResponseErrorInterceptor<E>
  ) => {
    responseInterceptors.push({ interceptor, errorInterceptor });
    instance = createInstance();
    return responseInterceptors.length - 1;
  };

  const removeRequestInterceptors = () => {
    requestInterceptors.length = 0;
    instance = createInstance();
    log('[HTTP Client] All request interceptors removed');
  };

  const removeResponseInterceptors = () => {
    responseInterceptors.length = 0;
    instance = createInstance();
    log('[HTTP Client] All response interceptors removed');
  };

  const cancelRequest = (url: string) => {
    const controller = abortControllers.get(url);
    if (controller) {
      controller.abort();
      abortControllers.delete(url);
      log(`[HTTP Client] Request cancelled: ${url}`);
    }
  };

  return {
    get: <R = any>(url: string, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, { ...requestConfig, method: 'GET' });
    },

    post: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, { ...requestConfig, json: data, method: 'POST' });
    },

    put: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, { ...requestConfig, json: data, method: 'PUT' });
    },

    patch: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, { ...requestConfig, json: data, method: 'PATCH' });
    },

    mergePatch: <R = any>(url: string, data?: any, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, {
        ...requestConfig,
        json: data,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          ...(requestConfig?.headers || {})
        }
      });
    },

    delete: <R = any>(url: string, requestConfig?: HttpClientConfig): Promise<{ data: R }> => {
      return makeRequest<R>(url, { ...requestConfig, method: 'DELETE' });
    },

    cancelRequest,

    addRequestInterceptor,
    addResponseInterceptor,
    removeRequestInterceptors,
    removeResponseInterceptors,

    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    getPreviewToken,
    setPreviewToken,

    getInstance: () => instance
  };
}
