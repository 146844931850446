/* eslint-disable max-len */
export const DEFAULT_SEO = {
  titleTemplate: '%s - Freshful.ro',
  defaultTitle: 'Freshful.ro - Viața ar trebui să fie Freshful',
  description: 'Gamă variată de produse proaspete ✓ Bunătăți românești, de la producători locali ✓ Pâine artizanală cu maia ✓ Livrare cu mașini frigorifice, 7/7, de la 12,99 lei',
  openGraph: {
    type: 'website',
    url: 'https://www.freshful.ro',
    images: [{ url: 'https://cdn.freshful.ro/assets/icon_512.png' }]
  },
  noindex: true
} as const;
