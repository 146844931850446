import { create } from './StoreCreator';

const initialState = {
  isGenericModalVisible: false,
  genericModalStackCount: 0,
  visible: false,
  type: null,
  props: {}
};

const reducer = (draft, action) => {

  const {
    type,
    payload = {}
  } = action;

  switch (type) {

    case ACTIONS.SHOW_MODAL:
      return {
        ...draft,
        visible: true,
        type: payload.type,
        props: payload.props

      };

    case ACTIONS.HIDE_MODAL:
      return {
        ...draft,
        visible: false,
      // type: payload.type,
      // props: payload.props
      };

    case ACTIONS.SHOW_GENERIC_MODAL:
      return {
        ...draft,
        genericModalStackCount: draft.genericModalStackCount + 1,
        isGenericModalVisible: draft.genericModalStackCount + 1 > 0,
      };

    case ACTIONS.HIDE_GENERIC_MODAL:
      return {
        ...draft,
        genericModalStackCount: draft.genericModalStackCount - 1,
        isGenericModalVisible: draft.genericModalStackCount - 1 > 0,
      };

    case ACTIONS.TOGGLE_GENERIC_MODAL:
      return {
        ...draft,
        isGenericModalVisible: payload.isGenericModalVisible
      };

    default:
      return draft;
  }
};

function useModalDispatchHelpers() {

  const dispatch = useModalDispatch();

  const showModal = (modalType, props) => dispatch({
    type: ACTIONS.SHOW_MODAL,
    payload: {
      type: modalType,
      props: {
        ...props,
        handleModalClose: (...args) => {
          if (props?.handleModalClose) {
            props.handleModalClose(...args);
          }
          hideModal();
        },
      }
    }
  });

  const hideModal = () => dispatch({
    type: ACTIONS.HIDE_MODAL
  });

  const toggleGenericModal = (isVisible) => dispatch({
    type: ACTIONS.TOGGLE_GENERIC_MODAL,
    payload: {
      isGenericModalVisible: isVisible
    }
  });

  const showGenericModal = () => dispatch({
    type: ACTIONS.SHOW_GENERIC_MODAL
  });

  const hideGenericModal = () => dispatch({
    type: ACTIONS.HIDE_GENERIC_MODAL
  });

  return {
    showModal,
    hideModal,
    showGenericModal,
    hideGenericModal,
    toggleGenericModal,
  };
}

const [
  ModalProvider,
  useModalStore,
  useModalDispatch,
] = create(reducer, initialState);

const ACTIONS = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  TOGGLE_GENERIC_MODAL: 'TOGGLE_GENERIC_MODAL',
  SHOW_GENERIC_MODAL: 'SHOW_GENERIC_MODAL',
  HIDE_GENERIC_MODAL: 'HIDE_GENERIC_MODAL'
};

export {
  ModalProvider, useModalDispatch, useModalStore, useModalDispatchHelpers, ACTIONS as MODAL_ACTIONS
};
