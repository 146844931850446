import React from 'react';
import dynamic from 'next/dynamic';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { RecipeCarouselSlot } from 'components/recipe';
import { Button, Link } from 'components/ui';
import { RECIPE_CAROUSEL_BREAKPOINTS } from 'constants/recipes';
import { APP_ROUTES } from 'constants/routes';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import { isObjectEmpty } from 'helpers/ObjectHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePrevious } from 'hooks/usePrevious';

import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import {
  ProductBadge, ProductBanner, ProductLabels, ProductPrice, ProductTags
} from '../parts';

import ProductGallery from '../ProductGallery/ProductGallery';
import ProductsCarousel from '../ProductsCarousel/ProductsCarousel';
import ProductVariantSwitcher from '../ProductVariantSwitcher/ProductVariantSwitcher';

import ProductDetailsActions from './ProductDetailsActions';
import ProductDetailsBrand from './ProductDetailsBrand';
import ProductDetailsDeliveryComment from './ProductDetailsDeliveryComment';
import ProductQuantityVariants from './ProductDetailsQuantityVariants';
import ProductDetailsSameBrandNavigation from './ProductDetailsSameBrandNavigation';
import ProductTabs from './ProductTabs';
import ProductTrustElements from './ProductTrustElements';

import type {
  IAnalyticsListProps,
  IProduct,
  IProductDetails,
  IProductVariant,
  IUser,
  Nullable,
} from 'types';

import {
  CarouselSlotTypeEnum,
  InfoBannerTypes
} from 'types';

import styles from './ProductDetails.module.scss';

const ProductDetailsToolbar = dynamic(() => import('./ProductDetailsToolbar'), { ssr: false });

interface Props {
  scrollContainer?: HTMLDivElement | undefined,
  //
  className?: string,
  detailsClassName?: string,
  nameClassName?: string,
  //
  product: IProductDetails,
  listProps?: IAnalyticsListProps,
  //
  isQuickView?: boolean,
  //
  withoutLinks?: boolean,
  withoutBanner?: boolean,
  withoutSameBrandNavigation?: boolean,
  withoutActions?: boolean,
  withoutTrustedElements?: boolean,
  withoutSlots?: boolean,
  withoutToolbar?: boolean,
  //
  onQuickViewClose?: () => void,
  onItemClick?: () => void
}

const ProductDetails = (props: Props) => {

  const {
    scrollContainer,
    //
    className,
    detailsClassName,
    nameClassName,
    //
    product,
    listProps = {},
    //
    isQuickView = true,
    //
    withoutLinks,
    withoutBanner,
    withoutSameBrandNavigation,
    withoutActions,
    withoutTrustedElements,
    withoutSlots,
    withoutToolbar,
    //
    onQuickViewClose = () => {},
    onItemClick = () => {}
  } = props;

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();
  const analytics = useAnalytics();

  const {
    analyticsListProps: persistedListProps,
    set: setAnalyticsListProps,
    persist: persistAnalyticsListProps
  } = useRouteQueryAnalyticsListProps();

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  // State Hooks

  const isBreakpoint = useMediaQuery('(max-width: 1260px)');

  const [isImageMaximized, setImageMaximized] = React.useState(false);
  const [isToolbarVisible, setToolbarVisible] = React.useState(false);
  const [isVariantLoaded, setIsVariantLoaded] = React.useState(false);

  const [variants, setVariants] = React.useState<IProductVariant[]>(getProductVariants(product, user));
  const [activeVariant, setActiveVariant] = React.useState<IProductVariant | undefined>(getActiveProductVariant(product, user, router, true));

  const previousUser = usePrevious(user);
  const previousProduct = usePrevious(product);

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Effects

  useDeepCompareEffect(() => {
    if (!product || (product?.variantCode === previousProduct?.variantCode && user?.id === previousUser?.id)) {
      return;
    }
    if (isQuickView) {
      analytics.viewProductModal(user, product);
    } else {
      analytics.viewProductPage(user, product);
    }
  }, [user, previousUser, previousProduct, product, isQuickView]);

  // --- Get variants

  React.useEffect(() => {
    const currentVariants = getProductVariants(product, user);
    if (!currentVariants) return;
    setVariants(currentVariants);
  }, [product, user]);

  // --- Update active variant

  React.useEffect(() => {
    const currentActiveVariant = getActiveProductVariant(product, user, router, router?.isReady);
    if (!currentActiveVariant) return;
    setActiveVariant(currentActiveVariant);
    setIsVariantLoaded(true);
  }, [product, user, router]);

  // --- Analytics

  React.useEffect(() => {
    if (product) {
      analytics.viewItem(product, {
        id: listProps?.analyticsListId,
        name: listProps?.analyticsListName
      });
    }
  }, []);

  const defaultListProps = React.useMemo(() => ({
    analyticsListId: product?.analyticsListId,
    analyticsListName: product?.analyticsListName
  }), [product]);

  // Handlers

  const onSelectVariant = (variant: IProductVariant) => {
    const {
      slug: variantSlug,
      isAcceleratedSale: isAcceleratedSaleVariant = false
    } = variant;

    const { query } = router;

    const asLink = ProductHelpers.getProductLink(variantSlug, isAcceleratedSaleVariant) || undefined;

    if (isAcceleratedSaleVariant) {
      router.replace({ pathname: router.pathname, query: { ...router.query, sm: true } }, asLink, { shallow: true });
    } else {
      delete query.sm;
      router.replace({ pathname: router.pathname, query }, asLink, { shallow: true });
    }
  };

  const onSimilarClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isQuickView && !isBreakpoint) {
      onQuickViewClose();
    }

    showModal(MODAL_TYPES.SIMILAR_PRODUCTS, { productSlug: slug });
  }, [product, showModal]);

  const onActionsEnter = React.useCallback(() => {
    setToolbarVisible(false);
  }, []);

  const onActionsExit = React.useCallback(() => {
    setToolbarVisible(true);
  }, []);

  const onLinkClick = React.useCallback(() => {
    if (persistedListProps) {
      persistAnalyticsListProps();
    } else setAnalyticsListProps(listProps);
  }, [listProps, persistedListProps]);

  // Empty

  if (!product || !activeVariant) return null;

  // Props

  // --- Product

  const {
    isGift,
    sku,
    slug,
    name = '',
    images,
    slots,
    tags,
    badge,
    taxes,
    saveMe,
    canAddMultipleType
  } = product;

  // --- Variant

  const {
    //
    isAvailable = true,
    isAcceleratedSale = false,
    //
    price,
    originalPrice,
    originalPriceStrikethrough,
    unitPriceLabel,
    currency,
    //
    discountLabel,
    //
    priceIcon,
    priceColor,
    priceTooltip,
    //
    priceLabel,
    priceLabelColor,
    //
    labels,
    infoLabels,
    banner,
    //
  } = activeVariant;

  // --- Link

  const link = ProductHelpers.getProductLink(slug, isAcceleratedSale);

  const hasAcceleratedSaleVariant = !!saveMe;
  const isAcceleratedSaleVariantSelected = router?.query?.sm === 'true';
  const areQuantityVariantsVisible = !isAcceleratedSaleVariantSelected && canAddMultipleType && isAvailable;

  // Effects

  // --- Redirect to default variant if save me is unavailable

  React.useEffect(() => {
    const { query } = router;
    if (isAcceleratedSaleVariantSelected && !hasAcceleratedSaleVariant) {
      delete query.sm;
      router.replace({ pathname: router.pathname, query }, link, { shallow: true });
    }
  }, [link, isAcceleratedSaleVariantSelected, hasAcceleratedSaleVariant]);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.inModal]: isQuickView },
        className
      )}
    >

      {/* Toolbar */}
      {
        withoutToolbar
          ? null
          : (
            <ProductDetailsToolbar
              isVisible={isToolbarVisible}
              //
              product={product}
              variant={activeVariant}
              listProps={listProps}
              //
              currency={currency}
              originalPrice={originalPrice}
              price={price}
              priceColor={priceColor}
              //
              onSimilarClick={onSimilarClick}
            />
          )
      }

      {/* Details */}
      <div
        className={classnames(
          styles.details,
          {
            [styles.maximized]: isImageMaximized,
            [styles.isQuickView]: isQuickView
          },
          detailsClassName
        )}
      >

        {/* Images */}
        <div
          className={classnames(
            styles.image,
            {
              [styles.maximized]: isImageMaximized,
              [styles.isQuickView]: isQuickView
            }
          )}
        >

          {/* Labels */}
          <ProductLabels
            className={styles.labels}
            labels={labels}
            size="large"
            responsiveSize="large"
          />

          {/* Gallery */}
          <ProductGallery
            className={styles.gallery}
            productName={name}
            images={images}
            infoLabels={infoLabels}
            canZoom={!isBreakpoint}
            isAvailable={isAvailable}
            isQuickView={isQuickView}
            shouldOffsetBullets={!!badge}
            setImageMaximized={setImageMaximized}
            isImageMaximized={isImageMaximized}
          >
            {/* Badge */}
            {
              !isImageMaximized && (
                <ProductBadge
                  className={styles.badge}
                  badge={badge}
                  size="large"
                />
              )
            }
          </ProductGallery>
        </div>

        {/* Info */}
        <div className={classnames(styles.info, { [styles.maximized]: isImageMaximized })}>
          <div className={classnames(styles.infoWrapper, { [styles.maximized]: isImageMaximized })}>
            <div className={classnames(styles.infoInner, { [styles.maximized]: isImageMaximized })}>

              {/* Brand */}
              <ProductDetailsBrand
                product={product}
                withoutLink={Boolean(withoutLinks || withoutSameBrandNavigation)}
              />

              {/* Name */}
              <h1 className={classnames(styles.name, nameClassName)} data-testid="product-title">
                {
                  (isQuickView && !withoutLinks)
                    ? (
                      <Link
                        href={link || APP_ROUTES.HOME}
                        passHref
                      >
                        <Button
                          tag="a"
                          className={classnames(styles.link, { [styles.alignMaximized]: isImageMaximized })}
                          onClick={onLinkClick}
                        >
                          {name}
                        </Button>
                      </Link>
                    ) : name
                }
              </h1>

              {/* Tags */}
              <ProductTags
                className={styles.tags}
                tagClassName={styles.tag}
                showText
                tags={tags}
              />

              {/* Banner */}
              <div className={styles.bannerWrapper}>
                {
                  (withoutBanner || !banner)
                    ? null
                    : (
                      <ProductBanner
                        scrollContainer={scrollContainer}
                        className={classnames(styles.banner, { [styles.wide]: !!banner.tooltip })}
                        tooltipTitleClassName={styles.bannerTooltipTitle}
                        tooltipTextClassName={styles.bannerTooltipText}
                        tooltipContainerClassName={styles.bannerTooltipContainer}
                        tooltipPosition={['bottom']}
                        productSlug={slug}
                        banner={banner}
                        excludedTypes={[InfoBannerTypes.ACCELERATED_SALE]}
                      />
                    )
                }
              </div>

              {/* Price */}
              <ProductPrice
                scrollContainer={scrollContainer}
                //
                className={styles.productPrice}
                priceWrapperClassName={styles.priceWrapper}
                priceClassName={styles.price}
                originalPriceClassName={classnames(
                  styles.originalPrice,
                  { [styles.withStrikethrough]: originalPriceStrikethrough }
                )}
                unitPriceLabelClassName={styles.unitPriceLabel}
                taxesClassName={styles.taxes}
                //
                align="start"
                size="large"
                //
                currency={currency}
                price={price}
                originalPrice={originalPrice}
                withOriginalPriceStrikethrough={originalPriceStrikethrough}
                unitPriceLabel={unitPriceLabel}
                taxes={taxes}
                //
                priceLabel={priceLabel || (isGift ? t('LABELS.FREE') : undefined)}
                priceLabelColor={priceLabelColor || (isGift ? styles.colorPrimary : undefined)}
                //
                color={priceColor}
                icon={priceIcon}
                tooltip={priceTooltip}
                discountLabel={discountLabel}
              />

              {/* Delivery comment */}
              {
                (withoutActions || !isImageMaximized)
                  ? null
                  : (
                    <div className={classnames(styles.deliveryCommentWrapper, { [styles.maximized]: isImageMaximized })}>
                      <ProductDetailsDeliveryComment
                        className={classnames(
                          styles.deliveryComment,
                          { [styles.maximized]: isImageMaximized }
                        )}
                        product={activeVariant}
                      />
                    </div>
                  )
              }
            </div>

            {/* Actions */}
            {
              withoutActions
                ? null
                : (
                  <div className={classnames(styles.actionsWrapper, { [styles.maximized]: isImageMaximized })}>

                    {/* Out of stock */}
                    <div className={classnames(styles.outOfStockWrapper, { [styles.hidden]: isAvailable })}>
                      {
                        (!isAvailable && isVariantLoaded) && (
                          <span className={styles.outOfStock}>{t('PRODUCT.DETAILS_OUT_OF_STOCK')}</span>
                        )
                      }
                    </div>

                    {/* Add / Edit / Remove */}
                    {
                      isVariantLoaded && (
                        <ProductDetailsActions
                          className={styles.actions}
                          product={product}
                          variant={activeVariant}
                          listProps={!isObjectEmpty(listProps) ? listProps : (persistedListProps || defaultListProps)}
                          onSimilarClick={onSimilarClick}
                          onEnterViewport={onActionsEnter}
                          onLeaveViewport={onActionsExit}
                        />
                      )
                    }

                    {/* Delivery comment */}
                    {
                      !isImageMaximized && (
                        <div
                          className={classnames(
                            styles.deliveryCommentWrapper,
                            {
                              [styles.hidden]: !isAvailable,
                              [styles.maximized]: isImageMaximized
                            },
                          )}
                        >
                          <ProductDetailsDeliveryComment
                            className={classnames(styles.deliveryComment, { [styles.maximized]: isImageMaximized })}
                            product={activeVariant}
                          />
                        </div>
                      )
                    }

                    {/* Variants - Desktop / Zoomed Out */}
                    {
                      isVariantLoaded && (
                        <ProductVariantSwitcher
                          className={classnames(
                            styles.variants,
                            styles.top,
                            { [styles.maximized]: isImageMaximized }
                          )}
                          selected={activeVariant}
                          variants={variants}
                          onSelectVariant={onSelectVariant}
                        />
                      )
                    }

                    {/* Multiple quantity variants */}
                    {areQuantityVariantsVisible ? (
                      <ProductQuantityVariants
                        product={product}
                        variant={activeVariant}
                        listProps={listProps}
                      />
                    ) : null}
                  </div>
                )
            }
          </div>

          {/* Trust elements */}
          {
            withoutTrustedElements
              ? null
              : (
                <ProductTrustElements isAvailable={isAvailable} />
              )
          }

        </div>
      </div>

      {/* Variants - Responsive / Zoomed In */}
      {
        withoutActions
          ? null
          : (
            <ProductVariantSwitcher
              className={classnames(
                styles.variants,
                styles.withBorder,
                styles.bottom,
                { [styles.maximized]: isImageMaximized }
              )}
              selected={activeVariant}
              variants={variants}
              onSelectVariant={onSelectVariant}
            />
          )
      }

      {/* Same brand navigation */}
      {
        withoutSameBrandNavigation
          ? null
          : (
            <ProductDetailsSameBrandNavigation
              product={product}
            />
          )
      }

      {/* Tabs */}
      <ProductTabs
        product={product}
        sku={sku}
        collapsed={!withoutSlots}
        withoutSameBrandTab={Boolean(withoutSameBrandNavigation)}
        onItemClick={onItemClick}
        carouselBreakpoints={isQuickView ? CAROUSEL_BREAKPOINTS_MODAL : CAROUSEL_BREAKPOINTS}
      />

      {/* Slots */}
      {
        withoutSlots
          ? null
          : (
            <div className={styles.slots}>
              {
                slots?.map((slot, index) => {
                  const {
                    analyticsListId, analyticsListName,
                    title, products, recipes, type
                  } = slot;

                  return (
                    <React.Fragment key={`${title}${index}`}>
                      {
                        type === CarouselSlotTypeEnum.PRODUCTS && (
                          <ProductsCarousel
                            title={title}
                            products={products}
                            spaceBetween={14}
                            productListProps={{
                              analyticsListId,
                              analyticsListName
                            }}
                            withSmallLabels={isQuickView}
                            breakpoints={isQuickView ? CAROUSEL_BREAKPOINTS_MODAL : CAROUSEL_BREAKPOINTS}
                            onItemClick={onItemClick}
                          />
                        )
                      }

                      {
                        type === CarouselSlotTypeEnum.RECIPES && (
                          <RecipeCarouselSlot
                            recipes={recipes}
                            isQuickView={isQuickView}
                            title={title}
                            breakpoints={RECIPE_CAROUSEL_BREAKPOINTS}
                            dataTestId={`${PRODUCT_SLOT_DATA_TEST_ID}-${type}`}
                          />
                        )
                      }
                    </React.Fragment>
                  );
                })
              }
            </div>
          )
      }
    </div>
  );
};

// Constants

const PRODUCT_SLOT_DATA_TEST_ID = 'product-slot';

const CAROUSEL_BREAKPOINTS = {
  1400: {
    slidesPerView: 6
  },
  960: {
    slidesPerView: 5,
  },
  600: {
    slidesPerView: 3
  }
};

const CAROUSEL_BREAKPOINTS_MODAL = {
  1400: {
    slidesPerView: 5
  },
  960: {
    slidesPerView: 4,
  },
  600: {
    slidesPerView: 3
  }
};

// Helpers

const getProductVariants = (
  product: IProduct,
  user: Nullable<IUser> | undefined
) => {
  if (!product) return [];

  return ProductHelpers.getProductVariants(product, {
    shouldShowGeniusPrice: user?.isGeniusUser,
  });
};

const getActiveProductVariant = (
  product: IProduct,
  user: Nullable<IUser> | undefined,
  router: NextRouter,
  enabled?: boolean
) => {
  if (
    !enabled
    || !product
    || !router?.asPath?.startsWith(APP_ROUTES.PRODUCTS)
  ) return undefined;

  const variants = getProductVariants(product, user);

  if (!variants) return undefined;

  const isAcceleratedSaleSelected = router?.query?.sm === 'true';

  return variants.find(({ isAcceleratedSale, isDefault }) => (
    isAcceleratedSaleSelected ? isAcceleratedSale : isDefault
  )) || undefined;
};

// Export

export default ProductDetails;
