import React from 'react';

import * as Notifications from 'common/Notification';
import { CONFIG } from 'constants/config';

import type { IFavoriteProduct, IImage, IProduct } from 'types';

export function isFavorite(favorites?: IFavoriteProduct[], product?: IProduct) {
  if (!favorites || !Array.isArray(favorites)) return false;
  const { variantCode } = product || {};
  return favorites.find((p) => p.code === variantCode);
}

// --- Product favorites notification

const FAVORITES_TOAST_ID = 'productFavoritesAction';

interface IFavoritesNotificationPayload {
  product: {
    name: string,
    image?: IImage
  },
  component: (props: React.ComponentProps<any>) => React.JSX.Element,
  message: string
}

export function showFavoritesNotification(payload: IFavoritesNotificationPayload) {
  const {
    product, message,
    component: Component // workaround to avoid dependency cycle
  } = payload;

  const toastBody = (
    <Component
      product={product}
      message={message}
    />
  );

  if (Notifications.isActive(FAVORITES_TOAST_ID)) {
    Notifications.update(FAVORITES_TOAST_ID, {
      autoClose: CONFIG.DURATIONS.PRODUCT_FAVORITES_TOAST_AUTOCLOSE,
      containerId: Notifications.TOAST_CONTAINER_IDS.CUSTOM,
      toastId: FAVORITES_TOAST_ID,
      render: toastBody,
    });
  } else {
    Notifications.showProgress(
      toastBody,
      {
        autoClose: CONFIG.DURATIONS.PRODUCT_FAVORITES_TOAST_AUTOCLOSE,
        containerId: Notifications.TOAST_CONTAINER_IDS.CUSTOM,
        toastId: FAVORITES_TOAST_ID
      },
    );
  }
}
