import { PageType } from 'types';

export const APP_ROUTES = {

  // Authentication

  LOGIN: '/login',
  LOGIN_CHECK_EMAG: '/login-check-emag',
  LOGOUT: '/logout',

  // Home

  HOME: '/',
  HOME_PREVIEW: '/content-preview/home',
  HOME_REDIRECT: '/home-redirect',

  // Categories

  CATEGORIES_FILTERED: '/cf',
  CATEGORIES: '/c',

  // Custom Listing

  CUSTOM_LISTING_FILTERED: '/lf',
  CUSTOM_LISTING: '/l',

  // Product

  PRODUCTS: '/p',

  // Search

  SEARCH: '/search',

  // Accelerated Sale

  ACCELERATED_SALE_FILTERED: '/save-me-f',
  ACCELERATED_SALE: '/save-me',

  // New Products

  NEW_PRODUCTS_FILTERED: '/noutatif',
  NEW_PRODUCTS: '/noutati',

  // Promotions

  PROMOTIONS_FILTERED: '/ofertef',
  PROMOTIONS: '/oferte',

  // Favorites / My Freshlist

  FAVORITES: '/favorites',
  MY_FRESHLIST: '/my-freshlist',

  // Account

  ACCOUNT: '/account',
  MY_FRESHCLUB: '/account/freshclub',
  MY_FRESHCLUB_HISTORY: '/account/freshclub/history',
  MY_FRESHCLUB_ABOUT: '/account/freshclub/about',
  MY_FRESHCLUB_OPT_OUT: '/account/freshclub/optout',
  ADDRESSES: '/account/addresses',
  BENEFITS: '/account/benefits',
  ORDER_HISTORY: '/account/order-history',
  BILLING: '/account/billing',
  MEAL_VOUCHERS: '/account/meal-vouchers',
  VOUCHERS: '/account/vouchers',
  ACCOUNT_PROMOTIONS: '/account/vouchers/promotions',

  // Checkout

  CHECKOUT: '/checkout',
  CHECKOUT_SUCCESS: '/checkout/success',

  // Genius
  GENIUS: '/descopera-genius',
  GENIUS_MOBILE: '/mobile-preview/descopera-genius',

  // Buy Genius
  EMAG_GENIUS: '/genius',
  EMAG_GENIUS_MOBILE: '/mobile-preview/genius',

  // Beneficii Genius

  GENIUS_BENEFITS: '/beneficii-genius',
  GENIUS_BENEFITS_MOBILE: '/mobile-preview/beneficii-genius',

  // Fast checkout mobile
  GENIUS_FAST_CHECKOUT_MOBILE: '/mobile-preview/genius-fast-checkout',

  // Upgreat

  UPGREAT: '/beneficii',
  UPGREAT_MOBILE: '/mobile-preview/beneficii',

  // Campaigns

  CAMPAIGNS: '/campanii',
  CAMPAIGN_RULES: '/info/regulament-campanii',
  CAMPAIGN_RULES_MOBILE: '/info/mobile/regulament-campanii',

  // Static

  ABOUT_MOBILE: '/info/mobile/despre',
  ABOUT: '/info/despre',
  CONTACT_MOBILE: '/info/mobile/contact',
  CONTACT: '/info/contact',
  STATIC_MOBILE: '/info/mobile',
  STATIC: '/info',
  LEGAL_INFO: '/info/informatii-legale',

  // Recipes

  RECIPES_LANDING: '/retete',
  RECIPES_BY_TAG: '/retete/t',
  RECIPES_LIST: '/retete/l',
  RECIPES_LIST_FILTERED: '/retete/lf',
  RECIPES_DETAILS: '/retete/r',

  // Blog

  BLOG_FRESHFUL_INSPIRATION: '/inspiratie-freshful',
  BLOG_FRESHFUL_INSPIRATION_MOBILE: '/mobile-preview/inspiratie-freshful',
  BLOG_GOOD_DEEDS: '/inspiratie-freshful/actiuni-de-bine',
  BLOG_GOOD_DEEDS_MOBILE: '/mobile-preview/inspiratie-freshful/actiuni-de-bine',
  BLOG_DIN_LIVADA: '/inspiratie-freshful/din-livada-by-freshful',
  BLOG_DIN_LIVADA_MOBILE: '/mobile-preview/inspiratie-freshful/din-livada-by-freshful',
  BLOG_DIN_GRADINA: '/inspiratie-freshful/din-gradina',
  BLOG_DIN_GRADINA_MOBILE: '/mobile-preview/inspiratie-freshful/din-gradina',
  BLOG_BREWZEUS: '/inspiratie-freshful/brewzeus-by-mihai-panfil',
  BLOG_BREWZEUS_MOBILE: '/mobile-preview/inspiratie-freshful/brewzeus-by-mihai-panfil',
  BLOG_BRUTARIA_FRESHFUL: '/inspiratie-freshful/brutaria-freshful',
  BLOG_BRUTARIA_FRESHFUL_MOBILE: '/mobile-preview/inspiratie-freshful/brutaria-freshful',

  // --- Landing Pages

  LANDING_PAGES: '/lp',
  LANDING_PAGES_MOBILE: '/mobile-preview/lp',
  LANDING_PAGES_VOUCHER30: '/lp/voucher30-cadou',
  LANDING_PAGES_CE_MAI_BEM: '/lp/ce-mai-bem-by-radu-dumitrescu',
  LANDING_PAGES_PHILIPS_AIRFRYER_CAMPAIGN: '/lp/campanie-philips-airfryer',
  LANDING_PAGES_POVESTI_DE_POFTA_BUNA: '/lp/povesti-de-pofta-buna-voxa-freshful',
  LANDING_PAGES_CAMPANIE_EDENRED: '/lp/campanie-edenred',
  LANDING_PAGES_LIFE_WITH_FRESHFUL: '/lp/viata-cu-freshful',
  LANDING_PAGES_MASLINESCU: '/lp/maslinescu-ulei-masline',
  LANDING_PAGES_FRESHFUL_MARKET: '/lp/piata-cu-freshful',
  LANDING_PAGES_FRESHFUL_MARKET_MOBILE: '/mobile-preview/lp/piata-cu-freshful',
  LANDING_PAGES_SGR_COLLECTION: '/lp/colectare-ambalaje-garantie-sgr',
  LANDING_PAGES_SGR_COLLECTION_MOBILE: '/mobile-preview/lp/colectare-ambalaje-garantie-sgr',

  // Forms

  // --- Order Feedback

  ORDER_FEEDBACK: '/forms/order-feedback',

  // Newsletter

  NEWSLETTER_UNSUBSCRIBE: '/newsletter/unsubscribe',

  // Abandoned Cart Unsubscribe

  ABANDONED_CART_UNSUBSCRIBE: '/unsubscribe/abandoned-cart',

  // Careers

  CAREERS: '/cariere',
  CAREERS_MOBILE: '/mobile-preview/cariere',

  // Errors

  ERROR500: '/500',
  ERROR404: '/404',
} as const;

export const ROUTES_WITH_MOBILE_PREVIEW = [
  APP_ROUTES.GENIUS,
  APP_ROUTES.EMAG_GENIUS,
  APP_ROUTES.UPGREAT,
  APP_ROUTES.BLOG_FRESHFUL_INSPIRATION,
  APP_ROUTES.LANDING_PAGES,
  APP_ROUTES.CAREERS
] as const;

export const ROUTES_LISTING_FILTERED_MAP = {
  [PageType.Category]: {
    url: APP_ROUTES.CATEGORIES,
    filteredUrl: APP_ROUTES.CATEGORIES_FILTERED,
  },
  [PageType.CustomListing]: {
    url: APP_ROUTES.CUSTOM_LISTING,
    filteredUrl: APP_ROUTES.CUSTOM_LISTING_FILTERED,
  },
  [PageType.Promotion]: {
    url: APP_ROUTES.PROMOTIONS,
    filteredUrl: APP_ROUTES.PROMOTIONS_FILTERED,
  },
  [PageType.NewProducts]: {
    url: APP_ROUTES.NEW_PRODUCTS,
    filteredUrl: APP_ROUTES.NEW_PRODUCTS_FILTERED,
  },
  [PageType.AcceleratedSale]: {
    url: APP_ROUTES.ACCELERATED_SALE,
    filteredUrl: APP_ROUTES.ACCELERATED_SALE_FILTERED,
  },
  [PageType.RecipesList]: {
    url: APP_ROUTES.RECIPES_LIST,
    filteredUrl: APP_ROUTES.RECIPES_LIST_FILTERED
  }
};
