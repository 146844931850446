import React, { forwardRef } from 'react';
import classnames from 'classnames';
// import PropTypes from 'prop-types';

import Button from 'components/ui/Button/Button';
import { CONFIG } from 'constants/config';

import Link from '../Link/Link';

import styles from './Chips.module.scss';

interface Chip {
  slug: string,
  name: string,
  href: string,
  as?: string,
  selected?: boolean
}

interface Props {
  className?: string,
  chipWrapperClassName?: string,
  chipClassName?: string,
  items: Chip[],
  selectedRef?: React.Ref<HTMLLIElement>,
  onChipClick: (chip: Chip) => void
}

const Chips = forwardRef<HTMLUListElement, Props>((
  props: Props,
  ref: React.Ref<HTMLUListElement>
) => {

  const {
    className,
    chipWrapperClassName,
    chipClassName,
    items,
    selectedRef,
    onChipClick
  } = props;

  // Handlers

  const handleChipClick = (chip: Chip) => {
    if (onChipClick) {
      onChipClick(chip);
    }
  };

  // Render

  return (
    <ul
      ref={ref}
      className={classnames(styles.root, className)}
    >
      {
        items.map((item) => {
          const {
            slug,
            name,
            href,
            selected
          } = item;

          return (
            <li
              key={slug}
              ref={selected ? selectedRef : null}
              className={classnames(styles.chipWrapper, chipWrapperClassName)}
            >
              <Link
                href={href}
                prefetch={CONFIG.PREFETCH_LINKS}
                passHref
              >
                <Button
                  tag="a"
                  className={classnames(
                    styles.chip,
                    { [styles.selected]: selected },
                    chipClassName
                  )}
                  onClick={() => {
                    handleChipClick(item);
                  }}
                >
                  {name}
                </Button>
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
});

export default Chips;
