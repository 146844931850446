import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import OrderInfoBlock from '../OrderInfoBlock/OrderInfoBlock';

// import styles from './OrderInfoDate.module.scss';

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const OrderInfoDate = (props) => {
  const {
    order = {},
    withIcon,
    children,
    ...rest
  } = props;

  const { checkoutCompletedAt } = order;

  const { t } = useTranslation();

  return (
    <OrderInfoBlock
      label={t('LABELS.PLACED')}
      value={checkoutCompletedAt}
      icon={withIcon ? 'calendar-time' : null}
      {...rest}
    >
      {children}
    </OrderInfoBlock>
  );
};

OrderInfoDate.propTypes = {
  // specific props
  withIcon: PropTypes.bool,
  order: PropTypes.shape({
    checkoutCompletedAt: PropTypes.string
  }),

  // block props
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  contentClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  valueClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withBorder: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default OrderInfoDate;
