import { useQuery } from '@tanstack/react-query';

import { ERROR_CODES } from 'constants/errors';
import { QUERY_KEYS } from 'constants/query-keys';
import { createListingQueryKey } from 'helpers/ReactQueryHelpers';
import { getPromotion } from 'services/PromotionsService';

import type {
  IListingRequestParams,
  IPromotionListing,
  QueryClient,
  ReactQueryOptions
} from 'types';

export function usePromotion(
  params: IListingRequestParams,
  queryOptions: ReactQueryOptions<IPromotionListing> = {}
) {

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => getPromotion(params),
    ...queryOptions
  });
}

export function getKey(params: IListingRequestParams) {
  return createListingQueryKey(QUERY_KEYS.PROMOTION, params);
}

export async function prefetchPromotion(
  queryClient: QueryClient,
  params: IListingRequestParams
) {

  const key = getKey(params);

  try {
    const response = await queryClient.fetchQuery({
      queryKey: key,
      queryFn: () => getPromotion(params)
    });
    if (!response) {
      return false;
    }
  } catch (err: any) {
    if (err?.response?.status === ERROR_CODES.NOT_FOUND) {
      return false;
    }
  }

  return true;
}
