import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Icon, Price } from 'components/ui';

import type { IOrder } from 'types';

import styles from './CartSubmit.module.scss';

interface Props {
  className?: string,
  order: IOrder,
  onSubmit: () => void
}

const CartSubmit = (props: Props) => {
  const {
    className,
    order,
    onSubmit = () => {},
  } = props;

  // Props

  const {
    currency,
    itemsTotal,
    itemsTotalDiscount,
  } = order || {};

  const discountLabel = getDiscountLabel(itemsTotalDiscount);

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Discounts */}
      {
        !discountLabel
          ? null
          : (
            <div className={styles.discounts}>
              <div
                className={styles.discountLabel}
                dangerouslySetInnerHTML={{ __html: discountLabel }}
              />
            </div>
          )
      }

      {/* Actions */}
      <div className={styles.actions}>
        <Button
          className={classnames(styles.btn, styles.checkout)}
          contentClassName={styles.btnContent}
          variant="primary"
          onClick={onSubmit}
        >

          {/* Label */}
          <div className={styles.btnLabel}>
            {t('CART.GO_TO_CHECKOUT')}
          </div>

          {/* Total */}
          <div className={styles.btnSubLabel}>
            <span className={styles.text}>{t('LABELS.TOTAL')}</span>
            <Price
              className={styles.price}
              currency={currency}
              value={itemsTotal}
            />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default CartSubmit;

// Helpers

// TODO: remove after BE HTML implementation
const getDiscountLabel = (label: string | null): string | null => {
  if (!label) return null;
  if (label.includes('<')) return label;
  return label.replace(/[0-9].*$/, (match) => `<span>${match}</span>`);
};
