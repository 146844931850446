/* eslint-disable global-require */

import React from 'react';
import { useReportWebVitals } from 'next/web-vitals';
import { appWithTranslation } from 'next-i18next';

import {
  AppVersion, BreakpointsObserver, ChatButton,
  GlobalErrorBoundary,
  Managers, NavigationLoader, OutdatedBrowser,
  ScriptsLoader, ToastContainers
} from 'components/layout';
import { AnalyticsProvider } from 'context/AnalyticsContext';
import AppProviders from 'context/index';
import QueryProvider from 'context/QueryContext';
import { isOutdatedBrowser } from 'helpers/BrowserHelpers';
import { reportWebVital } from 'helpers/WebVitalsHelpers';
import { useBootstrap } from 'hooks/useBootstrap';

import '@styles/index.scss';

// Start MSW API mocks - for development environment
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../../mocks');
}

function MyApp({ Component, pageProps }) {

  useReportWebVitals((metric) => reportWebVital(metric));

  if (isOutdatedBrowser()) {
    return (
      <div>
        <AnalyticsProvider>
          <OutdatedBrowser />
        </AnalyticsProvider>
      </div>
    );
  }

  return (
    <QueryProvider state={pageProps.dehydratedState}>
      <AppProviders>
        <NavigationLoader />
        <App Component={Component} pageProps={pageProps} />
        <AppVersion />
        <Managers />
        <ToastContainers />
        <ChatButton />
        <ScriptsLoader />
        <BreakpointsObserver />
      </AppProviders>
    </QueryProvider>
  );
}

const App = ({ Component: Page, pageProps }) => {
  const { getLayout, layoutProps = {}, Layout = React.Fragment } = Page;

  useBootstrap();

  const page = <Page {...pageProps} />;

  return (
    <>
      {/* App */}
      <GlobalErrorBoundary>
        {
          getLayout
            ? getLayout(page, pageProps)
            : <Layout {...layoutProps} id="layout">{page}</Layout>
        }
      </GlobalErrorBoundary>

    </>
  );
};

export default appWithTranslation(MyApp);
