import * as AnalyticsConfigEvents from './AnalyticsConfigEvents';
import * as AnalyticsCustomEvents from './AnalyticsCustomEvents';
import * as AnalyticsEcommerceEvents from './AnalyticsEcommerceEvents';
import * as AnalyticsGAEvents from './AnalyticsGAEvents';
import * as AnalyticsPageEvents from './AnalyticsPageEvents';

export default {
  ...AnalyticsConfigEvents,
  ...AnalyticsCustomEvents,
  ...AnalyticsEcommerceEvents,
  ...AnalyticsGAEvents,
  ...AnalyticsPageEvents
};
