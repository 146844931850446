import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getRecipe } from 'services/RecipeService/RecipeService';

import type { GetRecipeParams, GetRecipeParams as Params } from 'services/RecipeService/RecipeService';
import type {
  IRecipe, MarkdownSerializeFunction, QueryClient, ReactQueryOptions
} from 'types';

export const useRecipe = (params: Params, queryOptions: ReactQueryOptions<IRecipe> = {}) => {
  const { preview } = params || {};

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => getRecipe(params),
    staleTime: preview ? Infinity : CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...queryOptions
  });
};

function getKey(params: Params) {
  const {
    slug,
    preview,
  } = params || {};

  return createQueryKey([
    QUERY_KEYS.RECIPES_DETAILS,
    slug,
    preview
  ]);
}

export async function prefetchRecipe(
  params: GetRecipeParams,
  markdownSerializeFn: MarkdownSerializeFunction,
  queryClient: QueryClient
): Promise<boolean> {
  try {
    const data = await queryClient.fetchQuery({
      queryKey: getKey(params),
      queryFn: () => getRecipe(params, markdownSerializeFn)
    });
    return !!data;
  } catch (err) {
    return false;
  }
}
