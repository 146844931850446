import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';

import styles from './RecipeIngredientsListEmptyState.module.scss';

interface Props {
  className?: string
}

const RecipeIngredientsListEmptyState = (props: Props) => {

  const { className } = props;
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.root, className)}>
      <div className={classnames(styles.root, className)}>
        <ImageMessage
          className={styles.imageMessage}
          image={IMAGES.RECIPES.NO_RECIPES}
          imageProps={{
            width: 613,
            height: 215,
            alt: 'No recipes found'
          }}
          imageContainerClassName={styles.image}
          title={t('recipes:RECIPE_INGREDIENTS_MODAL.MESSAGES.EMPTY_STATE')}
          titleClassName={styles.title}
        />
      </div>
    </div>
  );
};

export default RecipeIngredientsListEmptyState;
