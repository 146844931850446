import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';

import { createV2 } from './StoreCreator';

import type { IStoreAction, IStoreActions } from './StoreCreator';

interface SeasonalStoreState {
  isSnowfallPaused: boolean
}

const initialState: SeasonalStoreState = {
  isSnowfallPaused: !!LocalStorage.get(LOCAL_STORAGE_KEYS.SNOWFALL_PAUSED)
};

const reducers = {
  toggleSnowfall: (draft: SeasonalStoreState, action: IStoreAction<SeasonalStoreState['isSnowfallPaused']>) => {
    const { payload } = action;
    return {
      ...draft,
      isSnowfallPaused: !!payload
    };
  }
};

type Actions = IStoreActions<typeof reducers>;

const [
  SeasonalProvider,
  useSeasonalStore,
  useDispatch,
  actions
] = createV2<SeasonalStoreState, Actions>(reducers, initialState);

function useSeasonalDispatch() {
  const dispatch = useDispatch();
  const toggleSnowfall = (props: SeasonalStoreState['isSnowfallPaused']) => {
    LocalStorage.set(LOCAL_STORAGE_KEYS.SNOWFALL_PAUSED, props);
    dispatch(actions.toggleSnowfall(props));
  };
  return { toggleSnowfall };
}

export {
  SeasonalProvider,
  useSeasonalStore,
  useSeasonalDispatch,
};
