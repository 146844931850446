import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { getActiveOrder } from 'services/OrdersService';

import type { IOrder, ReactQueryOptions } from 'types';

export const useActiveOrder = (options: ReactQueryOptions<IOrder> = {}) => {
  const {
    //
    isSettled: isAuthSettled,
    isFetching: isAuthFetching,
    isAuthenticated,
    //
    accessToken,
    //
  } = useIsAuthenticated();

  const isAuthenticatedOrder = !!accessToken;

  const query = useQuery({
    queryKey: getKey({ isAuthenticatedOrder }),
    queryFn: () => getActiveOrder(accessToken),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });

  return {
    ...query,
    isIdle: query.fetchStatus === 'idle',
    isLoading: (
      query.isLoading
      || isAuthFetching
      || !isAuthSettled
      || (isAuthSettled && isAuthenticated && !!query?.data?.isNotCreated)
    )
  };
};

interface GetKeyParams {
  isAuthenticatedOrder?: boolean
}

export const getKey = (params?: GetKeyParams) => {
  const {
    isAuthenticatedOrder
  } = params || {};

  if (typeof isAuthenticatedOrder === 'undefined') {
    return createQueryKey([
      QUERY_KEYS.ACTIVE_ORDER,
    ]);
  }

  return createQueryKey([
    QUERY_KEYS.ACTIVE_ORDER,
    isAuthenticatedOrder ? 'authenticated' : 'unauthenticated'
  ]);
};
