import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon/Icon';

import type Rating from './Rating';

import styles from './Rating.module.scss';

const STAR = '★';

// Types

interface RatingProps extends React.ComponentProps<typeof Rating> {}

type RatingSymbolsProps = RatingProps & {
  displayValue: number
}

const RatingSymbols = (props: RatingSymbolsProps) => {
  const {
    count, symbol, displayValue, symbolClassName, size, disabled, readOnly,
    onSymbolMouseEnter = () => {}, onSymbolClick = () => {}
  } = props;

  const isDisplayOnly = readOnly || disabled;

  if (isDisplayOnly) {
    return (
      <RatingReadonly
        symbol={symbol}
        symbolClassName={symbolClassName}
        size={size}
        count={count}
        displayValue={displayValue}
      />
    );
  }

  return Array(count).fill(null).map((el, index) => {
    return (
      <RatingSymbol
        key={index}
        onMouseEnter={(e) => onSymbolMouseEnter(e, index)}
        onClick={(e) => onSymbolClick(e, index)}
        active={index + 1 <= displayValue}
        symbol={symbol}
        symbolClassName={symbolClassName}
        size={size}
      />
    );
  });
};

type RatingReadonlyProps = Pick<RatingProps, 'size' | 'symbol' | 'symbolClassName' | 'count'> & {
  displayValue: number
}

const RatingReadonly = (props: RatingReadonlyProps) => {
  const {
    symbol, symbolClassName, size, count = 1, displayValue
  } = props;

  const symbolEl = symbol ? <span className={classnames(styles.symbol, symbolClassName)}><Icon name={symbol} size={size} /></span> : STAR;
  const fontSize = `${size * 1.2}px`;
  const fillPercentage = `${((displayValue * 100) / count).toFixed(2)}%`;

  const symbols = Array(count).fill(null).map((_, index) => <React.Fragment key={index}>{symbolEl}</React.Fragment>);

  return (
    <div className={styles.display} style={{ fontSize }}>
      {symbols}
      <div className={classnames(styles.inner, styles.display)} style={{ width: fillPercentage, fontSize }}>
        {symbols}
      </div>
    </div>
  );
};

type RatingSymbolProps = Pick<RatingProps, 'size' | 'symbol' | 'symbolClassName'> & {
  onMouseEnter: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void,
  onClick: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void,
  active: boolean
}

const RatingSymbol = (props: RatingSymbolProps) => {
  const {
    symbol, symbolClassName, active, onMouseEnter, onClick, size
  } = props;

  const fontSize = `${size * 1.2}px`;

  if (!symbol) {
    return (
      <button
        type="button"
        className={styles.symbolWrapper}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
      >
        <span className={classnames(styles.symbol, symbolClassName, { [styles.active]: active })} style={{ fontSize }}>★</span>
      </button>

    );
  }

  return (
    <button
      type="button"
      className={styles.symbolWrapper}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <Icon name={symbol} size={size} className={classnames(styles.symbol, symbolClassName, { [styles.active]: active })} />
    </button>
  );
};

export default RatingSymbols;
