import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ProductsService from 'services/ProductsService';

export const useFavoriteProductsMutation = () => {
  const mutation = useMutationInternal();
  return {
    isLoading: mutation.isPending,
    isError: mutation.isError,
    //
    add: (payload) => mutation.mutate({ payload, type: ACTIONS.ADD }),
    remove: (payload) => mutation.mutate({ payload, type: ACTIONS.REMOVE }),
  };
};

const useMutationInternal = () => {
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation({
    mutationKey: [MUTATION_KEYS.FAVORITES],
    mutationFn: (args = {}) => {
      const { type, payload = {} } = args;
      const { product = {} } = payload;
      const { variantCode } = product;

      switch (type) {

        case ACTIONS.ADD: {
          return ProductsService.addFavorite(variantCode);
        }

        case ACTIONS.REMOVE: {
          return ProductsService.removeFavorite(variantCode);
        }

        default:
          console.error('Unknown mutation type', type);
      }
    },
    onMutate: async (args = {}) => {
      const { type, payload = {} } = args;
      const { product = {} } = payload;
      const { variantCode } = product;

      await queryClient.cancelQueries({ queryKey: [QUERY_KEYS.FAVORITE_PRODUCT_IDS] });

      const previousState = queryClient.getQueryData([QUERY_KEYS.FAVORITE_PRODUCT_IDS]);

      switch (type) {

        case ACTIONS.ADD: {
          queryClient.setQueryData([QUERY_KEYS.FAVORITE_PRODUCT_IDS], (favorites) => ([...favorites, { code: variantCode }]));
          break;
        }

        case ACTIONS.REMOVE: {
          queryClient.setQueryData([QUERY_KEYS.FAVORITE_PRODUCT_IDS], (favorites) => favorites?.filter((item) => item.code !== variantCode));
          queryClient.setQueryData([QUERY_KEYS.FAVORITES], (favorites) => favorites?.filter((item) => item.variantCode !== variantCode));
          break;
        }

        default:
          console.error('Unknown mutation type (on mutate)', type);
      }

      return { previousState };
    },
    onSuccess: (data, args = {}) => {
      const { payload = {}, type } = args;
      const { product = {}, listProps = {} } = payload;

      const updateFavorites = (favorites) => {
        if (Array.isArray(favorites)) {
          queryClient.setQueryData([QUERY_KEYS.FAVORITE_PRODUCT_IDS], () => favorites);
        }
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FAVORITES] });
      };

      switch (type) {

        case ACTIONS.ADD:
          analytics.addToFavorites(product, listProps, 1);
          updateFavorites(data);
          break;

        case ACTIONS.REMOVE: {
          analytics.removeFromFavorites(product);
          updateFavorites(data);
          break;
        }

        default:
          console.error('Unknown mutation type (on success)', type);
      }

      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    onError: (err, args = {}) => {
      const { payload = {} } = args;
      if (payload.onError) {
        payload.onError();
      }
    },
    onSettled: (data, error, args = {}) => {
      const { payload = {} } = args;
      if (payload.onSettled) {
        payload.onSettled();
      }
    }
  });
};

const ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};
