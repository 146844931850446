import { useQuery } from '@tanstack/react-query';

import { CONFIG } from 'constants/config';
import { QUERY_KEYS } from 'constants/query-keys';
import { getFavoriteProductsIds } from 'services/ProductsService';

import type { IFavoriteProduct, ReactQueryOptions } from 'types';

export const useFavoriteProductsIds = (options: ReactQueryOptions<IFavoriteProduct[]> = {}) => {
  return useQuery({
    queryKey: getKey(),
    queryFn: () => getFavoriteProductsIds(),
    staleTime: CONFIG.DURATIONS.DEFAULT_QUERY_STALE_TIME,
    ...options
  });
};

function getKey() {
  return [QUERY_KEYS.FAVORITE_PRODUCT_IDS];
}
