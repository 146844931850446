import { pushEvent } from '../AnalyticsHelpers';

const GA_EVENT = 'ga_event';

interface GaEventParams {
  category: string,
  action: string,
  label?: string | number | object,
  param1?: string | number,
  param2?: string | number,
  param3?: string | number,
  [x: string]: any
}

export function pushGAEvent(params: GaEventParams) {
  const {
    category,
    action,
    label,
    param1,
    param2,
    param3,
    ...rest
  } = params;

  pushEvent({
    event: GA_EVENT,
    ec: category,
    ea: action,
    el: label,
    ep1: param1,
    ep2: param2,
    ep3: param3,
    ...rest
  });
}

interface RestParams {
  param1?: string | number,
  param2?: string | number,
  param3?: string | number,
  parent_index?: number,
  index?: number,
  item_list_id?: string,
  item_list_name?: string,
  [x: string]: any
}

export function getPushCategoryEventHandler<T extends string = string, P extends string = string>(category: GaEventParams['category']) {
  return (action: T, label?: P, rest?: RestParams) => {
    pushGAEvent({
      category,
      action,
      label,
      ...(rest || {})
    });
  };
}
