import { Api } from 'common/http';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';

import type { IListingRequestParams, IProductsListing } from 'types';

export async function getCustomListing(params: IListingRequestParams) {
  const {
    category, page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, filters, identifier
  } = params || {};

  const orderByQs = UrlParams.getOrderByQueryString(orderBy);
  const filtersQs = UrlParams.getFiltersQueryString(filters);
  const url = `/custom-listings/${identifier}?category=${category}&page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}`;
  const { data } = await Api.get<IProductsListing>(url);

  return data;
}
