import React from 'react';
import classnames from 'classnames';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { useIsClient } from 'hooks/useIsClient';

import type { Breakpoint } from 'hooks/useBreakpoint';

import styles from './Responsive.module.scss';

interface Props {
  className?: string,
  //
  strict?: boolean,
  breakpoint?: Breakpoint,
  //
  desktop?: React.ReactNode,
  mobile?: React.ReactNode
}

const Responsive = (props: Props) => {

  const {
    //
    className,
    //
    strict,
    breakpoint = 'sm',
    //
    mobile,
    desktop,
  } = props;

  // Hooks

  const isClient = useIsClient();
  const isDesktop = useBreakpoint(breakpoint, 'up');

  // Strict Mode

  if (strict) {
    return (
      <>
        {
          (isClient && isDesktop)
            ? desktop
            : mobile
        }
      </>
    );
  }

  // Render

  return (
    <>

      {/* Mobile */}
      <div
        className={classnames(
          styles.mobile,
          { [styles.rendered]: isClient },
          { [styles[`break-at-${breakpoint}`]]: breakpoint },
          className,
        )}
      >
        {mobile}
      </div>

      {/* Desktop */}
      {
        (isClient && isDesktop) && (
          <div
            className={classnames(
              styles.desktop,
              { [styles.rendered]: isClient },
              { [styles[`break-at-${breakpoint}`]]: breakpoint },
              className,
            )}
          >
            {desktop}
          </div>
        )
      }

    </>
  );
};

export default Responsive;
