import React from 'react';
import classnames from 'classnames';

import { YoutubeVideo } from 'components/ui';

import BlogBlockContainer from '../BlogBlockContainer/BlogBlockContainer';

import styles from './BlogVideoBlock.module.scss';

interface Props {
  url: string,
  title?: string,
  description?: string,
  className?: string,
  containerClassName?: string,
  innerContainerClassName?: string,
  textContainerClassName?: string,
  videoContainerClassName?: string
}

const BlogVideoBlock = (props: Props) => {

  const {
    title, description, url,
    containerClassName, innerContainerClassName, className,
    textContainerClassName, videoContainerClassName
  } = props;

  return (
    <BlogBlockContainer
      className={classnames(styles.container, containerClassName)}
      innerClassName={classnames(styles.innerContainer, innerContainerClassName)}
    >
      <div className={classnames(styles.root, className)}>
        <div className={classnames(styles.textContainer, textContainerClassName)}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.description}>
            {description}
          </div>
        </div>
        <div className={classnames(styles.videoContainer, videoContainerClassName)}>
          <YoutubeVideo url={url} className={styles.video} />
        </div>
      </div>
    </BlogBlockContainer>
  );
};

export default BlogVideoBlock;
