import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Button, Link, Scrollbar, SearchBox
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { isSearchMatch } from 'helpers/ObjectHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import type { IListingFilter, IListingFilterOption, IUrl } from 'types';

import styles from './FilterOptions.module.scss';

const MIN_COUNT_FOR_SEARCH = 6;

export interface Props {
  className?: string,
  optionClassName?: string,
  searchClassName?: string,
  scrollbarClassName?: string,
  noResultsClassName?: string,
  //
  parent: IListingFilter,
  options: IListingFilterOption[],
  multiple?: boolean,
  //
  withSearch?: boolean,
  withScrollbar?: boolean,
  searchProps?: any, // TODO: update to SearchBoxProps after refactor
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  onChange?: (option: IListingFilterOption, isSelected: boolean) => void
}

const FilterOptions = (props: Props) => {

  const {
    className,
    optionClassName,
    searchClassName,
    scrollbarClassName,
    noResultsClassName,
    //
    parent,
    options,
    multiple,
    //
    withSearch,
    withScrollbar,
    searchProps = {},
    //
    getHref,
    onChange,
  } = props;

  // Refs

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  // Hooks

  const { t } = useTranslation();
  const analytics = useAnalytics();
  const [searchQuery, setSearchQuery] = React.useState<string>();

  // Handlers

  const onFilterOptionClick = (option: IListingFilterOption) => {
    if (!option?.isSelected) {
      analytics.applyFilter(parent?.name, option?.name);
    } else {
      analytics.removeFilter(parent?.name, option?.name);
    }

    if (onChange) {
      onChange(option, !option.isSelected);
    }
  };

  const handleSearchChange = React.useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handleSearchClear = () => {
    setSearchQuery('');
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  // Props

  const filteredOptions = React.useMemo(() => {
    return options.filter((option) => {
      return isSearchMatch(option, searchQuery, {
        keys: ['name'],
        caseSensitive: false,
        replaceDiacritics: true,
        separated: false,
      });
    });
  }, [searchQuery, options]);

  const shouldShowSearchBar = (
    withSearch
    && options?.length >= MIN_COUNT_FOR_SEARCH
  );

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Search */}
      {
        !shouldShowSearchBar
          ? null
          : (
            <div className={classnames(styles.search, searchClassName)}>
              <SearchBox
                ref={searchInputRef}
                placeholder={t('LISTING_TOOLBAR.FILTER_BY.SEARCH_FILTERS')}
                size="small"
                variant="white"
                rounded={false}
                onChange={handleSearchChange}
                onSearchClear={handleSearchClear}
                {...searchProps}
              />
            </div>
          )
      }

      {/* List */}
      {
        !withScrollbar
          ? (
            <OptionsList
              noResultsClassName={noResultsClassName}
              optionClassName={optionClassName}
              parent={parent}
              options={withSearch ? filteredOptions : options}
              multiple={multiple}
              getHref={getHref}
              onOptionClick={onFilterOptionClick}
            />
          ) : (
            <Scrollbar
              className={scrollbarClassName}
              autoHide={false}
              forceVisible="y"
            >
              <OptionsList
                noResultsClassName={noResultsClassName}
                optionClassName={optionClassName}
                parent={parent}
                options={withSearch ? filteredOptions : options}
                multiple={multiple}
                getHref={getHref}
                onOptionClick={onFilterOptionClick}
              />
            </Scrollbar>
          )
      }
    </div>
  );
};

// Components

interface OptionsListProps {
  noResultsClassName?: string,
  optionClassName?: string,
  //
  parent: IListingFilter,
  options: IListingFilterOption[],
  multiple?: boolean,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  onOptionClick: (option: IListingFilterOption) => void
}

const OptionsList = (props: OptionsListProps) => {
  const {
    noResultsClassName,
    optionClassName,
    parent,
    options,
    multiple,
    getHref,
    onOptionClick
  } = props;

  // Hooks

  const { t } = useTranslation();

  const { persist: persistAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // Handlers

  const handleOptionClick = (option: IListingFilterOption) => {
    persistAnalyticsListProps();

    if (onOptionClick) {
      onOptionClick(option);
    }
  };

  // No Results

  if (isArrayEmpty(options)) {
    return (
      <div className={classnames(styles.noResults, noResultsClassName)}>
        {t('SEARCH.NO_RESULTS')}
      </div>
    );
  }

  // Render

  return (
    <div className={styles.list}>
      {
        options?.map((option) => {
          const {
            code, name, count, isSelected
          } = option;

          const href = getHref(option, parent);

          return (
            <Link
              key={code}
              href={href}
              prefetch={CONFIG.PREFETCH_LINKS}
              passHref
            >
              <Button
                tag="a"
                className={classnames(
                  styles.option,
                  { [styles.selected]: isSelected },
                  optionClassName
                )}
                contentClassName={styles.content}
                onClick={() => {
                  handleOptionClick(option);
                }}
              >
                {/* Checkbox */}
                <div
                  className={classnames(
                    styles.checkbox,
                    { [styles.rounded]: !multiple }
                  )}
                />

                {/* Label */}
                <div className={styles.label}>
                  {`${name} ${count ? `(${count})` : ''}`}
                </div>

              </Button>
            </Link>
          );
        })
      }
    </div>
  );
};

// Export

export default FilterOptions;
