import React from 'react';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import styles from './RecipeDetailsSection.module.scss';

interface Props {
  className?: string,
  titleClassName?: string,
  contentClassName?: string,
  title: string,
  children: React.ReactNode,
  onEnter?: (title: string) => void
}

const RecipeDetailsSection = (props: Props) => {

  const {
    className,
    titleClassName,
    contentClassName,
    title,
    children,
    onEnter,
  } = props;

  // Hooks

  const { observe } = useInView({
    threshold: 1,
    delay: 200,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter(title);
        unobserve();
      }
    }
  });

  // Render

  return (
    <div
      ref={onEnter ? observe : null}
      className={classnames(styles.root, className)}
    >

      {/* Title */}
      <div className={classnames(styles.title, titleClassName)}>
        {title}
      </div>

      {/* Content */}
      <div className={classnames(styles.content, contentClassName)}>
        {children}
      </div>

    </div>
  );
};

export default RecipeDetailsSection;
