import React from 'react';
import {
  Tab as RTab, TabList as RTabList, TabPanel as RTabPanel, Tabs as RTabs
} from 'react-tabs';
import classnames from 'classnames';

import { useTranslation } from 'hooks/common/useTranslation';

import Button from '../Button/Button';

import type { TabsController } from 'types';

import styles from './Tabs.module.scss';

interface Props {
  id?: string,
  className?: string,
  tabClassName?: string,
  selectedTabClassName?: string,
  children?: React.ReactNode,
  maxHeight?: number | null,
  center?: boolean,
  defaultIndex?: number,
  selectedIndex?: number,
  variant?: string,
  dataTestId?: string,
  onInit?: (ref?: HTMLElement | null) => {},
  onClick?: () => void
}

const TAB_VARIANTS = {
  BASIC: 'basic',
  BORDERED: 'bordered'
};

const Tab = (props: Props) => {
  const {
    children, className, dataTestId, ...rest
  } = props;
  return (
    <RTab
      className={classnames(styles.tab, className)}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </RTab>
  );
};

Tab.tabsRole = 'Tab';

interface ContainerProps {
  id?: string,
  className?: string,
  tabsClassName?: string,
  selectedTabClassName?: string,
  children?: React.ReactNode,
  maxHeight?: number | null,
  center?: boolean,
  defaultIndex?: number,
  selectedIndex?: number,
  variant?: string,
  onInit?: (ref: TabsController) => void,
  onSelect?: (index: number) => void,
  onClick?: () => void
}

const Container = (props: ContainerProps) => {
  const {
    id, className, children, maxHeight,
    tabsClassName, selectedTabClassName,
    center = false, variant = TAB_VARIANTS.BASIC,
    onInit = () => {},
    ...rest
  } = props;

  // Refs

  const ref = React.useRef<HTMLDivElement>(null);

  // Hooks

  const { t } = useTranslation();

  const [isCollapsed, setCollapsed] = React.useState(false);

  const tabsRef = React.useRef<TabsController>({
    expand: () => setCollapsed(false),
  });

  // Effects

  React.useEffect(() => {
    if (onInit) {
      onInit(tabsRef.current);
    }
  }, [tabsRef?.current]);

  React.useEffect(() => {
    if (!ref.current) return;

    if (maxHeight && ref.current.clientHeight > maxHeight && !isCollapsed) {
      setCollapsed(true);
    }
  }, []);

  // Classes

  const rootClasses = classnames(
    styles.root,
    className,
  );

  const contentClasses = classnames(
    styles.content,
    { [styles.isCollapsed]: isCollapsed }
  );

  const tabsClasses = classnames(
    styles.tabs,
    tabsClassName,
    {
      [styles.centered]: center,
      [styles.basic]: variant === TAB_VARIANTS.BASIC,
      [styles.bordered]: variant === TAB_VARIANTS.BORDERED,
    },
  );

  // Render

  return (
    <div className={rootClasses} id={id}>
      <div
        className={contentClasses}
        style={isCollapsed ? { maxHeight: `${maxHeight}px` } : {}}
        ref={ref}
      >
        <RTabs
          className={tabsClasses}
          selectedTabPanelClassName={styles.selectedPanel}
          selectedTabClassName={classnames(styles.selectedTab, selectedTabClassName)}
          {...rest}
        >
          {children}
          {isCollapsed && (
            <div className={styles.collapseContainer}>
              <Button variant="secondary" size="medium" onClick={() => setCollapsed(false)}>
                {t('TABS.READ_MORE')}
              </Button>
            </div>
          )}
        </RTabs>
      </div>
    </div>
  );
};

interface ListProps {
  children?: React.ReactNode,
  className?: string
}

const List = (props: ListProps) => {
  const { children, className, ...rest } = props;
  return (
    <RTabList className={classnames(styles.tabList, className)} {...rest}>
      {children}
    </RTabList>
  );
};

List.tabsRole = 'TabList';

interface PanelProps {
  children?: React.ReactNode,
  className?: string,
  forceRender?: boolean,
  dataTestId?: string
}

const Panel = (props: PanelProps) => {
  const {
    children, className, dataTestId, ...rest
  } = props;

  return (
    <RTabPanel
      {...rest}
      className={classnames(styles.tabPanel, className)}
      data-testid={dataTestId}
    >
      <div
        className={styles.panelContainer}
      >
        {children}
      </div>
    </RTabPanel>
  );
};

Panel.tabsRole = 'TabPanel';

export default {
  Tab, Container, List, Panel
};
