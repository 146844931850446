import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { searchProducts } from 'services/SearchService';

import type {
  IListingActiveFilters, IListingActiveOrderBy, IProductsListing, ReactQueryOptions
} from 'types';

interface Params {
  brand: string,
  brandCode: string,
  page?: number,
  itemsPerPage?: number,
  orderBy?: IListingActiveOrderBy
}

export const useSameBrandProducts = (params: Params, queryOptions: ReactQueryOptions<IProductsListing> = {}) => {

  const { brand, brandCode } = params;

  const filters: IListingActiveFilters = [
    ['brand', [brandCode]]
  ];

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => searchProducts(brand, { ...params, filters }),
    ...queryOptions
  });
};

export function getKey(params: Params) {
  const {
    brandCode, brand, page, itemsPerPage, orderBy,
  } = params;

  return createQueryKey([
    QUERY_KEYS.SAME_BRAND_PRODUCTS,
    brand,
    brandCode,
    page,
    itemsPerPage,
    orderBy
  ]);
}
