import React from 'react';
import classnames from 'classnames';

import {
  Carousel,
  Icon
} from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';

import PromotionCard from '../PromotionCard/PromotionCard';
import PromotionCardSkeleton from '../PromotionCard/PromotionCardSkeleton';

import type { Props as PromotionCardProps } from '../PromotionCard/PromotionCard';

import type { CarouselApi } from 'components/ui/Carousel';
import type { IPromotionCard } from 'types';

import styles from './PromotionsCarousel.module.scss';

const LOADING_SLIDES_COUNT = 7;

interface PromotionCardOverrideProps extends Partial<PromotionCardProps>{}

interface Props {
  className?: string,
  sliderClassName?: string,
  slideClassName?: string,
  cardClassName?: string,
  //
  loading?: boolean,
  //
  promotions?: IPromotionCard[],
  selectedCode?: string,
  //
  withNavigation?: boolean,
  withDescription?: boolean,
  withBackground?: boolean,
  fill?: boolean,
  cardProps?: PromotionCardOverrideProps,
  //
  onSelect?: (promotion: IPromotionCard) => void
}

const PromotionsCarousel = (props: Props) => {
  const {
    //
    className,
    sliderClassName,
    slideClassName,
    cardClassName,
    //
    loading,
    //
    promotions,
    selectedCode,
    //
    withNavigation = true,
    withBackground = true,
    withDescription,
    fill,
    cardProps,
    //
    onSelect = () => {},
    //
    ...rest
  } = props;

  // Hooks

  const { t } = useTranslation();
  const analytics = useAnalytics();

  const isSm = useBreakpoint('sm', 'down');

  // State Hooks

  const [isFirstSlide, setIsFirstSlide] = React.useState(false);
  const [isLastSlide, setIsLastSlide] = React.useState(false);

  const selectedPromotionIndex = React.useMemo(() => promotions?.findIndex(({ code }) => code === selectedCode), [promotions]);

  const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();
  const [carouselLoaded, setCarouselLoaded] = React.useState(false);

  // Effects

  React.useEffect(() => {
    if (carouselApi && !carouselLoaded && selectedPromotionIndex !== -1) {
      const { slideRegistry } = carouselApi.internalEngine();
      const snapIndexThatSlideBelongsTo = slideRegistry.findIndex((group) => group.includes(selectedPromotionIndex));

      if (typeof snapIndexThatSlideBelongsTo !== 'undefined') {
        carouselApi.scrollTo(snapIndexThatSlideBelongsTo);
      }

      setCarouselLoaded(true);
    }
  }, [carouselLoaded, selectedPromotionIndex, carouselApi]);

  // Handlers

  const onInit = (api: CarouselApi) => {
    setCarouselApi(api);
    updateSlidePosition(api);

    api.on('select', () => updateSlidePosition(api));
  };

  const onPromotionEnter = (promotion: IPromotionCard, index: number) => {
    analytics.viewPromotion(promotion, index + 1);
  };

  const onPromotionSelect = (promotion: IPromotionCard, index: number) => {
    analytics.selectPromotion(promotion, index + 1);
  };

  // Helpers

  const updateSlidePosition = (emblaApi: CarouselApi) => {
    const selectedSlide = emblaApi?.selectedScrollSnap();
    const totalSlides = emblaApi?.scrollSnapList().length;

    setIsFirstSlide(selectedSlide === 0);
    setIsLastSlide(selectedSlide === totalSlides - 1);
  };

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {
        (withNavigation && withBackground) && (
          <>
            {/* Fade - Left */}
            <div
              className={classnames(
                styles.fade,
                styles.left,
                { [styles.hidden]: loading || isFirstSlide },
                { [styles.small]: !isLastSlide }
              )}
              onClick={() => {
                carouselApi?.scrollPrev();
              }}
            />

            {/* Fade - Right */}
            <div
              className={classnames(
                styles.fade,
                styles.right,
                { [styles.hidden]: loading || isLastSlide }
              )}
              onClick={() => {
                carouselApi?.scrollNext();
              }}
            />
          </>
        )
      }

      {/* Slider */}
      <Carousel
        //
        className={classnames(
          styles.carousel,
          { [styles.withBackground]: withBackground },
          { [styles.withDescription]: withDescription },
        )}
        slidesWrapperClassName={classnames(
          styles.slider,
          { [styles.withBackground]: withBackground },
          sliderClassName
        )}
        prevClassName={classnames(styles.navButton, styles.prev, { [styles.hidden]: loading })}
        nextClassName={classnames(styles.navButton, styles.next, { [styles.hidden]: loading })}
        progressBarClassName={styles.progressBar}
        //
        spaceBetween={isSm ? 8 : (cardProps?.inline ? 24 : 16)}
        //
        navigation={withNavigation}
        progressBar
        //
        onInit={onInit}
        data-testid="promotions-carousel"
        //
        {...rest}
      >

        {/* Description */}
        {
          !withDescription
            ? null
            : (
              <div className={styles.description}>
                <Icon
                  name="discount"
                  size={50}
                  strokeWidth={1}
                />
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: t('PROMOTIONS.CAROUSEL.DESCRIPTION') }}
                />
              </div>
            )
        }

        {/* Slides */}
        {
          loading
            ? (
              Array.from(Array(LOADING_SLIDES_COUNT)).map((_, index) => (
                <div
                  key={`loading-${index}`}
                  className={classnames(
                    styles.slide,
                    { [styles.wide]: cardProps?.inline },
                    slideClassName,
                  )}
                >
                  <PromotionCardSkeleton
                    className={classnames(styles.card, cardClassName)}
                    {...(cardProps || {})}
                  />
                </div>
              ))
            ) : (
              promotions?.map((promotion, index) => (
                <div
                  key={`${promotion.code}${index}`}
                  className={classnames(
                    styles.slide,
                    { [styles.wide]: cardProps?.inline },
                    { [styles.thumbnail]: cardProps?.asThumbnail },
                    { [styles.padded]: withBackground },
                    slideClassName
                  )}
                >
                  {
                    loading
                      ? (
                        <PromotionCardSkeleton
                          className={classnames(styles.card, cardClassName)}
                          {...cardProps}
                        />
                      ) : (
                        <PromotionCard
                          className={classnames(styles.card, cardClassName)}
                          promotion={promotion}
                          selected={promotion?.code === selectedCode}
                          fill={fill}
                          onEnter={() => {
                            onPromotionEnter(promotion, index);
                          }}
                          onClick={() => {
                            onPromotionSelect(promotion, index);
                            onSelect(promotion);
                          }}
                          {...cardProps}
                        />
                      )
                  }
                </div>
              ))
            )
        }
      </Carousel>

    </div>
  );
};

export default PromotionsCarousel;
