import React from 'react';

import { AccountBenefitsModal, PhoneVerificationModal } from 'components/account';
import { GeniusFastCheckoutModal, GeniusTrialModal } from 'components/genius';
import { OrderGiftsModal } from 'components/orders';
import { ProductQuantityVariantsModal, SimilarProductsModal } from 'components/product';
import { PromotionsModal } from 'components/promotions';
import { RecommendationsModal } from 'components/recommendation';
import { useModalStore } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';

const ModalContainer = () => {

  const {
    visible: isOpen,
    type,
    props: modalProps,
  } = useModalStore();

  return (
    <>
      {/* Similar Products */}
      <SimilarProductsModal
        isOpen={isOpen && type === MODAL_TYPES.SIMILAR_PRODUCTS}
        {...modalProps}
      />

      {/* Product quantity variants */}
      <ProductQuantityVariantsModal
        isOpen={isOpen && type === MODAL_TYPES.PRODUCT_VARIANTS}
        {...modalProps}
      />

      {/* Available Promotions */}
      <PromotionsModal
        isOpen={isOpen && type === MODAL_TYPES.AVAILABLE_PROMOTIONS}
        {...modalProps}
      />

      {/* Recommendations */}
      <RecommendationsModal
        isOpen={isOpen && type === MODAL_TYPES.RECOMMENDATIONS}
        {...modalProps}
      />

      {/* Order Gifts */}
      <OrderGiftsModal
        isOpen={isOpen && type === MODAL_TYPES.ORDER_GIFTS}
        {...modalProps}
      />

      {/* Genius FastCheckout */}
      <GeniusFastCheckoutModal
        isOpen={isOpen && type === MODAL_TYPES.GENIUS_FAST_CHECKOUT}
        {...modalProps}
      />

      {/* Phone verification */}
      <PhoneVerificationModal
        isOpen={isOpen && type === MODAL_TYPES.PHONE_VERIFICATION}
        {...modalProps}
      />

      {/* Genius trial */}
      <GeniusTrialModal
        isOpen={isOpen && type === MODAL_TYPES.GENIUS_TRIAL}
        {...modalProps}
      />

      {/* Account Benefits */}
      <AccountBenefitsModal
        isOpen={isOpen && type === MODAL_TYPES.ACCOUNT_BENEFITS}
        {...modalProps}
      />
    </>
  );
};

export default ModalContainer;
