import { Api, CmsApi } from 'common/http';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';
import { getPrimaryIngredientsSkuList } from 'helpers/RecipeHelpers';

import {
  toRecipe,
  toRecipeIngredientProducts,
  toRecipesByTag,
  toRecipesLandingPage,
  toRecipesPage
} from './RecipeAdapter';

import type {
  HttpClientConfig,
  IListingRequestParams,
  IProductsListing,
  IRecipePrimaryIngredients,
  IRecipeResponse,
  IRecipesByTagPageResponse,
  IRecipesLandingPageResponse,
  IRecipesPageResponse,
  MarkdownSerializeFunction,
} from 'types';

// --- All Recipes
// --- GET /recipes?page={page}&limit={limit}

const orderByOptionsMap = {
  publishedat: 'publishedAt',
  priceperportion: 'pricePerPortion'
};

export interface GetRecipesParams extends IListingRequestParams{
  preview?: boolean
}

export async function getRecipes(params: GetRecipesParams) {
  const {
    page = 1, itemsPerPage = CONFIG.PAGINATION.RECIPES, preview, searchQuery,
    // filters,
    orderBy
  } = params || {};

  const httpClientConfig = getHttpClientConfig({ preview });
  const searchQueryString = searchQuery ? `&search=${searchQuery}` : '';
  const paginationQueryString = `page=${page}&limit=${itemsPerPage}`;
  const orderByQs = UrlParams.getOrderByQueryString(orderBy, orderByOptionsMap);

  const endpoint = `/recipes?${paginationQueryString}${searchQueryString}${orderByQs}`;

  const { data: response } = await CmsApi.get<IRecipesPageResponse>(endpoint, httpClientConfig);
  const { data, meta } = response || {};

  const recipesPage = toRecipesPage(data, meta);
  return recipesPage;
}

// --- Recipes Landing Page
// --- GET /recipes-landing-page

export interface GetRecipesLandingPageParams {
  preview?: boolean
}

export async function getRecipesLandingPage(params: GetRecipesLandingPageParams) {
  const { preview } = params || {};

  const httpClientConfig = getHttpClientConfig({ preview });

  const { data: response } = await CmsApi.get<IRecipesLandingPageResponse>('/recipes-landing-page', httpClientConfig);
  const { data, meta } = response || {};

  const recipesLandingPage = toRecipesLandingPage(data, meta);

  return recipesLandingPage;
}

// --- Recipes By Tag
// --- GET /recipes-by-tag/by-adi-hadean?page={page}&limit={limit}

export interface GetRecipesByTagParams extends IListingRequestParams {
  preview?: boolean
}

export async function getRecipesByTag(params: GetRecipesByTagParams) {
  const {
    identifier: tag, page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, preview
  } = params || {};

  const httpClientConfig = getHttpClientConfig({ preview });
  const orderByQs = UrlParams.getOrderByQueryString(orderBy, orderByOptionsMap);
  const endpoint = `/recipes?tag=${tag}&page=${page}&limit=${itemsPerPage}${orderByQs}`;

  const { data: response } = await CmsApi.get<IRecipesByTagPageResponse>(endpoint, httpClientConfig);
  const { meta, data } = response || {};

  return toRecipesByTag(data, meta);
}

// --- Recipe
// --- GET /recipes/{slug}

export interface GetRecipeParams {
  preview?: boolean,
  slug: string
}

export async function getRecipe(params: GetRecipeParams, markdownSerializeFn?: MarkdownSerializeFunction) {
  const { slug, preview } = params || {};

  const httpClientConfig = getHttpClientConfig({ preview });
  const { data: response } = await CmsApi.get<IRecipeResponse>(`/recipes/${slug}`, httpClientConfig);
  const { data, meta } = response || {};

  const recipe = await toRecipe(data, meta, markdownSerializeFn);
  return recipe;
}

// --- Recipe Ingredients
// --- GET /products-multi-sku?skus={skus}

interface RecipeIngredientsParams {
  ingredients: IRecipePrimaryIngredients,
  itemsPerPage?: number,
  page?: number
}

export async function getRecipeIngredients(params: RecipeIngredientsParams) {
  const { page = 1, itemsPerPage = 12, ingredients } = params || {};
  const skus = getPrimaryIngredientsSkuList(ingredients);
  const skuQs = skus.map((sku) => `sku[]=${sku}`).join('&');

  const endpoint = `/products-multi-sku?page=${page}&itemsPerPage=${itemsPerPage}&${skuQs}`;

  const { data } = await Api.get(endpoint);
  const { items: products } = data;

  const ingredientProducts = toRecipeIngredientProducts(ingredients, products);
  return ingredientProducts;
}

// --- Recipe Ingredients Listing
// --- GET custom-listings/{slug}

export async function getRecipeIngredientsListing(slug: string, itemsPerPage: number = 20) {
  const { data } = await Api.get<IProductsListing>(`/custom-listings/${slug}?category=all&page=1&itemsPerPage=${itemsPerPage}`);
  return data;
}

/* --- Helpers */

function getHttpClientConfig({ preview }: {preview?: boolean}): HttpClientConfig {
  return preview
    ? { headers: { 'x-content-preview-token': CONFIG.STRAPI.PREVIEW_ACCESS_TOKEN } }
    : {};
}
