import React from 'react';
import { useTranslation } from 'next-i18next';

import {
  Accordion, Button, Image, Radio, Scrollbar
} from 'components/ui';
import { useOrderReportProblemDispatchHelpers } from 'context/OrderReportProblemContext';
import { isEmptyObject } from 'helpers/utils';

import OrderProblemNavigation from './OrderProblemNavigation';
import OrderProblemSummary from './OrderProblemSummary';

import styles from './OrderProblemModal.module.scss';

const OrderProblemIncomplete = (props) => {
  const {
    order,
    problem = {},
    showCurrentStep,
    selection: initialSelection
  } = props;

  // Hooks

  const { t } = useTranslation();
  const [selection, setSelection] = React.useState({});
  const { showStep } = useOrderReportProblemDispatchHelpers();

  // Props

  const { itemSelectionTitle, itemSelectionGroups } = problem;
  const IMAGE_SIZE = 48;

  // Effects

  React.useEffect(() => {
    if (initialSelection) {
      setSelection(initialSelection);
    }
  }, [initialSelection]);

  // Handlers

  const onRadioChange = (id, group) => {
    const draft = { ...selection };
    draft[id] = [group];
    setSelection(draft);
  };

  const onRadioClick = (id) => {
    if (selection?.[id]) {
      const draft = { ...selection };
      delete draft[id];
      setSelection(draft);
    }
  };

  const showSummaryStep = () => {
    const orderItems = Object.keys(selection).map((key) => {
      const orderItemId = Number(key);
      const orderItem = order?.summaryItems?.find((oi) => oi.id === orderItemId);
      const {
        productName, productSlug, sku: productSku, image
      } = orderItem || {};
      return {
        orderItemId,
        productName,
        productSlug,
        productSku,
        image,
        groups: selection[key]
      };
    });
    showStep({
      title: OrderProblemNavigation,
      titleProps: {
        title: t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_TITLE'),
        onBackClick: () => showCurrentStep({ selection })
      },
      component: OrderProblemSummary,
      componentProps: { payload: { orderItems }, problem }
    });
  };

  // Render

  return (
    <div>
      <div className={styles.description}>{itemSelectionTitle}</div>
      <div className={styles.list}>
        <Scrollbar className={styles.scrollbar} forceVisible="y" autoHide={false}>
          {order?.summaryItems?.map((item) => {
            const {
              productName, productSlug, image, id
            } = item;
            return (
              <Accordion
                className={`${styles.productAccordion} ${styles.listItem}`}
                key={productSlug}
                initiallyExpanded={initialSelection?.[id]}
                title={(
                  <div className={styles.productAccordionTitle}>
                    <div className={styles.imageContainer}>
                      <Image
                        src={image}
                        height={IMAGE_SIZE}
                        width={IMAGE_SIZE}
                      />
                    </div>
                    <div className={styles.nameContainer}>
                      {productName}
                    </div>
                  </div>
                )}
              >
                <div className={styles.productAccordionContent}>
                  {itemSelectionGroups.map((reason) => {
                    const { group, singularName } = reason;
                    return (
                      <Radio
                        key={`${group}-${id}`}
                        className={styles.radio}
                        label={singularName}
                        labelClassName={styles.radioLabel}
                        onChange={() => onRadioChange(id, group)}
                        name={`group-${id}`}
                        checked={!!(selection?.[id]?.includes(group))}
                        onClick={() => onRadioClick(id)}
                      />
                    );
                  })}
                </div>
                <div />
              </Accordion>
            );
          })}
        </Scrollbar>
      </div>
      <div className={styles.footer}>
        <Button
          variant="primary"
          onClick={showSummaryStep}
          disabled={isEmptyObject(selection)}
        >
          {t('ACTIONS.CONTINUE')}
        </Button>
      </div>
    </div>
  );
};

export default OrderProblemIncomplete;
