import React from 'react';
import classnames from 'classnames';

import { Button, ButtonVariants } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';

import type { ButtonVariant } from 'components/ui';
import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './ProductSimilarProductButton.module.scss';

interface Props {
  className?: string,
  //
  size?: Size,
  variant?: ButtonVariant,
  //
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ProductSimilarProductButton = (props: Props) => {
  const {
    className,
    //
    size = Sizes.MEDIUM,
    variant = ButtonVariants.SUBTLE,
    //
    onClick
  } = props;

  const { t } = useTranslation();

  return (
    <Button
      className={classnames(
        styles.root,
        { [styles[size]]: !!size },
        { [styles[variant]]: !!variant },
        className
      )}
      size={size}
      variant={variant}
      onClick={onClick}
      //
      aria-label="View similar products"
    >
      {t('PRODUCT.SIMILAR_PRODUCTS')}
    </Button>
  );
};

export default ProductSimilarProductButton;
