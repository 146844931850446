import * as Einstein from '../Einstein';

import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { ICategory, ICategoryProductsListing, IUser } from 'types';

const CATEGORY = 'category_events';

enum Actions {
  VIEW_CATEGORY_PAGE = 'view_category_page'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- View Cart Error

export function viewCategoryPage(user: IUser | null | undefined, category: ICategory | ICategoryProductsListing['payload']) {
  push(Actions.VIEW_CATEGORY_PAGE, category?.name);

  if (user) {
    Einstein.trackCategoryView(user, category);
  }
}
