import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import * as Notifications from 'common/Notification';
import { Button, RatingCard } from 'components/ui';
import { ORDER_FEEDBACK_NEXT_STEPS } from 'helpers/OrderHelpers';
import { useOrderFeedbackMutation } from 'hooks/data/useOrderFeedbackMutation';

import styles from './OrderRatingCard.module.scss';

const SIZES = {
  MEDIUM: 'medium',
  LARGE: 'large'
};

const ELEVATION_LEVELS = [0, 1, 2, 3];

const OrderRatingCard = (props) => {

  const {
    className,
    orderToken,
    rating,
    updatedRating,
    size,
    readOnly,
    disabled,
    submitted,
    withSubmitButton,
    onSubmit = () => {},
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
    children,
    ...ratingCardProps
  } = props;

  const { t } = useTranslation();

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [currentRating, setCurrentRating] = React.useState(rating);

  const { isLoading: isMutationLoading, sendRating } = useOrderFeedbackMutation();

  // Effects

  React.useEffect(() => {
    onLoading(isMutationLoading);
  }, [isMutationLoading]);

  React.useEffect(() => {
    if (rating) {
      setCurrentRating(rating);
    }
  }, [rating]);

  React.useEffect(() => {
    setCurrentRating(updatedRating);
  }, [updatedRating]);

  // Handlers

  const onRatingChange = (value) => {
    if (isSubmitted || isMutationLoading) return;

    setCurrentRating(value);

    if (!withSubmitButton) {
      handleSubmit(null, value);
    }
  };

  const handleSubmit = (ev, selectedRating) => {
    onSubmit();

    sendRating({
      tokenValue: orderToken,
      rating: selectedRating || currentRating,
      onSuccess: (response = {}) => {
        const { nextStep } = response;
        const isCompleted = (nextStep === ORDER_FEEDBACK_NEXT_STEPS.COMPLETED_MESSAGE);

        if (isCompleted) {
          setIsSubmitted(true);
        }

        onSuccess(response);
      },
      onError: (err) => {
        setCurrentRating(0);
        Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'orderFeedbackRatingError' });
        onError(err);
      },
      onSettled: () => {
        onLoading(false);
      }
    });
  };

  // Empty

  if (!orderToken) return null;

  // Render

  return (
    <RatingCard
      className={className}
      {...ratingCardProps}
      size={size}
      disabled={disabled || isMutationLoading}
      readOnly={readOnly}
      submitted={submitted || isSubmitted}
      rating={readOnly ? rating : currentRating}
      onChange={onRatingChange}
    >
      {
        withSubmitButton && (
          <div className={styles.actions}>
            <Button
              variant="primary"
              size={size}
              onClick={handleSubmit}
              loading={isMutationLoading}
              disabled={isMutationLoading || !currentRating}
            >
              {t('ORDER_FEEDBACK_RATING_FORM.ACTIONS.SUBMIT')}
            </Button>
          </div>
        )
      }
      {children}
    </RatingCard>
  );
};

OrderRatingCard.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  elevation: PropTypes.oneOf(ELEVATION_LEVELS),
  size: PropTypes.oneOf(Object.values(SIZES)),
  rating: PropTypes.number,
  updatedRating: PropTypes.number,
  title: PropTypes.string,
  info: PropTypes.string,
  success: PropTypes.string,
  inline: PropTypes.bool,
  submitted: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  orderToken: PropTypes.string,
  withPadding: PropTypes.bool,
  withSubmitButton: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onLoading: PropTypes.func,
  withoutConfirmation: PropTypes.bool,
  children: PropTypes.element,
};

export default OrderRatingCard;
