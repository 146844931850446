import React, { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Button, Icon, Modal, Stepper
} from 'components/ui';
import { isObjectEmpty } from 'helpers/ObjectHelpers';
import { useStepper } from 'hooks/useStepper';

import OrderFeedbackLowRatingForm from '../OrderFeedbackLowRatingForm/OrderFeedbackLowRatingForm';
import OrderFeedbackTestimonialForm from '../OrderFeedbackTestimonialForm/OrderFeedbackTestimonialForm';
import OrderItem from '../OrderItem/OrderItem';
import OrderRatingCard from '../OrderRatingCard/OrderRatingCard';

import type {
  IOrder, IOrderFeedbackResponse, OrderFeedbackNextStepType, ValueOf
} from 'types';
import { ORDER_FEEDBACK_NEXT_STEPS } from 'types';

import styles from './OrderFeedbackModal.module.scss';

const STEP_IDS = {
  RATING: 'ratingForm',
  LOW_RATING: 'lowRatingForm',
  TESTIMONIAL: 'testimonialForm',
  SUCCESS: 'successMessage',
  ERROR: 'errorMessage',
} as const;

export type StepId = ValueOf<typeof STEP_IDS>;

const NEXT_STEPS = {
  [ORDER_FEEDBACK_NEXT_STEPS.TESTIMONIAL_FORM]: STEP_IDS.TESTIMONIAL,
  [ORDER_FEEDBACK_NEXT_STEPS.LOW_RATING_FORM]: STEP_IDS.LOW_RATING,
  [ORDER_FEEDBACK_NEXT_STEPS.COMPLETED_MESSAGE]: STEP_IDS.SUCCESS,
} as const;

const getNextStepId = (nextStep: OrderFeedbackNextStepType): StepId => {
  return NEXT_STEPS[nextStep];
};

interface Props {
  isOpen: boolean,
  order: IOrder,
  isLatestOrder: true,
  initialResponse?: IOrderFeedbackResponse,
  close: () => void
}

const OrderFeedbackModal = (props: Props) => {

  const {
    isOpen,
    order,
    initialResponse,
    isLatestOrder,
    close = () => {}
  } = props;

  // Hooks

  // TODO: add type after useStepper is migrated to TS
  const { activeStep, goTo } = useStepper(
    STEP_IDS,
    getNextStepId(initialResponse?.nextStep)
  );

  const [currentResponse, setCurrentResponse] = React.useState<IOrderFeedbackResponse>(null);

  // Handlers

  const updateState = (response: IOrderFeedbackResponse) => {
    const { nextStep } = response || {};

    setCurrentResponse(response);

    const nextStepId = getNextStepId(nextStep);

    if (nextStepId) {
      goTo(nextStepId);
    }
  };

  const onEditRating = () => {
    goTo(STEP_IDS.RATING);

    setTimeout(() => {
      setCurrentResponse(null);
    });
  };

  const onSuccess = (response: IOrderFeedbackResponse) => {
    updateState(response);
  };

  const onError = () => {
    setCurrentResponse(null);
    goTo(STEP_IDS.ERROR);
  };

  // Effects

  React.useEffect(() => {
    if (!isObjectEmpty(initialResponse)) {
      updateState(initialResponse);
    }
  }, [initialResponse]);

  // Steps

  const steps = [
    {
      id: STEP_IDS.RATING,
      component: RatingStep,
      componentProps: {
        order,
        isLatestOrder,
        response: currentResponse,
        onSuccess,
        onError
      }
    },
    {
      id: STEP_IDS.LOW_RATING,
      component: LowRatingStep,
      componentProps: {
        order,
        isLatestOrder,
        response: currentResponse,
        onEditRating,
        onSuccess,
        onError
      }
    },
    {
      id: STEP_IDS.TESTIMONIAL,
      component: TestimonialStep,
      componentProps: {
        order,
        response: currentResponse,
        onSuccess,
        onError
      }
    },
    {
      id: STEP_IDS.SUCCESS,
      component: SuccessStep,
    },
    {
      id: STEP_IDS.ERROR,
      component: ErrorStep,
    }
  ];

  return (
    <Modal
      //
      isOpen={isOpen && !!order?.tokenValue}
      //
      className={styles.root}
      contentClassName={styles.content}
      //
      withPadding={false}
      shouldCloseOnOverlayClick={false}
      //
      breakpoint="sm"
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom'
      }}
      headerProps={{
        sticky: true,
      }}
      //
      close={close}
    >
      <div className={styles.body}>
        <Stepper
          vertical
          contentClassName={styles.stepperContent}
          activeStep={activeStep}
          steps={steps}
        />
      </div>
    </Modal>
  );
};

// Steps

// --- Rating

interface RatingStepProps {
  order: IOrder,
  isLatestOrder: boolean,
  response: IOrderFeedbackResponse,
  onSuccess: (res: Response) => void,
  onError: (err: string) => void
}

const RatingStep = forwardRef<HTMLDivElement, RatingStepProps>((
  props: RatingStepProps,
  ref: React.Ref<HTMLDivElement>
) => {

  const {
    order,
    isLatestOrder,
    response,
    onSuccess,
    onError
  } = props;

  // Hooks

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  // Props

  const {
    rating
  } = response || {};

  const title = isLatestOrder
    ? 'ORDER_FEEDBACK_RATING_FORM.LATEST_ORDER_RATING_LABEL'
    : 'ORDER_FEEDBACK_RATING_FORM.CURRENT_ORDER_RATING_LABEL';

  // Render

  return (
    <div
      ref={ref}
      className={classnames(styles.step, styles.ratingStep)}
    >
      <OrderRatingCard
        className={styles.ratingCard}
        //
        elevation={0}
        size="large"
        withPadding={false}
        //
        disabled={isDisabled}
        title={title}
        orderToken={order?.tokenValue}
        updatedRating={rating}
        //
        onSubmit={() => {
          setIsDisabled(true);
        }}
        onSuccess={(res) => {
          onSuccess(res);
          setIsDisabled(false);
        }}
        onError={(err) => {
          onError(err);
          setIsDisabled(false);
        }}
        onLoading={setIsLoading}
      />
      <OrderItem
        className={classnames(styles.orderItem, { [styles.disabled]: isLoading })}
        order={order}
        inline
        withHeader={false}
        withOrderPlacedDate={false}
      />
    </div>
  );
});

// --- Testimonial

interface TestimonialStepProps {
  order: IOrder,
  response: IOrderFeedbackResponse,
  onSuccess: () => void,
  onError: () => void
}

const TestimonialStep = forwardRef<HTMLDivElement, TestimonialStepProps>((
  props: TestimonialStepProps,
  ref: React.Ref<HTMLDivElement>
) => {

  const {
    order,
    response,
    onSuccess,
    onError,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const {
    rating
  } = response || {};

  // Render

  return (
    <div
      ref={ref}
      className={classnames(styles.step, styles.withTitle, styles.testimonialStep)}
    >
      {/* Title */}
      <div className={styles.title}>
        {t('ORDER_FEEDBACK_COMMON.TITLE')}
      </div>

      {/* Form */}
      <OrderFeedbackTestimonialForm
        labelClassName={styles.label}
        orderToken={order?.tokenValue}
        rating={rating}
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
});

// --- Low Rating

interface LowRatingStepProps {
  order: IOrder,
  isLatestOrder: boolean,
  response: IOrderFeedbackResponse,
  onEditRating: () => void,
  onSuccess: () => void,
  onError: () => void
}

const LowRatingStep = forwardRef<HTMLDivElement, LowRatingStepProps>((
  props: LowRatingStepProps,
  ref: React.Ref<HTMLDivElement>
) => {

  const {
    order,
    isLatestOrder,
    response,
    onEditRating,
    onSuccess,
    onError,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const {
    rating,
    ratingEditable: canEditRating,
    driver
  } = response || {};

  const currentRatingTitle = rating === 1
    ? t(isLatestOrder ? 'ORDER_FEEDBACK_COMMON.LATEST_ORDER_RATING_SINGULAR' : 'ORDER_FEEDBACK_COMMON.CURRENT_ORDER_RATING_SINGULAR')
    : t(isLatestOrder ? 'ORDER_FEEDBACK_COMMON.LATEST_ORDER_RATING_PLURAL' : 'ORDER_FEEDBACK_COMMON.CURRENT_ORDER_RATING_PLURAL', { rating });

  // Render

  return (
    <div
      ref={ref}
      className={classnames(styles.step, styles.withTitle, styles.lowRatingStep)}
    >

      {/* Title */}
      <div className={styles.title}>
        {t('ORDER_FEEDBACK_COMMON.TITLE')}
      </div>

      {/* Rating Card */}
      <OrderRatingCard
        className={classnames(styles.ratingCard, styles.boxed)}
        elevation={0}
        size="medium"
        readOnly
        title={currentRatingTitle}
        orderToken={order?.tokenValue}
        rating={rating}
        withoutConfirmation
      >
        {
          canEditRating && (
            <div className={styles.ratingCardActions}>
              <Button
                className={styles.btn}
                variant="primary"
                onClick={() => {
                  onEditRating();
                }}
                size="medium"
              >
                {t('ORDER_FEEDBACK_PAGE.CHANGE_RATING')}
              </Button>
            </div>
          )
        }
      </OrderRatingCard>

      {/* Form */}
      <OrderFeedbackLowRatingForm
        className={styles.form}
        orderToken={order?.tokenValue}
        rating={rating}
        driver={driver}
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );

});

// --- Success

interface SuccessStepProps {}

const SuccessStep = forwardRef<HTMLDivElement, SuccessStepProps>((
  props: SuccessStepProps,
  ref: React.Ref<HTMLDivElement>
) => {

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <div
      ref={ref}
      className={classnames(styles.step, styles.successStep)}
    >
      {/* Icon */}
      <div className={styles.icon}>
        <Icon name="check" size={28} strokeWidth={2} />
      </div>

      {/* Title */}
      <div className={styles.title}>
        <p>{t('ORDER_FEEDBACK_COMMON.SUCCESS')}</p>
      </div>
    </div>
  );
});

// --- Error

interface ErrorStepProps {}

const ErrorStep = forwardRef<HTMLDivElement, ErrorStepProps>((
  props: ErrorStepProps,
  ref: React.Ref<HTMLDivElement>
) => {

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <div
      ref={ref}
      className={classnames(styles.step, styles.errorStep)}
    >
      {/* Icon */}
      <div className={styles.icon}>
        <Icon name="x" size={28} strokeWidth={2} />
      </div>

      {/* Title */}
      <div className={styles.title}>
        <p>{t('ERRORS.DEFAULT_TOAST')}</p>
      </div>
    </div>
  );
});

export default OrderFeedbackModal;
