import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useOrderBenefits } from 'hooks/data/useOrderBenefits';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { refreshOrderBenefits } from 'services/OrdersService';

interface Options {
  shouldRefetch?: boolean,
  disabled?: boolean
}

// TODO see why return type does not have 'data' as undefined
export function useGeniusBenefits(options?: Options) {

  const {
    shouldRefetch,
    disabled
  } = options || {};

  // Hooks

  const queryClient = useQueryClient();

  // Data Hooks

  const {
    isAuthenticated,
  } = useIsAuthenticated();

  const {
    isError: isOrderError,
    data: order,
    refetch: refetchActiveOrder
  } = useActiveOrder();

  React.useEffect(() => {
    if (isAuthenticated && order?.isNotCreated) {
      refetchActiveOrder();
    }
  }, [isAuthenticated, order]);

  const {
    isLoading: isBenefitsLoading,
    isError: isBenefitsError,
    data: benefits,
    refetch: refetchBenefits,
  } = useOrderBenefits(order?.tokenValue, {
    enabled: !!order?.tokenValue && !disabled
  });

  // Helpers

  const refresh = React.useCallback(async (refreshOptions?: { shouldInvalidate?: boolean }) => {
    try {
      const { shouldInvalidate } = refreshOptions || {};

      if (order?.tokenValue) {
        const data = await refreshOrderBenefits(order?.tokenValue);

        queryClient.setQueryData([QUERY_KEYS.ORDER_BENEFITS], data);

        // invalidate Genius related queries
        if (shouldInvalidate) {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INTERVALS_BY_ORDER] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADDRESSES] });
          // TODO should remove if backend returns correct status
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDER_BENEFITS] });
        }

        return data;
      }
    } catch (err) {
      console.error('Error while refreshing benefits', err);
    }
  }, [order?.tokenValue]);

  // Effects

  React.useEffect(() => {
    if (benefits && order?.itemsHash && shouldRefetch) {
      refetchBenefits();
    }
  }, [order?.itemsHash, shouldRefetch]);

  // Return

  return {
    isLoading: !order?.customer ? true : isBenefitsLoading,
    isError: isOrderError || isBenefitsError,
    data: benefits?.genius ? benefits.genius : undefined,
    refresh
  };

}
