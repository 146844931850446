import React from 'react';
import classnames from 'classnames';

import { Markdown, YoutubeVideo } from 'components/ui';
import { useAnalytics } from 'hooks/useAnalytics';

import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import type { IStrapiVideo } from 'types';

import styles from './RecipeDetailsVideo.module.scss';

interface Props {
  className?: string,
  titleClassName?: string,
  descriptionClassName?: string,
  videoClassName?: string,
  //
  recipeId: number,
  video: IStrapiVideo
}

const RecipeDetailsVideo = (props: Props) => {

  const {
    className,
    titleClassName,
    descriptionClassName,
    videoClassName,
    //
    recipeId,
    video
  } = props;

  const {
    id,
    url,
    title,
    description,
  } = video || {};

  // Hooks

  const analytics = useAnalytics();

  // Empty

  if (!url) return null;

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      titleClassName={titleClassName}
      title={title}
    >

      {/* Description */}
      {
        description && (
          <Markdown
            className={classnames(styles.description, descriptionClassName)}
            text={description}
          />
        )
      }

      {/* Video */}
      <YoutubeVideo
        className={classnames(styles.video, videoClassName)}
        id={id}
        url={url}
        onEnter={() => {
          analytics.viewRecipeVideo(recipeId);
        }}
        // onClick={() => {
        //   analytics.clickRecipeVideo(recipeId);
        // }}
      />

    </RecipeDetailsSection>
  );
};

export default RecipeDetailsVideo;
