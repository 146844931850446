/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Icon, Textarea } from 'components/ui';
import { useOrderFeedbackMutation } from 'hooks/data/useOrderFeedbackMutation';

import styles from './OrderFeedbackTestimonialForm.module.scss';

const MIN_TEXTAREA_LENGTH = 20;
const MAX_TEXTAREA_LENGTH = 500;

// Initial values

const initialValues = {
  testimonial: ''
};

const OrderFeedbackTestimonialForm = (props) => {

  const {
    className,
    label,
    labelClassName,
    highlight,
    center,
    orderToken,
    rating,
    onSuccess = () => {},
    onError = () => {},
  } = props;

  const { t } = useTranslation();
  const { isLoading: isMutationLoading, sendFeedback } = useOrderFeedbackMutation();

  // Validation

  const validationSchema = React.useMemo(() => yup.object().shape({
    testimonial: yup
      .string()
      .required(t('VALIDATION_ERRORS.REQUIRED'))
      .min(MIN_TEXTAREA_LENGTH, t('VALIDATION_ERRORS.MIN_LENGTH_POLITE', { count: MIN_TEXTAREA_LENGTH }))
      .max(MAX_TEXTAREA_LENGTH, t('VALIDATION_ERRORS.MAX_LENGTH_POLITE', { count: MAX_TEXTAREA_LENGTH }))
  }), [t]);

  // Handlers

  const onCancel = () => {
    handleSubmit({
      testimonial: null
    });
  };

  const handleSubmit = (fields) => {
    sendFeedback({
      tokenValue: orderToken,
      rating,
      fields,
      onSuccess,
      onError
    });
  };

  // Empty

  if (!orderToken) return null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const {
          isSubmitting
        } = formikProps;

        return (
          <Form
            className={classnames(
              styles.form,
              { [styles.center]: center },
              className
            )}
          >

            {/* Testimonial */}
            <div className={classnames(styles.formGroup, { [styles.highlightGroup]: highlight })}>

              {/* Textarea */}
              <Textarea
                className={styles.textarea}
                labelClassName={classnames(styles.label, labelClassName)}
                errorClassName={styles.error}
                label={t(label)}
                name="testimonial"
                disabled={isMutationLoading || isSubmitting}
                placeholder={t('ORDER_FEEDBACK_TESTIMONIAL_FORM.TESTIMONIAL_PLACEHOLDER')}
                minRows={2}
                maxRows={5}
              />

              {/* Info */}
              <div className={styles.info}>
                <Icon className={styles.icon} name="alert-circle" size={16} strokeWidth={2} />
                <div dangerouslySetInnerHTML={{ __html: t('ORDER_FEEDBACK_TESTIMONIAL_FORM.TESTIMONIAL_INFO') }} />
              </div>
            </div>

            {/* Actions */}
            <div className={styles.actions}>

              {/* Skip */}
              <Button
                type="button"
                className={styles.btn}
                variant="empty"
                onClick={onCancel}
                disabled={isMutationLoading || isSubmitting}
              >
                {t('ORDER_FEEDBACK_TESTIMONIAL_FORM.ACTIONS.SKIP')}
              </Button>

              {/* Submit */}
              <Button
                type="submit"
                className={styles.btn}
                variant="primary"
                loading={isMutationLoading || isSubmitting}
                disabled={(!(formikProps.dirty && formikProps.isValid)) || isSubmitting}
              >
                {t('ORDER_FEEDBACK_TESTIMONIAL_FORM.ACTIONS.SUBMIT')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

OrderFeedbackTestimonialForm.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  highlight: PropTypes.bool,
  orderToken: PropTypes.string,
  rating: PropTypes.number,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default OrderFeedbackTestimonialForm;
