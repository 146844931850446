import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { Image, Loader } from 'components/ui';
import * as OrderHelpers from 'helpers/OrderHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useActiveOrder } from 'hooks/data/useActiveOrder';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import { ProductBadge, ProductPrice } from '../../parts';

import ProductLink from '../../ProductLink/ProductLink';

import ProductSearchCardActions from './ProductSearchCardActions';

import type {
  IAnalyticsListProps,
  IProduct
} from 'types';

import styles from './ProductSearchCard.module.scss';

interface Props {
  className?: string,
  taxesClassName?: string,
  //
  product: IProduct,
  listProps: IAnalyticsListProps,
  //
  inline?: boolean,
  inlinePrice?: boolean,
  shouldShowGeniusPrice?: boolean,
  bypassQuantityVariants?: boolean,
  //
  withoutAddToCarText?: boolean,
  withoutBrand?: boolean,
  withoutImage?: boolean,
  withoutLink?: boolean,
  withoutTaxes?: boolean
}

const ProductSearchCard = (props: Props) => {
  const {
    className,
    taxesClassName,
    //
    product,
    listProps = {},
    //
    inline,
    inlinePrice = true,
    shouldShowGeniusPrice,
    bypassQuantityVariants,
    //
    withoutBrand,
    withoutAddToCarText,
    withoutImage,
    withoutLink,
    withoutTaxes
  } = props;

  // Hooks

  const { data: order } = useActiveOrder();

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    set: setAnalyticsListProps
  } = useRouteQueryAnalyticsListProps(listProps);

  // Props

  const orderItem = React.useMemo(() => OrderHelpers.getOrderItem(order, product), [order, product]);
  const defaultVariant = React.useMemo(() => ProductHelpers.getProductDefaultVariant(product, { shouldShowGeniusPrice }), [product, shouldShowGeniusPrice]);

  const { isOk, error } = orderItem || {};

  const {
    isAvailable,
    slug,
    image,
    name,
    brand,
    badge,
    currency,
    taxes,
  } = product;

  const {
    originalPriceStrikethrough,
    originalPrice,
    price,
    priceColor,
    priceIcon,
  } = defaultVariant;

  const IMAGE_SIZE = 96;

  const Tag = withoutLink ? 'div' : 'a';

  // Handlers

  const handleClick = () => {
    setAnalyticsListProps(listProps);
  };

  // Render

  return (
    <ProductLink
      isDirectLink
      slug={slug}
      withoutLink={withoutLink}
    >
      <Tag
        className={classnames(
          styles.root,
          { [styles.link]: !withoutLink },
          { [styles.inline]: inline },
          className
        )}
        onClick={handleClick}
      >

        {/* Thumbnail */}
        {
          !withoutImage && (
            <div className={styles.imageContainer}>

              {/* Image */}
              <Image
                className={classnames(
                  styles.image,
                  { [styles.unavailable]: !isAvailable }
                )}
                height={IMAGE_SIZE}
                width={IMAGE_SIZE}
                src={image?.thumbnail}
                quality={100}
              />

              {/* Badge */}
              <ProductBadge
                className={styles.badge}
                badge={badge}
                size="small"
                withPrefix={false}
              />

              {/* Loading Overlay */}
              <Fade
                className={styles.loadingOverlay}
                isVisible={isLoading}
                lazyLoad
                enterDuration={200}
                exitDuration={200}
              >
                <Loader
                  size={18}
                  lineWeight={2}
                />
              </Fade>

            </div>
          )
        }

        {/* Content */}
        <div
          className={classnames(
            styles.content,
            { [styles.inline]: inline }
          )}
        >

          <div className={styles.main}>

            {/* Brand */}
            {
              brand && !withoutBrand && (
                <div className={styles.brand}>
                  {brand}
                </div>
              )
            }

            {/* Name */}
            <div className={styles.name}>
              {name}
            </div>

            {/* Price / Error */}
            {
              !inlinePrice && (
                <div className={styles.priceContainer}>

                  {/* Price */}
                  <ProductPrice
                    className={styles.productPrice}
                    priceWrapperClassName={styles.priceWrapper}
                    taxesClassName={classnames(styles.taxes, taxesClassName)}
                    //
                    currency={currency}
                    withOriginalPriceStrikethrough={originalPriceStrikethrough}
                    originalPrice={originalPrice}
                    price={price}
                    taxes={withoutTaxes ? null : taxes}
                    //
                    color={priceColor}
                    icon={priceIcon}
                    //
                    align="start"
                    size="small"
                  />

                  {/* Error */}
                  {
                    !isOk && (
                      <div className={styles.error}>{error}</div>
                    )
                  }
                </div>
              )
            }

          </div>

          <div className={styles.secondary}>

            {/* Price / Error */}
            {
              inlinePrice && (
                <div className={styles.priceContainer}>

                  {/* Price */}
                  <ProductPrice
                    className={styles.productPrice}
                    priceWrapperClassName={styles.priceWrapper}
                    taxesClassName={classnames(styles.taxes, taxesClassName)}
                    //
                    currency={currency}
                    withOriginalPriceStrikethrough={originalPriceStrikethrough}
                    originalPrice={originalPrice}
                    price={price}
                    taxes={withoutTaxes ? null : taxes}
                    //
                    color={priceColor}
                    icon={priceIcon}
                    //
                    align={inline ? 'end' : 'start'}
                    size="small"
                  />

                  {/* Error */}
                  {
                    !isOk && (
                      <div className={styles.error}>{error}</div>
                    )
                  }
                </div>
              )
            }

            {/* Actions */}
            {
              !inline && (
                <div className={styles.actions}>
                  <ProductSearchCardActions
                    order={order}
                    product={product}
                    orderItem={orderItem}
                    listProps={listProps}
                    bypassQuantityVariants={bypassQuantityVariants}
                    //
                    withAddToCartText={!withoutAddToCarText}
                    //
                    onLoading={setIsLoading}
                  />
                </div>
              )
            }
          </div>
        </div>

        {/* Actions */}
        {
          inline && (
            <div
              className={classnames(
                styles.actions,
                { [styles.fixedWidth]: !withoutAddToCarText }
              )}
            >
              <ProductSearchCardActions
                order={order}
                product={product}
                orderItem={orderItem}
                listProps={listProps}
                //
                withAddToCartText={!withoutAddToCarText}
                //
                onLoading={setIsLoading}
              />
            </div>
          )
        }
      </Tag>
    </ProductLink>

  );
};

export default ProductSearchCard;
