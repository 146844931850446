import React from 'react';
import { useTranslation } from 'next-i18next';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { ModalBody } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useRecipeIngredients } from 'hooks/data/useRecipeIngredients';

import RecipeIngredientsList from '../RecipeIngredientsList/RecipeIngredientsList';

import type { IRecipeIngredientProduct } from 'types';

import styles from './RecipeIngredientsModalBackupsStep.module.scss';

interface Props {
  ingredientProductToReplace: IRecipeIngredientProduct,
  //
  canNavigateBack?: boolean,
  //
  onSelectIngredient: (backup: IRecipeIngredientProduct) => void,
  onBack: () => void,
  onClose: () => void
}

const RecipeIngredientsModalBackupsStep = (props: Props) => {
  const {
    ingredientProductToReplace,
    //
    canNavigateBack,
    //
    onClose,
    onBack,
    onSelectIngredient,
  } = props;

  const {
    sku,
    ingredient,
  } = ingredientProductToReplace || {};

  const {
    backups
  } = ingredient || {};

  // Hooks

  const { t } = useTranslation();

  const [selectedBackup, setSelectedBackup] = React.useState<IRecipeIngredientProduct>(ingredientProductToReplace);

  // Data Hooks

  const { isLoading: isLoadingIngredientProducts, data: ingredientProducts } = useRecipeIngredients({
    ingredients: backups || [],
  }, {
    enabled: !isArrayEmpty(backups)
  });

  // Props

  const validIngredientProducts = ingredientProducts?.filter(({ product }) => {
    const { isAvailable } = product || {};
    return isAvailable;
  });

  const hasBackups = !isArrayEmpty(validIngredientProducts);
  const canReplace = !!selectedBackup && selectedBackup?.product?.isAvailable;

  // Render

  return (
    <ModalBody
      className={styles.root}
      headerProps={{
        className: styles.header,
        titleClassName: styles.title,
        title: t('recipes:RECIPE_INGREDIENTS_MODAL.BACKUPS.TITLE'),
        desktopProps: {
          size: 'large',
          withCloseButton: true,
          withBackButton: canNavigateBack,
        },
        responsiveProps: {
          size: 'medium',
          withCloseButton: true,
          withBackButton: canNavigateBack,
        },
        onClose,
        onBack,
      }}
      actionsProps={{
        primary: {
          icon: 'cart-plus',
          text: t('recipes:RECIPE_INGREDIENTS_MODAL.BACKUPS.ACTIONS.REPLACE'),
          disabled: !canReplace,
          onClick: () => {
            onSelectIngredient(selectedBackup);
          }
        }
      }}
    >

      {/* Description */}
      <p
        className={classnames(
          styles.description,
        )}
      >
        {
          isLoadingIngredientProducts
            ? <Skeleton count={1} />
            : (
              hasBackups
                ? t('recipes:RECIPE_INGREDIENTS_MODAL.BACKUPS.DESCRIPTION.AVAILABLE')
                : t('recipes:RECIPE_INGREDIENTS_MODAL.BACKUPS.DESCRIPTION.UNAVAILABLE')
            )
        }
      </p>

      {/* List */}
      <RecipeIngredientsList
        className={styles.list}
        radioClassName={styles.radio}
        itemClassName={classnames(
          styles.item,
          styles.withRadio,
        )}
        //
        name={`replace-ingredient-${sku}`}
        //
        isLoading={isLoadingIngredientProducts}
        loadingCount={backups?.length ? backups.length + 1 : undefined}
        //
        ingredientProducts={[
          ingredientProductToReplace,
          ...validIngredientProducts || []
        ]}
        //
        canSelect
        selectedSku={selectedBackup?.sku}
        //
        cardProps={{
          withActions: false,
        }}
        //
        onSelectIngredient={(ingredientProduct: IRecipeIngredientProduct) => {
          setSelectedBackup(ingredientProduct);
        }}
      />

    </ModalBody>
  );
};

export default RecipeIngredientsModalBackupsStep;
