export const darkenColor = (hex: string, amount: number): string => {
  // Convert hex to RGB
  let r: number = parseInt(hex.slice(1, 3), 16);
  let g: number = parseInt(hex.slice(3, 5), 16);
  let b: number = parseInt(hex.slice(5, 7), 16);

  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max: number = Math.max(r, g, b);
  const min: number = Math.min(r, g, b);
  const l: number = (max + min) / 2;
  let h: number = 0;
  let s: number = 0;

  if (max !== min) {
    const d: number = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  // Darken color
  const lightness: number = Math.max(0, l - amount / 100);
  const saturation: number = s;

  // Convert HSL back to RGB
  const [rFinal, gFinal, bFinal]: number[] = hslToRgb(h, saturation, lightness);

  // Convert RGB back to hex
  let rHex: string = Math.round(rFinal * 255).toString(16);
  let gHex: string = Math.round(gFinal * 255).toString(16);
  let bHex: string = Math.round(bFinal * 255).toString(16);

  // Ensure 2 digits by padding
  rHex = rHex.length === 1 ? `0${rHex}` : rHex;
  gHex = gHex.length === 1 ? `0${gHex}` : gHex;
  bHex = bHex.length === 1 ? `0${bHex}` : bHex;

  return `#${rHex}${gHex}${bHex}`;
};

function hslToRgb(h: number, s: number, l: number): number[] {
  const a: number = s * Math.min(l, 1 - l);
  // eslint-disable-next-line no-mixed-operators
  const f = (n: number, k: number = (n + h * 360 / 30) % 12): number => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return [f(0), f(8), f(4)];
}
