import React from 'react';

import { USER_ACCOUNT_TYPES } from 'constants/user';
import { getUserDetails, updateUserDetails } from 'helpers/AuthenticationHelpers';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';

export function useUserObserver() {

  // Hooks

  const analytics = useAnalytics();

  const { data: user } = useUser({ isOptional: true });
  const { id, isGeniusUser } = user || {};
  const accountType = isGeniusUser ? USER_ACCOUNT_TYPES.GENIUS : USER_ACCOUNT_TYPES.STANDARD;

  const userDetails = getUserDetails();
  const { customerId: storedId, accountType: storedAccountType } = userDetails || {};

  // Effects

  React.useEffect(() => {
    if (typeof id !== 'undefined' && id !== storedId) {
      updateUserDetails({ customerId: id });
      analytics.setUserId(id);
    }
  }, [id, storedId]);

  React.useEffect(() => {
    if (typeof accountType !== 'undefined' && accountType !== storedAccountType) {
      updateUserDetails({ accountType });
      analytics.setAccountType(accountType);
    }
  }, [accountType, storedAccountType]);
}
