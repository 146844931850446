import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { IMAGES } from 'constants/images';

import ImageMessage from '../ImageMessage/ImageMessage';

import styles from './Error.module.scss';

interface Props {
  className?: string,
  imageContainerClassName?: string,
  titleClassName?: string,
  subtitleClassName?: string,
  title?: string,
  subtitle?: string,
  small?: boolean,
  children?: React.ReactNode
}

const Error = (props: Props) => {
  const {
    className,
    imageContainerClassName,
    titleClassName,
    subtitleClassName,
    title,
    subtitle,
    small = false,
    children
  } = props;
  const { t } = useTranslation();

  return (
    <ImageMessage
      className={classnames(styles.root, className)}
      imageContainerClassName={classnames(styles.imageContainer, imageContainerClassName)}
      titleClassName={
        small
          ? classnames(styles.title, titleClassName)
          : titleClassName
      }
      subtitleClassName={
        small
          ? classnames(styles.subtitle, subtitleClassName)
          : subtitleClassName
      }
      image={IMAGES.ERRORS.GENERIC}
      imageProps={{
        width: 414,
        height: 246,
        alt: 'Error'
      }}
      title={title || t('ERRORS.DEFAULT_TITLE')}
      subtitle={subtitle || t('ERRORS.DEFAULT_SUBTITLE')}
    >
      {children}
    </ImageMessage>
  );
};

export default Error;
