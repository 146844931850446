import type { CSSProperties } from 'react';
import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Icon, Image, Tooltip } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { hexToRgbA } from 'helpers/ColorHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { darkenColor } from 'helpers/StylingHelpers';
import { parseAbsoluteUrl } from 'helpers/UrlHelpers';
import { useAnalytics } from 'hooks/useAnalytics';

import {
  type IProductBanner,
  type InfoBannerType, InfoBannerTypes, type Nullable
} from 'types';

import styles from './InfoBanner.module.scss';

export interface Props {
  className?: string,
  tooltipTitleClassName?: string,
  tooltipTextClassName?: string,
  tooltipContainerClassName?: string,
  tooltipPosition?: ('top' | 'bottom' | 'left' | 'right')[],
  //
  banner?: Nullable<IProductBanner>,
  productSlug?: string,
  //
  full?: boolean,
  tall?: boolean,
  excludedTypes?: InfoBannerType[],
  //
  analyticsEventType?: string,
  analyticsEventPayload?: {
    promotion_id: string,
    promotion_name: string
  },
  //
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  handleModalClose?: () => void,
  //
  scrollContainer?: HTMLElement
}

const InfoBanner = (props: Props) => {

  const {
    tooltipTitleClassName,
    tooltipTextClassName,
    tooltipContainerClassName,
    tooltipPosition = ['top'],
    //
    banner,
    productSlug,
    //
    onClick,
    handleModalClose,
    //
    scrollContainer
  } = props;

  const {
    type,
    link,
    analyticsEventType,
    analyticsEventPayload,
    tooltip
  } = banner || {};

  // Hooks
  const ref = React.useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { pathname } = parseAbsoluteUrl(link);
  const analytics = useAnalytics();

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    switch (type) {

      // Accelerate Sale

      case InfoBannerTypes.ACCELERATED_SALE: {
        onClick(e);
        const productLink = ProductHelpers.getProductLink(productSlug, true) || undefined;
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            quickView: router.route.startsWith(`${APP_ROUTES.PRODUCTS}/`) ? null : productSlug,
            sm: true
          },
        }, productLink, { scroll: false });
        break;
      }

      // Default

      default: {
        if (analyticsEventType) {
          analytics.ecommerceEvent(analyticsEventType, analyticsEventPayload);
        }

        if (pathname) {
          if (handleModalClose) {
            handleModalClose();
          }
          router.push(pathname);
        }
      }
    }
  };

  // Without tooltip
  if (!tooltip) {
    return <Banner {...props} onClick={handleClick} />;
  }

  // With tooltip
  return (
    <div
      className={styles.tooltipWrapper}
      ref={ref}
    >
      <Tooltip
        containerClassName={classnames(styles.tooltipContainer, tooltipContainerClassName)}
        contentClassName={styles.tooltipContent}
        titleClassName={classnames(styles.tooltipTitle, tooltipTitleClassName)}
        textClassName={tooltipTextClassName}
        parentElement={ref as React.MutableRefObject<HTMLElement>}
        positions={tooltipPosition}
        scrollContainer={scrollContainer}
        padding={12}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        title={(
          <>
            <Icon name="brand-freshful" size={18} />
            <span>{tooltip.title}</span>
          </>
        )}
        text={tooltip.value}
      >
        <Banner {...props} onClick={undefined} />
      </Tooltip>
    </div>
  );
};

interface IBannerProps extends React.ComponentProps<typeof InfoBanner> {}

const Banner = (props: IBannerProps) => {
  const {
    className,
    banner,
    full,
    tall,
    onClick,
  } = props;
  const {
    text,
    icon,
    textColor = styles.color,
    bgColor,
    tooltip,
  } = banner || {};

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
  };
  // Classes

  return (
    <button
      style={{
        '--color': textColor,
        '--background-color': hexToRgbA(bgColor, 1),
        '--background-hover-color': darkenColor(bgColor, 7)
      } as CSSProperties}
      className={classnames(
        styles.root,
        { [styles.full]: full },
        { [styles.tall]: tall },
        className
      )}
      type="button"
      onClick={(e) => onClickHandler(e)}
    >

      {/* Content */}
      <div className={styles.content}>

        {/* Start Icon */}
        {
          !!icon && (
            <div className={styles.icon}>
              <Image
                src={icon}
                width={16}
                height={16}
                alt={text}
              />
            </div>
          )
        }

        {/* Text */}
        <div className={styles.name}>{text}</div>

      </div>

      {/* End Icon */}
      {
        tooltip
          ? (
            <Icon
              name="info-circle"
              size={16}
              className={styles.info}
            />
          ) : (
            <Icon
              name="chevron-right"
              size={12}
              className={styles.arrow}
            />
          )
      }
    </button>
  );
};
export default InfoBanner;
