import { LocalStorage } from 'common/Storage';
import { LOCAL_STORAGE_KEYS } from 'constants/storage';

export const isQueryDuplicate = (text: string, arr: string[]) => arr.find((query) => query === text);

export const DISPLAYED_PREVIOUS_RESULTS = 10;

export const getPreviousQueries = (): string[] => {
  const cookieRecentSearches = LocalStorage.get(LOCAL_STORAGE_KEYS.RECENT_SEARCHES);
  return cookieRecentSearches || [];
};

export const getNewQueries = (text: string, previousSearchQueries: string[]) => {
  const array = [text, ...previousSearchQueries.slice(0, DISPLAYED_PREVIOUS_RESULTS)];
  LocalStorage.set(LOCAL_STORAGE_KEYS.RECENT_SEARCHES, array);
  return array;
};

export const clearPreviousQueries = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.RECENT_SEARCHES);
  return [];
};
