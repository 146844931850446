import type {
  IFilters,
  IFiltersResponse,
  IListingPagination,
  IOrderBy,
  IOrderByResponse,
} from 'types';

// Response

// --- History

export interface ILoyaltyHistoryResponse extends IListingPagination {
  filters: IFiltersResponse,
  orderBy: IOrderByResponse,
  items: ILoyaltyHistoryResponseItem[]
}

// --- History Item

export interface ILoyaltyHistoryResponseItem {
  icon: IThemedColors,
  iconBgColor: IThemedColors,
  //
  title: string,
  subtitle: string,
  datetime: string,
  //
  status: LoyaltyItemStatus,
  type: LoyaltyItemTransactionType,
  label: string,
  sublabel?: string,
  //
  details?: ILoyaltyHistoryResponseItemDetails[],
  pdfUrl?: string
}

export interface ILoyaltyHistoryResponseItemDetails {
  key: string,
  value: string
}

// Generic

export interface IThemedColors {
  light: string,
  dark: string
}

// Models

// --- History

export interface ILoyaltyHistory {
  filters: IFilters,
  orderBy: IOrderBy,
  pagination: IListingPagination,
  items: ILoyaltyHistoryItem[]
}

// --- History Item

export interface ILoyaltyHistoryItem {
  meta: {
    status: LoyaltyItemStatus,
    type: LoyaltyItemTransactionType
  },
  icon?: {
    src?: string,
    bgColor?: string
  },
  data: {
    title: string,
    subtitle: string,
    date: string,
    value: string,
    subValue?: string,
    details?: ILoyaltyHistoryItemDetails[] | null,
    pdfUrl?: string
  }
}

// --- History Item Details

export interface ILoyaltyHistoryItemDetails {
  label: string,
  value: string
}

// Constants

// --- History Item Status

export const LOYALTY_ITEM_STATUSES = {
  VALID: 'valid',
  EXPIRED: 'expired',
  PENDING_EXPIRED: 'pending_expire',
  CANCELLED: 'cancelled'
} as const;

export type LoyaltyItemStatus = typeof LOYALTY_ITEM_STATUSES[keyof typeof LOYALTY_ITEM_STATUSES];

// --- History Item Transaction Type

export const LOYALTY_ITEM_TRANSACTION_TYPES = {
  INCOME: 'income',
  EXPENSE: 'expense'
} as const;

export type LoyaltyItemTransactionType = typeof LOYALTY_ITEM_TRANSACTION_TYPES[keyof typeof LOYALTY_ITEM_TRANSACTION_TYPES];
