import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { IAnalyticsListProps, IRecipeMeta, IRecipeTag } from 'types';

const CATEGORY = 'recipe_events';

enum Actions {
  SELECT_RECIPE_TAG = 'select_recipe_tag',
  VIEW_RECIPE_CARD = 'view_recipe_card',
  SELECT_RECIPE_CARD = 'select_recipe_card',
  VIEW_RECIPE_CAROUSEL = 'view_recipe_carousel',
  SELECT_RECIPE_CAROUSEL = 'select_recipe_carousel',
  SELECT_VIEW_RECIPE_INGREDIENTS = 'select_view_recipe_ingredients',
  VIEW_RECIPE_OUT_OF_STOCK_INGREDIENTS = 'view_recipe_out_of_stock_ingredients',
  START_REPLACE_INGREDIENT = 'start_replace_ingredient',
  COMPLETE_REPLACE_INGREDIENT = 'complete_replace_ingredient',
  CANCEL_REPLACE_INGREDIENT = 'cancel_replace_ingredient',
  VIEW_RECIPE_COOKING_STEPS = 'view_recipe_cooking_steps',
  VIEW_RECIPE_VIDEO = 'view_recipe_video',
  CLICK_RECIPE_VIDEO = 'click_recipe_video',
  SEARCH_RECIPES = 'recipes_search'
}

enum Labels {
  ALL_RECIPES_TAG = 'all-recipes'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function selectRecipeTag(tagId: IRecipeTag['id']) {
  push(Actions.SELECT_RECIPE_TAG, `${tagId}`);
}

export function selectAllRecipesTag() {
  push(Actions.SELECT_RECIPE_TAG, Labels.ALL_RECIPES_TAG);
}

export function viewRecipeCarousel(name: string, index?: number, listProps?: IAnalyticsListProps) {
  push(Actions.VIEW_RECIPE_CAROUSEL, name, {
    index,
    item_list_id: listProps?.analyticsListId,
    item_list_name: listProps?.analyticsListName
  });
}

export function selectRecipeCarousel(name: string, index?: number, listProps?: IAnalyticsListProps) {
  push(Actions.SELECT_RECIPE_CAROUSEL, name, {
    index,
    item_list_id: listProps?.analyticsListId,
    item_list_name: listProps?.analyticsListName
  });
}

export function viewRecipeCard(recipeId: IRecipeMeta['id']) {
  push(Actions.VIEW_RECIPE_CARD, `${recipeId}`);
}

export function selectRecipeCard(recipeId: IRecipeMeta['id']) {
  push(Actions.SELECT_RECIPE_CARD, `${recipeId}`);
}

export function selectRecipeViewIngredients(recipeId: IRecipeMeta['id']) {
  push(Actions.SELECT_VIEW_RECIPE_INGREDIENTS, `${recipeId}`);
}

export function viewRecipeOutOfStockIngredients(recipeId: IRecipeMeta['id']) {
  push(Actions.VIEW_RECIPE_OUT_OF_STOCK_INGREDIENTS, `${recipeId}`);
}

export function startRecipeIngredientReplace(recipeId: IRecipeMeta['id'], productSku: string) {
  push(Actions.START_REPLACE_INGREDIENT, `${recipeId} - ${productSku}`);
}

export function completeRecipeIngredientReplace(recipeId: IRecipeMeta['id'], productSku: string, replacementProductSku: string) {
  push(Actions.COMPLETE_REPLACE_INGREDIENT, `${recipeId} - ${productSku} - ${replacementProductSku}`);
}

export function cancelRecipeIngredientReplace(recipeId: IRecipeMeta['id'], productSku: string) {
  push(Actions.CANCEL_REPLACE_INGREDIENT, `${recipeId} - ${productSku}`);
}

export function viewRecipeCookingSteps(recipeId: IRecipeMeta['id']) {
  push(Actions.VIEW_RECIPE_COOKING_STEPS, `${recipeId}`);
}

export function viewRecipeVideo(recipeId: IRecipeMeta['id']) {
  push(Actions.VIEW_RECIPE_VIDEO, `${recipeId}`);
}

export function clickRecipeVideo(recipeId: IRecipeMeta['id']) {
  push(Actions.CLICK_RECIPE_VIDEO, `${recipeId}`);
}

export function searchRecipes(query: string) {
  push(Actions.SEARCH_RECIPES, query);
}
