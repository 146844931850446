import React from 'react';
import classnames from 'classnames';

import { Collapsible, Fade } from 'components/animations';
import { useTranslation } from 'hooks/common/useTranslation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import Card from '../Card/Card';
import Icon from '../Icon/Icon';
import Rating from '../Rating/Rating';

import styles from './RatingCard.module.scss';

// Constants

const SIZES = {
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

const ICON_SIZES = {
  [SIZES.MEDIUM]: 20,
  [SIZES.LARGE]: 28
};

const ELEVATION_LEVELS = [0, 1, 2, 3] as const;

// Types

type RatingSize = typeof SIZES[keyof typeof SIZES];
type ElevationLevel = typeof ELEVATION_LEVELS[number];

interface Props {
  className: string,
  elevation: ElevationLevel,
  size: RatingSize,
  title: string,
  info: string,
  success: string,
  rating: number,
  inline: boolean,
  readOnly: boolean,
  submitted: boolean,
  disabled: boolean,
  withPadding: boolean,
  onChange: () => void,
  children?: React.ReactNode[]
}

const RatingCard = (props: Props) => {

  const {
    className,
    elevation,
    size = SIZES.MEDIUM,
    title,
    info,
    success,
    inline = false,
    disabled,
    rating,
    readOnly,
    submitted,
    withPadding,
    onChange = () => {},
    children
  } = props;

  const { t } = useTranslation();

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const classes = classnames(
    styles.root,
    { [styles[size]]: size },
    { [styles.inline]: inline },
    { [styles.disabled]: disabled },
    { [styles.simple]: !success },
    className
  );

  const ratingClasses = classnames(
    styles.rating,
    { [styles.submitted]: submitted }
  );

  // W/O success animation

  if (!success) {
    return (
      <Card
        className={classes}
        size={size}
        withPadding={withPadding}
        elevation={elevation}
      >
        <div className={styles.ratingContainer}>
          <Rating
            className={ratingClasses}
            symbolClassName={styles.symbol}
            size={isBreakpoint ? 40 : 32}
            symbol="star"
            value={rating}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
          />
        </div>

        <div className={styles.textContainer}>
          <div className={styles.title}>{t(title)}</div>
          <div className={styles.info}>{t(info)}</div>
        </div>

        <div className={styles.content}>{children}</div>
      </Card>
    );
  }

  // With success animation

  return (
    <Card
      className={classes}
      size={size}
      withPadding={withPadding}
      elevation={elevation}
    >
      <div className={styles.ratingContainer}>

        {/* Rating */}
        <Fade
          isVisible={!submitted}
          persist
          yOffset={5}
          enterDelay={600}
        >
          <Rating
            className={ratingClasses}
            symbolClassName={styles.symbol}
            size={isBreakpoint ? 40 : 32}
            symbol="star"
            value={rating}
            readOnly={readOnly}
            onChange={onChange}
          />
        </Fade>

        {/* Check */}
        <Fade
          className={styles.check}
          isVisible={submitted}
          yOffset={-5}
          enterDelay={600}
        >
          <Icon name="check" size={ICON_SIZES[size]} />
        </Fade>

      </div>
      <div className={styles.textContainer}>

        {/* Title / Info */}
        <Fade
          isVisible={!submitted}
          persist
          yOffset={5}
          enterDelay={300}
        >
          <div className={styles.title}>{t(title)}</div>
          <div className={styles.info}>{t(info)}</div>
        </Fade>

        {/* Success */}
        <Fade
          className={styles.success}
          isVisible={submitted}
          yOffset={-5}
          enterDelay={300}
        >
          <div className={styles.title}>{t(success)}</div>
        </Fade>

        {/* Children */}
        {
          (children && !!children?.length && !!children.filter((c) => !!c).length) && (
            <Collapsible isExpanded={!submitted}>
              <div className={styles.inner}>
                {children}
              </div>
            </Collapsible>
          )
        }
      </div>
    </Card>
  );
};

// Export

export default RatingCard;
