import React, { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';

import {
  Button, Image, ImageMessage, Modal, Stepper
} from 'components/ui';
import { IMAGES } from 'constants/images';
import { useAccountMutation } from 'hooks/data/useAccountMutation';
import { useConfig } from 'hooks/data/useConfig';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useStepper } from 'hooks/useStepper';

import styles from './NewsletterSubscribeModal.module.scss';

const STEP_IDS = {
  NEWSLETTER_FORM: 'NEWSLETTER_FORM',
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
};

const CLOSE_MODAL_DELAY = 3000;

interface Props {
  isOpen: boolean,
  isSubmitted?: boolean,
  isBoost?: boolean,
  onContentOpen?: () => void,
  //
  close: () => void
}

const NewsletterSubscribeModal = (props: Props) => {

  const {
    isOpen,
    isBoost,
    close,
    onContentOpen,
    ...rest
  } = props;

  // Refs

  const closeTimeoutRef = React.useRef<any>(null);

  // Hooks

  const analytics = useAnalytics();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const { activeStep, next, goTo } = useStepper(STEP_IDS);

  const { data: config } = useConfig();
  const { newsletterInfo } = config || {};

  const { isLoading, subscribeToNewsletter } = useAccountMutation();

  // Effects

  React.useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  // Handlers

  const onSubmit = () => {
    subscribeToNewsletter({
      onSuccess: () => {
        if (isBoost) {
          analytics.subscribeToNewsletterFromBoost();
        } else {
          analytics.subscribeToNewsletterFromCheckout();
        }

        next();

        closeTimeoutRef.current = setTimeout(() => {
          close();
        }, CLOSE_MODAL_DELAY);
      },
      onError: () => {
        handleClose();
      }
    });
  };

  const handleClose = () => {
    close();
  };

  const onAfterClose = () => {
    goTo(STEP_IDS.NEWSLETTER_FORM);
  };

  // Steps

  const steps = [
    {
      id: STEP_IDS.NEWSLETTER_FORM,
      component: NewsletterForm,
      componentProps: {
        title: newsletterInfo?.SUBSCRIBE_DIALOG_TITLE,
        subtitle: newsletterInfo?.SUBSCRIBE_DIALOG_SUBTITLE,
        cta: newsletterInfo?.SUBSCRIBE_DIALOG_CTA,
        isLoading,
        onSubmit,
      }
    },
    {
      id: STEP_IDS.SUCCESS_MESSAGE,
      component: SuccessMessage,
      componentProps: {
        title: newsletterInfo?.SUBSCRIBED_CONFIRMATION_TITLE,
        subtitle: newsletterInfo?.SUBSCRIBED_CONFIRMATION_SUBTITLE,
      }
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      contentClassName={styles.content}
      //
      priority={5}
      //
      withPadding={false}
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
      }}
      headerProps={{
        sticky: true,
      }}
      //
      close={handleClose}
      onContentClose={onAfterClose}
      onContentOpen={onContentOpen}
      //
      {...rest}
    >
      <div>
        <Stepper
          contentClassName={styles.stepperContent}
          vertical
          inverted={isBreakpoint}
          activeStep={activeStep}
          steps={steps}
        />
      </div>
    </Modal>
  );
};

// Components

// --- Newsletter Form

interface NewsletterFormProps {
  isLoading?: boolean,
  //
  title: string,
  subtitle: string,
  cta: string,
  //
  onSubmit: () => void
}

const NewsletterForm = forwardRef<HTMLDivElement, NewsletterFormProps>((
  props: NewsletterFormProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    isLoading,
    //
    title,
    subtitle,
    cta,
    //
    onSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <div ref={ref}>

      {/* Image */}
      <div className={styles.imageWrapper}>
        <Image
          className={styles.image}
          src={IMAGES.NEWSLETTER.SUBSCRIBE}
          width="383"
          height="213"
          alt="Newsletter subscribe"
        />
      </div>

      {/* Body */}
      <div className={styles.body}>

        {/* Title */}
        <div className={styles.title}>
          {title || t('NEWSLETTER.MODAL.TITLE')}
        </div>

        {/* Subtitle */}
        <div className={styles.subtitle}>
          {subtitle || t('NEWSLETTER.MODAL.SUBTITLE')}
        </div>

        {/* Action */}
        <Button
          className={styles.submitBtn}
          variant="primary"
          disabled={isLoading}
          loading={isLoading}
          onClick={onSubmit}
        >
          {cta || t('NEWSLETTER.MODAL.CTA')}
        </Button>
      </div>
    </div>
  );
});

// --- Success Message

interface SuccessMessageProps {
  title: string,
  subtitle: string
}

const SuccessMessage = forwardRef<HTMLDivElement, SuccessMessageProps>((
  props: SuccessMessageProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    title,
    subtitle
  } = props;

  const { t } = useTranslation();

  return (
    <div ref={ref} className={styles.successContainer}>
      <ImageMessage
        className={styles.success}
        image={IMAGES.COMMON.SUCCESS}
        imageProps={{
          width: 100,
          height: 100,
          alt: 'Success icon'
        }}
        title={title || t('NEWSLETTER.MODAL.SUCCESS_TITLE')}
        subtitle={subtitle || t('NEWSLETTER.MODAL.SUCCESS_SUBTITLE')}
      />
    </div>
  );
});

export default NewsletterSubscribeModal;
