import React from 'react';
import classnames from 'classnames';

import { Chips } from 'components/ui';

import styles from './BlogTags.module.scss';

interface Props {
  className?: string,
  tagsClassName?: string,
  tagWrapperClassName?: string,
  tagClassName?: string,
  tags: {
    slug: string,
    name: string,
    href: string,
    selected?: boolean
  }[],
  onTagClick?: (e?: any) => void
}

const BlogTags = (props: Props) => {

  const {
    className,
    tagsClassName,
    tagWrapperClassName,
    tagClassName,
    tags,
    onTagClick,
  } = props;

  return (
    <div className={classnames(styles.root, className)}>
      <Chips
        className={tagsClassName}
        chipWrapperClassName={tagWrapperClassName}
        chipClassName={tagClassName}
        items={tags}
        onChipClick={onTagClick}
      />
    </div>
  );
};

export default BlogTags;
