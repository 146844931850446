import React from 'react';

import { BreadcrumbJsonLd, NextSeo } from 'next-seo';

import type { PageSeoProps } from 'types';

interface Props extends PageSeoProps {}

const PageSeo = (props: Props) => {
  const { seoProps = {}, breadcrumbs } = props;
  return (
    <>
      <NextSeo {...seoProps} />
      {breadcrumbs && <BreadcrumbJsonLd itemListElements={breadcrumbs} />}
    </>
  );
};

export default PageSeo;
