import * as Einstein from '../Einstein';

import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

import type { IProduct, IUser } from 'types';

const CATEGORY = 'product_events';

enum Actions {
  UNDO_DELETE = 'undo_delete',
  ADD_QUANTITY_VARIANT = 'add_quantity_variant',
  SELECT_QUANTITY_VARIANT = 'select_quantity_variant',
  VIEW_VARIANTS_SELECT = 'view_quantity_variants_select',
  CANCEL_VARIANTS_SELECT = 'cancel_quantity_variants_select',
  VIEW_PRODUCT_MODAL = 'view_product_modal',
  VIEW_PRODUCT_PAGE = 'view_product_page',
}

enum Labels {
  CART = 'cart',
  CHECKOUT = 'checkout'
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Undo Delete Product

export function undoDeleteProduct(location: Labels) {
  push(Actions.UNDO_DELETE, location);
}

export function undoDeleteProductFromCart() {
  return undoDeleteProduct(Labels.CART);
}

export function undoDeleteProductFromCheckout() {
  return undoDeleteProduct(Labels.CHECKOUT);
}

// --- Product Quantity Variant

export function viewProductQuantityVariantsSelect(variantCode: IProduct['variantCode'], canAddMultipleType?: string) {
  push(Actions.VIEW_VARIANTS_SELECT, variantCode, {
    param1: canAddMultipleType,
  });
}

export function cancelProductQuantityVariantsSelect(variantCode: IProduct['variantCode'], canAddMultipleType?: string) {
  push(Actions.CANCEL_VARIANTS_SELECT, variantCode, {
    param1: canAddMultipleType,
  });
}

export function addProductQuantityVariant(variantCode: IProduct['variantCode'], quantity: number, canAddMultipleType?: string) {
  push(Actions.ADD_QUANTITY_VARIANT, `${variantCode}-${quantity}`, {
    param1: canAddMultipleType,
  });
}

export function selectProductQuantityVariant(variantCode: IProduct['variantCode'], quantity: number, canAddMultipleType?: string) {
  push(Actions.SELECT_QUANTITY_VARIANT, `${variantCode}-${quantity}`, {
    param1: canAddMultipleType,
  });
}

// --- View Product Modal

export function viewProductModal(user: IUser, product: IProduct) {
  const { name } = product || {};
  push(Actions.VIEW_PRODUCT_MODAL, name);
  Einstein.trackProductView(user, product);
}

// --- View Product Page

export function viewProductPage(user: IUser, product: IProduct) {
  const { name } = product || {};
  push(Actions.VIEW_PRODUCT_PAGE, name);
  Einstein.trackProductView(user, product);
}
