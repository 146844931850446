import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'user_events';

enum Actions {
  SET_CONSENT_STATUS = 'set_consent_status',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function setConsentStatus() {
  push(Actions.SET_CONSENT_STATUS);
}
