import React from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';

interface StoreProviderProps {
  children: React.ReactNode
}

export interface IStoreAction<P = any, T extends string = string> {
  payload: P,
  type: T
}

export type ExtractPayloadType<T> = T extends IStoreAction<infer P, any> ? P : never;

export type IStoreActions<P extends Record<keyof P, IStoreReducer<any, IStoreAction<any>>>> = {
  [K in keyof P]: (p: ExtractPayloadType<Parameters<P[K]>[1]>) => ReturnType<P[K]>
}

export type IStoreReducer<S = any, A extends IStoreAction = any> = (state: S | undefined, action: A) => S

// Create V1
// TODO: remove after refactoring other context providers

export function create<T>(
  reducer: IStoreReducer<T, IStoreAction>,
  initialState: T,
): [
    (props: StoreProviderProps) => JSX.Element,
    () => T,
    () => React.Dispatch<any>
  ] {

  const StoreContext = React.createContext<T>(initialState);
  const DispatchContext = React.createContext<React.Dispatch<IStoreAction>>(() => {});

  const StoreProvider = (props: StoreProviderProps) => {
    const { children } = props;
    const [store, dispatch] = React.useReducer(reducer, initialState);

    return (
      <DispatchContext.Provider value={dispatch}>
        <StoreContext.Provider value={store}>
          {children}
        </StoreContext.Provider>
      </DispatchContext.Provider>
    );
  };

  function useStore() {
    return React.useContext<T>(StoreContext);
  }

  function useDispatch(): React.Dispatch<IStoreAction> {
    return React.useContext(DispatchContext);
  }

  return [StoreProvider, useStore, useDispatch];
}

// Create V2

export function createV2<T, P>(
  reducers: Record<string, IStoreReducer<T, IStoreAction<any>>>,
  initialState: T,
): [
    (props: StoreProviderProps) => JSX.Element,
    () => T,
    () => React.Dispatch<any>,
    P,
    (selector: (s: T) => any) => any
  ] {

  // Contexts

  const StoreContext = createContext<T>(initialState);
  const DispatchContext = createContext<React.Dispatch<IStoreAction>>(() => {});

  // Reducer / Actions

  const { reducer, actions } = createReducer<T, P>(reducers);

  // Provider

  const StoreProvider = (props: StoreProviderProps) => {
    const { children } = props;

    const [store, dispatch] = React.useReducer(reducer, initialState);

    return (
      <DispatchContext.Provider value={dispatch}>
        <StoreContext.Provider value={store}>
          {children}
        </StoreContext.Provider>
      </DispatchContext.Provider>
    );
  };

  // Hooks

  function useStore() {
    return useContext<T>(StoreContext);
  }

  function useDispatch(): React.Dispatch<IStoreAction> {
    return useContext(DispatchContext);
  }

  function useSelector(selector: (s: T) => any) {
    return useContextSelector(StoreContext, selector);
  }

  // Return

  return [
    StoreProvider,
    useStore,
    useDispatch,
    actions,
    useSelector
  ];
}

// Helpers

function createReducer<T, P>(
  reducers: Record<string, IStoreReducer<T, IStoreAction<any>>>
): {
    reducer: IStoreReducer<T, IStoreAction<any>>,
    actions: P
  } {

  // Reducer

  const reducer = (draft: T, action: IStoreAction<any>) => {
    const { type } = action;
    return reducers[type]
      ? reducers[type](draft, action)
      : draft;
  };

  // Actions

  const actions = Object.keys(reducers).reduce((acc: P, key: keyof typeof reducers) => {
    acc[key] = (payload: any) => ({ type: key, payload });
    return acc;
  }, {} as P);

  // Return

  return {
    reducer,
    actions,
  };
}
