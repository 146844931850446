import React from 'react';
import { useRouter } from 'next/router';

import Analytics from 'common/analytics';
import { isInArray } from 'helpers/ArrayHelpers';
import { getUserDetails } from 'helpers/AuthenticationHelpers';
import { safeCall } from 'helpers/utils';

type FunctionKeys<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never
}[keyof T];

type WithQueueMethods<T> = {
  [P in FunctionKeys<T>]: T[P] extends (...args: any[]) => any ? (...args: Parameters<T[P]>) => void : never;
};

const dispatchContext = React.createContext<WithQueueMethods<typeof Analytics> | null>(null);

interface AnalyticsEventHandler {
  fn: (...params: any) => void,
  args: any
}

const DELAYED_EVENTS = [
  'applyFilter',
  'removeFilter',
  'clearFilter',
  'clearAllFilters',
  'applyOrderBy',
];

interface Props {
  children?: React.ReactNode
}

const AnalyticsProvider = (props: Props) => {

  const { children } = props;

  // Refs

  const eventsQueueRef = React.useRef<AnalyticsEventHandler[]>([]);
  const isNavigationLoadingRef = React.useRef(false);

  // Hooks

  const router = useRouter();

  // Helpers

  const withQueue = React.useCallback((
    fn: AnalyticsEventHandler['fn'],
    args: any,
    shouldTriggerAfterNavigationComplete?: boolean
  ) => {
    if (isNavigationLoadingRef.current || shouldTriggerAfterNavigationComplete) {
      eventsQueueRef.current.push({ fn, args });
    } else {
      safeCall(fn, args);
    }
  }, []);

  const registerQueuedEvents = React.useCallback(() => {
    const events = eventsQueueRef.current;
    if (events?.length > 0) {
      events.forEach(({ fn, args }) => safeCall(fn, args));
      eventsQueueRef.current = [];
    }
  }, []);

  // Dispatch

  const dispatch = React.useMemo(() => {
    const wrappedMethods: WithQueueMethods<typeof Analytics> = {} as any;

    (Object.keys(Analytics) as Array<FunctionKeys<typeof Analytics>>).forEach((key) => {
      const handler = Analytics[key];
      if (typeof handler === 'function') {
        const delayed = isInArray(DELAYED_EVENTS, key);
        wrappedMethods[key] = (...args: Parameters<typeof handler>) => withQueue(handler, args, delayed);
      }
    });

    return wrappedMethods;
  }, [withQueue]);

  // Props

  const userDetails = getUserDetails();
  const { customerId, accountType } = userDetails || {};

  // Effects

  React.useEffect(() => {
    Analytics.setUserId(customerId || null);
  }, [customerId]);

  React.useEffect(() => {
    Analytics.setAccountType(accountType || null);
  }, [accountType]);

  React.useEffect(() => {
    isNavigationLoadingRef.current = false;
    Analytics.pageView(window.location.pathname);
    registerQueuedEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const navigationStart = () => {
      isNavigationLoadingRef.current = true;
    };

    const navigationError = () => {
      isNavigationLoadingRef.current = false;
    };

    const navigationComplete = (url: string) => {
      isNavigationLoadingRef.current = false;
      Analytics.pageView(url);
      registerQueuedEvents();
    };

    router.events.on('routeChangeStart', navigationStart);
    router.events.on('routeChangeComplete', navigationComplete);
    router.events.on('routeChangeError', navigationError);

    return () => {
      router.events.off('routeChangeStart', navigationStart);
      router.events.off('routeChangeComplete', navigationComplete);
      router.events.off('routeChangeError', navigationError);
      if (eventsQueueRef.current) {
        eventsQueueRef.current = [];
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  // Render

  return (
    <dispatchContext.Provider value={dispatch}>
      {children}
    </dispatchContext.Provider>
  );
};

function useAnalyticsDispatch() {
  const context = React.useContext(dispatchContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsProvider');
  }

  return context;
}

export {
  AnalyticsProvider,
  useAnalyticsDispatch
};
