import { Api, SecureApi, WebApi } from 'common/http';

import type { IConfig, IUserConfig } from 'types';

export async function getConfigFromWebApi() {
  const { data } = await WebApi.get<IConfig>('/config');
  return data;
}

export async function getConfig() {
  const { data } = await Api.get<IConfig>('/text-mappings/RO_ro');
  return data;
}

export async function getUserConfig() {
  const { data } = await SecureApi.get<IUserConfig>('/user-config/ro_RO');
  return data;
}
