/* eslint-disable react/no-danger */
import React from 'react';
import classnames from 'classnames';

import { Modal, ModalBody, ModalHeader } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import Button from '../Button/Button';
import Image from '../Image/Image';

import type { Variant } from '../Button/Button';

import type { IImageSrc } from 'types';

import styles from './CookieBanner.module.scss';

export const ALIGNMENTS = {
  START: 'start',
  CENTER: 'center',
  END: 'end'
};

interface Action {
  className?: string,
  //
  id: string,
  text: string,
  href?: string,
  variant?: Variant,
  //
  isPrimary?: boolean,
  //
  dataTestId?: string
}

interface Props {
  className?: string,
  isOpen: boolean,
  buttonClassName?: string,
  buttonsContainerClassName?: string,
  //
  title: string,
  description: string,
  image?: IImageSrc,
  imageProps?: React.ComponentProps<typeof Image>,
  actions: Action[],
  //
  isLoading?: boolean,
  //
  onAction: (id: Action['id']) => void,
  onClose: () => void,
  //
  dataTestId?: string
}

const CookieBanner = (props: Props) => {
  const {
    className,
    isOpen,
    buttonClassName,
    buttonsContainerClassName,
    //
    title,
    description,
    image,
    imageProps,
    actions = [],
    //
    isLoading,
    //
    onAction,
    onClose,
    //
    dataTestId
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <Modal
      isOpen={isOpen}
      close={onClose}
      className={classnames(styles.root, className)}
      contentClassName={styles.contentWrapper}
      innerClassName={styles.innerWrapper}
      data-testid={dataTestId}
      withCloseButton={false}
      responsiveProps={{ position: 'center' }}
    >

      {/* Header */}
      <ModalHeader
        className={styles.header}
        titleClassName={styles.title}
        withPadding={false}
        title={title}
        withCloseButton={false}
      />

      {/* Image */}
      {
        image && (
          <div className={styles.imageWrapper}>
            <Image
              src={image}
              optimize
              {...imageProps}
            />
          </div>
        )
      }

      {/* Content */}
      <ModalBody className={classnames(
        styles.content,
      )}
      >

        {/* Description */}
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {/* Actions */}
        <div className={classnames(styles.buttonsContainer, buttonsContainerClassName)}>
          {
            actions.map(({
              className: actionButtonClassName,
              id,
              text,
              href,
              variant,
              isPrimary,
              dataTestId: buttonDataTestId
            }) => {
              return (
                <ActionButton
                  key={id}
                  className={classnames(buttonClassName, actionButtonClassName)}
                  href={href}
                  variant={variant}
                  loading={isPrimary && isLoading}
                  disabled={isLoading}
                  onClick={() => onAction(id)}
                  dataTestId={buttonDataTestId}
                >
                  {t(text)}
                </ActionButton>
              );
            })
          }
        </div>
      </ModalBody>
    </Modal>
  );
};

// Components

// --- Action Button

interface ActionButtonProps {
  className?: string,
  //
  href: string,
  variant?: Variant,
  loading: boolean,
  disabled: boolean,
  //
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
  //
  children: React.ReactNode,
  //
  dataTestId?: string
}

const ActionButton = (props: ActionButtonProps) => {
  const {
    className,
    //
    href,
    variant = 'secondary',
    loading,
    disabled,
    //
    onClick,
    //
    children,
    //
    dataTestId
  } = props;

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const classes = classnames(
    styles.btn,
    className
  );

  if (href) {
    return (
      <a
        className={styles.link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          className={classes}
          size={isBreakpoint ? 'medium' : 'large'}
          variant={variant}
          loading={loading}
          disabled={disabled}
          onClick={onClick}
          data-testid={dataTestId}
        >
          {children}
        </Button>
      </a>
    );
  }

  return (
    <Button
      className={classes}
      size={isBreakpoint ? 'medium' : 'large'}
      variant={variant}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </Button>
  );
};

// Export

export default CookieBanner;
