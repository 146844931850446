/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Button, Label, Radio, Rating, Textarea
} from 'components/ui';
import { useOrderFeedbackMutation } from 'hooks/data/useOrderFeedbackMutation';

import styles from './OrderFeedbackLowRatingForm.module.scss';

const MAX_TEXTAREA_LENGTH = 4000;

// Initial values

const initialValues = {
  deliveryRating: null,
  deliveryComment: '',
  accuracyRating: null,
  accuracyComment: '',
  willReturn: null,
  comment: '',
  contactConsent: false,
};

const OrderFeedbackLowRatingForm = (props) => {

  const {
    className,
    formRef,
    orderToken,
    rating,
    driver,
    onLoading = () => {},
    onSuccess = () => {},
    onError = () => {},
  } = props;

  const { t } = useTranslation();
  const { isLoading: isMutationLoading, sendFeedback } = useOrderFeedbackMutation();

  // Validation

  const validationSchema = React.useMemo(() => yup.object().shape({
    deliveryComment: yup
      .string()
      .max(MAX_TEXTAREA_LENGTH, t('VALIDATION_ERRORS.MAX_LENGTH', { count: MAX_TEXTAREA_LENGTH })),
    accuracyComment: yup
      .string()
      .max(MAX_TEXTAREA_LENGTH, t('VALIDATION_ERRORS.MAX_LENGTH', { count: MAX_TEXTAREA_LENGTH })),
    comment: yup
      .string()
      .required(t('VALIDATION_ERRORS.REQUIRED'))
      .min(10, t('VALIDATION_ERRORS.MIN_LENGTH', { count: 10 }))
      .max(MAX_TEXTAREA_LENGTH, t('VALIDATION_ERRORS.MAX_LENGTH', { count: MAX_TEXTAREA_LENGTH })),
    willReturn: yup.boolean().required(t('VALIDATION_ERRORS.REQUIRED'))
  }), [t]);

  // Effects

  React.useEffect(() => {
    onLoading(isMutationLoading);
  }, [isMutationLoading]);

  // Handlers

  const handleSubmit = (fields) => {
    sendFeedback({
      tokenValue: orderToken,
      rating,
      fields,
      onSuccess,
      onError
    });
  };

  // Empty

  if (!orderToken) return null;

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const {
          values, setFieldValue, errors, isSubmitting
        } = formikProps;
        return (
          <Form className={classnames(styles.form, className)}>

            {/* Delivery - Rating */}
            <div className={styles.formGroup}>
              <Rating
                labelClassName={styles.label}
                label={(
                  <span
                    className={styles.labelText}
                    dangerouslySetInnerHTML={{ __html: t('ORDER_FEEDBACK_LOW_RATING_FORM.DELIVERY_RATING_LABEL', { driver: driver?.name }) }}
                  />
                )}
                symbol="star"
                value={values.deliveryRating}
                disabled={isMutationLoading || isSubmitting}
                onChange={(updatedRating) => setFieldValue('deliveryRating', updatedRating)}
              />
            </div>

            {/* Accuracy - Rating */}
            <div className={styles.formGroup}>
              <Rating
                label={t('ORDER_FEEDBACK_LOW_RATING_FORM.ACCURACY_RATING_LABEL')}
                labelClassName={styles.label}
                symbol="star"
                value={values.accuracyRating}
                disabled={isMutationLoading || isSubmitting}
                onChange={(r) => setFieldValue('accuracyRating', r)}
              />
            </div>

            {/* Accuracy - Comment */}
            <div className={classnames(styles.formGroup, styles.textareaGroup)}>
              <Textarea
                className={styles.textarea}
                labelClassName={styles.label}
                name="accuracyComment"
                label={t('ORDER_FEEDBACK_LOW_RATING_FORM.ACCURACY_COMMENT_LABEL')}
                placeholder={t('ORDER_FEEDBACK_LOW_RATING_FORM.ACCURACY_COMMENT_PLACEHOLDER')}
                disabled={isMutationLoading || isSubmitting}
                minRows={3}
                maxRows={12}
              />
            </div>

            {/* Comment / Contact Consent */}
            <div className={classnames(styles.formGroup, styles.highlightGroup)}>
              <Textarea
                className={styles.textarea}
                labelClassName={styles.label}
                name="comment"
                labelRequired
                label={t('ORDER_FEEDBACK_LOW_RATING_FORM.COMMENT_LABEL')}
                placeholder={t('ORDER_FEEDBACK_LOW_RATING_FORM.COMMENT_PLACEHOLDER')}
                disabled={isMutationLoading || isSubmitting}
                minRows={3}
                maxRows={12}
              />
              <div className={styles.info}>
                <div dangerouslySetInnerHTML={{ __html: t('ORDER_FEEDBACK_TESTIMONIAL_FORM.TESTIMONIAL_INFO') }} />
              </div>
            </div>

            {/* Will Return */}
            <div className={classnames(styles.formGroup, styles.highlightGroup)}>
              <Label
                htmlFor="willReturn"
                label={t('ORDER_FEEDBACK_LOW_RATING_FORM.WILL_RETURN_LABEL')}
                hasError={!!errors.willReturn}
                className={classnames(styles.label)}
                required
              />
              <div className={styles.radioGroup}>
                <Radio
                  className={styles.radio}
                  labelClassName={styles.radioLabel}
                  name="willReturn"
                  label={t('ACTIONS.YES')}
                  disabled={isMutationLoading || isSubmitting}
                  checked={values.willReturn === true}
                  onChange={() => setFieldValue('willReturn', true)}
                />
                <Radio
                  className={styles.radio}
                  labelClassName={styles.radioLabel}
                  name="willReturn"
                  disabled={isMutationLoading || isSubmitting}
                  label={t('ACTIONS.NO')}
                  onChange={() => setFieldValue('willReturn', false)}
                  checked={values.willReturn === false}
                />
              </div>
            </div>

            {/* Actions */}
            <div className={styles.actions}>
              <Button
                type="submit"
                className={styles.submitBtn}
                variant="primary"
                loading={isMutationLoading || isSubmitting}
                disabled={(isMutationLoading || !(formikProps.dirty && formikProps.isValid)) || isSubmitting}
              >
                {t('ORDER_FEEDBACK_LOW_RATING_FORM.ACTIONS.SUBMIT')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

OrderFeedbackLowRatingForm.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  orderToken: PropTypes.string,
  rating: PropTypes.number,
  driver: PropTypes.shape({}),
  onLoading: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default OrderFeedbackLowRatingForm;
