import React from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import { MDXRemote } from 'next-mdx-remote';
import classnames from 'classnames';

import BlogBlockContainer from '../BlogBlockContainer/BlogBlockContainer';

import styles from './BlogTextBlock.module.scss';

interface Props {
  title: string,
  content?: MDXRemoteSerializeResult,
  className?: string,
  containerClassName?: string,
  innerContainerClassName?: string
}

const BlogTextBlock = (props: Props) => {

  const {
    title, content,
    containerClassName, innerContainerClassName, className
  } = props;

  return (
    <BlogBlockContainer
      className={classnames(styles.container, containerClassName)}
      innerClassName={classnames(styles.innerContainer, innerContainerClassName)}
    >
      <div className={classnames(styles.root, className)}>
        <h2 className={styles.title}>
          {title}
        </h2>
        {
          content && (
            <div className={styles.content}>
              <MDXRemote {...content} />
            </div>
          )
        }
      </div>
    </BlogBlockContainer>
  );
};

export default BlogTextBlock;
