import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import type {
  IStrapiImage,
  IStrapiSeoImage,
  IStrapiVideo,
} from './generic';

import type { IRecipeCard } from './recipe-card';

import type {
  IAnalyticsListProps,
  IProductDetails, InfoBannerType, Nullable, ValueOf
} from 'types';

// Recipe

export interface IRecipe {
  //
  meta: IRecipeMeta,
  analyticsListProps: IAnalyticsListProps,
  details: IRecipeDetails,
  //
  label: IRecipeLabel,
  media: IRecipeMedia,
  stats: IRecipeStats,
  ingredients: IRecipeIngredients,
  disclaimer?: string,
  instructions: IRecipeInstructions,
  tips: IRecipeTips,
  banner?: IRecipeBanner,
  //
  tags: IRecipeTags,
  related: IRecipeCard[],
  //
  seo: IRecipeSeo,
  //
  __meta: any,
  __data: any
}

// --- Meta

export interface IRecipeMeta {
  id: number,
  slug: string
}

// --- Details

export interface IRecipeDetails {
  title: string,
  description: MDXRemoteSerializeResult,
  date: string
}

// --- Media

export interface IRecipeMedia {
  banner: {
    desktop: IStrapiImage,
    mobile: IStrapiImage
  },
  video?: IStrapiVideo
}

// --- Image Size

export const RECIPE_IMAGE_SIZES = {
  DEFAULT: 'default',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  THUMBNAIL: 'thumbnail'
} as const;

export type RecipeImageSize = ValueOf<typeof RECIPE_IMAGE_SIZES>

// --- Label

export interface IRecipeLabel {
  text: string,
  textColor: string,
  backgroundColor: string
}

// --- Stats

export const RECIPE_STATS = {
  CALORIES: 'calories',
  PREPARATION_TIME: 'preparationTime',
  COOKING_TIME: 'cookingTime',
  TOTAL_TIME: 'totalTime',
  SERVINGS: 'servings',
  PRICE_PER_SERVING: 'pricePerServing',
  ORIGINAL_PRICE_PER_SERVING: 'originalPricePerServing',
} as const;

export type RecipeStat = ValueOf<typeof RECIPE_STATS>

export interface IRecipeStat<T extends RecipeStat, K> {
  key: T,
  value: K,
  originalValue?: K
}

export interface IRecipeStats {
  calories: IRecipeStat<'calories', number>,
  preparationTime: IRecipeStat<'preparationTime', number>,
  cookingTime: IRecipeStat<'cookingTime', number>,
  totalTime: IRecipeStat<'totalTime', number>,
  servings: IRecipeStat<'servings', number>,
  pricePerServing: IRecipeStat<'pricePerServing', number>,
  originalPricePerServing: IRecipeStat<'originalPricePerServing', number>
}

// --- Ingredients

export interface IRecipeIngredients {
  primary: IRecipePrimaryIngredients,
  secondary: IRecipeSecondaryIngredients,
  extra: IRecipeExtraIngredients,
  text: IRecipeTextIngredients
}

// ------ Primary

export type IRecipePrimaryIngredients = IRecipePrimaryIngredient[];

export interface IRecipePrimaryIngredient {
  id: string,
  quantity: number,
  sku: string,
  backups?: IRecipePrimaryIngredients
}

// ------ Secondary / Extra

export interface IRecipeIngredientsListing {
  slug: string,
  title: string
}

export interface IRecipeSecondaryIngredients extends IRecipeIngredientsListing {}

export interface IRecipeExtraIngredients extends IRecipeIngredientsListing {}

// ------ Ingredient Products

export interface IRecipeIngredientProduct {
  id: string,
  sku: string,
  ingredient: IRecipePrimaryIngredient,
  product: IProductDetails
}

// ------ Text

export type IRecipeTextIngredients = IRecipeTextIngredient[]

export interface IRecipeTextIngredient {
  text: string
}

// --- Instructions

export interface IRecipeInstructions {
  title: string,
  steps: string[]
}

// --- Tips

export interface IRecipeTips {
  text: string
}

// --- Tags

export interface IRecipeTag {
  id: number,
  slug: string,
  label: string
}

export type IRecipeTags = IRecipeTag[];

// --- Seo

export interface IRecipeSeo {
  title: string,
  description: string,
  images: IStrapiSeoImage[]
}

export interface IRecipeBanner {
  text: string,
  tooltip?: Nullable<{
    title: string,
    value: string
  }>,
  icon?: string,
  textColor: string,
  bgColor: string,
  link: string,
  type: InfoBannerType,
  analyticsEventType: string,
  analyticsEventPayload: {
    promotion_id: string,
    promotion_name: string
  }
}

export interface IRecipeBreakpointParams {
  slidesPerView?: number,
  slidesPerGroup?: number,
  spaceBetween?: number
}
