import React from 'react';
import classnames from 'classnames';

import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './Card.module.scss';

export const ELEVATION_LEVELS = [0, 1, 2, 3] as const;

type Elevation = typeof ELEVATION_LEVELS[number];

interface Props {
  tag?: 'div' | 'a' | 'button',
  //
  className?: string,
  //
  size?: Exclude<Size, typeof Sizes.SMALL>,
  elevation?: Elevation,
  withPadding?: boolean,
  withBorder?: boolean,
  //
  onClick?: () => void,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  //
  children?: React.ReactNode | React.ReactElement | React.ReactElement[]
}

type Ref = (
  | HTMLDivElement
  | HTMLAnchorElement
);

const Card = React.forwardRef<Ref, Props>((
  props: Props,
  ref: React.Ref<Ref>
) => {

  const {
    tag: Tag = 'div',
    //
    className,
    //
    size = Sizes.MEDIUM,
    elevation = 2,
    withPadding,
    withBorder = true,
    //
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    //
    children
  } = props;

  const classes = classnames(
    styles.root,
    { [styles[`elevation-${elevation}`]]: elevation },
    { [styles.padded]: withPadding },
    { [styles.bordered]: withBorder },
    { [styles[size]]: size },
    className
  );

  if (Tag === 'a') {
    return (
      <a
        ref={ref as React.RefObject<HTMLAnchorElement>}
        className={classes}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    );
  }

  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className={classes}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
});

export default Card;
