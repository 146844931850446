export const IMAGES = {
  ABOUT: {
    HEADER: '/images/about/header.png',
    SECTION_1: '/images/about/section-1.jpg',
    SECTION_2: '/images/about/section-2.png',
    SECTION_3: '/images/about/section-3.jpg',
    SECTION_4: '/images/about/section-4.jpg',
    SECTION_5: '/images/about/section-5.jpg',
    SECTION_6: '/images/about/section-6.jpg',
  },
  ADDRESS: {
    DELIVERY: '/images/address/address_search.png',
    DELIVERY_INTERVALS: '/images/address/delivery_intervals.png',
    MAP_MARKER: '/images/address/map_marker.png',
    VALID_DELIVERY_ADDRESS: '/images/address/valid_delivery_address.png',
    INVALID_DELIVERY_ADDRESS: '/images/address/invalid_delivery_address.png',
    NO_RESULTS: '/images/address/no_results.svg'
  },
  ACCOUNT: {
    NO_ADDRESSES: '/images/account/no_addresses.png',
    NO_BILLINGS: '/images/account/no_billing.png',
    NO_ORDERS: '/images/account/no_orders.png',
  },
  ANPC: {
    SAL: '/images/anpc/SAL.svg',
    SOL: '/images/anpc/SOL.svg',
  },
  BRANDING: {
    LOGO: '/images/branding/logo.svg',
    LOGO_MOBILE: '/images/branding/logo_mobile.svg',
    GENIUS_LOGO: '/images/branding/genius_logo.svg',
    GENIUS_LOGO_HD: '/images/branding/genius_logo_hd.png',
    GENIUS_ICON: '/images/branding/genius_icon.svg',
    GENIUS_ICON_WHITE: '/images/branding/genius_icon_white.svg',
    GENIUS_DEALS_LOGO: '/images/branding/genius_deals_logoV2.svg',
    GENIUS_DISCOUNT: '/images/branding/genius_discount.svg',
    EMAG_GRADIENT: '/images/branding/emag_logo_gradient.svg',
    MOBILE_APP_ICON: '/images/branding/mobile_app_icon.svg',
    GENIUS_BRANDS_VERTICAL: '/images/branding/genius_brands_vertical.png',
    GENIUS_BRANDS_HORIZONTAL: '/images/branding/genius_brands_horizontal.png',
  },
  CART: {
    EMPTY: '/images/cart/empty.svg',
    EMPTY_SECONDARY: '/images/cart/empty-secondary.svg',
  },
  CAMPAIGNS: {
    UPGREAT: {
      INTRO: '/images/campaigns/upgreat/card_intro.png',
      ORDER_1: '/images/campaigns/upgreat/card_order_1.png',
      ORDER_2: '/images/campaigns/upgreat/card_order_2.png',
      ORDER_3: '/images/campaigns/upgreat/card_order_3.png',
      ORDER_4: '/images/campaigns/upgreat/card_order_4.png',
      ORDER_5: '/images/campaigns/upgreat/card_order_5.png',
    }
  },
  CHECKOUT: {
    NO_PRODUCTS: '/images/checkout/no_products.png',
    PAYMENT_ONLINE: '/images/checkout/payment_online.png',
    PAYMENT_DELIVERY: '/images/checkout/payment_delivery.png',
    RECYCLE_ICON: '/images/checkout/recycle_icon.svg',
    SOCIAL_ICON: '/images/checkout/social_icon.svg'
  },
  HOMEPAGE: {
    ICON_PROMO: '/images/homepage/icon-promo.svg',
    ICON_NEW_PRODUCTS: '/images/homepage/icon-new-products.svg',
    ICON_SAVE_ME: '/images/homepage/icon-save-me.svg'
  },
  COMMON: {
    SUCCESS: '/images/common/success.svg',
    SUCCESS_CUP: '/images/common/success-cup.svg',
    ERROR: '/images/common/error.svg'
  },
  ERRORS: {
    GENERIC: '/images/errors/500.png',
    NOT_FOUND: '/images/errors/404.png'
  },
  FAVORITES: {
    EMPTY: '/images/favorites/empty.png'
  },
  FRESHCLUB: {
    EMPTY: '/images/freshclub/empty.png'
  },
  GENIUS: {
    BANNER_DESKTOP: '/images/genius/banner_genius.jpg',
    BANNER_RESPONSIVE: '/images/genius/banner_genius_responsive.jpg',
    CALENDAR: '/images/genius/calendar.svg',
    ICON_DELIVERY_TRUCK: '/images/genius/icon_truck.svg',
    ICON_PRICE_TAG: '/images/genius/icon_price_tag.svg',
    DELIVERY_VAN: '/images/genius/delivery_van.png',
    BAG: '/images/genius/shopping_bag.png',
    ORDER_VALUE: '/images/genius/order_value.svg',
    TOAST_BANNER: '/images/genius/banner_genius.svg',
  },
  MOBILE_APP: {
    MOCK: '/images/misc/mobile_app_mockup.png',
    ANDROID: '/images/misc/android_store.png',
    IOS: '/images/misc/ios_store.png'
  },
  MENU: {
    BACKGROUND: '/images/menu/background.jpg'
  },
  MISC: {
    BULB: '/images/misc/bulb.svg',
    OUTDATED_BROWSER: '/images/misc/outdated_browser.png',
    CONTACT: '/images/misc/contact.png',
    CHRISTMAS_SCHEDULE: '/images/misc/christmas_schedule.png',
    INTERVALS_HOLIDAY_EMPTY: '/images/misc/intervals_holiday_empty.svg',
    BADGE_SEPARATOR: '/images/misc/badge_separator.svg',
  },
  NEWSLETTER: {
    UNSUBSCRIBE: '/images/newsletter/unsubscribe.png',
    SUBSCRIBE: '/images/newsletter/subscribe_modal.png'
  },
  MY_FRESHLIST: {
    EMPTY: '/images/my-freshlist/empty.png'
  },
  ORDER_FEEDBACK: {
    SUBMITTED: '/images/order-feedback/submitted.png'
  },
  PRODUCT: {
    SIMILAR_PRODUCTS_EMPTY: '/images/product/similar_products_empty.png'
  },
  PROMOTIONS: {
    INCREDIBLE_DISCOUNTS_PRICE_ICON: '/images/promotions/incredible-discounts-price-icon.png'
  },
  PLACEHOLDERS: {
    GENERIC: '/images/placeholders/generic.svg',
    NO_PRODUCTS: '/images/placeholders/no_products.png',
    USER: '/images/placeholders/user.png'
  },
  RECIPES: {
    NO_RECIPES: '/images/recipes/no_recipes.png',
  },
  SEARCH: {
    NO_RESULTS: '/images/search/no_results.png'
  },
  SEASONAL: {
    SNOWFLAKE: '/images/seasonal/snowflake.png',
    SLEIGH: '/images/seasonal/sleigh.png',
    REINDEER: '/images/seasonal/reindeer.png',
    SANTA_HAT: '/images/seasonal/santa-hat.png',
    EASTER_BASKET: '/images/seasonal/easter_basket.png',
    CHRISTMAS_BAG: '/images/seasonal/christmas_bag.png',
    EASTER_BUNNY: '/images/seasonal/easter_bunny.png',
    EASTER_BUNNIES_CHECKOUT: '/images/seasonal/easter_bunnies.png',
    CHRISTMAS_TREE_CHECKOUT: '/images/seasonal/christmas_tree.png'
  },
  VOUCHERS: {
    NO_VOUCHERS: '/images/vouchers/no-vouchers.png',
    NO_PROMOTIONS: '/images/vouchers/no-promotions.png'
  }
} as const;
