import * as yup from 'yup';

import * as FormHelpers from 'helpers/FormHelpers';

const {
  NAME_REGEX, PHONE_NUMBER_REGEX, STREET_DETAILS_REGEX, STREET_NUMBER_REGEX
} = FormHelpers;

export const MAX_DRAG_MAP_DISTANCE = 500;

export const FIELDS = {
  IS_PLACE_VALID: 'isPlaceValid',
  PLACE_ID: 'placeId',
  STREET_DETAILS: 'streetDetails',
  STREET_ADDRESS: 'streetAddress',
  STREET: 'street',
  NUMBER: 'number',
  CITY: 'city',
  PROVINCE: 'province',
  CONTACT_NAME: 'contactName',
  CONTACT_PHONE: 'contactPhoneNumber',
  GEOLOCATION: 'geolocation',
  DRAG_CENTER_GEOLOCATION: 'dragCenterGeolocation'
};

export const INITIAL_VALUES = {
  isPlaceValid: null,
  placeId: null,
  streetDetails: '',
  street: '',
  number: '',
  city: '',
  province: '',
  contactName: '',
  contactPhoneNumber: '',
  geolocation: null,
};

export const VALIDATIONS = {
  [FIELDS.IS_PLACE_VALID]: ({ t }) => yup
    .string()
    .required(t('VALIDATION_ERRORS.REQUIRED'))
    .max(40, t('VALIDATION_ERRORS.MAX_LENGTH', { count: 40 }))
    .matches(NAME_REGEX, t('VALIDATION_ERRORS.SINGLE_TYPE_ONLY', { type: t('TYPES.LETTERS') })),
  [FIELDS.STREET_DETAILS]: ({ t }) => yup
    .string()
    .matches(STREET_DETAILS_REGEX, t('VALIDATION_ERRORS.INVALID_CHARACTERS')),
  [FIELDS.STREET]: ({ t }) => yup
    .string()
    .required(t('VALIDATION_ERRORS.REQUIRED')),
  [FIELDS.NUMBER]: ({ t, isInvalid }) => yup
    .string()
    .max(9, t('VALIDATION_ERRORS.MAX_LENGTH', { count: 9 }))
    .required(t('VALIDATION_ERRORS.REQUIRED'))
    .matches(STREET_NUMBER_REGEX, t('VALIDATION_ERRORS.INVALID_STREET_NUMBER'))
    .test('Is not 0', t('VALIDATION_ERRORS.INVALID_STREET_NUMBER'), (value) => !value?.startsWith('0'))
    .test('Is valid', t('VALIDATION_ERRORS.INVALID_STREET_NUMBER'), () => !isInvalid),
  [FIELDS.CONTACT_NAME]: ({ t }) => yup
    .string()
    .required(t('VALIDATION_ERRORS.REQUIRED'))
    .max(40, t('VALIDATION_ERRORS.MAX_LENGTH', { count: 40 }))
    .matches(NAME_REGEX, t('VALIDATION_ERRORS.SINGLE_TYPE_ONLY', { type: t('TYPES.LETTERS') })),
  [FIELDS.CONTACT_PHONE]: ({ t }) => yup
    .string()
    .required(t('VALIDATION_ERRORS.REQUIRED'))
    .matches(PHONE_NUMBER_REGEX, t('VALIDATION_ERRORS.INVALID_PHONE'))
};
