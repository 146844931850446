/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { UrlParams } from 'common/url-params';
import { APP_ROUTES } from 'constants/routes';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderByDesktop from './OrderByDesktop';
import OrderByMobile from './OrderByMobile';

import type {
  IListingOrderBy, IListingOrderByOption, IUrl, PageType
} from 'types';

import styles from './OrderBy.module.scss';

interface Props {
  className?: string,
  //
  orderBy: { orderBy: IListingOrderBy | undefined },
  withLabel?: boolean,
  pageType: PageType
}

const OrderBy = (props: Props) => {

  const {
    className,
    //
    orderBy: { orderBy } = {},
    withLabel = true,
    pageType
  } = props;

  // Hooks

  const router = useRouter();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Empty

  if (!orderBy || !Array.isArray(orderBy)) return <div />;

  // Props

  const selectedLabel = orderBy?.find((option) => option?.isSelected)?.name;

  // Handlers

  const getHref = (option: IListingOrderByOption): IUrl => {
    try {
      const { query: { slug: segments = [] } } = router;
      const url = UrlParams.setOrderByParams(option, { segments, pageType });
      return url;

    } catch (err) {
      console.error('Failed to compute order by URL', err);
    }

    return {
      href: APP_ROUTES.HOME
    };
  };

  // Render

  return (
    isBreakpoint
      ? (
        <OrderByMobile
          className={classnames(styles.root, className)}
          label={selectedLabel}
          orderBy={orderBy}
          getHref={getHref}
        />
      ) : (
        <OrderByDesktop
          className={classnames(styles.root, className)}
          label={selectedLabel}
          withLabel={withLabel}
          orderBy={orderBy}
          getHref={getHref}
        />
      )
  );
};

export default OrderBy;
