import React from 'react';
import classnames from 'classnames';

import { Button, ButtonVariants, Icon } from 'components/ui';
import { useTranslation } from 'hooks/common/useTranslation';

import type { ButtonVariant } from 'components/ui';
import { Sizes } from 'types';
import type { Size } from 'types';

import styles from './ProductReplaceButton.module.scss';

const ICON_SIZES = {
  [Sizes.SMALL]: 16,
  [Sizes.MEDIUM]: 18,
  [Sizes.LARGE]: 20
};

interface Props {
  //
  className?: string,
  contentClassName?: string,
  iconWrapperClassName?: string,
  //
  iconSize?: number,
  iconStrokeWidth?: number,
  //
  size?: Size,
  variant?: ButtonVariant,
  //
  withText?: boolean,
  //
  disabled?: boolean,
  //
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  //
}

const ProductReplaceButton = (props: Props) => {

  const {
    className,
    contentClassName,
    iconWrapperClassName,
    //
    size = Sizes.SMALL,
    variant = ButtonVariants.SUBTLE,
    //
    iconSize = ICON_SIZES[size],
    iconStrokeWidth = 2,
    //
    withText,
    //
    disabled,
    //
    onClick
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const ButtonIcon = (
    <ReplaceIcon size={iconSize} strokeWidth={iconStrokeWidth} />
  );

  // Render

  return (
    <Button
      className={classnames(
        styles.root,
        className
      )}
      iconWrapperClassName={classnames(
        styles.iconWrapper,
        iconWrapperClassName
      )}
      contentClassName={classnames(
        styles.content,
        contentClassName,
      )}
      //
      icon={withText ? null : ButtonIcon}
      startIcon={withText ? ButtonIcon : null}
      variant={variant}
      size={size}
      iconSize={iconSize}
      iconStrokeWidth={iconStrokeWidth}
      //
      disabled={disabled}
      //
      onClick={onClick}
      //
      aria-label={t('ACTIONS.REPLACE')}
    >
      {t('ACTIONS.REPLACE')}
    </Button>
  );
};

interface ReplaceIconProps {
  size: number,
  strokeWidth: number
}

const ReplaceIcon = ({ size, strokeWidth }: ReplaceIconProps) => (
  <Icon name="replace" size={size} strokeWidth={strokeWidth} />
);

export default ProductReplaceButton;
