import classnames from 'classnames';

import { Skeleton } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './ProductDetailsSkeleton.module.scss';

interface Props {
  className?: string,
  detailsClassName?: string,
  //
  isQuickView?: boolean
}

const ProductDetailsSkeleton = (props: Props) => {

  const {
    className,
    detailsClassName,
    //
    isQuickView = true,
  } = props;

  // Hooks

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpointSm})`);

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Details */}
      <div className={classnames(styles.details, { [styles.isQuickView]: isQuickView }, detailsClassName)}>

        {/* Image */}
        <div className={styles.image}>
          <Skeleton className={styles.imageSkeleton} />
        </div>

        {/* Info */}
        <div className={styles.info}>
          <div className={styles.infoInner}>
            <div className={styles.name}>
              <Skeleton count={2} />
            </div>
            <div className={styles.tags}>
              <Skeleton count={2} />
              <Skeleton count={4} />
            </div>
            <div className={styles.price}>
              <Skeleton count={2} />
            </div>
            <div className={styles.actions}>
              <Skeleton count={2} />
            </div>
          </div>
        </div>

      </div>

      {/* Content */}
      <Skeleton
        className={styles.content}
        count={isBreakpoint ? 6 : 18}
      />

    </div>
  );
};

export default ProductDetailsSkeleton;
