import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'newsletter_events';

enum Actions {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe'
}

enum Labels {
  FROM_CHECKOUT = 'checkout',
  FROM_FOOTER = 'footer',
  FROM_BOOST = 'boost',
  FROM_ACCOUNT = 'account',
}

const push = getPushCategoryEventHandler<Actions, Labels>(CATEGORY);

// Events

// --- Subscribe

function subscribeToNewsletter(label: Labels) {
  push(Actions.SUBSCRIBE, label);
}

export function subscribeToNewsletterFromFooter() {
  subscribeToNewsletter(Labels.FROM_FOOTER);
}

export function subscribeToNewsletterFromCheckout() {
  subscribeToNewsletter(Labels.FROM_CHECKOUT);
}

export function subscribeToNewsletterFromBoost() {
  subscribeToNewsletter(Labels.FROM_BOOST);
}

export function subscribeToNewsletterFromAccount() {
  subscribeToNewsletter(Labels.FROM_ACCOUNT);
}

// --- Unsubscribe

function unsubscribeFromNewsletter(label: Extract<Labels, Labels.FROM_ACCOUNT>) {
  push(Actions.UNSUBSCRIBE, label);
}

export function unsubscribeFromNewsletterFromAccount() {
  unsubscribeFromNewsletter(Labels.FROM_ACCOUNT);
}
