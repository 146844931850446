import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import Modal from './Modal';

import styles from './ConfirmationModal.module.scss';

export const ConfirmationModalTypes = {
  CONFIRM: 'confirm',
  WARNING: 'warning'
} as const;

export type ConfirmationModalType = typeof ConfirmationModalTypes[keyof typeof ConfirmationModalTypes];

interface Props extends React.ComponentProps<typeof Modal> {
  isOpen: boolean,
  //
  className?: string,
  //
  variant: ConfirmationModalType,
  loading?: boolean,
  //
  title?: string,
  subtitle?: string,
  //
  children?: React.ReactNode,
  //
  cancelButtonText?: string,
  confirmButtonText?: string,
  onConfirm: () => void,
  onCancel: () => void
}

const ConfirmationModal = (props: Props) => {
  const { t } = useTranslation();

  const {
    isOpen,
    //
    className,
    //
    variant = ConfirmationModalTypes.CONFIRM,
    loading,
    //
    title,
    subtitle,
    children,
    //
    cancelButtonText = t('ACTIONS.CANCEL'),
    confirmButtonText = t('ACTIONS.CONFIRM'),
    onConfirm,
    onCancel,
    ...rest
  } = props;

  const params = React.useMemo(() => {
    return {
      iconName: ICON_NAMES[variant] || 'alert-circle',
      iconColor: ICON_COLORS[variant] || styles.confirmColor,
      confirmBtnClassName: CONFIRM_BTN_CLASS_NAMES[variant] || styles.confirmButton
    };
  }, [variant]);

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      contentClassName={classnames(styles.content, className)}
      //
      withCloseButton={false}
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'center',
      }}
      //
      {...rest}
    >

      {/* Main */}
      <div className={styles.main}>

        {/* Icon */}
        <Icon
          name={params.iconName}
          color={params.iconColor}
          strokeWidth={1}
          size={78}
        />

        {/* Title */}
        <div className={styles.title}>
          {title}
        </div>

        {/* Subtitle */}
        {
          subtitle && (
            <div className={styles.subtitle}>
              {subtitle}
            </div>
          )
        }

        {/* Children */}
        {children}
      </div>

      {/* Actions */}
      <div className={styles.actions}>

        {/* Cancel */}
        <Button
          disabled={loading}
          className={classnames(styles.btn, styles.cancelButton)}
          onClick={onCancel}
          data-testid="confirmation-modal.cancel-button"
        >
          {cancelButtonText}
        </Button>

        {/* Confirm */}
        <Button
          disabled={loading}
          loading={loading}
          loaderColor={styles.whiteColor}
          className={classnames(styles.btn, params.confirmBtnClassName)}
          onClick={onConfirm}
          data-testid="confirmation-modal.confirm-button"
        >
          {confirmButtonText}
        </Button>
      </div>

    </Modal>
  );
};

// Constants

const ICON_NAMES = {
  [ConfirmationModalTypes.CONFIRM]: 'alert-circle',
  [ConfirmationModalTypes.WARNING]: 'alert-triangle'
};

const ICON_COLORS = {
  [ConfirmationModalTypes.CONFIRM]: styles.confirmColor,
  [ConfirmationModalTypes.WARNING]: styles.warningColor
};

const CONFIRM_BTN_CLASS_NAMES = {
  [ConfirmationModalTypes.CONFIRM]: styles.confirmButton,
  [ConfirmationModalTypes.WARNING]: styles.warningConfirmButton
};

// Export

export default ConfirmationModal;
