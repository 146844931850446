import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';

import RecipeDetailsSection from '../RecipeDetailsSection/RecipeDetailsSection';

import type { IRecipeInstructions } from 'types';

import styles from './RecipeDetailsInstructions.module.scss';

interface Props {
  className?: string,
  instructions: IRecipeInstructions,
  onEnter?: (title: string) => void
}

const RecipeDetailsInstructions = (props: Props) => {

  const {
    className,
    instructions,
    onEnter,
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Props

  const {
    title,
    steps,
  } = instructions || {};

  // Empty

  if (isArrayEmpty(steps)) return null;

  // Render

  return (
    <RecipeDetailsSection
      className={classnames(styles.root, className)}
      title={title || t('recipe:RECIPE_DETAILS.COOKING_STEPS.TITLE')}
      onEnter={onEnter}
    >

      {/* Steps */}
      <div className={styles.steps}>
        {
          steps.map((step, index) => {
            return (
              <div className={styles.step} key={index}>

                {/* Index */}
                <div className={styles.index}>{index + 1}</div>

                {/* Text */}
                <div className={styles.text}>{step}</div>

              </div>
            );
          })
        }
      </div>
    </RecipeDetailsSection>
  );
};

export default RecipeDetailsInstructions;
