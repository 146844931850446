import React from 'react';

export function useSliderRef<T>() {
  const ref = React.useRef<T>(null);
  const [el, setEl] = React.useState<T>(null);

  React.useEffect(() => {
    setEl(ref.current);
  }, []);

  return { el, ref };
}

export default useSliderRef;
