import { Api, SecureApi } from 'common/http';
import { CONFIG } from 'constants/config';
import { buildURLWithQueryString } from 'helpers/UrlHelpers';

import type { IHomepageResponse } from 'types';

interface GetHomepageParams {
  page?: number,
  limit?: number,
  nextStartIndex?: number
}

// Homepage

export async function getHomepage(params?: GetHomepageParams) {
  let endpoint = '/homepage-web';

  if (params?.page || params?.limit) {
    const {
      page = 1,
      limit = CONFIG.PAGINATION.HOMEPAGE,
      nextStartIndex = 0
    } = params || {};
    endpoint = buildURLWithQueryString(endpoint, { page, limit, nextStartIndex });
  }

  const { data } = await Api.get<IHomepageResponse>(endpoint);
  return data;
}

// Personalized Homepage

export async function getPersonalizedHomepage(params?: GetHomepageParams) {
  let endpoint = '/homepage-web';

  if (params?.page || params?.limit) {
    const {
      page = 1,
      limit = CONFIG.PAGINATION.HOMEPAGE,
      nextStartIndex = 0
    } = params || {};
    endpoint = buildURLWithQueryString(endpoint, { page, limit, nextStartIndex });
  }

  const { data } = await SecureApi.get<IHomepageResponse>(endpoint);
  return data;
}

// Homepage Preview

export async function getHomepagePreview(previewId?: string) {
  const { data } = await Api.get<IHomepageResponse>(`/homepage/preview/${previewId}`);
  return data;
}

// Personalized Homepage Preview

export async function getPersonalizedHomepagePreview(previewId: string) {
  const { data } = await SecureApi.get<IHomepageResponse>(`/homepage/preview/${previewId}`);
  return data;
}
