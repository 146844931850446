import React from 'react';
import classnames from 'classnames';

import styles from './FormGroup.module.scss';

interface Props {
  className?: string,
  titleClassName?: string,
  fieldsClassName?: string,
  title?: React.ReactNode,
  withPadding?: boolean,
  withBorder?: boolean,
  inline?: boolean,
  children?: React.ReactNode
}

const FormGroup = (props: Props) => {
  const {
    className,
    titleClassName,
    fieldsClassName,
    title,
    withPadding = true,
    withBorder,
    inline,
    children
  } = props;

  const classes = classnames(
    styles.root,
    { [styles.withPadding]: withPadding },
    { [styles.withBorder]: withBorder },
    { [styles.inline]: inline },
    className
  );

  return (
    <div className={classes}>
      {/* Title */}
      {
        title && (
          <div className={classnames(styles.title, titleClassName)}>{title}</div>
        )
      }

      {/* Fields */}
      <div className={classnames(styles.fields, fieldsClassName)}>{children}</div>
    </div>
  );
};

export default FormGroup;
