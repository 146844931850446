import type { IAnalyticsListProps, INotice } from './misc';
import type { PaymentMethodType } from './payment';
import type { IOrderProduct } from './product';
import type { IUser } from './user';
import type { Nullable, ValueOf } from './utility';

// Order

export type IOrderCustomer = Pick<IUser, 'id' | 'firstName' | 'shouldShowGeniusPrice' | 'shouldVerifyPhoneNumber'> & {
  email: string
}

export interface IOrder extends IAnalyticsListProps {
  addTo?: string,
  ageCheckDone?: boolean,
  ageCheckRequired: boolean,
  billingAddressId?: number,
  canChangePaymentMethod?: boolean,
  cartNoticeV2: INotice[],
  checkoutNoticeV2: INotice[],
  couponInfo?: IOrderCouponInfo,
  currency: string,
  currencyCode: string,
  currentSelectedAddress?: IOrderAddress,
  customer?: IOrderCustomer,
  deliveryNotice?: {
    icon?: string,
    bgColor?: string,
    titleColor?: string,
    htmlDescription?: string
  },
  id: number,
  incompletePromotionsCount: number,
  invoices: IOrderInvoice[],
  isNotCreated?: boolean,
  items: IOrderProduct[],
  itemsHash: string,
  itemsTotal: number,
  itemsTotalDiscount: string,
  mergeVersion: number,
  notes?: string,
  number?: number | string,
  orderNotice?: {
    description: string,
    icon: string,
    title: string,
    type: string
  }[],
  payments: IOrderPayment[],
  reportProblemTypes?: OrderProblemType[],
  schedule?: IOrderSchedule,
  shouldRetryPayment?: boolean,
  shippingAddressId: number,
  shippingTotal: number,
  state?: string,
  summary: IOrderSummaryLine[],
  summaryItems?: IOrderProduct[],
  taxTotal?: number,
  tip?: number,
  tipOptions?: IOrderTipOption[],
  tipTotal?: number,
  tokenValue: string,
  total: number,
  verifyPhoneNumberMessageHtml?: string
}

// --- Invoice

export interface IOrderInvoice {
  url: string,
  label: string
}

// --- Schedule

export interface IOrderSchedule {
  id: number,
  isAvailable: boolean
}

// --- Payment

export interface IOrderPaymentMethodTranslation {
  id: number,
  name: string,
  description: string,
  instructions: string
}

export interface IOrderPaymentMethod extends IOrderPaymentMethodTranslation {
  checkoutEnabled: boolean,
  code: string, // "payable_on_delivery",
  translations: Record<string, IOrderPaymentMethodTranslation>,
  type: PaymentMethodType
}

export interface IOrderPayment {
  id: number,
  method?: IOrderPaymentMethod,
  state: string, // "cart",
  amount: number,
  currency: string,
  type: PaymentMethodType
}

export interface IOrderPaymentTotal {
  amount: number,
  currency: string,
  label: string
}

// --- Summary

// ------ Styles

export const OrderSummaryLineStyles = {
  REGULAR: 'regular',
  DARKER: 'darker',
} as const;

export type IOrderSummaryLineStyle = ValueOf<typeof OrderSummaryLineStyles>

// ------ Sizes

export const OrderSummaryLineSizes = {
  SMALL: 'small',
  DEFAULT: 'default',
  LARGE: 'large'
} as const;

export type IOrderSummaryLineSize = ValueOf<typeof OrderSummaryLineSizes>

// ------ Types

export const OrderSummaryLineTypes = {
  DEFAULT: 'default',
  SEPARATOR: 'separator',
  NESTED: 'nested',
  GROUPED: 'grouped'
} as const;

export type IOrderSummaryLineType = ValueOf<typeof OrderSummaryLineTypes>

// ------ Summary Line

export interface IOrderSummaryLine {
  type: IOrderSummaryLineType,
  size: IOrderSummaryLineSize,
  key?: {
    popoverContent?: string,
    text: string,
    bold: boolean,
    style: IOrderSummaryLineStyle,
    deleteKey?: string
  },
  value?: {
    text: string,
    bold: boolean,
    style: IOrderSummaryLineStyle
  },
  modal?: {
    title?: string,
    description?: string,
    summary?: Omit<IOrderSummaryLine, 'modal'>[],
    info?: string,
    buttonLabel?: string
  },
  group?: {
    summary?: IOrderSummaryLine[]
  }
}

// --- Coupon Info

export interface IOrderCouponInfo {
  code: string,
  promotionName: string,
  totalDiscount: number
}

// --- Tip Option

export interface IOrderTipOption {
  key: number,
  label: string
}

// --- Address

export interface IOrderAddress {
  contactPhonenumber: string,
  province: string,
  contactName: string,
  streetAddress: string,
  street?: string,
  number?: string,
  city: string,
  firstName: string,
  countryCode: string,
  postcode: string
}

// --- Report Problem

export const ORDER_PROBLEM_TYPES = {
  INCOMPLETE: 'incomplete',
  DELIVERY: 'delivery',
  RETURN: 'return',
  OTHER: 'other'
} as const;

export type OrderProblemType = ValueOf<typeof ORDER_PROBLEM_TYPES>

export interface IOrderProblem {
  type: OrderProblemType,
  name: string,
  itemSelectionGroups: {
    group: string,
    name: string,
    singularName: string
  }[],
  itemSelectionMultiple: boolean,
  itemSelectionTitle: string,
  secondTypeLabel: string,
  secondTypes: {
    code: string,
    name: string
  }
}

// Ongoing Order

export interface IOngoingOrder {
  details: IOngoingOrderDetails,
  eligible: boolean,
  errors: string[],
  isSelected: boolean,
  title: string,
  tokenValue: string,
  warnings: string[]
}

interface IOngoingOrderDetails {
  address: string,
  interval: string,
  number: string
}

// Cancellation Reason

export interface IOrderCancellationReason {
  reason: string
}

// Order benefits

export interface IOrderBenefits {
  genius?: {
    status: 'eligible' | 'pending' | 'nonEligible',
    title?: string,
    titleHtml?: string,
    subtitle?: string,
    subtitleHtml?: string,
    discount?: number
  }
}

// Order Feedback Reminder

export interface IOrderFeedbackReminder {
  order: Nullable<IOrder>
}

// Order Loyalty

export interface IOrderLoyalty {
  disabled: boolean,
  general: {
    unitName: string,
    unitsName: string
  },
  spend: {
    step: IOrderLoyaltyStep,
    slider: IOrderLoyaltySlider
  }
}

export interface IOrderLoyaltyStep {
  icon: string,
  title: string,
  subtitle: string,
  availableAmount: string,
  options: IOrderLoyaltyOption[],
  usedPoints: number
}

export interface IOrderLoyaltySlider {
  title: string,
  subtitle: string,
  description: string,
  //
  currentLabel: string,
  maxLabel: string,
  btnLabel: string,
  eligibleAmountLabel: string,
  //
  min: number,
  max: number,
  step: number,
  conversionRate: number
}

export const ORDER_LOYALTY_OPTION_TYPES = {
  PRESET: 'preset',
  CUSTOM: 'custom',
} as const;

export type OrderLoyaltyOptionType = ValueOf<typeof ORDER_LOYALTY_OPTION_TYPES>

export interface IOrderLoyaltyOption {
  enabled: boolean,
  type: OrderLoyaltyOptionType,
  points: number,
  label: string,
  subLabel: string
}
