import React from 'react';
import router from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { ProductsCarousel } from 'components/product';
import {
  Error, ImageMessage, Modal
} from 'components/ui';
import { IMAGES } from 'constants/images';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useRecommendations } from 'hooks/data/useRecommendations';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IOrder, RecommendationLocation, RecommendationType } from 'types';

import styles from './RecommendationsModal.module.scss';

interface Props {
  className?: string,
  //
  isOpen: boolean,
  //
  title: string,
  emptyTitle: string,
  emptySubtitle: string,
  //
  location: RecommendationLocation,
  type: RecommendationType,
  forceFallback?: boolean,
  //
  order: IOrder,
  //
  handleModalClose: () => void
}

const RecommendationsModal = (props: Props) => {

  const {
    className,
    //
    isOpen,
    //
    title,
    emptyTitle,
    emptySubtitle,
    //
    location,
    type,
    forceFallback,
    //
    order,
    //
    handleModalClose,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const { isAuthenticated } = useIsAuthenticated();

  const {
    isLoading,
    isError,
    isSuccess,
    data: recommendations,
  } = useRecommendations(
    location,
    type,
    { isAuthenticated, tokenValue: order?.tokenValue, forceFallback },
    { enabled: isOpen }
  );

  // Props

  const { slots = [] } = recommendations || {};

  // Handlers

  const handleRouteChange = React.useCallback(() => {
    if (isOpen) {
      handleModalClose();
    }
  }, [isOpen, handleModalClose]);

  // Effects

  React.useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleRouteChange]);

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      contentClassName={classnames(styles.content, className)}
      //
      title={title || t('RECOMMENDATIONS.MODAL_TITLE')}
      headerProps={{
        desktopProps: {
          size: 'large',
          withCloseButton: true,
        },
        responsiveProps: {
          size: 'medium',
          withBorder: true,
          withBackButton: true,
          withCloseButton: false,
        },
        onBack: handleModalClose,
        onClose: handleModalClose,
      }}
      //
      wide
      shouldReturnFocusAfterClose
      //
      responsiveProps={{
        isFullScreen: true,
        position: 'right'
      }}
      //
      close={handleModalClose}
    >

      {/* Error */}
      {
        isError && (
          <Error />
        )
      }

      {/* Loading */}
      {
        isLoading && (
          <div className={styles.body}>
            <ProductsCarousel
              className={classnames(styles.carouselContainer, styles.skeleton)}
              headerClassName={styles.carouselHeader}
              loading
              loadingCount={isBreakpoint ? 2 : 4}
              withLoadingHeader
              products={[]}
            />
          </div>
        )
      }

      {/* Success */}
      {
        isSuccess && (
          <div className={styles.body}>
            {
              isArrayEmpty(slots, true)
                ? (
                  <ImageMessage
                    className={styles.emptyContainer}
                    image={IMAGES.PLACEHOLDERS.NO_PRODUCTS}
                    imageProps={{
                      width: 414,
                      height: 246,
                      alt: 'No products'
                    }}
                    title={emptyTitle}
                    subtitle={emptySubtitle}
                  />
                )
                : (
                  <div className={styles.recommendations}>
                    {
                      slots?.map((slot, index) => {
                        const {
                          analyticsListId,
                          analyticsListName,
                          position,
                          ...rest
                        } = slot;
                        return (
                          <ProductsCarousel
                            className={styles.carouselContainer}
                            headerClassName={styles.carouselHeader}
                            breakpoints={CAROUSEL_BREAKPOINTS}
                            onItemClick={() => handleModalClose()}
                            onLinkClick={() => handleModalClose()}
                            productListProps={{
                              analyticsListId,
                              analyticsListName
                            }}
                            key={index}
                            {...rest}
                          />
                        );
                      })
                    }
                  </div>
                )
            }
          </div>
        )
      }
    </Modal>
  );
};

// Constants

const CAROUSEL_BREAKPOINTS = {
  1800: {
    slidesPerView: 4
  },
  1400: {
    slidesPerView: 4
  },
  600: {
    slidesPerView: 3
  }
};

// Exports

export default RecommendationsModal;
