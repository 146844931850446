import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Menu } from 'components/navigation';
import {
  Accordion, Avatar, Button, Icon, Link
} from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { useUser } from 'hooks/data/useUser';
import { useAccountMenu } from 'hooks/useAccountMenu';

import LoginPanel from '../LoginPanel';

import type { IUser } from 'types';

import styles from './AccountMenuMobile.module.scss';

const AccountMenuMobile = () => {

  const { data: user } = useUser({ isOptional: true });

  // Not authenticated
  if (!user) {
    return (
      <LoginPanel />
    );
  }

  // Authenticated
  return <AccountMenu user={user} />;

};

// Account Menu

const AccountMenu = (props: {user: IUser}) => {

  const { user } = props;

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();

  const { items } = useAccountMenu({ isDesktop: false });

  // Props

  const isAccountPage = router.asPath.startsWith(APP_ROUTES.ACCOUNT);

  // Render

  return (
    <div className={styles.root} key={router.asPath}>
      <Accordion
        className={styles.accordion}
        headerClassName={styles.accordionHeader}
        iconStrokeWidth={2}
        initiallyExpanded={isAccountPage}
        title={(
          <div className={styles.accordionTitle}>
            <Avatar
              user={user}
              className={styles.avatar}
              iconClassName={styles.avatarIcon}
              size={48}
              iconSize={24}
              withGeniusBadge
              geniusBadgeProps={{ withIcon: true }}
            />
            <div className={styles.nameContainer}>
              <div>{t('HEADER.MOBILE.WELCOME_LOGGED_IN')}</div>
              <div className={styles.name}>{user?.fullName}</div>
            </div>
          </div>
        )}
        withAnimation
      >
        <Menu
          className={styles.menu}
          itemClassName={styles.menuItem}
          linkClassName={styles.menuItemLink}
          linkIconWrapperClassName={styles.menuItemIconWrapper}
          linkIconClassName={styles.menuItemIcon}
          items={items}
          dataTestId="menu-mobile-account-navigation"
        />
        <div className={styles.logout}>
          <Link href={{ pathname: APP_ROUTES.LOGOUT }}>
            <Button
              className={styles.logoutBtn}
              tag="a"
              size="small"
              variant="subtle"
              startIcon={<Icon name="logout" size={18} />}
            >
              {t('NAVIGATION.ACCOUNT.LOGGED_IN.LOG_OUT')}
            </Button>
          </Link>
        </div>
      </Accordion>
    </div>
  );
};

export default React.memo(AccountMenuMobile);
