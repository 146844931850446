import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { ProductDetails, ProductDetailsSkeleton } from 'components/product';
import { Breadcrumbs, Error, Modal } from 'components/ui';
import * as BreadcrumbsHelpers from 'helpers/BreadcrumbsHelpers';
import { useProduct } from 'hooks/data/useProduct';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import type { IAnalyticsListProps } from 'types';

import styles from './ProductModal.module.scss';

interface ProductModalProps {
  withoutBreadcrumbs?: boolean
}

interface ProductDetailsProps {} // TODO: extend ProductDetailsProps

interface ProductListProps extends IAnalyticsListProps {}

const ProductModal = () => {

  // Refs

  const overlayRef = React.useRef<HTMLDivElement>();
  const bodyRef = React.useRef<HTMLDivElement>();

  // Hooks

  const router = useRouter();

  const { set: setAnalyticsListProps } = useRouteQueryAnalyticsListProps();

  // State Hooks

  const [modalProps, setModalProps] = React.useState<ProductModalProps>({});
  const [detailsProps, setDetailsProps] = React.useState<ProductDetailsProps>({});
  const [listProps, setListProps] = React.useState<ProductListProps>({});
  const [slug, setSlug] = React.useState(undefined);

  // Data Hooks

  const { quickView: currentSlug } = router.query;

  const {
    isSuccess,
    isLoading,
    isError,
    data: product
  } = useProduct(slug?.toString(), {
    enabled: !!slug
  });

  // Props

  const { withoutBreadcrumbs } = modalProps || {};

  // Effects

  React.useEffect(() => {
    if (currentSlug) {
      setSlug(currentSlug);
    }
  }, [currentSlug]);

  React.useEffect(() => {
    const {
      productModalProps,
      productDetailsProps,
      productListProps,
    } = router.query;

    // Product Modal Props
    if (productModalProps) {
      try {
        setModalProps(JSON.parse(productModalProps as string));
      } catch (e) {
        console.error('Failed to set product modal props - ', e);
      }
    }

    // Product Details Props
    if (productDetailsProps) {
      try {
        setDetailsProps(JSON.parse(productDetailsProps as string));
      } catch (e) {
        console.error('Failed to set product details props - ', e);
      }
    }

    // Product List Props
    if (productListProps) {
      try {
        setListProps(JSON.parse(productListProps as string));
      } catch (e) {
        console.error('Failed to set product list props - ', e);
      }
    }
  }, [router.query]);

  // Handlers

  const handleModalClose = () => {
    const {
      sm, // save me
      quickView,
      //
      originalPathname,
      //
      productModalProps,
      productDetailsProps,
      productListProps,
      //
      ...rest
    } = router.query;

    setAnalyticsListProps(listProps);

    router.push(
      { pathname: router.pathname, query: rest },
      originalPathname as string,
      { scroll: false }
    );
  };

  const onAfterModalClose = () => {
    setModalProps({});
    setDetailsProps({});
    setListProps({});
    setSlug(undefined);
  };

  // Render

  return (
    <Modal
      isOpen={!!router.query.quickView}
      overlayRef={(ref) => overlayRef.current = ref}
      bodyRef={(ref) => bodyRef.current = ref}
      //
      className={classnames(
        styles.root,
        styles.modal
      )}
      headerClassName={classnames(
        styles.header,
        { [styles.withoutBreadcrumbs]: withoutBreadcrumbs },
      )}
      titleClassName={styles.title}
      innerClassName={classnames(
        styles.inner,
        { [styles.withoutHeader]: withoutBreadcrumbs },
      )}
      //
      priority={4}
      //
      wide
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
        isFullScreen: true,
      }}
      //
      title={
        withoutBreadcrumbs
          ? undefined
          : (
            <Breadcrumbs
              className={styles.breadcrumbs}
              containerClassName={styles.breadcrumbsContainer}
              items={BreadcrumbsHelpers.getBreadcrumbsFromTrail(product?.breadcrumbs ?? [], { omitLast: true })}
              isLoading={isLoading}
              disableLast={false}
            />
          )
      }
      headerProps={{
        desktopProps: {
          sticky: withoutBreadcrumbs,
          size: 'large',
        },
        responsiveProps: {
          sticky: withoutBreadcrumbs,
          size: 'medium',
          withBorder: !withoutBreadcrumbs,
        }
      }}
      //
      close={handleModalClose}
      onAfterClose={onAfterModalClose}
    >

      <>
        {/* Error */}
        {
          isError
            ? (
              <Error />
            ) : (
              <>
                {/* Loading */}
                {
                  (isLoading || (isSuccess && !product))
                    ? (
                      <ProductDetailsSkeleton
                        className={styles.productDetailsSkeleton}
                        detailsClassName={styles.details}
                      />
                    ) : null
                }

                {/* Success */}
                {
                  (isSuccess && product)
                    ? (
                      <ProductDetails
                        scrollContainer={bodyRef?.current}
                        //
                        className={styles.productDetails}
                        detailsClassName={styles.details}
                        product={product}
                        listProps={listProps}
                        //
                        {...detailsProps}
                        //
                        onQuickViewClose={() => {
                          handleModalClose();
                        }}
                        onItemClick={() => {
                          overlayRef?.current?.scroll(0, 0);
                        }}
                      />
                    ) : null
                }
              </>
            )
        }

      </>

    </Modal>
  );
};

export default ProductModal;
