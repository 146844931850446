import React from 'react';
import { useTranslation } from 'next-i18next';

import { Button, Modal } from 'components/ui';

import FilterAccordion from './FilterAccordion';

import type {
  IListingFilter,
  IListingFilterOption, IListingFilters, IUrl
} from 'types';
import { PageType } from 'types';

import styles from './FiltersModal.module.scss';

interface Props {
  isOpen: boolean,
  //
  filters: IListingFilters,
  data?: { count?: number, query?: any },
  pageType: PageType,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl,
  clearFilter: (filter: IListingFilter) => void,
  clearFilters: () => void,
  close: () => void
}

const FiltersModal = (props: Props) => {

  const {
    isOpen,
    //
    filters,
    data,
    pageType,
    //
    getHref,
    clearFilter,
    clearFilters,
    close,
  } = props;

  const {
    countSelected,
    items
  } = filters || {};

  // Hooks

  const { t } = useTranslation();

  // Handlers

  const onClearFilters = () => {
    clearFilters();
    close();
  };

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      className={styles.root}
      contentClassName={styles.content}
      innerClassName={styles.inner}
      //
      title={t('LISTING_TOOLBAR.FILTER_BY.LABEL_SHORT')}
      //
      withPadding={false}
      //
      breakpoint="md"
      responsiveProps={{
        position: 'bottom'
      }}
      //
      close={close}
    >
      <>
        <div className={styles.list}>
          {
            items?.map((filter) => {
              const { code } = filter;
              return (
                <FilterAccordion
                  key={`filter-${code}`}
                  //
                  className={styles.accordion}
                  headerClassName={styles.accordionHeader}
                  headerTitleClassName={styles.accordionTitle}
                  headerLabelClassName={styles.accordionTitleLabel}
                  contentClassName={styles.accordionContent}
                  //
                  filter={filter}
                  optionsProps={{
                    withScrollbar: false,
                    searchClassName: styles.search,
                    searchProps: {
                      size: 'medium'
                    }
                  }}
                  withClearButton
                  //
                  getHref={getHref}
                  clearFilter={clearFilter}
                />
              );
            })
          }
        </div>

        {/* Actions */}
        <div className={styles.actions}>

          {/* Clear */}
          {
            (countSelected > 0) && (
              <Button
                variant="empty"
                className={styles.clearBtn}
                onClick={onClearFilters}
              >
                {t('LISTING_TOOLBAR.FILTER_BY.CLEAR_FILTERS')}
              </Button>
            )
          }

          {/* Apply */}
          <Button
            variant="primary"
            className={styles.viewBtn}
            onClick={close}
          >
            {t(getButtonLabel(pageType), { count: data?.count })}
          </Button>

        </div>
      </>
    </Modal>
  );
};

// Helpers

const getButtonLabel = (pageType: PageType) => {
  if (pageType === PageType.AccountFreshclub) {
    return 'LISTING_TOOLBAR.FILTER_BY.VIEW_HISTORY';
  }
  return 'LISTING_TOOLBAR.FILTER_BY.VIEW_PRODUCTS';
};

// Export

export default FiltersModal;
