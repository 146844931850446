import React from 'react';
import classnames from 'classnames';

import { Button, ButtonProps, Image } from 'components/ui';
import { objectClean } from 'helpers/ObjectHelpers';

import type { IProduct } from 'types';

import styles from './ProductToastBody.module.scss';

interface Props {
  className?: string,
  product: Pick<IProduct, 'name' | 'image'>,
  button?: {
    label: string,
    props: Partial<React.ComponentProps<typeof Button>>
  },
  message: string
}

const ProductToastBody = (props: Props) => {

  const {
    className,
    product,
    button,
    message = ''
  } = props;

  const { name, image } = product || {};
  const { label: buttonLabel, props: buttonProps } = button || {};

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.thumbnail}>
        <Image
          className={styles.image}
          src={image as any} // TODO: fix
          width={52}
          height={52}
          quality={100}
          optimize
          alt={name}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.message}>
          {message}
        </div>
        {
          button && (
            <Button
              className={styles.button}
              size="small"
              variant="subtle"
              {...(objectClean(buttonProps || {}) as ButtonProps)}
            >
              {buttonLabel}
            </Button>
          )
        }
      </div>
    </div>
  );
};

export default ProductToastBody;
