import React from 'react';
import classnames from 'classnames';

import { Image } from 'components/ui';
import { IMAGES } from 'constants/images';

import type {
  Breakpoint, IProductBadge, IProductBadgeItem, Size
} from 'types';

import {
  Breakpoints, ProductBadgeItemTypes, ProductBadgeTypes, Sizes
} from 'types';

import styles from './ProductBadge.module.scss';

export interface Props {
  className?: string,
  prefixClassName?: string,
  suffixClassName?: string,
  //
  badge?: IProductBadge,
  //
  size?: Size,
  responsiveSize?: Size,
  breakpoint?: Breakpoint,
  //
  withPrefix?: boolean,
  withOffset?: boolean
}

const ProductBadge = (props: Props) => {

  const {
    className,
    prefixClassName,
    suffixClassName,
    //
    badge,
    //
    size = Sizes.MEDIUM,
    responsiveSize = Sizes.MEDIUM,
    breakpoint = Breakpoints.MD,
    //
    withPrefix = true,
    withOffset = true,
  } = props;

  const {
    type, items
  } = badge || {};

  if (
    !type
    || !Object.values(ProductBadgeTypes).includes(type)
    || !items?.length
  ) return null;

  const { suffix, prefix } = parseItems(items);

  if ((withPrefix && !prefix) || !suffix) {
    return null;
  }

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[size]]: !!size },
        { [styles[`responsive-${responsiveSize}`]]: !!responsiveSize },
        { [styles[`break-at-${breakpoint}`]]: !!breakpoint },
        { [styles.hasPrefix]: withPrefix },
        { [styles.hasOffset]: withOffset },
        className
      )}
    >

      {/* Prefix */}
      {
        withPrefix && (
          <div
            className={classnames(styles.prefix, prefixClassName)}
            style={{
              color: prefix?.color,
              backgroundColor: prefix?.bgColor,
              borderColor: suffix?.bgColor,
            }}
          >

            {/* Text */}
            {prefix?.text}

            {/* Separator */}
            <div
              className={styles.separator}
              style={{
                backgroundColor: suffix?.bgColor,
              }}
            >
              <Image
                className={styles.image}
                src={IMAGES.MISC.BADGE_SEPARATOR}
                width={10}
                height={36}
              />
            </div>

          </div>
        )
      }

      {/* Suffix */}
      <div
        className={classnames(styles.suffix, suffixClassName)}
        style={{
          color: suffix?.color,
          backgroundColor: suffix?.bgColor,
          borderColor: suffix?.bgColor,
        }}
      >
        {suffix?.text}
      </div>
    </div>
  );
};

// Helpers

const parseItems = (items: IProductBadgeItem[]): { prefix: IProductBadgeItem | undefined, suffix: IProductBadgeItem | undefined } => {
  try {
    return items.reduce((acc, item) => {
      if (item?.type === ProductBadgeItemTypes.PREFIX) {
        acc.prefix = item;
      } else if (item?.type === ProductBadgeItemTypes.SUFFIX) {
        acc.suffix = item;
      }
      return acc;
    }, {
      prefix: undefined,
      suffix: undefined,
    } as {prefix: IProductBadgeItem | undefined, suffix: IProductBadgeItem | undefined});
  } catch (error) {
    return {
      prefix: undefined,
      suffix: undefined,
    };
  }
};

// Export

export default React.memo(ProductBadge);
