import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { useModalDispatchHelpers } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';

import CartButton from './CartButton';

import styles from './CartPromotionsButton.module.scss';

interface Props {
  wrapperClassName?: string,
  className?: string,
  iconClassName?: string,
  orderTokenValue: string,
  incompletePromotionsCount: number
}

const CartPromotionsButton = (props: Props) => {
  const {
    wrapperClassName,
    className,
    iconClassName,
    orderTokenValue,
    incompletePromotionsCount
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Props

  const promoLabel = incompletePromotionsCount
    ? incompletePromotionsCount === 1
      ? t('PROMOTIONS.AVAILABLE_PROMO')
      : t('PROMOTIONS.AVAILABLE_PROMO_PLURAL', { count: incompletePromotionsCount })
    : null;

  // Handlers

  const onClick = React.useCallback((e) => {
    e.preventDefault();
    showModal(MODAL_TYPES.AVAILABLE_PROMOTIONS, {
      order: {
        tokenValue: orderTokenValue
      }
    });
  }, [showModal, orderTokenValue]);

  // Render

  return (
    <div className={classnames(styles.root, wrapperClassName)}>
      <CartButton
        className={classnames(styles.btn, className)}
        iconClassName={classnames(styles.icon, iconClassName)}
        icon="discount"
        iconColor={styles.colorPromotion}
        label={promoLabel}
        onClick={onClick}
      />
    </div>
  );
};

export default CartPromotionsButton;
