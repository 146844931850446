import React from 'react';

export function useStepper(stepIds = {}, initial = null) {

  const [steps, setSteps] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    setSteps(Object.values(stepIds));

    const initialIndex = Object.values(stepIds).findIndex((i) => i === initial);

    if (initialIndex > 0) {
      setActiveIndex(initialIndex);
    }
  }, [stepIds, initial]);

  // --- Next

  const next = React.useCallback(() => {
    const nextIndex = activeIndex + 1;

    if (nextIndex < steps?.length) {
      setActiveIndex(nextIndex);
    }
  }, [steps, activeIndex]);

  // --- Prev

  const prev = React.useCallback(() => {
    const prevIndex = activeIndex - 1;

    if (prevIndex >= 0) {
      setActiveIndex(prevIndex);
    }
  }, [steps, activeIndex]);

  // --- Go to

  const goTo = React.useCallback((id) => {
    const goToIndex = steps.findIndex((i) => i === id);

    if (goToIndex >= 0 && goToIndex < steps?.length) {
      setActiveIndex(goToIndex);
    }
  }, [steps]);

  return {
    activeStep: steps[activeIndex],
    next,
    prev,
    goTo
  };
}
