import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { ProductsCarousel } from 'components/product';
import { Error, ImageMessage, Modal } from 'components/ui';
import { IMAGES } from 'constants/images';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { usePromotionsByOrder } from 'hooks/data/usePromotionsByOrder';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IOrder } from 'types';

import styles from './PromotionsModal.module.scss';

interface Props {
  isOpen: boolean,
  order: IOrder,
  handleModalClose: () => void
}

const PromotionsModal = (props: Props) => {
  const {
    isOpen,
    order,
    handleModalClose,
  } = props;

  // Hooks

  const router = useRouter();
  const { t } = useTranslation();

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Data Hooks

  const {
    isLoading,
    isError,
    isSuccess,
    data: promotions,
    refetch
  } = usePromotionsByOrder(order?.tokenValue, {
    enabled: isOpen && !!order?.tokenValue,
    refetchOnWindowFocus: false,
  });

  // Props

  const { slots } = promotions || {};

  // Handlers

  const handleRouteChange = React.useCallback(() => {
    if (isOpen) {
      handleModalClose();
    }
  }, [isOpen, handleModalClose]);

  // Effects

  React.useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleRouteChange]);

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      contentClassName={styles.root}
      //
      wide
      breakpoint="md"
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'right',
        isFullScreen: true
      }}
      //
      title={t('PROMOTIONS.MODAL_TITLE')}
      headerProps={{
        desktopProps: {
          size: 'large',
          withCloseButton: true,
        },
        responsiveProps: {
          size: 'medium',
          withBorder: true,
          withBackButton: true,
          withCloseButton: false,
        },
        onBack: handleModalClose,
        onClose: handleModalClose,
      }}
      //
      close={handleModalClose}
    >

      {/* Error */}
      {
        isError && (
          <Error />
        )
      }

      {/* Loading */}
      {
        isLoading && (
          <div className={styles.body}>
            <ProductsCarousel
              className={classnames(styles.carouselContainer, styles.skeleton)}
              headerClassName={styles.carouselHeader}
              loading
              loadingCount={isBreakpoint ? 2 : 4}
              withLoadingHeader
            />
          </div>
        )
      }

      {/* Success */}
      {
        isSuccess && (
          <div className={styles.body}>
            {
              isArrayEmpty(slots, true)
                ? (
                  <ImageMessage
                    className={styles.emptyContainer}
                    image={IMAGES.PLACEHOLDERS.NO_PRODUCTS}
                    imageProps={{
                      width: 414,
                      height: 246,
                      alt: 'No products'
                    }}
                    title={t('PROMOTIONS.LIST.EMPTY_TITLE')}
                  />
                ) : (
                  <div className={styles.promotions}>
                    {
                      slots?.map((slot, index) => {
                        const {
                          analyticsListId,
                          analyticsListName,
                          type,
                          ...rest
                        } = slot;

                        if (!SLOTS_MAP[type]) return null;

                        const { component, props: extra } = SLOTS_MAP[type];

                        switch (component) {
                          case ProductsCarousel:
                            return (
                              <ProductsCarousel
                                key={index}
                                //
                                className={styles.carouselContainer}
                                headerClassName={styles.carouselHeader}
                                //
                                breakpoints={PRODUCTS_CAROUSEL_BREAKPOINTS}
                                productListProps={{
                                  analyticsListId,
                                  analyticsListName
                                }}
                                //
                                {...extra}
                                {...rest}
                                //
                                onItemQuantityChange={() => refetch()}
                              />
                            );

                          default:
                            return null;
                        }

                      })
                    }
                  </div>
                )
            }
          </div>
        )
      }

    </Modal>
  );
};

// Constants

const PRODUCTS_CAROUSEL_BREAKPOINTS = {
  1600: {
    slidesPerView: 4
  },
  1440: {
    slidesPerView: 4
  },
  960: {
    slidesPerView: 4
  },
  640: {
    slidesPerView: 3
  }
};

const SLOTS_MAP = {
  products_carousel: {
    component: ProductsCarousel,
    props: {}
  },
  products_carousel_vertical: {
    component: ProductsCarousel,
    props: {}
  },
};

// Export

export default PromotionsModal;
