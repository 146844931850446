import React from 'react';
import { isMobile } from 'react-device-detect';

import {
  Link, ResponsiveImage, Slide, Slider
} from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './BlogFeaturedCarousel.module.scss';

const BlogFeaturedCarousel = (props) => {

  const { items } = props;

  const isMd = useMediaQuery(parseInt(styles.breakpointMd, 10));
  const isSm = useMediaQuery(parseInt(styles.breakpointSm, 10));

  const slidesPerGroup = isMd ? isSm ? 1 : 3 : 2;
  const sliderPerView = isMd ? isSm ? 1 : 3 : 'auto';

  return (
    <Slider
      className={styles.carousel}
      prevClassName={styles.carouselPrev}
      nextClassName={styles.carouselNext}
      spaceBetween={24}
      slidesPerGroup={slidesPerGroup}
      navigation
      allowTouchMove={isMobile}
      preventClicks={isMobile}
      preventClicksPropagation={isMobile}
      noSwiping={!isMobile}
      slidesPerView={sliderPerView}
      watchSlidesProgress
    >
      {
        items?.map((item, index) => {
          const {
            title,
            href,
            image: { large, small, fallback }
          } = item;

          const width = index === 0 && !isMd ? 648 : 312;

          const desktopSrc = (index === 0 ? isMd ? small : large : small) || fallback;
          const responsiveSrc = large || fallback;

          return (
            <Slide key={index} className={styles.slideWrapper}>
              <Link href={href}>
                <a className={styles.slide}>
                  <div className={styles.imageWrapper}>
                    <ResponsiveImage
                      optimize
                      imageClassName={styles.image}
                      alt={title}
                      desktopProps={{
                        src: desktopSrc,
                        width,
                        height: 410,
                        title,
                        style: {
                          objectFit: 'cover',
                        },
                      }}
                      responsiveProps={{
                        src: responsiveSrc,
                        width: 640,
                        height: 405,
                        title,
                        style: {
                          objectFit: 'cover',
                        },
                      }}
                    />
                    <div className={styles.overlay}>
                      <div className={styles.overlayContent}>
                        <div className={styles.featuredRecipeTitle}>{title}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            </Slide>
          );
        })
      }
    </Slider>
  );
};

export default BlogFeaturedCarousel;
