import React from 'react';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';

import styles from './ProductSearchCard.module.scss';

const ProductSearchCardSkeleton = () => {

  const IMAGE_SIZE = 48;

  return (
    <div className={classnames(styles.root, styles.skeleton)}>
      <div className={styles.imageContainer}>
        <div className={styles.imageSkeletonWrapper}>
          <Skeleton
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            className={styles.imageSkeleton}
          />
        </div>
      </div>
      <div className={classnames(styles.content, styles.skeleton)}>
        <div className={styles.contentSkeletonWrapper}>
          <Skeleton count={1} height={12} className={styles.contentSkeleton} />
          <Skeleton count={1} height={12} className={styles.contentSkeleton} />
        </div>
      </div>
    </div>
  );
};

export default ProductSearchCardSkeleton;
