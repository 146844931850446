import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import BlogBlockContainer from '../BlogBlockContainer/BlogBlockContainer';

import styles from './BlogProductsBlock.module.scss';

const BlogProductsCarousel = dynamic(() => import('../BlogProductsCarousel/BlogProductsCarousel'), { ssr: false });

interface Props {
  className?: string,
  containerClassName?: string,
  innerContainerClassName?: string,
  isMobilePreview?: boolean
}

const BlogProductsBlock = (props: Props) => {

  const {
    containerClassName,
    innerContainerClassName,
    ...restProps
  } = props;

  return (
    <BlogBlockContainer
      className={classnames(styles.container, containerClassName)}
      innerClassName={classnames(styles.innerContainer, innerContainerClassName)}
    >
      <BlogProductsCarousel {...restProps as React.ComponentProps<typeof BlogProductsCarousel>} />
    </BlogBlockContainer>
  );
};

export default BlogProductsBlock;
