import QueryString from 'common/QueryString';

export function getEmbeddedYoutubeUrl(id?: string, url?: string) {
  const params = {
    rel: 0,
    enablejsapi: 1,
  };

  const qs = QueryString.stringify(params);
  const videoId = id || getYoutubeVideoIdFromUrl(url);

  if (!id && !url) return null;

  return `https://www.youtube.com/embed/${videoId}?${qs}`;
}

export function getYoutubeVideoIdFromUrl(url?: string) {
  if (!url) return null;
  try {
    const query = url.split('?')[1];
    const { v } = QueryString.parse(query);
    return v;
  } catch (err) {
    console.error('Failed to get video ID', err);
  }
  return null;
}
