import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'hooks/common/useTranslation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import Button from '../Button/Button';

import type { Variant } from '../Button/Button';

import type { Breakpoint } from 'types';
import { Breakpoints } from 'types';

import styles from './ModalActions.module.scss';

export interface ActionProps {
  className?: string,
  icon?: string,
  text?: string,
  type?: 'button' | 'submit' | 'reset',
  translate?: boolean,
  variant?: Variant,
  disabled?: boolean,
  loading?: boolean,
  onClick: () => void,
  dataTestId?: string
}

const PRIMARY_DEFAULT: ActionProps = {
  text: 'ACTIONS.CONTINUE',
  type: 'button',
  variant: 'primary',
  disabled: false,
  loading: false,
  translate: true,
  onClick: () => {},
};

const SECONDARY_DEFAULT: ActionProps = {
  text: 'ACTIONS.BACK',
  type: 'button',
  variant: 'secondary',
  disabled: false,
  loading: false,
  translate: true,
  onClick: () => {}
};

interface BreakpointSpecificProps {
  withNegativeMargin?: boolean,
  withPadding?: boolean,
  withBorder?: boolean
}

export interface Props extends BreakpointSpecificProps {
  className?: string,
  btnClassName?: string,
  innerClassName?: string,
  //
  disabled?: boolean,
  loading?: boolean,
  //
  breakpoint?: Breakpoint,
  desktopProps?: BreakpointSpecificProps,
  responsiveProps?: BreakpointSpecificProps,
  reversed?: boolean,
  //
  primary?: ActionProps,
  secondary?: ActionProps
}

const ModalActions = forwardRef<HTMLDivElement, Props>((
  props: Props,
  ref: React.MutableRefObject<HTMLDivElement>
) => {

  const {
    //
    className,
    innerClassName,
    btnClassName,
    //
    disabled,
    loading,
    //
    breakpoint = Breakpoints.MD,
    desktopProps,
    responsiveProps,
    reversed,
    //
    withNegativeMargin = false,
    withPadding = true,
    withBorder = true,
    //
    primary,
    secondary,
  } = props;

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles[`breakpoint_${breakpoint}`]})`);

  // Empty

  if (!primary && !secondary) return null;

  // Props

  const {
    withNegativeMargin: withNegativeMarginAtBreakpoint = withNegativeMargin,
    withPadding: withPaddingAtBreakpoint = withPadding,
    withBorder: withBorderAtBreakpoint = withBorder,
  } = (isBreakpoint ? responsiveProps : desktopProps) || {};

  // Primary / Secondary Props

  const primaryProps = primary ? { ...PRIMARY_DEFAULT, ...primary } : null;
  const secondaryProps = secondary ? { ...SECONDARY_DEFAULT, ...secondary } : null;

  // Classes

  const classes = classnames(
    styles.root,
    { [styles.responsive]: isBreakpoint },
    { [styles.desktop]: !isBreakpoint },
    { [styles.hasNegativeMargin]: withNegativeMarginAtBreakpoint },
    { [styles.padded]: withPaddingAtBreakpoint },
    { [styles.bordered]: withBorderAtBreakpoint },
    { [styles.reversed]: reversed },
    className
  );

  // Render

  return (
    <div ref={ref} className={classes}>
      <div className={classnames(styles.inner, innerClassName)}>

        {/* Secondary */}
        {
          secondary && (
            <Button
              className={classnames(
                styles.btn,
                styles.secondary,
                btnClassName,
                secondaryProps?.className
              )}
              type={secondaryProps?.type || 'button'}
              variant={secondaryProps?.variant || 'secondary'}
              size="large"
              startIcon={secondaryProps?.icon}
              loading={secondaryProps?.loading}
              disabled={disabled || loading || secondaryProps?.disabled}
              onClick={secondaryProps?.onClick}
              {...(secondaryProps?.dataTestId ? { 'data-testid': secondaryProps.dataTestId } : {})}
            >
              {secondaryProps.translate ? t(secondaryProps.text) : secondaryProps.text}
            </Button>
          )
        }

        {/* Primary */}
        {
          primary && (
            <Button
              className={classnames(
                styles.btn,
                styles.primary,
                btnClassName,
                primaryProps?.className
              )}
              type={primaryProps?.type || 'button'}
              variant={primaryProps?.variant || 'primary'}
              size="large"
              startIcon={primaryProps?.icon}
              loading={loading || primaryProps?.loading}
              disabled={disabled || loading || primaryProps?.disabled}
              onClick={primaryProps?.onClick}
              {...(primaryProps?.dataTestId ? { 'data-testid': primaryProps.dataTestId } : {})}
            >
              {primaryProps.translate ? t(primaryProps.text) : primaryProps.text }
            </Button>
          )
        }

      </div>
    </div>
  );
});

export default ModalActions;
