import {
  browserVersion as _browserVersion,
  isFirefox as _isFirefox,
  isIE as _isIE,
  isIOS as _isIOS,
  isSafari as _isSafari
} from 'react-device-detect';

export function isClient() {
  return typeof window === 'object';
}

export function isApiAvailable(api: string) {
  return (typeof window !== 'undefined' ? api in window : false);
}

export function isIE() {
  return _isIE;
}

export function isIOS() {
  return _isIOS;
}

export function isSafari() {
  return _isSafari;
}

export function isSafari11() {
  return _isSafari && parseInt(_browserVersion, 10) < 12;
}

export function isFirefox() {
  return _isFirefox;
}

export function isOutdatedBrowser() {
  return isIE() || isSafari11();
}

export function decodeURIComponentSafe(s: string) {
  if (!s) {
    return s;
  }
  return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
}

export function isMobile() {
  if (!isClient()) return true;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent);
}
