import React from 'react';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import {
  Button, Icon, Link, ResponsiveImage
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { APP_ROUTES } from 'constants/routes';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { IPromotionItem, Size } from 'types';

import styles from './PromotionPreview.module.scss';

interface Props {
  className?: string,
  index: number,
  promotion: IPromotionItem,
  onClick?: () => void
}

const PromotionPreview = (props: Props) => {
  const {
    className,
    index,
    promotion,
    onClick = () => {},
  } = props;

  const {
    code,
    name,
    datesShort,
    footerImageWeb: desktopSrc,
    footerImageResponsive: responsiveSrc
  } = promotion;

  // Refs

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter();
      }
      unobserve();
    }
  });

  // Hooks

  const analytics = useAnalytics();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Handlers

  const onEnter = () => {
    analytics.viewPromotion(promotion, index + 1);
  };

  const onLinkClick = () => {
    analytics.selectPromotion(promotion, index + 1);
    onClick();
  };

  return (
    <div ref={observe} className={classnames(styles.root, className)}>

      <div className={styles.inner}>

        {/* Image */}
        <div className={styles.imageContainer}>
          <div className={styles.imageInner}>
            <ResponsiveImage
              className={styles.image}
              optimize
              alt={name}
              breakpoint="md"
              desktopProps={{
                src: desktopSrc,
                width: 378,
                height: 122,
                style: {
                  objectFit: 'cover',
                }
              }}
              responsiveProps={{
                src: responsiveSrc,
                width: 124,
                height: 124,
                style: {
                  objectFit: 'cover',
                }
              }}
            />
          </div>
        </div>

        {/* Content */}
        <div className={styles.content}>

          <div className={styles.details}>
            <div className={styles.title}>{name}</div>
            <div className={styles.date}>{datesShort}</div>
          </div>

          {/* Desktop Link */}
          {
            !isBreakpoint && (
              <div className={styles.action}>
                <LinkButton
                  code={code}
                  onClick={onLinkClick}
                />
              </div>
            )
          }
        </div>
      </div>

      {/* Responsive Link */}
      {
        isBreakpoint && (
          <div className={styles.action}>
            <LinkButton
              size="large"
              code={code}
              onClick={onLinkClick}
            />
          </div>
        )
      }

    </div>
  );
};

// Components

interface LinkButtonProps {
  size?: Size,
  code: string,
  onClick: () => void
}

const LinkButton = (props: LinkButtonProps) => {
  const {
    size = 'medium',
    code,
    onClick = () => {}
  } = props;

  const { t } = useTranslation();

  return (
    <Link
      href={`${APP_ROUTES.PROMOTIONS}/${code}`}
      prefetch={CONFIG.PREFETCH_LINKS}
    >
      <Button
        tag="a"
        variant="secondary"
        size={size}
        className={styles.btn}
        endIcon={(
          <Icon
            className={styles.btnIcon}
            name="chevron-right"
            size={18}
            strokeWidth={3}
          />
        )}
        onClick={onClick}
      >
        <span className={styles.btnText}>
          {t('PROMOTIONS.VIEW_UPCOMING_PROMOTION')}
        </span>
      </Button>
    </Link>
  );
};

// Export

export default PromotionPreview;
