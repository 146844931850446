import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import * as Notifications from 'common/Notification';
import ConfirmationModal from 'components/ui/Modal/ConfirmationModal';
import { APP_ROUTES } from 'constants/routes';
import { useOrderMutation } from 'hooks/data/useOrderMutation';
import { useAnalytics } from 'hooks/useAnalytics';

import styles from './OrderRepeatModal.module.scss';

const OrderRepeatModal = (props) => {
  const {
    order, isOpen, handleClose, onSubmitSuccess
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const { t } = useTranslation();
  const router = useRouter();
  const analytics = useAnalytics();
  const { repeat } = useOrderMutation();

  const goToCheckout = React.useCallback(() => {
    analytics.beginCheckout(order);
    router.push({ pathname: APP_ROUTES.CHECKOUT });
  }, [order, router]);

  const handleConfirm = () => {
    setIsLoading(true);

    repeat({
      tokenValue: order?.tokenValue,
      onSuccess: () => {
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }

        goToCheckout();
      },
      onError: () => {
        Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'repeatOrder' });
        setIsLoading(false);
      }
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      className={styles.confirmationModal}
      //
      loading={isLoading}
      title={t('account:ORDER_HISTORY.REPEAT_ORDER_TITLE')}
      subtitle={t('account:ORDER_HISTORY.REPEAT_ORDER_SUBTITLE')}
      //
      onConfirm={handleConfirm}
      onCancel={() => handleClose()}
      close={() => handleClose()}
    />
  );
};

export default OrderRepeatModal;
