import React from 'react';
import classnames from 'classnames';

import {
  Button, Icon, Popover, Scrollbar
} from 'components/ui';
import { useIsOpen } from 'hooks/useIsOpen';

import FilterOptions from './FilterOptions';

import type { IListingFilter, IListingFilterOption, IUrl } from 'types';

import styles from './FiltersPopover.module.scss';

interface Props {
  className?: string,
  triggerClassName?: string,
  //
  filter: IListingFilter,
  //
  multiple?: boolean,
  withTitle?: boolean,
  withCount?: boolean,
  //
  getHref: (option: IListingFilterOption, parent: IListingFilter) => IUrl
}

const FiltersPopover = (props: Props) => {

  const {
    className,
    triggerClassName,
    //
    filter,
    //
    multiple = true,
    withTitle = true,
    withCount = true,
    //
    getHref,
  } = props;

  const {
    name,
    countSelected,
    values,
  } = filter;

  // Refs

  const ref = React.useRef();

  // Hooks

  const { isOpen, toggle, close } = useIsOpen();

  // Handlers

  const handleOnChange = () => {
    if (!multiple) {
      close();
    }
  };

  // Empty

  if (!Array.isArray(values) || values.length === 0) return null;

  // Render

  return (
    <div
      ref={ref}
      className={classnames(
        styles.root,
        className
      )}
    >
      <Popover
        isOpen={isOpen}
        persist
        parentElement={ref || undefined}
        containerClassName={styles.popoverContainer}
        contentClassName={styles.popover}
        align="start"
        positions={['bottom', 'top']}
        content={(
          <>
            {/* Name */}
            {
              (name && withTitle) && (
                <div className={styles.popoverTitle}>{name}</div>
              )
            }

            {/* Options */}
            <FilterOptions
              searchClassName={styles.search}
              scrollbarClassName={styles.scrollbar}
              parent={filter}
              options={values}
              getHref={getHref}
              multiple={multiple}
              withSearch
              withScrollbar
              onChange={handleOnChange}
            />
          </>
        )}
        onClickOutside={close}
      >

        {/* Trigger */}
        <Button
          className={classnames(
            styles.selectBtn,
            { [styles.selected]: countSelected > 0 },
            triggerClassName,
          )}
          type="button"
          endIcon={ChevronIcon}
          onClick={toggle}
        >
          <div>{name}</div>

          {
            (withCount && countSelected > 0) && (
              <div className={styles.filtersCount}>{countSelected}</div>
            )
          }
        </Button>

      </Popover>
    </div>
  );
};

// Components

const ChevronIcon = <Icon size={16} name="chevron-down" className={styles.chevronIcon} />;

// Exports

export default FiltersPopover;
