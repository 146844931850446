import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'checkout_events';

enum Actions {
  OPEN_STEP_MODAL = 'open_modal_step',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

// --- Open Checkout Step Modal

export function openCheckoutStepModal(label: string) {
  push(Actions.OPEN_STEP_MODAL, label);
}
