import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormGroup } from 'components/ui';
import { useMediaQuery } from 'hooks/useMediaQuery';

import AddressGoogleMap from '../AddressGoogleMap/AddressGoogleMap';

import { FIELDS } from './AddressFormConfig';
import AddressStreetAutocomplete from './AddressStreetAutocomplete';

import styles from './AddressFormStreet.module.scss';

const {
  IS_PLACE_VALID, PLACE_ID, GEOLOCATION
} = FIELDS;

const AddressFormStreet = (props) => {

  const {
    className,
    titleClassName,
    form,
    withMap = true,
    withStickyActions = true,
    onContinue = () => {},
    onInvalidNumber = () => {},
    renderActions = () => {}
  } = props;

  const { t } = useTranslation();

  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const [isLoading, setLoading] = React.useState(false);

  const {
    [PLACE_ID]: placeId,
    [GEOLOCATION]: geolocation
  } = form?.values || {};

  const classes = classnames(
    styles.root,
    { [styles.withStickyActions]: withStickyActions },
    className
  );

  return (
    <div className={classes}>

      {/* Field Groups */}
      <div className={styles.groups}>

        {/* Street */}
        <FormGroup
          titleClassName={titleClassName}
          inline
          title={t('ADDRESS.DELIVERY_ADDRESS_LABEL')}
        >
          <div className={styles.autocomplete}>
            {/* Street Autocomplete */}
            <AddressStreetAutocomplete
              form={form}
              onLoading={setLoading}
              onInvalidNumber={onInvalidNumber}
              onEnterKeyDown={() => {
                if (!form?.isValidating && form?.values[IS_PLACE_VALID]) {
                  onContinue();
                }
              }}
            />
          </div>

          {/* Desktop Actions */}
          {
            (!withStickyActions || !isBreakpoint) && (
              <div className={styles.actions}>
                {renderActions(form, { isLoading, withPadding: false })}
              </div>

            )
          }
        </FormGroup>

        {/* Map */}
        {
          withMap && (
            <FormGroup
              className={styles.mapFormGroup}
              fieldsClassName={styles.mapFormGroupFields}
            >
              <AddressGoogleMap
                containerClassName={styles.googleMapContainer}
                className={styles.googleMap}
                readonly
                form={form}
                placeId={placeId}
                pin={geolocation}
                center={geolocation}
              />
            </FormGroup>
          )
        }
      </div>

      {/* Responsive Actions */}
      {
        withStickyActions && isBreakpoint && (
          <div className={styles.actions}>
            {renderActions(form, { isLoading, withPadding: true })}
          </div>
        )
      }
    </div>
  );
};

AddressFormStreet.propTypes = {
  titleClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  form: PropTypes.shape({}),
  withMap: PropTypes.bool,
  withStickyActions: PropTypes.bool,
  onInvalidNumber: PropTypes.func,
  renderActions: PropTypes.func
};

export default AddressFormStreet;
