import { Api, WebApi } from 'common/http';
import Logger from 'common/Logger';
import { UrlParams } from 'common/url-params';
import { CONFIG } from 'constants/config';

import type { ICategory, ICategoryProductsListing, IListingRequestParams } from 'types';

export async function getCategory(params: IListingRequestParams) {
  const {
    page = 1, itemsPerPage = CONFIG.PAGINATION.DEFAULT_SIZE, orderBy, filters, identifier
  } = params;
  const orderByQs = UrlParams.getOrderByQueryString(orderBy);
  const filtersQs = UrlParams.getFiltersQueryString(filters);
  const url = `/categories/${encodeURIComponent(identifier!)}?page=${page}&itemsPerPage=${itemsPerPage}${orderByQs}${filtersQs}`;
  const { data } = await Api.get<ICategoryProductsListing>(url);
  return data;
}

export async function getCategoriesFromWebApi() {
  try {
    const { data } = await WebApi.get('/categories');
    return data;
  } catch (err) {
    Logger.error('Failed to get categories', err);
    return [];
  }
}

export async function getCategories() {
  try {
    const { data } = await Api.get<{children: ICategory[]}>('/categories');
    return data.children;
  } catch (err) {
    Logger.error('Failed to get categories from backend', err);
    return [];
  }
}
