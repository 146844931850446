import React from 'react';

import * as ErrorHandler from 'common/ErrorHandler';
import { isApiAvailable, isClient } from 'helpers/BrowserHelpers';

type MediaQuery =
  | string
  | number
  | {direction?: 'min' | 'max' | 'up' | 'down', breakpoint: string | number };

export function useMediaQuery(rawMediaQuery: MediaQuery) {

  if (!isClient || !isApiAvailable('matchMedia')) {
    return false;
  }

  const mediaQuery = parseQuery(rawMediaQuery);
  const [isVerified, setIsVerified] = React.useState(!!window.matchMedia(mediaQuery).matches);

  React.useEffect(() => {

    const isLegacy = !!(window as any).addListener;

    try {

      const mediaQueryList = window.matchMedia(mediaQuery);
      const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

      if (isLegacy) {
        mediaQueryList.addListener(documentChangeHandler);
        documentChangeHandler();
      } else {
        mediaQueryList.addEventListener('change', documentChangeHandler);
        documentChangeHandler();
      }

      return () => {
        if (isLegacy) {
          mediaQueryList.removeListener(documentChangeHandler);
        } else {
          mediaQueryList.removeEventListener('change', documentChangeHandler);
        }
      };

    } catch (err) {
      ErrorHandler.throwError(err, 'Failed to add event listener');
    }

  }, [mediaQuery]);

  return isVerified;
}

const parseQuery = (query: MediaQuery) => {

  if (typeof query === 'string') return query;

  if (typeof query === 'number') return `(max-width: ${query}px)`;

  // TODO change default direction to 'min' for a mobile first approach
  const { direction = 'max', breakpoint } = query || {};
  const directionString = direction === 'max' || direction === 'down' ? 'max' : 'min';
  return `(${directionString}-width: ${breakpoint}px)`;
};
