import type {
  IStrapiDataResponsiveImage,
  IStrapiDataTimestamp,
  IStrapiResponse
} from './generic-response';

import type { IRecipeCardData } from './recipe-card-response';

import type { ValueOf } from '../utility';

// Response

export type IRecipesLandingPageResponse = IStrapiResponse<IRecipesLandingPageData, any>;

// Data

export interface IRecipesLandingPageData extends IStrapiDataTimestamp {
  id: number,
  title?: string,
  hero: IRecipesLandingPageDataHero,
  slots: IRecipesLandingPageDataSlot[],
  seo: IRecipesLandingPageDataSeo
}

// --- Hero

export interface IRecipesLandingPageDataHero {
  id: number,
  title: string,
  description: string,
  tags: IRecipesLandingPageDataHeroTags,
  image: IStrapiDataResponsiveImage
}

export type IRecipesLandingPageDataHeroTags = IRecipesLandingPageDataHeroTag[];

export interface IRecipesLandingPageDataHeroTag extends IStrapiDataTimestamp {
  id: number,
  slug: string,
  label: string
}

// --- Slot

export type IRecipesLandingPageDataSlot = (
  IRecipesLandingPageDataRecipesCarouselSlot
);

// ------ Slot Types

export const RECIPES_LANDING_PAGE_DATA_SLOT_TYPES = {
  RECIPES_CAROUSEL: 'recipes_carousel',
} as const;

type RecipesLandingPageDataSlotType = ValueOf<typeof RECIPES_LANDING_PAGE_DATA_SLOT_TYPES>;

// ------ Generic Slot

interface IRecipesLandingPageDataGenericSlot {
  type: RecipesLandingPageDataSlotType,
  data: any
}

// ------ Recipes Carousel Slot

interface IRecipesLandingPageDataRecipesCarouselSlot extends IRecipesLandingPageDataGenericSlot {
  type: typeof RECIPES_LANDING_PAGE_DATA_SLOT_TYPES.RECIPES_CAROUSEL,
  data: {
    title: string,
    metadata: IRecipesLandingPageDataSlotMetadata,
    link?: IRecipesLandingPageDataLink,
    recipes: IRecipeCardData[]
  }
}

// --- Slot Link

export interface IRecipesLandingPageDataLink {
  url: string,
  title: string
}

// --- Slot Metadata

// ------ Slot Metadata Types

export const SLOT_METADATA_TYPES = {
  BY_TAG: 'by-tag',
  LATEST: 'latest',
  CUSTOM: 'custom',
} as const;

export type SlotMetadataType = ValueOf<typeof SLOT_METADATA_TYPES>;

// ------ Generic Slot Metadata

export type IRecipesLandingPageDataSlotMetadata = (
  IRecipesLandingPageDataSlotByTagMetadata
  | IRecipesLandingPageDataSlotLatestMetadata
  | IRecipesLandingPageDataSlotCustomMetadata
);

interface IRecipesLandingPageDataSlotGenericMetadata {
  type: SlotMetadataType
}

// ------- By Tag Slot Metadata

interface IRecipesLandingPageDataSlotByTagMetadata extends IRecipesLandingPageDataSlotGenericMetadata {
  type: typeof SLOT_METADATA_TYPES.BY_TAG,
  tag: {
    id: number,
    slug: string
  }
}

// ------- Last Slot Metadata

interface IRecipesLandingPageDataSlotLatestMetadata extends IRecipesLandingPageDataSlotGenericMetadata {
  type: typeof SLOT_METADATA_TYPES.LATEST
}

// ------- Custom Slot Metadata

interface IRecipesLandingPageDataSlotCustomMetadata extends IRecipesLandingPageDataSlotGenericMetadata {
  type: typeof SLOT_METADATA_TYPES.CUSTOM
}

// --- SEO

export interface IRecipesLandingPageDataSeo {
  id: number,
  title: string,
  description: string
}
