import React from 'react';
import classnames from 'classnames';

import { RECOMMENDATION_LOCATIONS } from 'constants/recommendations';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import { useTranslation } from 'hooks/common/useTranslation';

import CartButton from './CartButton';

import type { RecommendationType } from 'types';

import styles from './CartRecommendationsButton.module.scss';

interface Props {
  wrapperClassName?: string,
  className?: string,
  iconClassName?: string,
  orderTokenValue: string,
  recommendationType: RecommendationType
}

const CartRecommendationsButton = (props: Props) => {
  const {
    wrapperClassName,
    className,
    iconClassName,
    orderTokenValue,
    recommendationType
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Handlers

  const onClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showModal(MODAL_TYPES.RECOMMENDATIONS, {
      location: RECOMMENDATION_LOCATIONS.CART,
      type: recommendationType,
      emptyTitle: t('CART.RECOMMENDATIONS_MODAL_EMPTY_TITLE'),
      emptySubtitle: t('CART.RECOMMENDATIONS_MODAL_EMPTY_SUBTITLE'),
      order: {
        tokenValue: orderTokenValue
      },
      forceFallback: true
    });
  }, [showModal, orderTokenValue, recommendationType, t]);

  // Render

  return (
    <div className={classnames(styles.root, wrapperClassName)}>
      <CartButton
        className={classnames(styles.btn, className)}
        iconClassName={classnames(styles.icon, iconClassName)}
        icon="bulb"
        iconColor={styles.colorRecommendation}
        label="CART.VIEW_RECOMMENDATIONS"
        onClick={onClick}
      />
    </div>
  );
};

export default CartRecommendationsButton;
