import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { CategoriesMobile as CategoriesNavigationMobile } from 'components/layout';
import { Menu, MenuItemLink } from 'components/navigation';
import { Responsive, Scrollbar } from 'components/ui';
import { useNavigationStore } from 'context/NavigationContext';
import { getCategoriesNavigationMap, isCategoryNavigationResult } from 'helpers/CategoryHelpers';
import { isElementInViewport, scrollParentToChild } from 'helpers/ScrollHelpers';
import { useTranslation } from 'hooks/common/useTranslation';
import { useCategories } from 'hooks/data/useCategories';
import { useAnalytics } from 'hooks/useAnalytics';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import Filters from '../Filters/Filters';

import type { IListingFilters, PageType } from 'types';

import styles from './ListingSidebar.module.scss';

interface Props {
  className?: string,
  //
  pageType: PageType,
  filters?: IListingFilters,
  count?: number,
  //
  withCategoryNavigation?: boolean,
  withFilters?: boolean,
  //
  startSlot?: () => React.ReactNode
}

const ListingSidebar = (props: Props) => {

  const {
    //
    className,
    //
    pageType,
    filters,
    count,
    //
    withCategoryNavigation,
    withFilters,
    //
    startSlot
  } = props;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Start slot */}
      {startSlot && startSlot()}

      {/* Navigation */}
      {
        withCategoryNavigation && (
          <Responsive
            breakpoint="lg"
            mobile={<CategoriesNavigationMobile />}
            desktop={(
              <CategoriesNavigationDesktop
                className={styles.categoriesDesktop}
                withScrollbar={!!withFilters}
              />
            )}
          />
        )
      }

      {/* Filters */}
      {
        (withFilters) && (
          <div className={styles.filters}>
            <Responsive
              breakpoint="lg"
              mobile={null}
              desktop={(
                <Filters
                  className={styles.filters}
                  //
                  filters={{ filters: filters! }}
                  pageType={pageType}
                  //
                  inline={false}
                  withAccordion
                  withActiveFilters
                  withLabel={false}
                  withClearButton={false}
                  //
                  data={{ count }}
                />
              )}
            />
          </div>
        )
      }
    </div>
  );
};

// Components

// --- Navigation Desktop

interface CategoriesNavigationDesktopProps {
  className: string,
  withScrollbar: boolean
}

const CategoriesNavigationDesktop = (props: CategoriesNavigationDesktopProps) => {

  const {
    className,
    withScrollbar,
  } = props;

  // Refs

  // TODO fix types
  const activeChildRef = React.useRef<any>(null);
  const scrollbarRef = React.useRef<any>(null);

  // Hooks

  const router = useRouter();
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const { slug } = useNavigationStore();
  const { persist: persistAnalyticsListProps } = useRouteQueryAnalyticsListProps();
  const { data: categories = [] } = useCategories();

  // Create a map of all categories
  // linking to the enclosing level 1 category

  const categoriesMap = React.useMemo(() => {
    return getCategoriesNavigationMap(categories, { t });
  }, [categories]);

  // Handlers

  const handleRouteChange = () => {
    if (
      scrollbarRef?.current?.contentWrapperEl
      && activeChildRef?.current
      && !isElementInViewport(activeChildRef.current, scrollbarRef.current.contentWrapperEl)
    ) {
      scrollParentToChild(scrollbarRef?.current?.contentWrapperEl, activeChildRef?.current);
    }
  };

  const handleLinkClick = () => {
    persistAnalyticsListProps();
  };

  // Effects

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      handleRouteChange();
    });
  }, []);

  // Empty

  if (!slug) return null;

  // Current category navigation item
  const categoryData = categoriesMap[slug];
  if (!isCategoryNavigationResult(categoryData)) return null;
  const { parent, children } = categoryData;

  // Render

  return (
    <div className={classnames(styles.categories, className)}>

      {/* Header */}
      {
        parent && (
          <MenuItemLink
            className={styles.header}
            iconClassName={styles.icon}
            //
            item={parent}
            //
            onClick={() => {
              handleLinkClick();
            }}
          />
        )
      }

      {/* List */}
      {
        !!children?.length && (
          <Scrollbar
            ref={scrollbarRef}
            className={classnames(styles.scrollbar, { [styles.active]: withScrollbar })}
            autoHide={false}
            forceVisible="y"
          >
            <Menu
              activeChildRef={activeChildRef}
              //
              className={styles.list}
              linkClassName={styles.link}
              subLinkClassName={styles.subLink}
              linkActiveClassName={styles.active}
              subLinkActiveClassName={styles.active}
              //
              items={children}
              //
              withAccordion
              withIndent
              accordionProps={{
                className: styles.accordion,
                headerClassName: styles.accordionHeader,
                iconWrapperClassName: styles.accordionIconWrapper,
                contentClassName: styles.accordionContent,
                iconClassName: styles.accordionIcon,
                iconExpandedClassName: styles.accordionIconExpanded,
                iconSize: 18,
                iconStrokeWidth: 2
              }}
              //
              onItemClick={(item) => {
                handleLinkClick();
                if (item?.href && item?.label) {
                  analytics.selectCategory(item.label);
                }
              }}
            />
          </Scrollbar>
        )
      }
    </div>
  );
};

// Export

export default ListingSidebar;
