import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ProductsCarousel } from 'components/product';

import styles from './RecommendationSlot.module.scss';

const RecommendationSlot = (props) => {

  const {
    className,
    slot = {},
  } = props;

  const {
    type, ...rest
  } = slot;

  const Component = TYPES_MAP[type];
  const componentProps = getComponentProps(type, props);

  if (!Component) return null;

  return (
    <div className={classnames(styles.root, className)}>
      <Component
        {...rest}
        {...componentProps}
      />
    </div>
  );
};

// Components

const RecommendationSlotProductsCarousel = (props) => {
  const {
    // slot props
    icon,
    title,
    link,
    analyticsListId,
    analyticsListName,
    products = [],
    // products carousel props
    className,
    headerClassName,
    iconClassName,
    titleClassName,
    withViewMoreLink,
    withViewMoreCard,
    spaceBetween,
    productCardProps = {}
  } = props;

  return (
    <ProductsCarousel
      className={className}
      headerClassName={headerClassName}
      iconClassName={iconClassName}
      titleClassName={titleClassName}
      prevClassName={styles.prev}
      nextClassName={styles.next}
      withViewMoreLink={withViewMoreLink}
      withViewMoreCard={withViewMoreCard}
      spaceBetween={spaceBetween}
      icon={icon}
      title={title}
      link={link}
      products={products}
      productListProps={{
        analyticsListId,
        analyticsListName
      }}
      productCardProps={productCardProps}
      breakpoints={CAROUSEL_BREAKPOINTS}
      watchSlidesProgress
    />
  );
};

// Helpers

const getComponentProps = (type, props) => {
  if (type === TYPES.PRODUCTS_CAROUSEL) {
    return props?.productsCarouselProps;
  }

  return {};
};

// Constants

const TYPES = {
  PRODUCTS_CAROUSEL: 'products_carousel'
};

const TYPES_MAP = {
  [TYPES.PRODUCTS_CAROUSEL]: RecommendationSlotProductsCarousel
};

const CAROUSEL_BREAKPOINTS = {
  1800: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  1600: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  1280: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  960: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  640: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  300: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  }
};

// Prop Types

RecommendationSlot.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  slot: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TYPES)),
  }),
  productsCarouselProps: PropTypes.shape({})
};

// Export

export default RecommendationSlot;
