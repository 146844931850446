import React from 'react';
import classnames from 'classnames';

import Image from '../Image/Image';

import type { IImage } from 'types';

import styles from './ImageMessage.module.scss';

// Types
interface Props {
  className?: string,
  titleClassName?: string,
  subtitleClassName?: string,
  imageContainerClassName?: string,
  contentClassName?: string,
  image?: React.ReactNode,
  imageProps?: IImage | {} | undefined,
  title?: React.ReactNode,
  subtitle?: React.ReactNode,
  children?: React.ReactNode
}
const ImageMessage = (props: Props) => {

  const {
    className,
    titleClassName,
    subtitleClassName,
    imageContainerClassName,
    contentClassName,
    //
    image,
    imageProps = {},
    //
    title,
    subtitle,
    //
    children
  } = props;

  return (
    <div className={classnames(styles.root, className)}>
      {
        image && (
          <div className={classnames(styles.imageContainer, imageContainerClassName)}>
            {
              typeof image === 'string'
                ? <Image src={image} {...imageProps} />
                : image
            }
          </div>
        )
      }
      {
        title && (
          <div className={classnames(styles.title, titleClassName)}>
            {title}
          </div>
        )
      }
      {
        subtitle && (
          <div className={classnames(styles.subtitle, subtitleClassName)}>
            {subtitle}
          </div>
        )
      }
      {
        children
        && (
          <div className={classnames(styles.content, contentClassName)}>
            {children}
          </div>
        )
      }
    </div>
  );
};

export default ImageMessage;
