import React from 'react';
import { useRouter } from 'next/router';

import { Link } from 'components/ui';

import { APP_ROUTES } from 'constants/routes';

import * as ProductHelpers from 'helpers/ProductHelpers';

import type ProductModal from 'components/layout/ProductModal/ProductModal';
import type ProductDetails from 'components/product/ProductDetails/ProductDetails';

import type { IAnalyticsListProps } from 'types';

interface Props {
  slug: string,
  //
  isDirectLink?: boolean,
  isAcceleratedSale?: boolean,
  //
  modalProps?: Partial<React.ComponentProps<typeof ProductModal>>,
  detailsProps?: Partial<React.ComponentProps<typeof ProductDetails>>,
  listProps?: IAnalyticsListProps,
  //
  withoutLink?: boolean,
  //
  children: React.ReactNode
}

const ProductLink = React.forwardRef<HTMLAnchorElement, Props>((
  props: Props,
  ref: React.Ref<HTMLAnchorElement>,
) => {
  const {
    slug,
    //
    isDirectLink,
    isAcceleratedSale = false,
    //
    modalProps = {},
    detailsProps = {},
    listProps = {},
    //
    withoutLink,
    //
    children
  } = props;

  // Hooks

  const router = useRouter();
  const link = ProductHelpers.getProductLink(slug, isAcceleratedSale);

  // Without Link

  if (withoutLink) {
    return children;
  }

  // Direct Link
  // opens product page

  if (isDirectLink) {
    return (
      <Link
        ref={ref}
        passHref
        prefetch={false}
        prefetchOnHover={false}
        href={{
          pathname: link,
          query: {
            ...router.query,
            sm: isAcceleratedSale,
          }
        }}
      >
        {children}
      </Link>
    );
  }

  // Quick View
  // opens product modal

  return (
    <Link
      ref={ref}
      scroll={false}
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          originalPathname: router.asPath,
          sm: isAcceleratedSale,
          quickView: router.route.startsWith(`${APP_ROUTES.PRODUCTS}/`)
            ? null
            : slug,
          productModalProps: JSON.stringify(modalProps),
          productDetailsProps: JSON.stringify(detailsProps),
          productListProps: JSON.stringify(listProps),
        }
      }}
      as={link || undefined}
      passHref
      prefetch={false}
      prefetchOnHover={false}
    >
      {children}
    </Link>
  );
});

export default React.memo(ProductLink);
