import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import {
  Button, Icon, Image, Link, Loader
} from 'components/ui';
import { hexToRgbA, shadeColor } from 'helpers/ColorHelpers';
import { parseAbsoluteUrl } from 'helpers/UrlHelpers';
import { useConfig } from 'hooks/data/useConfig';
import { useUserConfig } from 'hooks/data/useUserConfig';
import { useAnalytics } from 'hooks/useAnalytics';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { useIsClient } from 'hooks/useIsClient';

import type {
  IAnnouncement, IAnnouncementBanner, IAnnouncementBannerColors, IConfig, IUserConfig
} from 'types';

import styles from './AnnouncementBanner.module.scss';

const AnnouncementBanner = () => {

  // Hooks

  const {
    isAuthenticated,
    isSettled: isAuthenticationSettled
  } = useIsAuthenticated();

  const {
    data: config
  } = useConfig();

  const {
    isLoading: isUserConfigLoading,
    data: userConfig
  } = useUserConfig({ enabled: isAuthenticated });

  const analytics = useAnalytics();
  const isClient = useIsClient();

  // State Hooks

  const [isDismissed, setDismissed] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  // Props

  const genericBanner = React.useMemo(() => getGenericAnnouncementBanner(config), [config]);
  const userBanner = React.useMemo(() => getUserAnnouncementBanner(userConfig), [userConfig]);

  const currentBanner = isClient
    ? userBanner || genericBanner
    : genericBanner;

  // Effects

  React.useEffect(() => {
    setLoading(!isAuthenticationSettled || isUserConfigLoading);
  }, [isAuthenticationSettled, isUserConfigLoading]);

  // Empty

  if (isDismissed || !currentBanner) {
    return null;
  }

  // Props

  const {
    title,
    description,
    colors,
  } = currentBanner || {};

  const {
    bgColor,
    bgHoverColor,
    titleColor,
    separatorColor,
    descriptionColor,
  } = colors || {};

  // Handlers

  const handleClose = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    analytics.dismissAnnouncementBanner(bgColor, title || description);
    setDismissed(true);
  };

  const handleClick = () => {
    analytics.selectAnnouncementBanner(bgColor, title || description);
  };

  // Render

  return (
    <div
      id="announcement-banner"
      className={styles.root}
      onClick={handleClick}
      style={{
        '--background-color': bgColor,
        '--background-hover-color': bgHoverColor,
        '--title-color': titleColor,
        '--description-color': descriptionColor,
        '--separator-color': separatorColor
      } as CSSProperties}
    >
      <AnnouncementBannerContent
        loading={isLoading}
        announcementBanner={currentBanner}
        onClose={handleClose}
      />
    </div>
  );
};

// Components

interface AnnouncementBannerContentProps {
  loading: boolean,
  announcementBanner: IAnnouncementBanner,
  onClose: (e?: any) => void
}

const AnnouncementBannerContent = (props: AnnouncementBannerContentProps) => {
  const {
    loading,
    announcementBanner,
    onClose,
  } = props;

  // Props

  const {
    href,
    icon,
    title,
    description,
    colors,
  } = announcementBanner || {};

  const {
    titleColor,
  } = colors || {};

  // Inner

  const Inner = (
    <AnnouncementBannerInner
      loading={loading}
      loaderColor={titleColor}
      title={title}
      description={description}
      icon={icon}
      onClose={onClose}
    />
  );

  // Render

  return (
    <>
      {
        href
          ? (
            <Link
              href={href}
              prefetch={false}
              passHref
            >
              <a
                className={
                  classnames(
                    styles.announcement,
                    styles.link,
                    { [styles.withIcon]: !!icon },
                  )
                }
              >
                {Inner}
              </a>
            </Link>
          ) : (
            <div
              className={
                classnames(
                  styles.announcement,
                  { [styles.withIcon]: !!icon },
                )
              }
            >
              {Inner}
            </div>
          )
      }
    </>
  );
};

interface AnnouncementBannerInnerProps {
  className?: string,
  loading?: boolean,
  loaderColor?: string,
  icon?: string,
  title?: string,
  description: string,
  onClose: (e?: any) => void
}

const AnnouncementBannerInner = (props: AnnouncementBannerInnerProps) => {
  const {
    className,
    loading,
    loaderColor,
    icon,
    title,
    description,
    onClose = () => {},
  } = props;

  return (
    <>
      <div
        className={classnames(
          styles.inner,
          { [styles.loading]: loading },
          className,
        )}
      >

        {/* Container */}
        <div className={styles.container}>

          {/* Icon */}
          {
            icon && (
              <div
                className={classnames(
                  styles.icon,
                  { [styles.loading]: loading }
                )}
              >
                <div className={styles.iconInner}>
                  <Image
                    src={icon}
                    fill
                    style={{
                      objectFit: 'contain'
                    }}
                    alt="Announcement"
                  />
                </div>
              </div>
            )
          }

          {/* Content */}
          <div
            className={classnames(
              styles.content,
              { [styles.loading]: !!loading }
            )}
          >

            {/* Title */}
            {
              title && (
                <div
                  className={classnames(
                    styles.title,
                  )}
                >
                  {title}
                </div>
              )
            }

            {/* Description */}
            <div
              className={styles.description}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
            />

          </div>

          {/* Close */}
          <Button
            className={styles.closeBtn}
            icon={<Icon name="x" size={16} strokeWidth={2} />}
            size="small"
            onClick={onClose}
            aria-label="Close"
          />

        </div>

      </div>

      {/* Loader */}
      <Fade
        className={styles.loader}
        isVisible={loading}
        enterDuration={200}
        enterDelay={200}
        exitDuration={200}
        exitDelay={0}
      >
        <Loader
          color={loaderColor}
          size={18}
        />
      </Fade>
    </>
  );
};

// Helpers

// --- Get Generic Announcement Banner

const getGenericAnnouncementBanner = (config: IConfig) => {
  const {
    announcement: announcementV1Text,
    announcementLink: announcementV1Link
  } = config || {};

  if (!announcementV1Text) return null;

  return parseAnnouncement({
    htmlDescription: announcementV1Text,
    link: announcementV1Link,
  });
};

// --- Get User Specific Announcement Banner

const getUserAnnouncementBanner = (userConfig: IUserConfig) => {
  const { announcementV2 } = userConfig || {};

  if (!announcementV2) return null;

  return parseAnnouncement(announcementV2);
};

// --- Parse Announcement Banner

const parseAnnouncement = (announcement?: IAnnouncement): IAnnouncementBanner => {
  if (!announcement) return null;

  const {
    icon,
    title,
    htmlDescription,
    bgColor,
    titleColor,
    descriptionColor,
    link
  } = announcement;

  const {
    valid: isLinkValid,
    pathname: href
  } = parseAbsoluteUrl(link);

  const colors = getColors({
    bgColor,
    titleColor,
    descriptionColor,
  });

  return {
    icon,
    title,
    description: htmlDescription,
    href: isLinkValid ? href : undefined,
    colors,
  };
};

// --- Get Announcement Banner Colors

const getColors = (params: Pick<IAnnouncement, 'bgColor' | 'titleColor' | 'descriptionColor'>): IAnnouncementBannerColors => {
  const {
    bgColor: initialBgColor,
    titleColor: initialTitleColor,
    descriptionColor: initialDescriptionColor,
  } = params || {};

  const bgColor = initialBgColor || styles.colorPrimary;
  const bgHoverColor = shadeColor(bgColor, -5);

  const titleColor = initialTitleColor || styles.colorWhite;
  const separatorColor = hexToRgbA(titleColor, 0.7);
  const descriptionColor = initialDescriptionColor || titleColor || styles.colorWhite;

  return {
    bgColor,
    bgHoverColor,
    titleColor,
    descriptionColor,
    separatorColor,
  };
};

// Export

export default AnnouncementBanner;
