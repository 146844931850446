/* eslint-disable react/no-danger */

import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import { ProductImage, ProductLink, ProductPrice } from 'components/product';
import { Button, Loader } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { useRouteQueryAnalyticsListProps } from 'hooks/useRouteQueryAnalyticsListProps';

import RecipeIngredientCardActions from './RecipeIngredientCardActions';

import type { IAnalyticsListProps, IOrder, IRecipeIngredientProduct } from 'types';

import styles from './RecipeIngredientCard.module.scss';

export interface Props {
  className?: string,
  //
  order: IOrder,
  ingredientProduct: IRecipeIngredientProduct,
  listProps?: IAnalyticsListProps,
  //
  disabled?: boolean,
  //
  withLink?: boolean,
  withActions?: boolean,
  withVerboseActions?: boolean,
  withExternalInfo?: boolean,
  //
  onReplaceClick?: (ingredientProduct: IRecipeIngredientProduct) => void
}

const RecipeIngredientCard = (props: Props) => {

  const {
    className,
    //
    order,
    ingredientProduct,
    listProps,
    //
    disabled,
    //
    withLink,
    withActions = true,
    withVerboseActions,
    withExternalInfo,
    //
    onReplaceClick,
  } = props;

  const {
    ingredient,
    product,
  } = ingredientProduct;

  const {
    quantity,
    backups,
  } = ingredient || {};

  const {
    slug,
    isAvailable,
    image,
    name,
    //
    originalPrice,
    price,
    currency,
    taxes,
    //
    maxAvailableQuantity,
    maxAllowedQuantity,
  } = product || {};

  const hasBackups = !isArrayEmpty(backups);

  // Hooks

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const isMobile = useBreakpoint('md', 'down');

  const {
    set: setAnalyticsListProps
  } = useRouteQueryAnalyticsListProps(listProps);

  // Props

  const orderItem = React.useMemo(() => OrderHelpers.getOrderItem(order, product), [order, product]);

  const { error } = orderItem || {};

  const quantityRequiredLabel = quantity === 1
    ? t('recipes:GENERAL.INFO.QUANTITY_REQUIRED.SINGULAR')
    : t('recipes:GENERAL.INFO.QUANTITY_REQUIRED.PLURAL', { quantity });

  const hasInsufficientQuantity = isAvailable && (quantity > maxAvailableQuantity);

  const maxQuantity = Math.min(maxAvailableQuantity, maxAllowedQuantity);

  const insufficientQuantityError = maxAllowedQuantity === 1
    ? t('PRODUCT.ERRORS.INSUFFICIENT_QUANTITY_SINGULAR', { quantity: maxQuantity })
    : t('PRODUCT.ERRORS.INSUFFICIENT_QUANTITY_PLURAL', { quantity: maxQuantity });

  // Handlers

  const handleClick = () => {
    setAnalyticsListProps(listProps);
  };

  // Render

  return (
    <IngredientLink
      slug={slug}
      active={withLink && isMobile}
    >
      <div className={classnames(styles.root, className)}>

        {/* Inner */}
        <div className={styles.inner}>

          {/* Thumbnail */}
          <div className={styles.thumbnail}>

            {/* Image */}
            <ProductImage
              className={styles.image}
              src={image?.thumbnail}
              alt={name}
              size={IMAGE_SIZE}
              retina
              unavailable={!isAvailable || disabled}
              rounded
            />

            {/* Loading Overlay */}
            <Fade
              className={styles.loadingOverlay}
              isVisible={isLoading}
              lazyLoad
              enterDuration={500}
              exitDuration={300}
            >
              <Loader
                size={18}
                lineWeight={2}
              />
            </Fade>

          </div>

          {/* Details */}
          <div className={styles.details}>

            {/* Main */}
            <div className={styles.main}>

              {/* Title */}
              <IngredientLink
                active={withLink && !isMobile}
                slug={slug}
              >
                {
                  withLink && !isMobile
                    ? (
                      <Button tag="a" className={classnames(styles.title, styles.asLink)} onClick={handleClick}>
                        {product?.name}
                      </Button>
                    ) : (
                      <p className={styles.title}>
                        {product?.name}
                      </p>
                    )
                }
              </IngredientLink>

            </div>

            {/* Secondary */}
            <div className={styles.secondary}>

              {/* Price */}
              <ProductPrice
                priceClassName={classnames(
                  styles.price,
                  { [styles.unavailable]: !isAvailable },
                  { [styles.discounted]: originalPrice && price !== originalPrice }
                )}
                currency={currency}
                originalPrice={originalPrice}
                price={price}
                taxes={taxes}
              />

              {/* Actions */}
              {
                withActions && (
                  <RecipeIngredientCardActions
                    className={styles.actions}
                    //
                    order={order}
                    product={product}
                    listProps={listProps}
                    //
                    disabled={disabled}
                    canReplace={hasBackups}
                    //
                    withVerboseActions={withVerboseActions}
                    //
                    onLoading={setIsLoading}
                    onReplaceClick={() => {
                      if (onReplaceClick) {
                        onReplaceClick(ingredientProduct);
                      }
                    }}
                  />
                )
              }

            </div>

            {/* Tertiary */}
            {
              !withExternalInfo && (
                <div className={styles.tertiary}>

                  {/* Errors */}
                  <Errors
                    isAvailable={isAvailable}
                    hasInsufficientQuantity={hasInsufficientQuantity}
                    error={error}
                    insufficientQuantityError={insufficientQuantityError}
                  />

                  {/* Quantity Info */}
                  <p
                    className={styles.info}
                    dangerouslySetInnerHTML={{ __html: quantityRequiredLabel }}
                  />

                </div>
              )
            }
          </div>

        </div>

        {/* External Tertiary */}
        {
          withExternalInfo && (
            <div className={classnames(styles.tertiary, styles.external)}>

              {/* Errors */}
              <Errors
                isAvailable={isAvailable}
                error={error}
                hasInsufficientQuantity={hasInsufficientQuantity}
                insufficientQuantityError={insufficientQuantityError}
              />

              {/* Quantity Info */}
              <p
                className={styles.info}
                dangerouslySetInnerHTML={{ __html: quantityRequiredLabel }}
              />
            </div>
          )
        }

      </div>
    </IngredientLink>
  );
};

// Components

interface IngredientLinkProps {
  active: boolean,
  slug: string,
  children: React.ReactNode
}

const IngredientLink = (props: IngredientLinkProps) => {
  const {
    active,
    slug,
    children
  } = props;

  if (!active) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <ProductLink
      slug={slug}
      detailsProps={{
        withoutToolbar: true,
      }}
    >
      {children}
    </ProductLink>
  );
};

interface ErrorsProps {
  isAvailable: boolean,
  error: string,
  hasInsufficientQuantity: boolean,
  insufficientQuantityError: string
}

const Errors = (props: ErrorsProps) => {
  const {
    isAvailable,
    error,
    hasInsufficientQuantity,
    insufficientQuantityError,
  } = props;

  const { t } = useTranslation();

  if (error) {
    return (
      <p className={styles.error}>
        {error}
      </p>

    );
  }

  if (!isAvailable) {
    return (
      <p className={styles.error}>
        {t('PRODUCT.ERRORS.PRODUCT_UNAVAILABLE')}
      </p>
    );
  }

  if (hasInsufficientQuantity) {
    return (
      <p className={styles.error}>
        {insufficientQuantityError}
      </p>
    );
  }

  return null;
};

// Constants

export const IMAGE_SIZE = 80;

// Export

export default RecipeIngredientCard;
