import { CONFIG } from 'constants/config';

import { createHttpClient } from './HttpClient';

import type { HttpClientConfig, IContentfulApi, IContentfulRestApi } from 'types';

export const ContentfulApi = createContentfulApi();
export const ContentfulRestApi = createContentfulRestApi();

function createContentfulApi(): IContentfulApi {
  const client = createHttpClient({ prefixUrl: CONFIG.CONTENTFUL.GRAPHQL_API_URL });

  const fetch = (query: string, preview = false, partialConfig: HttpClientConfig = {}) => {
    return client.post('/', { query }, { ...getConfig(partialConfig, preview) });
  };

  return {
    ...client,
    fetch
  };
}

function createContentfulRestApi(): IContentfulRestApi {
  const client = createHttpClient({ prefixUrl: CONFIG.CONTENTFUL.API_URL });

  const fetch = (url: string, preview = false, partialConfig: HttpClientConfig = {}) => {
    return client.get(url, { ...getConfig(partialConfig, preview) });
  };

  return {
    ...client,
    fetch
  };
}

function getConfig(config: HttpClientConfig, preview: boolean): HttpClientConfig {
  const { headers: headerOptions = {}, ...rest } = config;
  return {
    headers: {
      Authorization: `Bearer ${
        preview
          ? CONFIG.CONTENTFUL.PREVIEW_ACCESS_TOKEN
          : CONFIG.CONTENTFUL.ACCESS_TOKEN
      }`,
      ...headerOptions
    },
    ...rest
  };
}
