import type { ValueOf } from 'types/utility';

export const PAYMENT_METHOD_TYPES = {
  MONEY: 'money',
  MEAL_VOUCHERS: 'meal-voucher'
} as const;

export type PaymentMethodType = ValueOf<typeof PAYMENT_METHOD_TYPES>;

// Money

export interface IPayment {
  type: 'money',
  id: number,
  amount: number,
  currency: string
}

// Meal Voucher

export interface IMealVoucherSupplier {
  code: 'edenred',
  enabled: boolean,
  label: string,
  icon: string,
  logo: string,
  connectUrl: string,
  configured: boolean
}

// Points

export interface IPointsPayment {
  type: 'points',
  id: number,
  amount: number,
  currency: string
}

// Generic

export interface IGenericPaymentMethod {
  amount?: number,
  uri: string,
  code: string,
  name: string,
  instructions: string,
  checkoutEnabled: boolean,
  eligible: true
}

export interface IMoneyPaymentMethod extends IGenericPaymentMethod {
  type: 'money',
  payment: IPayment
}

export interface IMealVoucherPaymentMethod extends IGenericPaymentMethod {
  type: 'meal-voucher',
  mealVoucher: IMealVoucherSupplier
}

export interface IPointsPaymentMethod extends IGenericPaymentMethod {
  type: 'points',
  payment: IPayment
}

// Payment Method

export type IPaymentMethod =
  IMoneyPaymentMethod
  | IMealVoucherPaymentMethod
  | IPointsPaymentMethod;
