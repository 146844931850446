import { getPushCategoryEventHandler } from './AnalyticsGAHelpers';

const CATEGORY = 'vouchers';

enum Actions {
  VIEW_MODAL = 'view_modal',
  SELECT_VIEW_MORE = 'select_view_more',
  DISMISS_MODAL = 'dismiss_modal',
  CHANGE_FILTER = 'change_filter',
}

const push = getPushCategoryEventHandler<Actions>(CATEGORY);

// Events

export function viewAccountBenefitsModal(label: string) {
  return push(Actions.VIEW_MODAL, label);
}

export function dismissAccountBenefitsModal(label: string) {
  return push(Actions.DISMISS_MODAL, label);
}

export function changeAccountBenefitsFilter(label: string) {
  return push(Actions.CHANGE_FILTER, label);
}

export function selectAccountBenefitsModalViewMore(label: string) {
  return push(Actions.SELECT_VIEW_MORE, label);
}
