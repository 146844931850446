import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Icon, Image, Link, Responsive
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { FOOTER_NAV_ITEMS } from 'constants/navigation';
import { useMediaQuery } from 'hooks/useMediaQuery';

import LegalImages from './FooterLegalImages';

import type { ImageProps } from 'components/ui';

import styles from './FooterLinks.module.scss';

const FooterLinks = () => {

  const [section1, section2, section3] = FOOTER_NAV_ITEMS;

  return (
    <div className={styles.root}>
      <div className={styles.links}>
        <div className={styles.col}>
          <FooterLinksSection section={section1} />
          <FooterLinksSection section={section2} />
        </div>
        <div className={styles.col}>
          <FooterLinksSection section={section3} />
        </div>
      </div>
      <LegalImages />
    </div>
  );
};

interface Props {
  section: {
    title?: string,
    children: {
      title?: string,
      href: string,
      external?: boolean,
      image?: string,
      imageProps?: Omit<ImageProps, 'src'>
    }[]
  }
}

const FooterLinksSection = (props: Props) => {
  const { section } = props;
  const { title: sectionTitle, children = [] } = section;

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery({ direction: 'min', breakpoint: styles.breakpoint });

  const [expanded, setExpanded] = React.useState(false);

  // Effects

  React.useEffect(() => {
    if (!isBreakpoint) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [isBreakpoint]);

  // Render

  return (
    <ul key={sectionTitle} className={styles.linksSection}>

      {
        sectionTitle && (
          <li
            className={classnames(
              styles.linksSectionTitle,
              { [styles.expanded]: expanded }
            )}
          >
            <Responsive
              breakpoint="lg"
              mobile={(
                <button type="button" className={styles.sectionExpandButton} onClick={() => setExpanded((prev) => !prev)}>
                  <span>{t(sectionTitle)}</span>
                  <Icon name="chevron-down" className={classnames(styles.chevron, { [styles.expanded]: expanded })} />
                </button>
              )}
              desktop={(
                <span>{t(sectionTitle)}</span>
              )}
            />
          </li>
        )
      }
      {
        children.map((page, index) => {
          const {
            title, image, imageProps, href, external
          } = page;

          return (
            <li
              key={page.href + index}
              className={classnames(
                styles.linkContainer,
                { [styles.expanded]: expanded },
                { [styles.withImage]: image }
              )}
            >
              {
                external
                  ? (
                    <a className={styles.link} href={href} target="_blank" rel="noopener noreferrer">
                      {title && t(title)}
                      {image && <Image src={image} {...imageProps} />}
                    </a>
                  ) : (
                    <Link href={href} prefetch={CONFIG.PREFETCH_LINKS}>
                      <a className={styles.link}>
                        {title && t(title)}
                        {image && <Image src={image} {...imageProps} />}
                      </a>
                    </Link>
                  )
              }
            </li>
          );
        })
      }
    </ul>
  );
};

export default FooterLinks;
