import React from 'react';
import { useTranslation } from 'next-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { Button, ImageMessage } from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';
import * as MonitoringService from 'services/MonitoringService';

import type { FallbackProps } from 'react-error-boundary';

import styles from './GlobalErrorBoundary.module.scss';

interface Props {
  children: React.ReactNode
}

const GlobalErrorBoundary = (props: Props) => {
  const { children } = props;
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
    >
      {children}
    </ErrorBoundary>
  );
};

// TODO Redirect to 5500 page instead

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    MonitoringService.logError(error.message, { error });
  }, []);

  const onClick = () => {
    resetErrorBoundary();
    window.location.href = APP_ROUTES.HOME;
  };

  return (
    <div className={styles.root}>
      <ImageMessage
        image={IMAGES.ERRORS.GENERIC}
        imageProps={{
          width: 414,
          height: 246,
          alt: '500 Server Error'
        }}
        title={t('ERROR_TEMPLATES.500.TITLE')}
        subtitle={t('ERROR_TEMPLATES.500.SUBTITLE')}
      >
        {
          window?.location.pathname !== APP_ROUTES.HOME && (
            <Button
              className={styles.ctaButton}
              onClick={onClick}
              variant="primary"
            >
              {t('ERROR_TEMPLATES.500.CTA')}
            </Button>
          )
        }
      </ImageMessage>
    </div>
  );
}

export default GlobalErrorBoundary;
