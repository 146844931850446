import React from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import { MDXRemote } from 'next-mdx-remote';
import classnames from 'classnames';

import { Skeleton } from 'components/ui';
import { convertMobilePreviewLinks } from 'helpers/UrlHelpers';
import { useParseMarkdown } from 'hooks/common/useParseMarkdown';

import styles from './Markdown.module.scss';

interface Props {
  className?: string,
  text: string | MDXRemoteSerializeResult,
  onLinkClick?: (url: string) => void
}

const Markdown = (props: Props) => {
  const {
    text
  } = props;

  const shouldParseMarkdown = typeof text === 'string';

  return (
    shouldParseMarkdown
      ? <LazyLoadMarkdown {...props as LazyLoadMarkdownProps} />
      : <DefaultMarkdown {...props as DefaultMarkdownProps} />
  );
};

// Default

interface DefaultMarkdownProps {
  className?: string,
  text: MDXRemoteSerializeResult,
  onLinkClick?: (linkUrl: string) => void
}

const DefaultMarkdown = (props: DefaultMarkdownProps) => {
  const {
    className,
    text,
    onLinkClick
  } = props;

  // Hooks

  const [initialText, setInitialText] = React.useState<MDXRemoteSerializeResult>(null);

  React.useEffect(() => {
    if (
      text
      && (
        !initialText
        || (!!text?.compiledSource && initialText?.compiledSource !== text?.compiledSource)
      )
    ) {
      setInitialText(text);
    }
  }, [initialText, text]);

  // Empty

  if (!initialText || !initialText.compiledSource) {
    return null;
  }

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      onClick={(e) => {
        handleInnerLinkClick(onLinkClick, e);
      }}
    >
      <MDXRemote {...initialText} />
    </div>
  );
};

// Lazy Load

interface LazyLoadMarkdownProps {
  className?: string,
  text: string,
  onLinkClick?: (url: string) => void
}

const LazyLoadMarkdown = (props: LazyLoadMarkdownProps) => {
  const {
    className,
    text,
    onLinkClick
  } = props;

  const parsedText = convertMobilePreviewLinks(text);

  const { isLoading, data: markdown } = useParseMarkdown(parsedText);

  if (isLoading) {
    return (
      <div
        className={classnames(
          styles.root,
          className
        )}
      >
        <Skeleton />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      onClick={(e) => {
        handleInnerLinkClick(onLinkClick, e);
      }}
    >
      <MDXRemote {...markdown} />
    </div>
  );

};

// Helpers

const handleInnerLinkClick = (
  handler: (url: string) => void,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  if (!handler || !event.target) return;

  const el = event?.target as HTMLElement;

  if ((el as HTMLAnchorElement).href) {
    handler((el as HTMLAnchorElement).href);
    return;
  }

  const parentLink = el.closest('a');

  if (parentLink) {
    handler((parentLink as HTMLAnchorElement).href);
  }
};

// Export

export default Markdown;
